angular.module('smartertools')
    .directive('compileHtml', ['$compile', function($compile) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            scope.$watch(attrs.compileHtml, function(newHtml) {
                element.html(newHtml);
                $compile(element.contents())(scope);
            });
        }
    };
}]);