(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditSpamAssassinServerController", sysAdminEditSpamAssassinServerController);

	function sysAdminEditSpamAssassinServerController($scope, $mdDialog, name, address, port) {
		$scope.name = name;
		$scope.address = address;
		$scope.port = port;
        $scope.isValid = true;
	    $scope.editingItem = !!address;

        $scope.delete = function () {
            $mdDialog.hide({ doDelete:true});
        };

		$scope.save = function (form) {
			form.$setSubmitted();
			if (!($scope.isValid = form.$valid)) {
				return;
			}

			$mdDialog.hide({
				name: $scope.name,
				address: $scope.address,
				port: $scope.port
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
