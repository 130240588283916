(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("whatsNewDialogController", whatsNewDialogController);

    function whatsNewDialogController($rootScope, $scope, $mdDialog, $sce, $state, $http, coreDataSettings, $translate, items) {
        var vm = this;
        vm.newItems = items;

        //Functions
        vm.getIcon = getIcon;
        vm.showDoubleColumnValue = showDoubleColumn();
        vm.getTranslationMessage = getTranslationMessage;
        vm.handleLinkClick = handleLinkClick;
        $scope.cancel = cancel;

        activate();
        /////////////////////////


        function activate() {
            $scope.$on('whatsNewLinkEvent', vm.handleLinkClick);
        }

        function getIcon(image) {
            var htmlImage = $sce.trustAsHtml(image);
            return htmlImage;
        }

        function getTranslationMessage(desc) {
            var translation = "";
            switch (desc) {
                case "WHATS_NEW_EMCLIENT_DESC_EMLCIENT":
                    translation = $translate.instant(desc, { preLink: "replace1", postLink: "replace2" });
                    translation = translation.replace("replace1", "<a onclick=\"broadcastWhatsNewMessage('whatsNewLinkEvent', { desc: 'WHATS_NEW_EMCLIENT_DESC_EMLCIENT'});\" >");
                    translation = translation.replace("replace2", "</a>");
                    break;
                default:
                    translation = $translate.instant(desc);
            }
            
            return $sce.trustAsHtml(translation);
        } 

        function handleLinkClick($event, message) {
            switch (message.desc) {
                case "WHATS_NEW_EMCLIENT_DESC_EMLCIENT":
                    $state.go("index.settings.domain-emclient-licenses",{type: "domain"});
                    $mdDialog.cancel();
                    break;
            }

        }

        function showDoubleColumn() {
            if (vm.newItems.length > 4 && window.innerWidth > 736)
                return true;
            return false;
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();