(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataDomainSettings", coreDataDomainSettings);

	function coreDataDomainSettings($http, $q, $filter, $translate, $rootScope, $log, userDataService, $state, $stateParams, emailValidationService) {
		var vm = this;
		var _domainSignatureMappings = {};
		var domainSignatureMappingsInitialized = false;
		var _domainContentFiltering = {};
		var contentFilteringInitialized = false;
		var domainDataInitialized = false;
		var _userList = {};
		var userListInitialized = false;
		var _aliases = {};
		var aliasesInitialized = false;
		var _userGroups = {};
		var userGroupsInitialized = false;
		var _resources = {};
		var resourcesInitialized = false;
		var _customFields = {};
		var customFieldsInitialized = false;
		var _companyInfo = {};
		var companyInfoInitialized = false;
		var _subscribers = {};
		var subscribersInitialized = false;
		var _domainEvents = {};
		var domainEventsInitialized = false;
		var _availableEventVars = {};
		var availableEventsVarsInitialized = false;
		var _availableDomainEvents = {};
		var availableDomainEventsInitialized = false;
		var _domainAliases = {};
		var domainAliasesInitialized = false;
		var _isInitialized = false;

		// Public Variables
		vm.domain = "";
		vm.domainData = {};
		vm.domainSignatureMappings = {};
		vm.domainSignatures = {};
		vm.domainPermissions = {};
		vm.settingsData = {
			get domainSignatureMappings() { return getDomainSignatureMappings(); },
			set domainSignatureMappings(value) { _domainSignatureMappings = value || {} },

			get contentFiltering() { return getContentFiltering(); },
			set contentFiltering(value) { _domainContentFiltering = value || {} },

			get folders() { return getFolders(); },

			get domainData() { return getDomainData(); },
			set domainData(value) { vm.domainData = value || {} },

			get userList() { return getUserList(); },
			set userList(value) { _userList = value || {} },

			get aliases() { return getAliases(); },
			set aliases(value) { _aliases = value || {} },

			get userGroups() { return getUserGroups(); },
			set userGroups(value) { _userGroups = value || {} },

			get resources() { return getResources(); },
			set resources(value) { _resources = value || {} },

			get customFields() { return getCustomFields(); },
			set customFields(value) { _customFields = value || {} },

			get companyInfo() { return getCompanyInfo(); },
			set companyInfo(value) { _companyInfo = value || {} },

			get subscribers() { return getSubscribers(); },
			set subscribers(value) { _subscribers = value || {} },

			get domainEvents() { return getDomainEvents(); },
			set domainEvents(value) { _domainEvents = value || {} },

			get availableEventVars() { return getAvailableEventVars(); },

			get availableDomainEvents() { return getAvailableDomainEvents() },

			get domainAliases() { return getDomainAliases(); },
			set domainAliases(value) { _domainAliases = value || {} }
		};
		vm.settingAdjustWait = 2000;
		vm.settingBlurWait = 0;
		vm.cards = [];
		vm.filteredCards = [];
		vm.newItem = undefined;
		vm.editItem = undefined;
		vm.deleteItems = undefined;
		vm.reindexItems = undefined;
		vm.searchItems = undefined;
		vm.searchText = undefined;
		vm.searchEnabled = false;
		vm.searchPredicate = undefined;
		vm.editingItem = false;
		vm.deselectAll = undefined;
		vm.selectAll = undefined;
		vm.backAction = undefined;
		vm.totalCount = vm.cards.length;
		vm.listDataCache = {};
		vm.listController = {};
		vm.selectMode = false;
		vm.selectedCards = [];

		// Functions
		vm.init = init;
		vm.reset = reset;
		vm.changePageReset = changePageReset;
		vm.generateNewGuid = generateNewGuid;
		vm.generateNewShortGuid = generateNewShortGuid;
		vm.generateRandomHexColor = generateRandomHexColor;
		vm.getFilteredCards = coreGetFilteredCards;
		vm.isCardSelected = isCardSelected;
		vm.listDataProvider = listDataProvider;
		vm.parseUserTimeSpan = parseUserTimeSpan;
		vm.setSelectMode = setSelectMode;
		vm.updateFilteredCards = updateFilteredCards;
		vm.loadDomainPermissions = loadDomainPermissions;
		vm.validateEmailAddress = function (emailAddress) { return emailValidationService.isValidEmail(emailAddress); };
		vm.validateHexSixColor = function (color) { return (/^#[a-fA-F0-9]{6}$/).test(color); };
        vm.loadDomainData = loadDomainData;

		// Startup 

		// Implementation

		var initDefer;
		function init() {
			if (initDefer) return initDefer.promise;
			if (_isInitialized) return $q.when();

			initDefer = $q.defer();
			var promise = initDefer.promise;

			var promises = [];
			promises.push(loadDomainSignatures());
			promises.push(loadDomainPermissions());
			promises.push(loadDomainData());
			$q.all(promises)
				.then(function () {
					_isInitialized = true;
					vm.domain = userDataService.user.domain;
					initDefer.resolve();
					initDefer = null;
				}, function (failure) {
					initDefer.reject(failure);
					initDefer = null;
				});

			return promise;
		}

		function reset() {
			_isInitialized = false;
			vm.domain = undefined;
			vm.domainSignatureMappings = {};
			vm.domainSignatures = {};
			vm.domainPermissions = {};

			_domainContentFiltering = {};
			contentFilteringInitialized = false;
            vm.domainData = {};
			domainDataInitialized = false;
			_userList = {};
			userListInitialized = false;
			_aliases = {};
			aliasesInitialized = false;
			_userGroups = {};
			userGroupsInitialized = false;
			_resources = {};
			resourcesInitialized = false;
			_customFields = {};
			customFieldsInitialized = false;
			_companyInfo = {};
			companyInfoInitialized = false;
			_subscribers = {};
			subscribersInitialized = false;
			_domainEvents = {};
			domainEventsInitialized = false;
			_availableEventVars = {};
			availableEventsVarsInitialized = false;
			_availableDomainEvents = {};
			availableDomainEventsInitialized = false;
			_domainAliases = {};
			domainAliasesInitialized = false;
		};

		function loadDomainSignatures() {
			return $http.get("~/api/v1/settings/domain/signatures")
				.then(function (success) {
					vm.domainSignatures = success.data.signatureConfigs;
				}, function () {
					vm.domainSignatures = {};
				});
		};

		function loadDomainPermissions() {
			return $http.get("~/api/v1/settings/domain/permissions")
				.then(function (success) {
					vm.domainPermissions = success.data.permissions;
				}, function () {
					vm.domainPermissions = {};
				});
		};

		function loadDomainSignatureMappings() {
			return $http.get("~/api/v1/settings/domain/signature-maps")
				.then(function (success) {
					_domainSignatureMappings = success.data.signatureMaps;
					domainSignatureMappingsInitialized = true;
				}, function () {
					_domainSignatureMappings = [];
					domainSignatureMappingsInitialized = false;
				});
		};

		function getDomainSignatureMappings() {
			var defer = $q.defer();
			if (domainSignatureMappingsInitialized) {
				return $q.when(_domainSignatureMappings);
			}
			loadDomainSignatureMappings()
				.then(function () {
					defer.resolve(_domainSignatureMappings);
				}, function () {
					defer.reject("FAILED_TO_LOAD_DOMAIN_SIGNATURE_MAPPINGS");
				});
			return defer.promise;
		};

		function loadContentFiltering() {
			return $http.get("~/api/v1/settings/domain/content-filter-groups")
				.then(function (success) {
					_domainContentFiltering = success.data.contentFilterGroups;
					contentFilteringInitialized = true;
				}, function () {
					_domainContentFiltering = {};
					contentFilteringInitialized = false;
				});
		};

		function getContentFiltering() {
			var defer = $q.defer();
			if (contentFilteringInitialized) {
				return $q.when(_domainContentFiltering);
			}
			loadContentFiltering()
				.then(function () {
					defer.resolve(_domainContentFiltering);
				}, function () {
					defer.reject("FAILED_TO_LOAD_CONTENT_FILTERING");
				});
			return defer.promise;
		};

		function loadFolders() {
			return $http.get("~/api/v1/settings/list-folders")
				.then(function (success) {
					return success.data.folders;
				}, function () {
					return [];
				});
		};

		function getFolders() {
			var defer = $q.defer();
			loadFolders()
				.then(function (success) {
					defer.resolve(success);
				}, function () {
					defer.reject("FAILED_TO_LOAD_RETRIEVAL_ACCOUNTS");
				});
			return defer.promise;
		};

		function loadDomainData() {
			return $http.get("~/api/v1/settings/domain/data")
				.then(function (success) {
                    vm.domainData = success.data.domainData;
					domainDataInitialized = true;
				}, function () {
                    vm.domainData = {};
					domainDataInitialized = false;
				});
		};

		function getDomainData() {
			var defer = $q.defer();
			if (domainDataInitialized) {
				return $q.when(vm.domainData);
			}
			loadDomainData()
				.then(function () {
					defer.resolve(vm.domainData);
				}, function () {
					defer.reject("FAILED_TO_LOAD_DOMAIN_DATA");
				});
			return defer.promise;
		};

		function loadUserList() {
			return $http.get("~/api/v1/settings/domain/list-users-extra")
				.then(function (success) {
					_userList = success.data.userData;
					userListInitialized = true;
				}, function () {
					_userList = {};
					userListInitialized = false;
				});
		};

		function getUserList() {
			var defer = $q.defer();
			if (userListInitialized) {
				return $q.when(_userList);
			}
			loadUserList()
				.then(function () {
					defer.resolve(_userList);
				}, function () {
					defer.reject("FAILED_TO_LOAD_USER_LIST");
				});
			return defer.promise;
		};

		function loadAliases() {
			return $http.get("~/api/v1/settings/domain/aliases")
				.then(function (success) {
					_aliases = success.data.gridInfo;
					aliasesInitialized = true;
				}, function () {
					_aliases = {};
					aliasesInitialized = false;
				});
		};

		function getAliases() {
			var defer = $q.defer();
			if (aliasesInitialized) {
				return $q.when(_aliases);
			}
			loadAliases()
				.then(function () {
					defer.resolve(_aliases);
				}, function () {
					defer.reject("FAILED_TO_LOAD_ALIASES");
				});
			return defer.promise;
		};

		function loadUserGroups() {
			return $http.get("~/api/v1/settings/domain/all-user-groups")
				.then(function (success) {
					_userGroups = success.data.userGroupCollection.customUserGroups;
					userGroupsInitialized = true;
				}, function () {
					_userGroups = {};
					userGroupsInitialized = false;
				});
		};

		function getUserGroups() {
			var defer = $q.defer();
			if (userGroupsInitialized) {
				return $q.when(_userGroups);
			}
			loadUserGroups()
				.then(function () {
					defer.resolve(_userGroups);
				}, function () {
					defer.reject("FAILED_TO_LOAD_USER_GROUPS");
				});
			return defer.promise;
		};

		function loadResources() {
			return $http.get("~/api/v1/settings/domain/resources")
				.then(function (success) {
					_resources = success.data.data;
					resourcesInitialized = true;
				}, function () {
					_resources = {};
					resourcesInitialized = false;
				});
		};

		function getResources() {
			var defer = $q.defer();
			if (resourcesInitialized) {
				return $q.when(_resources);
			}
			loadResources()
				.then(function () {
					defer.resolve(_resources);
				}, function () {
					defer.reject("FAILED_TO_LOAD_RESOURCES");
				});
			return defer.promise;
		};

		function loadCustomFields() {
			return $http.get("~/api/v1/settings/domain/subscriber-field-definitions")
				.then(function (success) {
					_customFields = success.data.subscriberFields;
					customFieldsInitialized = true;
				}, function () {
					_customFields = {};
					customFieldsInitialized = false;
				});
		};

		function getCustomFields() {
			var defer = $q.defer();
			if (customFieldsInitialized) {
				return $q.when(_customFields);
			}
			loadCustomFields()
				.then(function () {
					defer.resolve(_customFields);
				}, function () {
					defer.reject("FAILED_TO_LOAD_CUSTOM_FIELDS");
				});
			return defer.promise;
		};

		function loadCompanyInfo() {
			return $http.get("~/api/v1/companyInfo/" + vm.domain + "/")
				.then(function (success) {
					_companyInfo = success.data.companyInfo;
					companyInfoInitialized = true;
				}, function () {
					_companyInfo = {};
					companyInfoInitialized = false;
				});
		};

		function getCompanyInfo() {
			var defer = $q.defer();
			if (companyInfoInitialized) {
				return $q.when(_companyInfo);
			}
			loadCompanyInfo()
				.then(function () {
					defer.resolve(_companyInfo);
				}, function () {
					defer.reject("FAILED_TO_LOAD_COMPANY_INFO");
				});
			return defer.promise;
		};

		function loadSubscribers() {
			return $http.get("~/api/v1/settings/domain/grid-subscribers")
				.then(function (success) {
					_subscribers = success.data.subscriberGrid.subscribers;
					subscribersInitialized = true;
				}, function () {
					_subscribers = {};
					subscribersInitialized = false;
				});
		};

		function getSubscribers() {
			var defer = $q.defer();
			if (subscribersInitialized) {
				return $q.when(_subscribers);
			}
			loadSubscribers()
				.then(function () {
					defer.resolve(_subscribers);
				}, function () {
					defer.reject("FAILED_TO_LOAD_SUBSCRIBERS");
				});
			return defer.promise;
		};

		function loadDomainEvents() {
			return $http.post("~/api/v1/settings/domain/event-hooks-by-owner")
				.then(function (success) {
					domainEventsInitialized = true;
					_domainEvents = success.data.eventHooks;
				}, function () {
					_domainEvents = {};
					domainEventsInitialized = false;
				});
		};

		function getDomainEvents() {
			var defer = $q.defer();
			if (domainEventsInitialized) {
				return $q.when(_domainEvents);
			}
			loadDomainEvents()
				.then(function () {
					defer.resolve(_domainEvents);
				}, function () {
					defer.reject("FAILED_TO_LOAD_DOMAIN_EVENTS");
				});

			return defer.promise;
		};

		function loadAvailableEventVars() {
			return $http.get("~/api/v1/settings/domain/event-variables")
				.then(function (success) {
					availableEventsVarsInitialized = true;
					_availableEventVars = success.data.variables;
				}, function () {
					_availableEventVars = {};
					availableEventsVarsInitialized = false;
				});
		};

		function getAvailableEventVars() {
			var defer = $q.defer();
			if (availableEventsVarsInitialized) {
				return $q.when(_availableEventVars);
			}
			loadAvailableEventVars()
				.then(function () {
					defer.resolve(_availableEventVars);
				}, function () {
					defer.reject("FAILED_TO_LOAD_EVENT_VARS");
				});

			return defer.promise;
		};

		function loadAvailableDomainEvents() {
			return $http.get("~/api/v1/settings/domain/domain-events")
				.then(function (success) {
					_availableDomainEvents = success.data.events;
					availableDomainEventsInitialized = true;
				}, function () {
					_availableDomainEvents = {};
					availableDomainEventsInitialized = false;
				});
		};

		function getAvailableDomainEvents() {
			var defer = $q.defer();
			if (availableDomainEventsInitialized) {
				return $q.when(_availableDomainEvents);
			}
			loadAvailableDomainEvents()
				.then(function () {
					defer.resolve(_availableDomainEvents);
				}, function () {
					defer.reject("FAILED_TO_LOAD_AVILIABLE_DOMAIN_EVENTS");
				});

			return defer.promise;
		};

		function loadDomainAliases() {
			return $http.get("~/api/v1/settings/domain/domain-aliases")
				.then(function (success) {
					_domainAliases = success.data.domainAliasData;
					domainAliasesInitialized = true;
				}, function () {
					_domainAliases = {};
					domainAliasesInitialized = false;
				});
		};

		function getDomainAliases() {
			var defer = $q.defer();
			if (domainAliasesInitialized) {
				return $q.when(_domainAliases);
			}
			loadDomainAliases()
				.then(function () {
					defer.resolve(_domainAliases);
				}, function () {
					defer.reject("FAILED_TO_LOAD_DOMAIN_ALIASES");
				});

			return defer.promise;
		};

		function changePageReset() {
			vm.filteredCards.length = 0;
			vm.cards.length = 0;
			vm.selectedCards.length = 0;
			vm.newItem = undefined;
			vm.editItem = undefined;
			vm.deleteItems = undefined;
			vm.reindexItems = undefined;
			vm.searchItems = undefined;
			vm.searchEnabled = false;
			vm.searchText = undefined;
			vm.searchPredicate = undefined;
			vm.selectMode = false;
			vm.editingItem = false;
			vm.deselectAll = coreDeselectAll;
			vm.selectAll = coreSelectAll;
			vm.backAction = undefined;
			vm.getFilteredCards = coreGetFilteredCards;
		}

		function listDataProvider(obj) {
			//TODO: Manage loading contacts dynamically instead of pulling from a full set of data
			var subset = [];
			var j = 0;
			var c = vm.getFilteredCards();
			for (var i = obj.start; i < (obj.start + obj.take); i++) {
				//Note: splicing will make a copy, I want a reference.
				if (c[i] == undefined) {
					break;
				}
				subset[j++] = c[i];
			}
			obj.defer.resolve(subset);
			return obj.defer.promise;
		}

		function coreGetFilteredCards() {
			if (vm.searchEnabled && vm.searchPredicate) {
				vm.filteredCards = $filter("filter")(vm.cards, vm.searchPredicate);
			} else {
				vm.filteredCards = vm.cards;
			}
			return vm.filteredCards;
		};

		function updateFilteredCards() {
			vm.totalCount = vm.cards.length;
			vm.filteredCards = vm.getFilteredCards();
			if (vm.listController.reset && vm.listController.updateDisplayList) {
				vm.listController.reset();
				vm.listController.updateDisplayList();
			}
		};

		function setSelectMode(enable) {
			vm.selectedCards.length = 0;
			$("md-card").removeAttr("style");
			vm.selectMode = enable;
		};

		function isCardSelected(selectedCard) {
			var index = vm.selectedCards.indexOf(selectedCard);
			return index > -1;
		};

		function coreDeselectAll() {
			var indiciesToRemove = [];
			$.each(vm.selectedCards, function (index, value) {
				var indexOfCard = vm.filteredCards.indexOf(value);
				if (indexOfCard >= 0) {
					indiciesToRemove.push(index);
				};
			});
			$.each(indiciesToRemove.reverse(), function (index, value) {
				vm.selectedCards.splice(value, 1);
			});
			//$scope.selectedCards.length = 0;
		};

		function coreSelectAll() {
			$.each(vm.filteredCards, function (index, value) {
				if (vm.selectedCards.indexOf(value) < 0) {
					vm.selectedCards.push(value);
				};
			});
		};

		function generateRandomHexColor(depth) {
			if (!depth) { depth = 0 }
			if (depth > 2) { return "#CDCDCD" } //just in case something goes wrong
			//16777215 is the equivalent of ffffff in decimal.
			var random = "#" + (Math.floor(Math.random() * 16777215).toString(16));
			var difLength = 7 - random.length;
			if (difLength !== 0) {
				for (var i = 0; i < difLength; ++i) { random = random + "0"; }
			}
			if (vm.validateHexSixColor(random)) {
				return random;
			} else {
				random = generateRandomHexColor(depth + 1);
				return random;
			}
		};

		function generateNewGuid() {
			//Note that guids generated this way are not guarenteed to be unique and should not be used for ids sent to the api.
			var guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			return guid;
		};

		function generateNewShortGuid() {
			var guid = "xxxx-2xxx-yxxx".replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			return guid;
		};

		function parseUserTimeSpan(time) {
			var valid = /\d/.test(time);
			if (!valid) {
				return "";
			}
			var split = time.split(":");
			if (parseInt(split[0]) > 24) {
				split[0] = "24";
			}
			if (parseInt(split[0]) < 0) {
				split[0] = "00";
			}
			if (!split[1]) {
				return "";
			}
			var split2 = split[1].split(" ");
			if (parseInt(split2[0]) > 59) {
				split2[0] = "59";
			}
			if (parseInt(split2[0]) < 0) {
				split2[0] = "00";
			}
			if (parseInt(split[0]) === 24 && parseInt(split2[0]) > 0) {
				split2[0] = "00";
			}
			split[1] = split2.join(" ");
			time = split.join(":");
			//var timeOffset = new Date().getTimezoneOffset() / 60;
			var parsed = moment(time, ["h:m a", "H:m", "hh:mm A"]);
			if (parsed.isValid()) {
				return parsed;
			} else {
				return "";
			}
		};

	}

})();