(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("systemMessagesNewController", systemMessagesNewController);

	function systemMessagesNewController($scope, $mdDialog, $translate, sysMessages) {
		$scope.currentMessages = sysMessages;
		$scope.availableLocales = angularLangNames;
		$scope.selectedLocale;
		$scope.messageTypes = [];
		$scope.selectedType;
		$scope.isValid = true;

		// Functions
		$scope.cancel = cancel;
		$scope.save = save;

		activate();

		//////////////////////

		function activate() {
			angular.forEach($scope.currentMessages,
				function(value) {
					var type = {
						v: value.key,
						n: $translate.instant('SYSTEM_MESSAGES_MESSAGE_TYPES_' + value.key.toUpperCase())
					};
					if ($scope.messageTypes.filter(function(t) {return t.v === type.v;}).length === 0)
						$scope.messageTypes.push(type);
				});
			$scope.messageTypes.sort(function(a, b) {
				if (a.n < b.n)
					return -1;
				if (a.n > b.n)
					return 1;
				return 0;
			});
		}

		function cancel () {
			$mdDialog.cancel();
		}

		function save () {
			if (!validate())
				return;

			$mdDialog.hide({type: $scope.selectedType, localeId: $scope.selectedLocale});
		}

		function validate() {
			$scope.isValid = true;
			$scope.validationError = "";

			if ($scope.currentMessages
				.filter(function(m) { return m.key === $scope.selectedType && m.localeId === $scope.selectedLocale })
				.length !== 0) {
				$scope.isValid = false;
				$scope.validationError = "SYSTEM_MESSAGE_ERROR_EXISTS";
			}

			return $scope.isValid;
		}
	}
})();