(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("hstsWarningController", hstsWarningController);

	function hstsWarningController($rootScope, $scope, $http, $mdDialog, $translate, $sanitize) {
		$scope.cancel = function() {
			$mdDialog.cancel();
		};

		$scope.continue = function () {
			$mdDialog.hide();
		}
	}

})();