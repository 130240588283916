(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateNumberList", [validateNumberList]);

	function validateNumberList() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.validateNumberList = function (modelValue, viewValue) {
					if (!viewValue) {
						return true;
					}

					var numbers = viewValue.split("\n");

					var valid = true;
					for (var i = 0; i < numbers.length; i++) {
						if (!numbers[i] && i == numbers.length - 1) continue;
						if (!(/^\d+$/).test(numbers[i])) {
							valid = false;
							break;
						}
					}
					return valid;
				};
			}
		};
	}
})();