(function () {
    'use strict';

    angular
        .module('smartermail')
        .service('menuStateManager', menuStateManager);

	function menuStateManager($window) {
		var vm = this;
		init();
		function init() {
			vm.windowWidth = $window.innerWidth;
			if (vm.windowWidth <= 736) {
				vm.isExpanded = false;
			} else {
				vm.isExpanded = true;
			}
		}
        // Functions
        vm.toggleMenu = toggleMenu;

        ////////////////////

        function toggleMenu() {
            vm.toggleMenu = !vm.toggleMenu;
        }
    }

})();