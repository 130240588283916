(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminUserActivityOnlineController", adminUserActivityOnlineController);

	function adminUserActivityOnlineController($scope, $filter, $mdDialog, $rootScope, $http, $localStorage, $translate, $compile, $q,
		NgTableParams, errorHandling, successHandling, coreDataSpool, gridCheckboxes, claimsService, i18n) {
		var vm = this;
		$scope.gridColumns = [
			{ sortField: 'emailAddress', translate: 'USER' },
			{ sortField: 'isImapUser', translate: 'TYPE' },
			{ sortField: 'ipAddress', translate: 'IP_ADDRESS' },
			{ sortField: 'location', translate: 'LOCATION' },
			{ sortField: 'loginDate', translate: 'START' },
			{ sortField: 'timeTranslate', translate: 'DURATION' }
		];
		vm.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/all-user-activity";
		vm.selectedSection = $localStorage.userActivitySection ? $localStorage.userActivitySection : 'ONLINE_USERS';
		vm.onlineSortFieldTypes = { 'connectedName': 0, 'isWebmail': 1, 'smtp': 2, 'imap': 3, 'pop': 4, 'xmpp': 5, 'eas': 6, 'ews': 7 };
		vm.searchParams = { startindex: 0, count: 0, search: '', sortType: null, ascending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { 0: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.checkboxes.specialKey = function (item) { return item.lastAuthedUser + '|' + item.ipAddress; };

		// Functions
		vm.dropConnections = dropConnections;
		vm.disableUsers = disableUsers;
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.canDisableSelectedUsers = canDisableSelectedUsers;

		activate();

		/////////////////////

		function activate() {
			$scope.$emit('reloadUserActivity');
		}

		function dropConnections() {
			var foundRows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var split = key.split('|');
					foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.lastAuthedUser === split[0] && r.ipAddress === split[1] }));
				}
			}
			if (foundRows.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			} else if (foundRows.length == 1) {
				doDropConnections();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: foundRows.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(doDropConnections, function () {});
			}

			function doDropConnections() {
				var toEndWeb = [];
				var toDropCon = [];
				angular.forEach(foundRows, function (user) {
					if (user.isWebmailUser) {
						toEndWeb.push({ email: user.lastAuthedUser, ip: user.ipAddress })
					}
					if (user.lastAuthedUser.indexOf("/") !== -1) {
						toDropCon.push(user.lastAuthedUser.substring(0, user.lastAuthedUser.indexOf("/")));
					} else {
						toDropCon.push(user.lastAuthedUser);
					}
				});
				var disconnectedSelf = false;
				var promises = [];
				if (toDropCon.length > 0) {
					var dropParams = JSON.stringify({ dropInfo: toDropCon });
					promises.push($http.post('~/api/v1/settings/sysadmin/drop-user-connections', dropParams));
				}
				if (toEndWeb.length > 0) {
					var webParams = JSON.stringify({ sessions: toEndWeb });
					promises.push($http.post('~/api/v1/settings/sysadmin/kill-user-sessions', webParams));

					toEndWeb.forEach(function (webEnd) {
						if (webEnd.email === claimsService.getUsername())
							disconnectedSelf = true;
					});
				}
				$q.all(promises)
					.then(function(success) {
							vm.checkboxes.reset();
							vm.tableParams.reload();
							successHandling.report();
							if (disconnectedSelf)
								window.location.hash = "";
						},
						errorHandling.report);
			}
		}
		function canDisableSelectedUsers() {

			return _.some(vm.checkboxes.items,
				function(x, key) {
					return !key.includes("@");
				});
		}
		function disableUsers() {
			var foundRows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var split = key.split('|');
					foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.lastAuthedUser === split[0] && r.ipAddress === split[1] }));
				}
			}
			if (foundRows.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
				return;
			}
			var confirm = $mdDialog.confirmWithOption()
				.title($filter("translate")("CONFIRMATION_REQUIRED"))
				.textContent(i18n.reactPluralize("CONFIRMATIONS_DISABLE_USERS", { count: foundRows.length }))
				.optionText($translate.instant("ALLOW_INCOMING_MESSAGES"))
				.cancel($filter("translate")("CANCEL"))
				.ok($filter("translate")("DISABLE"));

			$mdDialog.show(confirm).then(function(option) { doDisableUsers(option); });

			function doDisableUsers(allowMail) {
				var toEndWeb = [];
				var toDropCon = [];

				angular.forEach(foundRows, function (user) {
					if (user.isWebmailUser) {
						toEndWeb.push({ email: user.lastAuthedUser, ip: user.ipAddress })
					}
					if (user.lastAuthedUser.indexOf("/") !== -1) {
						toDropCon.push(user.lastAuthedUser.substring(0, user.lastAuthedUser.indexOf("/")));
					} else {
						toDropCon.push(user.lastAuthedUser);
					}
				});
				var disconnectedSelf = false;
				var promises = [];
				if (toDropCon.length > 0) {
					var dropParams = JSON.stringify({ dropInfo: toDropCon });
					promises.push($http.post('~/api/v1/settings/sysadmin/drop-user-connections', dropParams));
				}
				if (toEndWeb.length > 0) {
					var webParams = JSON.stringify({ sessions: toEndWeb });
					promises.push($http.post('~/api/v1/settings/sysadmin/kill-user-sessions', webParams));

					toEndWeb.forEach(function (webEnd) {
						if (webEnd.email === claimsService.getUsername())
							disconnectedSelf = true;
					});
				}
				var apiEndpoint = allowMail
					? '~/api/v1/settings/sysadmin/users-disable-with-mail'
					: '~/api/v1/settings/sysadmin/users-disable/true';
				promises.push($http.post(apiEndpoint, JSON.stringify({ input: toDropCon })));

				$q.all(promises)
					.then(function(success) {
							vm.checkboxes.reset();
							vm.tableParams.reload();
							successHandling.report();
							if (disconnectedSelf)
								window.location.hash = "";
						},
						errorHandling.report);
			}
		}

		function Refresh() {
			$scope.$emit('reloadUserActivity');
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function () { }, function () { })
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function queryData(params) {
			vm.searchParams.startindex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();

			vm.searchParams.sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = k;
				vm.searchParams.ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.usersInfo;
				angular.forEach(vm.searchResults, function (user) {
					if (user.sessionStart) {
						user.sessionStart = new Date(user.sessionStart);
						var time = moment.duration(new Date() - user.sessionStart);
						user.timeHours = Math.floor(time.asHours());
						user.timeMinutes = Math.floor(time.asMinutes() - (user.timeHours * 60));
						user.timeSeconds = Math.floor(time.asSeconds() - ((user.timeMinutes + user.timeHours * 60) * 60));
						user.timeTranslate = $filter('translate')(user.timeHours ? 'SPOOL_TIME_HOURS' : 'SPOOL_TIME_NO_HOURS', { 
							hours: user.timeHours, minutes: user.timeMinutes, seconds: user.timeSeconds });
					}
					if (!user.lastAuthedUser) {
						user.lastAuthedUser = $filter('translate')('ANONYMOUS_USER');
					}
				});
				vm.searchResultCount = result.data.count;
				params.total(vm.searchResultCount);
				
				return vm.searchResults;
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.user; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.user];
			}
			var canDisable = canDisableSelectedUsers();
			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'dropConnections', click: dropConnections, translateKey: 'DROP_CONNECTIONS' },
				{ divider: true },
				{ key: 'disableUsers', click: disableUsers, translateKey: 'DISABLE', disabled: canDisable },
				{ divider: true },
				{ key: 'copyIp', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.ipAddress }, translateKey: "COPY_IP" },
				{ key: 'copyEmail', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.lastAuthedUser }, translateKey: "COPY_ADDRESS" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
