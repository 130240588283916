(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreLicensing", coreLicensing);

    function coreLicensing($rootScope, $http, $sessionStorage, $timeout) {
        var vm = this;
        vm.EDITION_UNSET = -1;
        vm.EDITION_ENTERPRISE = 0;
        vm.EDITION_PROFESSIONAL = 1;
        vm.EDITION_FREE = 2;

        // Functions
        vm.watchForChanges = watchForChanges;
        vm.reload = reload;
        vm.ensureLoaded = function () { /* NOOP, just to make it obvious why we're including the service in a controller */ }
        activate();

        //-----------------------------------------------
        function activate() {
            resetDefaults();
            vm.license = $sessionStorage.core_license;
            if (vm.license == null || vm.license.cache_expires == null || vm.license.cache_expires < new Date())
                loadLicensing();
            else
                fillLicense();
            $rootScope.$on("signalR.mailHub.client.licensingChanged", function () { loadLicensing(); });
        }

        function watchForChanges(scope, callbackOnChange) {
            if (!scope)
                return;

            if (scope.coreLicensingDeregisterer && typeof scope.coreLicensingDeregisterer === "function") {
                scope.coreLicensingDeregisterer();
                scope.coreLicensingDeregisterer = null;
            }

            scope.coreLicensingDeregisterer = scope.$watch(
                function () { return vm.license; },
                function () { callbackOnChange(); }
            );
        }

        function reload() {
            loadLicensing();
        }

        function resetDefaults() {
            vm.license = {
                version: "",
                edition: vm.EDITION_UNSET,
                hasEnterpriseFunctionality: false,
                hasEas: false,
                hasMapiEws: false
            };
            fillLicense();
        }

        function fillLicense() {
            vm.version = vm.license.version;
            vm.edition = +vm.license.edition;
            vm.hasEnterpriseFunctionality = !!vm.license.enterpriseFunctionality;
            vm.hasEas = !!vm.license.activeSyncEnabled;
            vm.hasMapiEws = !!vm.license.mapiEwsEnabled;
        }

        function loadLicensing() {
            return $http.get("~/api/v1/licensing/about")
                .then(onLoadSuccess, onLoadFailure);

            function onLoadSuccess(success) {
                vm.license = success.data;
                vm.license.cache_expires = generateExpiration();
                $sessionStorage.core_license = vm.license;
                fillLicense();
            }

            function onLoadFailure(failure) {
                delete $sessionStorage.core_license;
                resetDefaults();
                $timeout(loadLicensing, 1000 * 30); // try again in a little while
            }

            function generateExpiration() {
                var d = new Date();
                d.setMinutes(d.getMinutes() + 5);
                return d;
            }
        }
    }

})();