(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("userDataService", userDataService);

	function userDataService($rootScope, $http, $q, $log, claimsService, authStorage, errorHandling, apiCategories) {

		var vm = this;
		var defaultImgUrl = "/interface/img/ST_User_Cir.svg";

		vm.user = {
			_displayName: claimsService.getDisplayName(),
			_username: claimsService.getUsername(),
			_categories: [],
			_domain: claimsService.getDomain(),
			_emailAddress: claimsService.getEmailAddress(),
			_isPrimarySysAdmin: claimsService.isPrimarySysAdmin(),
			_isSysAdmin: claimsService.isSysAdmin(),
			_isDomainAdmin: claimsService.isDomainAdmin(),
			_settings: {},
			_pictureUrl: defaultImgUrl,

			get displayName() { return this._displayName; },
			set displayName(value) { claimsService.setDisplayName(value); this._displayName = value; },

			get username() { return this._username; },
			get domain() { return this._domain; },
			get emailAddress() { return this._emailAddress; },
			get isPrimarySysAdmin() { return this._isPrimarySysAdmin; },
			get isSysAdmin() { return this._isSysAdmin; },
			get isDomainAdmin() { return this._isDomainAdmin; },

			get categories() { return this._categories; },
			//set categories(value) { this._categories = value || []; },

			get settings() { return this._settings; },
			set settings(value) { this._settings = value || {}; },

			get pictureUrl() { return getProfilePicture(this._pictureUrl); },
			set pictureUrl(value) { this._pictureUrl = value; },
			refreshPictureUrl: function () { return loadProfilePicture(); }
		};

		var _favIconLoaded = false;
		var _isInitialized = false;
		vm.isInitialized = function () {
			return _isInitialized;
		};

		var initDefer;
		vm.init = function (onlyUserData) {
			var impersonatedTokens = authStorage.impersonationTokens();
			if (impersonatedTokens && authStorage.hasInitiatedImpersonation(impersonatedTokens.email) === false) {
				authStorage.deleteInitiatedImpersonation();
				authStorage.saveToken(impersonatedTokens, true, "user-data.service.init()");
				authStorage.deleteImpersonateToken();
				claimsService.setClaims(impersonatedTokens.accessToken, true);
			}

			// Get the domain Favicon
			if (!_favIconLoaded) {
				_favIconLoaded = true;

				//var domain = claimsService.getDomain();
				//if (domain.length > 0) {
				//    $http.get('~/api/v1/companyinfo/favicon/' + domain)
				//          .then(function (success) {
				//              if (success.data != null) { // The server has the Favicon so use this image
				//                  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
				//                  link.type = 'image/x-icon';
				//                  link.rel = 'shortcut icon';
				//                  link.href = stSiteRoot + 'api/v1/companyinfo/favicon/' + domain
				//                  document.getElementsByTagName('head')[0].appendChild(link);
				//              }
				//          }, function (failure) {
				//              // Do nothing for now
				//          });
				//} else {
				//    $http.get('~/api/v1/companyinfo/favicon/')
				//          .then(function (success) {
				//              if (success.data != null) { // The server has the Favicon so use this image
				//                  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
				//                  link.type = 'image/x-icon';
				//                  link.rel = 'shortcut icon';
				//                  link.href = stSiteRoot + 'api/v1/companyinfo/favicon/';
				//                  document.getElementsByTagName('head')[0].appendChild(link);
				//              }
				//          }, function (failure) {
				//              // Do nothing for now
				//          });
				//}
			}

			if (initDefer) return initDefer.promise;
			if (_isInitialized) return $q.when();

			initDefer = $q.defer();
			var promise = initDefer.promise;

			vm.user._displayName = claimsService.getDisplayName();
			vm.user._username = claimsService.getUsername();
			vm.user._domain = claimsService.getDomain();
			vm.user._emailAddress = claimsService.getEmailAddress();
			vm.user._isPrimarySysAdmin = claimsService.isPrimarySysAdmin();
			vm.user._isSysAdmin = claimsService.isSysAdmin();
			vm.user._isDomainAdmin = claimsService.isDomainAdmin();
			if (!vm.user._isSysAdmin)
				$rootScope.$broadcast("pageTitleUpdated", vm.user._emailAddress);

			var promises;
			if (onlyUserData === true) {
				promises = [loadUserData()];
			} else {
				promises = [loadUserData(), loadMasterCategories(), loadProfilePicture()];
			}
			$q.all(promises)
				.then(function () {
					_isInitialized = true;
					initDefer.resolve();
				}, function (response) {
					initDefer.reject(response);
				})
				.finally(function () {
					initDefer = null;
				});

			return promise;
		};

		vm.reset = function () {
			_favIconLoaded = false;
			_isInitialized = false;
			vm.user.settings = {};
			vm.admin = {};
		};

		function loadUserData() {
			if (vm.user._isSysAdmin === true) {
				return $http
					.get("~/api/v1/settings/sysadmin/logins")
					.then(function (result) {
						vm.admin = $.grep(result.data.systemLogins, function (admin) { return admin.username.toLowerCase() === vm.user.username.toLowerCase(); })[0];
						vm.admin.systemTimeZoneIndex = result.data.systemTimeZoneIndex;
						$rootScope.$broadcast("userSettings:retrieved");
					}, errorHandling.report);
			} else {
				return $http
					.get("~/api/v1/settings/user-startup-data")
					.then(function (response) {
						vm.user.settings = response.data;
						$rootScope.$broadcast("userSettings:retrieved");
						$rootScope.$on("categoriesUpdated", function () { vm.user._categories = apiCategories.getMasterCategories(); });
					});
			}
		};

		function loadMasterCategories() {
			if (vm.user._isSysAdmin === true) return $q.when();
			return apiCategories.init()
				.then(function () {
					vm.user._categories = apiCategories.getMasterCategories();
				});
		}

		var loadProfilePictureDefer;
		function loadProfilePicture() {
			if (vm.user._isSysAdmin === true) {
				vm.user.pictureUrl = defaultImgUrl;
				return $q.when();
			}

			if (loadProfilePictureDefer) return loadProfilePictureDefer.promise;

			loadProfilePictureDefer = $q.defer();

			$http
				.get("~/api/v1/contacts/gal-image-link?email=" + vm.user.emailAddress)
				.then(function (success) {
					if (success && success.data && success.data.imageLink) {
						vm.user.pictureUrl = "/" + success.data.imageLink + "&x=" + (Math.floor(Math.random() * 1000));
					} else {
						vm.user.pictureUrl = defaultImgUrl;
					}

					$rootScope.$broadcast("user-settings:changed");
					loadProfilePictureDefer.resolve(success);
					loadProfilePictureDefer = null;
				}, function (failure) {
					vm.user.pictureUrl = defaultImgUrl;
					loadProfilePictureDefer.resolve(failure);
					loadProfilePictureDefer = null;
				});

			return loadProfilePictureDefer.promise;
		};

		function getProfilePicture(avatarUrl) {
			var baseUrl = window.location.href.substring(0, window.location.href.indexOf("/interface/root#"));
			var retval = baseUrl + avatarUrl;
			if (avatarUrl === defaultImgUrl) {
				return retval;
			} else {
				retval = retval + '&' + Math.floor(Math.random() * 5000).toString();
			}
			return retval;
		};
	}

})();