(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("categoryColorPicker", ["coreDataCategories",categoryColorPicker]);
	// TODO: Change from mdMenu to a more generic mdPanel
	function categoryColorPicker(coreDataCategories, $mdPanel) {
		return {
			require: 'ngModel',
			replace: true,
			restrict: "E",
			link: function (scope, element, attrs, ngModel) {
				scope.colorPanel = $mdPanel;
				scope.selectedColor = coreDataCategories.getCategoryColor(-1);
				scope.$watch(() => ngModel.$viewValue, 
					(val) => {
						scope.selectedColor = coreDataCategories.getCategoryColor(val);
					});
				scope.selectedColor = coreDataCategories.getCategoryColor(ngModel.$viewValue);
				scope.availableColors = coreDataCategories.categoryColors;

				scope.openMenu = function($mdMenu, ev) {
					$mdMenu.open(ev);
				};
				scope.clickColorTile = function(color, ev) {
					ngModel.$setViewValue(color.value);
				};

			},
			template: 
				'<md-menu md-position-mode="target-left bottom" aria-label="select color">' +
				'<div layout layout-align="end" style="cursor: pointer; width: 40px" ng-click="openMenu($mdMenu, $event)">' +
						`<div class='category-tag'><st-category-flag flag-color="selectedColor.rgb"></st-category-flag></div>` +
						'<div class="st-select-icon">&#x25BC;</div>' +
					'</div>' +
					'<md-menu-content  class="st-color-picker" width="4" style="min-height: 312px; overflow:hidden;"><md-menu-item>' +
						'<md-grid-list md-cols="5" md-row-height="1:1" md-gutter="1px">' +
						'<md-grid-tile ng-repeat="color in availableColors" ng-style="{\'background\': color.rgb }" md-colspan="{{color.value < 0 ? 5 : 1}}" ng-click="clickColorTile(color, $event)" ng-row>' +
						'<md-grid-tile-footer class="md-body-2" ng-if="color.value < 0" layout-align="center center">{{color.name | translate}}</md-grid-tile-footer>' +
						' </md-grid-tile>' +
						'</md-grid-list>' +
					'</md-content></md-menu-item></md-menu-content>' +
				'</md-menu>'
		};
	}
})();