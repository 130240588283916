(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysadminAntiSpamOptionsController", sysadminAntiSpamOptionsController);

	function sysadminAntiSpamOptionsController($rootScope, $filter, $http, $q, $mdDialog,
		coreDataFileStorage, coreDataSysAdminSettings, errorHandling, successHandling, i18n) {

		var vm = this;
		vm.settings = {};
		vm.spamAssassinServers = [];
		vm.rspamdServers = [];
		vm.maxMessageSizeToContentScanInput = 0;

		// Functions
		vm.save = save;
		vm.exportSpamSettings = exportSpamSettings;
		vm.importSpamSettings = importSpamSettings;
		vm.startSpamWizard = startSpamWizard;
		vm.editFilterLevel = editFilterLevel;
		vm.newSpamAssassinServer = newSpamAssassinServer;
		vm.newRspamdServer = newRspamdServer;
		vm.editSpamAssassinServer = editSpamAssassinServer;
		vm.editRspamdServer = editRspamdServer;
		vm.openTrustedDomains = openTrustedDomains;
		vm.openTrustedEmails = openTrustedEmails;
		vm.doCancel = doCancel;

		activate();

		////////////////////////

		function activate() {
			refresh();
		}

		function refresh() {
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/antispam/settings").then(onSpamSettingsLoaded, function () {}),
				$http.get("~/api/v1/settings/sysadmin/spam-assassin-servers").then(onSpamAssassinServersLoaded, function () { }),
				$http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailSettingsLoaded, function () { }),
				$http.get("~/api/v1/settings/sysadmin/rspamd-servers").then(onRspamdServersLoaded, function () { }),
			];

			$rootScope.spinner.show();
			$q
				.all(promises)
				.finally($rootScope.spinner.hide);

			function onSpamAssassinServersLoaded(result) {
				vm.spamAssassinServers = result.data.spamAssassinServers || [];
			}

			function onSpamSettingsLoaded(result) {
				vm.settings = result.data.settings;
				vm.maxMessageSizeToContentScanInput = parseInt(vm.settings.maxMessageSizeToContentScan / 1024, 10);
				vm.spamLevels = {
					"SPAM_FILTERING_LOW_PROBABILITY": vm.settings.spamLevelLow,
					"SPAM_FILTERING_MED_PROBABILITY": vm.settings.spamLevelMedium,
					"SPAM_FILTERING_HIGH_PROBABILITY": vm.settings.spamLevelHigh
				};
				vm.privateSuffixList = vm.settings.privateSuffixList;
			}

			function onRspamdServersLoaded(result) {
				vm.rspamdServers = result.data.rspamdServers || [];
			}

			function onGlobalMailSettingsLoaded(result) {
				var data = result.data.globalMailSettings.emailWhitelist;

				vm.trustedDomains = [];
				angular.forEach(data.domains || [], function (value) {
					vm.trustedDomains.push(value.value);
				});

				vm.trustedEmails = [];
				angular.forEach(data.emailAddresses || [], function (value) {
					vm.trustedEmails.push(value.value);
				});
			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			$rootScope.spinner.show();

			// outgoingQuarantineEnabled: vm.settings.outgoingQuarantineEnabled, // No interface?
			var params = JSON.stringify({

				allowDomainOverride: vm.settings.allowDomainOverride,
				allowSystemResponsesToCatchallMessages: vm.settings.allowSystemResponsesToCatchallMessages,
				incomingBlockingEnabled: vm.settings.incomingBlockingEnabled,
				incomingBlockThreshold: vm.settings.incomingBlockThreshold,
				outgoingBlockingEnabled: vm.settings.outgoingBlockingEnabled,
				outgoingBlockingThreshold: vm.settings.outgoingBlockingThreshold,
				outgoingQuarantineAction: vm.settings.outgoingQuarantineAction,
				maxMessageSizeToContentScan: vm.maxMessageSizeToContentScanInput * 1024,
				allowBouncesForOutgoingBlockedItems: vm.settings.allowBouncesForOutgoingBlockedItems,
				useSpoolProcFolder: vm.settings.useSpoolProcFolder,
				enableDmarcPolicy: vm.settings.enableDmarcPolicy,
				dmarcQuarantineWeight: vm.settings.dmarcQuarantineWeight,
				copySpamHamToFolders: vm.settings.copySpamHamToFolders,
				providerReportSpamHam: vm.settings.providerReportSpamHam,
				autoResponseRestrictionLevel: vm.settings.autoResponseRestrictionLevel,
				contentFilterBounceRestrictionLevel: vm.settings.contentFilterBounceRestrictionLevel,
				spamLevelLow: vm.settings.spamLevelLow,
				spamLevelMedium: vm.settings.spamLevelMedium,
				spamLevelHigh: vm.settings.spamLevelHigh,
				greylisting: vm.settings.greylisting,
				spf: vm.settings.spf,
				maxSpamThreads: vm.settings.maxSpamThreads,
				privateSuffixList: vm.privateSuffixList !== vm.settings.privateSuffixList ? vm.privateSuffixList : null
			});

			$http.post("~/api/v1/settings/sysadmin/antispam/settings", params)
				.then(onSpamSettingsSaveSuccess, errorHandling.report);

			function onSpamSettingsSaveSuccess() {
				var params2 = JSON.stringify({ domains: vm.trustedDomains, emails: vm.trustedEmails });
				$http
					.post("~/api/v1/settings/sysadmin/whitelist", params2)
					.then(onSaveSuccess, errorHandling.report)
					.finally(function () { $rootScope.spinner.hide(); });
			}

			function onSaveSuccess() {
				form.$setPristine();
			}
		}

		function editFilterLevel(event, form, title, item) {
			$mdDialog
				.show({
					locals: {
						title: title,
						actionType: item.action,
						argument: item.actionData,
						weight: item.weight,
						hideMoveToJunk: false
					},
					controller: "editSpamFilterActionDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/spam-filter-action.dlg.html",
					targetEvent: event
				})
				.then(function (modalSuccess) {
					item.action = modalSuccess.actionTypeName;
					item.actionData = modalSuccess.argument;
					item.weight = modalSuccess.weight;
					form.$setDirty();
				}, function () { });
		}

		function newSpamAssassinServer(ev) {
			$mdDialog.show({
				locals: {
					name: "",
					address: "",
					port: ""
				},
				controller: "sysAdminEditSpamAssassinServerController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/antispam/modals/spam-assassin-server.dlg.html",
				targetEvent: ev
			})
				.then(onModalSuccess, function () { });

			function onModalSuccess(modalSuccess) {
				var params = JSON.stringify({
					toAdd: [{
						spamAssassinServerName: modalSuccess.name,
						spamAssassinIP: modalSuccess.address,
						spamAssassinPort: modalSuccess.port
					}],
					toRemove: [modalSuccess.address]
				});
				saveServers(params);
			}
		}

		function editSpamAssassinServer(ev, selectedCard, form) {
			$mdDialog
				.show({
					locals: {
						name: selectedCard.spamAssassinServerName,
						address: selectedCard.spamAssassinIP,
						port: selectedCard.spamAssassinPort
					},
					controller: "sysAdminEditSpamAssassinServerController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/settings/antispam/modals/spam-assassin-server.dlg.html",
					targetEvent: ev
				})
				.then(onModalSuccess, function () { });

			function onModalSuccess(modalSuccess) {
				if (modalSuccess.doDelete) {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_one"))
						.targetEvent(event);
					$mdDialog.show(confirm).then(function () {
						var params = JSON.stringify({
							toRemove: [selectedCard.spamAssassinIP]
						});
						saveServers(params);
					}, null);
				} else {
					var params = JSON.stringify({
						toAdd: [{
							spamAssassinServerName: modalSuccess.name,
							spamAssassinIP: modalSuccess.address,
							spamAssassinPort: modalSuccess.port
						}],
						toRemove: [selectedCard.spamAssassinIP]
					});
					saveServers(params);
				}
			}
		}

		function saveServers(params) {
			if (!params) return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/spam-assassin-servers", params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				refreshSpamAssassinServers();
			}
		}

		function refreshSpamAssassinServers() {
			$http.get("~/api/v1/settings/sysadmin/spam-assassin-servers").then(onSpamAssassinServersLoaded);
			function onSpamAssassinServersLoaded(result) {
				vm.spamAssassinServers = result.data.spamAssassinServers || [];
			}
		}

		function newRspamdServer(ev) {
			$mdDialog.show({
				locals: {
					cardData: null
				},
				controller: "sysAdminEditRspamdServerController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/antispam/modals/rspamd-server.dlg.html",
				targetEvent: ev
			}).then(onModalSuccess, function () { });

			function onModalSuccess(modalSuccess) {
				var params = JSON.stringify({
					toAdd: [{
						rspamdServerName: modalSuccess.rspamdServerName,
						httpBaseAddress: modalSuccess.httpBaseAddress,
						httpCheckAddress: modalSuccess.httpCheckAddress,
						httpSpamAddress: modalSuccess.httpSpamAddress,
						httpHamAddress: modalSuccess.httpHamAddress
					}],
					toRemove: [modalSuccess.httpBaseAddress]
				});
				saveRspamdServers(params);
			}
		}

		function editRspamdServer(ev, selectedCard, form) {
			$mdDialog.show({
				locals: {
					cardData: selectedCard,
				},
				controller: "sysAdminEditRspamdServerController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/antispam/modals/rspamd-server.dlg.html",
				targetEvent: ev
			})
			.then(onModalSuccess, function () { });

			function onModalSuccess(modalSuccess) {
				if (modalSuccess.doDelete) {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_one"))
						.targetEvent(event);
					$mdDialog.show(confirm).then(function () {
						var params = JSON.stringify({
							toRemove: [selectedCard.httpBaseAddress]
						});
						saveRspamdServers(params);
					}, null);
				} else {
					var params = JSON.stringify({
						toAdd: [{
							rspamdServerName: modalSuccess.rspamdServerName,
							httpBaseAddress: modalSuccess.httpBaseAddress,
							httpCheckAddress: modalSuccess.httpCheckAddress,
							httpSpamAddress: modalSuccess.httpSpamAddress,
							httpHamAddress: modalSuccess.httpHamAddress
						}],
						toRemove: [selectedCard.httpBaseAddress]
					});
					saveRspamdServers(params);
				}
			}
		}

		function saveRspamdServers(params) {
			if (!params) return;

			$rootScope.spinner.show();
			$http.post("~/api/v1/settings/sysadmin/rspamd-servers", params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				refreshRspamdServers();
			}
		}

		function refreshRspamdServers() {
			$http.get("~/api/v1/settings/sysadmin/rspamd-servers").then(onRspamdServersLoaded);
			function onRspamdServersLoaded(result) {
				vm.rspamdServers = result.data.rspamdServers || [];
			}
		}

		function startSpamWizard(ev) {
			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_RESET_SPAM_SETTINGS"))
				.ok($filter("translate")("RESET"))
				.targetEvent(ev);
			$mdDialog.show(confirm)
				.then(onSpamWizardSuccess, function () { });

			function onSpamWizardSuccess() {
				$http
					.post("~/api/v1/settings/sysadmin/load-spam-defaults")
					.then(onSaveSuccess, errorHandling.report);

				function onSaveSuccess() {
					successHandling.report();
					refresh();
					signalCountChanged();
				}
			}
		}

		function signalCountChanged() {
			$rootScope.$broadcast("antispam:counts-changed");
		}

		function exportSpamSettings() {
			var httpPath = "~/api/v1/settings/sysadmin/export-spam-settings";
			var fileName = $filter("translate")("FILENAME_SPAM_CONFIG") + ".json";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(httpPath, fileName)
				.then(onExportSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onExportSuccess() { }
		}

		function importSpamSettings(ev) {
			$mdDialog
				.show({
					locals: {},
					controller: "sysAdminImportspamSettingsController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/settings/antispam/modals/import-spam-settings.dlg.html",
					targetEvent: ev
				})
				.then(function () {
					refresh();
					signalCountChanged();
				});
		}

		function openTrustedDomains(ev, form) { openTrustedSenders(ev, form, false); }

		function openTrustedEmails(ev, form) { openTrustedSenders(ev, form, true); }

		function openTrustedSenders(ev, form, storeInEmails) {
			$mdDialog
				.show({
					locals: { trustedSenders: storeInEmails ? vm.trustedEmails : vm.trustedDomains, isAddresses: storeInEmails },
					controller: "trustedSenderDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/trusted-senders.dlg.html",
					targetEvent: ev
				})
				.then(onAdditionalInfoOk, function () { });

			function onAdditionalInfoOk(modalSuccess) {
				var filteredItems = $.grep(modalSuccess, function (value) {
					var trimmed = (value || "").trim();
					return trimmed;
				});
				if (storeInEmails)
					vm.trustedEmails = filteredItems;
				else
					vm.trustedDomains = filteredItems;
				form.$setDirty();
			}
		}
		vm.openDomainSuffixList = function(event, form) {
			$mdDialog.show({
					locals: { extensions: angular.copy(vm.privateSuffixList), title: "CUSTOM_DOMAIN_SUFFIXES", isReadOnly: false },
					controller: "extensionBlacklistDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
					targetEvent: event
				})
				.then(function (modalSuccess) {
					vm.privateSuffixList = modalSuccess.extensions;
					form.$setDirty();
				}, function () { });
		}
	}

})();
