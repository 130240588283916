(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("listEditDialogController", listEditDialogController);

	function listEditDialogController($rootScope, $scope, $mdDialog, items, title, readonly) {
		$scope.textValue = "";
		$scope.items = items || [];
		$scope.title = title || "";
		$scope.readonly = readonly;
        $scope.items.sort();
		$scope.textValue = $scope.items.join("\r\n");
		
		$scope.save = function () {
			var arr = $scope.textValue.split(/\r?\n/);
			arr = arr.filter(function (ext) {
				return ext != "";
			});
			$mdDialog.hide({ items: arr });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
