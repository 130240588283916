(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("createTaskFolderController", createTaskFolderController);

    function createTaskFolderController($mdDialog, $http, errorHandling) {
        var vm = this;
        vm.name = "";
        vm.color = "#C6DE70";

        // Functions
        vm.save = save;
        vm.cancel = cancel;

        activate();

        //////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, taskFolderForm) {
            if (vm.name === "")
                return;
            if (taskFolderForm.$invalid)
                return;

            try {
                const folder = { folder: vm.name, color: vm.color };
                const success = await $http.post("~/api/v1/tasks/sources/add", folder);
                vm.id = success.data.id;
                $mdDialog.hide();
            }
            catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();
