/*
 * Use Examples:
 * HTML:
 * <st-data-grid grid-list-id="vListManageSpool" card-type="spool-manage-all" selected-cards="spoolCore.selectedCards" total-count="spoolCore.totalFilteredCount" grid-columns="gridColumns" 
 * sort-field="spoolCore.sortField" is-descending="spoolCore.isDescending" list-data-cache="spoolCore.listDataCache" list-controller="spoolCore.listController" list-data-provider="spoolCore.listDataProvider"
 * select-all="spoolCore.selectAll" on-sorting-changed="spoolCore.onSortingChanged"></st-data-grid>
 * 
 * <st-data-grid grid-list-id="vListIdsAll" card-type="ids-all" selected-cards="idsCore.selectedCards" total-count="idsCore.totalFilteredCount" grid-columns="gridColumns"
 *	sort-field="idsCore.sortField" is-descending="idsCore.isDescending" list-data-cache="idsCore.listDataCache" list-controller="idsCore.listController" list-data-provider="idsCore.listDataProvider"
 *	select-all="idsCore.selectAll" on-sorting-changed="idsCore.onSortingChanged"></st-data-grid>
 * 
 * gridColumns:
 * $scope.gridColumns = [
 * 	 { sortField: 'fileName', translate: 'FILENAME' },
 * 	 { sortField: 'shortSpool', translate: 'SPOOL_PATH' },
 * 	 { sortField: 'from', translate: 'SENDER' },
 * 	 { sortField: 'deliveredratio', translate: 'RECIPIENTS' },
 * 	 { sortField: 'size', translate: 'SIZE' }
 * ];
 * 
 */

(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("stDataGrid", stDataGrid);

	function stDataGrid($compile, $interval) {
			return {
				restrict: 'EA',
				scope: {
					gridListId: "@", //the id given to the virtual list component.
					cardType: "@", //the name of the card type to use for the virtual list.
					selectedCards: "=", //A reference to the array to store the selected rows.
					totalCount: "=", //A total count of items that are in the data for the grid.
					gridColumns: "=", //The columns to use in the table.
					sortField: "=", //Model for the current field to sort by.
					isDescending: "=", //Model for whether the sort is descending or not.
					listDataCache: "=", //The list data cache for the virtual list.
					listController: "=", //The list controller for the virtual list.
					listDataProvider: "=", //The list data provider for the virtual list.
					selectAll: "=", //Callback for select all.
					onSortingChanged: "=" //Callback for on sort change.
				},
				link: function (scope, element, attr) {
					//$(element).uniqueId();
					var elementToBeAdded = angular.element(
						'<div class="st-data-grid-table">\
							<div class="st-data-grid-table-row st-data-grid-table-top-row">\
								<div class="st-data-grid-table-cell st-data-grid-table-select-cell">\
									<div class="st-data-grid-table-select-button" ng-click="selectAll()" ng-class="{\'st-data-grid-selected\': selectedCards.length === totalCount && totalCount != 0}"><div></div></div>\
								</div>\
								<div ng-repeat="row in gridColumns" class="st-data-grid-table-cell">\
									<span ng-click="onSortingChanged(row.sortField)" ng-class="{\'st-data-grid-sorting\': sortField == row.sortField}">\
										{{row.translate | translate}}\
										<i class="toolsicon toolsicon-arrow_drop_down_emphasis" ng-class="{\'st-data-grid-ascending\': isDescending}" ng-show="sortField == row.sortField"></i>\
									</span>\
								</div>\
							</div>\
							<div id="st-grid-table-list-area">\
								<div class="st-data-grid-no-info" ng-show="totalCount < 1">{{\'NO_ITEMS_TO_SHOW\' | translate}}</div>\
								<virtual-list id="' + scope.gridListId + '" card-type="' + scope.cardType + '" parent-id="st-grid-table-list-area" list-data-provider="listDataProvider(queueObject)" list-data-cache="listDataCache" list-controller="listController" total-items="totalCount" height="45" width="-1"></virtual-list>\
							</div>\
						</div>'
					);

					var elementCompiled = $compile(elementToBeAdded)(scope);
					element.append(elementCompiled);
				}
			}
		}

})();