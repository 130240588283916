(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateUserExists", validateUserExists);

	function validateUserExists($http, $q) {
	    return {
	        require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateUserExists = function (modelValue, viewValue) {
					if (!viewValue) return $q.when(true);
					var defer = $q.defer();
					$http.post('~/api/v1/settings/sysadmin/user-exists', JSON.stringify({ email: viewValue }))
						.then(function (success) {
							defer.resolve();
						}, function (failure) {
							defer.reject(failure);
						});

					return defer.promise;
	            };
	        }
	    };
	}
})();