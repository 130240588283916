(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateFullyQualifiedPath", validateFullyQualifiedPath);

    function validateFullyQualifiedPath($http, $q) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$asyncValidators.validateFullyQualifiedPath = function (modelValue, viewValue) {
                    if (!viewValue) return $q.when(true);

                    let preValidation = window.stOS == 'L' ? validateFullyQualifiedPathLinux(viewValue) : validateFullyQualifiedPathWindows(viewValue);
                    if (!preValidation) {
                        var defer = $q.defer();
                        defer.reject();
                        return defer.promise;
                    }

                    return $http.post('~/api/v1/settings/sysadmin/validate-path', { input: viewValue })
                };
            }
        };

        function validateFullyQualifiedPathLinux(viewValue) {
            // Ensure folder starts with / or ~/
            if (!/^(\/|~\/)/.test(viewValue))
                return false;

            // Ensure folder does not only contain / or ~/
            if (/^(\/|~\/)$/.test(viewValue))
                return false;

            // Check each part to make sure it doesn't have invalid characters
            let parts = viewValue.split('/');
            for (var i = 0; i < parts.length; ++i) {
                let part = parts[i];
                if (i == 0 && (part == '~' || part == ''))
                    continue;

                if (part.length == 0 && i != parts.length - 1)
                    return false;
                if (part.startsWith("."))
                    return false;
                let regexAllowedChars = /^[a-zA-Z0-9._-]*$/;
                if (!regexAllowedChars.test(parts[i]))
                    return false;
            }

            return true;
        }

        function validateFullyQualifiedPathWindows(viewValue) {
            if (!(/((^[a-zA-Z]:\\)|(^\\\\[^\\]))/).test(viewValue))
                return false;
            if ((/((^[a-zA-Z]:\\$)|(^\\\\[^\\]$))/).test(viewValue))
                return false;
            return true;
        }

    }
})();