(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("modularConfigurationModalController", modularConfigurationModalController);

    modularConfigurationModalController.$inject = [
        "$mdDialog",
        "$rootScope",
        "$translate",
        "$http",
        "errorHandling",
        "addonName",
        "enabled",
        "alreadyConfigured",
        "domainAdminCanOverride",
        "successHandling",
        "localHelpLink"
    ];

    function modularConfigurationModalController(
        $mdDialog,
        $rootScope,
        $translate,
        $http,
        errorHandling,
        addonName,
        enabled,
        alreadyConfigured,
        domainAdminCanOverride,
        successHandling,
        localHelpLink) {
        const vm = this;
        vm.helpLink = localHelpLink;
        vm.initialized = false;
        vm.addonName = addonName; // "owncloud" (passed in or set as needed)
        vm.activeSections = [];
        vm.addonEnabled = enabled;
        vm.errorMessage = null;
        vm.cancel = cancel;
        vm.save = save;
        vm.alreadyConfigured = alreadyConfigured;
        vm.disableOwncloudConnection = disableOwncloudConnection;
        vm.domainAdminCanOverride = domainAdminCanOverride;
        vm.closeRefresh = closeRefresh;
        vm.needsConfig = true;
        vm.chatGptCannotOverride = true;
        vm.close = close;
        vm.testRocketChatToken = testRocketChatToken;
        vm.disableControls = false;
        vm.ChatGptuserSettings = null;
        vm.selectedChatProvider = null;
        vm.rocketChatServerAddress = null;
        vm.personalAccessTokenEncrypted = null;
        vm.serviceUserId = null;
        vm.rocketChatTestFailed = false;
        


        async function activate() {
            if (vm.addonName === "onlyoffice" || vm.addonName === "collabora") {
                vm.alreadyConfigured = false;
            }
            if (vm.addonName.toLowerCase() !== "chatgpt") {
                await initializeOwnCloudSettings();
                vm.initialized = true;
                $rootScope.$applyAsync();
            } else {
                vm.title = $translate.instant("CHATGPT")
                await CheckIfChatGPTCanOverRide();
                if (!vm.chatGptCannotOverride) {
                    await initializeOwnCloudSettings();
                    vm.initialized = true;
                    $rootScope.$applyAsync();
                }
            }
        }

        async function CheckIfChatGPTCanOverRide() {
            try {
                const response = await $http.post("/api/v1/marketplace/GetChatGPTSettingsAdmin", {});
                const settings = response.data;
                vm.chatGptCannotOverride = !settings.usersCanOverrideApiKey;
                vm.addonEnabled = settings.enabled || false;
            } catch (error) {
                console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                vm.errorMessage = $translate.instant("FETCH_SETTINGS_FAILED");
            }
        }


        async function initializeOwnCloudSettings() {
            if (await isOwncloudConnected() && vm.addonName.toLowerCase() === "owncloud") {
                vm.alreadyConfigured = true;
            }
            vm.activeSections = [];
            switch (vm.addonName.toLowerCase()) {
                case "chatgpt":
                    vm.activeSections = [
                        {
                            title: $translate.instant("CHATGPT"),
                            needsConfig: true,
                            settings: [
                                {
                                    key: "overrideApiKey",
                                    type: "switch",
                                    label: $translate.instant("AI_OVERRIDE"),
                                    value: ""
                                },
                                {
                                    key: "aiModel",
                                    type: "modelSelect",
                                    label: $translate.instant("AI_MODEL"),
                                    value: 0,
                                    options: [
                                        {
                                            key: 0,
                                            translation: $translate.instant("GPT-4O-MINI")
                                        },
                                        {
                                            key: 1,
                                            translation: $translate.instant("GPT-4O")
                                        },
                                        {
                                            key: 3,
                                            translation: $translate.instant("O3-MINI")
                                        },
                                        {
                                            key: 3,
                                            translation: $translate.instant("O1-MINI")
                                        }
                                    ]
                                },
                                {
                                    key: "apiKey",
                                    type: "password",
                                    label: $translate.instant("AI_OPENAI_KEY"),
                                    value: ""
                                },
                                {
                                    key: "maxMessagesPerDay",
                                    type: "input_number",
                                    label: $translate.instant("MAX_REQUESTS_PER_DAY_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    const modelSetting = vm.activeSections[0].settings.find(s => s.key === "aiModel");

                    modelSetting.models = modelSetting.options;
                    modelSetting.value = modelSetting.value || modelSetting.options[0].key; // Initialize with a default value if not already set

                    vm.models = modelSetting.options;
                    fetchSettings();
                    break;
                case "owncloud":

                    vm.activeSections = [
                        {
                            title: $translate.instant("OWNCLOUD"),
                            needsConfig: true,
                            settings: [
                                {
                                    key: "name",
                                    type: "input",
                                    label: $translate.instant("DISPLAY_NAME"),
                                    value: ""
                                },
                                {
                                    key: "url",
                                    type: "url",
                                    label: $translate.instant("SERVER_ADDRESS"),
                                    value: ""
                                },
                                {
                                    key: "username",
                                    type: "input",
                                    label: $translate.instant("USERNAME"),
                                    value: ""
                                },
                                {
                                    key: "password",
                                    type: "password",
                                    label: $translate.instant("PASSWORD"),
                                    value: ""
                                }
                            ]
                        }
                    ];
                    break;
                case "collabora":
                    vm.activeSections = [{
                        title: $translate.instant("COLLABORA"),
                        needsConfig: false
                    }
                    ];
                    break;
                case "onlyoffice":
                    vm.activeSections = [{
                        title: $translate.instant("ONLYOFFICE"),
                        needsConfig: false
                    }];
                    break;
                case "rocketchat":
                    GetChatProviderSettings(1);
                    vm.activeSections = [{
                        title: $translate.instant("ROCKETCHAT"),
                        needsConfig: true,
                        settings: [
                            {
                                key: "enabledRocketChat",
                                type: "switch",
                                label: $translate.instant("ENABLE"),
                                value: ""
                            },
                            {
                                key: "serverUrl",
                                type: "url",
                                label: $translate.instant("ROCKETCHAT_SERVERURL"),
                                value: ""
                            },
                            {
                                key: "serviceUserId",
                                type: "input",
                                label: $translate.instant("ROCKETCHAT_USERID"),
                                value: ""
                            },
                            {
                                key: "personalAccessTokenEncrypted",
                                type: "password",
                                label: $translate.instant("PERSONAL_ACCESS_TOKEN"),
                                value: ""
                            }
                        ]
                    }]
                    break;
                case "smartermailchat":
                    vm.activeSections = [{
                        title: $translate.instant("SMARTERMAILCHAT"),
                        needsConfig: false,
                        settings: []
                    }]
                    break;
                case "smartermailfilestore":
                    vm.activeSections = [{
                        title: $translate.instant("SMARTERMAILFILESTORE"),
                        needsConfig: false,
                        settings: []
                    }]
                    break;
                case "smartermailonlinemeetings":
                    vm.activeSections = [{
                        title: $translate.instant("SMARTERMAILONLINEMEETINGS"),
                        needsConfig: false,
                        settings: []
                    }]
                    break;
            }
            try {
                vm.title = vm.activeSections[0].title;
                vm.needsConfig = vm.activeSections[0].needsConfig;
            } catch (e) {
                //prob collabora or Onlyoffice
            }
        }

        function fetchSettings() {
            var apiUrl;
            switch (vm.addonName.toLowerCase()) {
                case "chatgpt":
                    apiUrl = "/api/v1/marketplace/GetChatGPTSettingsAdmin";
                    break;
                default:
                    console.warn(`No settings endpoint defined for addon: ${vm.addonName}`);
                    return;
            }

            $http.post(apiUrl, {})
                .then(function (response) {
                    var settings = response.data;
                    if (settings.usersCanOverrideApiKey) {
                        fetchUserSettings();
                    } else {
                        vm.chatGptCannotOverride = true;
                        vm.addonEnabled = settings.enabled || false;
                    }
                })
                .catch(function (error) {
                    console.error(`Failed to fetch settings for addon: ${vm.addonName}`, error);
                    vm.errorMessage = $translate.instant("FETCH_SETTINGS_FAILED");
                });

            function fetchUserSettings() {
                var userApiUrl = "/api/v1/marketplace/GetChatGPTSettingsUser";
                
                $http.post(userApiUrl, {})
                    .then(function (response) {
                        toggleOverride();
                        var userSettings = response.data;
                        vm.ChatGptuserSettings = userSettings;
                        vm.addonEnabled = userSettings.enabled || false;
                        populateSettings(userSettings);
                    })
                    .catch(function (error) {
                        console.error("Failed to fetch user-specific settings", error);
                        vm.errorMessage = $translate.instant("FETCH_USER_SETTINGS_FAILED");
                    });
            }
        }


        function toggleOverride() {
            if (vm.addonName.toLowerCase() === "chatgpt") {
                if (vm.activeSections.length && vm.activeSections[0].settings) {
                    var overrideSetting = vm.activeSections[0].settings.find((s) => s.key === "overrideApiKey");
                    if (overrideSetting) {
                        $rootScope.$watch(
                            () => overrideSetting.value,
                            function (newValue, oldValue) {
                                if (newValue !== oldValue) {
                                    vm.disableControls = !newValue;
                                }
                            }
                        );
                    }
                }
            }
        }




        function populateSettings(settings) {
            vm.activeSections.forEach(function (section) {
                section.settings.forEach(function (setting) {
                    if (settings.hasOwnProperty(setting.key)) {
                        setting.value = settings[setting.key] !== undefined ? settings[setting.key] : "";
                    }
                });
            });
            vm.disableControls = !settings.overrideApiKey;
        }

        function closeRefresh() {
            $rootScope.$broadcast("refresh");
            $mdDialog.cancel();
        }

        // Check if OwnCloud is connected
        async function isOwncloudConnected() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        return response.data.connectedService.some(service => service.type === 10);
                    }
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Obtain the existing OwnCloud service ID
        async function getOwncloudID() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        const svc = response.data.connectedService.find(s => s.type === 10);
                        return svc ? svc.id : null;
                    }
                    return null;
                })
                .catch(function () {
                    return null;
                });
        }

        async function disableOwncloudConnection() {
            const id = await getOwncloudID();
            if (!id) return false;

            const data = {serviceIDs: [id]};
            await $http.post("/api/v1/settings/connected-services-delete", data)
                .then(function (response) {
                    if (response.data.success) {
                        closeRefresh();
                        return true;
                    }
                    return false;
                }, function () {
                    errorHandling.report("ERROR_DELETING_CLOUD_STORAGE_CONNECTION");
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }


        function GetChatProviderSettings(type) {
            $http.post("/api/v1/marketplace/GetChatProviderSettings")
                .then(
                    function (response) {
                        switch (type) {
                            case 1:
                                response.data["enabledRocketChat"] = response.data["providerType"] === 1;
                                console.log(response.data);
                                populateSettings(response.data);
                                
                                vm.selectedChatProvider = response.data.selectedChatProvider;
                                vm.rocketChatServerAddress = response.data.serverUrl;
                                vm.personalAccessTokenEncrypted = response.data.personalAccessTokenEncrypted;
                                vm.enabledRocketChat = response.data.enabledRocketChat;
                                vm.serviceUserId = response.data.serviceUserId;
                                break;
                        }
                    }).catch(function (error) {
            })
        }

        // Attempt to verify or save OwnCloud settings
        function checkOwncloudSettings(settings) {
            const testParams = {
                url: settings.ownCloud.url,
                username: settings.ownCloud.username,
                password: settings.ownCloud.password,
                name: settings.ownCloud.name
            };
            $http.post("/api/v1/settings/owncloud-test", testParams)
                .then((result) => {
                    if (!result.data.success) {
                        errorHandling.report("CHECK_DETAILS");
                    } else {
                        $mdDialog.hide(testParams);
                    }
                })
                .catch(() => {
                    errorHandling.report("CHECK_DETAILS");
                });
        }

        function processSectionSettings(settings, section, key) {
            section.settings.forEach(field => {
                settings[key][field.key] = field.value;
            });
            // Save/verify
            checkOwncloudSettings(settings);
        }

        function save() {
            let settings = {};
            if (vm.addonName.toLowerCase() === "chatgpt") {
                settings = {
                    enabled: vm.addonEnabled 
                };

                vm.activeSections.forEach(section => {
                    section.settings.forEach(setting => {
                        if (["overrideApiKey", "aiModel", "apiKey", "maxMessagesPerDay"].includes(setting.key)) {
                            if (setting.key === "maxMessagesPerDay") {
                                settings[setting.key] = Number(setting.value) || 0;
                            } else {
                                settings[setting.key] = setting.value;
                            }
                        }
                    });
                });

                $http.post("/api/v1/marketplace/SetChatGPTSettingsUser", settings)
                    .then(
                        function (response) {
                            $mdDialog.hide(response.data);
                        },
                        function (error) {
                            errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                        }
                    )
                    .catch(function (error) {
                        console.error("Error saving ChatGPT settings:", error);
                        errorHandling.handleError(error);
                    });
            } else if (vm.addonName.toLowerCase() === "owncloud") {
                settings = {
                    ownCloud: {
                        enabled: vm.addonEnabled
                    }
                };

                vm.activeSections.forEach(section => {
                    if (section.title === $translate.instant("OWNCLOUD")) {
                        processSectionSettings(settings, section, "ownCloud");
                    }
                });
            } else if (vm.addonName.toLowerCase() === "rocketchat") {
                settings = {};
                vm.activeSections.forEach(section => {
                    if (section.title === $translate.instant("ROCKETCHAT")) {
                        section.settings.forEach(setting => {
                            if (["enabledRocketChat", "serverUrl", "serviceUserId", "personalAccessTokenEncrypted"].includes(setting.key)) {
                                if (vm.personalAccessTokenEncrypted !== section.settings[3].value) {
                                    settings.personalAccessToken = settings.personalAccessTokenEncrypted
                                    delete settings.personalAccessTokenEncrypted;
                                }
                                if (setting.key === "enabledRocketChat") {
                                    // Set providerType based on the enabledRocketChat switch
                                    settings.providerType = setting.value ? 1 : 0;
                                } else {
                                    settings[setting.key] = setting.value;
                                }
                            }
                        });
                    }
                });

                $http.post("/api/v1/marketplace/SetChatProviderSettings", settings)
                    .then(
                        function (response) {
                            closeRefresh();
                        },
                        function (error) {
                            errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                        }
                    )
                    .catch(function (error) {
                        console.error("Error saving RocketChat settings:", error);
                        errorHandling.handleError(error);
                    });
            }
        }

        vm.getEncryptedTokenValue = function() {
            if (vm.activeSections && vm.activeSections.length && vm.activeSections[0].settings) {
                var setting = vm.activeSections[0].settings.find(function(s) {
                    return s.key === 'personalAccessTokenEncrypted';
                });
                console.log(setting);
                return setting ? setting.value : null;
            }
            return null;
        };




        function testRocketChatToken() {
           var settings = {};
            vm.activeSections.forEach(section => {
                section.settings.forEach(setting => {
                        if (["enabledRocketChat", "serverUrl", "serviceUserId", "personalAccessTokenEncrypted"].includes(setting.key)) {
                            if (setting.key === "enabledRocketChat") {
                                settings.providerType = setting.value ? 1 : 0;
                            } else {
                                settings[setting.key] = setting.value;
                            }
                        }
                        
                    });
                if (vm.getEncryptedTokenValue()){
                    settings.personalAccessToken = settings.personalAccessTokenEncrypted;
                    delete settings.personalAccessTokenEncrypted;
                }
            });
            
            
            $http.post("/api/v1/settings/test-rocket-chat", JSON.stringify(settings))
                .then(function() {
                        vm.rocketChatTestFailed = false;
                        successHandling.report();
                    },
                    function(fail) {
                        vm.rocketChatTestFailed = true;
                        errorHandling.report();
                    });
        }


        function cancel() {
            $mdDialog.cancel();

        }

        function close() {
            $mdDialog.hide();

        }

        activate();

    }
})();