(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateHexColor", [validateHexColor]);
	
	function validateHexColor() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.validateHexColor = function (modelValue, viewValue) {
					if (!viewValue) {
						return true;
					}

					return (/^#[a-fA-F0-9]{6}$/).test(viewValue);
				};
			}
		};
	}
})();