(function() {

    angular
        .module("smartertools")
        .filter('markdownToHtml', markdownToHtml);
    showdown.extension('strikethrough', function() {
        return [{
            type: 'output', // or 'output' depending on where you want to hook in
            regex: /~([^~]+)~/g,
            replace: function(match, text) {
                return '<s>' + text + '</s>';
            }
        }];
    });
    const showdownConverter = new showdown.Converter({extensions: ['strikethrough']});
    function markdownToHtml() {
        return function (text) {
            return showdownConverter.makeHtml(text || "");
        };
    }

})();