(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("treeState", treeState);

	function treeState($rootScope, $state, $log, $sanitize, $window, preferencesStorage) {
		var vm = this;
		vm.size = 'large';
		vm.isCollapsed = false;
		vm.isExpanded = !vm.isCollapsed;
		vm.windowWidth = 0;
		vm.lastWindowWidth = 0;
		vm.overrideHideOnSmall = false;

		// Functions
		vm.toggleTree = toggleTree;
		vm.setTree = setTree;
		vm.hideTreeOverlay = hideTreeOverlay;

		activate();

		/////////////////////

		function activate() {
			vm.windowWidth = vm.lastWindowWidth = $(window).width();
			onWindowWidthChanged(true);

			var treeExpandedState = preferencesStorage.getLocalParam("treeExpandedState");
			if (treeExpandedState && treeExpandedState.isExpanded != undefined) {
				setTree(treeExpandedState.isExpanded);
			}

			window.addEventListener('resize', onResize, true);
			$rootScope.$on("$destroy", function () {
				//$log.log("removed")
				window.removeEventListener('resize', onResize, true);
			});
		}

		function onResize(event) {
			vm.lastWindowWidth = vm.windowWidth;
			vm.windowWidth = $(window).width();
			onWindowWidthChanged();
		}

		function onWindowWidthChanged(force) {
			if (vm.windowWidth <= 736 && (vm.lastWindowWidth > 736 || force)) {
				// Moving from big to small
				vm.size = 'small';
				if (!vm.overrideHideOnSmall) setTree(false);
			}
			else if (vm.windowWidth > 736 && (vm.lastWindowWidth <= 736 || force)) {
				// Moving from small to big
				vm.size = 'large';
				setTree(true);
			}
		}

		function setTree(visible) {
			vm.isCollapsed = !visible;
			vm.isExpanded = !vm.isCollapsed;
			$rootScope.$broadcast("treeState:stateChange", { collapsed: vm.isCollapsed, expanded: vm.isExpanded });
		}

		function toggleTree(updatePrefs) {
			setTree(!vm.isExpanded);
			if (updatePrefs)
				preferencesStorage.setLocalParam("treeExpandedState", { isExpanded: vm.isExpanded });
			$rootScope.$broadcast("masonry:contentsChanged");
		}

		function hideTreeOverlay() {
			if (vm.size === 'small' && vm.isExpanded) {
				setTree(false);
			}
		}
	}

})();