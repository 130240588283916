(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("moveFolderController", moveFolderController);
    function moveFolderController($scope, $mdDialog, folders, folder) {
        $scope.controller = this;
        $scope.selectedNode = "";
        $scope.title = 'MOVE_FOLDER';
        $scope.folders = $.grep(folders, function (f) {
            return f.path != folder && f.access !== 2;
        });
        $scope.selectedFolder = JSON.parse(JSON.stringify($scope.folders[0]));
        if ($scope.selectedFolder.translatedName !== undefined)
            $scope.selectedFolder.translatedName = $scope.selectedFolder.translatedName.replaceAll("\u00A0", '');
        $scope.selectedFolder.name = $scope.selectedFolder.name.replaceAll("\u00A0", '');

        $scope.onFolderChanged = function (newVal) {
            $scope.selectedFolder = JSON.parse(JSON.stringify(newVal));
            $scope.selectedFolder.translatedName = newVal.translatedName.replaceAll("\u00A0", '');
            $scope.selectedFolder.name = newVal.name.replaceAll("\u00A0", '');
        }

        $scope.done = function () {
            $mdDialog.hide({ 'success': true, 'folder': $scope.selectedFolder.path, 'owner': $scope.selectedFolder.ownerEmailAddress });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }
})();