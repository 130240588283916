(function () {
    'use strict';

    angular
        .module('smartermail')
        .component('userWithContextMenu', {
            templateUrl: '/interface/app/email/message-view-components/user-with-context-menu.component.html',
            controller: UserContextMenuController,
            bindings: {
                options: '<',
                email: '<',
                onHidden: '&'
            }
        });

    function UserContextMenuController($timeout) {
        var vm = this;

        vm.$onInit = function () {
            attachClickCatcher();
        };

        vm.onOptionClicked = function (ev, option) {
            option.func(ev);
            hide();
        };

        function attachClickCatcher() {
            const clickCatcher = document.createElement("div");
            clickCatcher.classList.add('st-click-catcher');
            clickCatcher.addEventListener('contextmenu', (e) => {
                e.preventDefault();
                return false;
            });
            clickCatcher.addEventListener('mouseup', (e) => { hide(); });
            $('body').append(clickCatcher);
        }

        function hide() {
            vm.onHidden();
            $('.st-click-catcher').remove();
            $timeout(function () { //wait for opacity fade out;
                $('.user-with-context-menu').remove();
            }, 150);
        }
    }

})();