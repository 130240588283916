(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainEditUsernameController", domainEditUsernameController);

    function domainEditUsernameController($scope, $mdDialog, $filter, coreDataDomainSettings, errorHandling, oldUsername) {
        $scope.oldUsername = oldUsername;
        $scope.newUsername = '';
        $scope.isUsernameValid = true;
        $scope.isValid = true;
        $scope.domainPath = "";

        // Functions
        $scope.close = close;
        $scope.cancel = cancel;

        activate();

        ///////////////////////

        function activate() {
            var skipWatch = true;

            coreDataDomainSettings.settingsData.domainData
                .then(function (successData) {
                    $scope.domainPath = successData.path;
                }, function () { });

            $scope.$watch('newUsername', function () {
                if (skipWatch === true) {
                    skipWatch = false;
                    return;
                }
                $scope.isUsernameValid = $scope.newUsername && $scope.newUsername.length > 0;
                $scope.isValid = true;
            });
        }

        function close() {
            if (!($scope.isValid = validate()))
                return;

            $mdDialog.hide({ newUsername: $scope.newUsername });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function validate() {
            $scope.isUsernameValid = $scope.newUsername && $scope.newUsername.length > 0;
            if (($scope.domainPath.length + $scope.newUsername.length) > 210) {
                errorHandling.report($filter('translate')('WINDOWS_FILE_LENGTH_EXCEEDED'));
                return;
            }

            return $scope.isUsernameValid;
        }
    }

})();
