(function () {
	"use strict"
	angular
		.module("smartertools")
		.directive("mdAutofocus", mdAutofocus);

	angular
		.module("smartertools")
		.directive("stAutofocus", mdAutofocus);

	function mdAutofocus($compile, $log, $timeout) {
		return {
			restrict: "A",
			priority: -1,
			link: function (scope, el) {
				$timeout(function () {
					if (el[0].nodeName.toLowerCase() === "md-autocomplete") {
						if (el[0].hasAttribute("md-input-id"))
							el.find(`#${el[0].getAttribute("md-input-id")}`).trigger("focus");
						else if (el[0].hasAttribute("md-input-name"))
							el.find(`[name="${el[0].getAttribute("md-input-name")}"]`).trigger("focus");
						else
							el.find("input[type='text']").trigger("focus");
					}
					else
						el.trigger("focus");
				}, 50);
			}
		};
	}

})();