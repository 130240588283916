/*
 * Use Examples:
 * This menu is designed to be created when an item is right clicked on.
 * 
 * $scope.onMouseUp = function (selectedCard, ev) {
 *		if (!$scope.selectMode) {
 *			if (ev.which == 3) {
 *				ev.stopPropagation();
 *				ev.preventDefault();
 *				$scope.dropdownEvent = $.extend(true, {}, ev);
 *				$scope.dropdownOptions = [
 *					{ key: 'editItem', click: $scope.editItemDropdown, params: { card: selectedCard, event: $scope.dropdownEvent }, translateKey: 'EDIT' },
 *					{ divider: true, show: selectedCard.sourcePermission >= 8 }
 *					{ key: 'deleteItem', click: $scope.deleteDropdown, params: { card: [selectedCard], event: $scope.dropdownEvent }, translateKey: 'DELETE', show: selectedCard.sourcePermission >= 8}
 *				];
 *				var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'mail-dropdown-no-scroll\']"></st-context-menu>';
 *				var element = $('#context-menu-area');
 *				if (element) {
 *					var elementCompiled = $compile(elementToCompile)($scope);
 *					element.append(elementCompiled);
 *				}
 *			}
 *		}
 *	};
 * 
 *	var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']" menu-like="true" menu-class="\'abn-tree-row\'"></st-context-menu>';
 */

(function () {
	"use strict";

	angular.module("smartertools").directive("stContextMenu", ["$compile", "$timeout",
	function ($compile, $timeout) {
		return {
			restrict: "E",
			scope: {
				options: "=",
				event: "=",
				classes: "=", //A list of custom classes to add to the parent div.
				menuLike: "=", //Makes the menu act like a md-menu in that it will appear below the element that was clicked on.
				menuClass: "=", //The class of the object to use for menu-like.
				direction: "=", //whether the menu goes left or right.
				vertOffset: "=" //adds a extra vertical offset to the menu if its using menu like.
			},
			link: function (scope, element, attr) {
				scope.isOpen = false;
				scope.clickFunctions = {};
				scope.params = {};
				scope.event.preventDefault();
				scope.event.stopPropagation();
				scope.maxHeight = 8;

				var classesToAdd = "";
				if (scope.classes) {
					classesToAdd = scope.classes.join(" ");
				}

				var elementToBeAdded = '<div class="st-context-menu ' + classesToAdd + ' " oncontextmenu="return false;" ng-class="{\'context-open\': isOpen}">';

				for (var i = 0; i < scope.options.length; ++i) {
					if (scope.options[i].show == undefined || scope.options[i].show === true) {
						if (scope.options[i].divider) {
							elementToBeAdded += '<div class="st-menu-divider"></div>';
							scope.maxHeight += 9;
						} else {
							scope.clickFunctions[scope.options[i].key] = scope.options[i].click;
							scope.params[scope.options[i].key] = scope.options[i].params;
							var isDisabled = scope.options[i].disabled || false;
							elementToBeAdded += '<div class="st-menu-item">\
							<button class="md-button md-ink-ripple" type="button" ng-click="onClick(\'' + scope.options[i].key + '\', $event)" ng-disabled=' + isDisabled + '>{{::"' + scope.options[i].translateKey + '" | translate | htmlUnescape}}</button>\
							</div>';
							scope.maxHeight += 32;
						}
					}
				}
				elementToBeAdded += '</div>';

				scope.onClick = function (key, ev) { 
					if (ev.which == 3) {
						ev.stopPropagation();
						ev.preventDefault();
						return;
					}
					scope.clickFunctions[key](scope.params[key]);
					scope.hideMenu();
				};

				scope.openMenu = function (event) {
					var dElement = $(element);
					dElement[0].style.position = 'fixed';
					dElement[0].style.display = 'block';

					var vertOffset = scope.vertOffset;
					if (!vertOffset) { vertOffset = 0; }
					var parentElement = undefined;
					if (scope.maxHeight > dElement.height()) { scope.maxHeight = dElement.height(); };
					
					if (scope.menuLike && scope.menuClass) {
						parentElement = $(event.target).closest('.' + scope.menuClass);
					}
					if(parentElement && parentElement.length > 0) {
						var offset = parentElement.offset();
						// Set the top to something ridiculous for now. Need to let the translations happen before calculating left.
						// Delaying the top calculation until then helps keep the context menu from making a brief jump.
						dElement[0].style.top = -10000 + 'px';
						// The element currently has a width based on displayed values like '{{::"MOVE" | translate}}'.
						// Use timeout to give it a chance to perform the translation so we can get the real width.
						setTimeout(function() {
							calcPos(dElement, offset.left, offset.top + parentElement.height());
						}, 75);
					} else {
						var eventY, eventX;
						if (event.type === 'touchstart' || event.type === 'touchend') {
							eventY = event.touches[0].clientY;
							eventX = event.touches[0].clientX;
						} else {
							eventY = event.clientY;
							eventX = event.clientX;
						}
						// Set the top to something ridiculous for now. Need to let the translations happen before calculating left.
						// Delaying the top calculation until then helps keep the context menu from making a brief jump.
						dElement[0].style.top = -10000 + 'px';
						// The element currently has a width based on displayed values like '{{::"MOVE" | translate}}'.
						// Use timeout to give it a chance to perform the translation so we can get the real width.
						setTimeout(function() {
							calcPos(dElement, eventX, eventY);
						}, 75);
					}
					scope.isOpen = true;

					function calcPos(elem, baseLeft, baseTop) {
						// Calc top
						if (baseTop + scope.maxHeight + vertOffset > window.innerHeight) {
							var top = baseTop - scope.maxHeight + vertOffset;
							if (top <= 0) top = 0;
							$(elem)[0].style.top = top + 'px';
						} else {
							$(elem)[0].style.top = (baseTop + vertOffset) + 'px';
						}

						// Calc left
						var elemWidth = $(elem).width();
						var left = 0;
						if (scope.direction === 'left') {
							left = (baseLeft - elemWidth < 0) ? baseLeft : baseLeft - elemWidth;
							if (left + elemWidth > window.innerWidth) left = window.innerWidth - elemWidth - 5;
						} else {
							left = (baseLeft + elemWidth > window.innerWidth) ? baseLeft - elemWidth : baseLeft;
							if (left < 0) left = 5;
						}
						$(elem)[0].style.left = left + 'px';
					}
				};

				scope.hideMenu = function (event) {
					if (!event || (event && event.which !== 3)) {
						$('body').off('mousewheel DOMMouseScroll', scope.disableScroll);

						scope.isOpen = false;
						$('.st-click-catcher').remove();
						$timeout(function () { //wait for opacity fade out;
							$('st-context-menu').remove();
						}, 300);
					}
				};

				scope.disableScroll = function (event) {
					event.stopPropagation();
					event.preventDefault();
					event.cancelBubble = false;
					return false;
				};

				$('st-context-menu').remove();
				var elementCompiled = $compile(elementToBeAdded)(scope);
				element.append(elementCompiled);
				var clickCatcher = '<div class="st-click-catcher" ng-mouseup="hideMenu($event)" oncontextmenu="return false;"></div>';
				elementCompiled = $compile(clickCatcher)(scope);
				$("body").append(elementCompiled);

				$('body').on('mousewheel DOMMouseScroll', scope.disableScroll);

				$timeout(function () {
					scope.openMenu(scope.event);
				});
			}
		}
	}
	]);
})();