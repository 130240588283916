(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userCloudStorageConnectController", userCloudStorageConnectController);

    function userCloudStorageConnectController(
        $rootScope, $scope, $mdDialog, $filter, $http, $state, $window,
        $timeout, modalService, popupService, coreData, errorHandling,
        providerId, providerName, alreadyConfigured, enabled, DomainAdminCanOverride, $translate, localHelpLink) {
        const vm = this;

        // Banner image property
        $scope.helpLink = localHelpLink;
        $scope.providerIcon = "";
        // Initialize scope variables
        $scope.providerId = providerId;
        $scope.providerName = providerName;
        $scope.step = 1;
        $scope.folderName = $scope.folderName || "";
        $scope.alreadyConfigured = alreadyConfigured;
        $scope.enabled = enabled;
        $scope.disableConnection = disableConnection;
        $scope.DomainAdminCanOverride = DomainAdminCanOverride;
        $scope.isDomainAdmin = coreData.user.isDomainAdmin;
        $scope.close = close;
        $scope.closeRefresh = closeRefresh;
        $scope.persistFolderName = function () {
            $scope.folderName = $scope.folderName || "";
        };
        

        $scope.accountTypes = [
            {
                translatable: "DROPBOX",
                id: 2,
                imgSrc: "img/product-icons/dropbox_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_DROPBOX"
            },
            {
                translatable: "GOOGLEDRIVE",
                id: 9,
                imgSrc: "img/product-icons/drive_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_GOOGLE_DRIVE"
            },
            {
                translatable: "LEITZCLOUD",
                id: 7,
                imgSrc: "img/product-icons/leitzcloud_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_LEITZCLOUD"
            },
            {
                translatable: "ONEDRIVE",
                id: 4,
                imgSrc: "img/product-icons/onedrive_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_ONEDRIVE_ACCESS"
            },
            {
                translatable: "OWNCLOUD",
                id: 10,
                imgSrc: "img/product-icons/owncloud_icon.png",
                useOAuth: false,
                defaultDisplayKey: "MY_OWNCLOUD"
            },
            {
                translatable: "VBOXX",
                id: 8,
                imgSrc: "img/product-icons/vboxx_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_VBOXX"
            }
        ];
        $scope.accountType = null; // Set a default value

        if (Array.isArray($scope.accountTypes)) {
            for (let i = 0; i < $scope.accountTypes.length; i++) {
                if ($scope.accountTypes[i].translatable.toLowerCase() === $scope.providerId.toLowerCase()) {
                    $scope.accountType = $scope.accountTypes[i];
                    break; // Exit the loop once a match is found
                }
            }
        }
        $scope.translatedName = $translate.instant($scope.accountType.translatable);
        vm.modalServiceCallback = modalServiceCallback;
        $scope.openService = openService;

        activate();

        // Activate Function
        function activate() {

            modalService.setModalCallback(vm.modalServiceCallback);

            for (let i = 0; i < $scope.accountTypes.length; i++) {
                if ($scope.providerId.toUpperCase() === $scope.accountTypes[i].translatable) {
                    let type = $scope.accountTypes[i];
                    //  type.translatable = $filter("translate")(type.translatable);
                    if (!$scope.alreadyConfigured && $scope.enabled) {
                        openService(type);
                    } else if ($scope.enabled && $scope.alreadyConfigured) {
                        $scope.step = 3;
                    }
                    return;
                }
            }
        }

        async function addProvider(modalSuccess) {
            function handlePostError(error) {
                if (error.data) {
                    if (error.data.message === "Invalid Permissions") {
                        errorHandling.report($translate.instant("INVALID_PERMISSIONS"));
                    } else {
                        errorHandling.report("ERROR");
                        console.log(error);
                    }
                }
                console.error("API returned an error:", error);
            }

            let key = null;
            $scope.accountTypes.forEach(function (type) {
                if (type.id === modalSuccess.type.id) {
                    key = type.id;
                }
            });
            if (key === null) {
                return null;
            }
            let params = JSON.stringify({
                code: modalSuccess.code,
                name: modalSuccess.name,
                providerKey: key
            });
            switch (key) {
                case 2: // Dropbox
                    await $http.post("~/api/v1/settings/dropbox-put", params)
                        .then(
                            function () {
                                closeRefresh();
                                return true;
                            },
                            handlePostError
                        );
                    break;
                case 4: // OneDriveV2
                    await $http.post("~/api/v1/settings/one-drive-put", params)
                        .then(
                            function () {
                                closeRefresh();
                                return true;
                            },
                            handlePostError
                        );
                    break;
                case 7: // Leitzcloud
                    await $http.post("~/api/v1/settings/leitzcloud-put", params)
                        .then(
                            function () {
                                closeRefresh();
                                return true;
                            },
                            handlePostError
                        );
                    break;
                case 8: // vBoxx
                    await $http.post("~/api/v1/settings/vboxx-put", params)
                        .then(
                            function () {
                                closeRefresh();
                                return true;
                            },
                            handlePostError
                        );
                    break;
                case 9: // Google Picker
                    await $http.post("~/api/v1/settings/google-put", params)
                        .then(
                            function () {
                                closeRefresh();
                                return true;
                            },
                            handlePostError
                        );
                    break;
                default:
                    break;
            }

            closeRefresh();
            return true;
        }


        async function getCloudIds() {
            const accountType = $scope.accountTypes.find(
                (type) => $scope.providerId.toUpperCase() === type.translatable.toUpperCase()
            );
            if (!accountType) {
                return null;
            }
            const id = accountType.id;
            try {
                const response = await $http.get('/api/v1/settings/connected-services');
                if (response.data.success) {
                    const connectedService = response.data.connectedService.find(
                        (service) => service.type === id
                    );
                    return connectedService ? connectedService.id : null;
                }
                return null;
            } catch (error) {
                return null;
            }
        }

        async function disableConnection() {
            const id = await getCloudIds();
            const data = {serviceIDs: [id]};
            $http.post('/api/v1/settings/connected-services-delete', data)
                .then(function (response) {
                    if (response.data.success) {
                        closeRefresh();
                        return true;
                    }
                }, function () {
                    errorHandling.report("ERROR_DELETING_CLOUD_STORAGE_CONNECTION");
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Save Function
        $scope.save = function () {
            if (!$scope.folderName.trim()) {
                return;
            }

            if ($scope.currentType && $scope.currentType.useOAuth) {
                const returnArgs = {
                    name: $scope.folderName,
                    type: $scope.currentType,
                    code: $scope.returnCode
                };
                addProvider(returnArgs);
            }
        };

        // Cancel Function
        function closeRefresh() {
            $mdDialog.cancel({});
            modalService.setModalCallback(null);
        }

        function close() {
            $mdDialog.hide();
        }


        // Open Service Function
        function openService(type) {
            if (!type) {
                for (let i = 0; i < $scope.accountTypes.length; i++) {
                    if ($scope.providerId.toUpperCase() === $scope.accountTypes[i].translatable.toUpperCase()) {
                        type = $scope.accountTypes[i];
                        // type.translatable = $filter("translate")(type.translatable);
                        break;
                    }
                }
                if (!type) {
                    return;
                }
            }
            if (type.useOAuth) {
                doOAuth(type);
            } else {
                $scope.currentType = type;
                $scope.folderName =
                    $filter("translate")(type.defaultDisplayKey) ||
                    type.defaultDisplayKey ||
                    "Unnamed Folder";
                $scope.step = 2;
                $scope.persistFolderName();
            }
        }

        function modalServiceCallback() {
            $mdDialog.cancel({reason: "cancel"});
        }

        function doOAuth(type) {
            $scope.currentType = undefined;
            $scope.returnCode = undefined;

            // Safe apply utility function
            const safeApply = (fn) => {
                const phase = $scope.$root.$$phase;
                if (phase === '$apply' || phase === '$digest') {
                    if (fn && typeof fn === 'function') fn();
                } else {
                    $scope.$apply(fn);
                }
            };

            const redirect = coreData.baseUrl + "/interface/connect-redirect";
            const params = JSON.stringify({redirectUri: redirect});
            const popupFeatures = "width=500,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes,resizable=yes,left=200,top=100";
            const newWindow = window.open("", "OAuthPopup", popupFeatures);

            if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
                errorHandling.report($translate.instant("POPUP_BLOCKED"));
                return;
            }

            // Use native interval instead of $interval
            let popupCheckInterval = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(popupCheckInterval);
                    if (!$scope.returnCode) {
                        safeApply(() => {
                            $scope.cancelOAuthFlow();
                        });
                    }
                }
            }, 500);

            $window.handleConnectClose = function (result) {
                safeApply(() => {
                    if (result.code) {
                        $scope.returnCode = result.code;
                        $scope.step = 2;
                        $window.focus();
                    }
                    $window.handleConnectClose = null;
                });
            };

            switch (type.id) {
                case 2:
                    $http.post("~/api/v1/settings/dropbox-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 4:
                    $http.post("~/api/v1/settings/microsoft-redirect/OneDrive", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 7:
                    $http.post("~/api/v1/settings/leitzcloud-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 8:
                    $http.post("~/api/v1/settings/vboxx-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 9:
                    $http.post("~/api/v1/settings/google-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                default:
                    newWindow.close();
                    break;
            }

            function onRedirectApiFailure(failure) {
                clearInterval(popupCheckInterval);
                if (newWindow && !newWindow.closed) {
                    newWindow.close();
                }
                if (failure.data.message.includes("not enabled for this domain.")) {
                    errorHandling.report("CLOUD_STORAGE_PROVIDER_NOT_ENABLED_FOR_DOMAIN");
                } else {
                    errorHandling.report("ERROR");
                    console.error("API returned an error:", failure);
                }
                closeRefresh();
            }

            function onRedirectApiSuccess(success) {
                safeApply(() => {
                    $scope.currentType = type;
                    $scope.folderName =
                        $filter("translate")(type.defaultDisplayKey) ||
                        type.defaultDisplayKey ||
                        "Unnamed Folder";
                });
                newWindow.location = success.data.result;
            }

            // Cleanup on scope destruction
            $scope.$on('$destroy', () => {
                clearInterval(popupCheckInterval);
                if (newWindow && !newWindow.closed) {
                    newWindow.close();
                }
            });

            $scope.cancelOAuthFlow = function () {
                safeApply(() => {
                    $scope.step = 1;
                    errorHandling.report($translate.instant("AUTHORIZATION_CANCELED_BY_USER"));
                    closeRefresh();
                });
            };
        }
    }
})();