(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsMarketplaceController", domainSettingsMarketplaceController);

    function domainSettingsMarketplaceController(
        $scope,
        $http,
        errorHandling,
        $rootScope,
        themesService,
        $translate,
        $mdDialog,
        userDataService,
        coreData,
        treeState,
        $timeout,
        $state,
        coreLicensing
    ) {
        const vm = this;
        vm.isInitialized = false;
        vm.treeExpanded = treeState.isExpanded;
        vm.marketplaceItems = [];
        vm.bannerImage = "";
        vm.loadMarketplaceSettings = loadMarketplaceSettings;
        vm.openSettingsModal = openSettingsModal;
        vm.isOnlyOfficeEnabled = isOnlyOfficeEnabled;
        vm.domainAdminCanOverride = false;
        vm.emclientEnabled = true;
        vm.lightordark;
        vm.gridClass = "marketplace-grid-single";
        $scope.$watch(function(){
            return $state.$current.name;
        }, function(newVal, oldVal){
            activate();
        });
        vm.configuredAccounts = [];
        vm.reloadvar = true;
        vm.isAdmin = $state.current.name.startsWith("index.domain-marketplace");
        vm.helpTypesUser = [
            {cat: 6, helpLink: "user-marketplace-chat-providers"},
            {cat: 5, helpLink: "user-marketplace-conferencing"},
            {cat: 2, helpLink: "user-marketplace-document-management"},
            {cat: 3, helpLink: "user-marketplace-file-storage"},
            {cat: 1, helpLink: "user-marketplace-ai-integration"},
            {cat: 4, helpLink: "user-marketplace-email-client"},
        ];


        vm.accountTypes = [
            {name: "dropbox", id: 2, sort: 3},
            {name: "googledrive", id: 9, sort: 3},
            {name: "leitzcloud", id: 7, sort: 3},
            {name: "onedrive", id: 4, sort: 3},
            {name: "owncloud", id: 10, sort: 3},
            {name: "vboxx", id: 8, sort: 3},
            {name: "emclient", id: 11, sort: 4},
            {name: "onlyoffice", id: 12, sort: 2},
            {name: "collabora", id: 13, sort: 2},
            {name: "chatgpt", id: 14, sort: 1},
            {name: "smartermailfilestore", id: 15, sort: 3},
            {name: "smartermailchat", id: 15, sort: 6},
            {name: "rocketchat", id: 16, sort: 6},
            {name: "smartermailonlinemeetings", id: 17, sort: 5},
        ];

        vm.catname = "";
        switch ($state.current.params.sort) {
            case 0:
                vm.catname = "";
                break;
            default:
                vm.catname = "";
                break;
        }
        vm.catname = $translate.instant(vm.catname);
        vm.translatedMessage = $translate.instant("NO_INTEGRATIONS_ENABLED_BY_ADMINISTRATOR", {
            category: vm.catname
        });
        vm.marketplaceSideMenuItems = [];

        function onTreeStateChanged(event, data) {
            vm.treeExpanded = data.expanded;
            $timeout(function () {
                $(window).trigger("resize");
            }, 250);
        }

        activate();

        async function activate() {
            vm.marketplaceHasItems = false;
            vm.sort = $state.current.params.sort;
            $scope.$on("treeState:stateChange", onTreeStateChanged);
            vm.currentState = $state.current.params.sort;
            vm.marketplaceItems = [];
            await coreData.init;
            $rootScope.$on("themeService:changed", setBannerImage);
            vm.enableOnlyOffice = await vm.isOnlyOfficeEnabled();
            vm.enablewopi = await isCollaboraOnlineEnabled();
            setBannerImage();
            await UpdateConnectedServices();
            await loadMarketplaceSettings();
            $scope.$applyAsync();
            vm.marketplaceSideMenuItems = [
                {
                    title: $translate.instant("OVERVIEW"),
                    sref: vm.isAdmin ? "index.domain-marketplace.overview" : "index.marketplace.overview"
                },
                {
                    title: $translate.instant("AI_INTEGRATIONS"),
                    sref: vm.isAdmin ? "index.domain-marketplace.ai_integration" : "index.marketplace.ai_integration"
                },
                {
                    title: $translate.instant("CHATPROVIDER"),
                    sref: vm.isAdmin ? "index.domain-marketplace.chat_providers" : "index.marketplace.chat_providers"
                },
                {
                    title: $translate.instant("CONFERENCING"),
                    sref: vm.isAdmin ? "index.domain-marketplace.conferencing" : "index.marketplace.conferencing"
                },
                {
                    title: $translate.instant("DOCUMENT_MANAGEMENT"),
                    sref: vm.isAdmin ? "index.domain-marketplace.document_management" : "index.marketplace.document_management"
                },
                {
                    title: $translate.instant("FILE_STORAGE"),
                    sref: vm.isAdmin ? "index.domain-marketplace.file_storage" : "index.marketplace.file_storage"
                },
            ];
            if (vm.isAdmin && checkEmClientEligibility() && vm.emclientEnabled) {
                vm.marketplaceSideMenuItems.push({
                    title: $translate.instant("EMAIL_CLIENT"), sref: "index.domain-marketplace.email_client"
                });
            }
            var count = 0;
            for (let i = 0; i < vm.marketplaceItems.length; i++) {
                if (vm.marketplaceItems[i].enabled) {
                    count = count + 1;
                }
            }
            if (count > 1){
                vm.gridClass = "marketplace-grid-many";
            }
            vm.isInitialized = true;
        }

        function UpdateConnectedServices() {
            vm.configuredAccounts = [];

            return $http.get('/api/v1/settings/connected-services')
                .then(response => {
                    if (response.data.connectedService) {
                        const services = response.data.connectedService;
                        vm.configuredAccounts.push(
                            ...services.map(service =>
                                vm.accountTypes.find(type => type.id === service.type)?.name)
                                .filter(name => name !== undefined)
                        );
                    }
                    return $http.post('/api/v1/marketplace/GetConfiguredUserItems');
                })
                .then(postResponse => {
                    postResponse.data.results.forEach(element => {
                        if (element && !vm.configuredAccounts.includes(element)) {
                            vm.configuredAccounts.push(element);
                        }
                    });
                    vm.configuredAccounts = [...new Set(vm.configuredAccounts)];
                })
                .catch(error => {
                });
        }

        function setBannerImage() {
            if (themesService.isThemeDark()) {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_dark.webp";
                vm.lightordark = "dark";
            } else {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_light.webp";
                vm.lightordark = "light";
            }
        }

        async function isOnlyOfficeEnabled() {
            const apiUrl = "/api/v1/marketplace/IsOnlyOfficeEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch OnlyOffice settings", error);
                    return false;
                });
        }

        async function isCollaboraOnlineEnabled() {
            const apiUrl = "/api/v1/marketplace/IsCollaboraOnlineEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch Collabora settings", error);
                    return false;
                });
        }

        function loadMarketplaceSettings() {
            $rootScope.spinner = $rootScope.spinner || {show: angular.noop, hide: angular.noop};
            $rootScope.spinner.show();

            return $http.get("/api/v1/marketplace/getmarketplace")
                .then(function (response) {
                    const marketplaceSettings = response.data || {};
                    vm.domainAdminCanOverride = marketplaceSettings.domainAdminCanOverride || false;
                    vm.emclientEnabled = marketplaceSettings.emclientEnabled || false;

                    if (!coreData.user.isDomainAdmin) {
                        vm.domainAdminCanOverride = false;
                    }
                    vm.marketplaceItems = [];
                    if (marketplaceSettings.smarterMailFileStoreEnabled){
                        vm.configuredAccounts.push("smartermailfilestore");
                    }
                    if (vm.enablewopi && marketplaceSettings.collaboraEnabled) {
                        vm.configuredAccounts.push("collabora");
                    }
                    if (vm.enableOnlyOffice && marketplaceSettings.onlyOfficeEnabled) {
                        vm.configuredAccounts.push("onlyoffice");
                    }
                    Object.entries(marketplaceSettings).forEach(([key, value]) => {
                        if (key.endsWith("Enabled")) {
                            const itemId = key.replace("Enabled", "");
                            const parsedName = itemId.toUpperCase();
                            let currentEnabledState = false;

                            if (itemId.toLowerCase() === "onlyoffice") {
                                if (vm.enableOnlyOffice && marketplaceSettings.onlyOfficeEnabled) {
                                    currentEnabledState = vm.enableOnlyOffice;
                                } else {
                                    currentEnabledState = false;
                                }

                            } else if (itemId.toLowerCase() === "collabora") {
                                if (vm.enablewopi && marketplaceSettings.collaboraEnabled) {
                                    currentEnabledState = vm.enablewopi;
                                } else {
                                    currentEnabledState = false;
                                }
                            } else {
                                currentEnabledState = !!value;
                            }

                            const imageUrl = `img/MarketPlace/icon_${itemId.toLowerCase()}.webp`;
                            const name = $translate.instant(parsedName) || parsedName;
                            const description = $translate.instant(parsedName + "_DESCRIPTION") || "";

                            if (vm.sort !== 0 && vm.sort) {
                                vm.accountTypes.sort((a, b) => a.sort - b.sort);
                                const filteredAccountTypes = vm.accountTypes.filter(a => a.sort === vm.sort);
                                for (let i = 0; i < filteredAccountTypes.length; i++) {
                                    if (itemId.toLowerCase() === filteredAccountTypes[i].name) {
                                        vm.marketplaceItems.push({
                                            id: itemId,
                                            name: name,
                                            description: description,
                                            imageUrl: imageUrl,
                                            allowed: !!value,
                                            enabled: currentEnabledState
                                        });
                                    }
                                }
                            } else {
                                vm.marketplaceItems.push({
                                    id: itemId,
                                    name: name,
                                    description: description,
                                    imageUrl: imageUrl,
                                    allowed: !!value,
                                    enabled: currentEnabledState
                                });
                            }
                        }
                    });
                    if (coreLicensing.edition === 1) {
                        vm.marketplaceItems = vm.marketplaceItems.filter(item =>
                            item.id.toLowerCase() !== "smartermailonlinemeetings"
                        );
                    }else{
                        vm.configuredAccounts.push("smartermailonlinemeetings");
                    }
                    vm.marketplaceItems = vm.marketplaceItems.filter(item =>
                        item.id.toLowerCase() !== "emclient"
                    );
                    vm.marketplaceHasItems = vm.marketplaceItems && vm.marketplaceItems.some(item => item.enabled);
                })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        function checkEmClientEligibility() {
            switch (true) {
                case coreLicensing.edition >= 2:
                    return false;
                case !coreLicensing.license:
                    return false;
                case coreLicensing.license.isTrialLicense:
                    return false;
                case !vm.domainSettings.emClientEnabled:
                    return false;
                case !vm.domainSettings.emClientOffersAvailable:
                    return false;
                case !vm.domainSettings.isTopLevelDomain:
                    return false;
                default:
                    return true;
            }
        }

        function openSettingsModal(item) {
            if (["googledrive", "dropbox", "onedrive", "leitzcloud", "vboxx"].includes(item.id.toLowerCase())) {
                // Cloud Storage Connection modal
                $mdDialog.show({
                    controller: "userCloudStorageConnectController",
                    controllerAs: "cloudCtrl",
                    templateUrl: "app/marketplace/modals/user-cloud-storage-connect.modal.html",
                    clickOutsideToClose: false,
                    locals: {
                        providerName: item.name,
                        providerId: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.map(account => account.toLowerCase()).includes(item.id.toLowerCase()),
                        DomainAdminCanOverride: vm.domainAdminCanOverride,
                        localHelpLink: (() => {
                            const account = vm.accountTypes.find(account => account.name.toLowerCase() === item.id.toLowerCase());
                            const matchedHelpType = account ? vm.helpTypesUser.find(helpType => helpType.cat === account.sort) : null;
                            return matchedHelpType ? matchedHelpType.helpLink : "default-help-link";
                        })()
                    }
                })
                    .then(function () {
                        $rootScope.spinner.show();
                        vm.configuredAccounts = [];
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    })
                    .catch(function () {
                        $rootScope.spinner.show();
                        vm.configuredAccounts = [];
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    });

            } else {
                // Modular configuration modal
                $mdDialog.show({
                    controller: "modularConfigurationModalController",
                    controllerAs: "settingsCtrl",
                    templateUrl: "app/marketplace/modals/modular-configuration.modal.html",
                    clickOutsideToClose: false,
                    locals: {
                        addonName: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.includes(item.id),
                        domainAdminCanOverride: vm.domainAdminCanOverride,
                        localHelpLink: (() => {
                            const account = vm.accountTypes.find(account => account.name.toLowerCase() === item.id.toLowerCase());
                            const matchedHelpType = account ? vm.helpTypesUser.find(helpType => helpType.cat === account.sort) : null;
                            return matchedHelpType ? matchedHelpType.helpLink : "default-help-link";
                        })()
                    }
                })
                    .then(function (updatedSettings) {
                        if (!updatedSettings) return;
                        $rootScope.spinner.show();
                        let apiUrl, payload;
                        switch (item.id.toLowerCase()) {
                            case "owncloud":
                                if (updatedSettings) {
                                    apiUrl = "/api/v1/settings/owncloud-put";
                                    payload = updatedSettings;
                                }
                                break;
                        }
                        if (!apiUrl || !payload) {
                            $rootScope.spinner.hide();
                            return;
                        }
                        $http.post(apiUrl, payload)
                            .then(function () {
                                if (typeof payload.enabled !== "undefined") {
                                    item.enabled = payload.enabled;
                                }
                                return UpdateConnectedServices();
                            })
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                $scope.$applyAsync();
                            })
                            .catch(function (error) {
                                console.error(`Failed to save settings for addon: ${item.id}`, error);
                                errorHandling.report(error);
                            })
                            .finally($rootScope.spinner.hide);
                    }, function (error) {
                        activate();
                    });
            }
        }
    }
})();
