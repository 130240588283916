(function () {

	"use strict";

	angular
		.module("smartermail")
		.controller("gatewaysController", gatewaysController);

	function gatewaysController($rootScope, $scope, $state, $http, $q, errorHandling, claimsService) {

		// Variables
		var vm = this;
		vm.isInitialized = false;
        vm.selectedSection = $state.current.data.type || "GATEWAYS";
        vm.gridSections = [
            { translate: "GATEWAYS", length: 0 },
            { translate: "FAILOVER_SERVERS", length: -1}
		];

		// Functions
		vm.changeSection = changeSection;

		activate();

		//////////////////////

        function activate() {
            refreshCounts();
            $scope.$on("gateways:countUpdated", function () {
                refreshCounts();
            });
		}

        function refreshCounts() {
            $rootScope.spinner.show();
            $q.all([
                $http.get("~/api/v1/settings/sysadmin/combined-gateways").then(onCombinedLoaded, errorHandling.report),
                $http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailLoaded, errorHandling.report)
            ])
                .then(onLoadSuccess, onLoadError)
                .finally($rootScope.spinner.hide);

            function onLoadSuccess() {
                vm.isInitialized = true;
            }

            function onLoadError(result) {
                vm.isInitialized = false;
                errorHandling.report(result);
            }

            function onCombinedLoaded(result) {
                vm.gridSections[0].length = (result.data.combinedGatewayConfigs || []).length;
            }

            function onGlobalMailLoaded(result) {
                var settings = result.data.globalMailSettings;
                if (!settings.failoverEnabled) {
                    vm.gridSections[1].length = -1;
                }
                else if (!settings.failoverClusterSettings || !settings.failoverClusterSettings.failoverNodes) {
                    vm.gridSections[1].length = 0;
                }
                else {
                    vm.gridSections[1].length = settings.failoverClusterSettings.failoverNodes.length;
                }
            }
        }

		function changeSection(section) {
			vm.selectedSection = section;
			$state.current.data.type = section;
		}

        $scope.$on("combinedGatewayCountUpdated", function (event, data) {
            vm.gridSections[0].length = data || 0;
        })
	}

})();
