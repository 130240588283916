(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("rblSpamCtrl", rblSpamCtrl);

	function rblSpamCtrl($scope, $mdDialog, $http, $translate, orig, errorHandling) {
		var vm = this;

		$scope.spamCheck = orig;
		$scope.spamResultIndicators = $.extend([], $scope.spamCheck.spamResultIndicators);
		$scope.spamResultIndicators.push({ ip: "", weight: null, weight_max: null });

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.delete = function () {
			if ($scope.spamForm.$invalid)
				return;
			if ($scope.spamCheck.new === true)
				return;

			$mdDialog.hide({ itemsToRemove: [$scope.spamCheck.name] });
		};
		
		$scope.onIpChanged = function (last, index, form) {
			const ip = form["ip_" + index].$viewValue;

			$scope.spamResultIndicators[index].ip = ip;

			if (ip && ip.length > 0 && last)
				$scope.spamResultIndicators.push({ ip: "", weight: null, weight_max: null });
		};

		$scope.removeIp = function (index, form) {
			$scope.spamResultIndicators.splice(index, 1);
			form.$setDirty();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			$scope.spamCheck.spamResultIndicators = $.extend([], $scope.spamResultIndicators);
			if ($scope.spamCheck.spamResultIndicators)
				$scope.spamCheck.spamResultIndicators.pop();
			else
				$scope.spamCheck.spamResultIndicators = [];

			if (!$scope.spamCheck.spamResultIndicators || $scope.spamCheck.spamResultIndicators.length == 0) {
				errorHandling.report($translate.instant("ANTI_SPAM_RBL_IP_REQUIRED"));
				return;
			}

			var params = JSON.stringify({ itemsToUpdate: [$scope.spamCheck] });
			$http
				.post("~/api/v1/settings/sysadmin/antispam/rbls/update", params)
				.then(function () { $mdDialog.hide($scope.spamCheck); }, errorHandling.report);
		};
	}
})();