(function () {
    "use strict";

    /*

    EXAMPLE USAGE:

    <st-select-menu ng-show="settingCore.editItem" 
                    select-mode="settingCore.selectMode" 
                    selected-items="settingCore.selectedCards" 
                    on-select-all="settingCore.selectAll()" 
                    on-deselect-all="settingCore.deselectAll()">
        <md-menu-item>
            <md-button ng-click="settingCore.deleteItems(settingCore.selectedCards, $event)">
                {{::'DELETE'|translate}}</md-button>
        </md-menu-item>
    </st-select-menu>

    */

    angular
        .module('smartertools')
        .component('stSelectMenu', {
            template: template(),
            controller: stSelectMenuController,
            transclude: true,
            bindings: {
                selectMode: '=',
                selectedItems: '=',
                onSelectAll: '&',
                onDeselectAll: '&',
                deselectOnCancel: '='
            }
        });

    function template() {
        return `
            <md-menu ng-show='!$ctrl.selectMode' class='select-menu' md-position-mode='target-left bottom' md-offset='-2 0'>
                <div class="split-menu">
                    <md-button ng-click='$mdMenu.open($event)' class='select-menu-button md-secondary' aria-label='menu'>                    
                        <span ng-show='$ctrl.selectedItems.length > 1'>
                            {{'X_SELECTED' | reactPluralizeWithCommas : {count:$ctrl.selectedItems.length} }}
                        </span>
                        <span ng-show='$ctrl.selectedItems.length <= 1' translate-once='SELECT'></span>
                        <i class='toolsicon toolsicon-arrow_drop_down_emphasis'></i>
                    </md-button>
                </div>
                <md-menu-content class='standard'>
                    <md-menu-item><md-button ng-click='$ctrl.onSelectAll()' aria-label='select all'>{{::'SELECT_ALL'|translate}}</md-button></md-menu-item>
                    <md-menu-item><md-button ng-click='$ctrl.onDeselectAll()' aria-label='deselect all'>{{::'DESELECT_ALL'|translate}}</md-button></md-menu-item>
                    <md-divider></md-divider>
                    <md-menu-item><md-button ng-click='$ctrl.toggleSelectMode()' aria-label='toggle select mode'>{{::'ENABLE_SELECT_MODE'|translate}}</md-button></md-menu-item>
                    <ng-transclude></ng-transclude>
                </md-menu-content>
            </md-menu>
            <md-menu ng-show='$ctrl.selectMode' class='select-menu' md-position-mode='target-left bottom' md-offset='-2 0'>
                <div class='split-menu'>
                    <md-button ng-click='$mdMenu.open($event)' class='select-menu-button md-primary reply-button' aria-label='menu'>
                        <span>{{'X_SELECTED' | reactPluralizeWithCommas : {count:$ctrl.selectedItems.length} }}</span>
                        <i class='toolsicon toolsicon-arrow_drop_down_emphasis'></i>
                    </md-button>
                    <md-button ng-click='$ctrl.setSelectMode(false)' class='md-primary reply-menu' aria-label='clear'>
                        <i class='toolsicon toolsicon-close'></i>
                    </md-button>
                </div>
                <md-menu-content class='standard'>
                    <md-menu-item><md-button ng-click='$ctrl.onSelectAll()' aria-label='select all'>{{::'SELECT_ALL'|translate}}</md-button></md-menu-item>
                    <md-menu-item><md-button ng-click='$ctrl.onDeselectAll()' aria-label='deselect all'>{{::'DESELECT_ALL'|translate}}</md-button></md-menu-item>
                    <ng-transclude></ng-transclude>
                </md-menu-content>
            </md-menu>
            `;
    }

    /* @ngInject */
    function stSelectMenuController($log, $translate) {
        var vm = this;
        vm.setSelectMode = setSelectMode;
        vm.toggleSelectMode = toggleSelectMode;

        function toggleSelectMode() {
            setSelectMode(!vm.selectMode);
        }

        function setSelectMode(val) {
            vm.selectMode = val;
            if (vm.selectedItems && vm.deselectOnCancel != false) {
                if (vm.onDeselectAll) {
                    vm.onDeselectAll();
                } else {
                    vm.selectedItems.splice(0, vm.selectedItems.length);
                }
            }
        }
    }

})();
