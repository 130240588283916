(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('attachedFileResourceUpload', {
            templateUrl: '/interface/app/shared/components/attached-file-resource-upload.component.html',
            controller: attachedFileResourceUploadController,
            bindings: {
                attachedFiles: '=',
                dropZoneSelector: '@',
                browseControlSelector: '@',
                dropControlSelector: '@',
                attachmentGuid: '<',
                onFileUploaded: '&',
                removeAttachment: '&'
            }
        });

	function attachedFileResourceUploadController($translate, $timeout, toaster, fileInfoProvider, errorHandling, coreData) {
		var vm = this;
        vm.$onInit = function() {

        }
        vm.uploaderData = () => JSON.stringify({ guid: vm.attachmentGuid });
        vm.uploaderOnInit = function(pubFuncs) {
            vm.uploaderFuncs = pubFuncs;
        }
        
        
        vm.uploaderOnPreUpload = function(fileData, file) {
            vm.filesUploading++;

            if (!vm.attachedFiles || !file.fileName)
                return;

            var exist = vm.attachedFiles.some(f => f.isNew && f.filename.toLowerCase() === file.fileName.toLowerCase());
            if (exist) {
                vm.filesUploading--;
                file.cancel();
                errorHandling.warn("MAIL_SAME_FILE_UPLOAD");
            }
        }
        vm.uploaderOnUploaded = function(result, dropzone, file) {
            const extension = (result.name || '').split('.').pop();
            var icon = fileInfoProvider.iconFromExtension(extension).split('-').pop();
            if (icon === 'file') {
                icon = '';
            }

            const attachment = {
                isNew: true,
                filename: result.name,
                type: icon,
                isAttachedMessage: false,
                size: result.size,
                origFile: file,
                contentType: result.type,
                isInline: false,
                webLink: file.file.webkitRelativePath,
                remove: function() {
                    vm.uploaderFuncs.removeFile(file);
                },
                isComplete: function() { return true; }
            };
            if (vm.attachedFiles === undefined) 
                vm.attachedFiles = [];
            vm.attachedFiles.push(attachment);

            if (typeof vm.onFileUploaded() === "function") {
                vm.onFileUploaded()();
            }

            vm.filesUploading--;
        }
        vm.uploaderOnFileRemoved = function(attachment) {
            vm.attachedFiles = 
                vm.attachedFiles.filter(f => f.filename !== attachment.name || !f.isNew);

        }
        vm.uploaderOnUploadFailure = function(file, exception) {
            vm.attachedFiles = vm.attachedFiles.filter((a) => a.filename !== file.name || !a.isNew);

            vm.filesUploading--;
        }

        vm.uploaderOnCanceled = function(file) {
            vm.filesUploading--;
            vm.attachedFiles = vm.attachedFiles.filter((a) => a.filename !== file.name);
        }

        vm.removeUploadedAttachment = function(file) {
            if (file.isNew) {
                vm.uploaderFuncs.removeFile(file);
                vm.attachedFiles = vm.attachedFiles.filter((a) => a.filename !== file.name);

            }
        }
        // #endregion

    }

})();