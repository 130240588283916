(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("spoolViewRecipientsController", spoolViewRecipientsController);

	function spoolViewRecipientsController($scope, $mdDialog, recipients, statuses) {
		$scope.recipients = recipients;
		$scope.statuses = statuses;

		// This little blob of code will add breaking characters every so often in an email address
		// which should help it wrap better
		const regex = /([_\.@])/gm;
		if ($scope.recipients) {
			for (var i = 0; i < $scope.recipients.length; i++) {
				var r = $scope.recipients[i];
				r.addressWbr = (r.address || '').replace(regex, `<wbr>$1`);
			}
		}

		$scope.save = function () {
			$mdDialog.hide();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
