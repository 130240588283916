(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataSettings", coreDataSettings);

	function coreDataSettings($http, $q, $filter, $translate, $rootScope, $log, errorHandling, emailValidationService) {
		var _this = this;

		this.userSettings = {};
		this.userForwardSettings = {};
		//var _userAutoResponderSettings = {};
		//var userAutoResponderInitialized = false;
		var _userConnectedServices = {};
		var _allUserConnectedServices = {};
		var userConnectedServicesInitialized = false;
		var allUserConnectedServicesInitialized = false;
		this.userSignatureMappings = {};
		//var _userSmtpAccounts = {};
		//var userSmtpAccountsInitialized = false;
		this.userSignatures = {};
		var _signatureVariables = {};
		var signatureVariablesInitialized = false;
		var _userSyncDevices = [];
		this.userPermissions = {};
		this.userProperties = {};
		this.userDomainSettings = {};
		var _userEvents = {};
		var userEventsInitialized = false;
		var _aviliableEvents = {};
		var aviliableEventsInitialized = false;
		var _availableUserEvents = {};
		var availableUserEventsInitialized = false;
		var _aviliableEventVars = {};
		var aviliableEventsVarsInitialized = false;
		var _spamIp4r = {};
		var spamIp4rInitialized = false;
		var _userContentFiltering = {};
		var contentFilteringInitialized = false;
		var _resources = [];
		var resourcesInitialized = false;

		//this.globalSettings = {};
		var _aviliableTimeZones = {};
		var timeZonesInitialized = false;
		var _aviliableTextEncodings = {};
		var textEncodingsInitialized = false;
		var _autoCompleteList = {};
		var autoCompleteListInitialized = false;
		var _retrievalAccounts = {};
		var retrievalAccountsInitialized = false;
		var _userList = [];
		var _userGroups = {};
		var userGroupsInitialized = false;
		var _mappedResources = {};
		var mappedResourcesInitialized = false;
		var _domainAliases = {};
		var domainAliasesInitialized = false;

		var _messagesImported = 0;
		var _contactsImported = 0;
		var _calendarsImported = 0;
		var _tasksImported = 0;
		var _notesImported = 0;
		var _doneImporting = undefined;
		_this.migrationID = undefined;

		_this.queryUsers = queryUsers;

		this.reloadUserSignatures = function () { loadUserSignatures(); }

		this.settingsData = {
			get userConnectedServices() { return getConnectedServices(); },
			set userConnectedServices(value) { _userConnectedServices = value || {}; },

			get allUserConnectedServices() { return getAllConnectedServices(); },
			set allUserConnectedServices(value) { _allUserConnectedServices = value || {}; },

			get userSyncDevices() { return getSyncDevices(); },
			set userSyncDevices(value) { _userSyncDevices = value || {}; },

			//get userSmtpAccounts() { return getUserSmtpAccounts(); },
			//set userSmtpAccounts(value) { _userSmtpAccounts = value || {}; },

			//get userAutoResponderSettings() { return getAutoResponderSettings(); },
			//set userAutoResponderSettings(value) { _userAutoResponderSettings = value || {}; },

			get userEvents() { return getUserEvents(); },
			set userEvents(value) { _userEvents = value || {} },

			get aviliableEventVars() { return getAviliableEventVars(); },
			get availiableTimeZones() { return getTimeZones(); },
			get aviliableUserEvents() { return getAvailableUserEvents() },

			get autoCompleteList() { return getAutoCompleteList(); },
			set autoCompleteList(value) { _autoCompleteList = value || [] },

			get retrievalAccounts() { return getRetrievalAccounts(); },
			set retrievalAccounts(value) { _retrievalAccounts = value || [] },

			get contentFiltering() { return getContentFiltering(); },
			set contentFiltering(value) { _userContentFiltering = value || {} },

			get migrationStatus() { return getMigrationStatus(); },
			set migrationStatus(value) { setMigrationStatus(value); },

			get spamIp4r() { return getSpamIp4r(); },

			get resources() { return getResources(); },
			set resources(value) { _resources = value || {} },

			get userList() { return getUserList(); },
			set userList(value) { _userList = value || {} },

			get userGroups() { return getUserGroups(); },
			set userGroups(value) { _userGroups = value || {} },

			get mappedResources() { return getMappedResources(); },
			set mappedResources(value) { _mappedResources = value || {} },

			get signatureVariables() { return getSignatureVariables(); },

			get domainAliases() { return getDomainAliases(); },
			set domainAliases(value) { _domainAliases = value || {} },
		};

		$rootScope.$on('user-domain-settings:changed', loadUserDomainSettings);
		$rootScope.$on('user-settings:changed', loadUserSettings);
		$rootScope.$on("signalR.mailHub.client.userSignaturesModified",
			function () {
				const promises = [
					loadUserSignatureMappings(),
					loadUserSignatures()
				];
				$q.all(promises)
					.then(
						function () {
							$rootScope.$broadcast("user-signatures:changed");
						},
						function () { })
			});
		$rootScope.$on('signalR.mailHub.client.sharesChanged', function () { resourcesInitialized = false; });
		$rootScope.$on('signalR.mailHub.client.userGroupsModified', function () { userGroupsInitialized = false; });


		var _isInitialized = false;
		var initDefer;
		this.init = function () {
			if (initDefer) return initDefer.promise;
			if (_isInitialized) return $q.when();

			initDefer = $q.defer();

			var promises = [];
			promises.push(loadUser());
			promises.push(loadUserPermissions());
			promises.push(loadUserDomainSettings());
			promises.push(loadUserSettings());
			//promises.push(coreDataSettings.loadGlobalSettings());
			promises.push(loadForwardSettings()); //TODO: this can probably be put into a async call like most of the settings.
			promises.push(loadUserSignatureMappings());
			promises.push(loadUserSignatures());
			$q.all(promises)
				.then(
					function () {
						_isInitialized = true;
						initDefer.resolve();
						initDefer = null;
					},
					function (failure) {
						initDefer.reject(failure);
						initDefer = null;
					})
				.finally(function () {
					sessionStorage.rememberMe = "false";
				});

			return initDefer.promise;
		};

		this.reset = function () {
			_isInitialized = false;
			this.userSettings = {};
			this.userForwardSettings = {};
			//_userAutoResponderSettings = {};
			//userAutoResponderInitialized = false;
			_userConnectedServices = {};
			_allUserConnectedServices = {};
			userConnectedServicesInitialized = false;
			allUserConnectedServicesInitialized = false;
			this.userSignatureMappings = {};
			//_userSmtpAccounts = {};
			//userSmtpAccountsInitialized = false;
			this.userSignatures = {};
			_userSyncDevices = {};
			this.userPermissions = {};
			this.userDomainSettings = {};
			this.userProperties = {};
			_userEvents = {};
			userEventsInitialized = false;
			//_this.globalSettings = {};
			_aviliableTimeZones = {};
			timeZonesInitialized = false;
			_aviliableTextEncodings = {};
			textEncodingsInitialized = false;
			_spamIp4r = {};
			spamIp4rInitialized = false;
			_autoCompleteList = {};
			autoCompleteListInitialized = false;
			_retrievalAccounts = {};
			retrievalAccountsInitialized = false;
			_resources = [];
			resourcesInitialized = false;
			_userList = [];
			_userGroups = {};
			userGroupsInitialized = false;
			_mappedResources = {};
			mappedResourcesInitialized = false;
			_userContentFiltering = {};
			contentFilteringInitialized = false;
			_domainAliases = {};
			domainAliasesInitialized = false;
			this.customHelpInfo = { text: '', url: '' };
		}.bind(this);

		this.resetMigrationStatus = function()
		{
			_messagesImported = 0;
			_contactsImported = 0;
			_calendarsImported = 0;
			_tasksImported = 0;
			_notesImported = 0;
			_doneImporting = undefined;
			_this.migrationID = undefined;
		}

		//#region Load Settings
		function loadUser() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/user')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user loaded');
					_this.userProperties = success.data.userData;
					defer.resolve();
				}, function (failure) {
					_this.userProperties = {};
					defer.reject();
				});
			return defer.promise;
		}

		function loadUserPermissions() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/permissions')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user permissions loaded');
					_this.userPermissions = success.data.permissions;
					defer.resolve();
				}, function (failure) {
					_this.userPermissions = {};
					defer.reject();
				});
			return defer.promise;
		}

		function loadUserDomainSettings() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/domain')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user permissions loaded');
					_this.userDomainSettings = success.data;
						defer.resolve();
				}, function (failure) {
					_this.userDomainSettings = {};
						defer.reject();
				});
			return defer.promise;
		}

		function loadUserSettings() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/user-mail')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user mail settings loaded');
					_this.userSettings = success.data.userMailSettings;
					if (moment(_this.userSettings.userContactInfo.birthDate) < moment(new Date('1/1/101'))) {
						_this.userSettings.userContactInfo.birthDate = null;
					};
					$rootScope.$broadcast('user-settings:loaded');
					defer.resolve();
				}, function (failure) {
					_this.userSettings = {};
					defer.reject();
				});
			return defer.promise;
		}

		function loadForwardSettings() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/mailbox-forward-list')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user mail forward list loaded');
					_this.userForwardSettings = success.data.mailboxForwardList;
					defer.resolve();
				}, function (failure) {
					_this.userForwardSettings = {};
					defer.reject();
				});
			return defer.promise;
		}

		//function loadAutoResponderSettings() {
		//	return $http.get('~/api/v1/settings/auto-responder')
		//	.then(function (success) {
		//		//$log.debug('CoreDataSettings: user auto-responder loaded');
		//		_userAutoResponderSettings = success.data.autoResponderSettings;
		//		_userAutoResponderSettings.startDateUtc = moment(_userAutoResponderSettings.startDateUtc);
		//		_userAutoResponderSettings.endDateUtc = moment(_userAutoResponderSettings.endDateUtc);
		//		userAutoResponderInitialized = true;
		//	}, function (failure) {
		//		_userAutoResponderSettings = {};
		//		userAutoResponderInitialized = false;
		//	});
		//}

		//function getAutoResponderSettings() {
		//	var defer = $q.defer();
		//	if (userAutoResponderInitialized) {
		//		return $q.when(_userAutoResponderSettings);
		//	}
		//	loadAutoResponderSettings()
		//	.then(function (success) {
		//		defer.resolve(_userAutoResponderSettings);
		//	}, function (failure) {
		//		defer.reject("FAILED_TO_LOAD_AUTO_RESPONDER_SETTINGS");
		//	});
		//	return defer.promise;
		//}

		function loadConnectedServices() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/connected-services')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user connected services loaded');
					if (success.data.connectedService) {
						_userConnectedServices = success.data.connectedService;
						userConnectedServicesInitialized = true;
					} else {
						_userConnectedServices = [];
						userConnectedServicesInitialized = false;
					}
					defer.resolve();
				}, function (failure) {
					_userConnectedServices = {};
					defer.reject();
				});
			return defer.promise;
		}

		function getConnectedServices() {
			var defer = $q.defer();
			if (userConnectedServicesInitialized) {
				return $q.when(_userConnectedServices);
			}
			loadConnectedServices()
			.then(function (success) {
				defer.resolve(_userConnectedServices);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_CONNECTED_SERVICES");
			});
			return defer.promise;
		}

		function loadAllConnectedServices() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/connected-services')
				.then(function (success) {
					if (success.data.connectedService) {
						_allUserConnectedServices = success.data.connectedService;
						allUserConnectedServicesInitialized = true;
					} else {
						_allUserConnectedServices = [];
						allUserConnectedServicesInitialized = false;
					}
					defer.resolve();
				}, function (failure) {
					_userConnectedServices = {};
					defer.reject();
				});
			return defer.promise;
		}

		function getAllConnectedServices() {
			var defer = $q.defer();
			if (allUserConnectedServicesInitialized) {
				return $q.when(_allUserConnectedServices);
			}
			loadAllConnectedServices()
				.then(function (success) {
					defer.resolve(_allUserConnectedServices);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_CONNECTED_SERVICES");
				});
			return defer.promise;
		}

		function loadUserSignatureMappings() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/user-signature-maps')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user signature maps loaded');
					_this.userSignatureMappings = success.data.signatureMaps;
					defer.resolve();
				}, function (failure) {
					_this.userSignatureMappings = {};
					defer.reject();
				});
			return defer.promise;
		}

		//function loadUserSmtpAccounts() {
		//	return $http.get('~/api/v1/settings/user-smtp-accounts')
		//	.then(function (success) {
		//		//$log.debug('CoreDataSettings: user smtp accounts loaded');
		//		_userSmtpAccounts = success.data.smtpAccounts;
		//		userSmtpAccountsInitialized = true;
		//	}, function (failure) {
		//		_userSmtpAccounts = {};
		//		userSmtpAccountsInitialized = false;
		//	});
		//}

		//function getUserSmtpAccounts() {
		//	var defer = $q.defer();
		//	if (userSmtpAccountsInitialized) {
		//		return $q.when(_userSmtpAccounts);
		//	}
		//	loadUserSmtpAccounts()
		//	.then(function (success) {
		//		defer.resolve(_userSmtpAccounts);
		//	}, function (failure) {
		//		defer.reject("FAILED_TO_LOAD_SMTP_ACCOUNTS");
		//	});

		//	return defer.promise;
		//}

		function loadContentFiltering() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/content-filter-groups')
				.then(function (success) {
					_userContentFiltering = success.data.contentFilterGroups;
					contentFilteringInitialized = true;
					defer.resolve();
				}, function (failure) {
					_userContentFiltering = {};
					contentFilteringInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getContentFiltering() {
			var defer = $q.defer();
			if (contentFilteringInitialized) {
				return $q.when(_userContentFiltering);
			}
			loadContentFiltering()
				.then(function (success) {
					defer.resolve(_userContentFiltering);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_CONTENT_FILTERING");
				});
			return defer.promise;
		}

		function loadUserSignatures() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/user-signatures')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user signatures loaded');
					_this.userSignatures = success.data.signatureConfigs;
					defer.resolve();
				}, function (failure) {
					_this.userSignatures = {};
					defer.reject();
				});
			return defer.promise;
		}
		
		var loadSignatureVariables = function () {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/signature-variables')
				.then(function (success) {
					_signatureVariables = success.data.variables;
					signatureVariablesInitialized = true;
					defer.resolve();
				}, function (failure) {
					_signatureVariables = {};
					signatureVariablesInitialized = false;
					defer.reject();
				});
			return defer.promise;
		};

		var getSignatureVariables = function () {
			var defer = $q.defer();
			if (signatureVariablesInitialized) {
				return $q.when(_signatureVariables);
			}
			loadSignatureVariables()
			.then(function (success) {
				defer.resolve(_signatureVariables);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_SIGNATURE_VARIABLES");
			});

			return defer.promise;
		};

		function loadSyncDevices() {
			return $http
				.get("~/api/v1/settings/sync-devices")
				.then(function(success) {
					_userSyncDevices = success.data.devices;
					angular.forEach(_userSyncDevices,
						function(value, key) {
							value.lastSyncTimeUTC = moment(value.lastSyncTimeUTC);
							//this id is only used for tracking the selection for sync devices.
							value.id = _this.generateNewShortGuid();
						});
				},
				function() { _userSyncDevices = []; });
		}

		var syncDevicesDefer;
		function getSyncDevices() {
			if (syncDevicesDefer) return syncDevicesDefer.promise;

			syncDevicesDefer = $q.defer();
			loadSyncDevices()
				.then(function () {
					syncDevicesDefer.resolve(_userSyncDevices);
				}, function () {
					syncDevicesDefer.reject("FAILED_TO_LOAD_SYNC_DEVICES");
				})
				.finally(function () {
					syncDevicesDefer = null;
				});

			return syncDevicesDefer.promise;
		}

		function loadAvailableUserEvents() {
			var defer = $q.defer();
			$http.post("~/api/v1/settings/user-events")
				.then(function (success) {
					_availableUserEvents = success.data.events;
					availableUserEventsInitialized = true;
					defer.resolve();
				}, function () {
					_availableUserEvents = {};
					availableUserEventsInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		var getAvailableUserEventsDefer;
		function getAvailableUserEvents() {
			if (availableUserEventsInitialized) return $q.when(_availableUserEvents);
			if (getAvailableUserEventsDefer) return getAvailableUserEventsDefer.promise;

			getAvailableUserEventsDefer = $q.defer();

			loadAvailableUserEvents()
				.then(function() {
						getAvailableUserEventsDefer.resolve(_availableUserEvents);
					},
					function() {
						getAvailableUserEventsDefer.reject("FAILED_TO_LOAD_AVAILABLE_USER_EVENTS");
					})
				.finally(function() {
					getAvailableUserEventsDefer = null;
				});

			return getAvailableUserEventsDefer.promise;
		}

		function loadSpamIp4r() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/ip4r-lookup')
				.then(function (success) {
					_spamIp4r = success.data.ip4rLookup;
					spamIp4rInitialized = true;
					defer.resolve();
				}, function (failure) {
					_spamIp4r = {};
					spamIp4rInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getSpamIp4r() {
			var defer = $q.defer();
			if (spamIp4rInitialized) {
				return $q.when(_spamIp4r);
			}
			loadSpamIp4r()
			.then(function (success) {
				defer.resolve(_spamIp4r);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_SPAM_IP4R");
			});

			return defer.promise;
		}

		function loadUserEvents() {
			var defer = $q.defer();
			$http.post('~/api/v1/settings/event-hooks-by-owner')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user events loaded');
					userEventsInitialized = true;
					_userEvents = success.data.eventHooks;
					defer.resolve();
				}, function (failure) {
					_userEvents = {};
					userEventsInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}
		
		function getUserEvents() {
			var defer = $q.defer();
			if (userEventsInitialized) {
				return $q.when(_userEvents);
			}
			loadUserEvents()
			.then(function (success) {
				defer.resolve(_userEvents);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_USER_EVENTS");
			});

			return defer.promise;
		}

		function loadAviliableEventVars() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/event-variables')
				.then(function (success) {
					//$log.debug('CoreDataSettings: user event variables loaded');
					aviliableEventsVarsInitialized = true;
					_aviliableEventVars = success.data.variables;
					defer.resolve();
				}, function (failure) {
					_aviliableEventVars = {};
					aviliableEventsVarsInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getAviliableEventVars() {
			var defer = $q.defer();
			if (aviliableEventsVarsInitialized) {
				return $q.when(_aviliableEventVars);
			}
			loadAviliableEventVars()
			.then(function (success) {
				defer.resolve(_aviliableEventVars);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_EVENT_VARS");
			});

			return defer.promise;
		}

		function loadTimeZones() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/all-timezones')
				.then(function (success) {
					//$log.debug('CoreDataSettings: time zones loaded');
					_aviliableTimeZones = success.data.timeZones;
					timeZonesInitialized = true;
					defer.resolve();
				}, function (failure) {
					_aviliableTimeZones = {};
					timeZonesInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getTimeZones() {
			var defer = $q.defer();
			if (timeZonesInitialized) {
				return $q.when(_aviliableTimeZones);
			}
			loadTimeZones()
			.then(function (success) {
				defer.resolve(_aviliableTimeZones);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_TIME_ZONES");
			});
			return defer.promise;
		}

		this.getUserTimeZone = function () {
			var defer = $q.defer();
			var timeZone = {};
			if (timeZonesInitialized) {
				timeZone = $.grep(_aviliableTimeZones, function (zone) { return zone.index === _this.userSettings.timeZoneIndex });
				defer.resolve(timeZone);
			} else {
				getTimeZones()
				.then(function (success) {
					timeZone = $.grep(_aviliableTimeZones, function (zone) { return zone.index === _this.userSettings.timeZoneIndex });
					defer.resolve(timeZone);
				}, function(failure) {
					defer.reject("FAILED_TO_FIND_TIMEZONE");
				});
			}
			return defer.promise;
		};

		this.getTimeZone = function (index) {
			var defer = $q.defer();
			var timeZone = {};
			if (timeZonesInitialized) {
				timeZone = $.grep(_aviliableTimeZones, function (zone) { return zone.index === index });
				defer.resolve(timeZone);
			} else {
				getTimeZones()
				.then(function (success) {
					timeZone = $.grep(_aviliableTimeZones, function (zone) { return zone.index === _this.userSettings.timeZoneIndex });
					defer.resolve(timeZone);
				}, function (failure) {
					defer.reject("FAILED_TO_FIND_TIMEZONE");
				});
			}
			return defer.promise;
		};

		function loadAutoCompleteList() {
			var defer = $q.defer();
			$http.get("~/api/v1/settings/auto-complete-list")
				.then(function (success) {
					_autoCompleteList = success.data.emailData;
					autoCompleteListInitialized = true;
					defer.resolve();
				}, function () {
					_autoCompleteList = [];
					autoCompleteListInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		var getAutoCompleteListDefer;
		function getAutoCompleteList() {
			if (autoCompleteListInitialized) {
				return $q.when(_autoCompleteList);
			}

			if (getAutoCompleteListDefer) return getAutoCompleteListDefer.promise;

			getAutoCompleteListDefer = $q.defer();

			loadAutoCompleteList()
				.then(function () {
					getAutoCompleteListDefer.resolve(_autoCompleteList);
					getAutoCompleteListDefer = null;
				}, function () {
					getAutoCompleteListDefer.reject("FAILED_TO_LOAD_AUTO_COMPLETE_LIST");
					getAutoCompleteListDefer = null;
				});

			return getAutoCompleteListDefer.promise;
		}

		function loadRetrievalAccounts() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/retrieval-accounts')
				.then(function (success) {
					_retrievalAccounts = success.data.accounts;
					retrievalAccountsInitialized = true;
					defer.resolve();
				}, function (failure) {
					_retrievalAccounts = [];
					retrievalAccountsInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getRetrievalAccounts() {
			var defer = $q.defer();
			if (retrievalAccountsInitialized) {
				return $q.when(_retrievalAccounts)
			}
			loadRetrievalAccounts()
			.then(function (success) {
				defer.resolve(_retrievalAccounts);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_RETRIEVAL_ACCOUNTS");
			});
			return defer.promise;
		}

		function getMigrationStatus() {
			var temp = {
				messagesImported: _messagesImported,
				contactsImported: _contactsImported,
				calendarsImported: _calendarsImported,
				tasksImported: _tasksImported,
				notesImported: _notesImported,
				doneImporting: _doneImporting
			};

			if (temp.doneImporting === true)
				_this.resetMigrationStatus();

			return temp;
		}

		function setMigrationStatus(data) {
			_doneImporting = false;
			
			angular.forEach(data, function (item) {
				switch (item.type) {
					case 0: // Migration
						_doneImporting = item.done;
						break;
					case 1: // Email
						_messagesImported = item.count;
						break;
					case 2: // Calendars
						_calendarsImported = item.count;
						break;
					case 3: // Contacts
						_contactsImported = item.count;
						break;
					case 4: // Tasks
						_tasksImported = item.count;
						break;
					case 5: // Notes
						_notesImported = item.count;
						break;
					default:
						break;
				}
			});
			$rootScope.$broadcast("migrationRefresh");
		}

		function loadResources() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/shared-resources')
				.then(function (success) {
					_resources = success.data.sharedResources;
					resourcesInitialized = true;
					defer.resolve();
				}, function (failure) {
					_resources = [];
					resourcesInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		var getResourceDefer = null;
		function getResources() {
			if (resourcesInitialized) return $q.when(_resources);
			if (getResourceDefer) return getResourceDefer.promise;

			getResourceDefer = $q.defer();
			loadResources()
				.then(function (success) {
					getResourceDefer.resolve(_resources);
				}, function (failure) {
					getResourceDefer.reject("FAILED_TO_LOAD_RESOURCES");
				});

			getResourceDefer.promise
				.finally(function () {
					getResourceDefer = null;
				});

			return getResourceDefer.promise;
		}

		function getUserList() {
			return $http
				.get('~/api/v1/settings/list-users')
				.then(function (success) {
					_userList = success.data.userData;
					return _userList;
				}, function (failure) {
					_userList = [];
					return [];
				});
		}

		function  queryUsers(query, ignoreSelf) {
			var defer = $q.defer();
			if (ignoreSelf == undefined || (ignoreSelf !== true && ignoreSelf !== false))
				ignoreSelf = true;
			$http
				.get("~/api/v1/settings/search-users/" + query + "/" + ignoreSelf)
				.then(function (success) {
					var results = success.data.userData;
					var names = results.map(function (v) { return { userName: v.userName, emailAddress: v.emailAddress, fullName: v.fullName } });
					defer.resolve(names);
				}, function (failure) {
					defer.resolve([]);
				});

			return defer.promise;
		}

		function loadUserGroups() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/all-user-groups')
				.then(function (success) {
					_userGroups = success.data.userGroupCollection.customUserGroups;
					userGroupsInitialized = true;
					defer.resolve();
				}, function (failure) {
					_userGroups = [];
					userGroupsInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getUserGroups() {
			var defer = $q.defer();
			if (userGroupsInitialized) {
				return $q.when(_userGroups);
			}
			loadUserGroups()
				.then(function (success) {
					defer.resolve(_userGroups);
				}, function (failure) {
					defer.reject("FAILED_TO_LOAD_USER_GROUPS");
				});
			return defer.promise;
		}

		function loadMappedResources() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/shares-available-to-user')
				.then(function (success) {
					_mappedResources = success.data.shareConnectorData;
					mappedResourcesInitialized = true;
					defer.resolve();
				}, function (failure) {
					_mappedResources = {};
					mappedResourcesInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		var getMappedResourcesDefer;
		function getMappedResources() {
			if (getMappedResourcesDefer) return getMappedResourcesDefer.promise;
			if (mappedResourcesInitialized) return $q.when(_mappedResources);

			getMappedResourcesDefer = $q.defer();
			loadMappedResources()
				.then(function () {
					getMappedResourcesDefer.resolve(_mappedResources);
				}, function () {
					getMappedResourcesDefer.reject("FAILED_TO_LOAD_RESOURCES");
				})
				.finally(function () {
					getMappedResourcesDefer = null;
				});
			return getMappedResourcesDefer.promise;
		}

		function loadDomainAliases() {
			var defer = $q.defer();
			$http.get('~/api/v1/settings/domain/domain-aliases')
				.then(function (success) {
					_domainAliases = success.data.domainAliasData;
					domainAliasesInitialized = true;
					defer.resolve();
				}, function (failure) {
					_domainAliases = {};
					domainAliasesInitialized = false;
					defer.reject();
				});
			return defer.promise;
		}

		function getDomainAliases() {
			var defer = $q.defer();
			if (domainAliasesInitialized) {
				return $q.when(_domainAliases);
			}
			loadDomainAliases()
			.then(function (success) {
				defer.resolve(_domainAliases);
			}, function (failure) {
				defer.reject("FAILED_TO_LOAD_DOMAIN_ALIASES");
			});
			return defer.promise;
		}

		//#endregion

		//#region Constants
		this.settingAdjustWait = 2000;
		this.settingBlurWait = 0;
		//#endregion

		this.changePageReset = function () {
			_this.filteredCards.length = 0;
			_this.cards.length = 0;
			_this.selectedCards.length = 0;
			_this.newItem = undefined;
			_this.editItem = undefined;
			_this.deleteItems = undefined;
			_this.searchItems = undefined;
			_this.searchEnabled = false;
			_this.searchText = undefined;
			_this.searchPredicate = undefined;
			_this.selectMode = false;
			_this.editingItem = false;
			_this.deselectAll = coreDeselectAll;
			_this.selectAll = coreSelectAll;
			_this.backAction = undefined;
		}

		//#region Cards
		this.cards = [];
		this.filteredCards = [];

		this.newItem = undefined;
		this.editItem = undefined;
		this.deleteItems = undefined;
		this.searchItems = undefined;
		this.searchText = undefined;
		this.searchEnabled = false;
		this.searchPredicate = undefined;
		this.editingItem = false;
		this.deselectAll = undefined;
		this.selectAll = undefined;
		this.backAction = undefined;

		//virtual list data
		this.totalCount = _this.cards.length;
		this.listDataCache = {};
		this.listDataProvider = function (obj) {
			//TODO: Manage loading contacts dynamically instead of pulling from a full set of data
			var subset = [];
			var j = 0;
			var c = _this.getFilteredCards();
			for (var i = obj.start; i < (obj.start + obj.take) ; i++) {
				//Note: splicing will make a copy, I want a reference.
				if (c[i] == undefined) {
					break;
				}
				subset[j++] = c[i];
			}
			obj.defer.resolve(subset);
			return obj.defer.promise;
		}
		this.listController = {};

		this.getFilteredCards = function() {
			if (_this.searchEnabled && _this.searchPredicate) {
				_this.filteredCards = $filter("filter")(_this.cards, _this.searchPredicate);
			} else {
				_this.filteredCards = _this.cards;
			}
			return _this.filteredCards;
		};

		this.updateFilteredCards = function () {
			_this.totalCount = _this.cards.length;
			_this.filteredCards = _this.getFilteredCards();
			if (_this.listController.reset && _this.listController.updateDisplayList) {
				_this.listController.reset();
				_this.listController.updateDisplayList();
			}
		};

		//#region Select functionality 
		this.selectMode = false;
		this.selectedCards = [];

		this.setSelectMode = function (enable) {
			_this.selectedCards.length = 0;
			$("md-card").removeAttr("style");
			_this.selectMode = enable;
		};

		// Is Card Selected?
		this.isCardSelected = function (selectedCard) {
			var temp = $.map(selectedCard, function (element, index) { return selectedCard.id; });
			var index = _this.selectedCards.indexOf(selectedCard);
			return index > -1;
		};

		//Deselect all from current page
		function coreDeselectAll() {
			var indiciesToRemove = [];
			$.each(_this.selectedCards, function (index, value) {
				var indexOfCard = _this.filteredCards.indexOf(value);
				if (indexOfCard >= 0) {
					indiciesToRemove.push(index);
				};
			});
			$.each(indiciesToRemove.reverse(), function (index, value) {
				_this.selectedCards.splice(value, 1);
			});
			//$scope.selectedCards.length = 0;
		}

		// Select all
		function coreSelectAll() {
			$.each(_this.filteredCards, function (index, value) {
				if (_this.selectedCards.indexOf(value) < 0) {
					_this.selectedCards.push(value);
				};
			});
		}
		//#endregion
		//#endregion

		//#region Helper Functions
		this.validateEmailAddress = function (emailAddress) {
			return emailValidationService.isValidEmail(emailAddress);
		};

		this.validateHexSixColor = function (color) {
			return (/^#[a-fA-F0-9]{6}$/).test(color);
		};

		//16777215 is the equivalent of ffffff in decimal.
		this.generateRandomHexColor = function (depth) {
			if (!depth) { depth = 0 }
			if (depth > 2) { return '#CDCDCD' } //just in case something goes wrong
			var random = '#' + (Math.floor(Math.random() * 16777215).toString(16));
			var difLength = 7 - random.length;
			if (difLength !== 0) {
				for (var i = 0; i < difLength; ++i) { random = random + '0'; }
			}
			if (_this.validateHexSixColor(random)) {
				return random;
			} else {
				random = generateRandomHexColor(depth + 1);
				return random;
			}
		};

		//Note that guids generated this way are not guarenteed to be unique and should not be used for ids sent to the api.
		this.generateNewGuid = function () {
			var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			return guid;
		};

		this.generateNewShortGuid = function () {
			var guid = 'xxxx-2xxx-yxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			return guid;
		};

		this.parseUserTimeSpan = function (time) {
			var valid = /\d/.test(time);
			if (!valid) {
				return '';
			}
			var split = time.split(':');
			if (parseInt(split[0]) > 24) {
				split[0] = '24';
			}
			if (parseInt(split[0]) < 0) {
				split[0] = '00';
			}
			if (!split[1]) {
				return '';
			}
			var split2 = split[1].split(' ');
			if (parseInt(split2[0]) > 59) {
				split2[0] = '59';
			}
			if (parseInt(split2[0]) < 0) {
				split2[0] = '00';
			}
			if (parseInt(split[0]) === 24 && parseInt(split2[0]) > 0) {
				split2[0] = '00';
			}
			split[1] = split2.join(' ');
			var time = split.join(':');
			//var timeOffset = new Date().getTimezoneOffset() / 60;
			var parsed = moment(time, ['h:m a', 'H:m', 'hh:mm A']);
			if (parsed.isValid()) {
				return parsed;
			} else {
				return '';
			}
		};

		this.addPermissions = function (shareType, resourceName, amount) {
			for (var i = 0; i < _resources.length; i++) {
				if (_resources[i].shareType === shareType && _resources[i].resourceName.toLowerCase() === resourceName.toLowerCase()) {
					_resources[i].permissions += amount;
					return;
				}
			}

			_resources.push({ permissions: amount, shareType: shareType, resourceName: resourceName });
		};

		this.removePermissions = function (shareType, resourceName, amount) {
			for (var i = 0; i < _resources.length; i++) {
				if (_resources[i].shareType === shareType && _resources[i].resourceName.toLowerCase() === resourceName.toLowerCase()) {
					_resources[i].permissions -= amount;
					if (_resources[i].permissions < 0) _resources[i].permissions = 0;
					return;
				}
			}
		};

		this.removeAllPermissions = function (shareType, resourceName) {
			for (var i = 0; i < _resources.length; i++) {
				if (_resources[i].shareType === shareType && _resources[i].resourceName.toLowerCase() === resourceName.toLowerCase()) {
					_resources[i].permissions = 0;
					return;
				}
			}
		};

		this.resetResources = function () {
			_mappedResources = {};
			mappedResourcesInitialized = false;
			_resources = [];
			resourcesInitialized = false;
		};
		//#endregion
	}

})();