(function () {
	"use strict";

	// Processes Enter Key commands on input controls. 

	// Usage Example:
	// <input type="text" st-enter-key="someFunction();" />

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stFluid", stFluidDirective);

	function stFluidDirective($window) {
		return function (scope, element, attrs) {
			$(element).addClass('st-fluid');

			var w = angular.element($window);
			scope.$watch(function () {
				return {
					'h': w.height(),
					'w': w.width()
				};
			}, function (newValue, oldValue) {
				if (scope.windowWidth < 1000)
					element.addClass('st-small-fluid');
				else
					element.removeClass('st-small-fluid');
			}, true);

			w.bind('resize', function () {
				scope.$applyAsync();
			});
		};
	}

})();