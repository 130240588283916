+(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("messageArchiveSearchViewController", messageArchiveSearchViewController);

    function messageArchiveSearchViewController($rootScope, $scope, $http, $timeout, $filter, $mdDialog, $translate, $compile, $q, $state, 
        coreLicensing, coreDataDomainSettings, coreDataFileStorage, userDataService, errorHandling, successHandling, popupService, NgTableParams, gridCheckboxes, emailArchiveFunctions, emailNavigation) {

		var vm = this;
		const domainState = $state &&
			$state.$current &&
			$state.$current.name &&
			$state.$current.name.toLowerCase().startsWith("index.sysadmin-manage.dashboard.domain-specific"); 
		vm.isSysAdmin = userDataService.user.isSysAdmin && !domainState;
		const domainId = domainState ? $state.params.id : userDataService.user.domain;
		$scope.archiveType = vm.isSysAdmin ? "sysadmin" : "domain";
		$scope.archiveName = vm.isSysAdmin ? "all" : domainId;

		$scope.searchResults = [];
		$scope.startDate = moment().local().subtract(7, "d").toDate();
		$scope.endDate = moment().local().toDate();
		$scope.percentProgress = 100;

        vm.searchGuid = $state.params.searchGuid;
        vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;

		vm.searchingAdmin = userDataService.user.emailAddress + (vm.isSysAdmin ? "@" : "");
		vm.showingResults = false;
		vm.searchPerformed = false;
		vm.searchParams = { startIndex: 0, count: 0, sortType: null, ascending: true };
		vm.tableParams = new NgTableParams(
			{
				sorting: { receivedDateUtc: "asc" }, // { fieldToSortBy: sortOrder }
				count: 25 // Default count
			},
			{
				getData: queryData, // Gets the data from a promise resolution
				counts: $rootScope.commonTableCounts
			}
		);
		vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.ignoreItemDelegate = function (item) { return item.receivedDateUtc < $scope.cutoffDate; }
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyToMailbox = copyToMailbox;
		vm.copyAll = copyAll;
		vm.downloadMessages = downloadMessages;
		vm.downloadAll = downloadAll;
        vm.searchArgsToSummaryText = searchArgsToSummaryText;
		activate();

        /////////////////////

        function activate() {
            if (!vm.isEnterprise)
                return;

			$rootScope.$on("signalR.mailHub.client.messageArchiveSearchUpdate", function (e, searchGuid) {
				if (searchGuid === vm.searchGuid) {
					vm.tableParams.reload();
				}
			});
		}
		function editItem(selectedCard, ev) {
             if (selectedCard.receivedDateUtc < $scope.cutoffDate) return;
           const packet = emailNavigation.makeArchivePacket(vm.searchGuid, selectedCard.id);
            const url = emailNavigation.getPopoutUrl(packet);
			var newWindow = window.open(url, "emailID" + selectedCard.id, "resizable=1, " + popupService.dimensions.email);
		}
		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();
            if (item.receivedDateUtc < $scope.cutoffDate) return;

            var items = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val == item.id; })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "copyToMailbox", click: copyToMailbox, params: $scope.dropdownEvent, translateKey: "COPY_TO_MAILBOX" },
                { key: "downloadMessages", click: downloadMessages, translateKey: "DOWNLOAD" }
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        async function copyToMailbox(ev) {
            if (!vm.isEnterprise) {
                errorHandling.report("ENTERPRISE_ONLY");
                return;
            }

            var ids = [];
            if (vm.checkboxes.items) {
                for (var key in vm.checkboxes.items) {
                    ids.push(Number(key));
                }
            }

            if (ids.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            }

            try {
	            const wasCopied = await emailArchiveFunctions.copyToMailbox(vm.searchGuid, ids, ev);
                if (wasCopied)
                    successHandling.report("ACTION_SUCCEEDED");
            }
            catch (err) {
                errorHandling.report(err);
            }
        }

        async function copyAll(ev) {
            if (!vm.isEnterprise) {
                errorHandling.report("ENTERPRISE_ONLY");
                return;
            }

            try {
	            const wasCopied = await emailArchiveFunctions.copyAllToMailbox(vm.searchGuid, ev);
                if (wasCopied)
                    successHandling.report("ACTION_SUCCEEDED");
            }
            catch (err) {
                errorHandling.report(err);
            }
        }

        async function downloadMessages() {
            var ids = [];
            if (vm.checkboxes.items) {
                for (var key in vm.checkboxes.items) {
                    ids.push(Number(key));
                }
            }

            if (ids.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            }

            if (ids.length > 1) {
                var confirm = $mdDialog.confirm()
                    .textContent($filter("translate")("CONFIRMATIONS_MULTI_GENERIC", { count: ids.length }))
                    .cancel($filter("translate")("CANCEL"))
                    .ok($filter("translate")("OK"))
                    .title($filter("translate")("CONFIRMATION_REQUIRED"));
                try {
                    await $mdDialog.show(confirm);
                } catch (err) {
                    return;
                }
            }

            try {
	            await emailArchiveFunctions.downloadEmlFile(vm.searchGuid, ids);
            }
            catch (err) {
                errorHandling.report(err);
            }
        }

        async function downloadAll() {
            if (vm.totalSearchResultCount === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            }

            var confirm = $mdDialog.confirm()
                .textContent($filter("translate")("CONFIRMATIONS_MULTI_GENERIC", { count: vm.totalSearchResultCount }))
                .cancel($filter("translate")("CANCEL"))
                .ok($filter("translate")("OK"))
                .title($filter("translate")("CONFIRMATION_REQUIRED"));
            try {
                await $mdDialog.show(confirm);
            } catch (err) {
                return;
            }
            try {
                await emailArchiveFunctions.downloadAll(vm.searchGuid)
            }
            catch (err) {
                errorHandling.report(err);
            }

		}

        function queryData(params) {

			vm.searchParams.startIndex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();
			vm.searchParams.searchGuid = vm.searchGuid;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = $scope.percentProgress === 100 ? k : "receivedDateUtc";
				vm.searchParams.ascending = $scope.percentProgress === 100 ? params.sorting()[k] !== "desc" : true;
				break;
			}
			
			$rootScope.spinner.show();
			return $http
				.post("~/api/v1/settings/" + $scope.archiveType + "/message-archive-results", JSON.stringify(vm.searchParams))
				.then(onSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSuccess(success) {
				vm.searchResultCount = success.data.searchResults.results.length;
				$scope.percentProgress = success.data.searchResults.percentProgress;
				$scope.matchLimitExceeded = success.data.searchResults.matchLimitExceeded;
				vm.totalSearchResultCount = success.data.searchResults.totalItems;
				params.total(vm.totalSearchResultCount);
				$scope.searchDescription = success.data.searchResults.searchDescription;
                $scope.searchSummary = searchArgsToSummaryText(success.data.searchResults.searchArgs);
                $scope.cutoffDate = success.data.searchResults.cutoffDate;
                $scope.disableActions = vm.searchResultCount === 0 ||
	                !success.data.searchResults.results.some(
		                function(row) { return row.receivedDateUtc > $scope.cutoffDate; });
				if ($scope.percentProgress >= 100 && vm.totalSearchResultCount === 0)
						vm.noResults = true;
				return success.data.searchResults.results;
			}
			function updateSearchArgs(searchArgs) {
				$scope.startDate = moment.tz(searchArgs.startDateUtc, "UTC").local().toDate();
				$scope.endDate = moment.tz(searchArgs.endDateUtc, "UTC").local().toDate();
				$scope.from = searchArgs.from;
				$scope.to = searchArgs.to;
				$scope.subject = searchArgs.subject;
				$scope.summary = searchArgsToSummaryText(searchArgs);

			}
		}
		function searchArgsToSummaryText(searchArgs) {
			const andy = $translate.instant("AND").toLocaleLowerCase();
            const suffix = $translate.instant("BETWEEN_X_AND_Y", { 
                x: $filter("date")(searchArgs.startDateUtc, "shortDate"),
                y: $filter("date")(searchArgs.endDateUtc, "shortDate") 
            });
            const sentByClause = searchArgs.to ? `${$translate.instant("SENT_TO")}: ${searchArgs.to}` : "";
            const sentFromClause = searchArgs.from ? `${$translate.instant("SENT_FROM")}: ${searchArgs.from}` : "";
            const senderClause = sentByClause && sentFromClause
	            ? `${sentByClause} ${andy} ${sentFromClause}`
	            : sentByClause
	            ? sentByClause
	            : sentFromClause
	            ? sentFromClause
	            : "";

            const subjectClause = searchArgs.subject
	            ? $translate.instant('SUBJECT_CONTAINS_X', { x: searchArgs.subject })
	            : "";
            const matchClause = subjectClause && senderClause ? `${$translate.instant("WHERE").toLocaleLowerCase()} ${subjectClause} ${senderClause}` 
	            : subjectClause ?  `${$translate.instant("WHERE").toLocaleLowerCase()} ${subjectClause}` 
	            : senderClause ?  ` ${senderClause}` 
	            : "";
            return matchClause && suffix
	            ? `${$translate.instant("MESSAGES")} ${$filter("htmlUnescape")(matchClause)} ${suffix}`
	            : `${$translate.instant("ALL")} ${$translate.instant("MESSAGES").toLocaleLowerCase()} ${suffix}`;
		}
    };
})();