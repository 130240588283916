(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataCategories", coreDataCategories);

    function coreDataCategories($rootScope, $http, $q, $filter, $log, claimsService, $state) {
        var vm = this;
        var isInitialized = false;
        var areCategoriesLoaded = false;
        const _categoryData = {
            local: [],
            sharedCategories: {}
        };
        vm.data = {
            get all() {
                loadCategories();
                return areCategoriesLoaded ? _categoryData : undefined;
            }
        }
        vm.categoryOwner = undefined;
        isInitialized = false;
        vm.ignoreCategoriesModified = {
            requested: moment(),
            ignored: moment()
        }
        vm.categoryColors = [
            { value: -1, rgb: null, name: "NONE" },
            { value: 0, rgb: "#D6252E", name: "CATEGORY_RED" },
            { value: 1, rgb: "#F06C15", name: "CATEGORY_ORANGE" },
            { value: 2, rgb: "#FFCA4C", name: "CATEGORY_PEACH" },
            { value: 3, rgb: "#FFFE3D", name: "CATEGORY_YELLOW" },
            { value: 4, rgb: "#4AB63F", name: "CATEGORY_GREEN" },
            { value: 5, rgb: "#40BD95", name: "CATEGORY_TEAL" },
            { value: 6, rgb: "#859A52", name: "CATEGORY_OLIVE" },
            { value: 7, rgb: "#3267B8", name: "CATEGORY_BLUE" },
            { value: 8, rgb: "#613DB4", name: "CATEGORY_PURPLE" },
            { value: 9, rgb: "#A34E78", name: "CATEGORY_MAROON" },
            { value: 10, rgb: "#C4CCDD", name: "CATEGORY_STEEL" },
            { value: 11, rgb: "#8C9CBD", name: "CATEGORY_DARKSTEEL" },
            { value: 12, rgb: "#C4C4C4", name: "CATEGORY_GRAY" },
            { value: 13, rgb: "#A5A5A5", name: "CATEGORY_DARKGRAY" },
            { value: 14, rgb: "#1C1C1C", name: "CATEGORY_BLACK" },
            { value: 15, rgb: "#AF1E25", name: "CATEGORY_DARKRED" },
            { value: 16, rgb: "#B14F0D", name: "CATEGORY_DARKORANGE" },
            { value: 17, rgb: "#AB7B05", name: "CATEGORY_DARKPEACH" },
            { value: 18, rgb: "#999400", name: "CATEGORY_DARKYELLOW" },
            { value: 19, rgb: "#35792B", name: "CATEGORY_DARKGREEN" },
            { value: 20, rgb: "#2E7864", name: "CATEGORY_DARKTEAL" },
            { value: 21, rgb: "#5F6C3A", name: "CATEGORY_DARKOLIVE" },
            { value: 22, rgb: "#2A5191", name: "CATEGORY_DARKBLUE" },
            { value: 23, rgb: "#50328F", name: "CATEGORY_DARKPURPLE" },
            { value: 24, rgb: "#82375F", name: "CATEGORY_DARKMAROON" }
        ];

        // Functions
        vm.init = init;
        vm.reset = reset;
        vm.setCategories = setCategories;
        vm.getCategories = getCategories;
        vm.getUsersCategories = getUsersCategories;
        vm.getCategoryColor = getCategoryColor;
        vm.addRgbColorsToCategories = addRgbColorsToCategories;
        vm.getCategoryByName = getCategoryByName;
        vm.generateNameString = generateNameString;
        // Startup 

        var initDefer = null;

        function init() {
            if (initDefer)
                return initDefer.promise;

            if (isInitialized)
                return $q.when();

            initDefer = $q.defer();
            var promise = initDefer.promise;

            loadCategories()
                .then(function () {
                    isInitialized = true;

                    initDefer.resolve();
                    initDefer = null;
                }, function (failure) {
                    isInitialized = false;

                    initDefer.reject(failure);
                    initDefer = null;
                });

            return promise;
        }

        function reset() {
            isInitialized = false;
            areCategoriesLoaded = false;
            _categoryData.local = [];
            _categoryData.sharedCategories = {};
        };

        async function loadCategories() {
            if (!areCategoriesLoaded) {
                await getCategoriesFromServer();
                await getSharedCategoriesFromServer();
                areCategoriesLoaded = true;
            }
        }

        function getCategoriesFromServer() {
            return $http.get("~/api/v1/categories/user-category-settings")
                .then(function (success) {
                    let results = success.data.categorySettings.categories || [];
                    angular.forEach(success.data.categorySettings.categories || [], function (cat) {
                        cat.master = true;
                        const translated = $filter('translate')(cat.name);
                        cat.translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
                    });
                    _categoryData.local = $filter("orderBy")(results, "translatedName");
                }, function (failure) {
                    return failure;
                });
        };

        function getSharedCategoriesFromServer() {
            return $http.get("~/api/v1/categories/shares-category-settings")
                .then(function (success) {
                    angular.forEach(success.data.sharedCategorySettings || {}, 
                        function (sharedCats, user) {
                            angular.forEach(sharedCats.categories, 
                                function (cat) {
                                    const translated = $filter('translate')(cat.name);
                                    cat.translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
                                });
                            _categoryData.sharedCategories[user] = $filter("orderBy")(sharedCats.categories, "translatedName");
                        });
                }, function (failure) {
                    return failure;
                });
        }

        function getCategories() {
            return $.extend(false, [], !vm.categoryOwner || vm.categoryOwner === claimsService.getEmailAddress() ? 
                _categoryData.local : 
                _categoryData.sharedCategories[vm.categoryOwner] );
        };

        async function setCategories(categories) {
            try {
                var args = { categories: categories, defaultCategory: categories.length > 0 ? categories[0].name : null };
                await $http.post("~/api/v1/categories/user-category-settings", args);
                areCategoriesLoaded = false;
                loadCategories(); // The prior implementation didn't wait for loadCategories
            } catch (e) {
                reset();
                throw (e);
            }
        };

        function getUsersCategories(user) {
                return $.extend(true, [], !user || user === claimsService.getEmailAddress() ? _categoryData.local : _categoryData.sharedCategories[user] );
        };

        function getCategoryColor(idx) {
            return (idx + 1) in vm.categoryColors ? vm.categoryColors[idx + 1] : vm.categoryColors[0];
        }

        function getCategoryByName(categoryName) {
            if (!categoryName) return null;
            const categories = Object.values(getCategories());
            return categories.find(cat => cat.name.toUpperCase() === categoryName.toUpperCase());
        }

        function addRgbColorsToCategories(categories) {
            if (!categories) return;
            categories.forEach((v) => {
                let cat = getCategoryByName(v.name);
                if (cat)
                    v.rgbColor = getCategoryColor(cat.colorIndex).rgb;
            });
        }

        function generateNameString(categories) {
            if (!categories)
                return '';
            categories.sort((a, b) => !a || !b ? 0 : (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)));
            let retVal = '';
            categories.forEach((v) => {
                if (retVal !== '')
                    retVal += ', ';
                retVal += v.name;
            });
            return retVal;
        }
    }

})();