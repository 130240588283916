(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("onlyOfficeConnectionsController", onlyOfficeConnectionsController);

    function onlyOfficeConnectionsController($state, $rootScope, $scope, $http, $filter, $compile, $mdDialog, NgTableParams,
        gridCheckboxes, userDataService, successHandling, errorHandling) {

        var vm = this;
        vm.searchText = '';
        vm.searchParams = {
            domain: vm.domain,
            skip: 0,
            take: 0,
            search: null,
            sortField: null,
            sortDescending: false
        };
        vm.maxResyncRequestsAllowed = 25;
        vm.searchItems = searchItems;
        vm.refresh = refresh;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.dropConnections = dropConnections;
        vm.enable = enable;
        vm.disable = disable;
        vm.enableAccess = $filter('translate')('ENABLE_EAS_ACCESS');
        vm.disableAccess = $filter('translate')('DISABLE_EAS_ACCESS');
        vm.getAuthIps = getAuthIps;
        vm.copyAddress = copyAddress;
        vm.getonlyofficedata = getonlyofficedata;

        activate();

        function activate() {
            vm.tableParams = new NgTableParams(
                {
                    sorting: { connections: 'desc' },
                    count: 25
                }, {
                getData: queryData,
                counts: $rootScope.commonTableCounts
            });
            vm.checkboxes = gridCheckboxes.init('emailAddress');
            vm.checkboxes.table = vm.tableParams;
            refresh();
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

        function queryData(params) {
            return getonlyofficedata(vm.domain).then(function (data) {
                params.total(data.total);
                return data.items;
            });
        }

        function refresh() {
            vm.tableParams.reload();
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            var items = vm.checkboxes.getCheckedItems();

            if ((items.length > 1 && !_.some(items, function (val) { return val === item.emailAddress })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.emailAddress];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'dropConnections', click: dropConnections, translateKey: 'DROP_CONNECTIONS' },
                { divider: true },
                { key: 'authIps', click: getAuthIps, params: $scope.dropdownEvent, translateKey: 'VIEW_AUTHENTICATED_IPS' },
                { key: 'copyAddress', click: copyAddress, params: $scope.dropdownEvent, translateKey: 'COPY_ADDRESS' }
            ];

            if (true) {
                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        //function getonlyofficedata(params) {
        //    return $http.get('~/api/v1/settings/domain/onlyoffice-connections-summary', { params: params })
        //        .then(response => {
        //            if (response.data && response.data.message) {
        //                return response.data.message;
        //            } else {
        //                throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
        //            }
        //        })
        //        .catch(error => {
        //            console.error("Error obtaining data:", error);
        //            throw error;
        //        });
        //}

        function dropConnections() {
            // Implement drop connections functionality
        }

        function enable() {
            // Implement enable functionality
        }

        function disable() {
            var foundRows = [];
            for (var key in vm.checkboxes.items) {
                if (vm.checkboxes.items[key]) {
                    foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.emailAddress === key }));
                }
            }

            if (foundRows.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            } else {
                doDisableUsers();
            }

            function doDisableUsers() {
                // Implement disable users functionality
            }
        }

        function getAuthIps(event, data) {
            var emailAddress = "";
            if (!data) {
                if (vm.checkboxes.getCheckedItems().length === 1) {
                    emailAddress = vm.checkboxes.getCheckedItems()[0];
                } else {
                    emailAddress = event.currentTarget.childNodes[4].innerText;
                }
            } else {
                emailAddress = data.emailAddress;
            }

            $mdDialog.show({
                locals: {
                    selectedUserEmailAddress: encodeURIComponent(emailAddress)
                },
                controller: "authIpsController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/domain-settings/users-connections/modals/auth-ips.dlg.html",
                targetEvent: event
            }).then(function () { }, function () { });
        }

        function copyAddress(event) {
            var address = "";
            if (vm.checkboxes.getCheckedItems().length === 1) {
                address = vm.checkboxes.getCheckedItems()[0];
            } else {
                address = event.currentTarget.childNodes[4].innerText;
            }

            var textArea = document.createElement('textarea');
            textArea.value = address;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            successHandling.report('COPIED_TO_CLIPBOARD');
        }
    }
})();
