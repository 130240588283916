(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditGreylistingController", sysAdminEditGreylistingController);

	function sysAdminEditGreylistingController($scope, $mdDialog, $filter, $translate, $http,
		filterType, countryCode, ipAddress, description, isNew) {
		$scope.isNew = isNew;
		$scope.filterType = filterType;
		$scope.countryCode = countryCode;
		$scope.ipAddress = ipAddress;
		$scope.description = description;
		$scope.isValid = true;
		$scope.filterOptions = [
			{ value: 0, translation: "IP_ADDRESS"},
			{ value: 1, translation: "COUNTRY_CODE" },
		];

	    // Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.deleteItem = deleteItem;

		activate();

	    /////////////////////

		function activate() {
		    getCountryCodes();
		}

		function save(scope) {
			if (!($scope.isValid = scope.userForm.$valid)) {
				return;
			}
			$mdDialog.hide({
				filterType: $scope.filterType,
				countryCode: $scope.countryCode,
				ipAddress: $scope.ipAddress,
				description: $scope.description,
			});
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function deleteItem() {
			$mdDialog.hide({
				deleteItem: true
			});
		};

		function getCountryCodes() {
			$http
				.get('~/api/v1/settings/sysadmin/unused-country-codes')
				.then(function (success) {
					$scope.countryOptions = success.data.results;
					for (var i = 0; i < $scope.countryOptions.length; i++) {
						var countryCode = $scope.countryOptions[i];
						$scope.countryOptions[i] = { value: countryCode, translation: $translate.instant("COUNTRY_CODE_" + countryCode) + " (" + countryCode + ")" };
					}

					if ($scope.countryCode)
						$scope.countryOptions.push({ value: $scope.countryCode, translation: $translate.instant("COUNTRY_CODE_" + $scope.countryCode) + " (" + $scope.countryCode + ")" });

					$scope.countryOptions = $filter("orderBy")($scope.countryOptions, "translation");

					if (!$scope.countryCode)
						$scope.countryCode = $scope.countryOptions[0].value;
				}, function () {});
		}
	}

})();
