(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("modifyProtocolAccess", modifyProtocolAccess);

    function modifyProtocolAccess($scope, $http, $filter, $mdDialog, $translate, errorHandling, items) {
        var vm = this;
        $scope.cancel = cancel;
        $scope.save = save;
        $scope.items = items;
        $scope.webmailEnabled = false;
        $scope.smtpEnabled = false;
        $scope.imapEnabled = false;
        $scope.popEnabled = false;
        $scope.xmppEnabled = false;
        $scope.easEnabled = false;
        $scope.mapiEwsEnabled = false;
        $scope.webdavEnabled = false;

        $scope.webmailEnabledTranslated = $filter("htmlUnescape")($translate.instant("WEBMAIL"));
        $scope.smtpEnabledTranslated = $filter("htmlUnescape")($translate.instant("SMTP"));
        $scope.imapEnabledTranslated = $filter("htmlUnescape")($translate.instant("IMAP"));
        $scope.popEnabledTranslated = $filter("htmlUnescape")($translate.instant("POP"));
        $scope.xmppEnabledTranslated = $filter("htmlUnescape")($translate.instant("XMPP"));
        $scope.easEnabledTranslated = $filter("htmlUnescape")($translate.instant("ACTIVESYNC"));
        $scope.mapiEwsEnabledTranslated = $filter("htmlUnescape")($translate.instant("MAPI_EWS"));
        $scope.webdavEnabledTranslated = $filter("htmlUnescape")($translate.instant("WEBDAV"));

        function cancel() {
            $mdDialog.cancel();
        }

        function save() {
            var params = JSON.stringify({
                items: items,
                webmailEnabled: $scope.webmailEnabled,
                smtpEnabled: $scope.smtpEnabled,
                imapEnabled: $scope.imapEnabled,
                popEnabled: $scope.popEnabled,
                xmppEnabled: $scope.xmppEnabled,
                easEnabled: $scope.easEnabled,
                mapiEwsEnabled: $scope.mapiEwsEnabled,
                webdavEnabled: $scope.webdavEnabled
            });

            $mdDialog.hide(params);
        }
    }

})();