(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateEmailUsernameInvalidCharacters", validateEmailUsernameInvalidCharacters);

	// NOTE: This is intended for validating SmarterMail usernames, NOT the local part of an email address
	function validateEmailUsernameInvalidCharacters() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				// NOTE: If this validator changes also update validate-alias-name.directive.js
				ctrl.$validators.validateEmailUsernameInvalidCharacters = function (modelValue, viewValue) {
					viewValue = viewValue ? viewValue.trim() : viewValue;
					if (!viewValue)
						return true;

					if (viewValue.indexOf("..") > -1 ||
						viewValue.startsWith(".") ||
						viewValue.endsWith("."))
						return false;

					// If you make any changes to this regex, make sure they are applied to ValidUserName in CommonRegex.cs
					const userNameRegex = /^[-a-zA-Z0-9.!$&=^_`{}~\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\u0100-\u0148\u014A-\u017F\u0180-\u019B\u019D-\u01A6\u01A9-\u01BA\u01BF\u01C4-\u0240\u0243-\u024F\u1E00-\u1EFF]+$/;
					return userNameRegex.test(viewValue);
				};
			}
		};
	}
})();