(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsFilteringSpamController", userSettingsFilteringSpamController);

	function userSettingsFilteringSpamController($rootScope, $http, $q, $mdDialog, errorHandling, localStorageService, coreDataSettings) {

		var vm = this;
		vm.overrideActive = false;
		vm.defaultSettings = {};
		vm.userSettings = {};
		vm.activeSettings = vm.userSettings;
		vm.trustedDomains = [];
		vm.trustedEmails = [];
		vm.emailsToBypass = [];
		vm.blockedSenderActionOptions = [
			{ value: 0, translation: "NONE" },
			{ value: 1, translation: "MOVE_TO_JUNK" },
			{ value: 2, translation: "MOVE_TO_DELETED" },
			{ value: 3, translation: "DELETE" }
		];

		// functions
		vm.reloadState = reloadState;
		vm.save = save;
		vm.switchOverride = switchOverride;
		vm.editItem = editItem;
		vm.openTrustedDomains = openTrustedDomains;
		vm.openTrustedEmails = openTrustedEmails;
		vm.openBlockedSenders = openBlockedSenders;
		vm.openBypassTrustedSenderVerification = openBypassTrustedSenderVerification;

		activate();

		////////////////////

		function activate() {

			$rootScope.spinner.show();

			var promises = [
				$http.get("~/api/v1/settings/user-mail"),
				$http.get("~/api/v1/settings/spam-settings"),
				coreDataSettings.init()
			];

			$q.all(promises)
				.then(function (datas) {
					onSettingsLoaded(datas[0]);
					onSpamSettingsRetrieved(datas[1]);
				}, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSettingsLoaded(result) {
				var data = result.data.userMailSettings;
				vm.allowOverride = coreDataSettings.userPermissions.spamCheckOverrideActive;
				vm.enableBypassSenderVerification = coreDataSettings.userPermissions.allowSpamActionOverride;

				vm.trustedDomains = [];
				angular.forEach(data.whitelistDomains || [], function (value) {
					vm.trustedDomains.push(value.value);
				});

				vm.trustedEmails = [];
				angular.forEach(data.whitelistAddresses || [], function (value) {
					vm.trustedEmails.push(value.value);
				});

				vm.originalBlockedSenders = data.blockedSenders;
				vm.blockedSenders = data.blockedSenders;
				vm.blockedSenderAction = data.blockedSenderAction;
				vm.emailsToBypass = data.bypassedTrustedSenders;
			}

			function onSpamSettingsRetrieved(response) {
				vm.userSettings = response.data.userSettings;
				vm.defaultSettings = response.data.defaultSettings;
				switchOverride(false);
			}
		}

		function reloadState(form) {
			activate();
			form.$setPristine();
		}

		function switchOverride(copyDomainActions) {
			if (copyDomainActions) {
				if (vm.userSettings.overrideActive === true) {
					vm.userSettings = angular.copy(vm.defaultSettings);
					vm.userSettings.overrideActive = true;
				}
			}
			vm.activeSettings = vm.userSettings.overrideActive && vm.allowOverride ? vm.userSettings : vm.defaultSettings;
		}

		function save(event, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			var params = {
				userMailSettings: {
					blockedSenders: vm.blockedSenders,
					blockedSenderAction: vm.blockedSenderAction,
					bypassedTrustedSenders: vm.emailsToBypass,
					spamCheckOverrideActive: vm.userSettings.overrideActive,
					spamLevelLowAction: vm.userSettings.lowAction,
					spamLevelMedAction: vm.userSettings.medAction,
					spamLevelHighAction: vm.userSettings.highAction
				}
			};
			var params2 = { domains: vm.trustedDomains, emails: vm.trustedEmails };

			$http.post("~/api/v1/settings/user-mail", JSON.stringify(params))
				.then(
					function () {
						$http
							.post("~/api/v1/settings/whitelist", JSON.stringify(params2))
							.then(onSaveSuccess, errorHandling.report);
					},
					errorHandling.report);

			function onSaveSuccess() {
				form.$setPristine();
				$rootScope.$broadcast("user-settings:changed");
			}
		}

		function editItem(event, form, item) {
			if (!vm.userSettings.overrideActive)
				return;

			var title;
			if (item === vm.activeSettings.lowAction)
				title = "SPAM_FILTERING_LOW_PROBABILITY";
			else if (item === vm.activeSettings.medAction)
				title = "SPAM_FILTERING_MED_PROBABILITY";
			else // item === vm.activeSettings.highAction
				title = "SPAM_FILTERING_HIGH_PROBABILITY";

			$mdDialog.show({
				locals: {
					title: title,
					actionType: item.actionType,
					argument: item.argument,
					weight: null,
					hideMoveToJunk: false
				},
				controller: "editSpamFilterActionDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/spam-filter-action.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					item.actionType = modalSuccess.actionType;
					item.argument = modalSuccess.argument;
					form.$setDirty();
				}, function () {
					// Do nothing
				});
		}

		function openTrustedDomains(ev, form) { openTrustedSenders(ev, form, false); }

		function openTrustedEmails(ev, form) { openTrustedSenders(ev, form, true); }

		function openTrustedSenders(ev, form, storeInEmails) {
			$mdDialog
				.show({
					locals: { trustedSenders: storeInEmails ? vm.trustedEmails : vm.trustedDomains, isAddresses: storeInEmails },
					controller: "trustedSenderDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/trusted-senders.dlg.html",
					targetEvent: ev
				})
				.then(onAdditionalInfoOk, function () { });

			function onAdditionalInfoOk(modalSuccess) {
				var filteredItems = $.grep(modalSuccess, function (value) {
					var trimmed = (value || "").trim();
					return trimmed;
				});
				if (storeInEmails) {
					vm.trustedEmails = filteredItems;
					vm.blockedSenders = $.grep(vm.blockedSenders, function (sender) {
						for (var i = 0; i < vm.trustedEmails.length; i++) {
							if (sender.toLowerCase() === vm.trustedEmails[i].toLowerCase())
								return false;
						}
						return true;
					});
				}
				else
					vm.trustedDomains = filteredItems;
				form.$setDirty();
			}
		}

		function openBlockedSenders(ev, form) {
			$mdDialog
				.show({
					locals: { blockedSenders: vm.blockedSenders },
					controller: "blockedSenderDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/blocked-senders.dlg.html",
					targetEvent: ev
				})
				.then(onAdditionalInfoOk, function () { });

			function onAdditionalInfoOk(modalSuccess) {
				var filteredItems = $.grep(modalSuccess, function (value) {
					var trimmed = (value || "").trim();
					return trimmed;
				});
				vm.blockedSenders = filteredItems;
				vm.trustedEmails = $.grep(vm.trustedEmails, function (sender) {
					for (var i = 0; i < vm.blockedSenders.length; i++) {
						if (sender.toLowerCase() === vm.blockedSenders[i].toLowerCase())
							return false;
					}
					return true;
				});
				form.$setDirty();
			}
		}

		function openBypassTrustedSenderVerification(event, form) {
			$mdDialog
				.show({
					locals: {
						bypassedSenders: vm.emailsToBypass
					},
					controller: "bypassTrustedSenderDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/bypass-trusted-senders.dlg.html",
					targetEvent: event
				})
				.then(onAdditionalInfoOk, function () { });

			function onAdditionalInfoOk(modalSuccess) {
				var filteredItems = $.grep(modalSuccess, function (value) {
					var trimmed = (value || "").trim();
					return trimmed;
				});
				vm.emailsToBypass = filteredItems;
				form.$setDirty();
			}
		}

	}
})();
