(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("antiSpamData", antiSpamData);

    /* @ngInject */
    function antiSpamData($rootScope, $timeout, $log, $filter, $translate, $http, $q) {
        var vm = this;

        // Functions

        activate();

        ///////////////////////////

        function activate() {
            refreshItems();
            $rootScope.$on('$translateChangeSuccess', refreshItems);
        }

        function refreshItems() {
			$q
				.all([$http.get("~/api/v1/settings/sysadmin/antispam/counts")])
				.then(function (results) { vm.spamCheckCounts = results[0].data; }, function () {});
        }
    }

})();