(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemAntiSpamController", systemAntiSpamController);

    function systemAntiSpamController($scope, $mdDialog, $timeout, $http, $filter, $rootScope, errorHandling, unsavedChangesTrackingService, claimsService) {
        var vm = this;
        vm.ready = false;
        vm.gridSections = [
            { translate: "OPTIONS", length: -1 },
            { translate: "SPAM_CHECKS", length: "0" },
            { translate: "RBLS", length: "0" },
            { translate: "URIBLS", length: "0" },
            { translate: "GREYLIST_FILTERS", length: "0" }
        ];
        vm.selectedSection = "OPTIONS";
        vm.changeSection = function (section) {
            if (unsavedChangesTrackingService.doConfirmDialog) {
                unsavedChangesTrackingService
                    .doConfirmDialog()
                    .then(function () { unsavedChangesTrackingService.doConfirmDialog = undefined; vm.selectedSection = section; }, function () { });
            } else
                vm.selectedSection = section;
        };

        // Functions
        activate();

        /////////////////////////

        function activate() {
            refreshCounts();
            $scope.$on("antispam:counts-changed", function () { refreshCounts(); });
        }

        function refreshCounts() {
            $rootScope.spinner.show();
            $http.get("~/api/v1/settings/sysadmin/antispam/counts")
                .then(onDataLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onDataLoaded(result) {
                vm.counts = result.data;
                vm.gridSections[1].length = $filter("unicodeNumber")(vm.counts.spamCheckCount);
                vm.gridSections[2].length = $filter("unicodeNumber")(vm.counts.rblCount);
                vm.gridSections[3].length = $filter("unicodeNumber")(vm.counts.uriblCount);
                vm.gridSections[4].length = vm.counts.greylistFilterCount;
                vm.ready = true;
            }
        }
    }

})();

