(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("errorHandling", errorHandling);

    function errorHandling($rootScope, toaster, $sanitize, $translate, $timeout) {
        var vm = this;

        // Functions
        vm.warn = warn;
        vm.report = report;
        vm.reportAndHideSpinner = reportAndHideSpinner;

        /////////////////////

        function report(failure) {

            if (failure && typeof failure.data !== 'undefined' && failure.data)
                failure = failure.data;

            if (failure && typeof failure.message !== 'undefined' && failure.message) {
                var msg = "";
                failure.message.split("\n")
                    .filter(function (i) { return i; })
                    .forEach(function (str) {
                        var msgPart = str;
                        var variables = null;
                        if (str.contains("|{") && str.endsWith("}")) {
                            var dataIndex = str.indexOf("|{");
                            msgPart = str.substr(0, dataIndex);
                            variables = JSON.parse(str.substr(dataIndex + 1));
                        }

                        msg += $translate.instant(msgPart, variables) + "\n";
                    });
                msg = $sanitize(msg.trim());
                msg = msg.replace(new RegExp("&#10;", 'g'), "<br>");

                var time = null;
                if (failure.message.split("\n").length > 1)
                    time = 15000;
                else
                    time = 10000;

                $timeout(function () {
                    toaster.pop({
                        type: 'error',
                        title: null,
                        body: msg,
                        timeout: time,
                        bodyOutputType: 'trustedHtml'
                    });
                }, 10);
            }
            else {
                $timeout(function () {
                    toaster.pop("error", $translate.instant(failure || "ERROR_HAS_OCCURRED"));
                }, 10);
            }
        }

        function warn(failure) {
            if (!failure)
                return;
            if (typeof failure.data !== 'undefined' && failure.data)
                failure = failure.data;
            if (typeof failure.message !== 'undefined' && failure.message)
                $timeout(function () {
                    toaster.pop("warning", $translate.instant(failure.message));
                }, 10);
            else
                $timeout(function () {
                    toaster.pop("warning", $translate.instant(failure));
                }, 10);
        }

        function reportAndHideSpinner(failure) {
            if (failure && typeof failure.data !== 'undefined' && failure.data)
                failure = failure.data;
            if (failure && typeof failure.message !== 'undefined' && failure.message)
                $timeout(function () {
                    toaster.pop("error", $translate.instant(failure.message));
                }, 10);
            else
                $timeout(function () {
                    toaster.pop("error", $translate.instant(failure || "ERROR_HAS_OCCURRED"));
                }, 10);
            $rootScope.spinner.hide();
        }
    }
})();