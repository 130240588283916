(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("checkPasswords", [checkPasswords]);

	function checkPasswords() {
		return {
			require: "ngModel",
			scope: {
				otherModelValue: "=checkPasswords"
			},
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.checkPasswords = function (modelValue) {
					//the other model field should already be throwing a required error.
					if (!scope.otherModelValue)
						return true;
					return modelValue === scope.otherModelValue;
				};

				scope.$watch("otherModelValue", function () {
					ctrl.$validate();
				});
			}
		};
	}

	angular
		.module("smartertools")
		.directive("checkPasswordsOptional", [checkPasswordsOptional]);

	function checkPasswordsOptional() {
		return {
			require: "ngModel",
			scope: {
				otherModelValue: "=checkPasswordsOptional"
			},
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.checkPasswords = function (modelValue) {
					if (!modelValue && !scope.otherModelValue) {
						return true;
					}
					return modelValue === scope.otherModelValue;
				};

				scope.$watch("otherModelValue", function () {
					ctrl.$validate();
				});
			}
		};
	}

	angular
		.module("smartertools")
		.directive("checkPasswordsNotMatch", [checkPasswordsNotMatch]);

	function checkPasswordsNotMatch() {
		return {
			require: "ngModel",
			scope: {
				otherModelValue: "=checkPasswordsNotMatch"
			},
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.checkPasswordsNotMatch = function (modelValue) {
					return modelValue !== scope.otherModelValue;
				};

				scope.$watch("otherModelValue", function () {
					ctrl.$validate();
				});
			}
		};
	}


	angular
		.module("smartertools")
		.directive("checkFieldsNotMatch", [checkFieldsNotMatch]);

	function checkFieldsNotMatch() {
		return {
			require: "ngModel",
			scope: {
				otherModelValue: "=checkFieldsNotMatch"
			},
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.checkFieldsNotMatch = function (modelValue) {
					return modelValue !== scope.otherModelValue;
				};

				scope.$watch("otherModelValue", function () {
					ctrl.$validate();
				});
			}
		};
	}

})();