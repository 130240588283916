(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedSettingsMailListController", sharedSettingsMailListController);

	function sharedSettingsMailListController($rootScope, $scope, $state, $http, errorHandling, unsavedChangesTrackingService, claimsService) {
		var vm = this;
		vm.mailingListId = $state.params.mailingListId;
		vm.ready = false;
		vm.canManageMailingLists = claimsService.isSysAdmin() || claimsService.isDomainAdmin();
		vm.gridSections = [
			{ translate: "OPTIONS", length: -1 },
			{ translate: "SUBSCRIBERS", length: "0" },
			{ translate: "DIGEST_SUBSCRIBERS", length: "0" },
			{ translate: "ALLOWED_POSTERS", length: "0" },
			{ translate: "BANNED_POSTERS", length: "0" },
			{ translate: "MESSAGES", length: "0" }
		];
		if (vm.canManageMailingLists)
			vm.gridSections.push({ translate: "CUSTOM_FIELDS", length: "0" });
		vm.selectedSection = "OPTIONS";

		// Functions
		vm.changeSection = changeSection;
		activate();

		/////////////////////////

		function activate() {
			if (!vm.mailingListId)
				$state.go("^");
			refreshCounts();
			$scope.$on("mailing-list:counts-changed", refreshCounts);
		}

		function refreshCounts() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/counts")
				.then(onDataLoaded, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDataLoaded(result) {
				vm.counts = result.data;
				vm.gridSections[1].length = vm.counts.listSubscribers;
				vm.gridSections[2].length = vm.counts.digestSubscribers;
				vm.gridSections[3].length = vm.counts.posters;
				vm.gridSections[4].length = vm.counts.bannedUsers;
				vm.gridSections[5].length = vm.counts.messages;
				if (vm.gridSections.length >= 7)
					vm.gridSections[6].length = vm.counts.customFields;
				vm.ready = true;
			}
		}

		function changeSection(section) {
			if (unsavedChangesTrackingService.doConfirmDialog) {
				unsavedChangesTrackingService.doConfirmDialog()
					.then(function () { unsavedChangesTrackingService.doConfirmDialog = undefined; vm.selectedSection = section; refreshCounts(); }, function () { });
			} else {
				vm.selectedSection = section;
				refreshCounts();
			}
		};
	};
})();