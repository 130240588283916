(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarEventProposeController", calendarEventProposeController);

    function calendarEventProposeController($mdDialog, $scope, $rootScope, $http, $translate, errorMessageService, userTimeService,
        errorHandling, coreDataCalendar, eventInfo, date, id, replyingComment, fromEmail) {
        var vm = this;
        vm.selectedReplyOption = 2;
        vm.eventInfo = eventInfo;
        vm.replyingComment = replyingComment;
        vm.fromEmail = fromEmail
        $scope.hours = new Array(24 * 4);

        activate();

        vm.replyOptions = [
            { value: 0, translation: 'ACCEPT' },
            { value: 1, translation: 'TENTATIVE' },
            { value: 2, translation: 'DECLINE' }
        ];

        vm.save = function () {
            $mdDialog.hide({ notify: vm.notify });
        }

        vm.cancel = function () {
            $mdDialog.cancel();
        }


        async function activate() {
            try {
                $rootScope.spinner.show();
                await coreDataCalendar.init();

                $scope.attendees = angular.copy(vm.eventInfo.info.attendees || []);

                if (date) {
                    $scope.availabilityDate = moment.tz(date.dt, date.tz);
                    $scope.currentAppointmentDate = moment.tz(date.dt, date.tz);


                    var momentStart = moment.tz(vm.eventInfo.info.start.dt, vm.eventInfo.info.start.tz);
                    var momentEnd = moment.tz(vm.eventInfo.info.end.dt, vm.eventInfo.info.end.tz);

                    if (eventInfo.info.allDay) {
                        vm.momentStartJS = momentStart.toDate();
                    }

                    if (!eventInfo.info.allDay) {
                        $scope.start = moment(momentStart).toDate();
                        $scope.end = moment(momentEnd).toDate();
                    } else {
                        $scope.start = moment(vm.eventInfo.info.start.dt.substr(0, 19)).toDate();
                        $scope.end = moment(vm.eventInfo.info.end.dt.substr(0, 19)).toDate();
                    }
                } else {
                    $scope.availabilityDate = moment.tz(vm.eventInfo.info.start.dt, vm.eventInfo.info.start.tz);
                }

                if (vm.eventInfo.info.allDay)
                    $scope.availabilityDate.hour(0).minute(0).second(0);

                $scope.availabilityDateStr = $translate.instant('AVAILABILITY_ON', { date: $scope.availabilityDate.format("LL") });
                $scope.currentAppointmentDateStr = $translate.instant('MEETING_CURRENTLY_ON', { date: $scope.currentAppointmentDate.format("LLL") });

                getUserAvailability();
            } catch (e) {
                errorHandling.report(e);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        vm.sendProposal = async function () {
            var start = $scope.start.toISOString(true);
            var end = $scope.end.toISOString(true);
            
            try {
                var url = `/api/v1/calendars/meeting-propose-new-time/${eventInfo.owner}/${eventInfo.calId}/${id}`;
                let meetingReplyStatus = "DECLINED";
                switch (vm.selectedReplyOption) {
                    case 0:
                        meetingReplyStatus = "ACCEPTED";
                        break;
                    case 1:
                        meetingReplyStatus = "TENTATIVE";
                        break;
                    case 2:
                        meetingReplyStatus = "DECLINED";
                        break;
                    default:
                        meetingReplyStatus = "DECLINED";
                        break;
                }

                var params = {
                    dtStart: start,
                    dtEnd: end,
                    replyStatus: meetingReplyStatus,
                    timezone: $scope.timeZoneStart,
                    replyingComment: vm.replyingComment
                }
               
                if (eventInfo.info.recurrenceId) 
                    url += "/" + eventInfo.info.recurrenceId;
                
                if (vm.fromEmail) {
                    $http
                        .post(url, JSON.stringify(params))
                        .then($mdDialog.hide(), function () { });
                } else {
                    $http
                        .post(url, JSON.stringify(params))
                        .then(window.close, function () { }).finally($rootScope.spinner.hide);
                }
                
			} catch (e) {
            
			}
        }

        // If it's not a performance issue, we could add the following code within the availability chart items
        // <md-tooltip md-direction="top">{{:: getAvailTime($index) | date:"shortTime" }} - {{  isUnavailable(attendee, $index) ? 'Unavailable' : 'Available'}}</md-tooltip>

        $scope.getAvailTime = function (slot) {
            var d = $scope.availabilityDate;
            var date = moment([d.year(), d.month(), d.date()]);
            return date.add(slot * 15, "m").toDate();
        };

        $scope.getAvailHour = function (offset) {
            var d = $scope.availabilityDate;
            var date = moment([d.year(), d.month(), d.date()]);
            return date.add(offset, "h").toDate();
        };

        $scope.moveBack = function () {
            $scope.availabilityDate.add(-1, "d");
            $scope.availabilityDateStr = $translate.instant('AVAILABILITY_ON', { date: $scope.availabilityDate.format("LL") });
            getUserAvailability();
        };

        $scope.moveForward = function () {
            $scope.availabilityDate.add(1, "d");
            $scope.availabilityDateStr = $translate.instant('AVAILABILITY_ON', { date: $scope.availabilityDate.format("LL") });
            getUserAvailability();
        };

        async function getUserAvailability() {
            var participants = $.map($scope.attendees, function (attendee) { return attendee.email; });
            if (participants.length === 0)
                return;

            for (var i = 0; i < $scope.attendees.length; ++i)
                $scope.attendees[i].nonavail = Array(24 * 4).fill(false);

            var params = JSON.stringify({
                date: $scope.availabilityDate,
                emailAddresses: participants,
                uidToIgnore: (eventInfo.info && eventInfo.info.id !== "new" && eventInfo.info.id !== null) ? eventInfo.info.id : null
            });

            try {
                $rootScope.spinner.show(1000);
                const success = await $http.post("~/api/v1/calendars/user-availability", params);

                $scope.$applyAsync(function () {
                    for (var i = 0; i < success.data.length; ++i) {
                        var temp = $scope.attendees.filter(att => att.email === success.data[i].emailAddress);
                        if (temp.length > 0) {
                            if (success.data[i].busyHours === null)
                                temp[0].externalUser = true;
                            else
                                temp[0].nonavail = success.data[i].busyHours.slice(0, 24 * 4);
                            temp[0].ready = true;
                        }
                    }
                });
            }
            catch (failure) {
                errorMessageService.showErrorMessage(failure)
            }
            finally {
                $rootScope.spinner.hide();
            }
        };

        $scope.isUnavailable = function (attendee, offset) {
            if (attendee == undefined || attendee.nonavail === null)
                return null;
            return attendee.nonavail[Math.floor(offset)] == 1;
        };

        $scope.isTentative = function (attendee, offset) {
            if (attendee == undefined || attendee.nonavail === null)
                return null;
            return attendee.nonavail[Math.floor(offset)] == 2;
        };

        $scope.close = function () {
            $mdDialog.hide();
        }

        var minStart = moment("1900-01-01").toDate();

        $scope.$watch("start", function (newValue, oldValue) {
            if (!$scope.start) return;


            if ($scope.start < minStart) {
                $scope.start = minStart;
            }

            var duration = null;
            if (oldValue) {
                var mStart = oldValue;
                var mEnd = $scope.end;
                duration = moment.duration(moment(mEnd).diff(mStart));
            }
            constrainEndTime(true, duration);
        });

        $scope.$watch("end", function (newValue, oldValue) {
            if (!newValue && !oldValue || newValue === oldValue)
                return;

            if (newValue && oldValue && newValue.getTime() === oldValue.getTime())
                return;


            if ($scope.start) {
                constrainEndTime();
            }
        });

        function constrainEndTime(startChanging, duration) {
            if (!$scope.start)
                return;

            $scope.endBeforeStart = false;

            var mStart = $scope.start;
            var minEnd = moment(mStart).add(15, "minutes");
            var tempEnd, relativeOffset;

            if (duration) {
                tempEnd = moment(mStart).utc().add(duration);
                recalculateEnd();
                if (tempEnd - mStart > 0) {
                    tempEnd = moment($scope.start).add(duration);
                    recalculateEnd();
                }
            } else if (!$scope.end) {
                tempEnd = moment(mStart).add(1, "hours");
                recalculateEnd();
            } else {
                tempEnd = moment($scope.end);
                if (startChanging && tempEnd.toDate() < moment(mStart).toDate()) {
                    tempEnd = moment(mStart).add(1, "hours");
                    recalculateEnd();
                }
            }

            $scope.endBeforeStart = isEndBeforeStart();

            function recalculateEnd() {
                $scope.end = tempEnd.toDate();
            }

            function isEndBeforeStart() {
                return $scope.end < $scope.start;
            }
        }

    }
})();