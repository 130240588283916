(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("passwordComplianceSendEmailController", passwordComplianceSendEmailController);

	function passwordComplianceSendEmailController($scope, $mdDialog, $timeout, from, subject, emailText, isSysAdmin) {
		$scope.isSysAdmin = isSysAdmin;
		$scope.emailInfo = {
			from: from,
			subject: subject,
			emailText: emailText,
		};
		$scope.editorScope = {};

		$scope.save = function () {
			$scope.emailInfo.emailText = $scope.editorScope.getHtml();

			$timeout(function () {
				if ($scope.emailForm.$valid) {
					$mdDialog.hide($scope.emailInfo);
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();
