(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminSettingsSystemAdminSpecificController", adminSettingsSystemAdminSpecificController);

    function adminSettingsSystemAdminSpecificController($rootScope, $scope, $filter, $mdDialog, $http, $q, $timeout, $translate,
        coreDataSysAdminSettings, $state, $stateParams, claimsService, errorHandling, successHandling, userDataService,
        localeInfoService, i18n) {

        var vm = this;
        vm.editingUsername = $stateParams.username;
        vm.isNew = false;
        vm.meetsReq = false;
        vm.user = {};
        vm.newPassword = "";
        vm.confirmPassword = "";
        vm.isOptionsChanged = false;
        vm.isReadOnly = false;
        vm.canManage = false;
        vm.isMe = stringCompare(claimsService.getUsername(), vm.editingUsername);
        vm.initialized = false;
        vm.isEnabled = false;
        vm.availableLocales = [];
        vm.systemDefaultLocaleId = "en";

        vm.currentUserImpersonationPermissions = false;
        vm.currentUserPasswordsInAPIPermissions = false;
        vm.currentUserIpAccessRestrictionPermissions = false;
        vm.currentUserManageAdminsPermissions = false;
        vm.currentUserIsPrimaryAdmin = false;
        vm.primaryDisable = false;

        vm.editUserImpersonationPermissions = false;
        vm.editUserPasswordsInAPIPermissions = false;
        vm.editUserIpAccessRestrictionPermissions = false;
        vm.editUserManageAdminsPermissions = false;

        // Functions
        vm.addRestriction = addRestriction;
        vm.editRestriction = editRestriction;
        vm.onOptionsCancel = onOptionsCancel;
        vm.save = save;
        vm.openChangePassword = openChangePassword;
        vm.setTwoFactor = setTwoFactor;
        vm.setupTwoFactor = setupTwoFactor;
        vm.allowImpersonationChanged = allowImpersonationChanged;
        vm.deleteSysAdmin = deleteSysAdmin;

        vm.init = activate;

        /////////////////////

        function activate() {
            $rootScope.spinner.show();

            $http.get("~/api/v1/settings/sysadmin/global-mail")
                .then(onGlobalMailSettingsLoaded)
                .then(onLoginsLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);
            function onGlobalMailSettingsLoaded(result) {
                vm.serverSettings = result.data.globalMailSettings.serverSettings;
                return $http.get("~/api/v1/settings/sysadmin/logins");
            };
            function onLoginsLoaded(result) {
                var admin = $.grep(result.data.systemLogins, function (admin) { return stringCompare(admin.username, claimsService.getUsername()); })[0];
                vm.systemDefaultLocaleId = result.data.defaultLocaleID || "en";

                if (vm.editingUsername === "new") {
                    vm.isReadOnly = admin ? !admin.manageAdmins : true;
                    setPermissions(admin);
                    vm.user.allowImpersonation = vm.currentUserIsPrimaryAdmin || vm.currentUserImpersonationPermissions;
                    vm.user.manageAdmins = vm.currentUserIsPrimaryAdmin || vm.currentUserManageAdminsPermissions;
                    vm.canManage = (!vm.isReadOnly);
                    vm.displayName = $filter("translate")("NEW");
                    vm.isOptionsChanged = true;
                    vm.onOptionsCancel();
                    vm.forceTwoFactorAuth = true;
                    vm.enableTwoFactorAuth = false;
                    vm.isNew = true;
                    vm.isEnabled = true;
                    vm.twoFactorSetComplete = false;
                } else {
                    setPermissions(admin);
                    var sc = $.grep(result.data.systemLogins, function (c) { return stringCompare(c.username, vm.editingUsername); });
                    if (sc.length > 0) {
                        vm.user = angular.copy(sc[0]);
                        if (vm.user.isPrimaryAdmin && !vm.currentUserIsPrimaryAdmin) {
                            vm.currentUserImpersonationPermissions = false;
                            vm.currentUserPasswordsInAPIPermissions = false;
                            vm.currentUserIpAccessRestrictionPermissions = false;
                            vm.currentUserManageAdminsPermissions = false;
                        }
                        if (vm.user.isPrimaryAdmin) {
                            vm.user.allowPasswordsInAPI = true;
                            vm.user.allowImpersonation = true;
                            vm.user.manageAdmins = true;
                            vm.primaryDisable = true;
                        }
                        vm.forceTwoFactorAuth = vm.user.forceTwoFactorAuth;
                        vm.enableTwoFactorAuth = vm.user.enableTwoFactorAuth;
                        vm.twoFactorSetComplete = vm.user.twoFactorSetComplete;
                        vm.isReadOnly = admin ? ((!claimsService.isPrimarySysAdmin() && vm.user.isPrimaryAdmin) || !admin.manageAdmins) : true;
                        vm.canManage = !vm.isReadOnly;
                        vm.displayName = vm.user.username;
                        vm.isNew = false;
                        vm.isOptionsChanged = true;
                        vm.isEnabled = vm.user.enabled;
                        vm.onOptionsCancel();
                    } else {
                        $state.go("^");
                    }
                }
                vm.initialized = true;
            }

            for (var i = 0; i < angularLangNames.length; i++) {
                var obj = {
                    n: angularLangNames[i].n,
                    v: angularLangNames[i].v,
                    engName: localeInfoService.getEnglishName(angularLangNames[i].v)
                }
                vm.availableLocales.push(obj);
            }
            vm.localeId = vm.localeId || vm.systemDefaultLocaleId;
        }

        function setPermissions(admin) {
            if (admin === null) {
                vm.currentUserImpersonationPermissions = false;
                vm.currentUserPasswordsInAPIPermissions = false;
                vm.currentUserIpAccessRestrictionPermissions = false;
                vm.currentUserManageAdminsPermissions = false;
            } else if (admin.isPrimaryAdmin) {
                vm.currentUserIsPrimaryAdmin = true;
                vm.currentUserImpersonationPermissions = true;
                vm.currentUserPasswordsInAPIPermissions = true;
                vm.currentUserIpAccessRestrictionPermissions = true;
                vm.currentUserManageAdminsPermissions = true;
            } else {
                vm.currentUserImpersonationPermissions = admin.allowImpersonation;
                vm.currentUserPasswordsInAPIPermissions = admin.allowPasswordsInAPI;
                vm.currentUserIpAccessRestrictionPermissions = admin.enableIpAccessRestriction;
                vm.currentUserManageAdminsPermissions = admin.manageAdmins;
            }
        }

        function setTwoFactor(form, enabled) {

            var confirm = $mdDialog.confirmDeletion({
                title: $translate.instant('CONFIRMATION_REQUIRED'),
                textContent: $translate.instant(enabled ? "TWO_FACTOR_CONFIRM_ADMIN_RESET" : "TWO_FACTOR_CONFIRM_ADMIN_DISABLE"),
                ok: enabled ? $translate.instant("RESET") : $translate.instant("DISABLE"),
                cancel: $translate.instant("CANCEL")
            });
            $mdDialog.show(confirm)
                .then(function (success) {

                    $http.post('~/api/v1/settings/sysadmin/set-two-factor/' + enabled, JSON.stringify({ email: vm.isMe ? claimsService.getUsername() : vm.editingUsername }))
                        .then(function () {
                            vm.twoFactorSetComplete = false;
                            vm.enableTwoFactorAuth = enabled;
                        }, errorHandling.report);
                });
        }

        function setupTwoFactor(form) {

            if (form.$dirty) {
                var confirm = $mdDialog.confirm({
                    title: $translate.instant('UNSAVED_CHANGES'),
                    textContent: $translate.instant('TWO_FACTOR_CONFIRM_UNSAVED'),
                    ok: $translate.instant('UNDO_CHANGES'),
                    cancel: $translate.instant("CANCEL")
                });

                $mdDialog.show(confirm).then(function (success) {
                    vm.skipNavigateConfirm = true;
                    vm.reloadState(form);
                    openTwoFactorSetup(form);
                }, function () { });
            } else {
                openTwoFactorSetup(form);
            }
        }

        function openTwoFactorSetup(form) {
            if (vm.isReadOnly && !vm.isMe)
                return;
            $mdDialog.show({
                locals: {
                    username: claimsService.getUsername(),
                    twoFactorSetupTypes: [{ value: 2, translation: "AUTHENTICATOR_APP" }]
                },
                controller: "adminTwoFactorController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/settings/system-administrators/admin-setup-two-factor.dlg.html"
            })
                .then(function (modalSuccess) {
                    if (modalSuccess.failure) {
                        errorHandling.report(failure);
                        return;
                    } else {
                        vm.twoFactorSetComplete = modalSuccess.configured;
                        vm.enableTwoFactorAuth = modalSuccess.enabled;
                        activate();
                    }
                }, function (cancel) { });
        }

        function stringCompare(strA, strB) {
            return strA.toUpperCase() === strB.toUpperCase();
        }

        function openChangePassword(ev) {
            if (vm.isReadOnly && !vm.isMe)
                return;
            $mdDialog.show({
                    locals: {
                        isMe: vm.isMe,
                        username: vm.editingUsername
                    },
                    controller: "sysAdminsEditPasswordDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/system-administrators/change-password.dlg.html",
                    targetEvent: ev
                })
                .then(successHandling.report, function() {});
        }

        function addRestriction(ev, form) {
            if (vm.isReadOnly)
                return;
            openModal(null, ev)
                .then(function (success) {
                    var restrictions = angular.copy(vm.ipAccessRestrictions);
                    restrictions.push(success.info);
                    var info = {
                        allowImpersonation: vm.allowImpersonation,
                        allowPasswordsInAPI: vm.allowPasswordsInAPI,
                        dateCreated: vm.dateCreated,
                        description: vm.description,
                        enableIpAccessRestriction: vm.enableIpAccessRestriction,
                        id: vm.id,
                        ipAccessRestrictions: restrictions,
                        isPrimaryAdmin: vm.isPrimaryAdmin,
                        username: vm.username,
                        manageAdmins: vm.manageAdmins,
                        enabled: vm.isEnabled
                    };
                    vm.ipAccessRestrictions.push(success.info);
                    $scope.pageForm.$setDirty();
                }, function () { });
        }

        function editRestriction(restriction, ev) {
            if (vm.isReadOnly)
                return;
            openModal(restriction, ev)
                .then(function (success) {
                    if (success.info) {
                        var restrict = restriction;
                        restriction.type = success.info.type;
                        restriction.description = success.info.description;
                        restriction.address = success.info.address;
                    } else {
                        var index = vm.ipAccessRestrictions.indexOf(restriction);
                        if (index > -1)
                            vm.ipAccessRestrictions.splice(index, 1);
                    }
                    var info = {
                        allowImpersonation: vm.allowImpersonation,
                        allowPasswordsInAPI: vm.allowPasswordsInAPI,
                        dateCreated: vm.dateCreated,
                        description: vm.description,
                        enableIpAccessRestriction: vm.enableIpAccessRestriction,
                        id: vm.id,
                        ipAccessRestrictions: vm.ipAccessRestrictions,
                        isPrimaryAdmin: vm.isPrimaryAdmin,
                        username: vm.username,
                        manageAdmins: vm.manageAdmins,
                        enabled: vm.isEnabled
                    };
                    $scope.pageForm.$setDirty();
                }, function () { });
        }

        function onOptionsCancel() {
            var isChanged = vm.isOptionsChanged;
            vm.isOptionsChanged = false;
            vm.allowImpersonation = vm.user.allowImpersonation || false;
            vm.allowPasswordsInAPI = vm.user.allowPasswordsInAPI || false;
            vm.dateCreated = vm.user.dateCreated || null;
            vm.description = vm.user.isPrimaryAdmin ? $filter("translate")("PRIMARY_ADMINISTRATOR") : (vm.user.description || "");
            vm.enableIpAccessRestriction = vm.user.enableIpAccessRestriction || false;
            vm.id = vm.user.id || "";
            vm.ipAccessRestrictions = vm.user.ipAccessRestrictions || [];
            vm.isPrimaryAdmin = vm.user.isPrimaryAdmin || false;
            vm.username = vm.user.username || "";
            vm.manageAdmins = vm.user.manageAdmins || vm.isPrimaryAdmin;
            vm.isEnabled = vm.user.enabled;
            vm.localeId = vm.user.localeId || vm.systemDefaultLocaleId;

            if (vm.isNew || isChanged === false) {
                $state.go("^");
            }
        }

        function openModal(info, ev) {
            return $mdDialog.show({
                locals: { info: info },
                controller: "ipRestrictionModalController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/settings/system-administrators/ip-restriction.dlg.html",
                targetEvent: ev
            });
        }

        function save(ev, form) {
            if (form.$invalid) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            $rootScope.spinner.show();
            var info;
            if (vm.isReadOnly && vm.isMe) {
                info = {
                    id: vm.user.id,
                    username: vm.user.username,
                    description: vm.description,
                    localeId: vm.localeId
                };
            } else if (vm.isReadOnly) {
                info = {
                    allowImpersonation: vm.user.allowImpersonation,
                    allowPasswordsInAPI: vm.user.allowPasswordsInAPI,
                    dateCreated: vm.user.dateCreated,
                    enableIpAccessRestriction: vm.user.enableIpAccessRestriction,
                    id: vm.user.id,
                    ipAccessRestrictions: vm.user.ipAccessRestrictions,
                    isPrimaryAdmin: vm.user.isPrimaryAdmin,
                    username: vm.user.username,
                    manageAdmins: vm.user.manageAdmins,
                    description: vm.description,
                    enabled: vm.user.isEnabled,
                    localeId: vm.localeId
                };
            } else {
                info = {
                    allowImpersonation: vm.allowImpersonation,
                    allowPasswordsInAPI: vm.allowPasswordsInAPI,
                    dateCreated: vm.dateCreated,
                    description: vm.description,
                    enableIpAccessRestriction: vm.enableIpAccessRestriction,
                    id: vm.id,
                    ipAccessRestrictions: vm.ipAccessRestrictions,
                    isPrimaryAdmin: vm.isPrimaryAdmin,
                    username: vm.username,
                    manageAdmins: vm.manageAdmins,
                    enabled: vm.isEnabled,
                    localeId: vm.localeId,
                    forceTwoFactorAuth: vm.forceTwoFactorAuth,
                };
            }
            if (!vm.isNew) {
                coreDataSysAdminSettings.editSystemAdministrator(info, vm.confirmPassword)
                    .then(onSaveSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            } else {
                coreDataSysAdminSettings.addSystemAdministrator(info, vm.confirmPassword)
                    .then(onSaveSuccess, function(result) {
                        if (result.data.message.indexOf("PASSWORD_RESET_REQUIRE") > -1) {
                            $rootScope.$broadcast("passwordCheckCallback", result);
                        } else {
                            errorHandling.report(result);
                        }
                    })
                    .finally($rootScope.spinner.hide);
            }

            function onSaveSuccess() {
                if ($scope.pageForm.langSelection.$dirty && vm.username === claimsService.getUsername()) {
                    localeInfoService.setLocale(vm.localeId);
                    sessionStorage.removeItem("locale.oneTime");
                }

                $scope.pageForm.$setPristine();
                $state.go("^");
            }
        }

        function allowImpersonationChanged() {
            if (!vm.allowImpersonation)
                vm.allowPasswordsInAPI = false;
        }

        function deleteSysAdmin(ev, pageForm) {
            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS_one'))
                .targetEvent(event);
            $mdDialog
                .show(confirm)
                .then(doDelete, function () { });

            function doDelete() {
                return $http
                    .post("~/api/v1/settings/sysadmin/logins-delete", JSON.stringify([vm.user.id]))
                    .then(onSuccess, errorHandling.report);

                function onSuccess() {
                    successHandling.report();
                    $state.go("^");
                }
            }
        }
    }

})();
