/*
 * USE EXAMPLES FOR REMAINING DIRECTIVES - Based on Required Template.html page
 * <st-side-menu ng-controller="mainMenuController">
 *      LIST ITEM - USE Example provided in st-side-menu-list-item.directive.js
 *      LIST ITEM - USE Example provided in st-side-menu-list-item.directive.js
 * </st-side-menu>
*/

(function () {
    'use strict';

    angular
        .module('smartertools')
        .directive('stSideMenu', function () {
            return {
                restrict: "E",
                transclude: true,
                template: '<md-button class="md-primary scroll-buttons scroll-top" ng-click="upClicked()" ng-show="showUp" aria-label="scroll">' +
                '<i class="toolsicon toolsicon-flat-arrow-up"></i>' +
                '</md-button>' +
                '<ul id="st-side-menu-list" ng-transclude ng-cloak></ul>' +
                '<md-button class="md-primary scroll-buttons scroll-bottom" ng-click="downClicked()" ng-show="showDown" aria-label="scroll">' +
                '<i class="toolsicon toolsicon-flat-arrow-down"></i>' +
                '</md-button>',
                controller: stSideMenuController
            };
        });

    function stSideMenuController($scope, $timeout, $log) {
        var currentTouch = {};
        $scope.showUp = false;
        $scope.showDown = false;
        $scope.topItemHeight = 0;
        $scope.itemHeight = 0;
        $scope.buttonHeight = 0;
        $scope.resizeCount = 0;
        $scope.offsetY = 0;

        // Functions
        $scope.move = move;
        $scope.upClicked = upClicked;
        $scope.downClicked = downClicked;
        activate();

        ///////////////////

        function activate() {
            recalculateVisibleItems();
            $(window).resize(function () { $scope.$evalAsync(recalculateVisibleItems); });

            var container = $('#st-side-menu-container');
            container.bind('mousewheel wheel', mouseWheel);
            container.bind('touchstart touchmove touchend', touchEvent);

            $scope.$on('$destroy', function (e) {
                container.unbind('mousewheel wheel', mouseWheel);
                container.unbind('touchstart touchmove touchend', touchEvent);
            });
        }

        function mouseWheel(e) {
            if (e.originalEvent.wheelDelta) {
                var pixels = e.originalEvent.wheelDelta;
                $scope.move(pixels);
            }
            else if (e.originalEvent.deltaY) {
                var pixels = -1 * e.originalEvent.deltaY;
                if (e.originalEvent.deltaMode == 1) // lines
                    pixels = -1 * e.originalEvent.deltaY * $scope.itemHeight;
                else if (e.originalEvent.deltaMode == 2) // pages
                    pixels = -1 * e.originalEvent.deltaY * $scope.itemHeight * 3;
                $scope.move(pixels);
            }
            return false;
        }

        function recalculateVisibleItems() {
            var list = $('#st-side-menu-list');
            var menu = $('#st-side-menu-container');
            var button = $('.scroll-top');

            var menuHeight = menu.innerHeight();
			var buttonHeight = button.innerHeight();
			if (!menuHeight || !buttonHeight) {
                $timeout(recalculateVisibleItems, 200);
                return;
            }

            if (menuHeight <= 100 && $scope.resizeCount < 2) {
                $scope.resizeCount++;
                $timeout(recalculateVisibleItems, 500);
                return;
            }
            $scope.resizeCount = 0;

            $scope.buttonHeight = $('.scroll-top').height();
            $scope.topItemHeight = $('.menu-item-top').height();
            $scope.itemHeight = $('.menu-item').height();
            setListPosition(true);
        }

        function upClicked() {
            var pixels = $scope.offsetY <= $scope.topItemHeight ? $scope.topItemHeight : $scope.itemHeight;
            $scope.move(pixels);
        }

        function downClicked() {
            var pixels = $scope.offsetY == 0 ? $scope.topItemHeight - $scope.buttonHeight : $scope.itemHeight;
            $scope.move(-1 * pixels);
        }

        function touchEvent(e) {
            if (e.type == "touchstart") {
                var touch = e.originalEvent.targetTouches[0];
                currentTouch = {
                    identifier: touch.identifier,
                    pageY: Math.round(touch.pageY)
                };
            }
            else {
                if (e.type == "touchmove")
                    e.preventDefault();
                for (var i = 0; i < e.originalEvent.changedTouches.length; i++) {
                    if (currentTouch.identifier != e.originalEvent.changedTouches[i].identifier)
                        continue;
                    var oldY = currentTouch.pageY;
                    var newY = Math.round(e.originalEvent.changedTouches[i].pageY);
                    var distance = newY - oldY;
                    currentTouch.pageY = newY;
                    move(distance, true);
                }
            }
        }

        function setListPosition(fast) {
            var containerHeight = $('#st-side-menu-container').innerHeight();
            var menuHeight = $('#st-side-menu-list').height();

            // Prevent scroll past top
            if ($scope.offsetY < 0)
                $scope.offsetY = 0;

            // Determine max scroll offset
            var maxOffsetY = Math.max(0, menuHeight - containerHeight);
            if ($scope.offsetY > maxOffsetY)
                $scope.offsetY = maxOffsetY;

            // Determine if we can scroll in more content at bottom
            if ($scope.offsetY > 0) {
                var menuHeightShowing = menuHeight + $scope.offsetY;
                var menuHeightThatCanShow = containerHeight;
                if (menuHeightShowing < menuHeightThatCanShow) {
                    $scope.offsetY = menuHeight - menuHeightThatCanShow;
                }
            }

            // Determine if top button exists
            var showTopButton = $scope.offsetY > 0;
            var showBottomButton = $scope.offsetY < maxOffsetY;

            $scope.$evalAsync(function () {
                var yPos = -1 * $scope.offsetY;
                $scope.showUp = showTopButton;
                $scope.showDown = showBottomButton;
                if (fast)
                    $('#st-side-menu-list').css('top', yPos);
                else
                    $('#st-side-menu-list').animate({ top: yPos }, { duration: 50, queue: false });
            });
        }

        function move(delta, fast) {
            $scope.offsetY -= delta;
            setListPosition(fast);
        }
    }
})();