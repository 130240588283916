(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateLicense", validateLicense);

	function validateLicense($http, $q) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateLicense = function (modelValue, viewValue) {
					ctrl.$error = {};

					if (!viewValue) return $q.when(true);

					var defer = $q.defer();

					$http.post('~/api/v1/settings/sysadmin/validate-license', { input: viewValue })
						.then(function (success) {
							scope.$emit("validate-license", {
								isValid: true,
								returnedData: success
							});
							defer.resolve();
						}, function (failure) {
							ctrl.$error[failure.data.message] = true;
							scope.$emit("validate-license", {
								isValid: false,
								returnedData: success
							});
							defer.reject();
						});

					return defer.promise;
				};
			}
		};
	}
})();