(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("fileInfoProvider", fileInfoProvider);

	function fileInfoProvider() {
		var vm = this;

		vm.iconFromExtension = iconFromExtension;

		/////////////////

		function iconFromExtension(extension) {
			if (!extension) return "file-misc";
			switch (extension.toLowerCase()) {
				case 'zip':
					return "file-zip";

				case 'rar':
					return "file-rar";

				case 'jpg':
				case 'jpeg':
				case 'png':
				case 'gif':
				case 'bmp':
					return "file-image";

				case 'doc':
				case 'docx':
				case 'txt':
				case 'rtf':
					return "file-document";

				case 'pdf':
					return "file-pdf";

				case 'exe':
				case 'wsf':
				case 'cfg':
				case 'pkg':
				case 'ini':
					return "file-executable";

				case 'email':
				case 'msg':
					return "file-email";

				case 'ppt':
				case 'pptx':
				case 'pps':
					return "file-powerpoint";

				case 'vcf':
					return "file-vcf";

				case 'log':
					return "file-document";

				case 'xml':
				case 'asp':
				case 'aspx':
				case 'js':
				case 'css':
				case 'html':
				case 'htm':
				case 'json':
					return "file-programming";

				case 'aif':
				case 'm4a':
				case 'mp3':
				case 'wav':
				case 'wma':
				case 'ogg':
					return "file-audio";

				case 'm4v':
				case 'avi':
				case 'mov':
				case 'wmv':
				case 'webm':
					return "file-video";

				case 'obj':
				case 'fbx':
				case '3ds':
				case 'max':
				case 'mb':
				case 'blend':
				case 'c4d':
					return "file-3d";

				case 'psd':
				case 'psb':
					return "file-photoshop";

				case 'ai':
					return "file-ai";

				case 'tiff':
				case 'tif':
					return "file-file";

				case 'svg':
				case 'eps':
					return "file-vector";

				case 'xls':
				case 'xlsx':
				case 'xhr':
					return "file-excel";

				case 'db':
				case 'dbf':
				case 'mdb':
				case 'sql':
				case 'bin':
					return "file-database";

				case 'apk':
					return "file-apk";

				case 'app':
					return "file-app";

				case 'com':
					return "file-com";

				case 'jar':
					return "file-jar";

				case 'cer':
				case 'crt':
				case 'pfx':
				case 'key':
				case 'pem':
					return "file-certificate";

				case 'crx':
					return "file-crx";

				case 'plugin':
					return "file-plugin";

				case 'dmg':
					return "file-dmg";

				case 'ics':
					return "file-ics";

				case 'locked':
					return "file-locked";

				default:
					return "file-misc";
			}

		}

	}

})();


