(function () {
    'use strict';

    angular
        .module('smartermail')
        .service('apiBase', apiBase);

    /* @ngInject */
    function apiBase($rootScope, $log, $translate, $http, $q, $sessionStorage) {
        var vm = this;

        // Functions
        vm.invalidateAll = invalidateAll;

        activate();

        ///////////////////////////

        function activate() {
            // To load on service instantiation, call this
            // refreshFromHttp();

            // If item could contain translatable elements, add this
            // $rootScope.$on('$translateChangeSuccess', retranslate);
        }

        function invalidateAll() {
            delete $sessionStorage.$reset();
        }

    }

})();