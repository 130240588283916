(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("emailAttachmentsController", emailAttachmentsController);

	emailAttachmentsController.$inject = [
		"$scope",
		"$mdDialog",
		"$http",
		"errorHandling",
		"message",
		"authStorage",
		"localeInfoService",
		"userDataService",
		"$rootScope",
		"$translate"
	];

	function emailAttachmentsController(
		$scope,
		$mdDialog,
		$http,
		errorHandling,
		message,
		authStorage,
		localeInfoService,
		userDataService,
		$rootScope,
		$translate
	) {
		$scope.controller = this;
		$scope.attachments = message.attachments || [];
		$scope.allAttachmentFile = null;
		$scope.showOptions = false;

		activate();

		function activate() {
			$rootScope.spinner.show();
			userDataService.init().then(function () {
				$scope.features = userDataService.user.settings.features;
				$scope.enableOnlyOffice = $scope.features.enableOnlyOffice;
				$scope.enablewopi = $scope.features.enableWOPI;
				$scope.wopiurl = $scope.features.wopiURL;
				$rootScope.spinner.hide();
			});
		}

		for (let i = 0; i < $scope.attachments.length; i++) {
			const item = $scope.attachments[i];
			if (item.allAttachments) {
				$scope.allAttachmentFile = item;
				break;
			}
		}

		$scope.close = function () {
			$mdDialog.cancel();
		};

		async function getWopiToken() {
			return $http.post("~/wopi/GetWOPIAccessToken")
				.then(response => {
					if (response.data && response.data.token) {
						return response.data.token;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}

		function findWhichAttachmentThisIsInTheList(attachment) {
			for (let i = 0; i < $scope.attachments.length; i++) {
				const item = $scope.attachments[i];
				if (item.allAttachments) {
					continue;
				}
				if (item.filename === attachment.filename) {
					return i;
				}
			}
		}

		let collaboraWindow = null;

		$scope.wopiEditCollabora = async function (item) {
			if (collaboraWindow && !collaboraWindow.closed) {
				errorHandling.report($translate.instant("COLLABORA_WINDOW_ALREADY_OPEN"));
				return;
			}

			let myData = item.link.split("/");
			for (const segment of myData) {
				if (segment.includes("download?data=")) {
					myData = "---" + findWhichAttachmentThisIsInTheList(item) + "---" + segment.split("download?data=")[1];
					break;
				}
			}
			try {
				const wopiToken = await getWopiToken();
				const url = await getDiscoveryUrl(2, item.filename, "view");
				if (!url || url === "Unknown") {
					errorHandling.report($translate.instant("THIS_ITEM_CANNOT_BE_PREVIEWED"));
					return;
				}
				if (wopiToken === "Unknown") {
					console.error("Failed to retrieve WOPI access token");
					return;
				}
				const wopiSrcUrl = new URL(`${window.location.origin}/wopi/files`);
				wopiSrcUrl.searchParams.set("file_id", myData);
				wopiSrcUrl.searchParams.set("access_token", wopiToken);
				const userLang = localeInfoService.convertToFullLocale(localeInfoService.language);
				const urlToOpen = `${url}?WOPISrc=${encodeURIComponent(wopiSrcUrl.toString())}&lang=${encodeURIComponent(userLang)}`;
				collaboraWindow = window.open(urlToOpen, "_blank");

				const checkWindowClosed = setInterval(() => {
					if (collaboraWindow.closed) {
						collaboraWindow = null;
						clearInterval(checkWindowClosed);
					}
				}, 1000);

			} catch (error) {
				console.error("Error in wopiEditCollabora:", error);
			}
		};
		async function getDiscoveryUrl(editorType, filename, edit) {
			return $http.post("~/wopi/getdiscoveryurl/" + filename, { editorType, filename, edit })
				.then(response => {
					if (response.data && response.data.discoveryUrl) {
						return response.data.discoveryUrl;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error(error);
					return "Unknown";
				});
		}

		$scope.wopiEditOnlyOffice = async function (item) {
			try {
				if (!item || !item.link || !item.filename) {
					console.error("Invalid item passed to wopiEditOnlyOffice");
					return;
				}

				let myData = null;
				const linkSegments = item.link.split("/");
				for (const segment of linkSegments) {
					if (segment.includes("download?data=")) {
						myData = segment.split("download?data=")[1];
						break;
					}
				}

				if (!myData) {
					console.error("Failed to extract file_id from item link");
					return;
				}

				const url = await getDiscoveryUrl(1, item.filename, "view");
				if (!url || url === "Unknown") {
					errorHandling.report($translate.instant("THIS_ITEM_CANNOT_BE_PREVIEWED"));
					return;
				}
				const wopiToken = await getWopiToken();

				if (!wopiToken) {
					console.error("Failed to retrieve WOPI token");
					return;
				}
				const userLang = localeInfoService.convertToFullLocale(localeInfoService.language);
				const encodedFileId = encodeURIComponent(myData);
				const wopiSrcUrl = `${window.location.origin}/wopi/files/${encodedFileId}`;
				const actionUrl = `${url}?WOPISrc=${encodeURIComponent(wopiSrcUrl)}&ui=${encodeURIComponent(userLang)}`;
				const form = document.createElement("form");
				form.method = "POST";
				form.action = actionUrl;
				form.target = "_blank";

				const accessTokenInput = document.createElement("input");
				accessTokenInput.type = "hidden";
				accessTokenInput.name = "access_token";
				accessTokenInput.value = wopiToken;
				form.appendChild(accessTokenInput);

				document.body.appendChild(form);
				form.submit();
				document.body.removeChild(form);

				console.log("WOPI Edit request submitted successfully");
			} catch (error) {
				console.error("Error in wopiEditOnlyOffice:", error.message);
			}
		};
	}
})();