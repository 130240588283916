(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateCertificateCsr", validateCertificateCsr);

    function validateCertificateCsr() {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                // NOTE: If this validator changes also update validate-certificate-csr.directive.js
                ctrl.$validators.validateCertificateCsr = function (modelValue, viewValue) {
                    return /^[a-z0-9\s.]+$/i.test(viewValue);
                };
            }
        };
    }
})();