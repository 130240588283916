(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("subscribeToCalendarController", subscribeToCalendarController);

    function subscribeToCalendarController($scope, $mdDialog, $http, $filter, $translate, $timeout,
        coreLicensing, source, coreDataSettings, coreDataCalendar, userDataService, coreData, errorHandling, successHandling
    ) {

        var originalShareUsers = [];

        var vm = this;
        vm.newItem = !source;
        vm.name = source ? (source.untranslatedName ? $translate.instant(source.untranslatedName) : source.name) : "";
        vm.originalName = angular.copy(vm.name);
        vm.color = source ? source.color : coreData.nextCalendarColor();
        vm.isCalendar = source ? source.isCalendar : true;
        vm.isResource = source ? source.isResource : false;
        vm.permission = vm.isResource ? 8 : (source ? source.permission : 8);
        vm.access = $filter("translate")("MAPPED_RESOURCES_ACCESS_" + vm.permission);
        vm.isOrigPrimary = source ? source.isPrimary : false;
        vm.isPrimary = source ? source.isPrimary : false;
        vm.isOwner = source ? (source.owner && source.owner.toLowerCase() === userDataService.user.username.toLowerCase() && !source.isDomainResource) : true;
        vm.owner = source ? (source.owner ? source.owner : userDataService.user.domain) : (vm.newItem ? userDataService.user.username : undefined);
        vm.isShareable = vm.isOwner;
        vm.sourceName = source ? (source.isCalendar ? source.id : "MY_TASKS") : "Calendar_";
        vm.shareType = source ? (source.isCalendar ? 3 : 4) : 3;
        vm.edition = 1;
        vm.userPermissions = [];
        vm.groupPermissions = [];
        vm.userGroups = [];
        vm.selectedItem = [];
        vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
        vm.ignoreNameWatch = true;
        vm.isNameValid = true;
        vm.isColorValid = true;
        vm.isValid = true;
        vm.internetCalendarLink = source ? source.internetCalendarLink : "";
        vm.internetCalendarInterval = source ? source.internetCalendarInterval : 60;
        vm.webcalLink = source ? source.webcalLink : null;
        vm.allowWebcalSubscription = source ? source.allowWebcalSubscription : false;
        vm.error = false;
        vm.testing = false;
        if (!source) {
            source = {
                isWebCalendar: true
            };
        }

        // Functions
        vm.queryUsers = coreDataSettings.queryUsers;
        vm.save = save;
        vm.delete = deleteCalendar;
        vm.detach = detach;
        vm.cancel = cancel;
        vm.focusUserInput = focusUserInput;
        vm.copyWebcalLinkToClipboard = copyWebcalLinkToClipboard;
        vm.onEmailChanged = onEmailChanged;
        vm.onEmailBlurred = onEmailBlurred;
        vm.removeUser = removeUser;
        vm.onGroupChanged = onGroupChanged;
        vm.removeGroup = removeGroup;
        vm.validateHexColor = validateHexColor;

        // Setup
        activate();

        //////////////////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            if (vm.isShareable && !vm.newItem) {
                var params = {
                    sharingItem: vm.isCalendar ? 3 : 4,
                    shareID: vm.sourceName
                };
                $http
                    .post("~/api/v1/settings/share-users/", JSON.stringify(params))
                    .then(onShareUsersSuccess, errorHandling.report);

                function onShareUsersSuccess(success) {
                    originalShareUsers = $.extend(true, [], success.data.shareUsers);

                    angular.forEach(success.data.shareUsers, function (share) {
                        if (share.user)
                            vm.userPermissions.push(share);
                        else
                            vm.groupPermissions.push(share);
                    });

                    vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });	// This is necessary to add the "blank entry" field

                    getGroupPermissions();
                }
            } else if (vm.newItem) {
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });	// This is necessary to add the "blank entry" field
                getGroupPermissions();
            }

            $scope.$watch("name", function () {
                if (!vm.ignoreNameWatch) {
                    if (vm.name === "") {
                        vm.isNameValid = false;
                    } else {
                        vm.isNameValid = true;
                        if (coreDataSettings.validateHexSixColor(vm.color)) {
                            vm.isValid = true;
                        }
                    }
                } else {
                    vm.ignoreNameWatch = false;
                }
            });
        }

        function getGroupPermissions() {
            coreDataSettings.settingsData.userGroups
                .then(function (success) {
                    vm.userGroups.push({ name: $filter("translate")("SELECT_A_GROUP"), id: undefined, selectText: true });
                    vm.userGroups.push({ name: $filter("translate")("EVERYONE"), id: -1, isEveryone: true });
                    vm.userGroups.push({ name: $filter("translate")("SHARED_RESOURCES_ADMIN"), id: -2, isAdmins: true });
                    vm.userGroups = vm.userGroups.concat($.extend(true, [], success));

                    if (vm.groupPermissions.length > 0) {
                        angular.forEach(vm.groupPermissions, function (share) {
                            for (var i = 0; i < vm.userGroups.length; i++) {
                                if (vm.userGroups[i].isEveryone && share.isEveryone) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (vm.userGroups[i].isAdmins && share.isAdmins) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (share.group && vm.userGroups[i].id === share.group.id) {
                                    share.group = vm.userGroups[i];
                                    break;
                                }
                            }
                        });
                    }

                    vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
                });
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
        }

        function copyWebcalLinkToClipboard() {
            copyToClipboard(vm.webcalLink);
        }

        function copyToClipboard(value) {
            var textArea = document.createElement("textarea");
            textArea.value = value || "";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            successHandling.report("COPIED_TO_CLIPBOARD");
        }

        function onEmailChanged(last, index, form) {
            var email = form["email" + index].$viewValue;
            if (email && email.length > 0 && last)
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });
        }

        function onEmailBlurred(last, index, form) {
            var email = form["email" + index].$viewValue;
            if ((!email || email.length === 0) && !last) {
                vm.selectedItem.splice(index, 1);
                vm.userPermissions.splice(index, 1);
            }
        }

        function removeUser(index, form) {
            vm.selectedItem.splice(index, 1);
            vm.userPermissions.splice(index, 1);
            form.$setDirty();
        }

        function onGroupChanged(last) {
            if (last)
                vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
        }

        function removeGroup(index, form) {
            vm.groupPermissions.splice(index, 1);
            form.$setDirty();
        }


        function validateHexColor(color) {
            var result = coreDataSettings.validateHexSixColor(color);
            if (result && vm.isNameValid) {
                vm.isValid = true;
            }
            return result;
        }

        function addCalendar() {
            var params = {
                setting: {
                    friendlyName: vm.name,
                    calendarViewColor: vm.color
                }
            };

            $http
                .post("~/api/v1/calendars/calendar-put", JSON.stringify(params))
                .then(onCalendarPutSuccess, errorHandling.report);

            function onCalendarPutSuccess(success) {
                var newSource = {
                    color: vm.color,
                    id: success.data.result,
                    isCalendar: true,
                    isResource: false,
                    isSharedItem: false,
                    isTask: false,
                    isVisible: true,
                    name: vm.name,
                    owner: coreData.user.username,
                    permission: 8,
                    allowWebcalSubscription: vm.allowWebcalSubscription
                };

                coreDataCalendar
                    .addCalendarSource(newSource)
                    .then(function () {
                        source = newSource;
                        vm.sourceName = newSource.id;
                        savePermissions(true);
                    }, errorHandling.report);
            }
        }

        function saveCalendar() {
            var params = {
                setting: {
                    friendlyName: vm.name !== vm.originalName ? vm.name : undefined,
                    calendarViewColor: vm.color,
                    isPrimary: vm.isPrimary,
                    id: source.id,
                    allowWebcalSubscription: vm.allowWebcalSubscription
                }
            };

            $http
                .post("~/api/v1/calendars/calendar", JSON.stringify(params))
                .then(onSaveSuccess, errorHandling.report);

            function onSaveSuccess() {
                $mdDialog.hide({ modified: true });
            }
        }

        function savePermissions(created) {
            vm.groupPermissions = $.grep(vm.groupPermissions, function (share) { return !share.group.selectText });
            vm.groupPermissions = vm.groupPermissions.map(function (g) {
                if (g.group && g.group.isEveryone) {
                    g.group = undefined;
                    g.isEveryone = true;
                } else if (g.group && g.group.isAdmins) {
                    g.group = undefined;
                    g.isAdmins = true;
                }
                return g;
            });

            var shareUsers = vm.userPermissions.concat(vm.groupPermissions);
            shareUsers = $.grep(shareUsers, function (su) {
                if (su.group || su.isEveryone || su.isAdmins) return true;
                var emailLen = su.user && su.user.emailAddress && su.user.emailAddress.length > 0;
                var userLen = su.user && su.user.userName && su.user.userName.length > 0;
                return emailLen || userLen;
            });
            var addedLength = shareUsers.length;

            // Push to server
            var params = {
                shareUsers: shareUsers,
                sharingItem: vm.isCalendar ? 3 : 4,
                shareID: vm.sourceName
            };

            $http
                .post("~/api/v1/settings/share-item", params)
                .then(onShareItemSuccess, errorHandling.report);

            function onShareItemSuccess() {
                coreDataSettings.addPermissions(vm.isCalendar ? 3 : 4, vm.sourceName, addedLength);

                var shareUsers = [];

                for (var iter = 0; iter < originalShareUsers.length; ++iter) {
                    var share = originalShareUsers[iter];
                    var found = false;
                    if (share.user) {
                        for (var i = 0; i < vm.userPermissions.length; i++) { // If we removed the user
                            if (vm.userPermissions[i].user.emailAddress === share.user.emailAddress) {
                                found = true;
                                break;
                            }
                        }
                    } else {
                        for (var i = 0; i < vm.groupPermissions.length; i++) { // If we removed the userGroup
                            if (vm.groupPermissions[i].isEveryone && share.isEveryone) {
                                found = true;
                                break;
                            } else if (vm.groupPermissions[i].isAdmins && share.isAdmins) {
                                found = true;
                                break;
                            } else if (share.group && vm.groupPermissions[i].group && vm.groupPermissions[i].group.id === share.group.id) {
                                found = true;
                                break;
                            }
                        }
                    }
                    if (!found)
                        shareUsers.push(share);
                }

                var removedLength = shareUsers.length;
                var params = {
                    shareUsers: shareUsers,
                    sharingItem: vm.isCalendar ? 3 : 4,
                    shareID: vm.sourceName
                };
                $http
                    .post("~/api/v1/settings/share-users-delete", JSON.stringify(params))
                    .then(onShareUsersDeleteSuccess, errorHandling.report);

                function onShareUsersDeleteSuccess() {
                    coreDataSettings.removePermissions(source.isCalendar ? 3 : 4, vm.sourceName, removedLength);
                    if (created) $mdDialog.hide({ created: true });
                    else $mdDialog.hide({ modified: true });
                }
            }
        }

        function saveSharedCalendar() {
            coreDataSettings.settingsData.mappedResources
                .then(function (success) {
                    for (var i = 0; i < success.length; i++) {
                        if (success[i].itemID === source.id && success[i].ownerUsername === source.owner) {
                            source = success[i];
                            break;
                        }
                    }

                    var oldFriendlyName = source.friendlyName;
                    source.friendlyName = vm.name;
                    source.calendarColor = vm.color;

                    $http.post("~/api/v1/settings/connect-to-share", JSON.stringify({ shareConnectorData: [source] }))
                        .then(function () {
                            source.friendlyName = oldFriendlyName;
                            $mdDialog.hide({ modified: true });
                        }, function (failure) {
                            source.friendlyName = oldFriendlyName;
                            errorHandling.report(failure);
                        });
                });
        };

        function save(ev, userForm) {
            if (vm.name === "") {
                vm.isNameValid = false;
                vm.isValid = false;
            }

            if (!vm.validateHexColor(vm.color))
                vm.isValid = false;

            if (!vm.isValid) return;

            if (userForm.$invalid)
                return;

            vm.testing = true;
            $http
                .post("~/api/v1/calendars/internet-calendars-test", JSON.stringify(vm.internetCalendarLink))
                .then(onSuccess, onFailure)
                .finally(onFinally);

            function onSuccess() {
                vm.error = false;
                if (vm.newItem) {
                    newInternetCalendar();
                } else {
                    editInternetCalendar();
                }
            }

            function onFailure() {
                vm.error = true;
            }

            function onFinally() {
                vm.testing = false;
                userForm.$setPristine(true);
            }
        }

        function newInternetCalendar() {
            var item = {
                id: "00000000-0000-0000-0000-000000000000",
                name: vm.name,
                url: vm.internetCalendarLink,
                interval: vm.internetCalendarInterval,
                intervalOverride: false
            };
            var params = JSON.stringify(item);
            $http
                .post("~/api/v1/calendars/internet-calendars-put", params)
                .then(addSuccess, errorHandling.report);

            function addSuccess(success) {
                source.id = success.data.result;
                coreDataCalendar.reset();
                saveCalendar();
            }
        }

        function editInternetCalendar() {
            var params = JSON.stringify({
                id: source.id,
                name: vm.name,
                url: vm.internetCalendarLink,
                interval: vm.internetCalendarInterval
            });
            $http
                .post("~/api/v1/calendars/internet-calendars-put", params)
                .then(saveCalendar, errorHandling.report);
        }

        function deleteCalendar(ev) {
            if (!vm.isPrimary && !vm.isResource) {
                const confirm = $mdDialog.confirmDeletion()
                    .htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: vm.name }))
                    .targetEvent(ev);
                $mdDialog.show(confirm)
                    .then(
                        function () {
                            $http
                                .post("~/api/v1/calendars/calendar-delete/" + source.id)
                                .then(onCalendarDeleteSuccess, errorHandling.report);
                        },
                        function () { });

                function onCalendarDeleteSuccess() {
                    coreDataCalendar
                        .removeCalendarSource(source.id)
                        .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
                }
            }
        }

        async function detach(ev) {
            try {
                const confirm = $mdDialog.confirmDeletion()
                    .htmlContent($filter("translate")("CONFIRMATIONS_DETACH_SUBSCRIBED_CALENDAR", { name: vm.name }))
                    .targetEvent(ev);
                await $mdDialog.show(confirm);
            }
            catch (err) {
                return;
            }

            // 
            try {
                await $http.post("~/api/v1/calendars/calendar-delete/" + source.id);
                await coreDataCalendar.removeCalendarSource(source.id);
                $mdDialog.hide({ deleted: true });
            }
            catch (err) {
                errorHandling.report(err);
            }

            //coreDataSettings.settingsData.mappedResources
            //    .then(function (success) {
            //        for (var i = 0; i < success.length; i++) {
            //            if (!source.isDomainResource) {
            //                if (success[i].itemID.toLowerCase() === source.id.toLowerCase() && success[i].ownerUsername.toLowerCase() === source.owner.toLowerCase()) {
            //                    source = success[i];
            //                    break;
            //                }
            //            } else {
            //                if (success[i].itemID.toLowerCase() === source.id.toLowerCase()) {
            //                    source = success[i];
            //                    break;
            //                }
            //            }
            //        }
            //        $http
            //            .post("~/api/v1/settings/disconnect-from-share", JSON.stringify({ shareConnectorData: [source] }))
            //            .then(function () {
            //                source.mapped = false;
            //                $mdDialog.hide({ detached: true });
            //            }, errorHandling.report);
            //    });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function focusUserInput() {
            $timeout(function () {
                $("md-autocomplete input").last().trigger("focus");
            });
        };
    }
})();
