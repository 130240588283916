(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainMassEmailController", domainMassEmailController);

	function domainMassEmailController($scope, $mdDialog, $timeout, from, to, subject, emailText, isReminder, errorHandling, $http) {
		$scope.toOptions = [
			{ translate: "ALL_USERS", value: 0 },
			{ translate: "ALL_USERS_ON_DOMAIN", value: 1 },
			{ translate: "ALL_DOMAIN_ADMINISTRATORS", value: 2 },
			{ translate: "SPECIFIC_USER", value: 3 },
		];
		$scope.emailInfo = {
			from: from,
			toOption: $scope.toOptions[0],
			toFriendly: to,
			toEmail: "",
			subject: subject,
			emailText: emailText,
			domainName: "",
			isReminder: isReminder
		};
		$scope.editorScope = {};
		var vm = this;
        activate();

        function activate() {
            $http.get("~/api/v1/settings/sysadmin/internationalized-domain-names")
                .then(function (result) {
                    vm.domainOptions = angular.copy(result.data.data);
                    vm.selectedDomain = vm.domainOptions[0];
                    $scope.emailInfo.domainName = vm.selectedDomain;
                }, errorHandling.report);
        }

		$scope.save = function () {
			$scope.emailInfo.emailText = $scope.editorScope.getHtml();

			$timeout(function () {
				if ($scope.emailForm.$valid) {
                    $mdDialog.hide($scope.emailInfo);
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
		vm.validateContent = function() {
			if ($scope.emailInfo.isReminder) {
				const htmlContent = $scope.editorScope.getHtml();
				$scope.emailForm.messageBodyTextContent.$setValidity("validateImagesNotAllowed", $(htmlContent).find('img[src*="data:"]').length <= 0);
			}
			
		};
        vm.changeDomain = function changeDomain() {
            $scope.emailInfo.domainName = vm.selectedDomain.asciiName;
        }
	}

})();
