(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditWhitelistingController", sysAdminEditWhitelistingController);

	function sysAdminEditWhitelistingController($scope, $mdDialog, addresses, domain, smtp, pop, imap, xmpp, ldap, web_mail, web_dav, mapi_ews, active_sync, bypass, description, spam_bypass, bruteforce_bypass, ip_bypass, greylist_bypass, changed, isNew) {
		var vm = this;
		vm.addresses = addresses;
		vm.domain = domain;
		vm.smtp = smtp;
		vm.pop = pop;
		vm.imap = imap;
		vm.xmpp = xmpp;
		vm.ldap = ldap;
		vm.web_mail = web_mail;
		vm.web_dav = web_dav;
		vm.mapi_ews = mapi_ews;
		vm.active_sync = active_sync;
		vm.bypass = bypass;
		vm.description = description;
		vm.spam_bypass = spam_bypass;
		vm.bruteforce_bypass = bruteforce_bypass;
		vm.isServiceSelected = true;
		vm.selectedType = 0;
		vm.validateSwitches = validateSwitches;
		vm.changed = changed;
		vm.greylist_bypass = greylist_bypass;
		vm.ip_bypass = ip_bypass;

		if (!isNew && 
			!isValidIPv4(vm.addresses) &&
			!isValidIPv4Range(vm.addresses) &&
			!isValidIPv4CidrBlock(vm.addresses) &&
			!isValidIPv6(vm.addresses) &&
			!isValidIPv6CidrBlock(vm.addresses) &&
			!isValidIPv6Range(vm.addresses)) {
			vm.domain = vm.addresses;
			vm.addresses = "";
			vm.selectedType = 1;
        }

		vm.save = save;
		vm.cancel = cancel;
		vm.hideChanged = hideChanged;

		validateSwitches()
		function save() {
			if (!validateSwitches())
				return;

			var address = vm.addresses;
			if (vm.selectedType == 1)
				address = vm.domain;

			$mdDialog.hide({
				address: address,
				domain: vm.domain,
				smtp: vm.smtp,
				pop: vm.pop,
				imap: vm.imap,
				xmpp: vm.xmpp,
				ldap: vm.ldap,
				web_dav: vm.web_dav,
				web_mail: vm.web_mail,
				mapi_ews: vm.mapi_ews,
				active_sync: vm.active_sync,
				bypass: vm.bypass,
				spam_bypass: vm.spam_bypass,
				bruteforce_bypass: vm.bruteforce_bypass,
				greylist_bypass: vm.greylist_bypass,
				ip_bypass: vm.ip_bypass,
				description: vm.description
			});
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function validateSwitches() {
			return true; //Always return true for now, always mark this as saveable.
		    //vm.isServiceSelected = vm.selectedType == 0 ? (vm.smtp || vm.pop || vm.imap || vm.xmpp || vm.bypass || vm.spam_bypass) : vm.bypass;
		    //return vm.isServiceSelected;
		}

		function hideChanged() {
			if (!vm.changed)
				return true;
			else
				return false;
		}

		function isValidIPv4(ip) {
			return ip.match(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g);
		}

		function isValidIPv4Range(ip) {
			return ip.match(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\s*-\s*(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g);
		}

		function isValidIPv4CidrBlock(ip) {
			return ip.match(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/g);
		}

		function isValidIPv6(ipv6) {
			return ipv6.match(/^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/g)
		}

		function isValidIPv6CidrBlock(ipv6) {
			return ipv6.match(/^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(\/([0-9]|[1-9][0-9]|1[0-2][0-9]|13[0-2]))(%.+)?\s*$/g)
		}

		function isValidIPv6Range(ipv6) {
			var parts = ipv6.split('-');
			if (parts.length != 2)
				return false;
			return isValidIPv6(parts[0]) && isValidIPv6(parts[1]);
		}
	}

})();
