(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userEditPasswordController", userEditPasswordController);

	function userEditPasswordController($scope, $mdDialog, $http, coreDataSettings, authStorage, errorHandling, successHandling) {

        // If Authtype isn't SmarterMail
		if (coreDataSettings.userProperties.passwordLocked)
			$scope.passwordLocked = "PASSWORD_LOCKED_DESCRIPTION";
        if (coreDataSettings.userProperties.securityFlags.authType !== 0)
            $scope.passwordLocked = "PASSWORD_ACTIVE_DIRECTORY_DESCRIPTION";

		$scope.oldPassword = "";
		$scope.newPassword = "";
		$scope.confirmPassword = "";

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;

		$scope.OldPasswordError = false;
		activate();

		///////////////////////

		function activate() {
		}

		function save(event, form) {
			if (form.$invalid || !$scope.meetsReq)
				return;

			var clientId = authStorage.getClientId();

			var params = JSON.stringify({ currentPassword: $scope.oldPassword, userData: { password: $scope.newPassword }, clientId: clientId });
			$http
				.post('~/api/v1/settings/user', params)
				.then(onSaveSuccess, onSaveFailure);

			function onSaveSuccess(res) {
				var response = res.data;
                if (response.accessToken && response.refreshToken) {
                    authStorage.saveToken(response, false, "authentication.service.Login()");
				}

                successHandling.report();
				$mdDialog.hide();
			}

			function onSaveFailure(reason) {

				if (reason.data.message.indexOf("PASSWORD_RESET_INCORRECT_OLD_PASSWORD") > -1) {
					$scope.userForm.oldpass.$setValidity("oldpass", false);
					$scope.OldPasswordError = true;
				}

				$scope.$broadcast("passwordCheckCallback", reason);
                form.$invalid = false;
			}
		};

		function cancel() {
			$mdDialog.cancel();
		};

		$scope.oldPasswordChanged = function(){

			if ($scope.OldPasswordError === true) {
				$scope.userForm.oldpass.$setValidity("oldpass", true);
				$scope.OldPasswordError = false;
			}

		}

	}

})();
