(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainImportUsersController", domainImportUsersController);

	function domainImportUsersController($rootScope, $scope, $mdDialog, $filter, $q, $http, $timeout, errorHandling) {
	    var vm = this;

	    vm.fileCount = 0;
        $scope.step = 1;
		$scope.importOptions = [];
		$scope.importDictionary = {};
		$scope.parameters = { importUsers: false }
		$scope.uploaderData = JSON.stringify({ importUsers: false });
		$scope.fieldsWithMissingValues = [];
		vm.temporaryPassword = "";
		$scope.isProcessing = false;
		$scope.tempPasswordRequired = false;
		$scope.importingOptions = [
			"ignore", "username", "password", "displayas", "title", "firstname", "middlename", "lastname", "suffix", "otheremail", "im",
			"homepage", "birthday", "homephone", "workphone", "mobilephone", "pager", "homefax", "workfax", "homeaddress", "homecity",
			"homestate", "homezip", "homecountry", "company", "jobtitle", "department", "office", "workaddress", "workcity",
			"workstate", "workzip", "workcountry", "recoveryemail"
		];

		// Functions
		$scope.uploaderInit = uploaderInit;
		$scope.save = save;
		$scope.next = next;
		$scope.cancel = cancel;
		$scope.uploadedFile = uploadedFile;
		$scope.uploadFailed = uploadFailed;
		$scope.addedFile = addedFile;
		$scope.removedFile = removedFile;
		$scope.checkTempPassRequired = checkTempPassRequired;
		vm.isValid = false;

		activate();

		///////////////////////

		function activate() {
		}

		function uploaderInit(publicFunctions) {
		    $scope.uploaderFunctions = publicFunctions;
		}

		function save() {
			// Fill parameters
			$scope.parameters.importUsers = true;
			$scope.parameters.importOptions = [];
			for (var key in $scope.importDictionary) {
				$scope.parameters.importOptions.push(key + "\r\n" + $scope.importDictionary[key]);
            }
			$scope.parameters.temporaryPassword = vm.temporaryPassword;
			$scope.uploaderData = JSON.stringify($scope.parameters);
			$scope.isProcessing = true;
		    $scope.uploaderFunctions.uploadFile($scope.csvFile);
		};

		function next() {
			if (!(vm.isValid = vm.fileCount > 0))
				return;

			$scope.step = 2;
			$scope.uploaderData = JSON.stringify($scope.parameters);
			$scope.isProcessing = true;
			$scope.uploaderFunctions.startUpload();
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function uploadedFile(file, data) {
			$scope.isProcessing = false;
			$scope.csvFile = file;
			$scope.totalAccounts = data.totalAccounts || 0;
			$scope.exceedsUserLimits = data.exceedsUserLimits || false;
			$scope.fieldsWithMissingValues = data.fieldsWithMissingValues ? $.map(data.fieldsWithMissingValues, function (option) { return option.toLowerCase() }) : [];
			
			if (data.importedUsers || data.editedUsers) {
				// Save and close
				$mdDialog.hide({ importedUsers: data.importedUsers, editedUsers: data.editedUsers });
			} else if (data.importOptions) {
				// Next Step
				$scope.importOptions = $.map(data.importOptions, function (option) { return option.toLowerCase() });

				var mapper = [];
				angular.forEach($scope.importingOptions, function (option) {
					var key = "";
                    switch (option) {
						case "ignore":
						case "displayas":
						case "otheremail": key = "IMPORT_USERS_"; break;

						case "firstname": option = "FIRST_NAME"; break;
						case "middlename": option = "MIDDLE_NAME"; break;
						case "lastname": option = "LAST_NAME"; break;
						case "im": option = "CONTACT_INFO_IM"; break;
						case "homepage": option = "HOME_PAGE"; break;
						case "homephone": option = "HOME_PHONE"; break;
						case "workphone": option = "WORK_PHONE"; break;
						case "mobilephone": option = "MOBILE_PHONE"; break;
						case "homefax": option = "HOME_FAX"; break;
						case "workfax": option = "WORK_FAX"; break;
						case "homeaddress": option = "HOME_ADDRESS"; break;
						case "homecity": option = "HOME_CITY"; break;
						case "homestate": option = "HOME_STATE"; break;
						case "homezip": option = "HOME_ZIP"; break;
						case "homecountry": option = "HOME_COUNTRY"; break;
						case "jobtitle": option = "JOB_TITLE"; break;
						case "workaddress": option = "COMPANY_ADDRESS"; break;
						case "workcity": option = "COMPANY_CITY"; break;
						case "workstate": option = "COMPANY_STATE"; break;
						case "workzip": option = "COMPANY_ZIP"; break;
                        case "workcountry": option = "COMPANY_COUNTRY"; break;
                        case "recoveryemail": option = "RECOVERY_EMAIL"; break;
					}
					key += option.toUpperCase();
					mapper.push($filter("translate")(key));
				});
				angular.forEach($scope.importOptions, function (option) {
					var index = $scope.importingOptions.indexOf(option);
					$scope.importDictionary[option] = index != -1 ? $scope.importingOptions[index] : "ignore";
				});

				checkTempPassRequired();

				$scope.$applyAsync();
			} else {
				// Failure, back to first step
				//errorHandling.report("ERROR_UPLOADING");
				$scope.step = 1;
				vm.fileCount = 0;
				$scope.totalAccounts = 0;
				$scope.exceedsUserLimits = false;
				$scope.uploaderFunctions.reset();
			}
			
		};

		function uploadFailed() {
			$scope.isProcessing = false;
			//errorHandling.report("ERROR_UPLOADING");
			vm.fileCount = 0;
			$scope.totalAccounts = 0;
			$scope.exceedsUserLimits = false;
			$scope.uploaderFunctions.reset();
			if ($scope.step === 2) {
				$mdDialog.hide({ importedUsers: [], editedUsers: [] });
				return;
			}
			$scope.step = 1;
		}

		function addedFile() {
			vm.isValid = true;
			vm.fileCount++;
			$scope.$applyAsync();
		};

		function removedFile() {
			vm.fileCount--;
			if (vm.fileCount < 0)
				vm.fileCount = 0;
		};

		function checkTempPassRequired() {
			$scope.tempPasswordRequired = $scope.importDictionary["password"] === undefined || $scope.importDictionary["password"] === "ignore" || $scope.fieldsWithMissingValues.indexOf($scope.importDictionary["password"]) > -1;
		}
	}
})();
