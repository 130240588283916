(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainGeneralSettingsController", domainGeneralSettingsController);

	function domainGeneralSettingsController($rootScope, $scope, $state, $http, $mdDialog, $q, $filter, $translate, $timeout, coreLicensing, coreDataSettings,
		coreDataDomainSettings, errorHandling, domainAliasesData, mailSigningData, preferencesStorage, popupService, claimsService, userDataService, successHandling, i18n) {

		var vm = this;
		vm.companyInfo = undefined;
		vm.domainAliases = [];
		vm.domainSettings = null;
		vm.form = null;
		vm.edition = 1;
		vm.num = 1000;
		vm.iconPendingSave = false;
		vm.imagePendingSave = false;
		vm.pendingRemoveIcon = false;
		vm.pendingRemoveImage = false;
		vm.uploaderFunctionsIcon = false;
		vm.uploaderFunctionsImage = false;
		vm.logoAction = "";
		vm.loginPreviewGuid = "";
		vm.twoFactorSettingOptions = [
			{ value: 1, translation: "ENABLED" },
			{ value: 2, translation: "FORCED" }
		];
		vm.allowUsersToOptOutOfGal = false;
		vm.autoCleanFolders = [];
		vm.autoCleanInheritedRules = [];
		vm.autoCleanInheritedRuleSource = null;
		vm.showDomainAliasMenu = false;
		vm.allowUserProfileEdits = true;
		vm.countries = [];
		vm.countrySearch = "";
		vm.editedAuthProviders = {
			deletedProviderIds: [],
			updatedProviders: [],
			addedProviders: []
		};
		// Functions
		vm.reloadState = reloadState;
		vm.clearSearchTerm = clearSearchTerm;
		vm.deleteCountryFromList = deleteCountryFromList;
		vm.countryListChanged = countryListChanged;

		// Buttons
		vm.disableDkim = disableDkim;
		vm.editAlias = editAlias;
		vm.enableDkim = enableDkim;
		vm.newAlias = newAlias;
		vm.save = save;
		vm.autoCleanNewRule = autoCleanNewRule;
		vm.autoCleanEditRule = autoCleanEditRule;
		vm.autoCleanDeleteItem = autoCleanDeleteItem;
		vm.autoCleanGetCleanBottomString = autoCleanGetCleanBottomString;
		vm.webRtcNewServer = webRtcNewServer;
		vm.webRtcEditServer = webRtcEditServer;

		// Uploader
		vm.adjustLogoHover = adjustLogoHover;
		vm.imagePreviewIcon = imagePreviewIcon;
		vm.imagePreviewImage = imagePreviewImage;
		vm.removePicture = removePicture;
		vm.uploadFile = uploadFile;
		vm.uploaderInitIcon = uploaderInitIcon;
		vm.uploaderInitImage = uploaderInitImage;

		// Open Modal
		vm.addDkimRollover = addDkimRollover;
		vm.openDkimFieldsModal = openDkimFieldsModal;
		vm.viewActiveDkimKey = viewActiveDkimKey;
		vm.viewPendingDkimKey = viewPendingDkimKey;

		vm.openExternalWhitelistModal = openExternalWhitelistModal;
		vm.openFooterBody = openFooterBody;
		vm.openHtmlLoginModal = openHtmlLoginModal;
		vm.openHtmlAUPModal = openHtmlAUPModal;
		vm.openLoginPreview = openLoginPreview;
		vm.openIncomingExtensionBlacklist = openIncomingExtensionBlacklist;
		vm.openOutgoingExtensionBlacklist = openOutgoingExtensionBlacklist;
		vm.editMailingListHelpMessage = editMailingListHelpMessage;

		// On Changed
		vm.customHelpTextChanged = customHelpTextChanged;
		vm.customHelpUrlChanged = customHelpUrlChanged;
		vm.customLoginTextChanged = customLoginTextChanged;
		vm.customLoginPageHtmlEnabledChanged = customLoginPageHtmlEnabledChanged;

		activate();

		/////////////

		function activate() {
			refresh();

			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);

			$scope.$on("$destroy", function () {
				closePreviewWindow();
				window.removeEventListener("beforeunload", closePreviewWindow);
			});

			window.addEventListener("beforeunload", closePreviewWindow);

			$rootScope.$on("signalR.mailHub.client.dkimRecordsUpdated", onDkimRecordsUpdated);

			function closePreviewWindow() {
				if (vm.previewWindow)
					vm.previewWindow.close();
			}
		}

		function recalculateLicense() {
			vm.edition = coreLicensing.edition;
			vm.hasEnterpriseFunctionality = coreLicensing.hasEnterpriseFunctionality;
		}

		async function refresh() {
			try {

				let result = await coreDataDomainSettings.init();

				vm.domain = $rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain;
				vm.permissions = $.extend(true, {}, coreDataDomainSettings.domainPermissions);
				refreshPicture();

				//vm.logoUrl = "~/api/v1/companyinfo/logo/" + vm.domain + "?" + (vm.num--).toString();
				//vm.faviconUrl = "~/api/v1/companyinfo/favicon/" + vm.domain + "?" + (vm.num--).toString();
				if (!vm.uploaderData)
					vm.uploaderData = JSON.stringify({ domain: vm.domain, guid: createGuid() });

				result = await $q.all([
					domainAliasesData.loadAliases(),
					$http.get('~/api/v1/settings/domain/domain'),
					$http.get(`~/api/v1/companyInfo/${vm.domain}/`),
					$http.get('~/api/v1/settings/auto-clean').then(onAutoCleanLoadCompleted, function () { }),
					$http.get('~/api/v1/settings/domain/webrtc-config').then(onWebRtcConfigLoadCompleted, function () { }),
					$http.get("~/api/v1/settings/auto-clean-calendar").then(function (success) { vm.calAutoCleanSettings = success.data; }),
					$http.get("~/api/v1/settings/domain/country-codes").then(onCountryCodesLoaded, errorHandling.report),
					$http.get("~/api/v1/settings/domain/mailing-lists/0/system-messages").then(onMailingListMessagesLoaded, errorHandling.report),
					$http.get("~/api/v1/settings/domain/idn").then(function (success) { vm.domainIdn = success.data.idnName; }, function () { }),
				]);
				onLoadCompleted(result);
			} catch (err) {
				errorHandling.report(err);
			}

			function onWebRtcConfigLoadCompleted(result) {
				vm.webRtcServers = result.data.stunTurnServers;
			}

			function onAutoCleanLoadCompleted(result) {
				vm.autoCleanSettings = result.data;
				vm.autoCleanSettings.domainRules = vm.autoCleanSettings.domainRules || [];
				vm.autoCleanSettings.systemRules = vm.autoCleanSettings.systemRules || [];
				for (var i = 0; i < vm.autoCleanSettings.domainRules.length; ++i) {
					vm.autoCleanSettings.domainRules[i].translatedPath = autoCleanGetFolderNameTranslation(vm.autoCleanSettings.domainRules[i].folderName);
				}
				for (var i = 0; i < vm.autoCleanSettings.systemRules.length; ++i) {
					vm.autoCleanSettings.systemRules[i].translatedPath = autoCleanGetFolderNameTranslation(vm.autoCleanSettings.systemRules[i].folderName);
				}
				vm.autoCleanFolders = vm.autoCleanSettings.allowAutoCleanInbox
					? [{
						name: 'deleted items', folderName: 'deleted items', path: 'deleted items',
						translatedPath: $translate.instant("DELETED_ITEMS")
					}, {
						name: 'inbox', folderName: 'inbox', path: 'inbox',
						translatedPath: $translate.instant("INBOX")
					}, {
						name: 'junk e-mail', folderName: 'junk e-mail', path: 'junk e-mail',
						translatedPath: $translate.instant("JUNK_EMAIL")
					}, {
						name: 'sent items', folderName: 'sent items', path: 'sent items',
						translatedPath: $translate.instant("SENT_ITEMS")
					}]
					: [{
						name: 'deleted items', folderName: 'deleted items', path: 'deleted items',
						translatedPath: $translate.instant("DELETED_ITEMS")
					},
					{
						name: 'junk e-mail', folderName: 'junk e-mail', path: 'junk e-mail',
						translatedPath: $translate.instant("JUNK_EMAIL")
					},
					{
						name: 'sent items', folderName: 'sent items', path: 'sent items',
						translatedPath: $translate.instant("SENT_ITEMS")
					}];
				angular.forEach(vm.autoCleanFolders, function (p) { p.translatedPath = autoCleanGetFolderNameTranslation(p.name); });
				vm.autoCleanInheritedRuleSource = "system";
				vm.autoCleanInheritedRules = vm.autoCleanSettings.systemRules;
			}

			function onCountryCodesLoaded(result) {
				vm.countries = [];
				angular.forEach(result.data.results, function (key) {
					vm.countries.push({ key: key, translation: $translate.instant('COUNTRY_CODE_' + key) })
				});
			}

			function onLoadCompleted(result) {
				vm.domainAliases = domainAliasesData.domainAliases;
				vm.domainSettings = result[1].data.domainSettings;
				vm.authProviders = vm.domainSettings.authenticationProviders;
				vm.editedAuthProviders = {
					deletedProviderIds: [],
					updatedProviders: [],
					addedProviders: []
				}
				vm.blockedCountrySettings = {
					type: (vm.domainSettings.authBlockedCountrySettings.listType & ~4),
					isGlobal: !!(vm.domainSettings.authBlockedCountrySettings.listType & 4),
					countryList: vm.domainSettings.authBlockedCountrySettings.countryList,
					removedItem: []
				}
				vm.isSysAdmin = claimsService.isSysAdmin() || claimsService.impersonating();
				vm.showDomainAliasMenu = vm.domainSettings.showDomainAliasMenu;
				vm.canEditDomainAliases = vm.showDomainAliasMenu || claimsService.isSysAdmin() || claimsService.impersonating();
				vm.calendarPublicAvailability = vm.domainSettings.calendarPublicAvailability;
				vm.customLoginDisplay = vm.domainSettings.customLoginDisplay;
				vm.allowUserProfileEdits = vm.domainSettings.allowUserProfileEdits;
				vm.allowUsersToOptOutOfGal = !vm.domainSettings.ldapDisallowOptOut;
				if (vm.isSysAdmin) {
					vm.twoFactorSettingOptions.push({ value: 6, translation: "FORCE_LOCKED" });
				}
				vm.footerAllowOverride = vm.domainSettings.footerSettings.allowOverride;
				vm.footerEnabled = vm.domainSettings.footerSettings.enabled;
				vm.footerApplyToMailingLists = vm.domainSettings.footerSettings.applyToMailingLists;
				vm.footerText = vm.domainSettings.footerSettings.footerText;
				vm.systemFooterText = vm.domainSettings.systemFooterSettings.footerText;
				vm.systemFooterEnabled = vm.domainSettings.systemFooterSettings.enabled;

				vm.companyInfo = result[2].data.companyInfo;
				if (!vm.companyInfo.customLoginTextEnabled)
					vm.companyInfo.customLoginText = null;
				refreshPicture();

				vm.dkim = vm.domainSettings.domainKeysSettings;
				vm.dkim.enabled = vm.domainSettings.enableDkimSigningDomainAdmin;
				vm.dkim.activeData = !vm.dkim.isActive
					? undefined
					: {
						keySize: vm.dkim.keySize,
						selector: vm.dkim.selector,
						publicKey: vm.dkim.publicKey,
						forced: vm.dkim.forced
					};

				if (vm.previewingLogin)
					setLocalStorageForPreview();

				vm.incomingExtensionBlacklist = vm.domainSettings.incomingFileTypeRestriction.blackList || [];
				vm.outgoingExtensionBlacklist = vm.domainSettings.outgoingFileTypeRestriction.blackList || [];

				countryListChanged();

				// SSL calculated properties
				if (vm.domainSettings.sslCertificates) {
					for (let i = 0; i < vm.domainSettings.sslCertificates.length; i++) {
						let item = vm.domainSettings.sslCertificates[i];
						item.IsExpired = (item.cert_expires_utc && new Date(item.cert_expires_utc) < new Date());
					}
				}
			}
		}

		function onMailingListMessagesLoaded(result) {
			vm.mailingListHelpMessage = result.data.systemMessages.find(message => message.name === "HELPMESSAGE") ||
				{ name: "HELPMESSAGE", lastModified: null, needsSubject: true, body: "" };

		}

		function webRtcNewServer(ev, form) {
			$mdDialog
				.show({
					locals: {
						server: null,
						newItem: true
					},
					controller: "editStunServerController",
					controllerAs: "ctrl",
					templateUrl: "app/shared/modals/stun-server.dlg.html",
					targetEvent: ev
				})
				.then(onNewRuleOk, function () { });

			function onNewRuleOk(modalSuccess) {
				modalSuccess.server.uris = modalSuccess.server.urlstring && modalSuccess.server.urlstring.split(/[\r\n]+/);
				modalSuccess.server.uris.sort();
				modalSuccess.server.urlstring = null;
				vm.webRtcServers.push(modalSuccess.server);
				vm.webRtcServers.sort(function (a, b) { return (a.uris[0] || "").localeCompare(b.uris[0] || ""); });
				form.$setDirty();
			}
		}

		function webRtcEditServer(ev, item, form) {
			$mdDialog
				.show({
					locals: {
						server: {
							serverType: item.serverType,
							urlstring: item.uris.join('\r\n'),
							username: item.username,
							credential: item.credential
						},
						newItem: false
					},
					controller: "editStunServerController",
					controllerAs: "ctrl",
					templateUrl: "app/shared/modals/stun-server.dlg.html",
					targetEvent: ev
				})
				.then(onEditRuleOk, function () { });

			function onEditRuleOk(modalSuccess) {
				var index;
				if (modalSuccess.deleteItem) {
					index = vm.webRtcServers.indexOf(item);
					if (index > -1) {
						vm.webRtcServers.splice(index, 1);
					}
					form.$setDirty();
					return;
				}

				modalSuccess.server.uris = modalSuccess.server.urlstring && modalSuccess.server.urlstring.split(/[\r\n]+/);
				modalSuccess.server.uris.sort();
				modalSuccess.server.urlstring = null;

				index = vm.webRtcServers.indexOf(item);
				if (index > -1) {
					vm.webRtcServers.splice(index, 1, modalSuccess.server);
				}
				vm.webRtcServers.sort(function (a, b) { return (a.uris[0] || "").localeCompare(b.uris[0] || ""); });
				form.$setDirty();
			}
		}

		function reloadState(form) {
			vm.logoAction = "revert";
			refresh();
			form.$setPristine();
		}

		function reload() {
			vm.domainAliases = domainAliasesData.domainAliases;
		}

		//// Email Signing (DKIM)
		function addDkimRollover(event) {
			var addDefer = $q.defer();

			$mdDialog
				.show({
					controller: "mailSigningViewController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/modals/mail-signing-view.dlg.html",
					targetEvent: event,
					locals: { settings: { keySize: vm.dkim.keySize }, mode: "newRollover", preventDelete: !vm.dkim.active }
				})
				.then(
					function (success) {
						refreshDkimData();
						verifyPendingDkimKey(success.force, false);
						addDefer.resolve(success);
					},
					function () {
						addDefer.reject();
					});

			return addDefer.promise;
		}

		function disableDkim(event) {
			var confirmBody = $translate.instant("CONFIRMATIONS_DISABLE_DKIM");
			if (vm.dkim.pending)
				confirmBody += `\r\n${$translate.instant("CONFIRMATIONS_DISABLE_DKIM_PENDING")}`;

			// Confirm modal
			var confirm = $mdDialog.confirmDeletion()
				.textContent(confirmBody)
				.ok($filter("translate")("DISABLE"))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDisableDkim, null);
		}

		function doDisableDkim() {
			mailSigningData
				.disableDkim()
				.then(function () {
					vm.dkim.enabled = false;
				}, errorHandling.report);
		}

		function enableDkim(event) {
			mailSigningData.enableDkim()
				.then(refreshDkimData, errorHandling.report)
				.then(function () {
					if (vm.dkim.activeData)
						viewActiveDkimKey(event);
					else if (vm.dkim.pending)
						viewPendingDkimKey(event);
					else {
						addDkimRollover(event)
							.then(function () { }, doDisableDkim);
					}
				}, function () { });
		}

		function onDkimRecordsUpdated(event, data) {
			if (data.domain.toLowerCase() !== vm.domain.toLowerCase())
				return;

			vm.dkim.isActive = data.isActive;
			vm.dkim.activeData = data.activeData;
			vm.dkim.pending = data.pending;
			$scope.$applyAsync();
		}

		function openDkimFieldsModal(event) {
			$mdDialog
				.show({
					controller: "mailSigningFieldsDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/modals/mail-signing-fields.dlg.html",
					targetEvent: event,
					locals: { fields: vm.dkim.dkimHeaderFields }
				})
				.then(function (success) {
					vm.dkim.dkimHeaderFields = success.fields;
					vm.form.$setDirty();
				}, function () { });
		}

		function refreshDkimData() {
			return mailSigningData.getSettings()
				.then(
					function (success) {
						vm.dkim.dkimCanonicalizationAlgorithmBody = success.canonicalizationBody;
						vm.dkim.dkimCanonicalizationAlgorithmHeader = success.canonicalizationHeader;
						vm.dkim.dkimHeaderFieldOption = success.fieldOption;
						vm.dkim.dkimHeaderFields = success.fields;
						vm.dkim.keySize = success.keySize;
						vm.dkim.maxMessageSign = success.maxMessageSign;

						vm.dkim.enabled = true;
						vm.dkim.activeData = !success.isActive
							? undefined
							: {
								keySize: success.keySize,
								selector: success.selector,
								publicKey: success.publicKey,
								forced: success.forced
							};
						vm.dkim.pending = success.pending;
					},
					function (data) {
						errorHandling.report(data);
					});
		}

		function verifyPendingDkimKey(force, reportDnsFailure) {
			force = !!force;
			$http.post(`~/api/v1/settings/domain/dkim-verify-rollover/${force}`)
				.then(
					function (result) {
						if (result.data.success) {
							refreshDkimData();
						}
						else if (reportDnsFailure) {
							errorHandling.report("DNS_VERIFICATION_FAILED");
						}
					},
					errorHandling.report);
		}

		function viewActiveDkimKey(event) {
			var settings = $.extend(vm.dkim.activeData, { enabled: vm.dkim.enabled })
			$mdDialog
				.show({
					controller: "mailSigningViewController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/modals/mail-signing-view.dlg.html",
					targetEvent: event,
					locals: { settings: settings, mode: "viewActive", preventDelete: false }
				})
				.then(function (result) {
					if (result.validate) {
						$http.post("~/api/v1/settings/domain/dkim-verify-active")
							.then(
								function (result) {
									if (result.data.success) {
										refreshDkimData();
									}
									else {
										errorHandling.report("DNS_VERIFICATION_FAILED");
									}
								},
								errorHandling.report);
					}
				}, function () { });
		}

		function viewPendingDkimKey(event) {
			$mdDialog
				.show({
					controller: "mailSigningViewController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/modals/mail-signing-view.dlg.html",
					targetEvent: event,
					locals: { settings: vm.dkim.pending, mode: "viewRollover", preventDelete: !vm.dkim.active }
				})
				.then(function (result) {
					if (result.delete) {
						let confirm = $mdDialog.confirmDeletion()
							.textContent($translate.instant("CONFIRMATIONS_DELETE_PENDING_DKIM"));
						$mdDialog.show(confirm)
							.then(function () {
								$http.post("~/api/v1/settings/domain/dkim-delete-rollover")
									.then(function () {
										vm.dkim.pending = null;
									}, errorHandling.report);
							}, function () { });
					}
					else if (result.force) {
						$http.post("~/api/v1/settings/domain/dkim-verify-rollover/true")
							.then(function () {
								refreshDkimData();
							}, errorHandling.report);
					}
				}, function () { });
		}

		//// Buttons
		function editAlias(event, item, form) {
			if (!vm.canEditDomainAliases)
				return;
			$mdDialog
				.show({
					locals: {
						name: item.name,
						idn: item.aliasIdn,
						domain: vm.domain,
						domainIdn: vm.domainIdn
					},
					controller: "domainEditDomainAliasController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/domain-alias.dlg.html",
					targetEvent: event
				})
				.then(function (modalResult) {
					if (modalResult.deleteAlias) {
						var confirm = $mdDialog.confirmDeletion()
							.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS_one'))
							.targetEvent(event);
						$mdDialog.show(confirm)
							.then(function () {
								domainAliasesData.deleteAlias(modalResult.deleteAlias)
									.then(reload, errorHandling.report);
							}, $mdDialog.cancel);
					} else {
						reload();
					}
				}, function () { });
		}

		function newAlias(event, form) {
			if (!vm.canEditDomainAliases)
				return;
			$mdDialog
				.show({
					locals: {
						name: undefined,
						idn: undefined,
						domain: vm.domain,
						domainIdn: vm.domainIdn
					},
					controller: "domainEditDomainAliasController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/domain-settings/general-settings/domain-alias.dlg.html",
					targetEvent: event
				})
				.then(reload, function () { });
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			if (!vm.allowUserProfileEdits) vm.allowUsersToOptOutOfGal = false;
			var params = {
				disableMailingListDeliveryAfterXBounces: vm.domainSettings.disableMailingListDeliveryAfterXBounces,
				disableMailingListDeliveryAfterBouncesInXDays: vm.domainSettings.disableMailingListDeliveryAfterBouncesInXDays,
				enableMailingListDayThreshold: vm.domainSettings.enableMailingListDayThreshold,
				listCommandAddress: vm.domainSettings.listCommandAddress,
				allowUserProfileEdits: vm.allowUserProfileEdits,
				ldapDisallowOptOut: !vm.allowUsersToOptOutOfGal,
				calendarPublicAvailability: vm.calendarPublicAvailability,
				footerSettings: {
					allowOverride: vm.footerAllowOverride,
					enabled: vm.footerEnabled,
					applyToMailingLists: vm.footerApplyToMailingLists,
					footerText: vm.footerText
				},
				outgoingFileTypeRestriction: { blacklist: vm.outgoingExtensionBlacklist },
				incomingFileTypeRestriction: { blacklist: vm.incomingExtensionBlacklist },
				twoFactorSettings: {
					setting: vm.domainSettings.twoFactorSettings.setting
				},
				acceptableUsePolicySettings: {
					html: vm.domainSettings.acceptableUsePolicySettings.html,
					enabled: vm.domainSettings.acceptableUsePolicySettings.enabled,
					version: vm.domainSettings.acceptableUsePolicySettings.version,
					emailsEnabled: vm.domainSettings.acceptableUsePolicySettings.emailsEnabled
				},
				allowSpamActionOverride: vm.domainSettings.allowSpamActionOverride,
				authProviderInputs: vm.editedAuthProviders
			};

			//if (vm.permissions)
			params.domainUrl = vm.domainSettings.domainUrl;

			if (vm.calAutoCleanSettings.allowDomainOverride) {
				params.calendarAutoCleanOverrideActive = vm.calAutoCleanSettings.domainOverrideActive;
				params.allowCalendarAutoCleanUserOverride = vm.calAutoCleanSettings.allowUserOverride;
				params.CalendarAutoCleanMonths = vm.calAutoCleanSettings.domainCalendarAutoCleanSetting;
			}

			const setCountries = [];
			for (var i = 0; i < vm.blockedCountrySettings.countryList.length; ++i) {
				if (vm.blockedCountrySettings.countryList[i] !== '') {
					setCountries.push(vm.blockedCountrySettings.countryList[i].item2);
				}
			}
			params.blockedCountriesAtAuth = {
				type: vm.blockedCountrySettings.type,
				countries: setCountries,
			};
			if (vm.domainSettings.externalSenderOverrideSettings.allowOverride) {
				params.externalSenderSettings = {
					enabled: vm.domainSettings.externalSenderOverrideSettings.overrideActive,
					add_to_body: vm.domainSettings.externalSenderSettings.add_to_body,
					add_to_subject: vm.domainSettings.externalSenderSettings.add_to_subject,
					subject_text: vm.domainSettings.externalSenderSettings.subject_text,
					body_text: vm.domainSettings.externalSenderSettings.body_text,
					whitelist: vm.domainSettings.externalSenderSettings.whitelist
				};
			}



			$http
				.post("~/api/v1/settings/domain/domain", JSON.stringify({ domainSettings: params }))
				.then(function (success) {

						if (success.data &&
							success.data.message &&
							success.data.message.includes("AUTHENTICATION_PROVIDER_CANNOT_DELETE_IN_USE_ERROR_MESSAGE")) {

							errorHandling.warn("AUTHENTICATION_PROVIDER_CANNOT_DELETE_IN_USE_ERROR_MESSAGE");
						}
						if (vm.imagePendingSave) {
							 return vm.uploaderFunctionsImage.startUpload();
						}
						else if (vm.pendingRemoveImage) {
							 return $http.post("~/api/v1/companyinfo/logo-delete/" + vm.domain + "/");
						}
					},
						function (error) {
							if (error.data.message === "BLOCKING_AUTHENTICATION_TO_THE_COUNTRY_ERROR_MESSAGE") {
								refresh();
							}
							errorHandling.report(error);
						}
				)
				.then(function () {
					if (vm.iconPendingSave) { return vm.uploaderFunctionsIcon.startUpload(); }
					else if (vm.pendingRemoveIcon) { return $http.post("~/api/v1/companyinfo/favicon-delete/" + vm.domain + "/"); }
				}, errorHandling.report)
				.then(saveLoginCard, errorHandling.report)
				.then(saveAutoCleanCard, errorHandling.report)
				.then(saveWebRtcSettings, errorHandling.report)
				.then(saveDkimSettings, errorHandling.report)
				.then(onSaveCompleted, errorHandling.report);

			function saveLoginCard() {
				var params = {
					customHelpLink: vm.companyInfo.customHelpLink,
					customHelpLinkEnabled: vm.companyInfo.customHelpLinkEnabled,
					customHelpText: vm.companyInfo.customHelpText,
					customTitleText: vm.companyInfo.customTitleText,
					customLoginText: vm.companyInfo.customLoginText,
					customLoginTextEnabled: vm.companyInfo.customLoginText ? true : false,
					showLoginAreaHTML: vm.companyInfo.showLoginAreaHTML,
					htmlDisplayBadge: vm.companyInfo.htmlDisplayBadge,
				};
				return $http.post(`~/api/v1/companyinfo/${vm.domain}/`, JSON.stringify({ companyInfo: params }));
			}

			function saveAutoCleanCard() {
				if (!vm.autoCleanSettings.allowDomainOverride)
					return;

				var params = {
					domainOverrideActive: vm.autoCleanSettings.domainOverrideActive,
					allowUserOverride: vm.autoCleanSettings.allowUserOverride,
					domainRules: vm.autoCleanSettings.domainRules
				};
				return $http.post("~/api/v1/settings/auto-clean-domain", JSON.stringify(params));
			}

			function saveDkimSettings() {
				var params = {
					maxMessageSign: vm.dkim.maxMessageSign,
					canonicalizationBody: vm.dkim.dkimCanonicalizationAlgorithmBody,
					canonicalizationHeader: vm.dkim.dkimCanonicalizationAlgorithmHeader,
					fieldOption: vm.dkim.dkimHeaderFieldOption,
					fields: vm.dkim.dkimHeaderFields
				};

				return $http.post("~/api/v1/settings/domain/dkim-settings-set", JSON.stringify(params));
			}

			function saveWebRtcSettings() {
				var params = { stunTurnServers: vm.webRtcServers };
				return $http.post("~/api/v1/settings/domain/webrtc-config", JSON.stringify(params));
			}

			function onSaveCompleted() {
				//reset permissions stuff for two factor.
				if (params.twoFactorSettings.setting !== 0) {
					coreDataSettings.userPermissions.enableTwoFactor = true;
				} else {
					coreDataSettings.userPermissions.enableTwoFactor = false;
				}

				coreDataSettings.userPermissions.twoFactorTypes = [1, 2];
				if (params.twoFactorSettings.setting !== 2) {
					coreDataSettings.userPermissions.twoFactorTypes.push(0);
				}

				form.$setPristine();
				$rootScope.$broadcast("domain-settings:changed");
				vm.logoAction = "replace";
				if (vm.previewingLogin)
					setLocalStorageForPreview();

				vm.countryListChanged();
				refresh();
			}
		}

		//// Uploader
		function adjustLogoHover() {
			$(".profile-setting-logo #logo-hover").css({ width: $(".profile-setting-logo img").width() });
		}

		function createGuid() {
			vm.loginPreviewGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
			return vm.loginPreviewGuid;
		}

		function imagePreviewImage(imgSrc, file) {
			vm.file = file;

			if (vm.previewingLogin)
				uploadTempFile(true);

			vm.logoAction = "";
			vm.logoUrl = imgSrc;
			vm.form.$setDirty();
			vm.imagePendingSave = true;
			vm.logoChanged = true;
			$scope.$applyAsync();
		}

		function imagePreviewIcon(imgSrc) {
			vm.faviconUrl = imgSrc;
			vm.form.$setDirty();
			vm.iconPendingSave = true;
			$scope.$applyAsync();
		}

		function refreshPicture() {
			var promises = [];
			promises.push($http.get("~/api/v1/companyinfo/logo-exists/" + vm.domain + "/"));
			//promises.push($http.get("~/api/v1/companyinfo/favicon-exists/" + vm.domain + '/'));
			$q.all(promises).then(onPicturesRefreshed, function () { });

			function onPicturesRefreshed(success) {
				vm.logoUrl = success[0].data ? (stSiteRoot + "api/v1/companyinfo/logo/") + vm.domain : undefined;
				//vm.faviconUrl = success[1].data ? (stSiteRoot + "api/v1/companyinfo/favicon/") + vm.domain : undefined;
				vm.randomUrlString = (vm.num--).toString();
				$scope.$applyAsync();
			}
		};

		function removePicture(logo, ev) {
			var picture = logo ? "logo" : "favicon";

			if (picture == "logo") {
				vm.logoUrl = null;
				vm.pendingRemoveImage = true;
				vm.imagePendingSave = false;

				preferencesStorage.setLocalParam("loginPreviewChanges", { imageGuid: "" });
				vm.logoChanged = true;
				vm.logoAction = "";
				vm.loginPreviewGuid = "";
			}
			else {
				vm.faviconUrl = null;
				vm.pendingRemoveIcon = true;
				vm.iconPendingSave = false;
			}
			vm.form.$setDirty();
		}

		function uploaderInitIcon(publicFunctions) {
			vm.uploaderFunctionsIcon = publicFunctions;
		}

		function uploaderInitImage(publicFunctions) {
			vm.uploaderFunctionsImage = publicFunctions;
		}

		function uploadFile(result, dropzone) {
			if (!result || result === -1 || result.code === 500) {
				errorHandling.report("ERROR_UPLOADING");
			} else {
				refreshPicture();
			}
		}

		function uploadTempFile(setStorage) {
			if (!vm.file)
				return $q.when();
			var promise = $http({
				method: 'POST',
				url: '~/api/v1/settings/sysadmin/temp-image/' + createGuid(),
				headers: {
					'Content-Type': undefined
				},
				data: {
					upload: vm.file
				},
				transformRequest: function (data, headersGetter) {
					var formData = new FormData();
					angular.forEach(data, function (value, key) {
						formData.append(key, value);
					});

					var headers = headersGetter();
					delete headers['Content-Type'];

					return formData;
				}
			})
				.then(function () {
					if (setStorage)
						preferencesStorage.setLocalParam("loginPreviewChanges", { imageGuid: vm.loginPreviewGuid });
				}, errorHandling.report);

			vm.file = undefined;
			return promise;
		}

		//// Open Modal
		function openExternalWhitelistModal(event, form) {
			const whitelistIn = angular.copy(vm.domainSettings.externalSenderOverrideSettings.overrideActive
				? vm.domainSettings.externalSenderSettings.whitelist
				: vm.domainSettings.externalSenderOverrideSettings.defaultWhitelist);
			$mdDialog
				.show({
					locals: {
						extensions: whitelistIn,
						title: "KNOWN_EXTERNAL_DOMAINS",
						isReadOnly: !vm.domainSettings.externalSenderOverrideSettings.overrideActive
					},
					controller: "extensionBlacklistDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
					targetEvent: event
				})
				.then(
					function (success) {
						if (vm.domainSettings.externalSenderOverrideSettings.overrideActive) {

							var filtered = $.grep(success.extensions, value => (value || "").trim());
							vm.domainSettings.externalSenderSettings.whitelist = filtered;
							vm.domainSettings.externalSenderOverrideSettings.whitelist = filtered;
							form.$setDirty();
						}
					},
					function () { });
		}

		function openFooterBody(ev, form) {
			$mdDialog.show({
				locals: {
					footerText: (vm.permissions.footerSettingsAllowOverride && vm.footerAllowOverride) ? vm.footerText : vm.systemFooterText,
					domainOverride: vm.domain,
					context: "domain",
					readOnly: !vm.permissions.footerSettingsAllowOverride || !vm.footerAllowOverride
				},
				controller: "domainFooterController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/email-customization/email-footer.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (vm.footerText !== modalSuccess) { vm.footerText = modalSuccess; form.$setDirty(); }
				});
		}

		function openHtmlLoginModal(ev, form) {
			$mdDialog.show({
				locals: {
					body: vm.companyInfo.htmlDisplayBadge
				},
				controller: "domainEditLoginHtmlController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/general-settings/login-html.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (vm.companyInfo.htmlDisplayBadge === modalSuccess)
						return;
					vm.companyInfo.htmlDisplayBadge = modalSuccess;
					form.$setDirty();
					preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: vm.companyInfo.htmlDisplayBadge });
				}, function () { });
		}

		function openHtmlAUPModal(ev, form) {
			$mdDialog.show({
				locals: {
					body: vm.domainSettings.acceptableUsePolicySettings.html
				},
				controller: "domainEditAUPHtmlController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/general-settings/aup-html.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (vm.domainSettings.acceptableUsePolicySettings.html === modalSuccess.body)
						return;
					vm.domainSettings.acceptableUsePolicySettings.html = modalSuccess.body;
					if (modalSuccess.update) {
						vm.domainSettings.acceptableUsePolicySettings.version = vm.domainSettings.acceptableUsePolicySettings.version + 1;
					}
					form.$setDirty();
					//preferencesStorage.setLocalParam("aupPreviewChanges", { customHtmlBlock: vm.domainSettings.acceptableUsePolicySettings.html });
				}, function () { });
		}

		function openLoginPreview() {
			if (vm.logoChanged) {
				uploadTempFile()
					.then(openPreview, errorHandling.report);
			}
			else
				openPreview();

			function openPreview() {
				vm.previewingLogin = true;
				setLocalStorageForPreview();
				var url = window.location.href.split("#")[0] + '#/popout/login-preview';
				vm.previewWindow = window.open(url, "login-preview", "resizable=1, " + popupService.dimensions.loginPreview);
			}
		}

		function openIncomingExtensionBlacklist(event, form) {
			$mdDialog.show({
				locals: { extensions: angular.copy(vm.incomingExtensionBlacklist), title: "INBOUND_EXTENSION_BLACKLIST", isReadOnly: false },
				controller: "extensionBlacklistDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/extension-blacklist.dlg.html",

				targetEvent: event
			})
				.then(function (modalSuccess) {
					vm.incomingExtensionBlacklist = modalSuccess.extensions;
					form.$setDirty();
				}, function () { });
		}

		function openOutgoingExtensionBlacklist(event, form) {
			$mdDialog.show({
				locals: { extensions: angular.copy(vm.outgoingExtensionBlacklist), title: "OUTBOUND_EXTENSION_BLACKLIST", isReadOnly: false },
				controller: "extensionBlacklistDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					vm.outgoingExtensionBlacklist = modalSuccess.extensions;
					form.$setDirty();
				}, function () { });
		}

		//// On changed
		function customHelpTextChanged() {
			preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpText: vm.companyInfo.customHelpLinkEnabled ? vm.companyInfo.customHelpText : '' });
		}

		function customHelpUrlChanged() {
			preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: vm.companyInfo.customHelpLinkEnabled ? vm.companyInfo.customHelpLink : '' });
		}

		function customHelpEnableChanged() {
			if (vm.companyInfo.customHelpLinkEnabled) {
				preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: vm.companyInfo.customHelpUrl, customHelpText: vm.companyInfo.customHelpLinkEnabled });
			} else {
				preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: '', customHelpText: '' });
			}
		}

		function customLoginPageHtmlEnabledChanged() {
			if (vm.companyInfo.showLoginAreaHTML)
				preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: vm.companyInfo.htmlDisplayBadge });
			else
				preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: "" });
		}

		function customLoginTextChanged() {
			preferencesStorage.setLocalParam("loginPreviewChanges", { customTitle: vm.companyInfo.customLoginText });
		}

		function setLocalStorageForPreview() {
			preferencesStorage.setLocalParam("loginPreviewChanges", {
				isDomain: true,
				customTitle: vm.companyInfo.customLoginText,
				customHelpUrl: vm.companyInfo.customHelpLinkEnabled ? vm.companyInfo.customHelpLink : '',
				customHelpText: vm.companyInfo.customHelpLinkEnabled ? vm.companyInfo.customHelpText : '',
				customHtmlBlock: vm.companyInfo.showLoginAreaHTML ? vm.companyInfo.htmlDisplayBadge : "",
				imageGuid: vm.logoChanged ? vm.loginPreviewGuid : undefined,
				logoAction: vm.logoAction
			});
		}

		function autoCleanGetFolderNameTranslation(folderName) {
			switch (folderName.toLowerCase()) {
				case "deleted items": return $translate.instant("DELETED_ITEMS");
				case "inbox": return $translate.instant("INBOX");
				case "junk e-mail": return $translate.instant("JUNK_EMAIL");
				case "sent items": return $translate.instant("SENT_ITEMS");
				default: return folderName;
			}
		}

		function autoCleanNewRule(ev, form) {
			var defaultFolder = "Deleted Items";
			for (var i = 0; i < vm.autoCleanFolders.length; i++) {
				if (vm.autoCleanFolders[i].name.toLowerCase() === "deleted items") {
					defaultFolder = vm.autoCleanFolders[i].name;
					break;
				}
			}
			$mdDialog
				.show({
					locals: {
						folderName: defaultFolder,
						folders: vm.autoCleanFolders,
						processGoal: 75,
						processThreshold: 100,
						thresholdDays: 30,
						type: 0,
						enabled: true,
						newItem: true
					},
					controller: "userEditAutoCleanController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
					targetEvent: ev
				})
				.then(onNewRuleOk);

			function onNewRuleOk(modalSuccess) {
				modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
				modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;
				modalSuccess.rule.translatedPath = autoCleanGetFolderNameTranslation(modalSuccess.rule.folderName);

				vm.autoCleanSettings.domainRules.push(modalSuccess.rule);
				form.$setDirty();
			}
		}

		function autoCleanEditRule(ev, rule, form) {
			$mdDialog
				.show({
					locals: {
						folderName: rule.folderName,
						folders: vm.autoCleanFolders,
						processGoal: rule.processGoalMb,
						processThreshold: rule.processThresholdMb,
						thresholdDays: rule.thresholdDays,
						type: rule.type,
						enabled: rule.enabled,
						newItem: false
					},
					controller: "userEditAutoCleanController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
					targetEvent: ev
				})
				.then(onEditRuleOk);

			function onEditRuleOk(modalSuccess) {
				if (modalSuccess.deleteItem) {
					autoCleanDeleteItem(rule, form);
					return;
				}
				modalSuccess.rule.id = rule.id;
				modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
				modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;
				modalSuccess.rule.translatedPath = autoCleanGetFolderNameTranslation(modalSuccess.rule.folderName);

				autoCleanDeleteItem(rule, form);
				vm.autoCleanSettings.domainRules.push(modalSuccess.rule);
				form.$setDirty();
			}
		}

		function autoCleanDeleteItem(rule, form) {
			var index = vm.autoCleanSettings.domainRules.indexOf(rule);
			if (index != -1)
				vm.autoCleanSettings.domainRules.splice(index, 1);
			form.$setDirty();
		}

		function autoCleanGetCleanBottomString(rule) {
			switch (rule.type) {
				case 0:
					var over = $filter("bytes")(rule.processThresholdBytes, 1);
					var reduce = $filter("bytes")(rule.processGoalBytes, 1);
					return $filter("translate")("AUTO_CLEAN_SUMMARY_SIZE", { over: over, reduce: reduce });
				case 1:
					return $filter("translate")("AUTO_CLEAN_SUMMARY_TIME_IN_FOLDER", { days: rule.thresholdDays });
				case 2:
					return $filter("translate")("AUTO_CLEAN_SUMMARY_MESSAGE_AGE", { days: rule.thresholdDays });
			}
		}

		function clearSearchTerm() {
			vm.countrySearch = '';
		}

		function deleteCountryFromList(index, form) {
			vm.blockedCountrySettings.countryList.splice(index, 1);
			vm.countryListChanged();
			form.$setDirty();
		}

		function countryListChanged(form) {
			if (!(vm.blockedCountrySettings.isGlobal && vm.blockedCountrySettings.type === 2)) {
				vm.blockedCountrySettings.countryList =
					vm.blockedCountrySettings.countryList.reduce((acc, val) => {
						if (acc.some(a => a.item2 === val.item2))
							errorHandling.report("ERROR_DUPLICATE_COUNTRIES_ARE_NOT_ALLOWED");
						else if (val.item2) {
							acc.push(val);
						}
						return acc;
					},
						[]);
				vm.blockedCountrySettings.countryList.push({ item1: false, item2: '' });
			} else {
				vm.countries = vm.domainSettings.systemBlockedCountries.map(function (key) {
					return { key: key, translation: $translate.instant('COUNTRY_CODE_' + key) };
				});

				if (!vm.blockedCountrySettings.countryList.some(p => p.item2 == '')) {
					vm.blockedCountrySettings.countryList.push({ item1: false, item2: '' });
				}

			}
		}

		function editMailingListHelpMessage(ev) {
			if (!vm.mailingListHelpMessage)
				return;
			$mdDialog.show({
				locals: {
					message: vm.mailingListHelpMessage,
					listId: 0
				},
				controller: "sharedEditSystemMessageController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/message.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (modalSuccess.reset) {
						var confirm = $mdDialog.confirmDeletion()
							.textContent($filter("translate")("CONFIRMATIONS_RESET_SYSTEM_MESSAGE"))
							.ok($filter("translate")("RESET"))
							.targetEvent(ev);
						$mdDialog.show(confirm)
							.then(function () {
								$http.post('~/api/v1/settings/domain/mailing-lists/0/system-messages/reset', JSON.stringify([vm.mailingListHelpMessage.name]))
									.then(reloadMailingList, errorHandling.report);
							}, function () { });

					} else {
						var messageParams = {
							name: vm.mailingListHelpMessage.name,
							subject: modalSuccess.subject,
							body: modalSuccess.body
						};
						$http.post('~/api/v1/settings/domain/mailing-lists/0/system-message', JSON.stringify(messageParams))
							.then(reloadMailingList, errorHandling.report);
					}

				});
			function reloadMailingList() {
				$http.get("~/api/v1/settings/domain/mailing-lists/0/system-messages")
					.then(onMailingListMessagesLoaded, errorHandling.report);
			}

		}
		function openReadonlyTextDialog(event, headerText, htmlContent) {
			$mdDialog.show({
				locals: {
					headerText: headerText,
					htmlContent: htmlContent,
					domainOverride: $filter("translate")("SYSTEM_POLICY"),
					context: "system",
					readOnly: true
				},
				controller: "simpleHtmlViewController",
				controllerAs: "ctrl",
				templateUrl: "app/shared/modals/simple-html-viewer.dlg.html",
				targetEvent: event
			})
				.then(function () {

				});
		}

		vm.openReadonlyBody = function (event) {
			openReadonlyTextDialog(event,
				$filter("translate")("BODY"),
				vm.domainSettings.externalSenderOverrideSettings.defaultBodyText);
		}
		vm.openReadonlySubject = function (event) {
			openReadonlyTextDialog(event,
				$filter("translate")("SUBJECT"),
				vm.domainSettings.externalSenderOverrideSettings.defaultSubjectText);
		}
		vm.authProviderFilter = function (item) {
			if (!item.providerId) return true; 
			return !vm.editedAuthProviders.deletedProviderIds.includes(item.providerId);
		}
		vm.editAuthProvider = function (event, item) {
			$mdDialog.show({
				locals: {
					existingProvider: item
				},
				controller: "directoryServiceProviderController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/general-settings/modals/directory-service-provider.html",
				targetEvent: event
			})
				.then(function (success) {
					vm.form.$setDirty();
					if (success.delete) {
						if (item && !item.providerId) {
							vm.authProviders = vm.authProviders.filter(x => x !== item);
							vm.editedAuthProviders.addedProviders = vm.editedAuthProviders.addedProviders.filter(x => x !== item);
						} else {
							vm.editedAuthProviders.deletedProviderIds.push(item.providerId);
						}
					} else {
						const editedProvider = {
							// Both
							providerId: item.providerId,
							providerType: success.providerType,
							providerName: success.providerName,
							serverName: success.serverName,
							serverPort: success.serverPort,
							encryption: success.encryption,
							// LDAP
							ldapBaseDn: success.ldapBaseDn,
							ldapUsername: success.ldapUsername,
							ldapPassword: success.ldapPassword,
							saveCredentials: success.saveCredentials,
							// AD
							fqdn: success.fqdn,
							organizationalUnit: success.organizationalUnit
						}
						const idx = vm.authProviders.indexOf(item);
						if (idx > -1) vm.authProviders.splice(idx, 1);
						vm.authProviders.push(editedProvider);
						vm.editedAuthProviders.updatedProviders.push(editedProvider);
					}
				});
		}
		vm.newAuthProvider = function (event) {
			$mdDialog.show({
				locals: {
					existingProvider: undefined
				},
				controller: "directoryServiceProviderController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/general-settings/modals/directory-service-provider.html",
				targetEvent: event
			})
				.then(function (success) {
					vm.form.$setDirty();
					const newProvider = {
							// Both
							providerType: success.providerType,
							providerName: success.providerName,
							serverName: success.serverName,
							serverPort: success.serverPort,
							encryption: success.encryption,
							// LDAP
							ldapBaseDn: success.ldapBaseDn,
							ldapUsername: success.ldapUsername,
							ldapPassword: success.ldapPassword,
							saveCredentials: success.saveCredentials,
							// AD
							fqdn: success.fqdn,
							organizationalUnit: success.organizationalUnit
					}
					vm.authProviders.push(newProvider);
					vm.editedAuthProviders.addedProviders.push(newProvider);
				});
		}
		vm.authProviderLabel = function (type, name) {
			let prefix = ""
			switch (type) {
				case 0:
					prefix = $filter('translate')("SMARTERMAIL");
					break;
				case 1:
					prefix = $filter('translate')("ACTIVE_DIRECTORY");
					break;
				case 3:
					prefix = $filter('translate')("LDAP");
					break;
				default:
					prefix = $filter('translate')("UNKNOWN");
					break;
			}

			return name ? `${prefix}: ${name}` : prefix;
		}

	}

})();