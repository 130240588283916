(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("tablePageService", tablePageService);

	function tablePageService($state, $transitions)
	{
		var vm = this;
		var _tablePageNumber = 1;
		var _setTablePage = false;

		vm.parentPage;
		vm.currentPage;
		vm.reset = reset;

		vm.tablePageNumber = {
			get: function () { return _setTablePage && !vm.parentPage ? _tablePageNumber : 1; },
			set: function (value) { _tablePageNumber = vm.parentPage ? _tablePageNumber : value; }
		};

		activate();

		function activate() {
			$transitions.onStart({}, onStateChangeStart);
			vm.currentPage = $state.current.name;
		}

		function onStateChangeStart(event) {
			var transitionTo = event._targetState._definition.name;
			var transitionToParent = event._targetState._definition.parent.name;
			if (vm.parentPage) {
				if (transitionTo === vm.parentPage) {
					_setTablePage = true;
					vm.currentPage = transitionTo;
					vm.parentPage = undefined;
				} else
					reset(transitionTo);
			} else {
				if (transitionToParent === vm.currentPage) {
					vm.parentPage = vm.currentPage;
					vm.currentPage = transitionTo;
				} else
					reset(transitionTo);
			}
		}

		function reset(page) {
			vm.parentPage = undefined;
			vm.currentPage = page;
			vm.tablePageNumber = 1;
			_setTablePage = false;
		}
	}
})();