(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("categoryFilterMenuItems", ["preferencesStorage", "apiCategories", "$rootScope", "$mdDialog", "$translate", categoryFilterMenuItems]);

    function categoryFilterMenuItems(preferencesStorage, apiCategories, $rootScope, $mdDialog, $translate) {
        return {
            //require: 'ngModel',
            replace: true,
            restrict: "E",
            scope: {
                stSection: "@",
                onCategoryFilterChanged: "&",
                showManageCategories: '<',
                onManageCategories: '&',
                showEmptyPlaceholder: '<',
                dividerBefore: '<'
            },
            link: link,
            template: `
                <div>
                <md-divider ng-if="dividerBefore && categoriesLoaded"></md-divider>
                <md-menu-item ng-if="showEmptyPlaceholder && !categoriesLoaded">
                    <md-button md-prevent-menu-close disabled>
                        <i class="toolsicon toolsicon-check" ng-style="{'visibility': 'hidden'}"></i> 
                        <span class="wordwrap">{{::"NO_FILTERS_AVAILABLE"| translate}}</span>
                    </md-button>
                </md-menu-item>
                <md-menu-item ng-if="categoriesLoaded">
                    <md-button md-prevent-menu-close ng-click="clearCategoryFilters(e)">
                        <i class="toolsicon toolsicon-check" ng-style="{'visibility': allSelected ? '' : 'hidden'}"></i> 
                        <span class="wordwrap">{{::"ALL_CATEGORIES_FILTER"| translate}}</span>
                    </md-button>
                </md-menu-item>
                <md-menu-item ng-if="categoriesLoaded" ng-repeat="cat in categoryFilter.categoryData">
                    <md-button md-prevent-menu-close ng-click="onFilterToggled($index)" class="category-filter-menu-item">
                        <i class="toolsicon toolsicon-check" ng-style="{'visibility': !allSelected && cat.selected ? '' : 'hidden'}"></i> 
                        <span style="overflow: hidden; text-overflow: ellipsis">{{ cat.translatedName }}</span>
                        <div ng-if="!cat.noCategory" category-color-preview="{{cat.colorIndex}}"></div>
                    </md-button>
                </md-menu-item>
                <md-divider ng-if="categoriesLoaded && showManageCategories"></md-divider>
                <md-menu-item ng-if="categoriesLoaded && showManageCategories">
                    <md-button ng-click="doManageCategories($event)" class="category-filter-menu-item">
                        <i class="toolsicon toolsicon-check" style="visibility:hidden"></i> 
                        <span style="overflow: hidden; text-overflow: ellipsis">{{::'MANAGE_CATEGORIES' | translate }}</span>
                    </md-button>
               </md-menu-item>
               </div>`
        };

        function link(scope, element, attrs, ngModel) {
            scope.categoriesLoaded = false;
            scope.allSelected = true;
            scope.onFilterToggled = onFilterToggled;
            scope.clearCategoryFilters = clearCategoryFilters;
            scope.doManageCategories = doManageCategories;

            activate();

            async function activate() {
                await apiCategories.init();
                loadCategories();
                scope.$on("categoriesUpdated", onCategoriesUpdated);
                scope.$on("doClearCategoryFilters", scope.clearCategoryFilters);
            }

            function loadCategories() {
                scope.categoryFilter = apiCategories.getCategoryFilter(scope.stSection);
                scope.categoriesLoaded = scope.categoryFilter.categoryData !== undefined && scope.categoryFilter.categoryData.length > 0;
                scope.allSelected = scope.categoryFilter.allSelected();
                scope.categoryFilter = apiCategories.getCategoryFilter(scope.stSection);
            }

            const debounceLoadCategories = _.debounce(loadCategories, 200);
            function onCategoriesUpdated() {
                debounceLoadCategories();
            }

            function onFilterToggled(index, ev) {
                // when no category filters are set; only this will be enabled
                if (scope.allSelected) {
                    scope.categoryFilter.categoryData.forEach((cat, i) => cat.selected = index === i);
                } else {
                    scope.categoryFilter.categoryData[index].selected = !scope.categoryFilter.categoryData[index].selected;
                }
                if (!scope.categoryFilter.categoryData.some(cat => cat.selected))
                    scope.clearCategoryFilters();
                else
                    storeAndNotify();
            };

            function clearCategoryFilters(ev) {
                if (!scope.categoryFilter.allSelected()) {
                    scope.categoryFilter.categoryData.forEach(cat => cat.selected = true);
                    storeAndNotify();
                }
            }

            function doManageCategories(event) {
                if (typeof scope.onManageCategories === 'function')
                    scope.onManageCategories(event);
            }

            function storeAndNotify() {
                scope.allSelected = scope.categoryFilter.allSelected();
                preferencesStorage.setSessionSortingFilteringParam(scope.stSection, "categoryFilters", scope.allSelected ? undefined : scope.categoryFilter.categoryData);
                if (typeof scope.onCategoryFilterChanged === "function")
                    scope.onCategoryFilterChanged({ categoryFilter: scope.categoryFilter.categoryData });
            }
        }

    }

})();