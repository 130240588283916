(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("archiveConfirmDisableDialogController", archiveConfirmDisableDialogController);

    function archiveConfirmDisableDialogController($mdDialog, locals, $filter) {
        var vm = this;
        vm.archivePath = locals.archivePath;
        vm.archiveSize = $filter("bytes")(locals.archiveSize);
        vm.passwordConfirm = false;
        vm.adminPassword = "";
        vm.deleteData = false;
        vm.onDisable = function () {
            $mdDialog.hide({ deleteData: vm.deleteData });
		}

		vm.cancel = function () {
			$mdDialog.cancel();
		}
    };
})();