(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("noteActions", noteActions);

    function noteActions($rootScope, $http, $mdDialog, $translate, $filter, errorHandling, preferencesStorage, userDataService) {
        var vm = this;

        // Functions
        vm.showCreateFolderModal = showCreateFolderModal;
        vm.showRenameFolderModal = showRenameFolderModal;
        vm.showDeleteFolderModal = showDeleteFolderModal;
        vm.showShareFolderModal = showShareFolderModal;
        vm.showSharedFolderPropertiesModal = showSharedFolderPropertiesModal;

        ///////////////////////////

        async function showCreateFolderModal(event) {
            try {
                await $mdDialog.show({
                    locals: { },
                    controller: "createNoteFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/notes/modals/create-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore error - they closed the modal
            }
        }

        async function showRenameFolderModal(event, source) {
            if (!source)
                return;

            try {
                let success = undefined;

                if (source.isSharedItem) {
                    success = await $mdDialog.show({
                        controller: "editSharedFolderDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                        targetEvent: event,
                        locals: { folderId: source.folderId, webcalLink: null, folder: null }
                    });
                }
                else {
                    success = await $mdDialog.show({
                        locals: { source: source },
                        controller: "renameNoteFolderController",
                        controllerAs: "ctrl",
                        templateUrl: "app/notes/modals/rename-folder.dlg.html",
                        targetEvent: event,
                        clickOutsideToClose: false
                    });
                }

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("notes", source);
                }

                return true;
            }
            catch (err) {
                // exception just means modal was closed
            }
        }

        async function showDeleteFolderModal(event, source) {
            if (!source || source.isPrimary)
                return;

            const owner = source.ownerUsername ? source.ownerUsername : userDataService.user.domain;
            const name = source.displayName ? $filter("folderTranslate")(source.displayName, owner) : source.name;
            const confirm = $mdDialog.confirmDeletion()
                .htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: name }))
                .targetEvent(event);

            try {
                await $mdDialog.show(confirm);
            } catch (err) {
                return;
            }

            try {
                var folder = {
                    isPrimary: source.isPrimary,
                    folder: name,
                    uid: source.id || source.itemID
                };
                await $http.post("~/api/v1/notes/sources/delete", folder)
                preferencesStorage.removeSourceVisibility("notes", source);
                return true;
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function showShareFolderModal(event, source) {
            try {
                await $mdDialog.show({
                    locals: { source: source },
                    controller: "shareNoteFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/notes/modals/share-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }

        async function showSharedFolderPropertiesModal(event, source) {
            try {
                let success = await $mdDialog.show({
                    controller: "editSharedFolderDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                    targetEvent: event,
                    locals: { folderId: source.folderId, webcalLink: null, folder: null }
                });

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("notes", source);
                }
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }
    }
})(); 