(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("impersonationService", impersonationService);

	function impersonationService($http, $timeout, authStorage, coreDataManageDomain, errorHandling, $sessionStorage) {
		var vm = this;
		vm.impersonateUser = impersonateUser;
		vm.impersonateUserReplaceCurrent = impersonateUserReplaceCurrent;

		function impersonateUser(emailAddress, newWindow, useThisWindow) {
			// Need to remove any previously saved impersonated tokens.
			// We no longer need them since we're initiating a new impersonation request.
			authStorage.deleteImpersonateToken();
			var baseUrl = window.location.href.substring(0, window.location.href.indexOf("#/"));
			if (coreDataManageDomain.impersonatingWindow != undefined) {
				coreDataManageDomain.impersonatingWindow.close();
				coreDataManageDomain.impersonatingWindow = undefined;
				if (newWindow) {
					newWindow.close();
					newWindow = undefined;
				}
			}

			if (!newWindow) {
				coreDataManageDomain.impersonatingWindow = window.open("", "ImpersonatedWindow");
				if (coreDataManageDomain.impersonatingWindow) {
					coreDataManageDomain.impersonatingWindow.sessionStorage.setItem("username", "");
					coreDataManageDomain.impersonatingWindow.sessionStorage.setItem("rememberMe", "false");
				}
			} else {
				coreDataManageDomain.impersonatingWindow = newWindow;
			}

			var clientId = authStorage.getClientId();

			$http
				.post("~/api/v1/settings/domain/impersonate-user", JSON.stringify({ email: emailAddress, clientId: clientId }))
				.then(onSuccess, onFailure);

			function onSuccess() {
				$timeout(function () {
					coreDataManageDomain.impersonatingWindow.location = baseUrl + "#/settings/user/account-settings";
				}, 1000);
			}

			function onFailure(failure) {
				coreDataManageDomain.impersonatingWindow.close();
				errorHandling.report(failure);
			}
		}

		function impersonateUserReplaceCurrent(emailAddress) {
			var clientId = authStorage.getClientId();

			$http
				.post("~/api/v1/settings/domain/impersonate-user", JSON.stringify({ email: emailAddress, clientId: clientId }))
				.then(onSuccess, errorHandling.report);

			function onSuccess() {
				delete $sessionStorage.categoryDataCache;
				delete $sessionStorage.sortingFiltering;
				var baseUrl = window.location.href.substring(0, window.location.href.indexOf("#/"));
				window.open(baseUrl + "#/settings/user/account-settings", "ImpersonatedWindow", "_self");
				location.reload();
			}
		}
	}
})();
