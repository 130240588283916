(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("calendarRecurrenceDescriptionService", calendarRecurrenceDescriptionService);

    function calendarRecurrenceDescriptionService(userTimeService, $translate, $filter, localeInfoService) {
        var vm = this;
        const recurrenceTypes = {
            ONCE: 0,
            DAILY: 4,
            WEEKLY: 5,
            MONTHLY: 6,
            YEARLY: 7
        };
        const monthFrequencyPeriods = [
            { id: 0, name: $translate.instant("CALENDAR_FIRST") },
            { id: 1, name: $translate.instant("CALENDAR_SECOND") },
            { id: 2, name: $translate.instant("CALENDAR_THIRD") },
            { id: 3, name: $translate.instant("CALENDAR_FOURTH") },
            { id: 4, name: $translate.instant("CALENDAR_LAST") }
        ];
        const daysOfWeek = [
            { id: 0, name: $translate.instant("SUNDAY") },
            { id: 1, name: $translate.instant("MONDAY") },
            { id: 2, name: $translate.instant("TUESDAY") },
            { id: 3, name: $translate.instant("WEDNESDAY") },
            { id: 4, name: $translate.instant("THURSDAY") },
            { id: 5, name: $translate.instant("FRIDAY") },
            { id: 6, name: $translate.instant("SATURDAY") },
            { id: 7, name: $translate.instant("DAY") },
            { id: 8, name: $translate.instant("CALENDAR_RECUR_WEEKDAY") },
            { id: 9, name: $translate.instant("CALENDAR_RECUR_WEEKEND_DAY") }
        ];
        const monthsById = [
            { id: 0, name: $translate.instant("MONTH_NAMES_JANUARY") },
            { id: 1, name: $translate.instant("MONTH_NAMES_FEBRUARY") },
            { id: 2, name: $translate.instant("MONTH_NAMES_MARCH") },
            { id: 3, name: $translate.instant("MONTH_NAMES_APRIL") },
            { id: 4, name: $translate.instant("MONTH_NAMES_MAY") },
            { id: 5, name: $translate.instant("MONTH_NAMES_JUNE") },
            { id: 6, name: $translate.instant("MONTH_NAMES_JULY") },
            { id: 7, name: $translate.instant("MONTH_NAMES_AUGUST") },
            { id: 8, name: $translate.instant("MONTH_NAMES_SEPTEMBER") },
            { id: 9, name: $translate.instant("MONTH_NAMES_OCTOBER") },
            { id: 10, name: $translate.instant("MONTH_NAMES_NOVEMBER") },
            { id: 11, name: $translate.instant("MONTH_NAMES_DECEMBER") },
        ];

        // Functions
        vm.recurrenceDescription = recurrenceDescription;
        vm.recurrenceDescriptionParts = recurrenceDescriptionParts;

        /////////////////////

        /**
        * For the rules to this description generator, see SML-8094
        * 
        * @param {any} opts - The object that contains all the parts of the event
        * @param {any} splitby - The string that each of the parts will be split by. If not specified, the default is ', '
        * @returns {string} The formatted description of the appointment
        */
        function recurrenceDescription(opts, splitby) {
            return recurrenceDescriptionParts(opts).join(splitby || ', ');
        }

        /**
        * For the rules to this description generator, see SML-8094
        * 
        * @param {any} opts - The object that contains all the parts of the event
        * @param {any} splitby - The string that each of the parts will be split by. If not specified, the default is ', '
        * @returns {string} The formatted description of the appointment
        */
        function recurrenceDescriptionParts(opts) {
            opts.startMeetingTimeUser = convertMeetingTime(opts.start, opts.isAllDay);
            opts.endMeetingTimeUser = convertMeetingTime(opts.end, opts.isAllDay);
            return [
                apptDescriptionTime(opts),
                apptDescriptionRecurrence(opts),
                apptDescriptionDates(opts)
            ].filter(x => x);
        }

        function convertMeetingTime(time, isAllDay) {
            if (!time) return "";
            if (!isAllDay)
                return userTimeService.convertLocalToUserTimeMoment(time.dt).toDate();
            else {
                var mjs = moment(time.dt.substr(0, 19));
                var momentDate = moment.tz({
                    year: mjs.year(),
                    month: mjs.month(),
                    date: mjs.date(),
                    hour: mjs.hour(),
                    minute: mjs.minute(),
                    second: 0
                }, time.tz);
                var momentDate2 = moment({ year: momentDate.year(), month: momentDate.month(), date: momentDate.date() }).toDate();
                return momentDate2;
            }
        }

        function apptDescriptionTime(opts) {
            let startShortDate = $filter("date")(opts.startMeetingTimeUser, "shortDate");
            let startShortTime = $filter("date")(opts.startMeetingTimeUser, "shortTime");
            let endShortTime = $filter("date")(opts.endMeetingTimeUser, "shortTime");
            let startShort = $filter("date")(opts.startMeetingTimeUser, "short");
            let endShort = $filter("date")(opts.endMeetingTimeUser, "short");
            let isRecurringRule = opts.isRecurring && !opts.recurrenceID;
            let numDays = daysDifference(opts.startMeetingTimeUser, opts.endMeetingTimeUser);
            let isMultiDay = (opts.isAllDay && numDays > 1) || (!opts.isAllDay && numDays >= 1);

            // All day, non-recurring, multi-day appointments need to subtract one day from their displayed end date
            let endMeetingTimeUserMinus1 = new Date(opts.endMeetingTimeUser);
            endMeetingTimeUserMinus1.setDate(endMeetingTimeUserMinus1.getDate() - 1);
            let endShortDateMinus1 = $filter("date")(endMeetingTimeUserMinus1, "shortDate");

            if (opts.isAllDay) {
                if (isMultiDay)
                    return isRecurringRule
                        ? $translate.instant("CALENDAR_DESC_TIME_ALLDAY_MULTIPLE_DAYS_RECURRING", { x: numDays })
                        : $translate.instant("CALENDAR_DESC_TIME_ALLDAY_MULTIPLE_DAYS", { start: startShortDate, end: endShortDateMinus1 });
                return isRecurringRule
                    ? $translate.instant("CALENDAR_DESC_TIME_ALLDAY_RECURRING")
                    : $translate.instant("CALENDAR_DESC_TIME_ALLDAY", { start: startShortDate });
            }

            // Not all day
            if (isMultiDay)
                return isRecurringRule
                    ? $translate.instant(
                        numDays == 1 ? "CALENDAR_DESC_TIME_ENDS_1_DAY_LATER" : "CALENDAR_DESC_TIME_ENDS_X_DAYS_LATER",
                        {
                            start: startShortTime,
                            end: endShortTime,
                            count: numDays,
                        },
                    )
                    : $translate.instant("CALENDAR_DESC_TIME_START_TO_END", {
                        start: startShort,
                        end: endShort,
                    });

            return isRecurringRule
                ? $translate.instant("CALENDAR_DESC_TIME_START_TO_END", { start: startShortTime, end: endShortTime })
                : $translate.instant("CALENDAR_DESC_TIME_START_TO_END", { start: startShort, end: endShortTime })
        }

        function daysDifference(date1, date2) {
            // Clone the dates and reset their time components
            let d1 = new Date(date1);
            d1.setHours(0, 0, 0, 0);
            let d2 = new Date(date2);
            d2.setHours(0, 0, 0, 0);

            // Find the milliseconds difference
            let differenceInMs = d2 - d1;

            // Convert milliseconds difference to days
            let differenceInDays = differenceInMs / (1000 * 3600 * 24);

            return Math.abs(differenceInDays); // Use Math.abs() to return absolute value, or remove it if negative values are relevant for your use case
        }

        function apptDescriptionDates(opts) {
            let startShortDate = $filter("date")(opts.startMeetingTimeUser, "shortDate");
            let isRecurringRule = opts.isRecurring && !opts.recurrenceID;

            if (!isRecurringRule)
                return '';

            if (opts.untilDateEnabled && opts.untilDate)
                return $translate.instant("CALENDAR_DESC_REPEAT_WITH_END", {
                    start: startShortDate,
                    end: $filter("date")(opts.untilDate, "shortDate")
                });

            if (opts.untilCountEnabled)
                return $translate.instant(
                    opts.untilCount == 1 ? "CALENDAR_DESC_REPEAT_1_TIMES" : "CALENDAR_DESC_REPEAT_X_TIMES",
                    {
                        start: startShortDate,
                        count: opts.untilCount,
                    },
                );

            return $translate.instant("CALENDAR_DESC_REPEAT_NO_END", { start: startShortDate });
        }

        function apptDescriptionRecurrence(opts) {
            let isRecurringRule = opts.isRecurring && !opts.recurrenceID;
            let interval;

            if (!isRecurringRule) return "";

            switch (opts.recurrenceType) {
                case recurrenceTypes.DAILY:
                    interval = +opts.dailyInterval;
                    return opts.isWeekdaysOnly
                        ? interval === 1
                            ? $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_1_WEEKDAY")
                            : interval === 2
                                ? $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_2_WEEKDAYS")
                                : $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_X_WEEKDAYS", { count: interval })
                        : interval === 1
                            ? $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_1_DAY")
                            : interval === 2
                                ? $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_2_DAYS")
                                : $translate.instant("CALENDAR_DESC_RECURRENCE_DAILY_EVERY_X_DAYS", { count: interval });

                case recurrenceTypes.WEEKLY:
                    interval = +opts.weeklyInterval;
                    return interval === 1
                        ? $translate.instant("CALENDAR_DESC_RECURRENCE_WEEKLY", {days: weeklySelectedDaysString(opts)})
                        : interval === 2
                            ? $translate.instant(
                                "CALENDAR_DESC_RECURRENCE_WEEKLY_EVERY_2_WEEKS",
                                { days: weeklySelectedDaysString(opts, true) },
                            )
                            : $translate.instant(
                                "CALENDAR_DESC_RECURRENCE_WEEKLY_EVERY_X_WEEKS",
                                {
                                    days: weeklySelectedDaysString(opts, true),
                                    count: interval,
                                },
                            );

                case recurrenceTypes.MONTHLY:
                    interval = +opts.monthlyInterval;
                    
                    return opts.monthlySpecificDay
                        ? $translate.instant(
                            interval === 1
                                ? "CALENDAR_DESC_RECURRENCE_MONTHLY_SPECIFIC_DAY_1"
                                : interval === 2
                                    ? "CALENDAR_DESC_RECURRENCE_MONTHLY_SPECIFIC_DAY_2"
                                    : "CALENDAR_DESC_RECURRENCE_MONTHLY_SPECIFIC_DAY_X",
                            {
                                day: ordinalNumber(opts.monthDay), 
                                count: interval
                            }
                        )
                        : $translate.instant(
                            interval === 1
                                ? "CALENDAR_DESC_RECURRENCE_MONTHLY_ORDINAL_DAY_1"
                                : interval === 2
                                    ? "CALENDAR_DESC_RECURRENCE_MONTHLY_ORDINAL_DAY_2"
                                    : "CALENDAR_DESC_RECURRENCE_MONTHLY_ORDINAL_DAY_X",
                            {
                                ordinal: getMonthlyPeriodText(opts.monthlyPeriodIndex).toLowerCase(),
                                day: ordinalNumber(opts.monthlyPeriodDayIndex),
                                count: interval,
                            }
                        );

                case recurrenceTypes.YEARLY:
                    interval = +opts.yearlyInterval;
                    return opts.yearlySpecificDate
                        ? $translate.instant(
                            interval === 1
                                ? "CALENDAR_DESC_RECURRENCE_YEARLY_SPECIFIC_DAY_1"
                                : interval === 2
                                    ? "CALENDAR_DESC_RECURRENCE_YEARLY_SPECIFIC_DAY_2"
                                    : "CALENDAR_DESC_RECURRENCE_YEARLY_SPECIFIC_DAY_X",
                            {
                                day: opts.yearlyDay,
                                month: monthsById[opts.yearlyMonthIndex].name,
                                count: interval,
                            },
                        )
                        : $translate.instant(
                            interval === 1
                                ? "CALENDAR_DESC_RECURRENCE_YEARLY_ORDINAL_DAY_1"
                                : interval === 2
                                    ? "CALENDAR_DESC_RECURRENCE_YEARLY_ORDINAL_DAY_2"
                                    : "CALENDAR_DESC_RECURRENCE_YEARLY_ORDINAL_DAY_X",
                            {
                                ordinal: getMonthlyPeriodText(opts.yearlyPeriodIndex).toLowerCase(),
                                day: getMonthlyPeriodDayText(opts.yearlyPeriodDayIndex),
                                month: monthsById[opts.yearlyMonthIndex].name,
                                count: interval,
                            });

                default:
                    return "";
            }
        }

        function ordinalNumber(n){
            try {
                const ordinalRules = new Intl.PluralRules(localeInfoService.language || navigator.language, {type: "ordinal"});
                if (!ordinalRules)
                    return n;
                return ordinalRules.select(n) || n;
            } catch (err){
                return n;
            }
        }
        
        function daysBetweenDateStrings(dateStr1, dateStr2) {
            // Parse the date strings into Date objects
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);

            // One day in milliseconds
            const oneDay = 1000 * 60 * 60 * 24;

            // Convert both dates to milliseconds
            const date1Ms = date1.getTime();
            const date2Ms = date2.getTime();

            // Calculate the difference in milliseconds
            const differenceMs = Math.abs(date1Ms - date2Ms);

            // Convert back to days and return
            return Math.round(differenceMs / oneDay);
        }

        function getMonthlyPeriodText(monthlyPeriodIndex) {
            var period = monthFrequencyPeriods.find(p => p.id === monthlyPeriodIndex)
            return period ? period.name : "";
        }

        function getMonthlyPeriodDayText(monthlyPeriodDayIndex) {
            var periodDay = daysOfWeek.find(p => p.id === monthlyPeriodDayIndex)
            return periodDay ? periodDay.name : "";
        }

        function weeklySelectedDaysString(opts, usePluralWeekdays) {
            var weeklyDaysStr = "";
            var selectedDays = [];
            for (var i = 0; i < opts.weeklyDays.length; i++) {
                if (opts.weeklyDays[i])
                    selectedDays.push(daysOfWeek[i].name);
            }

            if (selectedDays.length === 5 &&
                selectedDays[0] == 'Monday' &&
                selectedDays[1] == 'Tuesday' &&
                selectedDays[2] == 'Wednesday' &&
                selectedDays[3] == 'Thursday' &&
                selectedDays[4] == 'Friday'
            ) {
                weeklyDaysStr = $filter("translate")(usePluralWeekdays ? "CALENDAR_RECUR_WEEKDAYS" : "CALENDAR_RECUR_WEEKDAY").toLowerCase();
            }
            else if (selectedDays.length === 1) {
                weeklyDaysStr = selectedDays[0];
            } else if (selectedDays.length === 2) {
                weeklyDaysStr = selectedDays[0] + " " + $filter("translate")("AND").toLowerCase() + " " + selectedDays[1];
            } else if (selectedDays.length > 2) {
                for (i = 0; i < selectedDays.length - 1; ++i) {
                    weeklyDaysStr += selectedDays[i] + ", ";
                }
                weeklyDaysStr += $filter("translate")("AND").toLowerCase() + " " + selectedDays[selectedDays.length - 1];
            }
            return weeklyDaysStr;
        }
    }
})();