(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataTasks", coreDataTasks);

    function coreDataTasks($rootScope, $http, $q, $filter, userDataService, coreDataSettings, apiCategories, preferencesStorage, apiTaskSources) {
        var isInitialized = false;
        var isSourcesLoaded = false;
        var isTasksLoaded = false;
        var isTaskShared = [];
        var sources = [];
        var tasks = [];
        var initDefer;
        var getTasksDefer = {};
        var previousTreeController;
        var sourcesDefer;
        var _sourcesTree = {
            data: [], // Used by tree directive
            map: [], // Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        };


        // public variables
        var vm = this;
        vm.reloadOnEnter = false;
        vm.listDataCache = {};
        vm.ignoreTaskModified = { requested: moment(), ignored: moment() };
        vm.ignoreTaskRemoved = { requested: moment(), ignored: moment() };
        vm.parameters = {
            get sortField() {
                var value = preferencesStorage.getSessionSortingFilteringParam("tasks", "sortField");
                if (value === undefined) {
                    value = "due";
                    preferencesStorage.setSessionSortingFilteringParam("tasks", "sortField", value);
                }
                return value;
            },
            set sortField(value) {
                preferencesStorage.setSessionSortingFilteringParam("tasks", "sortField", value);
            },

            get isDescending() {
                var value = preferencesStorage.getSessionSortingFilteringParam("tasks", "isDescending");
                if (value === undefined) {
                    value = true;
                    preferencesStorage.setSessionSortingFilteringParam("tasks", "isDescending", true);
                }
                return value;
            },
            set isDescending(value) {
                preferencesStorage.setSessionSortingFilteringParam("tasks", "isDescending", value);
            },

            get sortReverse() {
                var value = preferencesStorage.getSessionSortingFilteringParam("tasks", "sortReverse");
                if (value === undefined) {
                    value = false;
                    preferencesStorage.setSessionSortingFilteringParam("tasks", "sortReverse", false);
                }
                return value;
            },
            set sortReverse(value) {
                preferencesStorage.setSessionSortingFilteringParam("tasks", "sortReverse", value);
            },

            get categoryFilters() {
	            const catFilter = apiCategories.getCategoryFilter("tasks");
	            return !catFilter ? [] : catFilter.categoryData;
            },

            get currentView() {
                var value = preferencesStorage.getSortingFilteringParam("tasks", "currentView");
                if (value === undefined) {
                    value = "CARD_VIEW";
                    preferencesStorage.setSortingFilteringParam("tasks", "currentView", value);
                }
                return value;
            },
            set currentView(value) {
                preferencesStorage.setSortingFilteringParam("tasks", "currentView", value);
            },
            get hasCategoryFilter() {
	            const catFilter = apiCategories.getCategoryFilter("tasks");
	            return !catFilter || !catFilter.allSelected();
            },

            get filterType() {
                return preferencesStorage.getSessionSortingFilteringParam("tasks", "filterType");
            },
            set filterType(value) {
                preferencesStorage.setSessionSortingFilteringParam("tasks", "filterType", value);
            }
        };
        vm.parameters.searchText = "";

        // Functions
        vm.init = init;
        vm.reset = reset;
        vm.areTasksLoaded = function () { return isTasksLoaded; };
        vm.categories = function () { return apiCategories.getMasterCategories(); };
        vm.ensureSourcesLoadedPromise = ensureSourcesLoadedPromise;
        vm.ensureTasksLoadedPromise = ensureTasksLoadedPromise;
        vm.getCardById = getCardById;
        vm.getFilteredTasks = getFilteredTasks;
        vm.getSources = function () { return sources; };
        vm.getSourcesTree = function () { return _sourcesTree; };
        vm.getTask = getTask;
        vm.getTaskFromServer = getTaskFromServer;
        vm.getTasks = function () { return tasks; };
        vm.listDataProvider = listDataProvider;
        vm.loadSources = loadSources;
        vm.loadSourcesTree = loadSourcesTree;
        vm.modifyTasksSignal = modifyTasksSignal;
        vm.removeTasks = removeTasks;
        vm.removeTasksSignal = removeTasksSignal;
        vm.resetSources = function () { isSourcesLoaded = false; apiTaskSources.invalidateSources(); };
        vm.resetSourcesTree = resetSourcesTree;
        vm.resetTasks = function () { isTasksLoaded = false; return loadTasks(); };
        vm.saveTasks = saveTasks;
        vm.updateSourceVisibility = updateSourceVisibility;

        vm.convertLocalToTargetTz = convertLocalToTargetTz;

        // Setup
        activate();

        ///////////////////////////////

        function activate() { }

        function init() {
            // For tasks, init doesn't really do anything.  We just keep this stuff below to keep the same pattern as the other areas
            // technically it would just be replaced with "isInitialized = true; return $q.when();"

            if (initDefer) return initDefer.promise;
            if (isInitialized)
                return $q.when();
            else {
                initDefer = $q.defer();
                initDefer.promise.finally(function () { initDefer = undefined; });
                isInitialized = true;
                initDefer.resolve();
                return initDefer.promise;
            }
        }

        function reset() {
            isInitialized = false;
            isSourcesLoaded = false;
            isTasksLoaded = false;
            sources = [];
            tasks = [];
            vm.listDataCache = {};
        }

        function ensureSourcesLoadedPromise() { return loadSources(); }
        function ensureTasksLoadedPromise() { return loadTasks(); }

        function listDataProvider(obj) {
            //TODO: Manage loading tasks dynamically instead of pulling from a full set of data
            var subset = [];
            var j = 0;
            var t = vm.getFilteredTasks();
            for (var i = obj.start; i < (obj.start + obj.take); i++) {
                //Note: splicing will make a copy, I want a reference.
                if (t[i] == undefined) {
                    break;
                }
                subset[j++] = t[i];
            }
            obj.defer.resolve(subset);
            return obj.defer.promise;
        }

        function filterByCategoryCallback(task) {

            const categoryFilters = vm.parameters.categoryFilters;
            if (task.categories && task.categories.some(c => c.selected)) {
	            return task.categories.some(cat => cat.selected &&
		            categoryFilters.some(catFilter => catFilter.selected && catFilter.name === cat.name));

            } else {
	            return categoryFilters.some(cat => cat.noCategory && cat.selected);
            }
        }

        function applyCategoryFiltering() {
	        if (!vm.parameters.hasCategoryFilter) return tasks;
            return $.grep(tasks, function (task) { return filterByCategoryCallback(task); });
        }

        function getFilteredTasks() {
            var filteredTasks = applyCategoryFiltering();
            filteredTasks = $filter("filter")(filteredTasks, (task) => {
                var search = vm.parameters.searchText || "";
                search = search.toLowerCase();
                return (!task.subject && search === "" || (task.subject && task.subject.toLowerCase().indexOf(search) > -1) ||
                    (task.description && task.description.toLowerCase().indexOf(search) > -1));
            });
            filteredTasks = $filter("filter")(filteredTasks, hasVisibleStatus);
            filteredTasks = $filter("orderBy")(filteredTasks, vm.parameters.sortField, vm.parameters.isDescending);
            return filteredTasks;
        }

        function loadSources() {
            var defer = $q.defer();
            if (isSourcesLoaded) {
                defer.resolve();
            } else {
                getSources()
                    .then(function () {
                        isTaskShared = [];
                        coreDataSettings.resetResources();
                        $q.all([coreDataSettings.settingsData.resources,
                        coreDataSettings.settingsData.mappedResources])
                            .then(function (success) {
                                var resourceData = success[0];
                                for (var i = 0; i < resourceData.length; i++) {
                                    if (resourceData[i].shareType === 4) {
                                        isTaskShared[resourceData[i].resourceName] = resourceData[i].permissions > 0;
                                    }
                                }
                                defer.resolve();
                            }, function (failure) {
                                defer.resolve();
                            });

                        isSourcesLoaded = true;
                        isTasksLoaded = false;
                    },
                        function (failure) {
                            isSourcesLoaded = false;
                            isTasksLoaded = false;
                            defer.reject(failure);
                        });
            }
            return defer.promise;
        }

        function getSources() {
            return $http.get("~/api/v1/tasks/sources/").then(onGetSourcesSuccess, onGetSourcesFailure);
        }

        function onGetSourcesSuccess(result) {
            sources = result.data.sources;

            sources.forEach(src => {
                if (src.isSharedItem && src.name.toUpperCase().endsWith("MY TASKS") || src.name.endsWith("MY_TASKS"))
                    src.name = src.owner + " - " + $filter("translate")("MY_TASKS");
            });

            preferencesStorage.cleanSourceVisibilities("tasks", sources);
            sources.forEach(src => src.isVisible = preferencesStorage.getSourceVisibility("tasks", src, true));
            sources.sort(apiTaskSources.defaultTaskSourceComparer);
        }

        function onGetSourcesFailure(result) {
            return result;
        }

        async function updateSourceVisibility(source, newValue) {
            if (typeof newValue !== 'undefined' && newValue !== null)
                source.isVisible = newValue;

            preferencesStorage.setSourceVisibility("tasks", source);
            sources.forEach(src => src.isVisible = preferencesStorage.getSourceVisibility("tasks", src, true));

            if (!source.isVisible) {
                tasks = $.grep(tasks, function (task) { return task.sourceId === source.id && task.sourceOwner === source.owner; }, true);
                return;
            }

            await getTasks(source);
        }

        function loadTasks() {
            var defer = $q.defer();

            loadSources().then(function () {
                if (isTasksLoaded) {
                    defer.resolve();
                    return defer.promise;
                }

                tasks.length = 0;

                var promises = [];
                angular.forEach(sources, function (source) {
                    if (source.isVisible)
                        promises.push(getTasks(source));
                });

                return $q.all(promises)
                    .then(function () {
                        isTasksLoaded = true;
                        defer.resolve();
                    }, function (failure) {
                        isTasksLoaded = false;
                        defer.reject(failure);
                    });
            });

            return defer.promise;
        }

        function getTasks(source) {
            if (getTasksDefer.hasOwnProperty(source.id)) {
                return getTasksDefer[source.id].promise;
            }
            getTasksDefer[source.id] = $q.defer();
            if (source.owner) {
                apiCategories.categoryOwner = source.owner;
            }
            $http.get(`~/api/v1/tasks/${source.owner}/${source.id}/`)
                .then(function (success) {
                    var details = [];
                    angular.forEach(success.data.details,
                        function (detail) {
                            var tempDetail = {};
                            $.extend(tempDetail, detail, { sourceOwner: source.owner, sourceId: source.id, sourceName: source.name });
                            if (new Date(tempDetail.start) < new Date("01/01/100")) {
                                tempDetail.start = undefined;
                            } else {
                                tempDetail.start = vm.convertLocalToTargetTz(tempDetail.start, tempDetail.userTimeZone);
                            }
                            if (new Date(tempDetail.due) < new Date("01/01/100")) {
                                tempDetail.due = undefined;
                            } else {
                                tempDetail.due = vm.convertLocalToTargetTz(tempDetail.due, tempDetail.userTimeZone);
                            }
                            if (new Date(tempDetail.reminder) < new Date("01/01/100")) {
                                tempDetail.reminder = undefined;
                            } else {
                                tempDetail.reminder = vm.convertLocalToTargetTz(tempDetail.reminder, tempDetail.userTimeZone);
                            }
                            if (tempDetail.sourceOwner) {
                                apiCategories.addRgbColorsToCategories(tempDetail.sourceOwner, tempDetail.categories);
                            }
                            else {
                                tempDetail.categories = [];
                            }
                            tempDetail.categoriesString = apiCategories.generateNameString(tempDetail.categories);

                            details.push(tempDetail);
                        });
                    tasks = tasks.concat(details);
                    isTasksLoaded = true;
                    getTasksDefer[source.id].resolve();
                    delete getTasksDefer[source.id];
                },
                    function (failure) {
                        getTasksDefer[source.id].reject(failure);
                        delete getTasksDefer[source.id];
                    });
            return getTasksDefer[source.id].promise;
        }

        function getTask(owner, sourceId, taskId) {
            if (owner && sourceId && taskId) {
                const temp = tasks.find(task => task.sourceOwner === owner && task.sourceId === sourceId && task.id === taskId);
                if (temp)
                    return temp;
            }
            return null;
        }

        function getTaskFromServer(owner, sourceId, taskId) {
            var defer = $q.defer();

            $http.get(`~/api/v1/tasks/${owner}/${sourceId}/${taskId}/true/`)
                .then(function (success) {
                    defer.resolve(success.data.details[0]);
                }, function (failure) {
                    defer.reject(failure);
                });

            return defer.promise;
        }

        function getCardById(id) {
            return tasks.filter(task => task.id === id);
        }

        function saveTasks(tasksToSave) {
            var defer = $q.defer();
            if (!angular.isArray(tasksToSave)) {
                defer.reject($filter("translate")("ERROR_NOT_ARRAY"));
            } else {
                const params = JSON.stringify(tasksToSave);
                vm.ignoreTaskModified.requested = moment();
                $http
                    .post("~/api/v1/tasks/save/", params)
                    .then(function (success) {
                        angular.forEach(success.data,
                            function (task) {
                                if (new Date(task.start) < new Date("01/01/100")) {
                                    task.start = undefined;
                                }
                                if (new Date(task.due) < new Date("01/01/100")) {
                                    task.due = undefined;
                                }
                                const temp = tasks.find(t => t.id === task.id &&
                                    t.sourceId === task.sourceId &&
                                    t.sourceOwner === task.sourceOwner);
                                if (temp) {
                                    const index = tasks.indexOf(temp);
                                    if (index > -1)
                                        tasks[index] = task;
                                } else {
                                    const source = sources.find(s => s.id === task.sourceId && s.owner === task.sourceOwner);
                                    if (source && source.isVisible)
                                        tasks.push(task);
                                }
                            });
                        $rootScope.$broadcast("tasksRefresh");
                        defer.resolve(success.data);
                    },
                        function (failure) {
                            defer.reject(failure);
                        });
            }

            return defer.promise;
        }

        function modifyTasksSignal(owner, sourceId) {
            vm.ignoreTaskModified.requested = moment();
            var parsedOwner = null;
            if (owner) {
                parsedOwner = owner.split('@');
                parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            }
            var taskSource = $.grep(sources, function (source) {
                return source.owner === parsedOwner && source.id === sourceId;
            });
            if (taskSource.length > 0 && taskSource[0].isVisible) {
                tasks = $.grep(tasks, function (task) { return task.sourceId === taskSource[0].id; }, true);
                var promises = [];
                promises.push(getTasks(taskSource[0]));

                $q.all(promises)
                    .then(function (success) {
                        $rootScope.$broadcast("tasksRefresh");
                    },
                        function (failure) { });
            }
        }

        function removeTasksSignal(owner, taskIds) {
            var parsedOwner = null;
            if (owner) {
                parsedOwner = owner.split('@');
                parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            }
            angular.forEach(taskIds,
                function (value, key) {
                    tasks = $.grep(tasks, function (task) { return task.sourceOwner === parsedOwner && task.id === value; }, true);
                });
            $rootScope.$broadcast("tasksRefresh");
        }

        function removeTasks(tasksToRemove) {
            var defer = $q.defer();
            if (!angular.isArray(tasksToRemove))
                defer.reject($filter("translate")("ERROR"));
            else {
                angular.forEach(tasksToRemove,
                    function (value, key) {
                        if (!value.id) {
                            var card = vm.getCardById(value);
                            if (card.length > 0) {
                                tasksToRemove[key] = card[0];
                            } else {
                                value = { sourceOwner: '', sourceId: '', id: '' };
                            }
                        }
                    });

                var temp = $.map(tasksToRemove, function (t) { return { sourceOwner: t.sourceOwner, sourceId: t.sourceId, id: t.id }; });
                var params = JSON.stringify(temp);
                vm.ignoreTaskRemoved.requested = moment();
                $http.post("~/api/v1/tasks/delete/", params)
                    .then(function (success) {
                        angular.forEach(tasksToRemove,
                            function (task) {
                                tasks = $.grep(tasks,
                                    function (t) { return t.id === task.id && t.sourceId === task.sourceId && t.sourceOwner === task.sourceOwner; },
                                    true);
                            });
                        $rootScope.$broadcast("tasksRefresh");
                        defer.resolve(tasksToRemove);
                    },
                        function (failure) {
                            $rootScope.$broadcast("tasksRefresh");
                            defer.reject(failure.data);
                        });
            }

            return defer.promise;
        }

        function hasVisibleStatus(task) {
            if (vm.parameters.filterType === undefined)
                return true;

            if (task.status === 3 && vm.parameters.filterType[4] === true) return true;
            if (task.status === 0 && vm.parameters.filterType[1] === true) return true;
            if (task.status === 1 && vm.parameters.filterType[3] === true) return true;
            if (task.status === 2 && vm.parameters.filterType[2] === true) return true;
            if (task.hasAttachments && vm.parameters.filterType[5] === true) return true;

            return false;
        }

        function resetSourcesTree() {
            _sourcesTree = { data: [], map: [], selectedBranchData: {} };
        }

        function loadSourcesTree(treeController, forceReload) {
            if (sourcesDefer)
                return sourcesDefer.promise;
            sourcesDefer = $q.defer();

            if (treeController == null && previousTreeController)
                treeController = previousTreeController;

            if (forceReload) {
                reset();
            }

            if (forceReload || _sourcesTree.data.length === 0) {
                loadSourcesTreeData(sourcesDefer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            } else {
                var b = _sourcesTree.map[_sourcesTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    sourcesDefer.resolve(false);
                }
                sourcesDefer.resolve(true);
            }

            sourcesDefer.promise.finally(function () {
                sourcesDefer = null;
            });

            return sourcesDefer.promise;
        }

        function loadSourcesTreeData(defer, treeController) {
            loadSources().then(function () {
                let newData = [];
                let newMap = [];

                $.each(sources,
                    function (i, val) {
                        if (val.owner === userDataService.user.username)
                            val.isBeingShared = isTaskShared[val.id] || false;
                        newData.push(loadSourcesTreeBranch(val, newMap));
                    });

                _sourcesTree.data = newData;
                _sourcesTree.map = newMap;
                defer.resolve(true);
            });
        }

        function loadSourcesTreeBranch(val, newMap) {
            // Load data for this branch.
            const name = val.isSharedItem ? $filter("folderTranslate")(val.name, val.owner) :  $filter("folderTranslate")(val.name);
            var branch = {
                label: name,
                data: {
                    "id": val.name,
                    "isShared": val.owner !== userDataService.user.username || val.isBeingShared,
                    "isSharedByOther": val.owner !== userDataService.user.username || val.isDomainResource,
                    "isDomainResource": val.isDomainResource,
                    "showEye": true,
                    "isVisible": val.isVisible,
                    "source": val,
                    "isBeingShared": val.isBeingShared,
                    "color": val.color,
                    "isPrimary": val.isPrimary
                }
            };
            newMap[branch.data.key] = branch;
            return branch;
        }

        function convertLocalToTargetTz(date, targetTz) {
            /*
             * Javascript only lets date objects use the local system timezone, and the controls won't work with a moment.
             * To get around this, we need to convert the date first to a psuedo-UTC that is off from real-UTC by the difference
             * between the offsets of the local system timezone and the target timezone. The psuedo-UTC time can then be converted to
             * a moment in the target timezone which is then used to generate a JS date object.
             */
            var localOffset = moment(date).utcOffset();
            var targetOffset = moment.tz(date, targetTz).utcOffset();
            var targetUtc = moment(date).utc().add(-localOffset + targetOffset, "m");
            var targetDate = moment.tz(targetUtc, targetTz);
            return targetDate.toDate();
        }
    }
})();