(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("popConnectionsController", popConnectionsController);

    function popConnectionsController($state, $rootScope, $scope, $http, $filter, $compile, $q, $mdDialog, $translate, NgTableParams,
        gridCheckboxes, userDataService, successHandling, errorHandling) {

        var vm = this;
        vm.domain = $state.params.id || userDataService.user.domain;
        vm.searchText = '';
        vm.searchParams = {
            domain: vm.domain,
            skip: 0,
            take: 0,
            search: null,
            sortField: null,
            sortDescending: false
        };
        vm.maxResyncRequestsAllowed = 25;
        vm.searchItems = searchItems;
        vm.refresh = refresh;
        var protocolType = 4;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.dropConnections = dropConnections;
        vm.enable = enable;
        vm.disable = disable;
        vm.enableAccess = $filter('translate')('ENABLE_POP_ACCESS');
        vm.disableAccess = $filter('translate')('DISABLE_POP_ACCESS');
        vm.resyncAllProtocols = resyncAllProtocols;
        vm.getAuthIps = getAuthIps;
        vm.copyAddress = copyAddress;
        vm.maxResyncRequestsAllowed = 25;

        activate();

        function activate() {
            vm.tableParams = new NgTableParams(
                {
                    sorting: { connections: 'desc' },
                    count: 25
                }, {
                getData: queryData,
                counts: $rootScope.commonTableCounts
            });
            vm.checkboxes = gridCheckboxes.init('emailAddress');
            vm.checkboxes.table = vm.tableParams;
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

        function queryData(params) {
            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();
            vm.searchParams.sortField = null;
            vm.searchParams.type = $rootScope.protoType;

            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
                break;
            }

            $rootScope.spinner.show();
            return $http
                .post('~/api/v1/settings/domain/users-connections-summary', JSON.stringify(vm.searchParams))
                .then(function (result) {
                    vm.searchResults = result.data.data;
                    angular.forEach(vm.searchResults, function (value) {
                        if (value.lastLogin) {
                            value.lastLoginTranslate = $filter('date')(value.lastLogin, 'short');
                        }
                    });
                    vm.searchResultCount = result.data.totalCount;
                    params.total(vm.searchResultCount);
                    return vm.searchResults;
                }, function () { })
                .finally(function () {
                    $rootScope.spinner.hide();
                });
        }

        function refresh() {
            $scope.$emit('reloadUsersConnectionsCounts');

            vm.checkboxes.reset();
            vm.tableParams
                .reload()
                .then(function () { }, function () { })
                .finally(function () { successHandling.report('ACTION_SUCCEEDED'); });
        }

        function contextMenuGridItem(item, ev) {
            var showMenu = true;
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            var items = vm.checkboxes.getCheckedItems();

            if ((items.length > 1 && !_.some(items, function (val) { return val = item.emailAddress })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.emailAddress];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'dropConnections', click: dropConnections, translateKey: 'DROP_CONNECTIONS' },
                { key: 'resyncUsers', click: resyncAllProtocols, translateKey: 'RESYNC_USERS' },
                { key: vm.enableAccess, click: enable, translateKey: vm.enableAccess },
                { key: vm.disableAccess, click: disable, translateKey: vm.disableAccess },
                { divider: true },
                { key: 'authIps', click: getAuthIps, params: $scope.dropdownEvent, translateKey: 'VIEW_AUTHENTICATED_IPS' },
                { key: 'copyAddress', click: copyAddress, params: $scope.dropdownEvent, translateKey: 'COPY_ADDRESS' }
            ];

            var selected = {};
            for (var i = 0; i < items.length; ++i) {
                selected[items[i]] = true;
            }

            if (showMenu) {
                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function dropConnections() {
            var foundRows = [];
            for (var key in vm.checkboxes.items) {
                if (vm.checkboxes.items[key]) {
                    foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.emailAddress === key }));
                }
            }

            if (foundRows.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
            } else if (foundRows.length == 1) {
                doDropConnections();
            } else {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: foundRows.length }))
                    .ok($filter('translate')('OK'))
                    .title($filter('translate')('CONFIRMATION_REQUIRED'));
                $mdDialog.show(confirm).then(doDropConnections, function () { });
            }

            function doDropConnections() {
                var toEndWeb = [];
                var toDropCon = [];
                angular.forEach(foundRows, function (user) {
                    toEndWeb.push({ email: user.emailAddress, ip: user.ipAddress });
                    toDropCon.push(user.emailAddress);
                });
                var disconnectedSelf = false;
                var promises = [];

                if (toDropCon.length > 0) {
                    var dropParams = JSON.stringify({ type: protocolType, dropInfo: toDropCon });
                    promises.push($http.post('~/api/v1/settings/domain/drop-user-connections', dropParams));
                }

                $rootScope.spinner.show();
                $q.all(promises)
                    .then(function () {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report();
                        if (disconnectedSelf)
                            window.location.hash = "";
                        $scope.$emit('reloadUsersConnectionsCounts');
                    }, errorHandling.report)
                    .finally(function () {
                        $rootScope.spinner.hide();
                    });
            }
        }

        function enable() {
            var foundRows = [];
            for (var key in vm.checkboxes.items) {
                if (vm.checkboxes.items[key]) {
                    foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.emailAddress === key }));
                }
            }

            if (foundRows.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            } else {
                doEnableUsers();
            }

            function doEnableUsers() {
                var data = [];
                angular.forEach(foundRows, function (user) {
                    data.push(user.emailAddress);
                });

                $rootScope.spinner.show();
                $http.post('~/api/v1/settings/domain/protocol-mailboxes-patch', JSON.stringify({ type: protocolType, isActive: true, emailAddresses: data }))
                    .then(function (success) {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report();
                    }, errorHandling.report)
                    .finally(function () {
                        $rootScope.spinner.hide();
                    });
            }
        }

        function disable() {
            var foundRows = [];
            for (var key in vm.checkboxes.items) {
                if (vm.checkboxes.items[key]) {
                    foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.emailAddress === key }));
                }
            }

            if (foundRows.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
                return;
            } else {
                doDisableUsers();
            }

            function doDisableUsers() {
                var data = [];
                var toDropCon = [];
                angular.forEach(foundRows, function (user) {
                    data.push(user.emailAddress);
                    toDropCon.push(user.emailAddress);
                });

                var promises = [];
                promises.push($http.post('~/api/v1/settings/domain/protocol-mailboxes-patch', JSON.stringify({ type: protocolType, isActive: false, emailAddresses: data })))
                promises.push($http.post('~/api/v1/settings/domain/drop-user-connections', JSON.stringify({ type: protocolType, dropInfo: toDropCon })));

                $rootScope.spinner.show();
                $q.all(promises)
                    .then(function () {
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                        successHandling.report();
                        $scope.$emit('reloadUsersConnectionsCounts');
                    }, errorHandling.report)
                    .finally(function () {
                        $rootScope.spinner.hide();
                    });
            }
        }

        function resyncAllProtocols() {
            if (vm.checkboxes.selectedCount > vm.maxResyncRequestsAllowed) {
                errorHandling.report($translate.instant('RESYNC_USERS_MAX_EXCEEDED'));
                return;
            }

            var foundRows = [];
            for (var key in vm.checkboxes.items) {
                if (vm.checkboxes.items[key]) {
                    foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.emailAddress === key }));
                }
            }

            var confirm = $mdDialog.confirmDeletion()
                .title($filter('translate')('CONFIRMATION_REQUIRED'))
                .htmlContent($filter('translate')('CONFIRMATIONS_RESYNC_OTHER_USER_DEVICES_COUNT', { count: foundRows.length }) +
                    "<br/><br/>" +
                    $filter('translate')('CONFIRMATIONS_RESYNC_OTHER_USER_DEVICES', { count: foundRows.length }))
                .cancel($filter('translate')('CANCEL'))
                .ok($filter('translate')('CONTINUE'));

            $mdDialog.show(confirm).then(function (option) {
                doResyncUser();
            });

            function doResyncUser() {
                var toResync = [];
                angular.forEach(foundRows, function (user) {
                    toResync.push(user.emailAddress);
                });

                $rootScope.spinner.show();
                $http.post('~/api/v1/settings/domain/resync-users-devices', JSON.stringify({ input: toResync }))
                    .then(function () {
                        successHandling.report($translate.instant('RESYNC_COMPLETE_USER_NOTIFICATION'));
                    }, errorHandling.report)
                    .finally(function () {
                        $rootScope.spinner.hide()
                    });
            }
        }

        function getAuthIps(event, data) {
            var emailAddress = "";
            if (!data) {
                if (vm.checkboxes.getCheckedItems().length == 1) {
                    // We get here from the action menu click
                    // And from a single selection context menu click
                    emailAddress = vm.checkboxes.getCheckedItems()[0];
                } else {
                    // We get here from a multi select context menu click
                    emailAddress = event.currentTarget.childNodes[4].innerText;
                }
            } else {
                // We get here from left clicking
                emailAddress = data.emailAddress;
            }

            $mdDialog.show({
                locals: {
                    selectedUserEmailAddress: encodeURIComponent(emailAddress)
                },
                controller: "authIpsController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/domain-settings/users-connections/modals/auth-ips.dlg.html",
                targetEvent: event
            }).then(function () { }, function () { });
        }

        function copyAddress(event) {
            var address = "";
            if (vm.checkboxes.getCheckedItems().length == 1) {
                address = vm.checkboxes.getCheckedItems()[0];
            } else {
                address = event.currentTarget.childNodes[4].innerText;
            }

            var textArea = document.createElement('textarea');
            textArea.value = address;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            successHandling.report('COPIED_TO_CLIPBOARD');
        }
    }
})();