(function () {
	"use strict";

	// Processes Enter Key commands on input controls. 

	// Usage Example:
	// <input type="text" st-enter-key="someFunction();" />

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("ngImageOnError", ngImageOnErrorFunc);

	function ngImageOnErrorFunc() {

		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.bind('error', function (evt) {

					scope.$apply(function () {
						scope.$eval(attrs.ngImageOnError, { $element: evt.target, evt });
					});

					//evt.preventDefault();

				});
			}
		};
	}

	angular
		.module("smartertools")
		.directive("ngImageOnError", ngImageOnErrorFunc);

})();