(function () {
	"use strict";

	angular
		.module("smartermail")
        .controller("sysAdminTroubleshootingController", sysAdminTroubleshootingController);

	function sysAdminTroubleshootingController($rootScope, $scope, $http, $filter, $mdDialog) {
		var vm = this;
        vm.gridSections = [
            { translate: "OPTIONS", length: -1 },
            { translate: "VIEW_LOGS", length: -1 }, 
            { translate: "SERVICES", length: -1 },
            { translate: "MAILBOX_INDEXING", length: -1 }, 
            { translate: "MAILBOX_MIGRATION", length: -1 }
        ];
        vm.selectedSection = "OPTIONS";
        vm.changeSection = function (section) { vm.selectedSection = section; };
		window.showDevMenu = showDevMenu;
		// Functions

		activate();

		////////////////////////////////

        function activate() {
            refreshCounts();
			$scope.$on("troubleshooting:countersChanged", refreshCounts);


			if (window.addEventListener) {
				var kkeys = [], beepbep = "38,38,40,40,37,39,37,39,66,65";
				window.addEventListener("keydown", function (e) {
					kkeys.push(e.keyCode);
					if (kkeys.toString().indexOf(beepbep) >= 0) {
						showDevMenu();
						kkeys = [];
					}
				}, true);
			}

        }

		function showDevMenu() {
			$mdDialog.show({
				controller: "sysAdminDevMenuController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/troubleshooting/devmenu/devmenu.dlg.html"
			});
		}

        function refreshCounts() {
            $http
				.get('~/api/v1/settings/sysadmin/troubleshooting-counts')
                .then(function (result) {
					vm.gridSections[1].length = result.data.logsCount;
					vm.gridSections[2].label = "/ " + $filter('number')(result.data.serviceCount) + " " + $filter("translate")("ACTIVE");
					vm.gridSections[2].length = result.data.activeServiceCount;
					vm.gridSections[3].length = result.data.mailboxIndexingCount;
					vm.gridSections[4].length = result.data.mailboxMigrationCount;
				}, function () { });

            //$http
            //    .get("~/api/v1/settings/sysadmin/priority-overrides")
            //    .then(function (result) {
            //        vm.gridSections[1].length= (result.data.priorityOverrides || []).length;
            //    });

            //$http
            //    .get("~/api/v1/settings/sysadmin/global-mail")
            //    .then(function (result) {
            //        vm.gridSections[2].length = result.data.globalMailSettings.serverSettings.reservedDomainNames.length;
            //    });
        }
	}

})();
