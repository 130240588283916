(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminTwoFactorController", adminTwoFactorController);

	function adminTwoFactorController($scope, $mdDialog, $http, $q, $timeout, $translate, errorHandling, successHandling, twoFactorSetupTypes, username) {

		$scope.step = -1;
		$scope.secretKey = "";
		$scope.qrUrl = "";
		$scope.type = 2;
		$scope.checkCode = "";
		$scope.codeError = false;
		$scope.hasVerifiedCode = false;
		$scope.tfCantScan = false;
		$scope.tfConfirmRecoveryMail = "";
		$scope.tfUsername = username;
		$scope.twoFactorSetupTypes = twoFactorSetupTypes;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.nextStep = nextStep;
		$scope.backStep = backStep;
		$scope.checkTwoFactorCode = checkTwoFactorCode;
		$scope.copySecretKey = copySecretKey;
		$scope.cantScan = cantScan;

		activate();

		///////////////////////

		function activate() {
			var containsRfc = false;
			for (var i = 0; i < twoFactorSetupTypes.length; ++i) {
				if (twoFactorSetupTypes[i].value === 2) {
					containsRfc = true;
					break;
				}
			}
			if (!containsRfc) {
				$scope.type = 1;
			}
		}

		function setQrCode() {
			var element = document.getElementById("qrcode");
			if (element) {
				new QRCode(element, {
					text: $scope.qrUrl,
					width: 150,
					height: 150,
				});
			} else {
				setTimeout(setQrCode, 150);
			}
		}

		function nextStep(event, form) {
			if (form.$invalid)
				return;

			$scope.codeError = false;
			if ($scope.step == -1) {
				if ($scope.type == 2) {
					DoRfcStep();
				} else {
					DoEmailStep();
				}
			} else if ($scope.step == 0 && $scope.type !== 1 && !$scope.hasVerifiedCode) {
				checkTwoFactorCode(event, form);
			} else if ($scope.step == 0 && $scope.type !== 1 && $scope.hasVerifiedCode) {
				$scope.step = 2;
			} else if ($scope.step == 1) {
				$scope.step = 2;
			}
		}

		function backStep(event, form) {
			$scope.hasVerifiedCode = false;
			$scope.codeError = false;
			$scope.checkCode = "";
			if ($scope.step == 0) {
				if ($scope.tfCantScan) {
					$scope.tfCantScan = false;
					$timeout(function () { $('input[name=checkFactorCode]').trigger("focus"); });
				} else {
					$scope.qrUrl = "";
					$scope.secretKey = "";
					$('#qrcode').empty();
					$scope.step = -1;
				}
			} else if ($scope.step == 1 && $scope.type === 1) {
				$scope.step = -1;
			} else if ($scope.step == 1 && $scope.type !== 1) {
				$scope.step = 0;
			}
		}
		function copySecretKey(event, form) {
			var copyText = $('#copy-key')[0];
			var range = document.createRange();
			range.selectNode(copyText);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
			document.execCommand('copy');
			window.getSelection().removeAllRanges();
			successHandling.report($translate.instant("COPIED_TO_CLIPBOARD"));
		}
		function cantScan() {
			$scope.tfCantScan = true;
			$timeout(function () { $('input[name=checkFactorCode]').trigger("focus"); });
		}

		function checkTwoFactorCode(event, form) {
			if (form.$invalid)
				return;
			var oType = ($scope.type == 1 ? 'email' : 'rfc6238');
			$http
				.post('~/api/v1/settings/user-check-two-factor-code/' + oType + '/' + $scope.checkCode + '/' + ($scope.secretKey ? $scope.secretKey : 'null'))
				.then(function (success) {
					if (success.data.result === true) {
						$scope.hasVerifiedCode = true;
						$scope.codeError = false;
						save(event, form);
					} else {
						$scope.hasVerifiedCode = false;
						$scope.codeError = true;
					}
				}, errorHandling.report);
		}
		// TODO: Needs to be implemented once we can support email 2fa
		function DoEmailStep() {
			$http.post('~/api/v1/settings/user-generate-two-factor/email')
				.then(function (success) {
					$scope.secretKey = success.data.clientSecret
					$scope.step = 1;
					$timeout(function () { $('input[name=checkFactorCodeMail]').trigger("focus"); });
				}, function (failure) {
					errorHandling.report(failure);
				});
		}

		function DoRfcStep() {
			$http.post('~/api/v1/settings/user-generate-two-factor/rfc6238')
				.then(function (success) {
					$scope.qrUrl = success.data.qrString;
					$scope.secretKey = success.data.clientSecret;
					setQrCode();
					$scope.step = 0;
					$timeout(function () { $('input[name=checkFactorCode]').trigger("focus"); });
				}, function (failure) {
					errorHandling.report(failure);
				});
		}


		function save(event, form) {
			if (form.$invalid)
				return;
			$http
				.post('~/api/v1/settings/sysadmin/set-two-factor/true')
				.then(function () {
					return $http.post(`~/api/v1/settings/user-set-two-factor-secret/${$scope.secretKey}/${$scope.type == 1 ? 'email' : 'rfc6238'}`);
				})
				.then(function () {
					$mdDialog.hide({ key: $scope.secretKey, type: $scope.type, enabled: true, configured: true });
				}, function (failure) { $mdDialog.hide({ failure: failure}); });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
