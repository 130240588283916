(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarEventPopoutController", calendarEventPopoutController);

    function calendarEventPopoutController($rootScope, $scope, $timeout, $http, authStorage,
        coreData, coreDataCalendar, errorHandling, errorMessageService, themesService) {

        var vm = this;
        var askBeforeLeave = $scope.isPopout;
        $scope.calendars = [];
        $scope.conferenceRooms = [];
        $scope.isInit = false;
        activate();

        /////////////////////////////////

        function activate() {
            $rootScope.spinner.show();
            coreData
                .init()
                .then(init, errorHandling.report)
                .finally($rootScope.spinner.hide);

            $rootScope.$on("cancelEditingEvent", onCancelEditingEvent);
            $rootScope.$on("saveEditingEvent", onSaveEditingEvent);
            window.onbeforeunload = onWindowBeforeUnload;
        }

        vm.initializeCoreDataCalendar = function () {
            $scope.calendars = coreDataCalendar.getCalendars();
            $scope.conferenceRooms = coreDataCalendar.getResources();
            $rootScope.spinner.hide();
        };

        function init() {
            themesService.ensureActivated();
            var popoutData = authStorage.getPopoutData();
            coreDataCalendar.editingEvent = popoutData.originalEvInfo;
            coreDataCalendar.editingEvent.popoutData = popoutData.eventDetails;
            vm.initializeCoreDataCalendar();
            $scope.isInit = true;
        }

        function saveEvent(owner, calId, eventId, originalDetails, changes, revertFunc) {
            if (!changes || changes.length < 0)
                return;

            var evt = $.extend(true, {}, originalDetails);
            for (var i in changes) {
                var change = changes[i];
                if (change.calId != undefined) evt.calendarId = change.calId;
                else if (change.owner != undefined) evt.calendarOwner = change.owner;
                else if (change.subject != undefined) evt.subject = change.subject;
                else if (change.location != undefined) evt.location = change.location;
                else if (change.resourceId != undefined) evt.resourceId = change.resourceId;
                else if (change.start != undefined) { evt.start = change.start; }
                else if (change.end != undefined) { evt.end = change.end; }
                else if (change.allDay != undefined) evt.allDay = change.allDay;
                else if (change.availability != undefined) evt.availability = change.availability;
                else if (change.reminderIndex != undefined) evt.reminderIndex = change.reminderIndex;
                else if (change.emailNotificationEnabled != undefined) evt.emailNotificationEnabled = change.emailNotificationEnabled;
                else if (change.emailNotification != undefined) evt.emailNotification = change.emailNotification;
                else if (change.description != undefined) evt.description = change.description;
                else if (change.isPrivate != undefined) evt.isPrivate = change.isPrivate;
                else if (change.organizerName != undefined) evt.organizerName = change.organizerName;
                else if (change.organizerEmail != undefined) evt.organizerEmail = change.organizerEmail;
                else if (change.attendees != undefined) evt.attendees = change.attendees;
                else if (change.categories != undefined) evt.categories = change.categories;
                else if (change.recurrence != undefined) evt.recurrence = change.recurrence;
            }

            $rootScope.spinner.show();
            var params = JSON.stringify(evt);
            $http
                .post("~/api/v1/calendars/events/save/" + owner + "/" + calId + "/" + eventId, params)
                .then(window.close, errorMessageService.showErrorMessage)
                .finally($rootScope.spinner.hide);
        }

        function deleteEvent(owner, calId, eventInfo, instanceStart) {
            $rootScope.spinner.show();
            var params = "";
            if (instanceStart != undefined)
                params = JSON.stringify({ instanceStart: moment(instanceStart).toDate() });

            $http
                .post("~/api/v1/calendars/events/delete/" + owner + "/" + calId + "/" + eventInfo.id, params)
                .then(window.close, errorMessageService.showErrorMessage)
                .finally($rootScope.spinner.hide);
        }

        function acceptMeetingInvite(owner, calId, eventId) {
            $rootScope.spinner.show();
            $http
                .post("~/api/v1/calendars/meeting-accept/" + owner + "/" + calId + "/" + eventId)
                .then(window.close, function () { })
                .finally($rootScope.spinner.hide);
        }

        function declineMeetingInvite(owner, calId, eventId) {
            $rootScope.spinner.show();
            $http
                .post("~/api/v1/calendars/meeting-decline/" + owner + "/" + calId + "/" + eventId)
                .then(window.close, function () { })
                .finally($rootScope.spinner.hide);
        }

        function onCancelEditingEvent() {
            askBeforeLeave = false;
            window.close();
        }

        function onSaveEditingEvent(ev, details) {
            askBeforeLeave = false;
            var editEvent = coreDataCalendar.editingEvent;
            if (!details.deleteEvent) {
                if (details.acceptInvite) {
                    acceptMeetingInvite(editEvent.owner, editEvent.calId, editEvent.info.id);
                } else if (details.declineInvite) {
                    declineMeetingInvite(editEvent.owner, editEvent.calId, editEvent.info.id);
                } else if (details.changes != undefined && details.changes.length > 0) {
                    saveEvent(editEvent.owner, editEvent.calId, editEvent.info ? editEvent.info.id : null, editEvent.info, details.changes);
                }
            } else if (details.instance != undefined) {
                deleteEvent(editEvent.owner, editEvent.calId, editEvent.info, details.instance);
            } else {
                deleteEvent(editEvent.owner, editEvent.calId, editEvent.info);
            }
            $scope.editingCalEvent = false;
            coreDataCalendar.editingEvent = undefined;
            $timeout(function () { $(window).trigger("resize"); });
        }

        function onWindowBeforeUnload() {
            if (askBeforeLeave) {
                askBeforeLeave = false;
                return true;
            }
        };
    }
})();
