(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateIpAddressWildcard", [validateIpAddressWildcard]);

	function validateIpAddressWildcard() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				function isValidIPv4(ipv4) {
					var ips = ipv4.split(".");
					if (ips.length !== 4) return false;
					for (var i = 0; i < 4; ++i) {
						if (isNaN(parseFloat(ips[i]))) return false;
						var value = parseInt(ips[i], 10);
						if (value < 0 || value > 255) return false;
					}
					return true;
				}

				function isValidIPv6(ipv6) {
					var ips = ipv6.split(":");
					if (ips.length < 2 || ips.length > 8) return false;
					for (var i = 0; i < ips.length; ++i) {
						if (ips[i] === "") continue;
						var ip = parseInt(ips[i].toLowerCase(), 16);
						if (ip.toString(16) !== ips[i].toLowerCase())
							return false;
					}
					return true;
				}

				ctrl.$validators.validateIpAddressWildcard = function (modelValue, viewValue) {
					if (!viewValue) return true;
					return !viewValue.contains("-") && !viewValue.contains("/") &&					// Disallow IP ranges and CIDR blocks
						(viewValue === "*" || isValidIPv4(viewValue) || isValidIPv6(viewValue));	// Allow wildcard, and valid IPv4 and IPv6
				};
			}
		};
	}
})();