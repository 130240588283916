(function () {
	"use strict";

	angular
		.module("smartertools")
		.service("emailValidationService", emailValidationService);

	function emailValidationService($http) {
		var vm = this;

		vm.isValidDomain = isValidDomain;
		vm.isValidEmail = isValidEmail;
		vm.isValidRbl = isValidRbl;
		
		const validatedDomains = {};

		const hasPunycode = /(^|[.\u3002\uFF0E\uFF61])xn--/;
		const hasUnicode = /[^\x00-\x7F]/;

		function baseDomainChecks(domain) {
			if (domain.length < 4) return false;
			if (domain[0] === "-") return false;
			if (domain[domain.length - 1] === "-") return false;
			if (domain.indexOf("-.") > -1) return false;
			
			let invalidChars = ["*", "@", "!", "_"];
			for (let i = 0; i < invalidChars.length; i++) {
				if (domain.indexOf(invalidChars[i]) > -1)
					return false;
			}

			const dotChars = /[.\u3002\uFF0E\uFF61]/;
			if (!dotChars.test(domain))
				return false;

			return true;
		}

		function isValidDomain(domain) {
			if (!domain) return true;
			var doMoreChecks = baseDomainChecks(domain);
			if (!doMoreChecks) return false;
			
			if (hasPunycode.test(domain)) {
				return isValidDomainApiCall(domain);
			}
			
			if (!hasUnicode.test(domain)) {
				const asciiDomain = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
				return asciiDomain.test(domain);
			}

			/* idnRegex follows this basic form:
			 *   * first character is an ASCII letter, ASCII digit, or non-ASCII Unicode, optionally followed by:
			 *     * 0 to 61 characters of ASCII letter, ASCII digit, ASCII hyphen, or non-ASCII Unicode AND
			 *     * 1 character of ASCII letter, ASCII digit, or non-ASCII Unicode
			 *   * next character is a dot (., U+3002, U+FF0E, or U+FF61)
			 *   * 2 to 63 characters of ASCII letter or non-ASCII Unicode
			 */
			const idnRegex = /^([a-zA-Z0-9\u00A1-\u3001\u3003-\uD7FF\uF900-\uFDCF\uFDF0-\uFF0D\uFF0F-\uFF60\uFF62-\uFFEF]([-a-zA-Z0-9\u00A1-\u3001\u3003-\uD7FF\uF900-\uFDCF\uFDF0-\uFF0D\uFF0F-\uFF60\uFF62-\uFFEF]{0,61}[a-zA-Z0-9\u00A1-\u3001\u3003-\uD7FF\uF900-\uFDCF\uFDF0-\uFF0D\uFF0F-\uFF60\uFF62-\uFFEF])?[.\u3002\uFF0E\uFF61])+[a-zA-Z\u00A1-\u3001\u3003-\uD7FF\uF900-\uFDCF\uFDF0-\uFF0D\uFF0F-\uFF60\uFF62-\uFFEF]{2,63}$/; 
			
			var isValidIdnFormat = XRegExp ? XRegExp(idnRegex).test(domain) : idnRegex.Test(domain);
			if (!isValidIdnFormat)
				return false;

			return isValidDomainApiCall(domain);
		}

		function isValidRbl(domain) {
			// Hostnames for RBL/URIBL lookups have slightly relaxed rules compared to standard domain names.
			// Since hostnames for RBL/URIBL are used to do DNS lookups, they need to be all-ASCII.

			if (!domain)
				return true;
			var doMoreChecks = baseDomainChecks(domain);
			if (!doMoreChecks)
				return false;
			
			if (hasUnicode.test(domain))
				return false;

			const asciiRblHostname = /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;
			return asciiRblHostname.test(domain);
		}

		async function isValidDomainApiCall(domain) {
			if (Object.prototype.hasOwnProperty.call(validatedDomains, domain))
				return validatedDomains[domain];
			try {
				var result = await $http.post("~/api/v1/settings/validate-domain-name", { input: domain });
				validatedDomains[domain] = result.data.success;
				return result.data.success;
			}
			catch (e) {
				validatedDomains[domain] = false;
				return false;
			}
		}

 		function isValidEmail(email, strict = false) {
			if (!email) return true;
			// RFC-5321 limits email addresses to 256 octets. This requirement is unchanged by RFCs 6530 through 6533.
			// By definition, the shortest possible email address is 6 octets (e.g. a@a.aa)
			const octetCount = getUTF8ByteCount(email);
			if (octetCount < 6 || octetCount > 256)
				return false;
			if (email.indexOf("@") < 0) return false;

			let split = email.split("@");
			if (split.length != 2) return false;
			let localPart = split[0];
			let domainPart = split[1];
			
			let isLocalValid = isValidLocalPart(localPart, strict);

			if (isValidIPv4(domainPart)) {
				return isLocalValid;
			}
			
			if (!isLocalValid)
				return false;
			
			return isValidDomain(domainPart);
		}

		function isValidLocalPart(localPart, strict) {
			// RFC-5321 limits the local part to 64 octets. This requirement is unchanged by RFCs 6530 through 6533.
			// Other clients don't enforce this limit so enforcing this requirements is relaxed unless the local part
			// contains a non-ASCII character.
			const octetCount = getUTF8ByteCount(localPart);
			const checkOctetCount = strict ? true : checkContainsNonAscii(localPart);
			if (checkOctetCount && (octetCount < 0 || octetCount > 64))
				return false;
			// EMAIL VALIDATION - if this is changed, change it everywhere in the project
			// Disallowed characters are taken from RFC-5322 section 3.2.3, specifically the disallowed characters since we don't support
			// quoted local-parts. RFC-6531 section 3.3 indicates that the rules for special and control characters are unchanged by
			// internationalization.
			const localPartRegex = /^([^()<>[\]:;@\\,"\s.\u3002\uFF0E\uFF61]+([.\u3002\uFF0E\uFF61][^()<>[\]:;@\\,"\s.\u3002\uFF0E\uFF61]+)*)$/;
			return XRegExp
				? XRegExp(localPartRegex).test(localPart)
				: localPartRegex.test(localPart);
		}
		
		function getUTF8ByteCount(inputString) {
			try {
				const encoder = new TextEncoder();
				const utf8Bytes = encoder.encode(inputString);
				return utf8Bytes.length;

			} catch (err) {
				console.error("emailValidationService error: ", err);
				return 0;
			}
		}
		
		function checkContainsNonAscii(inputString)
		{
			for (let char of inputString) {
				if(char.codePointAt() > 0x7f) {
					return true;
				}
			}
			
			return false;
		}
		
		function isValidIPv4(ipv4) {
			var ips = ipv4.split(".");
			if (ips.length !== 4) return false;
			for (var i = 0; i < 4; ++i) {
				if (!$.isNumeric(ips[i])) return false;
				var value = parseInt(ips[i], 10);
				if (value < 0 || value > 255) return false;
			}
			return true;
		}
	}
})();