(function () {
	"use strict";

	angular
		.module("smartermail")
		.directive("fileStorageCard", fileStorageCardDirective);

	function fileStorageCardDirective() {
		return {
			restrict: "EA",
			templateUrl: "app/file-storage/file-storage-card.html",
			controller: function () { }
		};
	}

})();
