(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataSpool", coreDataSpool);

	function coreDataSpool($http, $q, emailNavigation) {
		var vm = this;
		var _isInitialized = false;
		var _spoolStats = undefined;
		var spoolStatsInitialized = false;
		var _messageStats = undefined;
		var messageStatsInitialized = false;
		var _outboundSenders = {};
		var _outboundIpAddress = {};
		var _inboundRecipients = {};
		var _inboundSenders = {};
		var _inboundIpAddress = {};
		var _inboundDomains = {};
		var spoolRowsCount = 10;
		var spoolDataLoaded = false;
		vm.spoolStatTypes = { OutboundSenders: 0, InboundRecipients: 1, InboundSenders: 2, InboundIPAddresses: 3, OutboundIPAddresses: 4, InboundDomain: 5 };
		var reverseIPs = {};
		var requestedIPs = {};
		vm.isIPListValid = true;
		var _smtpBlocks = [];
		var smtpBlocksInitialized = false;
		var userList = {};
		vm.isUserListValid = true;
		vm.currentSection = "OVERVIEW";
		vm.spoolStatus = { 0: "SPOOL_WRITING", 1: "SPOOL_DELAY", 2: "COMMAND_LINE", 3: "SPOOL_SPAM_CHECK", 4: "SPOOL_LOCAL_DELIVERY", 5: "DELIVERED", 6: "SPOOL_REMOTE_DELIVERY_PROCESSING", 7: "SPOOL_QUARANTINED" };
		vm.sortFieldTypes = { "fileName": 2, "shortSpool": 3, "from": 1, "deliveredratio": 5, "size": 4, "deliveryAttempts": 6, "timeTranslate": 8, "priority": 9, "statusStr": 7, "creationDate": 12, "nextDeliveryAttempt": 10, "timeuntilremoval": 11 };
		vm.Data = {
			get spoolStats() { return getSpoolStats(); },
			get messageStats() { return getMessageStats(); },
			get refreshMessageStats() { return refreshMessageStats(); },
			get outboundSenders() { return getOutboundSenders(); },
			get outboundIPAddresses() { return getOutboundIPAddresses(); },
			get inboundRecipients() { return getInboundRecipients(); },
			get inboundSenders() { return getInboundSenders(); },
			get inboundIPAddresses() { return getInboundIPAddresses(); },
			get inboundDomains() { return getInboundDomains(); },
			get smtpBlockRules() { return getSmtpBlocks(); },
			set smtpBlockRules(value) { _smtpBlocks = value || []; }
		};

		// Functions
		vm.init = init;
		vm.reset = reset;
		vm.initialSpoolLoad = initialSpoolLoad;
		vm.reloadSpoolStats = reloadSpoolStats;
		vm.getReverseIPs = getReverseIPs;
		vm.getUsers = getUsers;
		vm.updateUser = updateUser;
		vm.forceMessage = forceMessage;
		vm.refreshRetries = refreshRetries;
		vm.setPriority = setPriority;
		vm.resendMessages = resendMessages;
		vm.deleteMessages = deleteMessages;
		vm.deleteAllQuarantineMessagesByType = deleteAllQuarantineMessagesByType;
		vm.getViewMessage = getViewMessage;
		vm.changeSection = changeSection;

		activate();

		/////////////////////

		function activate() {
		}

		var initDefer = null;
		function init() {
			if (_isInitialized)
				return $q.when();

			if (initDefer)
				return initDefer.promise;

			initDefer = $q.defer();

			var promises = [];
			$q.all(promises)
				.then(function () {
					_isInitialized = true;
					initDefer.resolve();
				}, function (failure) {
					_isInitialized = false;
					initDefer.reject(failure);
				})
				.finally(function () {
					initDefer = null;
				});

			return initDefer.promise;
		}

		function reset() {
			_isInitialized = false;
			_spoolStats = undefined;
			spoolStatsInitialized = false;
			_messageStats = undefined;
			messageStatsInitialized = false;
			vm.currentSection = "OVERVIEW";
		}

		var getSpoolStatsDefer = null;
		function getSpoolStats() {
			if (spoolStatsInitialized)
				return $q.when(_spoolStats);

			if (getSpoolStatsDefer)
				return getSpoolStatsDefer.promise;

			getSpoolStatsDefer = $q.defer();

			loadSpoolStats()
				.then(function () {
					getSpoolStatsDefer.resolve(_spoolStats);
				}, function () {
					getSpoolStatsDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_SPOOL_STATS");
				})
				.finally(function () {
					getSpoolStatsDefer = null;
				});

			return getSpoolStatsDefer.promise;
		}

		function loadMessageStats() {
			return $http.get("~/api/v1/settings/sysadmin/message-traffic-statistics")
				.then(function (success) {
					_messageStats = success.data.stats;
					messageStatsInitialized = true;
				}, function () {
					_messageStats = undefined;
					messageStatsInitialized = false;
				});
		}

		var getMessageStatsDefer = null;
		function getMessageStats() {
			if (messageStatsInitialized)
				return $q.when(_messageStats);

			if (getMessageStatsDefer)
				return getMessageStatsDefer.promise;

			getMessageStatsDefer = $q.defer();

			loadMessageStats()
				.then(function () {
					getMessageStatsDefer.resolve(_messageStats);
				}, function () {
					getMessageStatsDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_MESSAGES_STATS");
				})
				.finally(function () {
					getMessageStatsDefer = null;
				});

			return getMessageStatsDefer.promise;
		}

		function refreshMessageStats() {
			messageStatsInitialized = false;
			return $q.when(getMessageStats());
		}

		var initialSpoolLoadDefer = null;
		function initialSpoolLoad() {
			if (initialSpoolLoadDefer)
				return initialSpoolLoadDefer.promise;

			initialSpoolLoadDefer = $q.defer();

			var promises = [];
			promises.push(loadSpoolStats());
			$q.all(promises)
				.then(function () {
					spoolDataLoaded = true;
					initialSpoolLoadDefer.resolve();
				}, function () {
					spoolDataLoaded = false;
					initialSpoolLoadDefer.reject();
				})
				.finally(function () {
					initialSpoolLoadDefer = null;
				});

			return initialSpoolLoadDefer.promise;
		}

		function loadSpoolStats() {
			var params = JSON.stringify({
				type: [vm.spoolStatTypes.OutboundSenders, vm.spoolStatTypes.OutboundIPAddresses, vm.spoolStatTypes.InboundRecipients, vm.spoolStatTypes.InboundSenders, vm.spoolStatTypes.InboundDomain, vm.spoolStatTypes.InboundIPAddresses],
				timeframes: ["0.00:05:00", "0.01:00:00", "1.00:00:00"],
				amount: spoolRowsCount
			});
			return $http.post("~/api/v1/settings/sysadmin/spool-stats", params)
				.then(function (success) {
					if (success.data.statInfo) {
						var typeKeys = Object.keys(success.data.statInfo);
						for (var i = 0; i < typeKeys.length; ++i) {
							var tKey = typeKeys[i];
							var keys = Object.keys(success.data.statInfo[tKey]);
							var j, key;
							switch (tKey) {
								case "outBoundSenders":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_outboundSenders[key]) {
											_outboundSenders[key] = {};
										}
										_outboundSenders[key].OutboundSenders = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								case "outboundIPAddresses":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_outboundIpAddress[key]) {
											_outboundIpAddress[key] = {};
										}
										_outboundIpAddress[key].OutboundIPAddresses = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								case "inboundRecipients":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_inboundRecipients[key]) {
											_inboundRecipients[key] = {};
										}
										_inboundRecipients[key].InboundRecipients = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								case "inboundSenders":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_inboundSenders[key]) {
											_inboundSenders[key] = {};
										}
										_inboundSenders[key].InboundSenders = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								case "inboundDomain":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_inboundDomains[key]) {
											_inboundDomains[key] = {};
										}
										_inboundDomains[key].InboundDomains = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								case "inboundIPAddresses":
									for (j = 0; j < keys.length; ++j) {
										key = keys[j];
										if (!_inboundIpAddress[key]) {
											_inboundIpAddress[key] = {};
										}
										_inboundIpAddress[key].InboundIPAddresses = { 5: success.data.statInfo[tKey][key][0].amount, 1: success.data.statInfo[tKey][key][1].amount, 24: success.data.statInfo[tKey][key][2].amount, enabled: success.data.statInfo[tKey][key][0].enabled };
									}
									break;
								default:
									break;
							}
						}
					} else {
						resetSpoolStats();
					}
				}, function () {
					resetSpoolStats();
				});

			function resetSpoolStats() {
				_outboundSenders = {};
				_outboundIpAddress = {};
				_inboundRecipients = {};
				_inboundSenders = {};
				_inboundIpAddress = {};
				_inboundDomains = {};
			}
		}

		var reloadSpoolStatsDefer = null;
		function reloadSpoolStats() {
			if (reloadSpoolStatsDefer)
				return reloadSpoolStatsDefer.promise;

			reloadSpoolStatsDefer = $q.defer();

			loadSpoolStats()
				.then(function () {
					reloadSpoolStatsDefer.resolve();
				}, function () {
					reloadSpoolStatsDefer.reject();
				})
				.finally(function () {
					reloadSpoolStatsDefer = null;
				});

			return reloadSpoolStatsDefer.promise;
		}

		var getOutboundSenderDefer = null;
		function getOutboundSenders() {
			if (spoolDataLoaded)
				return $q.when(_outboundSenders);

			if (getOutboundSenderDefer)
				return getOutboundSenderDefer.promise;

			getOutboundSenderDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getOutboundSenderDefer.resolve(_outboundSenders);
				}, function () {
					getOutboundSenderDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_OUTBOUND_SENDERS");
				})
				.finally(function () {
					getOutboundSenderDefer = null;
				});
			return getOutboundSenderDefer.promise;
		}

		var getOutboundIpAddressesDefer = null;
		function getOutboundIPAddresses() {
			if (spoolDataLoaded)
				return $q.when(_outboundIpAddress);

			if (getOutboundIpAddressesDefer)
				return getOutboundIpAddressesDefer.promise;

			getOutboundIpAddressesDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getOutboundIpAddressesDefer.resolve(_outboundIpAddress);
				}, function () {
					getOutboundIpAddressesDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_OUTBOUND_IP_ADDRESSES");
				})
				.finally(function () {
					getOutboundIpAddressesDefer = null;
				});

			return getOutboundIpAddressesDefer.promise;
		}

		var getInboundRecipientsDefer = null;
		function getInboundRecipients() {
			if (spoolDataLoaded)
				return $q.when(_inboundRecipients);

			if (getInboundRecipientsDefer)
				return getInboundRecipientsDefer.promise;

			getInboundRecipientsDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getInboundRecipientsDefer.resolve(_inboundRecipients);
				}, function () {
					getInboundRecipientsDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_INBOUND_RECIPIENTS");
				})
				.finally(function () {
					getInboundRecipientsDefer = null;
				});

			return getInboundRecipientsDefer.promise;
		}

		var getInboundSendersDefer = null;
		function getInboundSenders() {
			if (spoolDataLoaded)
				return $q.when(_inboundSenders);

			if (getInboundSendersDefer)
				return getInboundSendersDefer.promise;

			getInboundSendersDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getInboundSendersDefer.resolve(_inboundSenders);
				}, function () {
					getInboundSendersDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_INBOUND_SENDERS");
				})
				.finally(function () {
					getInboundSendersDefer = null;
				});

			return getInboundSendersDefer.promise;
		}

		var getInboundIpAddressesDefer = null;
		function getInboundIPAddresses() {
			if (spoolDataLoaded)
				return $q.when(_inboundIpAddress);

			if (getInboundIpAddressesDefer)
				return getInboundIpAddressesDefer.promise;

			getInboundIpAddressesDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getInboundIpAddressesDefer.resolve(_inboundIpAddress);
				}, function () {
					getInboundIpAddressesDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_INBOUND_IP_ADDRESSES");
				})
				.finally(function () {
					getInboundIpAddressesDefer = null;
				});

			return getInboundIpAddressesDefer.promise;
		}

		var getInboundDomainsDefer = null;
		function getInboundDomains() {
			if (spoolDataLoaded)
				return $q.when(_inboundDomains);

			if (getInboundDomainsDefer)
				return getInboundDomainsDefer.promise;

			getInboundDomainsDefer = $q.defer();

			vm.initialSpoolLoad
				.then(function () {
					getInboundDomainsDefer.resolve(_inboundDomains);
				}, function () {
					getInboundDomainsDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_INBOUND_DOMAINS");
				})
				.finally(function () {
					getInboundDomainsDefer = null;
				});

			return getInboundDomainsDefer.promise;
		}

		var getReverseIpsDefer1 = null;
		var getReverseIpsDefer2 = null;
		function getReverseIPs(ips, type) {
            var getReverseIpsDefer = type == "in" ? getReverseIpsDefer1 : getReverseIpsDefer2;

			if (getReverseIpsDefer) {
                return getReverseIpsDefer.promise;
            }
			getReverseIpsDefer = $q.defer();

			var tempIps = [];
			var promises = [];
			if (!vm.isIPListValid) {
				reverseIPs = {};
				vm.isIPListValid = true;
			}
			angular.forEach(ips, function (ip) {
				if (reverseIPs[ip]) {
					var obj = {};
					obj[ip] = reverseIPs[ip];
					promises.push($q.when({ data: { domains: obj } }));
				} else if (!requestedIPs[ip]) {
					tempIps.push(ip);
					requestedIPs[ip] = true;
				}
			});
			if (tempIps.length > 0) {
				var params = JSON.stringify({ ips: tempIps });
				promises.push($http.post("~/api/v1/settings/sysadmin/reverse-dns", params));
			}
			$q
				.all(promises)
				.then(function (success) {
					angular.forEach(success,
						function (result) { 
							var keys = Object.keys(result.data.domains);
							for (var i = 0; i < keys.length; ++i) {
								reverseIPs[keys[i]] = result.data.domains[keys[i]];
							}
						});
					getReverseIpsDefer.resolve(reverseIPs);
				}, function () {
					/*getReverseIpsDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_IPS");*/
				})
				.finally(function () {
                    if (type == "in")
                        getReverseIpsDefer1 = null;
                    else
                        getReverseIpsDefer2 = null;
					getReverseIpsDefer = null;
				});

            if (type == "in")
				getReverseIpsDefer1 = getReverseIpsDefer;
            else
				getReverseIpsDefer2 = getReverseIpsDefer;
			return getReverseIpsDefer.promise;
		}

		var getUsersDefer = null;
		function getUsers(addresses) {
			if (getUsersDefer)
				return getUsersDefer.promise;

			getUsersDefer = $q.defer();

			var promises = [];
			if (!vm.isUserListValid) {
				userList = {};
				vm.isUserListValid = true;
			}
			angular.forEach(addresses, function (address) {
				if (userList[address]) {
					var obj = {};
					obj[address] = userList[address];
					promises.push($q.when({ data: { userData: obj } }));
				} else {
					promises.push($http.post("~/api/v1/settings/sysadmin/get-user", JSON.stringify({ email: address })));
				}
			});
			$q.all(promises)
				.then(function (success) {
					angular.forEach(success,
						function (result, index) {
							if (result.data.userData) {
								userList[result.data.userData.emailAddress] = result.data.userData;
							} else {
								userList[addresses[index]] = undefined;
							}
						});
					getUsersDefer.resolve(userList);
				}, function () {
					getUsersDefer.resolve(userList);
				})
				.finally(function () {
					getUsersDefer = null;
				});

			return getUsersDefer.promise;
		}

		function updateUser(user) {
			userList[user.emailAddress] = user;
		}

		function loadSmtpBlocks() {
			return $http.get("~/api/v1/settings/sysadmin/smtp-block-rules")
				.then(function (success) {
					if (success.data.blockRules) {
						_smtpBlocks = success.data.blockRules;
					} else {
						_smtpBlocks = [];
					}
					smtpBlocksInitialized = true;
				}, function () {
					_smtpBlocks = [];
					smtpBlocksInitialized = false;
				});
		}

		var getSmtpBlocksDefer = null;
		function getSmtpBlocks() {
			if (smtpBlocksInitialized)
				return $q.when(_smtpBlocks);

			if (getSmtpBlocksDefer)
				return getSmtpBlocksDefer.promise;

			getSmtpBlocksDefer = $q.defer();

			loadSmtpBlocks()
				.then(function () {
					getSmtpBlocksDefer.resolve(_smtpBlocks);
				}, function () {
					getSmtpBlocksDefer.reject("SPOOL_ERRORS_FAILED_TO_LOAD_SMTP_BLOCKS");
				})
				.finally(function () {
					getSmtpBlocksDefer = null;
				});

			return getSmtpBlocksDefer.promise;
		}

		var forceMessageDefer = null;
		function forceMessage(rows) {
			if (forceMessageDefer)
				return forceMessageDefer.promise;

			forceMessageDefer = $q.defer();

			var toForce = [];
			angular.forEach(rows, function (message) {
				toForce.push({ fileName: message.fileName, spoolName: message.spoolName });
			});
			var params = JSON.stringify({ spoolInput: toForce });
			$http.post("~/api/v1/settings/sysadmin/push-spool-message", params)
				.then(function () {
					forceMessageDefer.resolve();
				}, function (failure) {
					forceMessageDefer.reject(failure.data.message);
				})
				.finally(function () {
					forceMessageDefer = null;
				});

			return forceMessageDefer.promise;
		}

		var refreshRetriesDefer = null;
		function refreshRetries(rows) {
			if (refreshRetriesDefer)
				return refreshRetriesDefer.promise;

			refreshRetriesDefer = $q.defer();

			var toReset = [];
			angular.forEach(rows, function (message) {
				toReset.push({ fileName: message.fileName, spoolName: message.spoolName });
			});
			$http.post("~/api/v1/settings/sysadmin/reset-spool-messages", JSON.stringify({ spoolInput: toReset }))
				.then(function (success) {
					refreshRetriesDefer.resolve(success);
				}, function (failure) {
					refreshRetriesDefer.reject(failure.data.message);
				})
				.finally(function () {
					refreshRetriesDefer = null;
				});

			return refreshRetriesDefer.promise;
		}

		var setPriorityDefer = null;
		function setPriority(priority, rows) {
			if (setPriorityDefer)
				return setPriorityDefer.promise;

			setPriorityDefer = $q.defer();

			var toChange = [];
			angular.forEach(rows, function (message) {
				toChange.push({ fileName: message.fileName, spoolName: message.spoolName, priority: priority });
			});
			var params = JSON.stringify({ spoolInput: toChange });
			$http.post("~/api/v1/settings/sysadmin/spool-message-priority", params)
				.then(function () {
					setPriorityDefer.resolve();
				}, function (failure) {
					setPriorityDefer.reject(failure.data.message);
				})
				.finally(function () {
					setPriorityDefer = null;
				});

			return setPriorityDefer.promise;
		}

		var resendMessagesDefer = null;
		function resendMessages(rows) {
			if (resendMessagesDefer)
				return resendMessagesDefer.promise;

			resendMessagesDefer = $q.defer();

			var toResend = [];
			angular.forEach(rows, function (message) {
				toResend.push({ fileName: message.fileName, spoolName: message.spoolName });
			});
			var params = JSON.stringify({ spoolInput: toResend });
			$http.post("~/api/v1/settings/sysadmin/resend-quarantine-messages", params)
				.then(function () {
					resendMessagesDefer.resolve();
				}, function (failure) {
					resendMessagesDefer.reject(failure.data.message);
				})
				.finally(function () {
					resendMessagesDefer = null;
				});

			return resendMessagesDefer.promise;
		}

		var deleteMessagesDefer = null;
		function deleteMessages(rows) {
			if (deleteMessagesDefer)
				return deleteMessagesDefer.promise;

			deleteMessagesDefer = $q.defer();

			var toDelete = [];
			angular.forEach(rows, function (message) {
				toDelete.push({ fileName: message.fileName.replace(".eml", ""), spoolName: message.spoolName, status: message.status, quarantineType: message.quarantineType });
			});
			var deleted = toDelete.length;
			var params = JSON.stringify({ spoolInput: toDelete });
			$http
				.post("~/api/v1/settings/sysadmin/spool-delete-message", params)
				.then(function () {
					deleteMessagesDefer.resolve(deleted);
				}, function (failure) {
					deleteMessagesDefer.reject(failure.data.message);
				})
				.finally(function () {
					deleteMessagesDefer = null;
				});

			return deleteMessagesDefer.promise;
		}

		var deleteAllMessagesDefer = null;
		function deleteAllQuarantineMessagesByType(type) {
			if (deleteAllMessagesDefer)
				return deleteAllMessagesDefer.promise;

			deleteAllMessagesDefer = $q.defer();
			$http
				.post("~/api/v1/settings/sysadmin/spool-delete-all-quarantine-messages/" + type)
				.then(deleteAllMessagesDefer.resolve,
					function(failure) {
						deleteAllMessagesDefer.reject(failure);
					})
				.finally(function() {
					deleteAllMessagesDefer = null;
				});

			return deleteAllMessagesDefer.promise;
		}

		function getViewMessage(baseUrl, row) {
            const packet = emailNavigation.makeSpoolPacket(row.spoolName, row.fileName, !!row.quarantineType);
            const url = emailNavigation.getPopoutUrl(packet);
			return url;
		}

		function changeSection(section) {
			vm.currentSection = section;
		}
	}
})();