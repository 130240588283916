(function () {
    'use strict';

    angular
        .module('smartertools')
        .directive('helpLink', helpLink);

    function helpLink($rootScope, $mdDialog) {
        return {
            restrict: 'EA',
            link: function (scope, element, attributes, controller) {
                scope.helpid = attributes.page;
                scope.openHelp = function (event) {
                    var page = $rootScope.$state.current.name;
                    if (scope.helpid)
                        page = scope.helpid;
                    var version = stProductVersion;
                    const url = "https://help.smartertools.com/smartermail/current/?page=" + encodeURIComponent(page) + "&v=" + version;
                    console.log('NAVIGATING TO HELP PAGE: ' + url);
                    window.open(url, "help");
                };
                scope.cancelDialog = function () {
                    $mdDialog.cancel();
                }

            },
            template:
                "<md-button class='md-icon-button modal-help-link' ng-click='openHelp($event)' aria-label='help'>" +
                "<i class='toolsicon toolsicon-question_mark'></i>" +
                "</md-button>"
            /* + "<md-button class='md-icon-button modal-close-link' ng-click='cancelDialog();' aria-label='close'>" +
                "<i class='toolsicon toolsicon-close_emphasis'></i>" +
            "</md-button>"*/
        };
    }


})();