(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateUsernameOctetLength", validateUsernameOctetLength);

	function validateUsernameOctetLength() {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$validators.validateUsernameOctetLength = function (modelValue, viewValue) {
					viewValue = viewValue ? viewValue.trim() : viewValue;
					let octetCount = getUTF8ByteCount(viewValue);
					if (!viewValue)
						return true;
					return !(octetCount < 0 || octetCount > 64);
				};
			}
		};

		function getUTF8ByteCount(inputString) {
			try {
				const encoder = new TextEncoder();
				const utf8Bytes = encoder.encode(inputString);
				return utf8Bytes.length;

			} catch (err) {
				console.error("validateUsernameOctetLength error: ", err);
				return 0;
			}
		}
	}
})();