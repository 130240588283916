(function () {

    // Below is the original SVG file. Useful if you need to edit it in illustrator.
    // Afterwards, just delete the <style> block and embed style="{{fillStyles}}" to
    // the path, and delete the top two lines

    /*
    <?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <style type="text/css">
                    .st0{fill:#3EB54A; stroke:#000; stroke-width:0.25;stroke-miterlimit:10;}
        </style>
        <g id="outline">
            <path class="st0" d="M11.7,17.6c-0.3,0.3-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4l-7.1-7.1c-0.1-0.1-0.2-0.3-0.3-0.5C2,9.7,2,9.5,2,9.4
                        V3.5c0-0.4,0.1-0.8,0.4-1.1C2.7,2.1,3.1,2,3.5,2h5.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.5,0.3l7.1,7.1c0.3,0.3,0.4,0.6,0.4,1.1
                        c0,0.4-0.1,0.8-0.4,1.1L11.7,17.6z M5.5,6.8c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9S6.6,4.9,6.4,4.6
                        C6.1,4.4,5.8,4.2,5.5,4.2S4.9,4.4,4.6,4.6C4.4,4.9,4.2,5.2,4.2,5.5s0.1,0.6,0.4,0.9C4.9,6.6,5.2,6.8,5.5,6.8z"/>
        </g>
    </svg>  
    */

    angular
        .module('smartertools')
        .component('stCategoryFlag', {
            template: `
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                <g>
	                <path style="{{$ctrl.fillStyles}}" d="M11.7,17.6c-0.3,0.3-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4l-7.1-7.1c-0.1-0.1-0.2-0.3-0.3-0.5C2,9.7,2,9.5,2,9.4
		                V3.5c0-0.4,0.1-0.8,0.4-1.1C2.7,2.1,3.1,2,3.5,2h5.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.5,0.3l7.1,7.1c0.3,0.3,0.4,0.6,0.4,1.1
		                c0,0.4-0.1,0.8-0.4,1.1L11.7,17.6z M5.5,6.8c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9S6.6,4.9,6.4,4.6
		                C6.1,4.4,5.8,4.2,5.5,4.2S4.9,4.4,4.6,4.6C4.4,4.9,4.2,5.2,4.2,5.5s0.1,0.6,0.4,0.9C4.9,6.6,5.2,6.8,5.5,6.8z"/>
                </g>
                </svg>
                `,
            controller: stCategoryFlag,
            bindings: {
                flagColor: "<",
            }
        });

    /* @ngInject */
    function stCategoryFlag() {
        let vm = this;
        let lastColor = -1;

        vm.$onInit = () => recalculate();
        vm.$onChanges = (changesObj) => recalculate();

        function recalculate() {
            if (lastColor === vm.flagColor)
                return;
            if (vm.flagColor === undefined || vm.flagColor === null || vm.flagColor === '' || vm.flagColor === 'transparent') {
                vm.fillStyles = `fill: transparent; stroke: #888888; stroke-dasharray: 2,2; stroke-width:1; stroke-miterlimit:10;`;
            } else {
                let pillBrightness = brightnessLevelForColor(vm.flagColor || '#888888');
                let strokeColor = (pillBrightness && pillBrightness < 0.2)
                    ? "#cccccc"
                    : "#000000";
                vm.fillStyles = `fill: ${vm.flagColor}; stroke: ${strokeColor}; stroke-width:0.25; stroke-miterlimit:10;`;
            }

            lastColor = vm.flagColor;
        }
    }

    window.getCategoryFlagHtml = function (flagColor) {
        let pillBrightness = brightnessLevelForColor(flagColor || '#888888');
        let strokeColor = (pillBrightness && pillBrightness < 0.2)
            ? "#cccccc"
            : "#000000";
        let fillStyles = `fill: ${flagColor}; stroke: ${strokeColor}; stroke-width:0.25; stroke-miterlimit:10;`;
        if (flagColor === undefined || flagColor === null || flagColor === '') {
            fillStyles = `fill: transparent; stroke: #888888; stroke-dasharray: 2,2; stroke-width:1; stroke-miterlimit:10;`;
        }

        return `
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
                <g>
	                <path style="${fillStyles}" d="M11.7,17.6c-0.3,0.3-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4l-7.1-7.1c-0.1-0.1-0.2-0.3-0.3-0.5C2,9.7,2,9.5,2,9.4
		                V3.5c0-0.4,0.1-0.8,0.4-1.1C2.7,2.1,3.1,2,3.5,2h5.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.5,0.3l7.1,7.1c0.3,0.3,0.4,0.6,0.4,1.1
		                c0,0.4-0.1,0.8-0.4,1.1L11.7,17.6z M5.5,6.8c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9S6.6,4.9,6.4,4.6
		                C6.1,4.4,5.8,4.2,5.5,4.2S4.9,4.4,4.6,4.6C4.4,4.9,4.2,5.2,4.2,5.5s0.1,0.6,0.4,0.9C4.9,6.6,5.2,6.8,5.5,6.8z"/>
                </g>
                </svg>
                `;
    }

    function brightnessLevelForColor(hexcolor) {
        try {
            var r = parseInt(hexcolor.substr(1, 2), 16);
            var g = parseInt(hexcolor.substr(3, 2), 16);
            var b = parseInt(hexcolor.substr(5, 2), 16);
            return brightnessLevel(r, g, b);
        } catch (err) {
            return null;
        }
    }

    function brightnessLevel(r, g, b) {
        try {
            var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
            return (yiq > 256) ? 1 : (yiq / 256);
        } catch (err) {
            return null;
        }
    }

})();