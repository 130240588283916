(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminImpersonateUserController", sysAdminImpersonateUserController);

	function sysAdminImpersonateUserController($mdDialog, $http, $translate, $rootScope, $state, errorHandling, impersonationService, coreDataManageDomain, domain, email) {

		var vm = this;
		vm.email = email || "";
		vm.failedEmail = "";
		vm.selectedDomain = domain || "";
		vm.userOptions = undefined;

		activate();

		function activate() {
			$http.get("~/api/v1/settings/sysadmin/internationalized-domain-names")
				.then(function (result) {
					vm.domainOptions = angular.copy(result.data.data);
					vm.domainOptions.unshift({ asciiName: $translate.instant("ALL") });

					var selectedName;
					if ($state.$current && $state.$current.name && $state.$current.name.startsWith("index.sysadmin-manage.dashboard.domain-specific") && !vm.selectedDomain && $rootScope.secondaryImpersonationDomain)
						selectedName = $rootScope.secondaryImpersonationDomain;

					// See if there's a match to the input
					var itemFound = _.find(vm.domainOptions, function (s) { return s.asciiName == selectedName; });
					if (itemFound == undefined)
						vm.selectedDomain = vm.domainOptions[0];
					else {
						vm.selectedDomain = itemFound;
						setTimeout(function() {
							document.querySelector("#impersonateUserAutocompleteBox").focus();
						}, 0);
					}

				}, errorHandling.report);
		}
		vm.customSortFunction = function(domain) {
			return domain.idnName || domain.asciiName;
		};

		vm.impersonate = function (form) {
			var isAnyDomain = vm.selectedDomain === vm.domainOptions[0];
			if (isAnyDomain && (!form || !form.email || !form.email.$valid))
				return;

			if (coreDataManageDomain.impersonatingWindow != undefined) {
				coreDataManageDomain.impersonatingWindow.close();
				coreDataManageDomain.impersonatingWindow = undefined;
			}

			var email = (vm.email && vm.email.item1) ? vm.email.item1 : vm.email;
			impersonationService.impersonateUser(email);
			$mdDialog.hide();
		};

		vm.emailChanged = function (form) {
			if (form) {
				if (vm.failedEmail !== "" && vm.failedEmail === vm.email.item1) {
					form.email.$setValidity("validateEmailExistence", false);
				}
				else {
					form.email.$setValidity("validateEmailExistence", true);
				}
			}
		};

		vm.queryUsers = function (searchText) {
			if (searchText.length > 0 && !searchText.endsWith(".")) {
				return $http.post("~/api/v1/settings/sysadmin/search-impersonation-users", { domain: vm.selectedDomain.asciiName, search: searchText })
					.then(function (result) {
						return result.data.data;
					}, errorHandling.report)
					.catch(function () {
						return [];
					});
			}

			return [];
		};

		vm.cancel = function () {
			$mdDialog.cancel();
		};

		vm.changeDomain = function changeDomain() {
			vm.email = "";
			vm.failedEmail = "";
			vm.userOptions = undefined;
		}
	}

})();