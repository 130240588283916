(function () {
    'use strict';

    angular
        .module('smartertools')
        .directive('validateEmailAliasDoesNotExist', validateEmailAliasDoesNotExist);

    function validateEmailAliasDoesNotExist($http, $q) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$asyncValidators.validateEmailAliasDoesNotExist = function (modelValue, viewValue) {
                    if (!viewValue || ctrl.$pristine) return $q.when(true);
                    var defer = $q.defer();
                    $http.get('~/api/v1/settings/domain/email-alias-does-not-exists/' + viewValue + '/')
                        .then(function (success) {
                            defer.resolve();
                        }, function (failure) {
                            defer.reject(failure);
                        });

                    return defer.promise;
                };
            }
        };
    }

})();