(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("htmlSandboxing", htmlSandboxing);

    function htmlSandboxing() {
        let vm = this;
        
        // Functions
        vm.parseCss = parseCss;
        
        /////////////////////

        // Creates new styles from an included style element to append 
        // the html editor element if the styles exist so that an event's 
        // custom css for a description doesn't affect and override our styles.
        function parseCss(htmlText) {
            const PARSER = new DOMParser();
            const HTML_EDITOR = "st-html-editor";
            let documentObject = PARSER.parseFromString(htmlText, 'text/html');
            let updatedHtml = htmlText;

            if (documentObject) {
                // Grab all style elements to iterate on
                let styleElements = documentObject.querySelectorAll("style");

                if (styleElements) {
                    // Begin iterating and creating new styles to replace the embedded ones
                    for(let styleElement of styleElements) {
                        let newStyleElement = documentObject.createElement("style");
                        let cssRules = styleElement.sheet.cssRules;

                        // For loop to append the html editor element and then add them to the new style element
                        // Also nested to support MediaRule queries
                        for (let rule of cssRules) {
                            if (rule.constructor.name === "CSSMediaRule")
                                for (let mediaRule of rule.cssRules)
                                    appendEditorToCssRule(mediaRule, HTML_EDITOR);
                            else
                                appendEditorToCssRule(rule, HTML_EDITOR);

                            newStyleElement.innerHTML += rule.cssText + "\n";
                        }

                        documentObject.body.removeChild(styleElement);
                        documentObject.head.appendChild(newStyleElement);
                    }
                }

                updatedHtml = documentObject.head.innerHTML + documentObject.body.innerHTML;
            }

            return updatedHtml;

            // Manipulate selectorText property of the CSS Rule object so that it applies to
            // the html editor
            function appendEditorToCssRule(rule, htmlEditor) {
                const selectors = rule.selectorText.split(",").map(selector => `${htmlEditor} ${selector.trim()}`);
                rule.selectorText = selectors.join(", ");
            }
        }
    }
})();