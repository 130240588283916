//(function () {
//	"use strict";

//	// Processes Enter Key commands on input controls. 

//	// Usage Example:
//	// <md-tabs st-dialog-tabs>...

//	// ReSharper disable once UndeclaredGlobalVariableUsing
//	angular
//		.module("smartertools")
//		.directive("stDialogTabs", stDialogTabs);

//	function stDialogTabs($window, $log) {
//		return function (scope, element, attrs) {
//			$(element).addClass('st-dialog-tabs');

//			var w = angular.element($window);
//			$log.log(w.height());
//			element.css("min-height", Math.min(200, w.height() * 0.6));

//			scope.$watch(function () {
//				return {
//					'h': w.height(),
//					'w': w.width()
//				};
//			}, function (newValue, oldValue) {
//				scope.windowHeight = newValue.h;
//				scope.windowWidth = newValue.w;
//				$log.log(scope.windowHeight);
//				element.css("min-height", Math.min(200, scope.windowHeight * 0.6));
//			}, true);

//			w.bind('resize', function () {
//				scope.$applyAsync();
//			});
//		};
//	}

//})();