(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedMailingListModeratorSettingsController", sharedMailingListModeratorSettingsController);

	function sharedMailingListModeratorSettingsController($state, $rootScope, $scope, $http, $timeout, $filter, $mdDialog, $q, $translate,
		errorHandling, successHandling, coreDataDomainSettings, coreDataSettings, claimsService, coreLicensing) {

		var vm = this;
		vm.canManageMailingLists = claimsService.isSysAdmin() || claimsService.isDomainAdmin();
		vm.isEnterprise = false;

		vm.data = {};
		vm.mailingListId = $state.params.mailingListId;
		vm.listFromOption = "LISTADDRESS";
		vm.listFromAddress = "";
		vm.listReplyToOption = "DONOTSET";
		vm.listReplyToAddress = "";
		vm.originalDoubleOpt = false;
		vm.allowAnyoneToPost = false;
		vm.allowSubscribersToPost = false;

		// Functions
		vm.save = save;
		vm.sendDigestNow = sendDigestNow;
		vm.queryUsers = queryUsers;

		activate();

		/////////////////////////////

		function activate() {
			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);
			loadMailingList();
		}

		function recalculateLicense() {
			vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;
		}

		function loadMailingList() {
			var promises = [
				$http.get(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/settings`)
			];
			if (vm.canManageMailingLists) {
				promises.push(coreDataDomainSettings.init());
			}

			$q
				.all(promises)
				.then(onPromisesReady, onPromisesFailure);

			function onPromisesReady(success) {
				vm.data = success[0].data.item;
				vm.listFromOption = vm.data.listFromAddress;
				if (vm.listFromOption !== "LISTADDRESS" && vm.listFromOption !== "POSTERADDRESS") {
					vm.listFromAddress = vm.data.listFromAddress;
					vm.listFromOption = "SPECIFICADDRESS";
				}
				vm.listReplyToOption = vm.data.listReplyToAddress;
				if (vm.listReplyToOption !== "LISTADDRESS" &&
					vm.listReplyToOption !== "POSTERADDRESS" &&
					vm.listReplyToOption !== "DONOTSET") {
					vm.listReplyToAddress = vm.data.listReplyToAddress;
					vm.listReplyToOption = "SPECIFICADDRESS";
				}
				vm.allowAnyoneToPost = vm.data.postingPermissions === 0;
				vm.allowSubscribersToPost = vm.data.postingPermissions <= 1;

				vm.originalDoubleOpt = vm.data.doubleOptIn;
				vm.listAddress = vm.data.listAddress + "@" + ($rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain);
				vm.listCommandAddress = vm.data.listCommandAddress;

				if (vm.data.enableDigest && (!vm.data.digestSubject || vm.data.digestSubject.length === 0)) {
					vm.data.digestSubject = $translate.instant("DIGEST");
					vm.pageForm.$setDirty();
				}
				else
					$timeout(pristineForm, 200, false);

				$scope.$watch(function () { return vm.allowAnyoneToPost; }, function (newValue, oldValue) {
					if (newValue === oldValue) return;
					if (newValue === true)
						vm.allowSubscribersToPost = true;
				});
			}

			function onPromisesFailure(failure) {
				if (!vm.canManageMailingLists) {
					const isListModerator = coreDataSettings.userPermissions.isListModerator;
					if (isListModerator)
						$state.go("index.settings.mailing-lists");
					else
						$state.go("index.settings.user-account-settings");
					return;
				}
				errorHandling.report(failure);
			}
		}

		function pristineForm() {
			if (!vm.pageForm) {
				$timeout(pristineForm, 20, false);
				return;
			}
			vm.pageForm.$setPristine();
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			vm.data.listFromAddress = vm.listFromOption === "SPECIFICADDRESS"
				? vm.listFromAddress
				: vm.listFromOption;

			vm.data.listReplyToAddress = vm.listReplyToOption === "SPECIFICADDRESS"
				? vm.listReplyToAddress
				: vm.listReplyToOption;

			if (!vm.allowSubscribersToPost && !vm.allowAnyoneToPost)
				vm.data.postingPermissions = 2;
			else
				vm.data.postingPermissions = !vm.allowAnyoneToPost ? 1 : 0;

			var params = {
				password: vm.data.password,
				postingPermissions: vm.data.postingPermissions,
				subject: vm.data.subject,
				listToAddress: vm.data.listToAddress,
				listFromAddress: vm.data.listFromAddress,
				listReplyToAddress: vm.data.listReplyToAddress,
				unsubscribeText: vm.data.unsubscribeText,
				enableDigest: vm.data.enableDigest,
				sendSubscribeEmail: vm.data.sendSubscribeEmail,
				sendUnsubscribeEmail: vm.data.sendUnsubscribeEmail,
				disableSubscriptions: vm.data.disableSubscriptions,
				doubleOptIn: vm.data.doubleOptIn,
				disableListErrorReplies: vm.data.disableListErrorReplies,
				digestSubject: vm.data.digestSubject,
				digestSendTriggerType: vm.data.digestSendTriggerType,
				digestSendTriggerSize: vm.data.digestSendTriggerSize,
				digestSendType: vm.data.digestSendType,
				digestStripNonTextAttachments: vm.data.digestStripNonTextAttachments,
				baseUrl: vm.data.baseUrl
			};

			$http.post(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/settings`, JSON.stringify(params))
				.then(function () {
					form.$setPristine();
					$rootScope.$broadcast("domain-settings:changed");
					if (vm.originalDoubleOpt && !vm.data.doubleOptIn)
						$rootScope.$broadcast("mailing-list:counts-changed");
				}, errorHandling.report);
		}

		function sendDigestNow(event) {
			const confirm = $mdDialog.confirm()
				.title($filter("translate")("SEND_DIGEST"))
				.ok($filter("translate")("SEND"))
				.cancel($filter("translate")("CANCEL"))
				.textContent($filter("translate")("MAILING_LISTS_SEND_DIGEST_CONFIRMATION"))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doSend, function() {});

			function doSend() {
				$http
					.post(`~/api/v1/settings/domain/mailing-lists/${vm.mailingListId}/send-digest`)
					.then(successHandling.report, errorHandling.report);
			}
		}

		function queryUsers(searchText) {
			if (searchText.length > 0) {
				return $http
					.get(`~/api/v1/settings/domain/list-usernames/?usersToReturn=50&includeDomain=true&search=${searchText}`)
					.then(function (result) { return result.data.usernames; }, errorHandling.report)
					.catch(function () { return []; });
			}

			return $q.when([]);
		}
	}

})();
