(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("errorMessageService", errorMessageService);

    function errorMessageService($filter, errorHandling) {
        var service = {};
        service.showErrorMessage = showErrorMessage;
        service.isServiceDown = isServiceDown;
        service.showErrorMessageFromString = showErrorMessageFromString;
        return service;

        function isServiceDown(failure) {
            var isServerDown = false;
            if (failure.data.message.indexOf('No connection could be made because the target machine actively refused it') > -1 ||
                failure.data.message.indexOf("An existing connection was forcibly closed by the remote host") > -1 ||
                failure.data.message.indexOf('EndpointNotFoundException') > -1 ||
                failure.data.message.indexOf('CommunicationObjectFaultedException') > -1 ||
                failure.status === -1) {
                isServerDown = true;
            }
            return isServiceDown;
        }

        function showErrorMessage(failure) {
            var isServerDown = false;
            if (failure.data.message.indexOf('No connection could be made because the target machine actively refused it') > -1 ||
                failure.data.message.indexOf("An existing connection was forcibly closed by the remote host") > -1 ||
                failure.data.message.indexOf('EndpointNotFoundException') > -1 ||
                failure.data.message.indexOf('CommunicationObjectFaultedException') > -1 ||
                failure.status === -1) {
                isServerDown = true;
            }
            if (isServerDown) {
                errorHandling.report($filter('translate')('LOGIN_PAGE_MAINTENANCE_BODY'));
            } else if (failure.data && failure.data.message) {
                errorHandling.report(failure.data.message);
            } else {
                errorHandling.report();
            }
        }

        function showErrorMessageFromString(message) {
            var isServerDown = false;
            if (message.indexOf('No connection could be made because the target machine actively refused it') > -1 ||
                message.indexOf("An existing connection was forcibly closed by the remote host") > -1 ||
                message.indexOf('EndpointNotFoundException') > -1 ||
                message.indexOf('CommunicationObjectFaultedException') > -1) {
                isServerDown = true;
            }
            if (isServerDown) {
                errorHandling.report($filter('translate')('LOGIN_PAGE_MAINTENANCE_BODY'));
            } else if (message) {
                errorHandling.report(message);
            } else {
                errorHandling.report();
            }
        }
    }
})();