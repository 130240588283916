(function () {

    angular
        .module("smartertools")
        .filter("folderTranslate", folderTranslate);

    function folderTranslate(folderNameTranslator) {

        return function (folderName, owner) {
            return folderNameTranslator.fromNameAndOwner(folderName, owner);
        };
    }

})();
