(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("taskOutlookDialogController", taskOutlookDialogController);

    function taskOutlookDialogController($scope, $mdDialog, $filter, coreDataTasks) {
        $scope.outlookVersionOptions = [];
        $scope.outlookVersionOptions.push({ version: "3.0", name: $filter("translate")("OUTLOOK_2007_OR_LATER") });
        $scope.outlookVersionOptions.push({ version: "1.0", name: $filter("translate")("OUTLOOK_2003") });
        $scope.currentVersion = $scope.outlookVersionOptions[0];
        $scope.sources = coreDataTasks.getSources().filter(folder => !folder.isDomainResource);
        $scope.currentSource = $scope.sources[0];

        $scope.done = function () {
            var metaData = {
                "outlookVersion": $scope.currentVersion.version,
                "source": $scope.currentSource,
                "displayName": $scope.displayName
            };
            $mdDialog.hide(metaData);
        }.bind(this);

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }

})();
