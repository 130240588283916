(function (undefined) {
	'use strict';

	angular
		.module("smartermail")
		.factory("siteRootPathInterceptor", siteRootPathInterceptor);

	/* @ngInject */
	function siteRootPathInterceptor($log) {
		return {
			request: function (config) {
				if (config && config.url && config.url.indexOf("~/") == 0) {
					var before = config.url;
					config.url = stSiteRoot + config.url.substr(2);
				}
				return config;
			}
		}
	}


})();