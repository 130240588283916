(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fsEditFolderController", fsEditFolderController);

	function fsEditFolderController($scope, $mdDialog, folderInfo) {
		$scope.folderInfo = $.extend(true, {}, folderInfo);
		$scope.name = $scope.folderInfo.name;

		var oldInfo = {};
		$scope.oldName = folderInfo.name;
		$scope.oldPath = folderInfo.path;

		$scope.save = function () {
			$mdDialog.hide({
				folderInfo: {
					count: $scope.folderInfo.count,
					files: $scope.folderInfo.files,
					key: $scope.folderInfo.key,
					name: $scope.name,
					path: $scope.folderInfo.path,
					size: $scope.folderInfo.size,
					subFolders: $scope.folderInfo.subFolders
				},
				oldInfo: {
					oldName: $scope.oldName,
					oldPath: $scope.oldPath
				}
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
