(function () {
    "use strict";

    angular
        .module("smartermail")
		.controller("foundTrackersController", foundTrackersController);
    function foundTrackersController($scope, $mdDialog, $http, coreDataSettings, errorHandling, message) {
		$scope.controller = this;
        $scope.message = message;

        $scope.close = function () {
            var whitelistedTrackers = message.foundTrackers.filter((ft) => {
                if (ft.allowed) {
                    coreDataSettings.userSettings.remoteTrackersExceptions.push(ft.id);
                    ft.revert();
                    return true;
                }
                return false;
            });
            if (coreDataSettings.userSettings.remoteTrackersExceptions.length > 0) {
                var params = {
                    userMailSettings: {
                        remoteTrackersExceptions: coreDataSettings.userSettings.remoteTrackersExceptions
                    }
                };
                $http.post("~/api/v1/settings/user-mail", JSON.stringify(params))
                    .then(() => {
                        //success
                    }, errorHandling.report);
            }

            $mdDialog.cancel();
        };
    }
})();