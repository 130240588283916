(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedUploadMailListPostersController", sharedUploadMailListPostersController);

	function sharedUploadMailListPostersController($scope, $mdDialog, $filter, $translate, toaster, options, errorHandling) {
	    var vm = this;

	    vm.fileCount = 0;
        $scope.subscriberType = options.subscriberType;
        $scope.filterListID = options.filterListID;
        $scope.uploaderData = JSON.stringify(options);
		$scope.isValid = false;
		$scope.isProcessing = false;

		// Functions
		$scope.uploaderInit = uploaderInit;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.importFile = importFile;
		$scope.uploadFailed = uploadFailed;
		$scope.addedFile = addedFile;
		$scope.removedFile = removedFile;

		activate();

		///////////////////////

		function activate() {
		}

		function uploaderInit(publicFunctions) {
		    $scope.uploaderFunctions = publicFunctions;
		}

		function save() {
			if (!($scope.isValid = vm.fileCount > 0))
				return;
			$scope.isProcessing = true;
			$scope.uploaderFunctions.startUpload();
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function importFile(file, data) {
			if (data.somePostersNotImported)
				toaster.pop("warning", $translate.instant("SOME_POSTERS_NOT_IMPORTED"));
			$scope.isProcessing = false;
			$mdDialog.hide();
		};

		function uploadFailed() {
			errorHandling.report("ERROR_UPLOADING");
			$scope.step = 1;
			$scope.isProcessing = false;
		    $scope.uploaderFunctions.reset();
		}

		function addedFile() {
			$scope.isValid = true;
			$scope.$applyAsync();
			vm.fileCount++;
		};

		function removedFile() {
			vm.fileCount--;
			if (vm.fileCount < 0)
				vm.fileCount = 0;
		};
	}

})();
