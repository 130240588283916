(function($) {
	window.SmarterTools = window.SmarterTools || {};
	window.SmarterTools.touchContext = function (control, selector, handler) {
		if (!handler) {
			if (typeof selector === 'function') {
				handler = selector;
				selector = null;
			}
			else
				return;
		}
		if (typeof handler !== 'function')
			return;

		var $ctl = $(control);
		var touchTimer = null;
		var longTriggered = false;

		$ctl.on('touchstart',
			function(e) {
				resetTimeout();
				if (e.originalEvent.touches.length > 1)
					return;

				var target = this;
				touchTimer = setTimeout(function () {
						timeoutComplete(e, target);
					},
					750);
			});

		$ctl.on('touchmove',
			resetTimeout);

		$ctl.on('touchend',
			function (e) {
				if (longTriggered) {
					e.stopPropagation();
					e.preventDefault();
				}
				resetTimeout();
			});

		function resetTimeout() {
			if (touchTimer) {
				clearTimeout(touchTimer);
				touchTimer = null;
				longTriggered = false;
			}
		}

		function timeoutComplete(e, target) {
			longTriggered = true;
			touchTimer = null;
			handler.call(target, e);
		}
	};
})(jQuery);