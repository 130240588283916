(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecuritySysAdminPassSettingsController", sysAdminSecuritySysAdminPassSettingsController);

	function sysAdminSecuritySysAdminPassSettingsController($rootScope, $scope, $http, $filter, $state, $mdDialog, errorHandling, claimsService) {

		// Functions
		const vm = this;
		vm.save = save;
		vm.doCancel = doCancel;
		vm.canEdit = claimsService.isPrimarySysAdmin();
		activate();

		//////////////////////

		function activate() {
			if (!vm.canEdit) {
				$http.get("~/api/v1/settings/sysadmin/logins")
					.then(function(success) {
							const admin = success.data.systemLogins.find(x => x.username.toLowerCase() ===
								claimsService.getUsername().toLowerCase());
							if (admin) {
								vm.canEdit = admin.manageAdmins;
							}
							
							refresh();
						},
						errorHandling.report);
			} else {
				refresh();
			}
		}

		function refresh() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(onGlobalMailSettingsLoaded, function () { })
				.finally($rootScope.spinner.hide);

			function onGlobalMailSettingsLoaded(result) {
				vm.passwordSettings = result.data.globalMailSettings.sysAdminPasswordRules;
			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			var params = JSON.stringify({
				globalMailSettings: {
					sysAdminPasswordRules: vm.passwordSettings
				}
			});

			$rootScope.spinner.show();
			$http
				.post('~/api/v1/settings/sysadmin/global-mail', params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				$scope.$emit('admin-password-update-count');
				form.$setPristine();
			}
		}
	}

})();
