(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("usersConnectionsController", usersConnectionsController);

    function usersConnectionsController($state, $rootScope, $scope, $http, $filter, coreDataDomainSettings, userDataService) {
        var vm = this;
        vm.isInitialized = false;
        vm.domain = $state.params.id || userDataService.user.domain;
        vm.gridSections = [
            { translate: 'ALL', length: '0' },
            { translate: 'WEBMAIL', length: '0' },
            { translate: 'SMTP', length: '0' },
            { translate: 'IMAP', length: '0' },
            { translate: 'POP', length: '0' },
            { translate: 'XMPP', length: '0' },
            { translate: 'ACTIVESYNC', length: '0' },
            { translate: 'MAPI_EWS', length: '0' },
            { translate: 'WEBDAV', length: '0' },
           // Hide onlyoffice for now { translate: 'ONLYOFFICE', length: '0'},
        ];
        vm.selectedSection = 'ALL';
        vm.changeSection = function (section) {
            vm.selectedSection = section;
            $rootScope.protoType = section;
            refreshCounts();
        };
        activate();

        function activate() {
            coreDataDomainSettings.changePageReset();
            refreshCounts();
            $scope.$on('reloadUsersConnectionsCounts', refreshCounts);
            vm.isInitialized = true;
        }

        function refreshCounts() {
            var params = {
                domain: vm.domain
            };
            return $http.post('~/api/v1/settings/domain/users-connections-counts', JSON.stringify(params))
                .then(function (result) {
                    vm.gridSections[0].length = result.data.allCount;
                    vm.gridSections[0].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[1].length = result.data.webmailCount;
                    vm.gridSections[1].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[2].length = result.data.smtpCount;
                    vm.gridSections[2].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[3].length = result.data.imapCount;
                    vm.gridSections[3].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[4].length = result.data.popCount;
                    vm.gridSections[4].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[5].length = result.data.xmppCount;
                    vm.gridSections[5].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[6].length = result.data.easCount;
                    vm.gridSections[6].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[7].length = result.data.mapiEwsCount;
                    vm.gridSections[7].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);

                    vm.gridSections[8].length = result.data.webdavCount;
                    vm.gridSections[8].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);
                    // The below is for if we decide we want to add onlyoffice to the user connections in the future. 
                   // vm.gridSections[9].length = result.data.onlyofficeCount;
                   // vm.gridSections[9].label = "/ " + $filter("unicodeNumber")(result.data.allUsersCount);
                }, function () { });
        }
    }
})();