(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSecurityPassSettingsController", domainSecurityPassSettingsController);

	function domainSecurityPassSettingsController($rootScope, $scope, $http, $filter, $state, $mdDialog, errorHandling) {

		const vm = this;
		// Functions
		vm.save = save;
		vm.doCancel = doCancel;

		activate();

		//////////////////////

		function activate() {
			refresh();
		}

		function refresh() {
			$rootScope.spinner.show();
			$http
				.get(`~/api/v1/settings/domain/password-settings`)
				.then(onDomainPasswordSettingsLoaded, function () { })
				.finally($rootScope.spinner.hide);

			function onDomainPasswordSettingsLoaded(result) {
				vm.systemDefaults = result.data.systemDefaults;
				vm.domainOverrides = result.data.overrides;
				vm.systemDefaults.length = vm.systemDefaults.passLengthEnabled ? vm.systemDefaults.length : 3;

				vm.notificationTimings = $filter("orderBy")(vm.domainOverrides.expiration.notificationTimings, null, true).join(", ");

			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			var timingArray = vm.notificationTimings.split(",");
			var parsedNotificationTimings = [];
			for (var i = 0; i < timingArray.length; i++) {
				parsedNotificationTimings.push(Number(timingArray[i].trim()));
			}
			vm.domainOverrides.expiration.notificationTimings =
				$filter("orderBy")(parsedNotificationTimings, null, true);
			var params = JSON.stringify(vm.domainOverrides);


			$rootScope.spinner.show();
			$http
				.post(`~/api/v1/settings/domain/password-settings`, params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess(success) {
				if (success.data.message) {
					errorHandling.warn($filter('translate')('SETTINGS_CONTROLLED_BY_SYSTEM_ADMIN'));
					refresh();
				}				
				form.$setPristine();
			}
		}
	}

})();
