(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateEmailInEventAction", validateEmailInEventAction);

	function validateEmailInEventAction($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function (scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateEmail = async function (modelValue, viewValue) {
					//Variables that can be used in the event action email address in place of an email address.
					var vars = [
						"#aliasaddresses#",			// Alias Addresses
						"#alldomainadmins#",		// All Domain Admins
						"#forwardingaddresses#",	// Forwarding Address
						"#fromaddress#",			// From Address
						"#mailinglistaddress#",		// Mailing List Address
						"#primarydomainadmin#",		// Primary Domain Admin
						"#toaddress#"				// To Address
					];

					if (scope.$eval(attrs.validateEmail) === false) return $q.resolve({ succss: true });

					if (!viewValue) return $q.resolve({ succss: true });
					if (vars.indexOf(viewValue) > -1) return $q.resolve({ succss: true });

					var isValid = await emailValidationService.isValidEmail(viewValue);
					return isValid
						? $q.resolve({ success: true })
						: $q.reject({ success: false });
				};
			}
		};
	}
})();