(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityBlacklisting", sysAdminSecurityBlacklisting);

	function sysAdminSecurityBlacklisting($rootScope, $scope, $http, $mdDialog, $filter, $compile,
		coreDataSysAdminSettings, coreDataSysAdminCards, errorHandling, NgTableParams, gridCheckboxes, coreDataFileStorage, $translate, i18n) {
		var vm = this;
		vm.items = [];
		vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { ip: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("ip");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.newItem = newItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
        vm.exportSecuritySettings = exportSecuritySettings;
        vm.importSecuritySettings = importSecuritySettings;

		activate();

		//////////////////////////

		function activate() { }

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			refreshTable();
		}
		
		function queryData(params) {
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = Number(params.count());

			vm.searchParams.sortField = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === "desc";
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post('~/api/v1/settings/sysadmin/ip-access/' + false, JSON.stringify({ searchParams: vm.searchParams, showHoneypot: true }))
				.then(onDataRetrieved, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDataRetrieved(result) {
				vm.items = result.data.ipAccessList || [];
				for (var i = 0; i < vm.items.length; i++) {
					if (vm.items[i].description === "SPOOL.BLOCKED_FROM_DASHBOARD")
						vm.items[i].description = "SPOOL_BLOCKED_FROM_DASHBOARD";

					if (vm.items[i].modified)
						vm.items[i].modifiedTranslated = $filter('date')(vm.items[i].modified, 'short');

					if (vm.items[i].country) {
						if (vm.items[i].country === null)
							vm.items[i].countryTranslated = $filter("translate")("NA");
						else
							vm.items[i].countryTranslated = $filter("translate")("COUNTRY_CODE_" + vm.items[i].country) + " (" + vm.items[i].country + ")";
					} else {
						vm.items[i].countryTranslated = $filter("translate")("NA");
					}
				}

				params.total(result.data.totalCount);
				return vm.items;
			}
		}

		function newItem(ev) {
			$mdDialog.show({
				locals: {
					addresses: "",
					smtp: false,
					pop: false,
					imap: false,
					xmpp: false,
					ldap: false,
					active_sync: false,
					web_dav: false,
					web_mail: false,
					mapi_ews: false,
					description: "",
					fromGrid: false,
					changed: "",
					readOnly: false
				},
				controller: "sysAdminEditBlacklistingController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/security/modals/blacklist.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					var serviceList = [];
					if (modalSuccess.smtp)
						serviceList.push(0);
					if (modalSuccess.imap)
						serviceList.push(1);
					if (modalSuccess.pop)
						serviceList.push(2);
					if (modalSuccess.xmpp)
						serviceList.push(7);
					if (modalSuccess.ldap)
						serviceList.push(4);
					if (modalSuccess.active_sync)
						serviceList.push(9);
					if (modalSuccess.mapi_ews)
						serviceList.push(10);
					if (modalSuccess.web_dav)
						serviceList.push(12);
					if (modalSuccess.web_mail)
						serviceList.push(8);
						

					var params = {
						serviceList: serviceList,
						dataType: 1,
						address: modalSuccess.address,
						description: modalSuccess.description
					};

					$http.post("~/api/v1/settings/sysadmin/ip-access", JSON.stringify(params))
						.then(function () {
							refreshTable();
							$rootScope.$broadcast("security:countersChanged");
						}, errorHandling.report);
				}, function () { });
		}

		function editItem(card, ev) {
			$mdDialog.show({
				locals: {
					addresses: card.ip,
					smtp: card.smtp,
					pop: card.pop,
					imap: card.imap,
					xmpp: card.xmpp,
					ldap: card.ldap,
					active_sync: card.active_sync,
					web_dav: card.web_dav,
					web_mail: card.web_mail,
					mapi_ews: card.mapi_ews,
					description: card.description,
					fromGrid: false,
					changed: card.modifiedTranslated,
					readOnly: card.description.startsWith("HoneyPot ")
				},
				controller: "sysAdminEditBlacklistingController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/security/modals/blacklist.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (card.description.startsWith("HoneyPot "))
						return;
					var serviceList = [];
					if (modalSuccess.smtp)
						serviceList.push(0);
					if (modalSuccess.imap)
						serviceList.push(1);
					if (modalSuccess.pop)
						serviceList.push(2);
					if (modalSuccess.xmpp)
						serviceList.push(7);
					if (modalSuccess.ldap)
						serviceList.push(4);
					if (modalSuccess.active_sync)
						serviceList.push(9);
					if (modalSuccess.mapi_ews)
						serviceList.push(10);
					if (modalSuccess.web_dav)
						serviceList.push(12);
					if (modalSuccess.web_mail)
						serviceList.push(8);

					var params = {
						serviceList: serviceList,
						dataType: 1,
						address: modalSuccess.address,
						oldAddress: card.ip,
						description: modalSuccess.description
					};

					$http.post("~/api/v1/settings/sysadmin/ip-access", JSON.stringify(params))
						.then(function () {
							refreshTable();
							$rootScope.$broadcast("security:countersChanged");
						}, errorHandling.report);
				}, function () { });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.ip; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.ip];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var addresses = vm.checkboxes.getCheckedItems();
			if (!addresses || !addresses.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: addresses.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {

				var deleteEntries = [];
				var deleteHoneyPotEntries = [];

				vm.items.forEach(function (item) {
					if (addresses.indexOf(item.ip) > -1)
					{
						if (item.description.startsWith("HoneyPot "))
							deleteHoneyPotEntries.push(item.ip);
						else
							deleteEntries.push(item.ip);
					}
				});

				if (deleteEntries.length > 0)
				{
					var joined = addresses.join(",");
					var params = {
						address: joined,
						dataType: 1
					};

					$http.post("~/api/v1/settings/sysadmin/ip-access-delete", JSON.stringify(params))
						.then(function (success) {
							refreshTable();
							$rootScope.$broadcast("security:countersChanged");
						}, errorHandling.report);
				}
				if (deleteHoneyPotEntries.length > 0)
				{
					var joined = addresses.join(",");
					var params = {
						ips: deleteHoneyPotEntries
					};

					$http.post("~/api/v1/settings/sysadmin/honey-pot/remove", JSON.stringify(params))
						.then(function (success) {
							refreshTable();
							$rootScope.$broadcast("security:countersChanged");
						}, errorHandling.report);
				}
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

        function exportSecuritySettings() {
            var httpPath = "~/api/v1/settings/sysadmin/export-security-settings/blacklist";
            var fileName = $filter("translate")("FILENAME_SECURITY_CONFIG") + "-" + $filter("translate")("BLACKLIST") + ".json";

            $rootScope.spinner.show();
            coreDataFileStorage
                .downloadFile(httpPath, fileName)
                .then(onExportSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onExportSuccess() { }
        }

        function importSecuritySettings(ev) {
            $mdDialog
                .show({
                    locals: {
                        titleText: $translate.instant("IMPORT") + " " +  $translate.instant("BLACKLIST")
                    },    
                    controller: "sysAdminImportSecuritySettingsController",
                    controllerAs: "ctrl",
                    templateUrl: "app/sysadmin/settings/security/modals/import-security-settings.dlg.html",
                    targetEvent: ev
                })
                .then(function () {
                    refreshTable();
                    $rootScope.$broadcast("security:countersChanged");
                });
        }
	}
})();
