(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("modalService", modalService);

    function modalService($rootScope, $location, $mdDialog) {
        var _this = this;

        var modalCallback = null;
        var modal = null;

        _this.setModalCallback = setModalCallback;
        _this.cancelModal = cancelModal;

        function setModalCallback(callback) {
            modalCallback = callback;
        }

        function cancelModal() {
            if (modalCallback == null) {
                $mdDialog.cancel();
                modal = null;
            }
            else {
                modalCallback();
                modalCallback = null;
                modal = null;
            }
        }
    }
})();