(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedMailingListCustomFieldsController", sharedMailingListCustomFieldsController);

	function sharedMailingListCustomFieldsController($rootScope, $scope, $http, $filter, $mdDialog, $compile, $stateParams,
		NgTableParams, gridCheckboxes, errorHandling, i18n) {
		var vm = this;
		vm.isDomain = $stateParams.type === 'domain';
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.deleteItems = deleteItems;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
			refreshFields(true);
		}

		function refreshFields(ignoreSignalCount) {
			$http
				.get('~/api/v1/settings/domain/subscriber-field-definitions')
				.then(function (result) {
					vm.customFields = result.data.subscriberFields || [];
					vm.tableParams.settings({ dataset: vm.customFields });
					
					refreshTable();
					if (ignoreSignalCount !== true)
						signalCountChanged();
				}, function () {});
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function searchItems() {
			vm.checkboxes.reset();
			vm.tableParams.filter({ $: vm.searchText });
			vm.tableParams.reload();
		}

		function signalCountChanged() {
			$rootScope.$broadcast("mailing-list:counts-changed");
		}

		function newItem(event) {
			$mdDialog.show({
				locals: {
					name: undefined,
					defaultValue: undefined
				},
				controller: "sharedEditCustomFieldController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/custom-field.dlg.html",
				targetEvent: event
			})
			.then(function (modalSuccess) {
				let params = JSON.parse(JSON.stringify(vm.customFields));
				var item = {
					name: modalSuccess.name,
					defaultValue: modalSuccess.defaultValue
				};

				params.push(item);
				$http
					.post('~/api/v1/settings/domain/subscriber-field-definitions', JSON.stringify({ subscriberFieldDefinitions: params }))
					.then(function (success) {
						vm.customFields.push(item);
						refreshFields(success);
						}, errorHandling.report);
			});
		}

		function deleteItems(event) {
			var ids = vm.checkboxes.getCheckedItems();
			confirmAndDelete(ids, event);
		}
		function confirmAndDelete(ids, event) {

		
			if (!ids || !angular.isArray(ids) || !ids.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CUSTOM_FIELDS_SHARED') + " " + i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: ids.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				$http.post('~/api/v1/settings/domain/mailing-lists/subscriber-field-definitions/delete-bulk', JSON.stringify({ ints: ids })).then(refreshFields, errorHandling.report);
			}
		}

		function fireDataChanged() {
			vm.checkboxes.reset();
			signalCountChanged();
			vm.tableParams.reload();
		}

		function editItem(selectedCard, ev) {
			$mdDialog.show({
				locals: {
					name: selectedCard.name,
					defaultValue: selectedCard.defaultValue
				},
				controller: "sharedEditCustomFieldController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/custom-field.dlg.html",
				targetEvent: ev
			})
			.then(function (modalSuccess) {
				if (modalSuccess.delete) {
					confirmAndDelete([selectedCard.id], ev);
				} else {

					selectedCard.name = modalSuccess.name;
					selectedCard.defaultValue = modalSuccess.defaultValue;

					$http
						.post('~/api/v1/settings/domain/subscriber-field-definitions', JSON.stringify({ subscriberFieldDefinitions: vm.customFields }))
						.then(refreshFields, errorHandling.report);
				}
			});
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val == item.id })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, translateKey: 'DELETE' },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
	}

})();




