(function () {
	"use strict";

	angular.module("smartertools").directive("stDataTabs", ["$compile", "$interval",
		function ($compile, $interval) {
			return {
				restrict: 'EA',
				scope: {
					changeSection: "=",
					selectedSection: "=",
					sections: "=",
				},
				link: function (scope, element, attr) {
					var elementToBeAdded = angular.element(
						'<div class="st-data-tabs-wrapper">\
							<div class="st-data-tabs" layout="row" layout-align="start center">\
								<div class="st-data-tabs-selector" ng-repeat="section in sections" ng-show="!section.hidden" ng-click="changeSection(section.translate)" ng-class="{\'st-data-tab-selected\': selectedSection == section.translate}">\
									<div class="st-data-tab-content" ng-class="{\'st-data-tab-no-length\': (section.length < 0 && section.length != -2)}">\
										<div>\
                                            <span class="st-data-tab-title">{{ section.translate | translate | htmlUnescape }}</span>\
                                            <a href=""><span class="not-licensed" style="color: #ffffff !important" ng-if="section.showNotLicensed">{{::"NOT_LICENSED" | translate}}</span></a>\
                                        </div>\
										<span class="st-data-tab-number" ng-class="{\'over-max\': section.length > section.max }">\
                                            <span ng-if="section.length == -2">{{\'ALL\' | translate}}</span>\
                                            <span ng-if="section.length > -1">{{section.length | number}}</span>\
                                            <span ng-if="section.max > -1 && section.length > -1" class="st-data-tab-number-label">&nbsp;/&nbsp;{{section.max | number}}</span>\
											<span class="st-data-tab-number-label">&nbsp;{{(section.length > -1 ? section.label : "") | translate}}</span>\
										</span>\
									</div>\
								</div>\
							</div>\
							<md-menu md-offset="0 90" md-position-mode="target-right target" layout="row" layout-align="start center">\
								<md-button ng-click="$mdMenu.open($event);" aria-label="menu" class="st-data-tabs-dropdown">\
									<div class="st-data-tab-content" ng-repeat="section in sections" ng-class="{\'st-data-tab-no-length\': (section.length < 0 && section.length != -2), \'st-data-tab-content-viewable\': selectedSection == section.translate}">\
										<div>\
                                            <span class="st-data-tab-title">{{ section.translate | translate | htmlUnescape }}</span>\
                                            <a href=""><span class="not-licensed" style="color: #ffffff !important" ng-if="section.showNotLicensed">{{::"NOT_LICENSED" | translate}}</span></a>\
                                        </div>\
										<span class="st-data-tab-number">\
											{{ section.length == -2 ? (\'ALL\' | translate) : (section.max > -1 ? (section.max + \'/\' + section.length) : (section.length > -1 ? (section.length | number) : \'\')) }}\
											<span class="st-data-tab-number-label">&nbsp;{{(section.length > -1 ? section.label : "") | translate}}</span>\
										</span>\
									</div>\
									<span class="st-data-tab-content-caret"><i class="toolsicon toolsicon-arrow_drop_down_emphasis"></i></span>\
								</md-button>\
								<md-menu-content class="standard">\
									<div ng-repeat="section in sections">\
										<md-menu-item>\
											<md-button ng-click="changeSection(section.translate)">{{ section.translate | translate | htmlUnescape }}</md-button>\
										</md-menu-item>\
									</div>\
								</md-menu-content>\
							</md-menu>\
						</div>'
					);

					var elementCompiled = $compile(elementToBeAdded)(scope);
					element.append(elementCompiled);
				}
			}
		}
	]);
})();