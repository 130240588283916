(function () {
	/*
	 * This directive loads content using the report-card-content directive, report-card-content.directive.js.
	 */
	angular
		.module('smartermail')
		.directive('reportCard', reportCardDirective);

	/* @ngInject */
	function reportCardDirective($compile) {
		return {
			restrict: 'E',
			link: function (scope, element, attrs) {
				var cardMarkup = {};
				scope.card.isTitleCard = false;
				
				switch (scope.card.cardType) {
					case "dataCard":
						cardMarkup = angular.element(
							"<div class='report-card-data'>" +
							"<div class='rcd-title'>" +
							(scope.card.cardDisplayTitle
								? "{{card.cardDisplayTitle.title | translate : card.cardDisplayTitle.data}}"
								: "{{card.cardTitle | translate}}") +
							"</div>" +
							(scope.card.cardData != undefined && scope.card.cardData.value != undefined ?
								("<div class='rcd-value'>" + ((scope.card.cardData.type && scope.card.cardData.type == "bytes") ? "{{card.cardData.value | bytes : 2 | safeNumber}}" : "{{card.cardData.value | safeNumber}}") +
									(scope.card.cardData.unit ? " {{card.cardData.unit | translate}}" : "") +
									(scope.card.cardData.label != undefined ? "<span class='rcd-value-label' style='color:" + scope.card.cardData.color != undefined ? scope.card.cardData.color : "" + "'>{{card.cardData.label | safeNumber}}</span>" : "") +
									"</div>")
								: "") +
							"<report-card-content ng-repeat='content in card.cardContents'></report-card-content>" +
							"</div>");
						break;

					case "titleCard_User":
						scope.card.isTitleCard = true;
						cardMarkup = angular.element(
							"<div class='title-card'><div class='tc-portrait'>" +
							"<img class='img-circle user-picture' ng-src='{{userImageSource}}'></img>" +
							"</div>" +
							"<div class='tc-content'>" +
							"<div class='tc-title'>{{card.cardTitle | translate}}</div>" +
							"<div class='tc-subtitle'>{{card.cardSubTitle | translate}}</div>" +
							"</div></div>"
						);
						element.addClass('rc-title-card');
						break;

					case "pairedCard":
						var classAdd = "";
						if ($(element).hasClass('disableClick'))
							classAdd = "disableClick";

						cardMarkup = angular.element(

							(scope.card.cardContents[1] ?
								'<md-card class="paired-card-data ' + classAdd + ' float-position" ng-click="$parent.goToCard($event, card, 1)">' +
								'<div class="rcd-title" ng-if="card.cardTitles[1]">{{card.cardTitles[1] | translate}}</div>' +
								'<div class="rcd-value" ng-if="card.cardData.values[1]">{{card.cardData.values[1] | translate}}<span class="rcd-value-label"> {{card.cardData.labels[1] | translate}}</span></div>' +
								'<div ng-repeat="content in card.cardContents[1] track by $index" >' +
								'<report-card-content></report-card-content>' +
								'</div>' +
								'</md-card>' : "")+
							'<md-card class="paired-card-data ' + classAdd + '" ng-click="$parent.goToCard($event, card, 0)">' +
							'<div class="rcd-title" ng-if="card.cardTitles[0]">{{card.cardTitles[0] | translate}}</div>' +
							'<div class="rcd-value" ng-if="card.cardData.values[0]">{{card.cardData.values[0] | translate}}<span class="rcd-value-label"> {{card.cardData.labels[0] | translate}}</span></div>' +
							'<div ng-repeat="content in card.cardContents[0] track by $index" >' +
							'<report-card-content></report-card-content>' +
							'</div>' +
							'</md-card>' 
							
						);
						break;

					case "titleCard_Domain":
					case "titleCard_Generic":
						scope.card.isTitleCard = true;
						cardMarkup = angular.element(
							"<div class='title-card'>" +
							"<div class='tc-content'>" +
							"<div class='tc-title'>{{card.cardTitle | translate}}</div>" +
							"<div class='tc-subtitle'>{{card.cardSubTitle | translate}}</div>" +
							"</div></div>"
						);
						element.addClass('rc-title-card');
						break;
				}

				element.append($compile(cardMarkup)(scope));

				function goToSubCard(e, card, index) {

				}
			}

		}
	}
})();

