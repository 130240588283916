(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("noteImportDialogController", noteImportDialogController);

	function noteImportDialogController($scope, $mdDialog, $q, $timeout, coreDataNotes, apiCategories, coreDataCategories) {
		var vm = this;
		var csvLoaded = false;
		var processing = false;

		$scope.FILE_NOT_SELECTED = 1;
		$scope.FILE_SELECTED = 2;
		$scope.FILE_PROCESSING = 3;
		$scope.CSV_MAPPING = 4;
		$scope.REPORT = 5;
		$scope.step = $scope.FILE_NOT_SELECTED;

		$scope.sources = $.grep(coreDataNotes.getSources(), function (source) { return source.access > 4; });
		vm.currentSource = $scope.sources[0];
		$scope.uploaderData = JSON.stringify({ source: vm.currentSource.ownerUsername });
		$scope.report = { numLoaded: 0, numAdded: 0, numErrors: 0 };

		var loadedNotes = [];
		var mappingDict = [];
		$scope.reqFound = false;

		// Function definitions
		$scope.addedFile = addedFile;
		$scope.loadFile = loadFile;
		$scope.removedFile = removedFile;
		$scope.uploaderInit = uploaderInit;

		$scope.onCancelClicked = onCancelClicked;
		$scope.onCloseClicked = onCloseClicked;
		$scope.onCsvMappingNextClicked = onCsvMappingNextClicked;
		$scope.onLoadClicked = onLoadClicked;
		$scope.onMappingChanged = onMappingChanged;

		activate();

		//////////////////////

		function activate() { }

		// Uploader functions
		function addedFile() {
			$scope.step = $scope.FILE_SELECTED;
			$scope.$applyAsync();
		}

		function loadFile(file, result) {
			if (angular.isArray(result.notes)) {
				csvLoaded = true;
				angular.forEach(result.notes, function (note) {
					loadedNotes.push(note);
				});
			}

			if (!csvLoaded || loadedNotes.length === 0)
				$scope.step = $scope.REPORT;
			else {
				setupCsvMappings();
				$scope.step = $scope.CSV_MAPPING;
				onMappingChanged();
			}
		}

		function removedFile() {
			$scope.step = $scope.FILE_NOT_SELECTED;
			$scope.$applyAsync();
		}

		function uploaderInit(uploaderFuncs) {
			$scope.uploaderFunctions = uploaderFuncs;
		}

		// Helper functions
		function addNotes(notes) {
			if (!notes || notes.length === 0) return $q.when();
			var defer = $q.defer();
			
			coreDataNotes.addNotes(notes, vm.currentSource)
				.then(function () {
					coreDataCategories.reset();
					apiCategories.invalidateCategories();
					defer.resolve();
				}, function () {
					$scope.report.numErrors = notes.length;
					defer.reject();
				});
			return defer.promise;
		}

		function onCancelClicked() {
			$mdDialog.cancel();
		}

		function onCloseClicked() {
			$mdDialog.hide();
		}

		function onCsvMappingNextClicked() {
			mappingDict.length = 0;	// Reset mapping dictionary
			for (var i = 0; i < $scope.mappings.length; ++i) {
				mappingDict[$scope.mappings[i].id] = $scope.mappings[i].mappedField;
			}
			$timeout(process, 100);
		}

		function onMappingChanged() {
			$scope.reqFound = false;
			for (var i = 0; i < $scope.mappings.length; ++i) {
				if ($scope.mappings[i].mappedField === "subject" ||
					$scope.mappings[i].mappedField === "body") {
					$scope.reqFound = true;
					break;
				}
			}
		}

		function process() {
			var notes = [];
			
			for (var i = 0; i < loadedNotes.length; ++i) {
				try {
					var note = buildFromCsvFields(loadedNotes[i]);
					if (note === null) {
						$scope.report.numLoaded++;
						$scope.report.numErrors++;
						continue;
					}
					$.extend(note, note, {
						sourceOwner: vm.currentSource.ownerUsername,
						sourceId: vm.currentSource.itemID,
						sourceName: vm.currentSource.displayName,
						sourcePermission: vm.currentSource.access,
						isVisible: vm.currentSource.enabled
					});
					notes.push(note);
					$scope.report.numLoaded++;
					$scope.report.numAdded++;
				} catch (err) {
					$scope.report.numLoaded++;
					$scope.report.numErrors++;
				}
			}

			$scope.processing = true;
			addNotes(notes)
				.then(function () { }, function() { })
				.finally(function () {
					$scope.step = $scope.REPORT;
					$scope.processing = false;
				});			
		}

		function buildFromCsvFields(csvFields) {
			var note = {};
			for (var i = 0; i < csvFields.length; ++i) {
				if (!mappingDict[csvFields[i].guid])
					continue;

				switch (mappingDict[csvFields[i].guid]) {
					default:
					case "ignore":
						break;
					case "subject":
						note.subject = csvFields[i].value;
						break;
					case "body":
						note.text = csvFields[i].value;
						break;
					case "color":
						note.color = csvFields[i].value;
						break;
					case "categories":
						if (csvFields[i].value) {
							var categoryNames = csvFields[i].value.split(",");
							note.categoriesMetaData = [];
							for (var j = 0; j < categoryNames.length; j++)
								note.categoriesMetaData.push({ name: categoryNames[j], selected: true });
						}
						break;
				}
			}

			if (!note.subject && !note.text)
				return null;

			return note;
		}

		function onLoadClicked() {
			$scope.step = $scope.FILE_PROCESSING;
			$scope.uploaderData = JSON.stringify({ source: vm.currentSource.ownerUsername });
			$scope.uploaderFunctions.startUpload();
		}

		function setupCsvMappings() {
			$scope.mappings = [];
			var headerIds = [];
			for (var j = 0; j < loadedNotes.length; ++j) {
				var csvFields = loadedNotes[j] || [];
				for (var i = 0; i < csvFields.length; ++i) {
					if (headerIds.indexOf(csvFields[i].guid) > -1)
						continue;

					headerIds.push(csvFields[i].guid);
					$scope.mappings.push({ id: csvFields[i].guid, mappedField: csvFields[i].mappedField || "ignore", uploadedField: csvFields[i].uploadedField });
				}
			}
		}
	}

})();