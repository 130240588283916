(function () {
	"use strict";

	/*
	Surround items with <st-masonry> tags.  Any DIV, FORM, or MD_CARD will be treated as a masonry item.

	Masonry will automatically set columns to the width of the first child.  If you wish to override this, set the masonry-width attribute.
	*/

	// ReSharper disable once UndeclaredGlobalVariableUsing
	angular
		.module("smartertools")
		.directive("stMasonry", stMasonryDirective);

	function stMasonryDirective($rootScope, $log, $timeout) {
		return {
			restrict: "EA",
			transclude: true,
			link: function (scope, el, attrs, ctrl, transclude) {
				var container;
				var opacitySet = false;
				var lastVals;
				var debouncedOnResize = _.throttle(onWindowResize, 50, { leading: false }); 

				activate();

				function activate() {
					el.append("<div class='st-angular-masonry-container st-angular-masonry-container-building'></div>");
					transclude(scope, function (clone) {
						$(".st-angular-masonry-container").css("opacity", "0");
						el.find(".st-angular-masonry-container").append(clone);
					});
					container = el[0];
					$(container).css('display', 'block');
					angular.element("st-masonry").ready(waitForLoad);

					scope.$on("masonry:contentsChanged", debouncedOnResize);

                    window.addEventListener('resize', debouncedOnResize);
					scope.$on('$destroy', function () {
						window.removeEventListener('resize', debouncedOnResize);
					});
                }

				function waitForLoad() {
					var cards = $(".st-angular-masonry-container md-card:not(.ng-hide)");
					for (var i = 0; i < cards.length; i++) {
						if (cards[i].clientWidth <= 300) {
							$timeout(waitForLoad, 20);
							return;
						}
					}

                    debouncedOnResize();
					$rootScope.$broadcast("masonry:loaded");

					//Dumb chrome 63 rendering bug
					if (navigator.userAgent.toLowerCase().indexOf("chrome/63") !== -1) {
						$('.st-angular-masonry-container md-input-container .md-input').css('position', 'static');
						$timeout(function () {
							$('.st-angular-masonry-container md-input-container .md-input').css('position', 'relative');
						}, 200);
					}
				}

				function onWindowResize() {
					try {
						const containerObject = $(container);

						var unboundItems = containerObject
							.find(".st-angular-masonry-container > :not(st-masonry-item)");

						if (unboundItems.length > 0) {
							unboundItems.each(function (index) {
								var t = $(this);
								var d = $("<st-masonry-item></st-masonry-item>");
								var ngif = t.attr("ng-if");
								var ngshow = t.attr("ng-show");
								if (ngif)
									d.attr("ng-if", ngif);
								if (ngshow)
									d.attr("ng-show", ngshow);
								t.wrap(d);
							});
						}

						var childCount = containerObject.find("st-masonry-item").length;
					    var w = containerObject.width();

						var subItems = containerObject.find("st-masonry-item > *");
						if (!subItems || !subItems.length) {
							return;
						}

						// Get col gap
					    var possibleScrollbar = 20;
						var colGap = 20;

						// TODO: This looks like it may not be working
						var itemWidth = parseInt(containerObject.attr("masonry-width") || subItems.outerWidth(false) || 400);
						var cols = 1;
						while (true) {
							var newCols = cols + 1;
							var widthRequired = newCols * itemWidth;
                            var gapRequired = (newCols - 1) * colGap + possibleScrollbar;
							if (widthRequired + gapRequired <= w)
								cols = newCols;
							else
								break;
						}

						if (cols > childCount)
							cols = childCount;
						if (cols > 5)
							cols = 5;

                        var masonrySize = cols * itemWidth + (cols - 1) * colGap;
						if (lastVals && lastVals.itemWidth === itemWidth && lastVals.masonrySize === masonrySize) {
							return;
						}

						containerObject
							.find(".st-angular-masonry-container")
							.css("-webkit-column-width", String(itemWidth) + "px")
							.css("-moz-column-width", String(itemWidth) + "px")
							.css("column-width", String(itemWidth) + "px")
							.innerWidth(masonrySize)
							.removeClass("st-angular-masonry-container-building");

						lastVals = { itemWidth: itemWidth, masonrySize: masonrySize };

						// TODO: If a single column, size sub-components to be full width

						// Can't think of a reason this would be needed here
						//scope.$applyAsync();

						if (!opacitySet) {
							$timeout(function () { opacitySet = true; $(".st-angular-masonry-container").css("opacity", "1") }, 100);
						} 

					} catch (ex) {
						// Ignore exceptions here
					}
				}
			}
		};
	}
})();