(function () {

    angular
        .module("smartertools")
        .filter('secondsToHmsWithExpired', secondsToHmsWithExpired);

    function secondsToHmsWithExpired($translate) {
        return function (seconds) {
            if (seconds < 1)
                return $translate.instant("EXPIRED");
            return moment.duration(seconds, 'seconds').format("hh:mm:ss");
        }
    }

})();

