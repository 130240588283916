(function () {
	"use strict";

	angular.module("smartertools").directive("virtualList",
		function ($window, $timeout, $q, $document, spinnerFactory, $rootScope, $log) {
			return {
				restrict: "E",
				scope: {
					parentId: "@",
					totalItems: "=",		// Binds to control number of items scroller should show.
					listDataProvider: "&",	// Function to return data for scroller. Takes in an object that has start, take, and defer
					listDataCache: "=",		// Currently only used to hold last scroll position
					listController: "=",	// Exposes list functions to the section controller
					onDrag: "="
				},
				transclude: true,
				template: function (elem, attr) {
					var cardMarkup;

					// page specific templates
					switch (attr.cardType.toLowerCase()) {
						case "contact":
							cardMarkup =
								'<div class="renderer">' +
								'<contact-card id="cc{{contact.id}}" ng-repeat="contact in visibleProvider" ng-style="contact.styles"></contact-card>' +
								'</div>';
							break;
						case "note":
							cardMarkup =
								'<div class="renderer">' +
								'<note-card id="nc{{note.id}}" ng-repeat="note in visibleProvider" ng-style="note.styles"></note-card>' +
								'</div>';
							break;
						case "task":
							cardMarkup =
								'<div class="renderer">' +
								'<task-card id="tc{{task.id}}" ng-repeat="task in visibleProvider" ng-style="task.styles"></task-card>' +
								'</div>';
							break;
						case "mail":
							cardMarkup = '<div ng-repeat="mail in visibleProvider track by mail.gridId"\
							class="mailGridItem"\
							ng-include="\'app/email/templates/email-list-item.html\'"\
							data=\'{{mail.uid}}|{{mail.folder}}\'\
							id="mailGridItem-{{mail.uid}}"\
							draggable="true"\
							st-drag-start="onListDrag(event)"\
							ng-class="{\'select\':mail.selected, \'messageInPreview\': parentScope.navigationPacket.uid == mail.uid, \'markedAsDeleted\':mail.isDeleted, \'gridRowDark\':mail.highlight}"\
							read="{{mail.isSeen != undefined && mail.isSeen == true}}"\
							ng-style="mail.styles"></div>';
							break;
						case "file-storage":
							cardMarkup = '<div class="renderer">' +
								'<file-storage-card\
							id="fsc{{file.id}}"\
							ng-repeat="file in visibleProvider" \
							ng-style="file.styles"\
							draggable="true"\
							st-drag-start="onListDrag(event)"\
							data="{{file.id}}"></file-storage-card>' +
								'</div>';
							break;
						case "file-storage-nointeract":
							cardMarkup = '<div class="renderer">' +
								'<file-storage-card\
							id="fsc{{file.id}}"\
							ng-repeat="file in visibleProvider" \
							ng-style="file.styles"\
							data="{{file.id}}"></file-storage-card>' +
								'</div>';
							break;
						case "notification-profile":
							cardMarkup =
								'<div class="renderer">' +
								'<notification-profile-card id="npc{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></notification-profile-card>' +
								'</div>';
							break;
						case "user-activity-online":
							cardMarkup =
								'<div ng-repeat="user in visibleProvider" \
							class="st-data-grid-item" \
							ng-include="\'app/sysadmin/manage/cards/user-activity-online-item.html\'" \
							ng-style="user.styles">\
							</div>';
							break;
						case "user-activity-inactive":
							cardMarkup =
								'<div ng-repeat="user in visibleProvider" \
							class="st-data-grid-item" \
							ng-include="\'app/sysadmin/manage/cards/user-activity-inactive-item.html\'" \
							ng-style="user.styles">\
							</div>';
							break;
						case "connection-all":
							cardMarkup =
								'<div ng-repeat="con in visibleProvider" \
							class="st-data-grid-item" \
							ng-include="\'app/sysadmin/manage/cards/connection-all-item.html\'" \
							ng-style="con.styles">\
							</div>';
							break;
						case "connection-users":
							cardMarkup =
								'<div ng-repeat="con in visibleProvider" \
							class="st-data-grid-item" \
							ng-include="\'app/sysadmin/manage/cards/connection-users-item.html\'" \
							ng-style="con.styles">\
							</div>';
							break;
						case "ids-all":
							cardMarkup =
								'<div ng-repeat="ids in visibleProvider" \
							class="st-data-grid-item" \
							ng-include="\'app/sysadmin/manage/cards/ids-all-item.html\'" \
							ng-style="ids.styles">\
							</div>';
							break;
						case "domain-resources":
							cardMarkup =
								'<div class="renderer">' +
								'<domain-resources-card id="src{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></domain-resources-card>' +
								'</div>';
							break;
						case "domain-subscribers":
							cardMarkup =
								'<div class="renderer">' +
								'<domain-subscribers-card id="cfc{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></domain-subscribers-card>' +
								'</div>';
							break;
						case "domain-mailing-lists":
							cardMarkup =
								'<div class="renderer">' +
								'<domain-mailing-lists-card id="mlc{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></domain-mailing-lists-card>' +
								'</div>';
							break;
						case "domain-mail-list-posters":
							cardMarkup =
								'<div class="renderer">' +
								'<domain-mail-list-posters-card id="lpc{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></domain-mail-list-posters-card>' +
								'</div>';
							break;
						case "message-archive":
							cardMarkup =
								'<div class="renderer">' +
								'<message-archive-card id="mac{{card.id}}" ng-repeat="card in visibleProvider" ng-style="card.styles"></message-archive-card id>' +
								'</div>';
							break;
						default:
							return "<span>Missing " + attr.cardType + " attribute</span>";
					}

					var result =
						'<div class="canvas" ng-style="canvasHeight">' +
						cardMarkup +
						'<div ng-style="{\'width\': width, \'height\': height}" style="background-color: rgba(255, 255, 255, 0.5); display: flex; z-index: 2000; position: fixed; pointer-events: initial; outline: none;" ng-click="$event.stopPropagation();" ng-if="listController.spinner.isShown()">' +
						'<md-progress-circular md-mode="indeterminate" md-diameter="60" style="margin: auto; outline: none;"></md-progress-circular>' +
						'</div>' +
						'</div>';
					return result;
				},
				link: function (scope, elem, attrs, ngModelCtrl) {
					var rowHeight = parseInt(attrs.height, 10) || 217;
					var colWidth = parseInt(attrs.width, 10) || 346;

					scope.parentScope = scope.$parent;
					scope.height = rowHeight;
					scope.width = colWidth;
					scope.scrollTop = 0;
					scope.visibleProvider = []; //Items actually being shown
					scope.rowsPerPage = 0;
					scope.colsPerPage = 0;
					scope.numberOfCells = 0;
					scope.canvasHeight = {};
					scope.listController.spinner = new spinnerFactory(500);

					scope.onListDrag = function (event) {
						if (scope.onDrag) {
							scope.onDrag(event);
						}
					};

					scope.init = function () {
						elem[0].addEventListener("scroll", scope.onScroll);
						window.addEventListener('resize', scope.onResize, true);
					};

					scope.config = function () {
						// IE only
						//elem.css("display", 'none');

						// Determines the number of columns and rows that need to be visible.
						scope.height = $("#" + scope.parentId).height();
						//if (scope.height < rowHeight)
						//	scope.height = rowHeight;

						scope.width = $("#" + scope.parentId).width();
						// IE only
						//elem.css("height", '100%');
						elem.css("max-height", scope.height);
						// NOT IE only next line
						elem.css("min-height", scope.height);
						// IE only
						//elem.css("display", 'block');

						//$log.log("#"+scope.parentId+": "+scope.width + " x " + scope.height);

						scope.rowsPerPage = Math.round(scope.height / rowHeight);
						if (scope.rowsPerPage < 1)
							scope.rowsPerPage = 1;

						if (colWidth == -1)
							scope.colsPerPage = 1;
						else
							scope.colsPerPage = Math.floor(scope.width / colWidth);
						if (scope.colsPerPage < 1)
							scope.colsPerPage = 1;

						scope.numberOfCells = 3 * scope.rowsPerPage * scope.colsPerPage;
						scope.canvasHeight = {
							height: scope.totalItems * rowHeight / scope.colsPerPage + "px"
						};

						//$log.log("cols and rows: " + scope.colsPerPage + " x " + scope.rowsPerPage);
					};

					var lastCall = { start: 0, take: 0 };
					scope.listController.reset = function (clearContents) {
						// Clears out the scroller. Good for loading new content on the same page.
						if (clearContents)
							scope.visibleProvider.length = 0;
						elem.prop("scrollTop", 0);
						scope.listDataCache.lastScrollPos = 0;
						lastCall = { start: 0, take: 0 };
					};

					scope.listController.updateDisplayList = function (avoidDuplicateCall) {
						//$log.debug('updateDisplayList called');
						// Loads data as the list is scrolled/initialized. Will queue data calls as it scrolls down. Queue system will then return data for the last item queued.
						var defer = $q.defer();

						if (scope.listDataCache.lastScrollPos != undefined && elem.prop("scrollTop") != scope.listDataCache.lastScrollPos)
							elem.prop("scrollTop", scope.listDataCache.lastScrollPos);

						var firstCell = Math.max((Math.floor(scope.scrollTop / rowHeight) - scope.rowsPerPage) * scope.colsPerPage, 0);
						var cellsToCreate = Math.min(firstCell + scope.numberOfCells, scope.numberOfCells);

						if (avoidDuplicateCall && lastCall.start == firstCell && lastCall.take == cellsToCreate) {
							// Avoiding duplicate call
							defer.resolve();
							return defer.promise;
						} else {
							lastCall.start = firstCell;
							lastCall.take = cellsToCreate;
						}

						if ($rootScope.spinner) {
							$rootScope.spinner.show();
						} else {
							scope.listController.spinner.show();
						}

						//$log.debug('calling request update');
						requestUpdate(firstCell, cellsToCreate)
							.then(function (response) {
								if (response === false) { defer.reject('No response'); }
								scope.visibleProvider = response;
								for (var i = 0, len = scope.visibleProvider.length; i < len; i += scope.colsPerPage) {
									for (var j = 0; j < scope.colsPerPage; j++) {
										if ((i + j) < scope.visibleProvider.length) {
											if ($('body[dir=rtl]').length > 0) {
												scope.visibleProvider[i + j].styles = {
													"top": ((firstCell + i) / scope.colsPerPage * rowHeight) + "px",
													"right": (j * colWidth) + "px",
													"display": "block",
													"position": "absolute"
												};
											} else {
												scope.visibleProvider[i + j].styles = {
													"top": ((firstCell + i) / scope.colsPerPage * rowHeight) + "px",
													"left": (j * colWidth) + "px",
													"display": "block",
													"position": "absolute"
												};
											}

											if (scope.$parent.selectMode) {
												scope.visibleProvider[i + j].styles["z-index"] = 1;
											} else {
												delete scope.visibleProvider[i + j].styles["z-index"];
											}
										}
									}
								}

								defer.resolve();
							}, function (response) {
								//$log.debug('requestUpdate rejected:');
								//$log.debug(response);
								defer.reject(response);
							}).finally(function () {
								if ($rootScope.spinner) {
									$rootScope.spinner.hide();
								}
								scope.listController.spinner.hide();
							});
						return defer.promise;
					};

					var updateQueue = [];
					var processUpdate = function (obj) {
						// Process an update from the queue
						scope.listDataProvider({ queueObject: obj })
							.then(function (response) {
								//$log.debug('processUpdate success:');
								//$log.debug(response);
								obj.defer.resolve(response);
								updateQueue.shift();
								if (updateQueue.length > 0) {
									processUpdate(updateQueue[0]);
								}
							},
								function (response) {
									//$log.debug('processUpdate failure:');
									//$log.debug(response);
									obj.defer.reject(response);
									updateQueue.shift();
									if (updateQueue.length > 0) {
										processUpdate(updateQueue[0]);
									}
								});
					};

					function requestUpdate(start, take) {
						// Called from updateDisplayList. 
						// The list is asking for data, we will only queue 1 request deep so that it doesn't load all of the data during a fast scroll and prioritizes loading the data needed to display when scrolling stops or slows
						var defer = $q.defer();

						var queueObject = {
							start: start,
							take: take,
							defer: defer
						};
						while (updateQueue.length > 1) {
							var p = updateQueue.pop();
							//$log.debug('requestUpdate rejecting:');
							//$log.debug(p);
							p.defer.reject();
						}
						updateQueue.push(queueObject);
						if (updateQueue.length == 1) {
							//$log.debug('requestUpdate processing:');
							//$log.debug(queueObject);
							processUpdate(queueObject);
						}
						return defer.promise;
					}

					var scrollDebounce = undefined;
					var scrollThrottle = _.throttle(scrollUpdateList, 50);
					scope.onScroll = function (ev) {
						scope.listDataCache.lastScrollPos = scope.scrollTop = elem.prop("scrollTop");
						scrollThrottle();
					};

					function scrollUpdateList() {
						scope.listController.updateDisplayList(true);
					}

					scope.listController.refresh = function () {
						scope.config();
						return scope.listController.updateDisplayList(false)
							.then(function (success) { }, function (failure) { });
					};

					var timer;
					scope.onResize = function (ev) {
						if (timer) {
							$timeout.cancel(timer);
							timer = null;
						}
						timer = $timeout(function () {
							timer = null;
							scope.listController.refresh()
								.then(function (success) { scope.$applyAsync(); }, function (failure) { });
						}, 65);
					};

					scope.listController.onResize = scope.onResize;

					scope.listController.getListSize = function () {
						return scope.visibleProvider.length;
					};

					scope.$watch(function (scope) { return scope.totalItems; }, function (newVal, oldVal) {
						// Resize virtual list to match number of items
						scope.listController.refresh();
					}, true);

					var ready = false;
					$timeout(function () {
						$timeout(function () {
							//Gives page time to render
							ready = true;
							scope.listController.refresh()
								.then(function (success) { }, function (failure) { });
						});
					});
					scope.init();

					scope.$on("$destroy", function () {
						elem[0].removeEventListener("scroll", scope.onScroll);
						window.removeEventListener('resize', scope.onResize, true);
					});
				}
			};
		}
	);
})();














































/*
 * Congratulations! You've found the Dimetrodon. There are others dinosaurs hidden. Can you find them all?
 *
                              _._
                            _/:|:
                           /||||||.
                           ||||||||.
                          /|||||||||:
                         /|||||||||||
                        .|||||||||||||
                        | ||||||||||||:
                      _/| |||||||||||||:_=---.._
                      | | |||||:'''':||  '~-._  '-.
                    _/| | ||'         '-._   _:    ;
                    | | | '               '~~     _;
                    | '                _.=._    _-~
                 _.~                  {     '-_'
         _.--=.-~       _.._          {_       }
     _.-~   @-,        {    '-._     _. '~==+  |
    ('          }       \_      \_.=~       |  |
    `,,,,,,,'  /_         ~-_    )         <_oo_>
      `-----~~/ /'===...===' +   /
             <_oo_>         /  //
                           /  //
                          <_oo_>

 *
 */