(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditProfilingController", sysAdminEditProfilingController);

	function sysAdminEditProfilingController($scope, $mdDialog, $http) {
		$scope.savedProfiles = [];
		$scope.isAttached = true;
		$scope.isWaitingAttach = false;
		$scope.isProfilerActive = true;
		$scope.isProfilerInstalled = false;
		$scope.profilerType = 0;
		$scope.profilerStartTime = "";
		$scope.profilerRuntimeMs = 0;
		$scope.Math = window.Math;
		$scope.viewMode = "manual";
		$scope.currentStatus = "uninitialized";
		$scope.autoProfilerOptions = {};
		$scope.timerProfilerOptions = { active: false };

		$scope.updateInformation = updateInformation;
		$scope.stopProfiling = stopProfiling;
		$scope.startProfiling = startProfiling;
		$scope.dropProfiling = dropProfiling;
		$scope.deleteProfile = deleteProfile;
		$scope.detach = detach;
		$scope.autoProfilerToggle = autoProfilerToggle;
		$scope.startTimer = startTimer;

		updateInformation();

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.$on('signalR.mailHub.client.profilerStatus', processSignalrStatus);

		function updateInformation() {
			$http.get("~/api/v1/profiling/info")
				.then(function (result) {
					processStatus(result.data);
				}, function () { });
		}

		function processSignalrStatus(event, statusData) {
			processStatus(toCamel(statusData));
		}

		function processStatus(statusData) {
			$scope.isProfilerActive = statusData.profilerActive;
			$scope.currentStatus = statusData.currentStatus;
			$scope.isAttached = statusData.profilerAttached;
			$scope.profilerStartTime = moment(statusData.profilerStartTimeJs);

			$scope.profilerRuntimeMs = statusData.profilerRuntimeMs;
			$scope.isProfilerInstalled = statusData.profilerSdkInstalled;
			statusData.profiles.forEach(function (profile) {
				profile.dateStr = moment(profile.dateStrJsFriendly).format('LLL');
				profile.downloadLink = stSiteRoot + "api/v1/profiling/download/" + profile.guid;
			});

			$scope.savedProfiles = statusData.profiles;
			
			if ($scope.isAttached)
				$scope.isWaitingAttach = false;
			$scope.autoProfilerOptions = statusData.autoProfilerOptions;
			if (statusData.autoProfilerOptions.monitoring) {
				$scope.profilerType = statusData.autoProfilerOptions.profileType;

				if (statusData.autoProfilerOptions.triggerType == 0)
					$scope.viewMode = "autocpu";//We lock them to this
				else if (statusData.autoProfilerOptions.triggerType == 1)
					$scope.viewMode = "automem";//We lock them to this
			}

			if ($scope.isProfilerActive)
				$scope.profilerType = statusData.profilerType;

			$scope.timerProfilerOptions = statusData.timerProfilerOptions;

			if (statusData.timerProfilerOptions.active) {
				$scope.profilerType = statusData.timerProfilerOptions.profileType;
				$scope.viewMode = "time";
			}
		}

		function stopProfiling() {
			switch ($scope.profilerType) {
				case 0:
					$http.get("~/api/v1/profiling/memory/GetAndSaveMemorySnapshot").then(function (result) { });
					break;
				case 1:
					$http.get("~/api/v1/profiling/timeline/SaveSnapshot").then(function (result) { });
					break;
			}
		}

		function startProfiling() {
			$scope.isWaitingAttach = true;
			switch ($scope.profilerType) {
				case 0:
					$http.get("~/api/v1/profiling/memory/TraceAllocationsEnable").then(function(result) {
						setTimeout($scope.updateInformation, 3000);
					});
					break;
				case 1:
					$http.get("~/api/v1/profiling/timeline/NewSnapshot").then(function(result) {
						setTimeout($scope.updateInformation, 3000);
					});
					break;
			}
		}

		function dropProfiling() {
			switch ($scope.profilerType) {
			case 0:
				//no memory drop action
				break;
			case 1:
					$http.get("~/api/v1/profiling/timeline/DropSnapshot").then(function (result) { });
				break;
			}
		}

		function detach() {
			switch ($scope.profilerType) {
			case 0:
					$http.get("~/api/v1/profiling/memory/DetachProfiler").then(function (result) { });
				break;
			case 1:
					$http.get("~/api/v1/profiling/timeline/DetachProfiler").then(function (result) { });
				break;
			}
		}

		function deleteProfile(guid) {
			$http.get("~/api/v1/profiling/delete/"+guid).then(function(result) {
				setTimeout($scope.updateInformation, 1000);
			});
		}


		function autoProfilerToggle(start) {

			if ($scope.viewMode == "automem")
				$scope.autoProfilerOptions.triggerType = 1;
			else if ($scope.viewMode == "autocpu")
				$scope.autoProfilerOptions.triggerType = 0;

			$scope.autoProfilerOptions.profileType = $scope.profilerType;

			if (start) {
				$http.post("~/api/v1/profiling/autoprofiler/start", $scope.autoProfilerOptions)
					.then(function(success) {
							setTimeout($scope.updateInformation, 1000);
						},
						function(failure) {
							alert("Could not start");
						});
			} else {
				$http.post("~/api/v1/profiling/autoprofiler/stop", $scope.autoProfilerOptions)
					.then(function (success) {
							setTimeout($scope.updateInformation, 1000);
						},
						function (failure) {
							alert("Could not stop");
						});
			}
			
		}

		function startTimer(start) {
			$scope.timerProfilerOptions.profileType = $scope.profilerType;
			if (start) {
				$http.post("~/api/v1/profiling/timerprofiler/start", $scope.timerProfilerOptions)
					.then(function (success) {
						setTimeout($scope.updateInformation, 1000);
					},
					function (failure) {
						alert("Could not start");
					});
			} else {
				$http.post("~/api/v1/profiling/timerprofiler/stop", $scope.timerProfilerOptions)
					.then(function (success) {
						setTimeout($scope.updateInformation, 1000);
					},
					function (failure) {
						alert("Could not stop");
					});
			}
		}


		function toCamel(o) {
			var newO, origKey, newKey, value
			if (o instanceof Array) {
				return o.map(function (value) {
					if (typeof value === "object") {
						value = toCamel(value)
					}
					return value
				})
			} else {
				newO = {}
				for (origKey in o) {
					if (o.hasOwnProperty(origKey)) {
						newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
						value = o[origKey]
						if (value instanceof Array || (value !== null && value.constructor === Object)) {
							value = toCamel(value)
						}
						newO[newKey] = value
					}
				}
			}
			return newO
		}
	}

})();
