(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSharedResourceDialogController", domainSharedResourceDialogController);

	function domainSharedResourceDialogController($scope, $mdDialog, $filter, coreDataSettings, userGroups, resource, isPublicFolder) {
		var regex = /[\`\~\$\^\&\!\=\+\#\{\}\%\(\)\,\;\[\]\*\<\>\:\/\|\"\?\'\\\s\@]/g;

		$scope.oldResource = resource;
		$scope.resource = angular.copy(resource);
		$scope.isPublicFolder = isPublicFolder;
		$scope.userPermissions = [];
		$scope.groupPermissions = [];
		$scope.userGroups = [];
		$scope.selectedItem = [];

		const folderTypeOptions = [
			{ value: 1, shared_resource_type: 4, translation: "CONTACTS" },
			{ value: 2, shared_resource_type: 1, translation: "CALENDAR" },
			{ value: 4, shared_resource_type: 5, translation: "TASKS" },
			{ value: 5, shared_resource_type: 6, translation: "NOTES" }
		];
		const resourceTypeOptions = [
			{ value: 2, shared_resource_type: 2, translation: "SHARED_RESOURCES_CONFERENCE_ROOM" },
			{ value: 2, shared_resource_type: 3, translation: "SHARED_RESOURCES_EQUIPMENT" }
		];
		$scope.shareAccessOptions = [
			{
				value: 0,
				label: $filter("translate")("MAPPED_RESOURCES_ACCESS_0"),
				group: false,
			},
			{
				value: 4,
				label: $filter("translate")("MAPPED_RESOURCES_ACCESS_4"),
				group: true,
			},
			{
				value: 8,
				label: $filter("translate")("MAPPED_RESOURCES_ACCESS_8"),
				group: true,
			}
		];
		$scope.filterByResourceType = function(option) {
			return option.value !== 4 || !resourceTypeOptions.some(x => x.shared_resource_type === $scope.resource.type.shared_resource_type);
		};
		$scope.itemOptions = isPublicFolder ? folderTypeOptions : resourceTypeOptions;
		// Functions
		$scope.removeUser = removeUser;
		$scope.removeGroup = removeGroup;
		$scope.queryUsers = queryUsers;
		$scope.onEmailChanged = onEmailChanged;
		$scope.onEmailBlurred = onEmailBlurred;
		$scope.onGroupChanged = onGroupChanged;
		$scope.onTypeChanged = onTypeChanged;
		$scope.onNameChanged = onNameChanged;
		$scope.deleteThis = deleteThis;
		$scope.save = save;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			$scope.resource.type = $scope.itemOptions[0];
			for (let i = 0; i < $scope.itemOptions.length; i++) {
				const type = $scope.itemOptions[i];
				if (type.value === $scope.resource.folderType && type.shared_resource_type === $scope.resource.sharedResourceType) {
					$scope.resource.type = type;
					break;
				}
			}

			if ($scope.resource.enabled === undefined)
				$scope.resource.enabled = true;
			
			$scope.userGroups.push({ groupName: $filter("translate")("SELECT_A_GROUP"), userGroupId: undefined, selectText: true });
			$scope.userGroups.push({ groupName: $filter("translate")("EVERYONE"), userGroupId: -1, isEveryone: true });
			$scope.userGroups.push({ groupName: $filter("translate")("SHARED_RESOURCES_ADMIN"), userGroupId: -2, isAdmins: true });
			for (let i = 0; i < userGroups.length; i++)
				$scope.userGroups.push({ groupName: userGroups[i].name, userGroupId: userGroups[i].id });

			// Fixup users
			angular.forEach($scope.resource.accessUsers, function (share) {
				// conference rooms can only have none or full access
				if (($scope.resource.sharedResourceType === 2 || $scope.resource.sharedResourceType === 3) && (share.access === 2 || share.access === 4))
					share.access = 8;
				$scope.userPermissions.push(share);
			});

			if ($scope.resource.type.shared_resource_type == 2 || $scope.resource.type.shared_resource_type == 3) {
				$scope.userPermissions.push({ access: 8, user: { username: "" } });
			} else {
				$scope.userPermissions.push({ access: 4, user: { username: "" } });
			}

			// Fixup user groups
			angular.forEach($scope.resource.accessGroups, function (share) {
				// conference rooms & equipment can only have none or full access
				if ($scope.resource.type.shared_resource_type === 2 || $scope.resource.type.shared_resource_type === 3) {
					if ($scope.resource.isDomainResource && (share.access === 2 || share.access === 4))
						share.access = 8;
				}
				$scope.groupPermissions.push(share);
			});

			if ($scope.groupPermissions.length > 0) {
				angular.forEach($scope.groupPermissions, function (share) {
					for (var i = 0; i < $scope.userGroups.length; i++) {
						if (share.userGroupId == $scope.userGroups[i].userGroupId) {
							share.group = $scope.userGroups[i];
							break;
						}
					}
				});
			}

			if ($scope.resource.type.shared_resource_type == 2 || $scope.resource.type.shared_resource_type == 3)
				$scope.groupPermissions.push({ access: 8, group: $scope.userGroups[0] });
			else
				$scope.groupPermissions.push({ access: 4, group: $scope.userGroups[0] });
		}

		function queryUsers(query) {
			return coreDataSettings.queryUsers(query, false);
		}

		function onEmailChanged(last, index, form) {
			var email = form["email" + index].$viewValue;
			if (email && email.length > 0 && last) {
				if ($scope.resource.type.shared_resource_type == 2 || $scope.resource.type.shared_resource_type == 3) {
					$scope.userPermissions.push({ access: 8, user: { username: "" } });
				} else {
					$scope.userPermissions.push({ access: 4, user: { username: "" } });
				}
			}
		};

		function onEmailBlurred(last, index, form) {
			var email = form["email" + index].$viewValue;
			if ((!email || email.length === 0) && !last) {
				$scope.selectedItem.splice(index, 1);
				$scope.userPermissions.splice(index, 1);
			}
		};

		function onGroupChanged(last) {
			if (last) {
				if ($scope.resource.type.shared_resource_type == 2 || $scope.resource.type.shared_resource_type == 3) {
					$scope.groupPermissions.push({ access: 8, group: $scope.userGroups[0] });
				} else {
					$scope.groupPermissions.push({ access: 4, group: $scope.userGroups[0] });
				}
			}
		};

		function onTypeChanged() {
			if ($scope.resource.type.shared_resource_type == 2 || $scope.resource.type.shared_resource_type == 3) {
				for (var i = 0; i < $scope.userPermissions.length; ++i) {
					if ($scope.userPermissions[i].access === 2 || $scope.userPermissions[i].access === 4) {
						$scope.userPermissions[i].access = 8;
					}
				}
				for (var i = 0; i < $scope.groupPermissions.length; ++i) {
					if ($scope.groupPermissions[i].access === 2 || $scope.groupPermissions[i].access === 4) {
						$scope.groupPermissions[i].access = 8;
					}
				}
			}
			onNameChanged();
		}

		function onNameChanged() {
			if ($scope.resource.folderName &&
				($scope.resource.type.shared_resource_type === 2) || $scope.resource.type.shared_resource_type === 3) {
				$scope.resource.emailAlias = $scope.resource.folderName.replaceAll(regex, "_").toLowerCase();
				if ($scope.resourcesForm.emailAlias) {
					$scope.resourcesForm.emailAlias.$dirty = true;
					$scope.resourcesForm.emailAlias.$pristine = false;
				}
			}
		}

		function removeUser(index, form) {
			$scope.selectedItem.splice(index, 1);
			$scope.userPermissions.splice(index, 1);
			form.$setDirty();
		}

		function removeGroup(index, form) {
			$scope.groupPermissions.splice(index, 1);
			form.$setDirty();
		}

		function save(event, form) {
			if (form.$invalid)
				return;

			// Clean everyone and admins
			if (!$scope.resource.folderId) {
				$scope.resource.folderType = $scope.resource.type.value;
				$scope.resource.sharedResourceType = $scope.resource.type.shared_resource_type;
			}
			$scope.resource.admin_access = 0;
			$scope.resource.everyone_access = 0;
			$scope.resource.users = [];
			$scope.resource.userGroups = [];
			for (var i = 0; i < $scope.groupPermissions.length; i++) {
				var g = $scope.groupPermissions[i];
				if (g.group.userGroupId)
					$scope.resource.userGroups.push({ groupName: g.group.name, userGroupId: g.group.userGroupId, access: g.access });
			}
			for (var i = 0; i < $scope.userPermissions.length; i++) {
				var g = $scope.userPermissions[i];
				if (!g.username)
					continue;
				$scope.resource.users.push({ username: g.username, access: g.access});
			}

			$mdDialog.hide({ resource: $scope.resource });
		};

		function deleteThis(event) {
			$mdDialog.hide({ action: "delete" });
		}

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
