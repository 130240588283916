(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("trustedSenderDialogController", trustedSenderDialogController);

	function trustedSenderDialogController($rootScope, $scope, $mdDialog, emailValidationService, trustedSenders, isAddresses) {
		$scope.textValue = "";
		$scope.isAddresses = isAddresses;
		$scope.trustedSenders = trustedSenders || [];
		$scope.trustedSenders.sort();
		$scope.textValue = $scope.trustedSenders.join("\n");
		$scope.invalidEmails = [];
		$scope.invalidDomains = [];

		$scope.save = function () {
			var arr = $scope.textValue.split(/\r?\n/);
			angular.forEach(arr, function (text) {
				text.replace('\r', '');
				text.replace('\n', '');
			});
			$mdDialog.hide(arr);
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
		
		$scope.getInvalidEmails = async function (userForm) {
			$scope.invalidEmails = [];
			$scope.textValue = userForm.addresses.$viewValue;

			var emails = $scope.textValue.split("\n");

			for (var i = 0; i < emails.length; i++) {
				if (!emails[i] && i == emails.length - 1) continue;
				var isValid = await emailValidationService.isValidEmail(emails[i]);
				if (!isValid)
					$scope.invalidEmails.push({ invalidEmailIndex: i, emailToFix: emails[i] });
			}
		}

		$scope.getInvalidDomains = async function (userForm) {
			$scope.invalidDomains = [];
			$scope.textValue = userForm.domains.$viewValue;

			var domains = $scope.textValue.split("\n");

			for (var i = 0; i < domains.length; i++) {
				if (!domains[i] && i == domains.length - 1) continue;
				var isValid = await emailValidationService.isValidDomain(domains[i]);
				if (!isValid)
					$scope.invalidDomains.push({ invalidDomainIndex: i, domainToFix: domains[i] });
			}
		}

		$scope.fixInvalidEmail = function (userForm, email) {
			var emails = $scope.textValue.split("\n");

			emails[email.invalidEmailIndex] = email.emailToFix;

			$scope.textValue = emails.join("\n");
			userForm.addresses.$viewValue = $scope.textValue;
			userForm.addresses.$modelValue = $scope.textValue;
			userForm.addresses.$commitViewValue();
			userForm.addresses.$rollbackViewValue();

			$scope.getInvalidEmails(userForm);
		}

		$scope.fixInvalidDomain = function (userForm, domain) {
			var domains = $scope.textValue.split("\n");

			domains[domain.invalidDomainIndex] = domain.domainToFix;

			$scope.textValue = domains.join("\n");
			userForm.domains.$viewValue = $scope.textValue;
			userForm.domains.$modelValue = $scope.textValue;
			userForm.domains.$commitViewValue();
			userForm.domains.$rollbackViewValue();

			$scope.getInvalidDomains(userForm);
		}

		$scope.removeInvalidEmail = function (userForm, email) {
			var emails = $scope.textValue.split("\n");

			emails.splice(email.invalidEmailIndex, 1);

			$scope.textValue = emails.join("\n");
			userForm.addresses.$viewValue = $scope.textValue;
			userForm.addresses.$modelValue = $scope.textValue;
			userForm.addresses.$commitViewValue();
			userForm.addresses.$rollbackViewValue();

			$scope.getInvalidEmails(userForm);
		}

		$scope.removeInvalidDomain = function (userForm, domain) {
			var domains = $scope.textValue.split("\n");

			domains.splice(domain.invalidDomainIndex, 1);

			$scope.textValue = domains.join("\n");
			userForm.domains.$viewValue = $scope.textValue;
			userForm.domains.$modelValue = $scope.textValue;
			userForm.domains.$commitViewValue();
			userForm.domains.$rollbackViewValue();

			$scope.getInvalidDomains(userForm);
		}
	}

})();
