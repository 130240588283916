(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminConnectionsMainController", adminConnectionsMainController);

	function adminConnectionsMainController($scope, $filter, $q, $state, $http, $translate, errorHandling, $localStorage, coreData) {
		var vm = this;
		$state.current.data.pageTitle = $translate.instant("CURRENT_CONNECTIONS");
		switch ($localStorage.connectionsSection) {
			case "ALL_CONNECTIONS":
			case "WEBMAIL":
			case "SMTP":
			case "IMAP":
			case "POP":
			case "XMPP":
			case "ACTIVESYNC":
			case "MAPIEWS":
			case "WEBDAV":
				vm.selectedSection = $localStorage.connectionsSection;
				break;
			default:
				vm.selectedSection = "ALL_CONNECTIONS";
				break;
		}
		vm.gridSections = [
			{ translate: 'ALL_CONNECTIONS', length: 0 },
			{ translate: 'WEBMAIL', length: 0 },
			{ translate: 'SMTP', length: 0 },
			{ translate: 'IMAP', length: 0 },
			{ translate: 'POP', length: 0 },
			{ translate: 'XMPP', length: 0 },
			{ translate: 'ACTIVESYNC', length: 0 },
			{ translate: 'MAPI_EWS', length: 0 },
			{ translate: 'WEBDAV', length: 0 }
		];
		var serviceTypesToGet = [0, 1, 2, 7, 8, 9, 10, 12];

		// Functions
		vm.getConnections = getConnections;
		vm.changeSection = changeSection;

		activate();

		/////////////////////

		function activate() {
			coreData
				.init()
				.then(onSuccess, errorHandling.report);

			function onSuccess() {
				var promises = [];
				promises.push(vm.getConnections());
				$q.all(promises)
					.then(function (success) {
						$scope.isInit = true;
					}, function (failure) {
						errorHandling.report();
					});
			};

			$scope.$on('reloadConnections', getConnections);
		};

		function getConnections() {
			return $http
				.post('~/api/v1/settings/sysadmin/connections-count', JSON.stringify({ serviceTypes: serviceTypesToGet }))
				.then(function (success) {
					vm.gridSections[0].length = success.data.counts.webMail + success.data.counts.smtp + success.data.counts.imap + success.data.counts.pop + success.data.counts.xmpp + success.data.counts.activeSync + success.data.counts.mapiEws + success.data.counts.webdav;
					vm.gridSections[1].length = success.data.counts.webMail;
					vm.gridSections[2].length = success.data.counts.smtp;
					vm.gridSections[3].length = success.data.counts.imap;
					vm.gridSections[4].length = success.data.counts.pop;
					vm.gridSections[5].length = success.data.counts.xmpp;
					vm.gridSections[6].length = success.data.counts.activeSync;
					vm.gridSections[7].length = success.data.counts.mapiEws;
					vm.gridSections[8].length = success.data.counts.webdav;
				}, function (failure) {
					errorHandling.report(failure.data.message);
				});
		};

		function changeSection(section) {
			if (vm.selectedSection != section) {
				vm.selectedSection = section;
				$localStorage.connectionsSection = section;
			}
		};
	}
})();
