(function() {
	"use strict";

	angular
		.module("smartertools")
		.directive("validateDomainOrEmail", validateDomainOrEmail);

	function validateDomainOrEmail($q, emailValidationService) {
		return {
			require: "ngModel",
			link: function(scope, element, attrs, ctrl) {
				ctrl.$asyncValidators.validateDomainOrEmail = async function (modelValue, viewValue) {
					if (scope.$eval(attrs.validateDomainOrEmail) === false) return $q.resolve({ success: true });
					if (!viewValue) return $q.resolve({ success: true });

					var isValid = viewValue.indexOf("@") > -1
						? await emailValidationService.isValidEmail(viewValue)
						: await emailValidationService.isValidDomain(viewValue);
					return isValid
						? $q.resolve({ success: true })
						: $q.reject({ success: false });
				};
			}
		};
	}

})();
