jQuery.fn.extend({
	insertAtCaret: function (myValue, numToRemoveFirst) {
		return this.each(function (i) {
			if (document.selection) {
				//For browsers like Internet Explorer
				this.focus();
				sel = document.selection.createRange();
				sel.text = myValue;
				this.focus();
			}
			else if (this.selectionStart || this.selectionStart == '0') {
				//For browsers like Firefox and Webkit based
				var startPos = this.selectionStart;
				var endPos = this.selectionEnd;
				var scrollTop = this.scrollTop;
				this.value = this.value.substring(0, startPos - numToRemoveFirst) + myValue + this.value.substring(endPos, this.value.length);
				this.focus();
				this.selectionStart = startPos + myValue.length - numToRemoveFirst;
				this.selectionEnd = startPos + myValue.length - numToRemoveFirst;
				this.scrollTop = scrollTop;
			} else {
				this.value += myValue;
				this.focus();
			}
		})
	}
});


jQuery.fn.extend({
	htmlToPlainText: function (html) {
		var text = html.replace(/<br\s*[\/]?>/gi, "\n")
						.replace(/<\/div>/ig, '\n')
						.replace(/<\/li>/ig, '\n')
						.replace(/<\/ul>/ig, '\n')
						.replace(/<\/p>/ig, '\n')
						.replace(/<li>/ig, '  *  ')
						.replace(/<[^>]*>/g, '');
		return text;
	}
});

jQuery.fn.extend({
	getSelectionCoords: function (win) {
		win = win || window;
		var doc = win.document;
		var sel = doc.selection, range, rects, rect;
		var x = 0, y = 0;
		try{
			if (sel) {
				if (sel.type != "Control") {
					range = sel.createRange();
					range.collapse(true);
					x = range.boundingLeft;
					y = range.boundingTop;
				}
			} else if (win.getSelection) {
				sel = win.getSelection();
				if (sel.rangeCount) {
					range = sel.getRangeAt(0).cloneRange();
					if (range.getClientRects) {
						range.collapse(true);
						rects = range.getClientRects();
						if (rects.length > 0) {
							rect = rects[0];
						}
						x = rect.left;
						y = rect.top;
					}
					// Fall back to inserting a temporary element
					if (x == 0 && y == 0) {
						var span = doc.createElement("span");
						if (span.getClientRects) {
							// Ensure span has dimensions and position by
							// adding a zero-width space character
							span.appendChild(doc.createTextNode("\u200b"));
							range.insertNode(span);
							rect = span.getClientRects()[0];
							x = rect.left;
							y = rect.top;
							var spanParent = span.parentNode;
							spanParent.removeChild(span);

							// Glue any broken text nodes back together
							spanParent.normalize();
						}
					}
				}
			}
			return { x: x, y: y };
		}
		catch(ex)
		{
			return { x: 0, y: 0 };
		}
	}
});

jQuery.extend({
	shadeColor: function (color, percent) {
		var f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
		return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
	}
});

//TODO: Add support/test localization. Use http://momentjs.com/docs/#/customization/calendar/ for reference.
///Converts UTC time into friendly local time string
jQuery.extend({
	getFriendlyDate: function (date) {
		return moment.utc(date, "YYYY-MM-DD HH:mm").local()//return moment(+moment.utc(date, "YYYY-MM-DD HH:mm"))
				.calendar(null, {
					sameDay: '[Today] h:mma - M.D.YYYY',
					nextDay: '[Tomorrow] h:mma - M.D.YYYY',
					nextWeek: 'dddd h:mma - M.D.YYYY',
					lastDay: '[Yesterday] h:mma - M.D.YYYY',
					lastWeek: '[Last] dddd h:mma - M.D.YYYY',
					sameElse: 'dddd h:mma - M.D.YYYY'
				})
			}
});

jQuery.extend({
	getFriendlyTimespan: function (startDate, endDate) {
		var start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
		var end = endDate != undefined ? moment(startDate, "YYYY-MM-DD HH:mm:ss") : moment();
		var diff = start.diff(endDate);
		if (diff < 0) diff *= -1;
		var span = moment.duration(diff);

		if (span.years() > 0)
			return moment.duration(span).format("y[y] M[M]");
		else if (span.months() > 0)
			return moment.duration(span).format("M[M] d[d]");
		else if (span.days() > 0)
			return moment.duration(span).format("d[d] h[h]");
		else if (span.hours() > 0)
			return moment.duration(span).format("h[h] m[m]");
		else
			return moment.duration(span).format("m[m] s[s]");
	}
});

_.mixin({
	trim: function (str, characters) {
		return str.replace(new RegExp('^[' + characters + ']+|[' + characters + ']+$', 'g'), '');
	},
	clean: function (str) {
		return str.trim().replace(/\s\s+/g, ' ');
	}
});