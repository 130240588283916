(function () {
    angular.module("smartertools").filter('htmlEscape', function () {
        return function (str) {
            return String(str)
                .replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#39;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/\//g, '&#x2F;');
        };
    });
})();

(function () {
    angular.module("smartertools").filter('htmlUnescape', function () {
        return function (str) {
            return String(str)
                .replace(/&quot;/g, '"')
                .replace(/&#39;/g, "'")
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/&#x2F;/g, '/');
        };
    });
})();