(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityAdministratorsController", sysAdminSecurityAdministratorsController);

	function sysAdminSecurityAdministratorsController($rootScope,
		$scope,
		$http,
		$filter,
		errorHandling,
		unsavedChangesTrackingService) {
		var vm = this;
		vm.gridSections = [
			{ translate: "ADMINISTRATORS", length: -1 },
			{ translate: "SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS", length: -1 }
		];
		vm.selectedSection = "ADMINISTRATORS";
		vm.changeSection = function(section) {
			if (unsavedChangesTrackingService.doConfirmDialog) {
				unsavedChangesTrackingService
					.doConfirmDialog()
					.then(function() {
							unsavedChangesTrackingService.doConfirmDialog = undefined;
							vm.selectedSection = section;
						},
						function() {});
			} else {
				vm.selectedSection = section;
			}

		};

		activate();

		//////////////////////

		function activate() {
		}

	}
})();
