(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminConnectionsEditBlacklistingController", sysAdminConnectionsEditBlacklistingController);

	function sysAdminConnectionsEditBlacklistingController($scope, $mdDialog, $translate, $timeout, addresses, smtp, pop, imap, xmpp, description, fromGrid, readOnly) {

		var vm = this;

		$scope.addresses = addresses;
		$scope.smtp = smtp;
		$scope.pop = pop;
		$scope.imap = imap;
		$scope.xmpp = xmpp;
		$scope.description = description;
		$scope.fromGrid = fromGrid;
		$scope.isValid = true;
		$scope.isSwitchSelected = true;
		$scope.readOnly = readOnly;
		$scope.description = $translate.instant($scope.description);

		$timeout(function () { vm.userForm.$setDirty(); }, 100);

		$scope.save = function (scope) {
			if (!($scope.isValid = scope.userForm.$valid && validateSwitches())) {
				return;
			}

			$mdDialog.hide({
				address: $scope.addresses,
				smtp: $scope.smtp,
				pop: $scope.pop,
				imap: $scope.imap,
				xmpp: $scope.xmpp,
				description: $scope.description
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		function validateSwitches() {
			$scope.isSwitchSelected = $scope.smtp || $scope.pop || $scope.imap || $scope.xmpp;
			return $scope.isSwitchSelected;
		}
	}

})();