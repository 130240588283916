(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("browserNotifications", browserNotifications);

    function browserNotifications($q, $mdDialog, $log, $sanitize, $timeout, $state, $filter, $translate, coreDataSettings, popupService, emailNavigation, i18n) {
        var PERMISSION_GRANTED = "granted";
        var PERMISSION_DENIED = "denied";
        var PERMISSION_UNSET = "default";
        var GROUP_MESSAGE_NOTIFICATIONS_MS = 15000;
        var vm = this;
        vm.supported = null;
        vm.permission = PERMISSION_UNSET;
        vm.messageNotificationInfo = {
            lastSent: null,
            count: 0,
            popupTimeout: null,
            message: null
        }

        // Functions
        vm.fetchPermission = fetchPermission;
        vm.show = show;
        vm.notifyOfNewMessage = notifyOfNewMessage;
        vm.checkPermissions = checkPermissions;

        activate();

        /////////////////////

        function activate() {
            checkPermissions();
        }

        function checkPermissions() {
            if (vm.supported === null && (typeof Notification === 'undefined')) {
                vm.permission = PERMISSION_UNSET;
                vm.supported = false;
                return;
            }

            vm.supported = true;
            vm.permission = Notify.needsPermission ? (Notification.permission === PERMISSION_DENIED ? PERMISSION_DENIED : PERMISSION_UNSET) : PERMISSION_GRANTED;
        }

        var notificationDialogPromise = null;

        function fetchPermission() {
            //// Removed this because if a user hit the x on the permissions dialog pressing OK on the wizard would no longer do anything
            //if (notificationDialogPromise)
            //	return notificationDialogPromise.promise;

            notificationDialogPromise = $q.defer();
            var tempPromise = notificationDialogPromise;

            if (!vm.supported) {
                notificationDialogPromise.reject();
                notificationDialogPromise = null;
            }
            else if (vm.permission === PERMISSION_GRANTED) {
                notificationDialogPromise.resolve();
                notificationDialogPromise = null;
            }
            else if (vm.permission === PERMISSION_DENIED) {
                notificationDialogPromise.reject();
                notificationDialogPromise = null;
            }
            else {
                askPermissionFromBrowser();
            }

            function askPermissionFromBrowser() {
                window.Notification.requestPermission(function (permission) {
                    switch (permission) {
                        case "granted":
                            Notify.needsPermission = false;
                            onPermissionGranted();
                            break;
                        default:
                            Notify.needsPermission = true;
                            onPermissionDenied();
                    }
                });
            }

            function onPermissionGranted() {
                vm.permission = PERMISSION_GRANTED;
                notificationDialogPromise.resolve();
                notificationDialogPromise = null;
            }

            function onPermissionDenied() {
                vm.permission = PERMISSION_DENIED;
                notificationDialogPromise.reject();
                notificationDialogPromise = null;
            }

            return tempPromise.promise;
        }

        function show(title, options) {
            options = options || {};
            fetchPermission().then(onPermissionRetreived, onNoPermission);

            function onPermissionRetreived() {
                if (!options.icon && !options.image)
                    options.icon = (stSiteRoot || '/') + 'favicon.ico';
                if (options.closeOnClick === undefined || options.closeOnClick === null)
                    options.closeOnClick = true;

                if (options.notifyClick) {
                    var v = options.notifyClick;
                    options.notifyClick = function (e) {
                        v(this.myNotify ? this.myNotify.data : null);
                    }
                }

                title = title || $translate.instant('NA');
                options.body = options.body;

                var myNotification = new Notify(title, options);
                myNotification.show();
            }

            function onNoPermission() { }
        }

        function notifyOfNewMessage(message) {
            if (!message || !message.from || !message.folder || !message.uid)
                return;
            if (message.folder.toLowerCase() != "inbox" || message.ownerEmailAddress)
                return;
            if (!coreDataSettings.userSettings.notifyOnNewEmails)
                return;

            vm.messageNotificationInfo.count++;
            vm.messageNotificationInfo.message = message;
            if (!vm.messageNotificationInfo.popupTimeout) {
                var now = new Date();
                var msSinceLastSent = vm.messageNotificationInfo.lastSent ? (now.getTime() - vm.messageNotificationInfo.lastSent.getTime()) : 9999999999;
                var shouldSendFast = msSinceLastSent >= GROUP_MESSAGE_NOTIFICATIONS_MS;
                var timeToWait = (shouldSendFast ? 500 : GROUP_MESSAGE_NOTIFICATIONS_MS) - msSinceLastSent;
                if (timeToWait < 250)
                    timeToWait = 250;
                vm.messageNotificationInfo.popupTimeout = $timeout(onNotifyNewMessagesTimer, timeToWait);
            }
        }

        function onNotifyNewMessagesTimer() {
            if (vm.messageNotificationInfo.count == 1 && vm.messageNotificationInfo.message) {
                showNewMessageNotification(vm.messageNotificationInfo.message);
            }
            else if (vm.messageNotificationInfo.count > 1) {
                var options = {
                    body: i18n.reactPluralizeWithCommas('NOTIFICATIONS_NEW_MESSAGES_BODY_X', { count: vm.messageNotificationInfo.count }),
                    icon: (stSiteRoot || '/') + 'interface/img/notifications/email.png',
                    tag: "email",
                    timeout: 5000,
                    notifyClick: function (e) {
                        window.focus();
                        $state.go("index.email");
                    }
                };
                show($filter('translate')('EMAIL'), options);
            }

            vm.messageNotificationInfo.popupTimeout = null;
            vm.messageNotificationInfo.count = 0;
            vm.messageNotificationInfo.lastSent = new Date();
            vm.messageNotificationInfo.message = null;
        }

        function showNewMessageNotification(message) {
            var fromName = message.from.name != "" ? message.from.name : message.from.email;
            var options = {
                body: message.subject,
                icon: (stSiteRoot || '/') + 'interface/img/notifications/email.png',
                tag: message.uid,
                timeout: 5000,
                priority: 1,
                notifyClick: function (e) {
                    const packet = emailNavigation.makeEmailPacket(message.ownerEmailAddress, message.folder, message.uid, undefined, { markRead: true });
                    const url = emailNavigation.getPopoutUrl(packet);
                    var win = window.open(url, "emailID" + message.uid, 'resizable=1, ' + popupService.dimensions.email);
                    window.blur();
                    win.focus();
                    $timeout(function () { win.focus() });
                }
            };
            show(fromName, options);
        }
    }

})();