(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("authIpsController", authIpsController);

    function authIpsController($scope, $http, $filter, $mdDialog, $translate, errorHandling, selectedUserEmailAddress) {
        var vm = this;
        $scope.close = close;
        $scope.emailAddress = decodeURIComponent(selectedUserEmailAddress);
        $scope.authenticatedIps = [];
        $scope.isEmpty = true;

        getIps();

        function getIps() {
            $http.post('~/api/v1/settings/domain/authenticated-ips', JSON.stringify({ emailAddress: $scope.emailAddress }))
                .then(function (success) {
                    $scope.authenticatedIps = success.data.authenticatedIps || [];
                    $scope.isEmpty = Object.keys($scope.authenticatedIps).length > 0 ? false : true;
                    angular.forEach($scope.authenticatedIps, function (collection) {
                        angular.forEach(collection, function (item) {
                            item.lastLogin = $filter('date')(item.lastLogin, 'short');
                            if (!item.location || item.location == "N/A")
                                item.location = null;
                            else if (item.location.length == 2)
                                item.location = $translate.instant("COUNTRY_CODE_" + item.location);
                        })
                    });
                }, errorHandling.report);
        }

        function close() {
            $mdDialog.cancel();
        }
    }

})();