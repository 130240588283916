(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedSettingsMailingListOptionsController", sharedSettingsMailingListOptionsController);

	function sharedSettingsMailingListOptionsController($rootScope, $scope, $state, $http, $mdDialog, $filter, $compile,
		coreDataDomainSettings, errorHandling, successHandling, NgTableParams, gridCheckboxes, claimsService, i18n) {
		var vm = this;
		vm.canAddMailingListAccounts = claimsService.isSysAdmin() || claimsService.isDomainAdmin();
		vm.settingCore = coreDataDomainSettings;
		vm.mailingListHostHeader = null;
		vm.mailingListBaseUrlEnabled = null;
		vm.disableMailingListDeliveryAfterXBounces = null;
		vm.disableMailingListDeliveryAfterBouncesInXDays = null;
		vm.enableMailingListDayThreshold = null;
		vm.mailingLists = [];
		vm.customFields = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { listAddress: 'asc' },
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.newMailingList = newMailingList;
		vm.deleteMailingLists = deleteMailingLists;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.editList = adminEditList;

		activate();

		///////////////////////

		function activate() {
			$http.get('~/api/v1/settings/domain/mailing-lists/list')
				.then(onLoadCompleted, onLoadFailed);

			function onLoadCompleted(result) {
				vm.mailingLists = result.data.items || [];
				vm.tableParams.settings({ dataset: vm.mailingLists });
				vm.initialized = true;
				refreshTable();
			}

			function onLoadFailed(failure) {
				$state.go("^");
				errorHandling.report(failure);
			}

			$scope.$on('signalR.mailHub.client.listModeratorChanged', function (event, data) {
                refreshMailingLists();
			});
		}

		function adminEditList(selectedCard, event) {
			if (selectedCard.status === 3) {
				$mdDialog
					.show({
						locals: { listName: selectedCard.listAddress },
						controller: "listFailedToLoadDialogController",
						controllerAs: "ctrl",
						templateUrl: "app/settings/domain-settings/users/list-failed-to-load.dlg.html",
						targetEvent: event
					})
					.then(
						function (result) {
							if (!result) return;
							refreshMailingLists();
						},
						function () { });
			}
			else {
				$state.go(".specific-list", { mailingListId: selectedCard.id });
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function searchItems() {
			vm.checkboxes.reset();
			vm.tableParams.filter({ $: vm.searchText });
			vm.tableParams.reload();
		}

		function refreshMailingLists() {
			$http
				.get('~/api/v1/settings/domain/mailing-lists/list')
				.then(function (result) {
					vm.mailingLists = result.data.items || [];
					vm.tableParams.settings({ dataset: vm.mailingLists });
					refreshTable();
				});
		}

		function newMailingList(event) {
			$mdDialog.show({
				locals: {},
				controller: "sharedNewMailingListController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/new-list.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					var params = {
						listAddress: modalSuccess.listAddress,
						moderatorAddress: modalSuccess.moderator
					};
					$http.post('~/api/v1/settings/domain/mailing-lists/add', JSON.stringify(params))
						.then(function (success) {
							$state.go(".specific-list", { mailingListId: success.data.item.id });
						}, errorHandling.report);
				}, function () {});
		}

		function deleteMailingLists(event) {
			var ids = vm.checkboxes.getCheckedItems();
			if (!ids || !angular.isArray(ids) || ids.length === 0)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: ids.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				$rootScope.spinner.show();
				return $http
					.post("~/api/v1/settings/domain/mailing-lists/delete-bulk", JSON.stringify({ ints: ids }))
					.then(function() {
						refreshMailingLists();
						successHandling.report();
                        $rootScope.$broadcast("refresh-users-counts");
					}, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			if (!vm.canAddMailingListAccounts)
                return; 

			ev.stopPropagation();
			ev.preventDefault();

			var lists = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((lists.length > 1 && !_.some(lists, function (val) { return val == item.id; })) || lists.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				lists = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteMailingLists', click: deleteMailingLists, params: $scope.dropdownEvent, translateKey: 'DELETE' },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}
	}
})();
