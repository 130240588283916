(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("userSettingEnums", userSettingEnums);

	/* @ngInject */
	function userSettingEnums($rootScope, $translate, $q, $http, coreDataSettings) {
		var vm = this;
		vm.authOptions = [
			{ value: 0, translation: 'SMARTERMAIL' },
			{ value: 1, translation: 'ACTIVE_DIRECTORY' }
		];

		vm.autoSaveOptions = [
			{ value: 0, translation: 'NONE' },
			{ value: 60000, translation: '1_MINUTES' },
			{ value: 120000, translation: '2_MINUTES' },
			{ value: 180000, translation: '3_MINUTES' },
			{ value: 300000, translation: '5_MINUTES' }
		];

		vm.calCleanOptions = [
			{ value: -2, translation: 'DOMAIN_DEFAULT' },
			{ value: -1, translation: 'NEVER' },
			{ value: 3, translation: 'X_MONTHS_other' },
			{ value: 6, translation: 'X_MONTHS_other' },
			{ value: 12, translation: 'X_MONTHS_other' },
			{ value: 18, translation: 'X_MONTHS_other' },
            { value: 24, translation: 'X_MONTHS_other' }
		];

		vm.deleteOptions = [
			{ value: 0, translation: 'MOVE_TO_DELETED' },
			{ value: 1, translation: 'PERMANENTLY_DELETE' },
			{ value: 2, translation: 'MARK_DELETED' }
		];

		vm.encodingOptions = [];

		vm.fontOptions = [
			{ value: 'arial', translation: 'ARIAL' },
			{ value: 'comic sans ms', translation: 'COMIC_SANS_MS' },
			{ value: 'courier new', translation: 'COURIER_NEW' },
			{ value: 'georgia', translation: 'GEORGIA' },
			{ value: 'lucida', translation: 'LUCIDA' },
			{ value: 'tahoma', translation: 'TAHOMA' },
			{ value: 'times new roman', translation: 'TIMES_NEW_ROMAN' },
			{ value: 'trebuchet ms', translation: 'TREBUCHET_MS' },
			{ value: 'verdana', translation: 'VERDANA' }
		];

		vm.fontSizeOptions = [
			{ value: '8px', translation: 8 },
			{ value: '9px', translation: 9 },
			{ value: '1px', translation: 10 },
			{ value: '11px', translation: 11 },
			{ value: '12px', translation: 12 },
			{ value: '14px', translation: 14 },
			{ value: '16px', translation: 16 },
			{ value: '18px', translation: 18 },
			{ value: '20px', translation: 20 },
			{ value: '22px', translation: 22 },
			{ value: '24px', translation: 24 },
			{ value: '26px', translation: 26 },
			{ value: '28px', translation: 28 },
			{ value: '36px', translation: 36 },
			{ value: '48px', translation: 48 },
			{ value: '72px', translation: 72 }
		];

		vm.forwardOptions = [
			{ value: 'normal', translation: 'NORMAL' },
			{ value: 'plain', translation: 'TEXT' },
			{ value: 'attachment', translation: 'EMBED_AS_ATTACHMENT' }
		];

		vm.plusAddressOptions = [
			{ value: 'disabled', translation: 'DISABLED' },
			{ value: 'autocreate', translation: 'MOVE_TO_FOLDER' },
			{ value: '', translation: 'MOVE_TO_FOLDER_EXISTS' },
			{ value: 'inbox', translation: 'LEAVE_IN_INBOX' }
		];

		vm.previewPaneOptions = [
			{ value: 'right', translation: 'RIGHT' },
			{ value: 'none', translation: 'NONE' }
		];

		vm.replyHeaderOptions = [
			{ value: 'Basic', translation: 'BASIC' },
			{ value: 'Full', translation: 'FULL' }
		];

		vm.sortOptions = [
			{ value: 'From', translation: 'FROM' },
			{ value: 'Subject', translation: 'SUBJECT' },
			{ value: 'Size', translation: 'SIZE' },
			{ value: 'InternalDate', translation: 'DATE' }
		];

		vm.sortOrder = [
			{ value: true, translation: 'DESCENDING' },
			{ value: false, translation: 'ASCENDING' },
		];

		vm.timeZoneOptions = [];

		vm.throttleOptions = [
			{ value: 2, translation: 'NONE' },
			{ value: 1, translation: 'DELAY' },
			{ value: 0, translation: 'REJECT' }
		];

		vm.userStatusOptions = [
			{ value: { isEnabled: true, canReceiveMail: true }, translation: 'ENABLED' },
			{ value: { isEnabled: false, canReceiveMail: true }, translation: 'DOMAIN_USERS_DISABLED_ALLOW_MAIL' },
			{ value: { isEnabled: false, canReceiveMail: false }, translation: 'DOMAIN_USERS_DISABLED_DISALLOW_MAIL' }
		];

		vm.availableIndexers = ['LUCENE_BASE'];


		// Functions
		vm.findOptionByValue = findOptionByValue;
		vm.findOptionTextByValue = findOptionTextByValue;
		vm.findOptionTextByIndexValue = findOptionTextByIndexValue;
		vm.findEncodingOptionTextByValue = findEncodingOptionTextByValue;
		vm.init = init;

		activate();

		///////////////////////////

		function activate() {
			init()
				.then(function () {
					$rootScope.$on('$translateChangeSuccess', refreshItems);
				}, function () {});
		}

		function init() {
			var deferredItem = $q.defer();
			$q.all([
				coreDataSettings.settingsData.availiableTimeZones,
				$http.get('~/api/v1/settings/available-indexers')
			]).then(function (result) {
				vm.timeZoneOptions = result[0];
				vm.availableIndexers = result[1].data.indexers;
				refreshItems();
				deferredItem.resolve();
			}, deferredItem.reject);
			return deferredItem.promise;
		}

		function findOptionByValue(arr, val) {
			for (var i = 0; i < arr.length; i++) {
				var arrVal = arr[i].value;
				if (arrVal == undefined) { arrVal = arr[i]; }
				if (arrVal == val)
					return arr[i];
			}
			return null;
		}

		function findOptionTextByValue(arr, val) {
			for (var i = 0; i < arr.length; i++) {
				var arrVal = arr[i].value;
				if (arrVal == undefined) { arrVal = arr[i]; }
				if (arrVal == val)
					return arr[i].translated || $translate.instant(arr[i]);
			}
			return null;
		}

		function findOptionTextByIndexValue(arr, val) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].index == val)
					return arr[i].translated || $translate.instant(arr[i]);
			}
			return null;
		}

		function findEncodingOptionTextByValue(arr, val) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].code == val)
					return arr[i].translated || $translate.instant(arr[i]);
			}
			return null;
		}

		function refreshItems() {
			angular.forEach(vm.encodingOptions, function (value) {
				value.translated = $translate.instant(value.name);
			});
			angular.forEach(vm.timeZoneOptions, function (value) {
				value.translated = $translate.instant(value.displayName);
			});
			translateItems(vm.authOptions);
			translateItems(vm.autoSaveOptions);
			translateItems(vm.deleteOptions);
			translateItems(vm.fontOptions);
			translateItems(vm.fontSizeOptions);
			translateItems(vm.forwardOptions);
			translateItems(vm.plusAddressOptions);
			translateItems(vm.replyHeaderOptions);
			translateItems(vm.sortOptions);
			translateItems(vm.sortOrder);
			translateItems(vm.throttleOptions);
			translateItems(vm.userStatusOptions);
			translateItems(vm.previewPaneOptions);

			angular.forEach(vm.calCleanOptions, function (value) {
				if (value.value > 0)
					value.translated = $translate.instant(value.translation, { count: value.value });
				else
					value.translated = $translate.instant(value.translation);
			});
		}

		function translateItems(arr) {
			angular.forEach(arr, function (value) {
				value.translated = $translate.instant(value.translation);
			});
		}

	}

})();