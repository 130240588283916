(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("rspamdSpamCtrl", rspamdSpamCtrl);

    function rspamdSpamCtrl($rootScope, $scope, $mdDialog, $http, errorHandling, cardType) {
        $scope.type = cardType;
        activate();

        function activate() {
            $rootScope.spinner.show();
            $http
                .get("~/api/v1/settings/sysadmin/antispam/settings")
                .then(onLoad, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onLoad(result) {
                $scope.item = angular.copy(result.data.settings.remoteRspamd);
            }
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        }

        $scope.save = function () {
            if (!$scope.spamForm.$valid)
                return;

            var params = JSON.stringify({ remoteRspamd: $scope.item });
            $rootScope.spinner.show();
            $http
                .post("~/api/v1/settings/sysadmin/antispam/settings", params)
                .then($mdDialog.hide, errorHandling.report)
                .finally($rootScope.spinner.hide);
        }
    }
})();