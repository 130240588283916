(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminUserDefaultsController", sysAdminUserDefaultsController);

    function sysAdminUserDefaultsController($rootScope, $http, $timeout, $mdDialog, $q, $state,
        coreLicensing, coreDataLicensing, userSettingEnums, errorHandling, localeInfoService) {

        var vm = this;
        vm.timeZoneSearch = "";
        vm.textEncodeSearch = "";
        vm.isLoaded = false;
        vm.mailboxSpaceInput = 0;
        vm.Licensing = [];
        vm.availableLocales = [];
        vm.enableGreylisting = false;

        // Functions
        vm.clearSearchTerm = function () { vm.timeZoneSearch = ""; vm.textEncodeSearch = ""; };
        vm.openPropagateModal = openPropagateModal;
        vm.save = save;
        vm.cancel = cancel;
        vm.navigateUp = function (form) { form.$setPristine(); $state.go("^"); }
        vm.data = undefined;
        vm.mailForwardingChanged = mailForwardingChanged;

        activate();

        //////////////////////////////////

        function activate() {
            userSettingEnums.init().then(function () {
                vm.authOptions = userSettingEnums.authOptions;
                vm.autoSaveOptions = userSettingEnums.autoSaveOptions;
                vm.deleteOptions = userSettingEnums.deleteOptions;
                vm.encodingOptions = userSettingEnums.encodingOptions;
                vm.fontOptions = userSettingEnums.fontOptions;
                vm.fontSizeOptions = userSettingEnums.fontSizeOptions;
                vm.forwardOptions = userSettingEnums.forwardOptions;
                vm.plusAddressOptions = userSettingEnums.plusAddressOptions;
                vm.previewPaneOptions = userSettingEnums.previewPaneOptions;
                vm.replyHeaderOptions = userSettingEnums.replyHeaderOptions;
                vm.sortOptions = userSettingEnums.sortOptions;
                vm.startupOptions = userSettingEnums.startupOptions;
                vm.throttleOptions = userSettingEnums.throttleOptions;
                vm.timeZoneOptions = userSettingEnums.timeZoneOptions;
                vm.userStatusOptions = userSettingEnums.userStatusOptions;
                vm.availableIndexers = userSettingEnums.availableIndexers;
                loadDefaults();
            }, function () { });

            for (var i = 0; i < angularLangNames.length; i++) {
                var obj = {
                    n: angularLangNames[i].n,
                    v: angularLangNames[i].v,
                    engName: localeInfoService.getEnglishName(angularLangNames[i].v)
                }
                vm.availableLocales.push(obj);
            }

            coreDataLicensing
                .init()
                .then(onLicensingLoaded, errorHandling.report);

            function onLicensingLoaded() {
                vm.Licensing = angular.copy(coreDataLicensing.Data.Licensing);
            }
        }

        function loadDefaults() {
            $rootScope.spinner.show();

            const promises = [
                $http.get("~/api/v1/settings/sysadmin/user-defaults"),
                $http.get("~/api/v1/settings/sysadmin/antispam/settings")
            ];

            $q.all(promises)
                .then(onLoadSuccessful, errorHandling.report)
                .finally(function () {
                    vm.isLoaded = true;
                    $rootScope.spinner.hide();
                    $rootScope.$broadcast("masonry:contentsChanged");
                });

            function onLoadSuccessful(success) {
                vm.data = success[0].data.settings;
                vm.enableGreylisting = !vm.data.disableGreylisting;
                vm.mailboxSpaceInput = vm.data.maxMailboxSize / (1024 * 1024);
                vm.previewPaneOption = vm.data.previewUsePreview ? "right" : "none";
                if (!coreLicensing.hasEnterpriseFunctionality && vm.data && vm.data.services) {
                    vm.data.services.xmpp = false;
                    vm.data.enableEas = false;
                    vm.data.enableMapiEws = false;
                } else if (!coreLicensing.hasEnterpriseFunctionality || !coreLicensing.isActivated) {
                    vm.data.enableEas = false;
                }

                $timeout(function () {
                    $("#timeZoneSearch").on("keydown", function (ev) { ev.stopPropagation(); });
                    $("#textEncodeSearch").on("keydown", function (ev) { ev.stopPropagation(); });
                });

                vm.canChangeGreylist = success[1].data.settings.greylisting.allowUserOverride;
            }
        };

        function save(event, form) {
            if (!form.$valid)
                return;

            vm.data.previewUsePreview = vm.previewPaneOption !== "none";
            vm.data.maxMailboxSize = vm.mailboxSpaceInput * (1024 * 1024);
            vm.data.disableGreylisting = !vm.enableGreylisting;

            $rootScope.spinner.show();
            $http
                .post("~/api/v1/settings/sysadmin/user-defaults", JSON.stringify(vm.data))
                .then(onSaveSuccess, errorHandling.report)
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onSaveSuccess() {
                form.$setPristine();
                form.$setUntouched();
            }
        }

        function cancel(event, form) {
            loadDefaults();
            form.$setPristine();
        }

        function openPropagateModal(ev) {
            vm.data.previewPaneOption = vm.previewPaneOption;

            $mdDialog.show({
                locals: {
                    modUser: vm.data
                },
                controller: "sysAdminPropagateUsersController",
                controllerAs: "PropCtrl",
                templateUrl: "app/sysadmin/manage/domains/modals/user-propagation.dlg.html",
                targetEvent: ev
            });
        };

        function mailForwardingChanged() {
            if (vm.data.mailForwarding) {
                vm.data.keepRecipients = true;
            }
        }
    }

})();
