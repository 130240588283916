/*
 * Use Examples:
 * <st-avatar-icon name="message.fromAddress.name" image-url="message.avatarUrl" width="64" height="64"></st-avatar-icon>
 */

(function () {
	"use strict";

	angular
		.module('smartermail')
		.component('stStatusAvatarIcon', {
			controller: stStatusAvatarIconController,
			bindings: {
				imageUrl: "<",
				name: "<",
				width: "<",
				height: "<",
				elementId: "@",
				noBorder: "<",
				preLoading: "<",
				contactType: "<",
				contactEmailList: "<"
			},
			template: `
                <div class="st-avatar-status-container">
                    <st-avatar-icon name="$ctrl.name" element-id="$ctrl.elementId" image-url="$ctrl.imageUrl" width="$ctrl.width" height="$ctrl.height"></st-avatar-icon>

                    <div ng-if="$ctrl.chat.showStatus && $ctrl.chat.status">
                        <div class="chat-status-icon" ng-class="$ctrl.getIconClass()"/>
                    </div>
                </div>`
		});

	function stStatusAvatarIconController($scope, $timeout, chatProviderFactory) {
		var vm = this;
		let _chatStatus = null;

		vm.chat = {
			get showStatus() {
				return vm.contactEmailList && vm.contactEmailList.length > 0 && chatProviderFactory.connected;
			},
			get status() {
				return _chatStatus;
			},
			set status(value) {
				_chatStatus = value === "room" ? null : value;
			}
		}
		/////////////////
		vm.getIconClass = function() {
			let className = null;
			switch (vm.chat.status) {
				case "offline":
					className = "status-offline";
					break;
				case "available":
				case "online":
					className = "status-available";
					break;
				case "away":
					className = "status-away";
					break;
				case "dnd":
				case "busy":
					className = "status-dnd";
					break;
			}
			return className;
		}

		vm.$onInit = function () {
			getChatStatus({ all: true });
		};
		$scope.$on('chat.user-status-changed', (e, data) => {
			if (!vm.chat.showStatus)
				return;
			getChatStatus(data);
		});
		function getChatStatus(data) {
			if (!vm.chat.showStatus)
				return;
			if (data.all) {
				const emailWithStatus =
					vm.contactEmailList.find(email => chatProviderFactory.getUserStatus(email));
				if (emailWithStatus) {
					$scope.$applyAsync(function() {
						vm.chat.status = chatProviderFactory.getUserStatus(emailWithStatus);
					});
				}
			} else if (data.email && data.status && vm.contactEmailList.includes(data.email)) {
				$scope.$applyAsync(function() {
					vm.chat.status = data.status;
				});
				$scope.$apply();
			}
		}


	}
})();