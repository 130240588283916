(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("validateDirectoryAllowRoot", [validateDirectoryAllowRoot]);

    function validateDirectoryAllowRoot() {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$validators.requiresNoUncAllowRoot = function (modelValue, viewValue) {
                    if (!viewValue) { return true; }
                    if (window.stOS == 'L')
                        return requiresNoUncAllowRootLinux(viewValue);
                    else
                        return requiresNoUncAllowRootWindows(viewValue);

                };
            }
        };

        function requiresNoUncAllowRootLinux(viewValue) {
            // Ensure folder starts with / or ~/
            if (!/^(\/|~\/)/.test(viewValue))
                return false;

            // Ensure folder does not only contain / or ~/
            if (/^(\/|~\/)$/.test(viewValue))
                return false;

            // Check each part to make sure it doesn't have invalid characters
            let parts = viewValue.split('/');
            for (var i = 0; i < parts.length; ++i) {
                let part = parts[i];
                if (i == 0 && (part == '~' || part == ''))
                    continue;

                if (part.length == 0 && i != parts.length - 1)
                    return false;
                if (part.startsWith("."))
                    return false;
                let regexAllowedChars = /^[a-zA-Z0-9._-]*$/;
                if (!regexAllowedChars.test(parts[i]))
                    return false;
            }

            return true;
        }

        function requiresNoUncAllowRootWindows(viewValue) {
            //if the directory doesnt start with *:\ or \\
            if (!(/((^[a-zA-Z]:\\)|(^\\\\[^\\]))/).test(viewValue)) { return false; }
            //if the directory only contains *:\ or \\
            if ((/((^[a-zA-Z]:\\$)|(^\\\\[^\\]$))/).test(viewValue)) { return false; }
            //split the directory into its parts and check if any of the parts besides the *:\ contain invalid characters.
            let parts = [];
            if (/^\\\\/.test(viewValue)) {
                parts = viewValue.substring(2).split('\\');
            } else {
                parts = viewValue.substring(3).split('\\');
                if (parts.length < 1) { return false; }
            }
            // [\/\:\*\?\"\<\>\|] //invalid characters
            // ^[.]+|[.]+$ //folder contains only . or starts/ends with a .
            for (var i = 0; i < parts.length; ++i) {
                if ((/[\/\:\*\?\"\<\>\|]|^[.]+|[.]+$/).test(parts[i])) { return false; }
                //any empty fragments in the path ex. test////test. Don't check the last part as c:\test\ should be valid.
                if (i != parts.length - 1 && (/^$/).test(parts[i])) { return false; }
            }
            return true;
        }
    }
})();