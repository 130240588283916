(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("spoolDropConnectionsController", spoolDropConnectionsController);

	function spoolDropConnectionsController($scope, $mdDialog, address) {
		$scope.address = address;

		$scope.save = function () {
			$mdDialog.hide();
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
