(function () {
    angular
        .module("smartertools")
        .filter('roundNumUp', roundNumUp);

    function roundNumUp($filter) {
        return function (input, places) {
            if (isNaN(input)) return input;
            var factor = "1" + Array(+(places > 0)).join("0");
            return Math.ceil(input * factor) / factor;
        };
    }
})();