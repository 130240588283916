(function () {
    'use strict';

    angular
        .module('smartermail')
        .directive('contactCard', contactCardDirective);

    function contactCardDirective() {
        return {
            restrict: 'EA',
            templateUrl: 'app/contacts/contact-card.html',
            controller: function ($scope, $translate) {

                $scope.contactCard = {};
                $scope.contactCard.sharedResourceCountString = ($scope.contact.contactType == 1 || $scope.contact.contactType == 2)
                    ? $translate.instant("SHARED_RESOURCES_GROUP_COUNT", { count: $scope.contact.groupedContacts.length })
                    : "";

                if ($scope.contact.categories && $scope.contact.categories.length) {
                    $scope.contact.categories.forEach(cat => {
                        const translated = $translate.instant(cat.name);
                        cat.translatedName = $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
                    });
                }
            }
        };
    }

})();
