(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminManageIndexStatusController", sysAdminManageIndexStatusController);

	function sysAdminManageIndexStatusController($rootScope, $scope, $filter, $q, $http,
		coreDataManageSettings, errorHandling, successHandling, NgTableParams) {
		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { currentStatus: 'desc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.status = {
			0: "NONE",
			1: "QUEUED",
			2: "INDEXING",
			3: "OPTIMIZING",
			4: "COMPLETED"
		};

		// Functions
		vm.searchItems = searchItems;
		vm.refresh = refresh;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
			$scope.$emit("troubleshooting:countersChanged");
		}

		function loadItems() {
			return $q
				.all([
					$http.get('~/api/v1/settings/sysadmin/index-processing'),
					$http.get('~/api/v1/settings/sysadmin/index-queued')
				])
				.then(onDataLoaded, errorHandling.report);

			function onDataLoaded(results) {
				var items = [];
				angular.forEach(results[0].data.indexItems, function (value) { items.push(value); });
				angular.forEach(results[1].data.indexItems, function (value) { items.push(value); });
				vm.items = items;
				vm.tableParams.settings({ dataset: vm.items });
				refreshTable();
			}
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function refreshTable() {
			vm.tableParams.reload();
		}

		function refresh() {
			loadItems()
				.then(function() {}, function() {})
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
			$rootScope.$broadcast("troubleshooting:countersChanged");
		};
	}

})();
