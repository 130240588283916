(function () {

	angular
		.module("smartermail")
		.service("notifications", notifications);

	function notifications($rootScope, $timeout, $log, $q, $http, $mdDialog, $sessionStorage, $localStorage) {
		// Data
		var vm = this;
		vm.items = [];
		vm.itemCounts = {};

		// Functions
		vm.getNotifications = getNotifications;
		vm.getNotificationCount = getNotificationCount;
		vm.openNotificationDialog = openNotificationDialog;
        vm.dismiss = dismiss;
        vm.dismissAll = dismissAll;
		vm.snooze = snooze;
		vm.reset = reset;

		// Startup
		$rootScope.$on("Authentication:Logout", reset);
		$rootScope.$on('signalR.mailHub.client.notificationChanged', onNotificationChanged);

		// Implementation
		function reset() {
			vm.items = [];
			vm.itemCounts = {};
		}

		var notificationsDefer;
		function getNotifications() {
		    if (notificationsDefer)
		        return notificationsDefer.promise;

			notificationsDefer = $q.defer();
			var deferred = notificationsDefer;

			$http
				.get("~/api/v1/notifications/list")
				.then(function (result) {
					vm.items = result.data.items;
					vm.itemCounts = { domainCount: 0, userCount: 0, systemCount: 0 };
					for (var i = 0; i < vm.items.length; i++) {
						var item = vm.items[i];
						if (item.snoozedUntil && new Date(item.snoozedUntil) < new Date())
							item.snoozedUntil = null;
						if (!item.snoozedUntil) {
							if (item.type == 'system')
								vm.itemCounts.systemCount++;
							else if (item.type == 'domain')
								vm.itemCounts.domainCount++;
							else
								vm.itemCounts.userCount++;
						}
					}
					notificationsDefer.resolve(vm.items);
					notificationsDefer = null;
				},
				function () {
					notificationsDefer.reject();
					notificationsDefer = null;
				});

			notificationsDefer.promise.finally(function () {
			    notificationsDefer = null;
			});

			return deferred.promise;
		}

		function getNotificationCount() {
			var defer = $q.defer();
			getNotifications().finally(function () { defer.resolve(vm.itemCounts); })
			return defer.promise;
		}

		function openNotificationDialog(notification, ev) {
			var defer = $q.defer();
			$mdDialog.show({
				locals: {
					item: notification
				},
				controller: "notificationDialogController",
				templateUrl: "app/notifications/notification.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					vm.getNotifications().then(function (success) { defer.resolve(success); });
				}, defer.reject);
			return defer.promise;
		}

		function dismiss(notifications, ev) {
			var defer = $q.defer();
			var ids = [];

			if (Array.isArray(notifications)) {
				for (var i = 0; i < notifications.length; i++)
                    ids.push(notifications[i].id || notifications[i]);
			}
            else ids.push(notifications.id || notifications);

			$http
				.post("~/api/v1/notifications/dismiss", { ids: ids })
				.then(function (result) {
					if (result.data.success) {
						var newArray = [];
						for (var i = 0; i < vm.items.length; i++)
							if (ids.indexOf(vm.items[i].id) === -1)
								newArray.push(vm.items[i]);
						vm.items = newArray;
					}
					defer.resolve(result);
				}, defer.reject);

			return defer.promise;
		}

        function dismissAll(ev) {
            var defer = $q.defer();

            $http
                .post("~/api/v1/notifications/dismiss-all")
                .then(function (result) {
                    if (result.data.success) {
                        var newArray = [];
                        vm.items = newArray;
                    }
                    defer.resolve(result);
                }, defer.reject);

            return defer.promise;
        }

		function snooze(notifications, minutes, ev) {
			var defer = $q.defer();
			var ids = [];
			var newSnooze = minutes == 0 ? null : new Date((new Date()).getTime() + (minutes || 15) * 60000);

			if (Array.isArray(notifications)) {
				for (var i = 0; i < notifications.length; i++)
                    ids.push(notifications[i].id || notifications[i]);
			}
            else ids.push(notifications.id || notifications);
			$http
				.post("~/api/v1/notifications/snooze", { ids: ids, snoozeUntil: newSnooze })
				.then(function (result) {
					if (result.data.success) {
						for (var i = 0; i < vm.items.length; i++)
							if (ids.indexOf(vm.items[i].id) !== -1)
								vm.items[i].snoozedUntil = newSnooze;
					}
					defer.resolve(result);
				}, defer.reject);

			return defer.promise;
		}

		function onNotificationChanged()
		{
		    reset();
		    $rootScope.$broadcast('Notifications:Reloaded');
		}

	}

})();