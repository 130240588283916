(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("userEditSmtpAccountsController", userEditSmtpAccountsController);

	function userEditSmtpAccountsController($scope,
		$mdDialog,
		$http,
		$q,
		$window,
		coreData,
		errorHandling,
		successHandling,
		newItem,
		popupService,
		card) {

		const microsoftUrls = [
			"outlook.office365.com", // M365, Outlook, Hotmail, Live.com
			"smtp-mail.outlook.com" // MSN
		];

		$scope.address = card.address;
		$scope.port = card.port;
		$scope.displayName = card.displayName;
		$scope.emailAddress = card.emailAddress;
		$scope.userName = card.userName;
		$scope.password = card.password;
		$scope.encryptionType = parseInt(card.encryptionType || "0");
		$scope.useAuthentication = card.useAuthentication;
		$scope.newItem = newItem;
		$scope.isServerAddressValid = true;
		$scope.isPortValid = true;
		$scope.isEmailValid = true;
		$scope.isValid = true;

		$scope.oauthCode = null;
		$scope.useOAuth = false;

		if (!$scope.newItem)
			onServerChanged();

		$scope.testing = false;
		$scope.error = false;
		$scope.success = false;

		// Functions
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;
		$scope.onServerChanged = onServerChanged;

		activate();

		///////////////////////

		function activate() {
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			$scope.testing = true;
			$scope.success = false;
			$scope.error = false;

			doMicrosoftOAuth()
				.then(
					function () {
						const params = {
							smtpAccount: {
								address: $scope.address,
								displayName: $scope.displayName,
								emailAddress: $scope.emailAddress,
								encryptionType: $scope.encryptionType,
								password: $scope.password,
								port: $scope.port,
								useAuthentication: $scope.useAuthentication,
								userName: $scope.userName,
								oauthCode: $scope.oauthCode
							}
						};

						$http
							.post("~/api/v1/settings/user-smtp-account-test", params)
							.then(onTestSuccess, onTestFailure)
							.finally(function () {
								$scope.testing = false;
							});
					},
					function(failure) {
						errorHandling.report(failure);
						$scope.testing = false;
					});

			function onTestSuccess(result) {
				$scope.success = true;
				successHandling.report();
				$mdDialog.hide({
					address: $scope.address,
					port: $scope.port,
					displayName: $scope.displayName,
					emailAddress: $scope.emailAddress,
					userName: $scope.userName,
					password: $scope.password,
					encryptionType: $scope.encryptionType,
					useAuthentication: $scope.useAuthentication,
					deleteItem: false,
					oauthCode: null,
					oauthAccessToken: result.data.oauthAccessToken,
					oauthRefreshToken: result.data.oauthRefreshToken,
					oauthAccount: result.data.oauthAccount,
					oauthTokenExpires: result.data.oauthTokenExpires
				});
			}

			function onTestFailure(failure) {
				$scope.error = failure.data.message.toUpperCase();
			}
		}

		function deleteThis() {
			$mdDialog.hide({ deleteItem: true });
		}

		function doMicrosoftOAuth() {
			if (!$scope.useOAuth)
				return $q.when();

			const defer = $q.defer();

			const newWindow = window.open("", "_blank", popupService.dimensions.connectivity);

			const params = JSON.stringify({ redirectUri: `${coreData.baseUrl}/interface/connect-redirect` });
			$http.post("~/api/v1/settings/microsoft-redirect/SMTP", params)
				.then(
					function (success) {
						$window.handleConnectClose = onConnectClose;
						newWindow.location = success.data.result;
					},
					function (failure) {
						$scope.error = failure.data.message;
						newWindow.close();
						defer.reject(failure);
					});

			return defer.promise;

			function onConnectClose(result) {
				$window.handleConnectClose = null;
				if (result.code) {
					$scope.oauthCode = result.code;
				}
				if (result.error) {
					defer.reject(result.error);
					return;
				}
				defer.resolve();
			}
		}
		
		function cancel() {
			$mdDialog.cancel();
		}

		function onServerChanged() {
			if (!$scope.address || microsoftUrls.indexOf($scope.address.toLowerCase()) === -1) {
				$scope.useOAuth = false;
				$scope.oauthCode = null;
			} else {
				$scope.useOAuth = true;
				$scope.useAuthentication = false;
				$scope.encryptionType = 2;
				$scope.port = 587;
			}
		}
	}
})();