(function () {
    "use strict";

    angular
        .module("smartertools")
        .service("folderNameTranslator", folderNameTranslator);

    function folderNameTranslator($filter) {
        var cleanedItemCache = {};
        var translatedFolders = {};
        var builtinFolders = {
            "ALL_FILES": 1,
            "CALENDAR": 1,
            "CATEGORY_BLUE": 1,
            "CATEGORY_GREEN": 1,
            "CATEGORY_ORANGE": 1,
            "CATEGORY_PURPLE": 1,
            "CATEGORY_RED": 1,
            "CATEGORY_YELLOW": 1,
            "CONTACTS": 1,
            "DELETED_ITEMS": 1,
            "DRAFTS": 1,
            "FILES": 1,
            "GLOBAL_ADDRESS_LIST": 1,
            "INBOX": 1,
            "JUNK_EMAIL": 1,
            "MY_CALENDAR": 1,
            "MY_CONTACTS": 1,
            "MY_FILES": 1,
            "MY_NOTES": 1,
            "MY_TASKS": 1,
            "NOTES": 1,
            "PUBLIC_CHAT_FILES": 1,
            "SENT_ITEMS": 1,
            "TASKS": 1,
            "SHARED_WITH_ME": 1,

        };

        var vm = this;
        vm.fromNameAndOwner = fromNameAndOwner;
        vm.fromFolderData = fromFolderData;
        vm.isBuiltinFolder = function (s) { return s && !!builtinFolders[s.toLocaleUpperCase()]; }

        // Functions
        activate();

        ////////////////////

        function activate() { }

        function fromFolderData(folderData) {
            if (!folderData)
                return folderData;

            var translated;
            var branchData = folderData.branch === undefined ? folderData.data : folderData.branch.data;

            if (branchData && branchData.untranslatedName) {
                translated = translateAndCache(branchData.untranslatedName);
            } else if (folderData.key === folderData.label) {
                translated = translateAndCache(folderData.key);
            } else {
                var ownerName;
                if (branchData) {
                    if (branchData.owner) {
                        ownerName = branchData.owner;
                    } else if (branchData.source) {
                        ownerName = branchData.source.owner;
                    }
                }
                var isSharing = ownerName && folderData.label.startsWith(ownerName + " - ");
                var tempFolder = isSharing ? folderData.label.substring(ownerName.length + 3) : folderData.label;

                if (vm.isBuiltinFolder(tempFolder)) {
                    translated = translateAndCache(tempFolder.toLocaleUpperCase());
                    translated = isSharing ? ownerName + " - " + translated : translated;
                } else {
                    return folderData.label;
                }
            }

            return clean(translated);
        };

        function fromNameAndOwner(folderName, owner) {
            if (!folderName)
                return folderName;

            if (owner && owner.indexOf("@") !== -1)
                owner = owner.substring(0, owner.indexOf("@"));

            var isShared = folderName.startsWith(owner + " - ");
            var tempFolder = isShared ? folderName.substring(owner.length + 3) : folderName;

            var upper = tempFolder.toLocaleUpperCase();

            var translated = tempFolder;
            if (vm.isBuiltinFolder(tempFolder)) {
                translated = translateAndCache(upper);
            }
            translated = clean(translated);

            if (upper === translated)
                return folderName;

            return isShared
                ? owner + " - " + translated
                : translated;
        }

        function clean(s) {
            // Need to eliminate any HTML encoding that was done during the translate step to avoid display issues.
            var t = cleanedItemCache[s];
            if (t) return t;

            var wrapper = document.createElement("textarea");
            wrapper.innerHTML = s;
            t = wrapper.value;
            cleanedItemCache[s] = t;
            return t;
        }

        function translateAndCache(s) {
            var t = translatedFolders[s];
            if (t) return t;

            t = $filter("translate")(s);
            if (t && t != s)
                translatedFolders[s] = t;
            return t;
        }

    }

})();


