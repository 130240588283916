(function () {
	"use strict";

	angular
		.module("smartertools")
		.controller("productActivationDialogController", productActivationDialogController);

	function productActivationDialogController($scope, $mdDialog, $http, $filter, errorHandling) {
		var vm = this;
		vm.licenseKey = "";
		vm.emailAddress = "";
		vm.password = "";
		vm.progress = "0";
		vm.waiting = false;

		// Functions
		$scope.next = next;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
		}

		function next() {
			switch (vm.progress) {
				case "0":
					validateLicense();
					break;

				case "1":
					activateLicense();
					break;
			}
		};

		function cancel() {
			$mdDialog.cancel();
		};

		function validateLicense() {
			//vm.waiting = true;
			$http
				.post('~/api/v1/settings/sysadmin/validate-license', JSON.stringify({ input: vm.licenseKey }))
				.then(onSuccess, errorHandling.report)
				.finally(function () {
					vm.waiting = false;
				});

			function onSuccess(success) {
				if (success.data.owned)
					vm.progress = (parseInt(vm.progress, 10) + 1).toString(10);
				else
					activateLicense();
			}
		}

		function activateLicense() {
			//vm.waiting = true;
			$http.post('~/api/v1/settings/sysadmin/activate-license', JSON.stringify({ email: vm.emailAddress, password: vm.password, licenseKey: vm.licenseKey }))
			.then(function (success) {
				$mdDialog.hide();
			},
			function (failure) {
				if ($filter("translate")(failure.data.message) == failure.data.message)
					failure.data.message = $filter("translate")("LICENSE_PROBLEM_ACTIVATING") + " " + failure.data.message;

				errorHandling.report(failure);
			})
			.finally(function () {
				vm.waiting = false;
			});
		}
	}

})();