(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditMappingController", domainEditMappingController);

	function domainEditMappingController($rootScope, $scope, $mdDialog, $filter, $http, errorHandling, mapping, availableMappings) {
		$scope.mapping = mapping;
		$scope.availableMappings = availableMappings;

		activate();

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;

		//////////////////////////

		function activate() {
			if ($scope.mapping.mapOption === 2) {
				for (var i = 0; i < $scope.availableMappings.length; ++i) {
					var value = $scope.availableMappings[i];
					if (value.signatureGuid === $scope.mapping.signatureGuid && value.signatureMapOption === $scope.mapping.mapOption) {
						return $scope.mapping.signature = value;
					}
				}
			} else {
				for (var i = 0; i < $scope.availableMappings.length; ++i) {
					var value = $scope.availableMappings[i];
					if (value.signatureMapOption === $scope.mapping.mapOption) {
						return $scope.mapping.signature = value;
					}
				}
			}
		};

		function save() {
			$rootScope.spinner.show();
			$scope.mapping.signatureGuid = $scope.mapping.signature.signatureGuid;
			$scope.mapping.mapOption = $scope.mapping.signature.signatureMapOption;
			$http.post('~/api/v1/settings/domain/signature-mappings ', { signatureMaps: [  $scope.mapping] })
				.then(onSignaturesSaved, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSignaturesSaved(success) {
				$mdDialog.hide($scope.mapping);
			}

		};

		function cancel() {
			$mdDialog.cancel();
		};
	}
})();
