(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsAccountsController", domainSettingsAccountsController);

    function domainSettingsAccountsController($state, $rootScope, $scope, $http, $filter, coreDataDomainSettings, userDataService) {

        var vm = this;
        vm.isInitialized = false;
		vm.domain = $state.params.id || userDataService.user.domain;
        vm.gridSections = [
            { translate: "USERS", length: "0" },
            { translate: "ALIASES", length: "0" },
            { translate: "MAILING_LISTS", length: "0" }
        ];
        vm.selectedSection = "USERS";

        // Functions
		vm.changeSection = function (section) { vm.selectedSection = section; refreshCounts(); };
        activate();

        //////////////////////////////////

        function activate() {
            coreDataDomainSettings.changePageReset();
            $rootScope.$on("refreshUsersGrid", refreshCounts);
            $rootScope.$on("refresh-users-counts", refreshCounts);
            refreshCounts();
            vm.isInitialized = true;
        }

        function refreshCounts() {
            return $http.get("~/api/v1/settings/domain/account-list-counts")
				.then(function (result) {
                    vm.gridSections[0].length = result.data.users;
                    if (result.data.userLimit)
                        vm.gridSections[0].label = "/ " + $filter("unicodeNumber")(result.data.userLimit);
                    else
                        vm.gridSections[0].label = "";

                    vm.gridSections[1].length = result.data.aliases;
                    if (result.data.aliasLimit)
                        vm.gridSections[1].label = "/ " + $filter("unicodeNumber")(result.data.aliasLimit);
                    else
                        vm.gridSections[1].label = "";

                    vm.gridSections[2].hidden = !result.data.mailingListsAllowed;
                    vm.gridSections[2].length = result.data.mailingLists;
                    if (result.data.mailingListLimit)
                        vm.gridSections[2].label = "/ " + $filter("unicodeNumber")(result.data.mailingListLimit);
                    else
                        vm.gridSections[2].label = "";
                }, function () { });
        }
	}

})();
