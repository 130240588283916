(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("coreDataCalendar", coreDataCalendar);

    function coreDataCalendar($rootScope,
        $http,
        $q,
        $filter,
        $translate,
        preferencesStorage,
        userDataService,
        coreDataSettings,
        apiCategories,
        errorHandling,
        apiTaskSources) {
        var calendarEvents = [];
        var calendars = [];
        var resources = [];
        var gettingResources = false;
        var isEventsLoaded = false;
        var isInitialized = false;
        var isSourcesLoaded = false;
        var startDate = moment().subtract(1, 'months').date(1).subtract(7, 'days');
        var endDate = moment().add(2, 'months').date(1).add(7, 'days');
        var tasks = [];
        var isCalendarShared = [];
        var areTasksShared = [];
        var initDefer;
        var loadSourcesDefer;
        var loadEventsDefer;
        var eventDeferDict = {};
        var taskDeferDict = {};
        var resourceDeferDict = {};
        var previousTreeController;
        var sourcesDefer;
        var tasksDefer;
        var _sourcesTree = {
            data: [], // Used by tree directive
            map: [], // Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        }
        var _tasksTree = {
            data: [], // Used by tree directive
            map: [], // Reference to treeData nodes, used to quickly find and change values
            selectedBranchData: {}
        }

        // events
        $rootScope.$on("signalR.mailHub.client.sharesChanged", onShareChanged);

        // Public Variables
        var vm = this;
        vm.editingEvent = undefined;
        vm.userTimeZone = undefined;
        vm.listDataCache = {};
        vm.ignoreCalendarEventModified = {
            requested: moment(),
            ignored: moment()
        }
        vm.ignoreCalendarEventRemoved = {
            requested: moment(),
            ignored: moment()
        }
        vm.parameters = {
            get sortField() {
                var value = preferencesStorage.getSessionSortingFilteringParam("calendars", "sortField");
                if (value === undefined) {
                    value = "start";
                    preferencesStorage.setSessionSortingFilteringParam("calendars", "sortField", value);
                }
                return value;
            },
            set sortField(value) {
                preferencesStorage.setSessionSortingFilteringParam("calendars", "sortField", value);
            },

            get currentView() {
                var value = preferencesStorage.getSortingFilteringParam("calendars", "currentView");

                return value;
            },
            set currentView(value) { preferencesStorage.setSortingFilteringParam("calendars", "currentView", value); },

            get categoryFilters() {
                const catFilter = apiCategories.getCategoryFilter("calendars");
                return !catFilter ? [] : catFilter.categoryData;
            },
            //set categoryFilters(value) {
            //    preferencesStorage.setSessionSortingFilteringParam("calendars", "categoryFilters", value);
            //    vm.parameters.hasCategoryFilters = vm.parameters.categoryFilters && vm.parameters.categoryFilters.some(cat => !cat.selected);
            //},
            get hasCategoryFilter() {
                const catFilter = apiCategories.getCategoryFilter("calendars");
                return !catFilter || !catFilter.allSelected();
            },

            get filterType() {
                return preferencesStorage.getSessionSortingFilteringParam("calendars", "filterType");
            },
            set filterType(value) {
                preferencesStorage.setSessionSortingFilteringParam("calendars", "filterType", value);
            },
            get hasFilter() {
                return hasCategoryFilter() || filterType();
            }
        };
        vm.parameters.searchText = "";
        vm.parameters.useWeekends = function () {
            return coreDataSettings.userSettings.calendarSettings.showWeekendsWeekly;
        };
        vm.parameters.visibleHours = function () { return getVisibleHours(); };

        // Functions
        vm.addCalendarSource = addCalendarSource;
        vm.addEvents = addEvents;
        vm.addResourceSource = addResourceSource;
        vm.addTaskSource = addTaskSource;
        vm.categories = function () { return apiCategories.getMasterCategories(); }
        vm.filterByCategoryCallback = filterByCategoryCallback;
        vm.getCalendars = function () { return calendars; };
        vm.getEvents = getEvents;
        vm.getIsInitialized = function () { return isInitialized; };
        vm.getResources = function () { return resources; };
        vm.getSourcesTree = getSourcesTree;
        vm.getTasks = function () { return tasks; };
        vm.getTasksTree = getTasksTree;
        vm.init = init;
        vm.loadCalendarEvents = loadCalendarEvents;
        vm.loadCalendarTasks = loadCalendarTasks;
        vm.loadEvents = loadEvents;
        vm.loadSources = loadSources;
        vm.loadSourcesTree = loadSourcesTree;
        vm.loadTasksTree = loadTasksTree;
        vm.modifyCalendarSource = modifyCalendarSource;
        vm.modifyEvents = modifyEvents;
        vm.removeCalendarEvents = removeCalendarEvents;
        vm.removeCalendarSource = removeCalendarSource;
        vm.removeCalendarTasks = removeCalendarTasks;
        vm.removeEvents = removeEvents;
        vm.reset = reset;
        vm.resetOtherEvents = function () { isEventsLoaded = false; }
        vm.resetSources = function () { isSourcesLoaded = false; }
        vm.resetTasksTree = function () { _tasksTree = { data: [], map: [], selectedBranchData: {} }; };
        vm.setDateRange = setDateRange;
        // Startup 
        activate();

        ///////////////////////////////////

        function activate() { }

        function init() {
            if (initDefer)
                return initDefer.promise;

            if (isInitialized)
                return $q.when();


            initDefer = $q.defer();
            var promise = initDefer.promise;

            coreDataSettings
                .init()
                .then(function () {
                    loadTimeZones().then(onSettingsLoaded, onSettingsLoadError);
                },
                    onSettingsLoadError);

            function loadTimeZones() {
                var defer = $q.defer();
                coreDataSettings.settingsData.availiableTimeZones
                    .then(function (success) {
                        vm.userTimeZone = $.grep(success,
                            function (tz) { return tz.index === coreDataSettings.userSettings.timeZoneIndex; })[0];
                        defer.resolve();
                    },
                        function (failure) {
                            defer.reject();
                        });

                return defer.promise;
            }

            function onSettingsLoaded() {
                Object.defineProperty(vm,
                    "calendarSettings",
                    {
                        value: coreDataSettings.userSettings.calendarSettings,
                        writable: false
                    });
                isInitialized = true;
                initDefer.resolve();
                initDefer = null;
            }

            function onSettingsLoadError(result) {
                errorHandling.report(result);
                isInitialized = false;
                initDefer.reject(result);
                initDefer = null;
            }

            return promise;
        }

        function reset() {
            isInitialized = false;
            isSourcesLoaded = false;
            isEventsLoaded = false;
            calendars = [];
            tasks = [];
            resources = [];
            calendarEvents = [];
            gettingResources = false;
            _sourcesTree = { data: [], map: [], selectedBranchData: {} };
            _tasksTree = { data: [], map: [], selectedBranchData: {} };
            vm.listDataCache = {};
            vm.editingEvent = undefined;
        }

        function onShareChanged(e, args) {
            if (args && args.shareType && args.shareType !== "calendar") return;
            vm.reloadOnEnterCalendar = true;
            vm.resetSources();
        }

        function getEvents(options) {
            var result = $.extend(true, [], calendarEvents);
            //angular.forEach(result, function (source) {
            //	source.events = $.grep(source.events, function (evt) { return hasVisibleStatus(evt); });
            //	if (!options || !options.skipCategoryFilter)
            //		source.events = filterByCategoryCallback(source);

            //	source.events.forEach(function (s) {
            //		if (s.allDay) {
            //			// NOTE: We need to drop the time component for all-day appointments
            //			var start = moment(s.startWithTZ.dt);
            //			s.start = moment.utc({ year: start.year(), month: start.month(), date: start.date() });

            //			var end = moment(s.endWithTZ.dt);
            //			s.end = moment.utc({ year: end.year(), month: end.month(), date: end.date() });
            //		} else {
            //			s.start = moment.tz(s.startWithTZ.dt, s.startWithTZ.tz).tz(vm.userTimeZone.location);
            //			s.end = moment.tz(s.endWithTZ.dt, s.endWithTZ.tz).tz(vm.userTimeZone.location);
            //		}
            //	});
            //});
            return result;
        }

        function filterByCategoryCallback(source) {
            var evts = source.events;
            const result = [];
            if (!evts) return result;
            const categoryFilters = vm.parameters.categoryFilters;
            const noCategories = categoryFilters.some(cat => cat.noCategory && cat.selected);
            const retSource = returnSourceForFiltering(source);

            const addToResults = function (categories) {
                if (categories && categories.some(c => c.selected)) {
                    return categories.some(cat => cat.selected &&
                        categoryFilters.some(catFilter => catFilter.selected && catFilter.name === cat.name));

                } else {
                    return noCategories;
                }
            };
            evts.forEach(evt => {
                if (!vm.parameters.hasCategoryFilter || addToResults(evt.categories)) {
                    result.push($.extend({}, evt, retSource));
                }
            });

            return result;
        }

        function returnSourceForFiltering(source) {
            return {
                permission: source.permission,
                color: source.color,
                currentUser: userDataService.user.username,
                isPrimary: source.isPrimary,
                source: {
                    uid: source.uid,
                    owner: source.owner
                }
            }
        }

        function extendEventsForCards(source) {
            var result = [];
            angular.forEach(source.events, function (evt) {
                var res = $.extend({}, evt, {
                    permission: source.permission,
                    color: source.color,
                    currentUser: userDataService.user.username,
                    isPrimary: source.isPrimary,
                    source: {
                        uid: source.uid,
                        owner: source.owner
                    }
                });
                result.push(res);
            });
            return result;
        }

        function loadSources() {
            if (isSourcesLoaded) return $q.when();
            if (loadSourcesDefer) return loadSourcesDefer.promise;

            loadSourcesDefer = $q.defer();
            getSources()
                .then(function () {
                    isCalendarShared = [];
                    areTasksShared = [];
                    coreDataSettings.settingsData.resources
                        .then(function (success) {
                            for (var i = 0; i < success.length; i++) {
                                if (success[i].shareType === 3) {
                                    isCalendarShared[success[i].resourceName] = success[i].permissions > 0;
                                } else if (success[i].shareType === 4)
                                    areTasksShared[success[i].resourceName] = success[i].permissions > 0;
                            }
                            loadSourcesDefer.resolve();
                            loadSourcesDefer = null;
                        }, function (failure) {
                            loadSourcesDefer.resolve();
                            loadSourcesDefer = null;
                        });

                    isSourcesLoaded = true;
                    isEventsLoaded = false;
                }, function (failure) {
                    isSourcesLoaded = false;
                    isEventsLoaded = false;
                    loadSourcesDefer.reject(failure);
                    loadSourcesDefer = null;
                });

            return loadSourcesDefer.promise;
        }

        function getSources() {
            return $http.get("~/api/v1/calendars/sources").then(onGetSourcesSuccess, onGetSourcesFailure);
        }

        function onGetSourcesSuccess(result) {
            calendars = result.data.calendars;
            tasks = result.data.tasks || [];
            resources = result.data.resources;

            for (let iter = 0; iter < tasks.length; ++iter) {
                if (tasks[iter].name.toUpperCase().endsWith("MY TASKS") || tasks[iter].name.endsWith("MY_TASKS"))
                    tasks[iter].name = tasks[iter].owner + " - " + $filter("translate")("MY_TASKS");
            }

            preferencesStorage.cleanSourceVisibilities("calendars", calendars.concat(tasks.concat(resources)));
            angular.forEach(calendars, function (source) {
                source.isVisible = preferencesStorage.getSourceVisibility("calendars", source);
                //Im doing this like this to try to keep any | that the actual name may have in it
                source.name = source.name.replace("~CAL_SHARE|", '');
                const split = source.name.split("|");
                if (split.length > 1) {
                    split.pop();
                    source.name = split.join("|");
                }
            });
            angular.forEach(tasks, function (source) {
                source.isVisible = preferencesStorage.getSourceVisibility("calendartask", source);
            });
            angular.forEach(resources, function (source) {
                source.isVisible = preferencesStorage.getSourceVisibility("resource", source, false);
                source.name = source.name.replace("~CAL_SHARE|", "");
                const split = source.name.split("|");
                if (split.length > 1) {
                    split.pop();
                    source.name = split.join("|");
                }
            });

            calendars.sort(folderSort);
            tasks.sort(apiTaskSources.defaultTaskSourceComparer);
            resources.sort(folderSort);

            function folderSort(a, b) {
                if (a == null) return -1;
                if (b == null) return 1;
                if (a.isSharedItem && !b.isSharedItem) return 1;
                if (b.isSharedItem && !a.isSharedItem) return -1;
                if (!a.isSharedItem && a.isPrimary && !b.isPrimary) return -1;
                if (!b.isSharedItem && b.isPrimary && !a.isPrimary) return 1;
                if (a.isDomainResource && !b.isDomainResource) return 1;
                if (b.isDomainResource && !a.isDomainResource) return -1;
                return caseInsensitiveStrCompare(a.name, b.name);

                function caseInsensitiveStrCompare(x, y) {
                    x = (x === undefined || x === null) ? '' : x;
                    y = (y === undefined || y === null) ? '' : y;
                    return x.localeCompare(y, undefined, { sensitivity: 'base' });
                }
            }
        }

        function onGetSourcesFailure(result) {
            return result;
        }

        function loadEvents() {
            if (loadEventsDefer)
                return loadEventsDefer.promise;

            if (isEventsLoaded)
                return $q.when();

            var loadEventsDefer = $q.defer();

            calendarEvents.length = 0;
            var promises = [];
            angular.forEach(calendars, function (value, key) {
                if (value.isVisible) {
                    promises.push(getCalendarEvents(value));
                }
            });

            angular.forEach(tasks, function (value, key) {
                if (value.isVisible)
                    promises.push(getTaskEvents(value));
            });

            angular.forEach(resources, function (value, key) {
                if (value.isVisible && value.isMapped)
                    promises.push(getResourceEvents(value));
            });

            $rootScope.spinner.show();
            $q.all(promises)
                .then(function (success) {
                    isEventsLoaded = true;
                    loadEventsDefer.resolve();
                }, function (failure) {
                    isEventsLoaded = false;
                    loadEventsDefer.reject(failure);
                })
                .finally($rootScope.spinner.hide);

            loadEventsDefer.promise.finally(function () { loadEventsDefer = null; });

            return loadEventsDefer.promise;
        };

        function loadCalendarEvents(owner, calId) {
            var parsedOwner = null;
            if (owner) {
                parsedOwner = owner.split('@');
                parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            }
            var calendarSource = $.grep(calendars, function (calendar) {
                if (calendar.owner !== parsedOwner) return false;
                if (calId === "default" || calId === "Calendar_")
                    return calendar.isPrimary;
                else
                    return calId === calendar.id;
            });
            if (calendarSource.length > 0 && calendarSource[0].isVisible) {
                calendarEvents = $.grep(calendarEvents, function (cal) {
                    if (cal.isDomainResource) return true;
                    if (cal.owner !== parsedOwner) return false;
                    if (cal.uid === calId) return true;
                    if ((calId === "default" || calId === "Calendar_") && (cal.isPrimary && cal.uid === calendarSource[0].id)) return true;
                    if (cal.isPrimary && cal.uid === "Calendar_") return true;
                }, true);
                var promises = [];
                promises.push(getCalendarEvents(calendarSource[0]));
                if (!gettingResources) {
                    gettingResources = true;
                    angular.forEach(resources, function (value, key) {
                        if (value.isVisible) {
                            promises.push(getResourceEvents(value));
                        }
                    });
                }
                $q.all(promises)
                    .then(function (success) {
                        $rootScope.$broadcast("calendarRefresh");
                        gettingResources = false;
                    }, function (failure) { });
            } else { //if the calendar isint visible we still need to reload resources.
                calendarEvents = $.grep(calendarEvents, function (cal) {
                    if (cal.isDomainResource) return true;
                }, true);
                var promises = [];
                if (!gettingResources) {
                    gettingResources = true;
                    angular.forEach(resources, function (value, key) {
                        if (value.isVisible) {
                            promises.push(getResourceEvents(value));
                        }
                    });
                }
                $q.all(promises)
                    .then(function (success) {
                        $rootScope.$broadcast("calendarRefresh");
                        gettingResources = false;
                    }, function (failure) { });
            }
        };

        function removeCalendarEvents(events) {
            var eventsRemoved = [];
            angular.forEach(events, function (value) {
                var parsedName = null;
                if (value.ownerEmail) {
                    parsedName = value.ownerEmail.split('@');
                }
                if (parsedName && parsedName.length > 0) eventsRemoved.push({ id: value.eventId, owner: parsedName[0] });
                else eventsRemoved.push({ id: value.eventId, owner: value.ownerEmail });
            });

            vm.removeEvents(eventsRemoved);
            $rootScope.$broadcast("calendarRefresh");
        };

        function loadCalendarTasks(owner, sourceId) {
            var parsedOwner = owner.split('@');
            parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            if (parsedOwner === '') { return; };
            var taskSource = $.grep(tasks, function (taskS) { return taskS.owner === parsedOwner; });
            if (taskSource.length > 0 && taskSource[0].isVisible) {
                calendarEvents = $.grep(calendarEvents, function (cal) {
                    if (!cal.isTask) return false;
                    if (cal.owner === parsedOwner) return true;
                }, true);

                var promises = [];
                promises.push(getTaskEvents(taskSource[0]));

                $q.all(promises)
                    .then(function (success) {
                        $rootScope.$broadcast("calendarRefresh");
                        gettingResources = false;
                    }, function (failure) { });
            }
        };

        function removeCalendarTasks(owner, events) {
            var eventsRemoved = [];
            var parsedOwner = owner.split('@');
            parsedOwner = parsedOwner[0] != undefined ? parsedOwner[0] : '';
            if (parsedOwner === '') { return; };
            angular.forEach(events, function (value, key) {
                eventsRemoved.push({ id: value, owner: parsedOwner });
            });

            vm.removeEvents(eventsRemoved);
            $rootScope.$broadcast("calendarRefresh");
        }

        function getCalendarEvents(source) {
            var key = source.owner + "|" + source.id;
            if (eventDeferDict.hasOwnProperty(key))
                return eventDeferDict[key].promise;

            eventDeferDict[key] = $q.defer();

            var id = source.id;
            endDate.add(1, 'day');
            var params = JSON.stringify({
                startDate: moment.utc(startDate.toJSON()),
                endDate: moment.utc(endDate.toJSON()),
            });
            $http
                .post("~/api/v1/calendars/events/" + source.owner + "/" + id, params)
                .then(function (success) {
                    var evt = convertToFullCalendarSource(source, success.data.events);
                    calendarEvents.push(evt);
                    eventDeferDict[key].resolve(success);
                }, function (failure) {
                    errorHandling.report($translate.instant("SHARED_RESOURCES_INVALID_PERMISSIONS", { friendlyName: source.name }));
                    eventDeferDict[key].resolve(failure);
                })
                .finally(function () { delete eventDeferDict[key]; });

            return eventDeferDict[key].promise;
        }

        function getTaskEvents(source) {
            var key = source.owner + "|" + source.id;
            if (taskDeferDict.hasOwnProperty(key))
                return taskDeferDict[key].promise;

            taskDeferDict[key] = $q.defer();

            var id = (source.isPrimary && !source.isSharedItem) ? "" : source.id;
            $http
                .get("~/api/v1/calendars/tasks/" + source.owner + "/" + id)
                .then(function (success) {
                    var evt = convertToFullCalendarSource(source, success.data.events);
                    calendarEvents.push(evt);
                    taskDeferDict[key].resolve(success);
                }, function (failure) {
                    taskDeferDict[key].reject(failure);
                })
                .finally(function () { delete taskDeferDict[key]; });

            return taskDeferDict[key].promise;
        }

        function getResourceEvents(source) {
            if (!source.isMapped)
                return $q.resolve(true);

            var key = source.owner + "|" + source.id;
            if (resourceDeferDict.hasOwnProperty(key))
                return resourceDeferDict[key].promise;

            resourceDeferDict[key] = $q.defer();

            var id = source.id;
            var params = JSON.stringify({
                startDate: startDate.toJSON(),
                endDate: endDate.toJSON()
            });
            $http
                .post("~/api/v1/calendars/resource/" + id, params)
                .then(function (success) {
                    var evt = convertToFullCalendarSource(source, success.data.events);
                    calendarEvents.push(evt);
                    resourceDeferDict[key].resolve(success);
                }, function (failure) {
                    resourceDeferDict[key].reject(failure);
                })
                .finally(function () { delete resourceDeferDict[key]; });

            return resourceDeferDict[key].promise;
        }

        function addEvents(events) {
            var eventsByOwner = {};
            angular.forEach(events, function (evt) {
                evt.title = $filter("translate")(evt.title);
                evt.start = moment(evt.start);
                evt.end = moment(evt.end);

                if (!eventsByOwner[evt.owner])
                    eventsByOwner[evt.owner] = [];
                eventsByOwner[evt.owner].push(evt);
            });

            angular.forEach(eventsByOwner, function (evt) {
                var calSource = $.grep(calendarEvents, function (cal) {
                    if (cal.owner !== evt[0].owner) return false;
                    if (cal.uid === evt[0].calId) return true;
                    if (!evt[0].isTask && cal.isTask) return false;
                    if (evt[0].isTask && cal.isCalendar) return false;
                    if (cal.isPrimary === true && evt[0].calId === "Calendar_") return true;
                    if (cal.isPrimary === true && evt[0].calId === "Task_") return true;
                    if (cal.isPrimary === true && evt[0].calId === "") return true;
                    return false;
                });

                if (calSource.length > 0) {
                    var eventsWithinDateRange = $.grep(evt, function (value) {
                        return (startDate >= value.start && value.end >= startDate) ||
                            (value.start >= startDate && value.start <= endDate);
                    });
                    calSource[0].events = calSource[0].events.concat(eventsWithinDateRange);
                }

                if (calSource.length > 0)
                    calSource[0].events.push(evt[0]);
            });
        };

        function removeEvents(events) {
            return [];
        };

        function modifyEvents(events) {
            var eventGroups = [];
            var calendarsReturn = [];
            angular.forEach(events, function (value, key) {
                var eventGroup = $.grep(eventGroups, function (group) { return group.id === value.id });
                if (eventGroup.length > 0) {
                    eventGroup[0].events.push(value);
                } else {
                    eventGroups.push({ id: value.id, calId: value.calId, events: [value], owner: value.owner, resourceId: value.resourceId, isTask: value.isTask == undefined ? false : value.isTask });
                }
            });

            angular.forEach(eventGroups, function (value, key) {
                //get the calendar event source for this event group.
                var eventSource = $.grep(calendarEvents, function (source) {
                    if (source.owner !== value.owner) return false;
                    if (source.uid === value.calId) return true;
                    if (!value.isTask && source.isTask) return false;
                    if (value.isTask && source.isCalendar) return false;
                    if (source.isPrimary === true && value.calId === "Calendar_") return true;
                    if (source.isPrimary === true && value.calId === "Task_") return true;
                    if (source.isPrimary === true && value.calId === "") return true;
                });

                if (eventSource.length > 0) {
                    angular.forEach(value.events, function (eValue, key) {
                        //if the value of the event matchs the resource id of the event group it is a domain resource.
                        if (!eValue.isTask && eValue.calId === value.resourceId) {
                            //find the source of the domain resource and add the resource to it.
                            var resourceSource = $.grep(calendarEvents, function (source) { return source.uid === value.resourceId });
                            if (resourceSource.length > 0) {
                                eValue.title = $filter("translate")(eValue.title);
                                eValue.start = moment(eValue.start);
                                eValue.end = moment(eValue.end);
                                if ((startDate >= eValue.start && eValue.end >= startDate) ||
                                    (eValue.start >= startDate && eValue.start <= endDate)) {
                                    resourceSource[0].events.push(eValue);
                                }
                            }
                            //otherwise the event is just a regular event so add it to the event source.
                        } else {
                            eValue.summary = $filter("translate")(eValue.summary);
                            eValue.start = moment(eValue.start);
                            eValue.end = moment(eValue.end);
                            if ((startDate >= eValue.start && eValue.end >= startDate) ||
                                (eValue.start >= startDate && eValue.start <= endDate)) {
                                eventSource[0].events.push(eValue);
                            }
                        }
                    });
                }
            });
            return calendarsReturn;
        };

        function addCalendarSource(calendar) {
            var defer = $q.defer();
            clearDefaults(calendar);
            calendars.push(calendar);
            var promises = [];
            promises.push(getCalendarEvents(calendar));
            $q.all(promises)
                .then(function () {
                    defer.resolve();
                }, function (failure) {
                    defer.reject(failure);
                });
            return defer.promise;
        }

        function addTaskSource(task) {
            //TODO
        }

        function addResourceSource(resource) {
            //TODO
        }

        function clearDefaults(isPrimary) {
            if (isPrimary) {
                angular.forEach(calendars, function (value, key) {
                    value.isPrimary = false;
                });
            }
        }

        function modifyCalendarSource(calendar) {
            var defer = $q.defer();
            var foundCalendar = false;
            for (var i = 0; i < calendars.length; ++i) {
                if (calendars[i].id === calendar.id) {
                    foundCalendar = true;
                    clearDefaults(calendar.isPrimary);
                    calendars[i].name = calendar.name;
                    calendars[i].color = calendar.color;
                    calendars[i].isPrimary = calendar.isPrimary;
                    defer.resolve();
                    break;
                }
            }
            if (!foundCalendar) {
                defer.reject();
            }

            return defer.promise;
        }

        function removeCalendarSource(calendarId) {
            var defer = $q.defer();

            var calIndex = -1;
            for (var i = 0; i < calendars.length; ++i) {
                if (calendars[i].id === calendarId) {
                    calIndex = i;
                    break;
                }
            }

            if (calIndex != -1) {
                clearDefaults(calendars[calIndex]);
                //If the calendar we are removing was a default calendar, we need to set the primary calendar back to default.
                if (calendars[calIndex].isPrimary) {
                    var primaryIndex = -1;
                    for (var i = 0; i < calendars.length; ++i) {
                        if (calendars[i].isPrimary) {
                            primaryIndex = i;
                            break;
                        }
                    }
                    if (primaryIndex != -1) {
                        calendars[primaryIndex].isPrimary = true;
                    }
                }
                calendars.splice(calIndex, 1);
                defer.resolve();
            } else {
                defer.reject();
            }

            return defer.promise;
        }

        function setDateRange(sDate, eDate) {
            var defer = $q.defer();
            sDate = sDate || moment().subtract(1, 'months').date(1).subtract(7, 'days');
            eDate = eDate || moment().add(2, 'months').date(1).add(7, 'days');

            if (!moment.isMoment(sDate)) sDate = moment(sDate);
            if (!moment.isMoment(eDate)) eDate = moment(eDate);

            if (moment.isMoment(sDate) && moment.isMoment(eDate)) {
                if (eDate < sDate) {
                    var temp = sDate;
                    sDate = eDate;
                    eDate = temp;
                }

                if (sDate < startDate || eDate > endDate) {
                    startDate = sDate;
                    endDate = eDate;
                    isEventsLoaded = false;
                }

                isEventsLoaded = false;
                vm.loadSources().then(onSourcesLoaded, onLoadFailed);

                function onSourcesLoaded() {
                    defer.resolve(); // delete this if uncommenting statement below
                    //vm.loadEvents().then(onEventsLoaded, onLoadFailed);
                }

                function onEventsLoaded() {
                    defer.resolve();
                }

                function onLoadFailed(failure) {
                    defer.reject(failure);
                }
            } else {
                defer.reject();
            }

            return defer.promise;
        };

        function hasVisibleStatus(evt) {
            if (vm.parameters.showAllDay === false && evt.allDay === true) return false;
            if (vm.parameters.showRecurring === false && evt.isRecurring === true) return false;
            return true;
        }

        function calculateForeground(background, dark, light, bias) {
            if (background == undefined || background == null) return light;
            if (dark == undefined || dark === null) dark = "#282828";
            if (light == undefined || light === null) light = "#ffffff";
            if (bias == undefined || bias === null) bias = 0.43;

            var rgb = parseInt("0x" + background.substring(1));
            var r = (rgb >> 16) & 0xff;
            var g = (rgb >> 8) & 0xff;
            var b = (rgb >> 0) & 0xff;

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;	// per ITU-R BT.709
            var isLight = (luma < 255 * bias);
            return isLight ? light : dark;
        }

        function convertToFullCalendarSource(source, events) {

            var evt = {
                uid: source.id,
                owner: source.owner,
                permission: source.permission,
                //events: events,
                color: source.color,
                textColor: calculateForeground(source.color),
                isPrimary: source.isPrimary,
                isTask: source.isTask,
                isCalendar: source.isCalendar,
                isDomainResource: source.isDomainResource
            };
            return evt;
        }

        function getVisibleHours() {
            var visibleHours = [];
            if (coreDataSettings.userSettings.calendarSettings.sunday && coreDataSettings.userSettings.calendarSettings.sunday.enabled &&
                coreDataSettings.userSettings.calendarSettings.sunday.start !== coreDataSettings.userSettings.calendarSettings.sunday.end) {
                visibleHours.push({
                    daysOfWeek: [0],
                    startTime: coreDataSettings.userSettings.calendarSettings.sunday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.sunday.end.substring(0, 5)
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.monday && coreDataSettings.userSettings.calendarSettings.monday.enabled &&
                coreDataSettings.userSettings.calendarSettings.monday.start !== coreDataSettings.userSettings.calendarSettings.monday.end) {
                var startTime = coreDataSettings.userSettings.calendarSettings.monday.start.substring(0, 5);
                var endTime = coreDataSettings.userSettings.calendarSettings.monday.end.substring(0, 5);
                visibleHours.push({
                    daysOfWeek: [1],
                    startTime: startTime,
                    endTime: endTime
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.tuesday && coreDataSettings.userSettings.calendarSettings.tuesday.enabled &&
                coreDataSettings.userSettings.calendarSettings.tuesday.start !== coreDataSettings.userSettings.calendarSettings.tuesday.end) {
                visibleHours.push({
                    daysOfWeek: [2],
                    startTime: coreDataSettings.userSettings.calendarSettings.tuesday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.tuesday.end.substring(0, 5)
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.wednesday && coreDataSettings.userSettings.calendarSettings.wednesday.enabled &&
                coreDataSettings.userSettings.calendarSettings.wednesday.start !== coreDataSettings.userSettings.calendarSettings.wednesday.end) {
                visibleHours.push({
                    daysOfWeek: [3],
                    startTime: coreDataSettings.userSettings.calendarSettings.wednesday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.wednesday.end.substring(0, 5)
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.thursday && coreDataSettings.userSettings.calendarSettings.thursday.enabled &&
                coreDataSettings.userSettings.calendarSettings.thursday.start !== coreDataSettings.userSettings.calendarSettings.thursday.end) {
                visibleHours.push({
                    daysOfWeek: [4],
                    startTime: coreDataSettings.userSettings.calendarSettings.thursday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.thursday.end.substring(0, 5)
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.friday && coreDataSettings.userSettings.calendarSettings.friday.enabled &&
                coreDataSettings.userSettings.calendarSettings.friday.start !== coreDataSettings.userSettings.calendarSettings.friday.end) {
                visibleHours.push({
                    daysOfWeek: [5],
                    startTime: coreDataSettings.userSettings.calendarSettings.friday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.friday.end.substring(0, 5)
                });
            }
            if (coreDataSettings.userSettings.calendarSettings.saturday && coreDataSettings.userSettings.calendarSettings.saturday.enabled &&
                coreDataSettings.userSettings.calendarSettings.saturday.start !== coreDataSettings.userSettings.calendarSettings.saturday.end) {
                visibleHours.push({
                    daysOfWeek: [6],
                    startTime: coreDataSettings.userSettings.calendarSettings.saturday.start.substring(0, 5),
                    endTime: coreDataSettings.userSettings.calendarSettings.saturday.end.substring(0, 5)
                });
            }
            return visibleHours;
        };

        // Load Sources Tree
        function loadSourcesTree(treeController, forceReload) {
            if (sourcesDefer)
                return sourcesDefer.promise;
            sourcesDefer = $q.defer();

            if (treeController == null && previousTreeController)
                treeController = previousTreeController;

            if (forceReload || _sourcesTree.data.length === 0) {
                loadSourcesTreeData(sourcesDefer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            }
            else {
                var b = _sourcesTree.map[_sourcesTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    sourcesDefer.resolve(false);
                }
                sourcesDefer.resolve(true);
            }

            sourcesDefer.promise.finally(function () {
                sourcesDefer = null;
            });

            return sourcesDefer.promise;
        }

        function loadSourcesTreeData(defer, treeController) {
            loadSources()
                .then(function () {
                    let newData = [];
                    let newMap = [];

                    $.each(calendars, function (i, val) {
                        if (val.owner == userDataService.user.username) {
                            val.isBeingShared = isCalendarShared[val.id] || false;
                        }
                        var calBranch = loadSourcesTreeBranch(val, newMap);
                        newData.push(calBranch);
                    });
                    $.each(resources, function (i, val) {
                        if (val.isMapped)
                            newData.push(loadSourcesTreeBranch(val, newMap));
                    });

                    _sourcesTree.data = newData;
                    _sourcesTree.map = newMap;
                    defer.resolve();
                });
        }

        function loadSourcesTreeBranch(val, newmap) {
            // Load data for this branch.
            var name = val.isSharedItem ? $filter("folderTranslate")(val.name, val.owner) :  $filter("folderTranslate")(val.name);

            var branch = {
                label: name,
                data: {
                    "folderId": val.folderId,
                    "id": name,
                    "isShared": val.owner !== userDataService.user.username || val.isBeingShared || val.isDomainResource,
                    "isSharedByOther": val.owner !== userDataService.user.username || val.isDomainResource,
                    "isDomainResource": val.isDomainResource,
                    "showEye": true,
                    "isVisible": val.isVisible,
                    "source": val,
                    "color": val.color,
                    "isBeingShared": val.isBeingShared,
                    "isPrimary": val.isPrimary,
                }
            };
            newmap[branch.data.folderId] = branch;
            return branch;
        }

        // Load Tasks Tree
        function loadTasksTree(treeController, forceReload) {
            if (tasksDefer)
                return tasksDefer.promise;
            tasksDefer = $q.defer();

            if ((treeController == null || treeController == undefined) && previousTreeController)
                treeController = previousTreeController;

            if (forceReload || _tasksTree.data.length === 0) {
                loadTasksTreeData(tasksDefer, treeController);
                if (treeController)
                    previousTreeController = treeController;
            }
            else {
                var b = _tasksTree.map[_tasksTree.selectedBranchData.key];
                if (b != undefined) {
                    if (treeController) {
                        previousTreeController = treeController;
                    }
                    tasksDefer.resolve(false);
                }
                tasksDefer.resolve(true);
            }

            tasksDefer.promise.finally(function () {
                tasksDefer = null;
            });

            return tasksDefer.promise;
        };

        function loadTasksTreeData(defer, treeController) {
            loadSources()
                .then(function () {
                    let newData = [];
                    let newMap = [];

                    $.each(tasks, function (i, val) {
                        if (val.owner === userDataService.user.username)
                            val.isBeingShared = areTasksShared[val.id] || false;
                        newData.push(loadTasksTreeBranch(val, newMap));
                    });

                    _tasksTree.data = newData;
                    _tasksTree.map = newMap;
                    defer.resolve();
                });
        }

        function loadTasksTreeBranch(val, newMap) {
            // Load data for this branch.
            const name = val.isSharedItem ? $filter("folderTranslate")(val.name, val.owner) :  $filter("folderTranslate")(val.name);

            var branch = {
                label: name,
                data: {
                    "folderId": val.folderId,
                    "id": val.name,
                    "isShared": val.owner !== userDataService.user.username || val.isBeingShared,
                    "isDomainResource": val.isDomainResource,
                    "isSharedByOther": val.owner !== userDataService.user.username || val.isDomainResource,
                    "showEye": true,
                    "isVisible": val.isVisible,
                    "source": val,
                    "color": val.color,
                    "isBeingShared": val.isBeingShared,
                    "isPrimary": val.isPrimary,
                }
            };
            newMap[branch.data.key] = branch;
            return branch;
        }

        // Getters
        function getSourcesTree() {
            return _sourcesTree;
        }

        function getTasksTree() {
            return _tasksTree;
        }

    }

})();
