(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editRssController", editRssController);

	function editRssController($scope, $mdDialog, $http, $timeout, coreDataRss, name, data, folder, newFeed) {
		$scope.isNewFeed = newFeed;
		$scope.name = name;
		$scope.data = data;
		$scope.folders = [];
		$scope.selectedFolder = {};
		$scope.canUseEnterKey = true;

		activate();

		function activate() {
		    var feedTree = coreDataRss.data.feedTree;
			for (var key in feedTree.map) {
			    if (!feedTree.map.hasOwnProperty(key))
			        continue;
			    var folderOb = feedTree.map[key];
                if (!folderOb.data.isFolder)
                    continue;

                $scope.folders.push(folderOb);
                if (folderOb.data.id === folder)
                    $scope.selectedFolder = folderOb;
			}
		}

		var enterKeyTimeout;
		$scope.onUrlChanged = function () {
			if (enterKeyTimeout) {
				$timeout.cancel(enterKeyTimeout);
			}

			$scope.canUseEnterKey = false;
			enterKeyTimeout = $timeout(function () {
				$scope.canUseEnterKey = true;
				enterKeyTimeout = undefined;
			}, 1100);
		};

		$scope.onUrlBlur = function () {
			if (enterKeyTimeout) {
				$timeout.cancel(enterKeyTimeout);
			}

			$scope.canUseEnterKey = true;
		};

		$scope.enterKeySubmit = function (userForm) {
			if (!$scope.canUseEnterKey)
				return;

			$scope.submit(userForm);
		};

		$scope.submit = function (userForm) {
			if (userForm && (userForm.$invalid || userForm.$pending)) {
				return;
			}

			$mdDialog.hide({ name: $scope.name, url: $scope.data, folderId: $scope.selectedFolder.data.id });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();
