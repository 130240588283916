(function () {
    'use strict';

    angular
        .module('smartertools')
        .directive('validateMonthsAsNum', [validateMonthsAsNum]);

    function validateMonthsAsNum() {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ctrl) {
                ctrl.$validators.validateMonthsAsNum = function (modelValue, viewValue) {
                    if (!viewValue) {
                        return true;
                    }

                    if (viewValue < 1)
                        return false;
                    if (viewValue > 12)
                        return false;
                    return true;
                };
            }
        };
    }
})();