(function () {

    // Below is the original SVG file. Useful if you need to edit it in illustrator.
    // Afterwards, just delete the <style> block and embed style="{{fillStyles}}" to
    // the path, and delete the top two lines

    // Checkbox
    /*
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path d="M395-245 185-456l90-90 120 120 291-290 90 90-381 381Z"/>
    </svg>
    */

    angular
        .module('smartertools')
        .component('stTreeCheckbox', {
            template: `
                <div class="st-tree-checkbox {{$ctrl.boxClass}}" ng-disabled="$ctrl.isDisabled" style="{{$ctrl.boxStyles}}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                        <path style="{{$ctrl.checkStyles}}" d="M395-245 185-456l90-90 120 120 291-290 90 90-381 381Z"/>
                    </svg>                    
                </div>
               `,
            controller: stTreeCheckboxController,
            bindings: {
                fillColor: "<",
                isChecked: "<",
                isDisabled: "<"
            }
        });

    /* @ngInject */
    function stTreeCheckboxController($scope, $timeout, themesService) {
        let vm = this;
        let lastColor = -1;
        let lastChecked = null;

        vm.$onInit = () => recalculate();
        vm.$onChanges = () => recalculate();
        $scope.$on("themeService:changed", () => {
            lastColor = -1;
            recalculate();
        });

        function recalculate() {
            if (lastColor === vm.fillColor && lastChecked === vm.isChecked)
                return;

            let additionalBoxClasses = '';
            let calculatedFillColor = vm.fillColor;
            let isDefaultFill = false;
            const isDarkMode = themesService.isThemeDark();

            if (!calculatedFillColor || calculatedFillColor === 'transparent') {
                isDefaultFill = true;
                calculatedFillColor = isDarkMode ? '#e8e8e8' : '#444444';
            }

            let hsl = hslForColor(calculatedFillColor);
            let pillBrightness = hsl && hsl.l;

            vm.checkStyles = '';

            if (pillBrightness !== null && pillBrightness < 0.2) {
                vm.checkStyles = `fill: var(--checkbox-fg-inverse)`;
                additionalBoxClasses = isDarkMode ? 'standout altshadow' : 'standout';
                calculatedFillColor = isDarkMode ? adjustLightness(calculatedFillColor, 0.2) : calculatedFillColor;
            }
            else if (pillBrightness !== null && pillBrightness > 0.85) {
                additionalBoxClasses = isDarkMode ? '' : 'altshadow';
                calculatedFillColor = isDarkMode ? calculatedFillColor : adjustLightness(calculatedFillColor, -0.2);
            }
            else if (isDefaultFill) {
                additionalBoxClasses = 'is-default-fill';
            }

            if (vm.isChecked) {
                vm.boxClass = `checked ${additionalBoxClasses}`;
                vm.boxStyles = `--checkbox-bg-checked: ${calculatedFillColor}; --checkbox-border: ${calculatedFillColor};`;
            } else {
                vm.boxClass = `unchecked ${additionalBoxClasses}`;
                vm.boxStyles = `--checkbox-border: ${calculatedFillColor};`;
            }

            lastColor = vm.fillColor;
            lastChecked = vm.isChecked;
        }

    }

    function adjustLightness(hexcolor, amount) {
        try {
            let r = parseInt(hexcolor.substr(1, 2), 16);
            let g = parseInt(hexcolor.substr(3, 2), 16);
            let b = parseInt(hexcolor.substr(5, 2), 16);
            let hsl = rgbToHsl(r, g, b);
            hsl.l += amount;
            if (hsl.l > 1) hsl.l = 1;
            if (hsl.l < 0) hsl.l = 0;

            let rgb = hslToRgb(hsl.h, hsl.s, hsl.l);
            return rgbToHex(rgb.r, rgb.g, rgb.b);
        } catch (err) {
            return null;
        }
    }

    function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);

        function componentToHex(c) {
            const hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }
    }

    function hslForColor(hexcolor) {
        try {
            let r = parseInt(hexcolor.substr(1, 2), 16);
            let g = parseInt(hexcolor.substr(3, 2), 16);
            let b = parseInt(hexcolor.substr(5, 2), 16);
            return rgbToHsl(r, g, b);
        } catch (err) {
            return null;
        }
    }

    function rgbToHsl(r, g, b) {
        r /= 255, g /= 255, b /= 255;

        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }

            h /= 6;
        }

        return { h: h, s: s, l: l };
    }

    function hslToRgb(h, s, l) {
        var r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return { r: Math.floor(r * 255), g: Math.floor(g * 255), b: Math.floor(b * 255) };
    }

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }

})();