(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("newSharedFolderDialogController", newSharedFolderDialogController);

	function newSharedFolderDialogController($mdDialog, $http, $filter, $timeout, errorHandling, coreDataSettings, coreData, unsharedFolders) {
		var vm = this;
		vm.FOLDERTYPE_NOTES = 5;
		vm.FOLDERTYPE_CONTACTS = 1;
		vm.FOLDERTYPE_APPOINTMENTS = 2;
		vm.FOLDERTYPE_TASKS = 4;
		vm.FOLDERTYPE_EMAIL = 3;
		vm.ready = false;
		vm.edition = 2;
		vm.userPermissions = [];
		vm.groupPermissions = [];
		vm.userGroups = [];
		vm.selectedItem = [];
		vm.stopSharingVisible = false;
		vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
		vm.canSetPermissions = true;
		vm.folders = [];

		// functions
		vm.cancel = cancel;
		vm.save = save;
		vm.focusUserInput = focusUserInput;
		vm.removeUser = removeUser;
		vm.removeGroup = removeGroup;
		vm.queryUsers = queryUsers;
		vm.onEmailChanged = onEmailChanged;
		vm.onEmailBlurred = onEmailBlurred;
		vm.onGroupChanged = onGroupChanged;

		// Startup
		activate();

		///////////////////////////////////////////////////

		function activate() {
			vm.folders = unsharedFolders;
			if (vm.folders.length > 0) {
				vm.selectedFolder = vm.folders[0];
			}
			onFolderLoaded();
		}

		function onFolderLoaded() {
			coreDataSettings.settingsData.userGroups
				.then(function (success) {
					var i;

					vm.userGroups.push({ groupName: $filter("translate")("SELECT_A_GROUP"), userGroupId: undefined, selectText: true });
					vm.userGroups.push({ groupName: $filter("translate")("EVERYONE"), userGroupId: -1, isEveryone: true });
					vm.userGroups.push({ groupName: $filter("translate")("SHARED_RESOURCES_ADMIN"), userGroupId: -2, isAdmins: true });
					var grps = $.extend(true, [], success);
					for (i = 0; i < grps.length; i++) {
						vm.userGroups.push({ groupName: grps[i].name, userGroupId: grps[i].id });
					}

					for (i = 0; i < vm.selectedFolder.accessUsers.length; ++i) {
						vm.userPermissions.push(vm.selectedFolder.accessUsers[i]);
						vm.selectedItem[i] = vm.selectedFolder.accessUsers[i];
					}
					vm.userPermissions.push({ access: 4, user: { username: "" } });

					angular.forEach(vm.selectedFolder.accessGroups, function (share) {
						vm.groupPermissions.push(share);
					});

					if (vm.groupPermissions.length > 0) {
						angular.forEach(vm.groupPermissions, function (share) {
							for (i = 0; i < vm.userGroups.length; i++) {
								if (share.userGroupId == vm.userGroups[i].userGroupId) {
									share.group = vm.userGroups[i];
									break;
								}
							}
						});
					}
					vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
					vm.ready = true;
				});
		}

		function queryUsers(query) {
			return coreDataSettings.queryUsers(query);
		}

		function onEmailChanged(last, index, form) {
			var email = form["email" + index].$viewValue;
			if (email && email.length > 0 && last) {
				vm.userPermissions.push({ access: 4, user: { username: "" } });
			}
		};

		function onEmailBlurred(last, index, form) {
			var email = form["email" + index].$viewValue;
			if ((!email || email.length === 0) && !last) {
				vm.selectedItem.splice(index, 1);
				vm.userPermissions.splice(index, 1);
			}
		};

		function onGroupChanged(last) {
			if (last) {
				vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
			}
		};

		function removeUser(index, form) {
			vm.selectedItem.splice(index, 1);
			vm.userPermissions.splice(index, 1);
			form.$setDirty();
		}

		function removeGroup(index, form) {
			vm.groupPermissions.splice(index, 1);
			form.$setDirty();
		}

		function focusUserInput() {
			$timeout(function () {
				$("md-autocomplete input").last().trigger("focus");
			});
		}

		function save(event, form) {
			if (form.$invalid)
				return;

			// Clean everyone and admins
			if (!vm.selectedFolder.folderId) {
				vm.selectedFolder.folderType = vm.selectedFolder.type.value;
			}
			vm.selectedFolder.admin_access = 0;
			vm.selectedFolder.everyone_access = 0;
			vm.selectedFolder.users = [];
			vm.selectedFolder.usergroups = [];
			for (var i = 0; i < vm.groupPermissions.length; i++) {
				var g = vm.groupPermissions[i];
				if (g.group.userGroupId)
					vm.selectedFolder.usergroups.push({ groupName: g.group.name, userGroupId: g.group.userGroupId, access: g.access });
			}
			for (var i = 0; i < vm.userPermissions.length; i++) {
				var g = vm.userPermissions[i];
				if (!g.username)
					continue;
				vm.selectedFolder.users.push({ username: g.username, access: g.access });
			}

			var params = JSON.stringify({
				folderId: vm.selectedFolder.folderId,
				accessUsers: vm.selectedFolder.users,
				accessGroups: vm.selectedFolder.usergroups,
				includeSubfolders: vm.selectedFolder.includeSubfolders
			});
			$http.post("~/api/v1/folders/set-owned-virtual-folder-properties", params)
				.then(function () {
					$mdDialog.hide({ resource: vm.selectedFolder });
				});
		}

		function cancel() {
			$mdDialog.cancel();
		}
	}
})();