(function () {
	'use strict';

	angular
		.module('smartertools')
		.directive('mdTooltip', mdTooltipRtl);

	function mdTooltipRtl(browserSupport) {
		return {
			restrict: 'E',
			link: function (scope, iElement, iAttrs, controller) {
				if (!iAttrs.stDirection)
					return;
				var newDirection = iAttrs.stDirection;
				if (newDirection != 'before' && newDirection != 'after')
					return;

				updateDirection();
				scope.$on("locale:changed", updateDirection);

				function updateDirection() {
					var newDirection = iAttrs.stDirection;
					if (newDirection === 'before') {
						var isRtl = $('body[dir=rtl]').length > 0;
						newDirection = isRtl ? 'right' : 'left';
					}
					else if (newDirection === 'after') {
						var isRtl = $('body[dir=rtl]').length > 0;
						newDirection = isRtl ? 'left' : 'right';
					}
					var oldDirection = iAttrs.mdDirection;
					if (newDirection != oldDirection) {
						iAttrs.$set('mdDirection', newDirection);
					}
				}
			}
		};
	}
})();