; (function ($, _) {

    // To call methods, store a reference to the created object or use a selector like this:
    //   $(".panel-grid").data('PanelGrid').selectAll()

    //-------------------------------------------------------------------------------------------
    // Instructions and known issues for this component can be found at the end of this file
    //-------------------------------------------------------------------------------------------
    /*
        TODO: Clicking fast with shift while a ticket is loading shows both interfaces

        TODO: Starting scroll position (used when switching sections?)

        TODO: Pass in selected rows

        TODO: Proper object dispose handling for the main jquery object and it's connections.
        - In old version it was a Deinitialize function
        - If an ajax call gets returned AFTER dispose, handle it.
          - In old code it had: if (this.deinit) return;

        TODO: Support API passing back additional javascript function, or at least the following:
        - Force full frame reload
        - Pause or resume auto-refresh

        TODO: Unread handling.  Specifically moving up or down appropriately when the item you're on
        gets deleted

        TODO: Proper Aria labels

        TODO: Custom fields
    */

    var keys = {
        up: 38,
        down: 40,
        pageUp: 33,
        pageDown: 34,
        home: 36,
        end: 35,
        space: 32,
        enter: 13,
        delete: 46
    };

    var defaults = {
        itemsToGrabAtInit: 100,
        itemsToPreloadAroundData: 50,
        itemHeight: 20,
        placeholderItem: null,
        searchApiUrl: null,
        searchApiUrlIdOnly: null,
        searchCriteria: {},
        renderOptions: {},
        translations: {
            noItemsToShow: 'No items to show',
            pleaseWait: 'Loading'
        },

        // event handlers and callbacks
        getAccessTokenHandler: null,
        renderItemHandler: null,
        renderItemHandlerPostHandler: null,
        onDeleteKey: null,
        onGetDataCompleted: null,
        onItemClick: null,
        onItemDoubleClick: null,
        onRightClick: null,
        onSelectionCountChanged: null
    };

    window.SmarterTools = window.SmarterTools || {};
    window.SmarterTools.PanelGrid = function (control, options) {
        options = $.extend({}, defaults, options);

        var plugin = this;
        var $noItemstoShow;
        var $panelBottom;
        var $panelItems = [];
        var $panelTop;
        var $pleaseWait;
        var $vlist = null;
        var dataRetrieved = false;
        var dataSource = [];
        var dateLastChangePainted;
        var dateLastChanged;
        var focusedIndex = 0;
        var isScrollRunning = false;
        var lastClickedIndex = -1;
        var lastScaffoldingItemCount = null;
        var pageLastPainted;
        var pendingItemIndexes = {};
        var selectedItemIds = {};
        var totalItems = 9007199254740991; // int.max
        var inSelectMode = false;
        var RunningCalls = [];
        var CallsToIgnore = [];
        var doubleTap = false;
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // Throttled Functions
        var onScrollThrottled = _.throttle(onScroll, 5);
        var onSizeChangedThrottled = _.throttle(onSizeChanged, 50);
        var paintItemsThrottled = _.throttle(paintItems, 50);
        var refreshDataDebounced = _.debounce(refreshData, 100);
        var refreshDataThrottled = _.throttle(refreshData, 100);
        var resetKeepSelectionThrottled = _.throttle(function () { reset(); }, 250);

        // TODO:
        // Focus rectangle disappering on refresh

        // Exposed Plugin Functions
        plugin.deleteItemById = deleteItemById;
        plugin.getItemByIndex = getItemByIndex;
        plugin.getItemById = getItemById;
        plugin.getSelectedItems = getSelectedItems;
        plugin.getSelectedIds = getSelectedIds;
        plugin.repaint = repaint;
        plugin.reset = reset;
        plugin.reloadItemById = reloadItemById;
        plugin.selectAll = selectAll;
        plugin.selectNone = selectNone;
        plugin.selectItemByIndex = selectItemByIndex;
        plugin.selectItemById = selectItemById;
        plugin.addItemToSelectionById = addItemToSelectionById;
        plugin.selectNext = selectNext;
        plugin.selectPrevious = selectPrevious;
        plugin.focusItem = focusItem;
        plugin.setOptions = function (ops) { options = $.extend(options, ops); }
        plugin.setSelectMode = setSelectMode;
        plugin.getItemCount = function () { return totalItems };

        // Initialization
        activate();
        $(control).data('PanelGrid', plugin);

        ///////////////////////////////////////////

        function activate() {

            // Create permanant child controls
            $pleaseWait = $('<div class="please-wait">' + _.escape(options.translations.pleaseWait) + '<span>.</span><span>.</span><span>.</span></div>');
            $noItemstoShow = $('<div class="no-items">' + _.escape(options.translations.noItemsToShow) + '</div>').hide();
            $vlist = $('<div class="panel-grid-list"></div>');

            ensureScaffoldingCreated();

            control
                .addClass('panel-grid')
                .attr('tabindex', '0')
                .append('<input class="selectedtracker" tabindex="-1" type="text" readonly id="SelectedIDs" name="SelectedIDs" style="visibility: hidden; position:fixed; z-index:0;" />')
                .append($pleaseWait)
                .append($noItemstoShow)
                .append($vlist);

            // Calculate height
            calculateHeightFromPlaceholder();

            // Start loading data
            refreshData();

            // Setup events
            $(window)
                .on('resize', onSizeChangedThrottled)
                .on('panelGrid:resized', onSizeChangedThrottled);
            control
                .on('keydown', onKeyDown)
                .on('scroll', onScrollThrottled)
                .on('click', '.panel-item', onPanelItemClick)
                //.on('dblclick', '.panel-item', onPanelItemDblClick)
                .on('contextmenu', '.panel-item', onPanelItemContextMenu)
                .focus();
            if (window.SmarterTools.touchContext) {
                window.SmarterTools.touchContext(control, '.panel-item', onPanelItemContextMenu);
            }
        }

        // Setup functions ----------------------------------------------------------------------------------

        function limitedScrollTop() {
            return Math.max(control.scrollTop(), 0);
        }

        function calculateHeightFromPlaceholder() {
            if (!options.placeholderItem || typeof (options.renderItemHandler) != 'function')
                return;

            var $temp;
            try {
                var item = options.placeholderItem;
                $vlist.css('visibility', "hidden");
                var html = "<div class='panel-item'>" + options.renderItemHandler(item, options.renderOptions) + "</div>";
                $temp = $(html);
                $vlist.append($temp);
                var h = Math.ceil($temp.outerHeight());
                options.itemHeight = h || options.itemHeight;
            } catch (err) { }
            if ($temp)
                $temp.remove();
            $vlist.css('visibility', "");
        }

        // Mouse interactions -------------------------------------------------------------------------------

        var lastFocusedIndex = -1;

        function onPanelItemClick(e) {
            var index = $(this).attr('data-index');
            focusedIndex = index;
            setFocusedItemStyle(focusedIndex);
            focusItem();
            selectItemByIndex(index, e);

            if (typeof options.onItemDoubleClick === 'function') {
                if (!doubleTap) {
                    doubleTap = true;
                    setTimeout(function () { doubleTap = false; }, 500);
                    lastFocusedIndex = focusedIndex;
                    return false;
                }
                if (lastFocusedIndex == focusedIndex && getSelectedIds().length == 1) {
                    e.preventDefault();
                    onPanelItemDblClick(e, $(this));
                }
            }

            lastFocusedIndex = focusedIndex;
            return true;
        }

        function onPanelItemDblClick(e, panel) {
            if (typeof options.onItemDoubleClick !== 'function')
                return true;

            if (!panel)
                panel = $(this);
            var id = panel.attr('data-id');
            var index = panel.attr('data-index');
            var item = dataSource[index];
            options.onItemDoubleClick(id, item, e);
            return false;
        }

        function onPanelItemContextMenu(e) {
            if (typeof options.onRightClick !== 'function')
                return true;

            var panel = $(this);
            var id = panel.attr('data-id');
            var index = panel.attr('data-index');
            focusedIndex = index;

            // TODO: Detect shift/control and ADD to selection instead
            if (!selectedItemIds[id]) {
                setFocusedItemStyle(index);
                selectItemByIndex(index, e);
            }

            options.onRightClick(getSelectedItems(), e);
            return false;
        }

        function onScroll() {
            if (isScrollRunning)
                return;
            isScrollRunning = true;

            if (typeof window.requestAnimationFrame !== 'undefined')
                window.requestAnimationFrame(scrollPaint);
            else
                window.setTimeout(scrollPaint, 5);
        }

        function scrollPaint() {
            if (navigator.platform.match(/i(Phone|Pod|Pad)/i))
                refreshDataDebounced();
            else {
                paintItemsThrottled();
                refreshDataThrottled();
            }
            isScrollRunning = false;
        }

        // Keyboard interactions ----------------------------------------------------------------------------

        function onKeyDown(e) {
            const isMac = navigator.userAgent.indexOf("Mac") != -1;

            if (!dataRetrieved)
                return true;

            switch (e.keyCode) {
                case keys.up: return onKeyboardArrowUp(e);
                case keys.down: return onKeyboardArrowDown(e);
                case keys.pageUp: return onKeyboardPageUp(e);
                case keys.pageDown: return onKeyboardPageDown(e);
                case keys.home: return false;// return onKeyboardHome(e);
                case keys.end: return false;// return onKeyboardEnd(e);
                case keys.delete: return onKeyboardDelete(e);
                case 65:
                    if (e.ctrlKey || e.metaKey)
                        return onKeyboardCtrlA(e);
                    break;
            }

            if (e.key == "Delete" || (isMac && e.key == "Backspace"))
                return onKeyboardDelete(e);

            return true;
        }

        function onKeyboardArrowUp(e) {
            if (e.shiftKey) {
                focusedIndex = Math.max(+focusedIndex - 1, 0);
                focusItem();
                selectItemRange(lastClickedIndex, focusedIndex, true, event);
            } else
                forceSelectItemByIndex(Math.max(0, +focusedIndex - 1), e);
            return false;
        }

        function onKeyboardArrowDown(e) {
            if (e.shiftKey) {
                focusedIndex = Math.min(+focusedIndex + 1, totalItems - 1);
                focusItem();
                selectItemRange(lastClickedIndex, focusedIndex, true, event);
            } else
                forceSelectItemByIndex(Math.min(totalItems - 1, +focusedIndex + 1), e);
            return false;
        }

        function onKeyboardCtrlA(e) {
            selectAll();
            return false;
        }

        function onKeyboardPageUp(e) {
            var topElement = Math.floor(limitedScrollTop() / options.itemHeight);
            var newIndex = focusedIndex === topElement
                ? Math.max(0, focusedIndex - Math.ceil(control.height() / options.itemHeight))
                : topElement;

            if (e.shiftKey) {
                focusedIndex = newIndex;
                focusItem();
                selectItemRange(lastClickedIndex, focusedIndex, true, event);
            } else
                forceSelectItemByIndex(newIndex, e);
            return false;
        }

        function onKeyboardPageDown(e) {
            var bottomElement = Math.min(Math.floor((limitedScrollTop() + control.height()) / options.itemHeight), totalItems - 1);
            var newIndex = (focusedIndex + 1) >= bottomElement
                ? Math.min(Math.floor((limitedScrollTop() + control.height() + control.height()) / options.itemHeight), totalItems - 1)
                : bottomElement;
            if (e.shiftKey) {
                focusedIndex = newIndex;
                focusItem();
                selectItemRange(lastClickedIndex, focusedIndex, true, event);
            } else
                forceSelectItemByIndex(newIndex, e);
            return false;
        }

        function onKeyboardHome(e) {
            if (e.shiftKey) {
                focusedIndex = 0;
                focusItem();
                selectItemByIndex(focusedIndex, e);
            } else
                forceSelectItemByIndex(0, e);
            return false;
        }

        function onKeyboardEnd(e) {
            if (e.shiftKey) {
                focusedIndex = totalItems - 1;
                focusItem();
                selectItemByIndex(focusedIndex, e);
            } else {
                forceSelectItemByIndex(totalItems - 1, e);
            }

            return false;
        }

        function onKeyboardDelete(e) {
            if (typeof options.onDeleteKey === 'function')
                options.onDeleteKey();
            return false;
        }

        // Painting data ------------------------------------------------------------------------------------

        function getItemsPerVirtualPage() { return Math.ceil(control.height() / options.itemHeight); }

        function getCurrentPageNumber() {
            var itemsPerVirtualPage = getItemsPerVirtualPage();
            if (!itemsPerVirtualPage)
                return 0;
            var topmostItem = Math.floor(limitedScrollTop() / options.itemHeight);
            return Math.floor(topmostItem / itemsPerVirtualPage);
        }

        function repaint(forceRepaint) {
            if (forceRepaint)
                pageLastPainted = -1;
            refreshData();
        }

        function reset(ops, callback) {
            var vm = this;
            var functionOps = $.extend({ keepSelection: true }, ops);
            var prevFocusedId = -1;
            if (dataSource && dataSource[focusedIndex]) {
                prevFocusedId = dataSource[focusedIndex].Id || dataSource[focusedIndex].id || dataSource[focusedIndex].uid;
            }

            var params = {
                start: null,
                count: null,
                preDataCallback: function () {
                    if (functionOps.keepSelection) {
                        functionOps.focusedIndex = focusedIndex;
                        functionOps.scrollTop = limitedScrollTop();
                    } else {
                        selectedItemIds = {};
                    }
                    dataSource = [];
                    pendingItemIndexes = {};
                    dataRetrieved = false;
                    dateLastChangePainted = null;
                    dateLastChanged = null;
                    ensureScaffoldingCreated();
                    if (!functionOps.keepSelection) {
                        focusedIndex = -1;
                        control.scrollTop(0);
                        $pleaseWait.show();
                        $noItemstoShow.hide();
                        $vlist.children('.panel-item').hide();
                    }
                },
                postDataCallback: function () {
                    if (functionOps.keepSelection) {
                        control.scrollTop(functionOps.scrollTop);
                        var prevItem = undefined;
                        if (prevFocusedId != -1) {
                            prevItem = getItemById(prevFocusedId);
                        }
                        if (prevItem && prevItem.index != undefined) {
                            focusedIndex = prevItem.index;
                        } else {
                            focusedIndex = functionOps.focusedIndex;
                        }
                        pageLastPainted = -1;
                        paintItemsThrottled();
                        if (callback) {
                            callback(vm, focusedIndex);
                        }
                    }
                    // TODO: Call onSelectedITemChanged when callback comes back with data, and reset selection smartly
                },
                idOnly: false,
                ignoreStoredData: true
            };

            totalItems = 9007199254740991;
            if (!functionOps.keepSelection)
                params.preDataCallback();

            for (var i = 0; i < RunningCalls.length; ++i) {
                CallsToIgnore.push(RunningCalls[i]);
            }
            RunningCalls.length = 0;

            refreshData(params);
        }

        function refreshData(inputs) {

            var params = $.extend({ start: null, count: null, preDataCallback: null, postDataCallback: null, idOnly: false, ignoreStoredData: false }, inputs);

            // Figure out range to query
            if (params.start === null || params.start === undefined) {
                var itemsPerVirtualPage = getItemsPerVirtualPage();
                var currentPage = getCurrentPageNumber();
                var startRender = currentPage === 0 ? 0 : (currentPage - 1);
                params.start = startRender * itemsPerVirtualPage;
                params.count = Math.max(options.itemsToGrabAtInit, itemsPerVirtualPage * 3);
            }
            if (!params.count) {
                var itemsPerVirtualPage2 = getItemsPerVirtualPage();
                params.count = Math.max(options.itemsToGrabAtInit, itemsPerVirtualPage2 * 3);
            }

            // Determine optimal load range
            var i;
            var earliestLoadIndex = Math.max(params.start - options.itemsToPreloadAroundData, 0);
            var latestLoadIndex = Math.min(params.start + params.count + options.itemsToPreloadAroundData, totalItems);

            if (!params.ignoreStoredData) {
                // If all items exist, don't call refresh at all
                var allPresent = true;
                var end = dataRetrieved ? Math.min(params.start + params.count, totalItems) : params.start + params.count;
                for (i = params.start; i < end; i++) {
                    if (!params.idOnly && (!dataSource[i] || dataSource[i]._idOnly) && !pendingItemIndexes[i]) {
                        allPresent = false;
                        break;
                    }
                    if (params.idOnly && !dataSource[i]) {
                        allPresent = false;
                        break;
                    }
                }

                if (allPresent) {
                    paintItemsThrottled();
                    if (typeof params.preDataCallback === 'function')
                        params.preDataCallback();
                    if (typeof params.postDataCallback === 'function')
                        params.postDataCallback();
                    return;
                }

                // trim start
                for (i = earliestLoadIndex; i <= latestLoadIndex; i++) {
                    if (params.idOnly) {
                        if (dataSource[i])
                            earliestLoadIndex++;
                        else
                            break;
                    } else {
                        if (dataSource[i] && !dataSource[i]._idOnly) {
                            earliestLoadIndex++;
                            latestLoadIndex++;
                        } else if (pendingItemIndexes[i]) {
                            earliestLoadIndex++;
                            latestLoadIndex++;
                        } else
                            break;
                    }
                }
                if (latestLoadIndex >= totalItems)
                    latestLoadIndex = totalItems - 1;

                // trim end
                for (i = latestLoadIndex; i > earliestLoadIndex; i--) {
                    if (params.idOnly) {
                        if (dataSource[i])
                            latestLoadIndex--;
                        else
                            break;
                    } else {
                        if (dataSource[i] && !dataSource[i]._idOnly) {
                            latestLoadIndex--;
                        } else if (pendingItemIndexes[i]) {
                            latestLoadIndex--;
                        } else
                            break;
                    }
                }
            }

            if (earliestLoadIndex <= latestLoadIndex) {

                if (!params.idOnly)
                    for (i = earliestLoadIndex; i <= latestLoadIndex; i++) {
                        pendingItemIndexes[i] = true;
                    }

                // Query the data
                params.apiInputs = $.extend({}, options.searchCriteria, { skip: earliestLoadIndex, take: latestLoadIndex + 1 - earliestLoadIndex, selectedIds: getSelectedIds() });
                if (typeof options.getAccessTokenHandler === 'function')
                    options.getAccessTokenHandler(callApi, params); // should set params.apiAccessToken
                else
                    callApi(params);
            }
            else {
                if (typeof params.preDataCallback === 'function')
                    params.preDataCallback();
                if (typeof params.postDataCallback === 'function')
                    params.postDataCallback();
            }
        }

        // TODO: Deleted items need to deselect the old item and move to next
        // Added items will just do refresh (replacing results)
        // Contents change will do same as add
        // 
        // TODO: Add parameter to replace results instead of doing it before the load call starts
        function callApi(params) {
            //console.time && console.time("callApi");
            params.idOnly = params.idOnly && options.searchApiUrlIdOnly;
            var skip = params.apiInputs.skip;
            var take = params.apiInputs.take;
            var callId = Math.floor((Math.random() * 10000)).toString();
            RunningCalls.push(JSON.stringify(params.apiInputs) + callId);

            $
                .ajax({
                    url: params.idOnly ? options.searchApiUrlIdOnly : options.searchApiUrl,
                    type: 'POST',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(params.apiInputs),
                    processData: false,
                    beforeSend: function (xhr) {
                        if (params.apiAccessToken) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + params.apiAccessToken);
                        }
                    }
                })
                .done(function (data) {
                    var ignoreIndex = CallsToIgnore.indexOf(this.data + callId);
                    if (ignoreIndex !== -1) {
                        CallsToIgnore.splice(ignoreIndex, 1);
                        return;
                    }
                    var runningIndex = RunningCalls.indexOf(this.data + callId);
                    if (runningIndex !== -1) {
                        RunningCalls.splice(runningIndex, 1);
                    }

                    //console.timeEnd && console.timeEnd("callApi");
                    var i, item, coercedIndex, id;
                    var coercedResults = data.Results || data.results;
                    var coercedCount = data.TotalCount || data.totalCount || 0;
                    var coercedIdsToRemove = data.RemovedIds || data.removedIds || data.removedids;
                    var idsToIndex = [];

                    // TODO: For performance should we store an Id to Index lookup table?
                    // TODO: Performance: maybe move the toggle selected down below, so we don't have a delay between off on the old and on on the new

                    // We remove selections before the data refresh takes effect so we can have access to the old index
                    // TODO: Performance: Instead of doing this, record this stuff when they click it, when we know all the data will be there
                    if (coercedIdsToRemove && coercedIdsToRemove.length) {
                        for (i = 0; i < dataSource.length; i++) {
                            item = dataSource[i];
                            if (!item) continue;
                            id = item.Id || item.id || item.uid;
                            if (selectedItemIds[id]) {
                                idsToIndex.push({ index: i, id: id, keep: true });
                            }
                        }

                        for (i = 0; i < coercedIdsToRemove.length; i++) {
                            for (var j = 0; j < idsToIndex.length; j++) {
                                if (idsToIndex[j].id == coercedIdsToRemove[i]) {
                                    toggleSelection(idsToIndex[j].index, false);
                                    idsToIndex[j].keep = false;
                                }
                            }
                        }
                    }

                    if (typeof params.preDataCallback === 'function') {
                        //console.time && console.time("preDataCallback");
                        params.preDataCallback();
                        //console.timeEnd && console.timeEnd("preDataCallback");
                    }

                    if (params.idOnly) {
                        if (coercedResults) {
                            for (i = 0; i < coercedResults.length; i++) {
                                coercedIndex = i + skip;
                                id = coercedResults[i];
                                var existingItem = dataSource[coercedIndex];
                                if (!existingItem || existingItem._idOnly) {
                                    item = {
                                        Id: id,
                                        _idOnly: true
                                    };
                                    dataSource[coercedIndex] = item;
                                }
                            }
                        }
                    } else {
                        var firstData = !dataRetrieved;
                        dataRetrieved = true;
                        dateLastChanged = new Date();

                        if (coercedResults) {
                            for (i = 0; i < coercedResults.length; i++) {
                                item = coercedResults[i];
                                coercedIndex = i + skip;
                                dataSource[coercedIndex] = item;
                                delete pendingItemIndexes[i];
                            }
                        }

                        totalItems = coercedCount;
                        if (typeof options.onGetDataCompleted === 'function') {
                            options.onGetDataCompleted(totalItems);
                        }

                        if (firstData && totalItems > 0 && _.size(selectedItemIds) === 0) {
                            selectItemByIndex(0);
                            focusedIndex = 0;
                            focusItem();
                        }

                        if (firstData && typeof params.preDataCallback !== 'function') {
                            focusedIndex = 0;
                            focusItem();
                        }

                        paintItemsThrottled();
                    }

                    if (typeof params.postDataCallback === 'function')
                        params.postDataCallback();

                    // If there are any idsToIndex that have keep:false, fire selectedCountChanged.
                    // If there was only one but now there is none, fire selectedCountChanged
                    // If there was more than one but now only one, fire clicked

                    var totalCount = 0, runningCount = 0, firstIndex = null;
                    for (i = 0; i < idsToIndex.length; i++) {
                        totalCount++;
                        if (idsToIndex[i].keep) {
                            firstIndex = firstIndex || idsToIndex[i].index;
                            runningCount++;
                        }
                    }
                    if (runningCount != totalCount) {
                        if (runningCount === 1 && firstIndex && typeof options.onItemClick === 'function') {
                            item = dataSource[firstIndex];
                            options.onItemClick(id, item, null);
                            if (typeof options.onSelectionCountChanged === 'function')
                                options.onSelectionCountChanged(runningCount);
                        }
                        else if (runningCount === 0) {
                            // last item removed
                            var selectedItem = idsToIndex[0].index;
                            if (selectedItem >= totalItems)
                                selectedItem = totalItems - 1;
                            if (selectedItem >= 0 && selectedItem < totalItems)
                                selectItemByIndex(selectedItem);
                            else if (typeof options.onSelectionCountChanged === 'function')
                                options.onSelectionCountChanged(runningCount);
                        } else {
                            if (typeof options.onSelectionCountChanged === 'function')
                                options.onSelectionCountChanged(runningCount);
                        }
                    }
                })
                .fail(function () { });
        }

        function reloadItemById(id) {
            var item = getItemById(id);
            if (!item)
                return;

            var index = item.Index || item.index;
            dataSource[index] = undefined;
            delete pendingItemIndexes[index];
            dateLastChangePainted = null;
            dateLastChanged = null;
            refreshDataThrottled();
        }

        function deleteItemById(id) {
            var item = getItemById(id);
            if (!item)
                return;

            var index = item.Index || item.index;
            dataSource[index] = undefined;
            delete pendingItemIndexes[index];
            resetKeepSelectionThrottled();
        }

        function ensureScaffoldingCreated() {
            var itemsPerVirtualPage = getItemsPerVirtualPage();
            if (lastScaffoldingItemCount === itemsPerVirtualPage)
                return;
            lastScaffoldingItemCount = itemsPerVirtualPage;
            dateLastChangePainted = null;

            $panelItems = [];

            $vlist.empty();
            $panelTop = $("<div class='panel-spacer' data-index='top' style='height: 0px;'></div>");
            $vlist.append($panelTop);
            for (var i = 0; i < itemsPerVirtualPage * 3; i++) {
                var item = $("<div class='panel-item' style='height: " + options.itemHeight + "px; display: none;'></div>");
                $panelItems.push(item);
                $vlist.append(item);
            }
            $panelBottom = $("<div class='panel-spacer' data-index='bottom' style='height: 0px;'></div>");
            $vlist.append($panelBottom);
        }

        function paintItems() {

            ensureScaffoldingCreated();

            // TODO: Only render if data or page number or page size changed
            var itemsPerVirtualPage = getItemsPerVirtualPage();

            var currentPage = getCurrentPageNumber();
            var startRender = currentPage === 0 ? 0 : (currentPage - 1);
            var endOnIndex = startRender * itemsPerVirtualPage + (itemsPerVirtualPage * 3);

            if (dateLastChanged && dateLastChanged === dateLastChangePainted && pageLastPainted === startRender)
                return;
            dateLastChangePainted = dateLastChanged;
            pageLastPainted = startRender;

            var scrollTop = limitedScrollTop();
            var startRenderingAt = Math.floor(startRender * itemsPerVirtualPage);
            var endRenderingAt = startRenderingAt + itemsPerVirtualPage * 3;

            var renderedItems = [];
            var i;

            for (i = startRenderingAt; i < endRenderingAt; i++) {
                var item2 = dataSource[i];
                renderedItems[i] = (item2 && !item2._idOnly && typeof options.renderItemHandler === 'function')
                    ? options.renderItemHandler(item2, options.renderOptions)
                    : null;
            }

            $pleaseWait.hide();
            $noItemstoShow.toggle(!totalItems);
            $vlist.detach();

            $panelTop.height(startRenderingAt * options.itemHeight);

            var counter = 0;
            var itemsDrawn = 0;

            for (i = startRenderingAt; i < endRenderingAt; i++) {
                var ctrl = $panelItems[counter++];

                if (i >= totalItems) {
                    ctrl.hide();
                    continue;
                }

                itemsDrawn++;

                var item = dataSource[i];
                var renderedItemHtml = renderedItems[i];
                var id = item ? (item.Id || item.id || item.uid) : -1;

                ctrl
                    .toggleClass('selected', !!selectedItemIds[id])
                    .toggleClass('focus', focusedIndex == i)
                    .attr({ 'data-index': i, 'data-id': id })
                    .height(options.itemHeight)
                    .html(renderedItemHtml)
                    .toggle(!!renderedItemHtml);

                if (typeof (options.renderItemHandlerPostHandler) == "function") {
                    options.renderItemHandlerPostHandler(item, options.renderOptions, ctrl);
                }
            }

            var bottomHeight = totalItems == 9007199254740991 ? 0 : Math.max(0, Math.ceil((totalItems - (startRenderingAt + itemsDrawn)) * options.itemHeight));
            $panelBottom.height(bottomHeight);

            control.append($vlist);
            control.scrollTop(scrollTop);
        }

        function onSizeChanged() {
            ensureScaffoldingCreated();
            calculateHeightFromPlaceholder();
            focusItem();
            refreshDataThrottled();
        }

        function setFocusedItemStyle(index) {
            control.find('.panel-item.focus').removeClass('focus');
            if (index === null || index === undefined)
                return;
            control.find(".panel-item[data-index='" + index + "']").addClass('focus');
        }

        function focusItem(index) {
            if (index)
                focusedIndex = index;

            var scrollTop = limitedScrollTop();
            var containerHeight = control.height();
            var topmostItem = Math.floor(scrollTop / options.itemHeight);
            var bottommostItem = Math.floor((scrollTop + containerHeight) / options.itemHeight);
            if (bottommostItem >= totalItems)
                bottommostItem = totalItems - 1;
            if (topmostItem < 0)
                topmostItem = 0;

            if (focusedIndex <= topmostItem) {
                control.scrollTop(focusedIndex * options.itemHeight);
            }
            else if (focusedIndex >= bottommostItem) {
                var newTop = (focusedIndex * options.itemHeight) - (containerHeight - options.itemHeight);
                control.scrollTop(newTop);
            }

            setFocusedItemStyle(focusedIndex);
        }

        function getSelectedItems() {
            var retVal = [];
            for (var i = 0; i < dataSource.length; i++) {
                var item = dataSource[i];
                if (!item) continue;
                var id = item.Id || item.id || item.uid;
                if (selectedItemIds[id])
                    retVal.push(item);
            }
            return retVal;
        }

        function getSelectedIds() {
            var retVal = [];
            for (var id in selectedItemIds)
                if (selectedItemIds.hasOwnProperty(id))
                    retVal.push(id);
            return retVal;
        }

        function getItemByIndex(index) {
            return dataSource[index];
        }

        function getItemById(toFind) {
            toFind = +toFind;
            for (var i = 0; i < dataSource.length; i++) {
                var item = dataSource[i];
                var id = item ? (item.Id || item.id || item.uid) : null;
                if (id === toFind)
                    return item;
            }
            return null;
        };

        // Functions dealing with selection -----------------------------------------------------------------

        function setSelectMode(mode) {
            inSelectMode = mode;
        }

        function selectAll() {
            if (!totalItems)
                return;

            //lastClickedIndex = 0;
            var arr = [];
            for (var i = 0; i <= totalItems - 1; i++) {
                arr.push(i);
            }

            selectItems(arr, true);
            control.focus();
        }

        function selectNone() {
            if (!totalItems)
                return;
            selectItems([lastClickedIndex], true);
        }

        function toggleSelection(index, force) {
            var item = dataSource[index];
            if (!item)
                return;
            var id = item.Id || item.id || item.uid;

            selectedItemIds = selectedItemIds || {};
            if (force === true)
                selectedItemIds[id] = true;
            else if (force === false)
                delete selectedItemIds[id];
            else if (selectedItemIds[id])
                delete selectedItemIds[id];
            else
                selectedItemIds[id] = true;

            // TODO: If removed active selected item, choose next one in list

            $vlist.children('.panel-item').removeClass('selected');
            var selectedKeys = [];
            for (var key in selectedItemIds)
                if (selectedItemIds.hasOwnProperty(key)) {
                    selectedKeys.push(key);
                    $vlist.children(".panel-item[data-id='" + key + "']").addClass('selected');
                }
            $('#SelectedIDs').val(selectedKeys.join(','));
            paintItemsThrottled();
        }

        function forceSelectItemByIndex(index, event) {

            //if (!dataSource[index]) {
            //    var params = { start: index, count: options.itemsToPreloadAroundData, preDataCallback: null, postDataCallback: null, idOnly: false };
            //    refreshData(params);
            //}

            selectedItemIds = {};
            focusedIndex = index;
            focusItem();
            toggleSelection(index, true);
            lastClickedIndex = index;
            afterSelectItem(event);
        }

        function selectItemByIndex(index, event) {
            var item = dataSource[index];
            if (!item)
                return;

            var ctrl = (event && (event.ctrlKey || event.metaKey)) || inSelectMode;
            if (!ctrl)
                selectedItemIds = {};

            var shift = event && event.shiftKey;
            if (shift && lastClickedIndex != -1)
                selectItemRange(lastClickedIndex, index, true, event);
            else {
                var id = item.Id || item.id || item.uid;
                if (!selectedItemIds[id] || _.size(selectedItemIds) !== 1)
                    toggleSelection(index);
                lastClickedIndex = index;
                afterSelectItem(event);
            }
        }

        function selectItemById(id) {
            selectedItemIds = {};
            selectedItemIds[id] = true;
            reset();
        }

        function addItemToSelectionById(id) {
            if (!selectedItemIds[id]) {
                selectedItemIds[id] = true;
                reset();
                afterSelectItem();
            }
        }

        function selectNext(event) {
            if (!dataSource || !dataSource.length)
                return;

            var index = -1;
            for (var i = 0; i < dataSource.length; i++) {
                var item = dataSource[i];
                if (!item)
                    continue;
                var id = item.Id || item.id || item.uid;
                if (selectedItemIds[id]) {
                    index = i;
                    break;
                }
            }

            index++;
            if (index < totalItems) {
                focusedIndex = index;
                focusItem();
                selectItemByIndex(index, event);
                var indexToCheck = Math.min(totalItems - 1, index + options.itemsToPreloadAroundData);
                if (!dataSource[indexToCheck]) {
                    var params = { start: index, count: options.itemsToPreloadAroundData, preDataCallback: null, postDataCallback: null, idOnly: false };
                    refreshData(params);
                }
            }
        }

        function selectPrevious(event) {
            if (!dataSource || !dataSource.length)
                return;

            var index = 1;
            for (var i = 0; i < dataSource.length; i++) {
                var item = dataSource[i];
                if (!item)
                    continue;
                var id = item.Id || item.id || item.uid;
                if (selectedItemIds[id]) {
                    index = i;
                    break;
                }
            }

            index--;
            if (index < totalItems && index >= 0) {
                focusedIndex = index;
                focusItem();
                selectItemByIndex(index, event);
                var indexToCheck = Math.max(0, index - options.itemsToPreloadAroundData);
                if (!dataSource[indexToCheck]) {
                    var params = { start: indexToCheck, count: options.itemsToPreloadAroundData, preDataCallback: null, postDataCallback: null, idOnly: false };
                    refreshData(params);
                }
            }
        }

        function afterSelectItem(event) {
            var id = null;
            var item = null;
            var selectedCount = 0;
            for (var key in selectedItemIds)
                if (selectedItemIds.hasOwnProperty(key)) {
                    selectedCount++;
                    if (id == null) {
                        id = key;
                        item = getItemById(key);
                    }
                }

            if (typeof options.onSelectionCountChanged === 'function')
                options.onSelectionCountChanged(selectedCount);
            if (selectedCount === 1 && typeof options.onItemClick === 'function' && (!event || event.type !== "contextmenu")) {
                options.onItemClick(id, item, event);
            }
        }

        function selectItemRange(indexStart, indexEnd, newValue, event) {
            var num1 = Math.min(indexStart, indexEnd);
            var num2 = Math.max(indexStart, indexEnd);
            var arr = [];
            for (var i = num1; i <= num2; i++) {
                arr.push(i);
            }
            selectItems(arr, newValue, event);
        }

        function selectItems(indexes, newValue, event) {
            if (!indexes || !indexes.length)
                return;

            // Ensure loaded
            var minIndex = indexes[0];
            var maxIndex = indexes[0];
            for (var i = 1; i < indexes.length; i++) {
                minIndex = Math.min(minIndex, indexes[i]);
                maxIndex = Math.max(maxIndex, indexes[i]);
            }

            // TODO: refreshData really should be a promise
            // TODO: Show a spinner here, then hide in postload
            var params = { start: minIndex, count: maxIndex + 1 - minIndex, preDataCallback: null, postDataCallback: function () { selectItemsPostLoad(indexes, newValue, event); }, idOnly: true };
            refreshData(params);
        }

        function selectItemsPostLoad(indexes, newValue, event) {
            if (indexes.length == 1) {
                var item2 = dataSource[indexes[0]];
                var id2 = item2.Id || item2.id || item2.uid;
                if (typeof options.onItemClick === 'function')
                    options.onItemClick(id2, item2, event);
            }


            selectedItemIds = {};
            var selectedIds = [];
            for (var i = 0; i < indexes.length; i++) {
                var item = dataSource[indexes[i]];
                var id = item.Id || item.id || item.uid;
                selectedIds.push(id);

                if (newValue === true)
                    selectedItemIds[id] = true;
                else if (newValue === false)
                    delete selectedItemIds[id];
                else if (selectedItemIds[id])
                    delete selectedItemIds[id];
                else
                    selectedItemIds[id] = true;
            }
            $('#SelectedIDs').val(selectedIds.join(','));

            dateLastChanged = null;
            dateLastChangePainted = null;
            paintItemsThrottled();
            if (typeof options.onSelectionCountChanged === 'function')
                options.onSelectionCountChanged(indexes.length);
        }
    }
})(jQuery, _);

/*

-------------------------------------------------------------------------------------------

Description

An infinite table of panels that uses a small sliding window of dom elements to keep memory
and CPU to a minimum.  Requests rows from an AJAX web API as they are needed.

-------------------------------------------------------------------------------------------

Example Usage

In HTML:

    <div id="TicketPanelGrid"></div>

In JS:

    var searchCriteria = {
        // All this just gets passed to the server
        ViewMode: "Normal",
        SortBy: "Status",
        SortOrder: "asc"
    };

    // Optionally, you can pull searchCriteria from codebehind by using JsonConvert.Serialize
    // on the backend, and use code like this on the frontend
    // var searchCriteria = <%= SearchCriteraJson %>;

    var control = new window.SmarterTools.PanelGrid(
        $("#TicketPanelGrid"),
        {
            searchApiUrl: "<%= VR %>api/tickets/search",
            searchCriteria: searchCriteria,
            renderItemHandler: TicketsLibrary.createGridItem,
            itemHeight: TicketsLibrary.calculateGridItemHeight(),
            onItemClick: function (item) {
                var id = item.attr('data-id');
                var uri = "<%= VR %>Management/Tickets/frmTicket.aspx?ticketid=" + id;
                NavPreviewPane(uri);
            },
            onItemDoubleClick: function (item) {
                var id = item.attr('data-id');
                var uri = "<%= VR %>Management/Tickets/frmTicket.aspx?popup=true&ticketid=" + id;
                OpenNewMessage(uri, 900, 650);
            }
    });

-------------------------------------------------------------------------------------------

Options

itemsToGrabAtInit: 100
     When the grid first initializes, it will request this many items and start the user out
     at the top of the list.

itemsToPreloadAroundData: 50
     When scrolling, grid will request the missing rows as well as UP TO this many below it.
     It will not request rows it already has loaded.

searchApiUrl: null (REQUIRED)
     The URL to the API that should be called to fetch rows.  This API must follow these 
     critera: 
     - Method: POST
     - Data Type: json
     - Accepts an object as defined in the searchCriteria option
     The grid will add two extra properties to the criteria object:
     - skip (the number of rows that should be skipped in the result set)
     - take (the number of rows that should be returned)
     The API function should return data in this form:
     {
       Results: [],  // An array of result items.  
                     // Each of these should have an "Index" and an "Id" property
       TotalCount: # // The total items in the set, including those not returned
     }

searchCriteria: { }
     An arbitrary object of search data that is passed into the search API.  This can be 
     whatever your system requires.

onGetDataCompleted: function (totalCount) {}
    Called when the search API comes back with results.  Useful for showing panels for
    "no items found" or for setting counters

itemHeight: 20 
    The height of each element in the list, when rendered by the renderItemHandler function.
    For correct operation of the grid, all items should be the same height.

renderItemHandler: function(item) { console && console.log(JSON.stringify(item)); }
     This function is called to render everything inside of the panel, once per item.  It is
     up to the implementer to define this function.  Should return a string.

onItemClick: function (item) { }
     This function is called when a grid item is selected

onItemDoubleClick: function (item) { }
     This function is called when a grid item is double-clicked

onDeleteKey: function(items) { }
     This function is called when the user presses the delete key with items selected

onRightClick: function(items, event) { }
     This function is called when the user right clicks an item

-------------------------------------------------------------------------------------------

Known Issues:

Over 169,465 items causes blank rows.  Seems that a top position this large is not allowed
- Probably need some sort of scroll factor
- Until then, number of items that would exceed 10,000,000 pixels are treated 
like they don't exist
- In old code, there was a row like this: grid.Scroller[0].scrollTop += (grid.rowHeight * -3 * delta) + 5;

If the data set changes on the server (smaller or larger), how does the index get affected
on items?  what about scroll position?

-------------------------------------------------------------------------------------------

*/