(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("editGroupMemberController", editGroupMemberController);

	function editGroupMemberController($scope, $mdDialog, $q, $timeout, memberItem, possibleMembers, sourceOwner, galEnabled, coreDataSettings) {
		$scope.autoMemberSelectedItem = $scope.originalItem = memberItem;
		$scope.isNewItem = !memberItem;
		$scope.galEnabled = galEnabled;
		// Functions
		$scope.getItemText = getItemText;
		$scope.onAutoMemberBlur = updateFromAutoMembers;
		$scope.onAutoMemberSelectedChanged = updateFromAutoMembers;
		$scope.onAutoMemberTextChanged = onAutoMemberTextChanged;
		$scope.cancel = $mdDialog.cancel;
		$scope.isGroup = false;
		$scope.doDelete = doDelete;
		$scope.queryMembers = queryMembers;
		$scope.save = save;
		
		activate();

		function activate() {
			if (!$scope.isNewItem) {
				$scope.emailAddress = $scope.originalItem.emailAddress;
				$scope.displayName = $scope.originalItem.displayName;
				$scope.isOneOff = $scope.originalItem.type === 0;
				$scope.isGroup = !($scope.originalItem.emailAddress);
			}
		}
		
		function doDelete() {
			$mdDialog.hide({ delete: true });
		}

		function getItemText(item) {
			return item.emailAddress
				? item.emailAddress
				: item.displayName;
		}

		function onAutoMemberTextChanged() {
			if ($scope.autoMemberText) {
				$scope.memberForm["autoMember"].$setValidity("validate-email", coreDataSettings.validateEmailAddress($scope.autoMemberText));
			}
			if ($scope.memberForm.$valid) {
				$scope.emailAddress = $scope.autoMemberText || $scope.emailAddress;
				updateFromAutoMembers();
			}

		}
		
		function queryMembers(searchText) {
			if (!searchText || !possibleMembers || possibleMembers.length === 0)
				return $q.when([]);
			
			const lowerSearch = searchText.toLowerCase();
			var results = possibleMembers
				.filter(function(c) {
					if (!$scope.galEnabled && c.type === 2) return false;
					const lowerEmail = c.emailAddress ? c.emailAddress.toLowerCase() : null;
					const lowerDisplay = c.displayName ? c.displayName.toLowerCase() : null;
					return (lowerEmail && lowerEmail.indexOf(lowerSearch) >= 0) ||
						(lowerDisplay && lowerDisplay.indexOf(lowerSearch) >= 0);
				});

			return $q.when(results);
		}

		function save(form) {
			if (!form.$valid)
				return;

			// Make sure that the member details have been updated.
			updateFromAutoMembers();

			var item = $scope.autoMemberSelectedItem;
			if ($scope.isOneOff) {
				item = {
					type: 0,
					addressType: "SMTP",
					displayName: $scope.displayName,
					emailAddress: $scope.emailAddress
				}
			}

			if (!item)
				return;

			item.sendAsMime = true;

			$mdDialog.hide({ member: item });
		}

		function updateFromAutoMembers() {
			if (!$scope.galEnabled &&
				$scope.autoMemberText &&
				possibleMembers) {
				// if the gal is hidden we still need to attempt to match a contact for in the Gal
				var galMember = possibleMembers.find(member => member.type === 2 &&
					member.emailAddress.toLowerCase() === $scope.autoMemberText);
				if (galMember)
					$scope.autoMemberSelectedItem = galMember;
			}
				
			if (!$scope.autoMemberSelectedItem || $scope.autoMemberSelectedItem.type === 0) {
				if (!$scope.isOneOff && $scope.isNewItem)
					$scope.displayName = "";
				
				$scope.isOneOff = true;
				$scope.isGroup = false;
			}
			else {
				$scope.emailAddress = $scope.autoMemberSelectedItem.emailAddress;
				$scope.displayName = $scope.autoMemberSelectedItem.displayName;
				$scope.isOneOff = false;
				$scope.isGroup = !($scope.emailAddress);
				$scope.memberForm["autoMember"].$setValidity("validate-email", true);
			}
		}
	}
})();