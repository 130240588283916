(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("decludeSpamCtrl", decludeSpamCtrl);

	function decludeSpamCtrl($rootScope, $scope, $mdDialog, $http, errorHandling, $q) {
		$scope.isDecludeInstalled = true;
		activate();

		///////////////

		function activate() {
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/antispam/is-declude-installed")
				.then(
					function(success) {
						if (!success.data.result)
							$scope.isDecludeInstalled = false;
					}, 
					function(failed) {
						$scope.isDecludeInstalled = false;
					}),
				$http
				.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(onLoad, errorHandling.report)

			];
			$rootScope.spinner.show();
			$q.all(promises)
				.finally($rootScope.spinner.hide);

			function onLoad(result) {
				$scope.item = angular.copy(result.data.settings.declude);
			}
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.save = function () {
			if (!$scope.spamForm.$valid)
				return;

			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/antispam/settings", JSON.stringify({ declude: $scope.item }))
				.then($mdDialog.hide, errorHandling.report)
				.finally($rootScope.spinner.hide);
		};
	}
})();