(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('messageViewSpool', {
            templateUrl: "app/email/spool/view-spool.component.html",
            controller: messageViewSpool,
        });

    /* @ngInject */
    function messageViewSpool($rootScope, $scope, $stateParams, $timeout, $element, $mdDialog, $translate, emailFunctions, emailSpoolFunctions, emailNavigation,
        errorMessageService, spinnerFactory, errorHandling, successHandling, userTimeService) {
        var vm = this;
        var popoutPacket = emailNavigation.parsePopoutPacket($stateParams.packet);
        vm.loading = true;
        vm.message = null;
        vm.mode = 'text';
        vm.isQuarantineMessage = !!popoutPacket.quarantine;
        vm.iFrameLoaded = false;
        vm.messageDoesNotExist = false;
        vm.mode = popoutPacket.mode || 'text';

        // Functions
        vm.closeWindow = closeWindow;
        vm.deleteClicked = deleteClicked;
        vm.viewSpoolRecipients = viewSpoolRecipients;
        vm.onDownloadRaw = onDownloadRaw;
        vm.viewAsClicked = viewAsClicked;
        vm.showAttachmentsModal = showAttachmentsModal;
        vm.copyContentToClipboard = copyContentToClipboard;

        /////////////////

        vm.$onInit = function () {
            userTimeService.init();
            $(document).on("keydown", ListenPrintHotkey);
            $element.addClass('message-view-spool');
            init();
        };

        vm.$onDestroy = function () {
            $(document).off("keydown", ListenPrintHotkey);
        };

        async function init() {
            const spinner = $rootScope.spinner || new spinnerFactory();

            try {
                spinner.show();
                vm.loading = true;

                const loadedMessage = await emailSpoolFunctions.loadMessage(popoutPacket.fileName, popoutPacket.spoolName, !!popoutPacket.quarantine);
                const noTextContent = '[' + $translate.instant('MESSAGE_HAS_NO_TEXT_CONTENT') + ']';
                const noHtmlContent = '[' + $translate.instant('MESSAGE_HAS_NO_HTML_CONTENT') + ']';
                vm.messageDoesNotExist = !loadedMessage;

                if (loadedMessage) {
                    vm.message = loadedMessage;
                    vm.message.raw = loadedMessage.raw || $translate.instant('NO_CONTENT_TO_SHOW');
                    vm.message.hdr = loadedMessage.hdr || $translate.instant('NO_CONTENT_TO_SHOW');
                    let originalPlainText = loadedMessage.messagePlainText;
                    vm.message.messagePlainText = loadedMessage.messagePlainText || noTextContent;
                    if (loadedMessage.messageHtml) {
                        vm.message.messageHTML = loadedMessage.messageHtml;
                    } else {
                        const regexPlainLines = /\r\n|\n/gm;
                        const plainConverted = $("<div>").text(originalPlainText || noHtmlContent).html();
                        vm.message.messageHTML = plainConverted.replace(regexPlainLines, `<br>`);
                    }

                    vm.message.toAddresses = [loadedMessage.to];
                    vm.message.cc = [loadedMessage.cc];
                    vm.message.bcc = [loadedMessage.bcc];
                    vm.message.date = new Date(loadedMessage.date);

                    // TODO: Filter Trackers and these items
                    // vm.activeTrackersCount = XXX
                    // vm.hadUnsafeCode = response.data.messageData.hadUnsafeCode;
                    // vm.htmlIsStillUnsafe = response.data.messageData.htmlIsStillUnsafe;
                    // vm.imagesBlocked = response.data.messageData.imagesBlocked;
                    // vm.cleansedFromAddress = response.data.messageData.cleansedFromAddress;

                    userTimeService.convertLocalToUserTime(vm.message.date)
                        .then(function (success) {
                            if (success.getSeconds() >= 30) {
                                success.setMinutes(success.getMinutes() + 1);
                            }
                            success.setSeconds(0);
                            vm.message.userDate = success;
                            vm.message.userDataMoment = moment(success).calendar();
                        }, function () { });

                    loadIframe(vm.message, vm.mode);
                }
            } catch (err) {
                vm.messageDoesNotExist = true;
                errorHandling.report();
            } finally {
                vm.loading = false;
                spinner.hide();
            }

            $scope.$applyAsync(function () { });
        }

        function closeWindow() {
            window.close();
        }

        function ListenPrintHotkey(event) {
            if (event.keyCode == 80 && (event.metaKey || event.ctrlKey)) { //ctrl + p
                event.preventDefault();
                vm.message.mode = vm.mode;
                emailFunctions.showPrintPopup(vm.message);
            }
        }

        async function deleteClicked() {
            try {
                let fileName = popoutPacket.fileName;
                let spoolName = popoutPacket.spoolName;
                const wasDeleted = await emailSpoolFunctions.deleteMessage(fileName, spoolName, false);
                if (wasDeleted) {
                    if (typeof window.opener.refreshSpool === 'function')
                        window.opener.refreshSpool();
                    window.close();
                }
            }
            catch (err) {
                errorMessageService.showErrorMessage(err);
            }
        }

        async function viewSpoolRecipients(event) {
            try {
                await emailSpoolFunctions.popupRecipientsModal(popoutPacket.fileName, popoutPacket.spoolName, event);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function onDownloadRaw() {
            try {
                await emailSpoolFunctions.downloadEmlFile(popoutPacket.fileName, popoutPacket.spoolName, popoutPacket.quarantine);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function viewAsClicked(mode) {
            try {
                vm.mode = mode;

                // TODO: The spool always loads raw content. This is pretty inefficient, and it should only load when people click on it
                // If they're requesting raw and we don't have it, load it
                //if (mode === 'raw' && !vm.message.raw) {
                //    vm.message.raw = await emailArchiveFunctions.loadRawContent(popoutPacket.searchGuid, popoutPacket.uid);
                //}

                loadIframe(vm.message, vm.mode);
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function loadIframe(contents, mode) {
            // TODO: Ban trackers
            //if (vm.message && vm.message.foundTrackers && vm.message.foundTrackers.length > 0) {
            //    for (var i = 0; i < vm.message.foundTrackers.length; i++) {
            //        var ft = vm.message.foundTrackers[i];
            //        contents.messageHTML = emailTracker.replaceTracker(contents.messageHTML, ft.id);
            //    }
            //}

            var iframe = document.getElementById('spoolViewFrame');
            if (iframe && iframe.contentWindow && iframe.contentWindow.setHTML) {
                contents.translations = contents.translations || {};
                contents.translations.MESSAGE_HAS_NO_TEXT_CONTENT = $translate.instant('MESSAGE_HAS_NO_TEXT_CONTENT');
                contents.translations.MESSAGE_HAS_NO_HTML_CONTENT = $translate.instant('MESSAGE_HAS_NO_HTML_CONTENT');
                contents.translations.MESSAGE_ENCRYPTED_CANNOT_VIEW = $translate.instant('MESSAGE_ENCRYPTED_CANNOT_VIEW');
                contents.translations.MESSAGE_SIGNED = $translate.instant('MESSAGE_SIGNED');
                iframe.contentWindow.setHTML(contents, mode);
                $rootScope.$applyAsync(() => vm.iFrameLoaded = true);
            } else {
                $timeout(function () { loadIframe(contents, mode); }, 200);
            }
        }

        async function showAttachmentsModal() {
            try {
                await $mdDialog.show({
                    locals: { message: vm.message },
                    controller: "emailAttachmentsController",
                    templateUrl: 'app/email/modals/attachments.dlg.html',
                    clickOutsideToClose: true
                });
            } catch (e) { }
        }

        async function copyContentToClipboard() {
            try {
                let text = "";
                switch (vm.mode) {
                    case 'text': text = vm.message.messagePlainText; break;
                    case 'raw': text = vm.message.raw; break;
                    case 'hdr': text = vm.message.hdr; break;
                    default: throw 'View type cannot be copied to clipboard';
                }

                navigator.clipboard.writeText(text);
                successHandling.report($translate.instant("COPIED_TO_CLIPBOARD"));
            } catch (err) {
                errorHandling.report(err);
            }
        }


    }

})();
