(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEditReservedDomainNameEditController", sysAdminEditReservedDomainNameEditController);

	function sysAdminEditReservedDomainNameEditController($scope, $mdDialog, domain) {
		$scope.editingItem = domain;
		$scope.domains = domain;
		$scope.isValid = true;
		
		$scope.save = function (form) {
			form.$setSubmitted();
			if (!($scope.isValid = form.$valid)) {
				return;
			}

			var splitDomains = [];
			if (!$scope.editingItem) {
				splitDomains = $.grep($scope.domains.split("\n"), function (name) {
					if (!name)
						return false;
					return true;
				});
			} else
				splitDomains = [$scope.domains];

			$mdDialog.hide({
				domains: splitDomains
			});
		};
		$scope.deleteItem = function () {
			$mdDialog.hide({
				toRemove: [$scope.domains]
			});
		}

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
