(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("spoolDeleteConfirmController", spoolDeleteConfirmController);

	function spoolDeleteConfirmController($scope, $mdDialog, coreDataSpool, address, type, editAddress) {
		var vm = this;

		vm.address = address;
		$scope.type = type;
		$scope.addressRequired = false;
		$scope.editAddress = editAddress;
		$scope.init = function() {
			switch ($scope.type) {
				case coreDataSpool.spoolStatTypes.OutboundSenders:
				case coreDataSpool.spoolStatTypes.InboundRecipients:
				case coreDataSpool.spoolStatTypes.InboundSenders:
					$scope.addressRequired = true;
					break;
			};
		};

		$scope.save = function () {
			if ($scope.spoolDeleteForm.$valid) {
				$mdDialog.hide({address: vm.address, type: $scope.type});
			}
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
