(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editStunServerController", editStunServerController);

	function editStunServerController($scope, $mdDialog, server, newItem) {

		$scope.server = server || { serverType: 0 };
		$scope.newItem = newItem;
		
		// Functions
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
		}

		function save() {
			var servers = [];
			var uris = $scope.server.urlstring.replace(/\r/g, "").split("\n");
			var stun = "stun:", turn = "turn:", turns = "turns:";
			for (var i = 0; i < uris.length; ++i) {
				if (!uris[i])
					continue;

				uris[i] = uris[i].trim();

				var index = uris[i].indexOf("://");
				if (index > -1)
					uris[i] = uris[i].substring(index + 3);

				if ($scope.server.serverType === 0 && !uris[i].toLocaleLowerCase().startsWith(stun))
					uris[i] = stun + uris[i];
				else if ($scope.server.serverType === 1 &&
					!uris[i].toLocaleLowerCase().startsWith(turn) &&
					!uris[i].toLocaleLowerCase().startsWith(turns))
					uris[i] = turn + uris[i];

				servers.push(uris[i]);
			}

			$scope.server.urlstring = servers.join("\r\n");
			$mdDialog.hide({ server: $scope.server, deleteItem: false });
		}

		function deleteThis() {
			$mdDialog.hide({ deleteItem: true });
		}

		function cancel() {
			$mdDialog.cancel();
		}
	}

})();
