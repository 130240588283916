/* eslint-disable no-undef */
(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("signalrHubManager", signalrHubManager);

    function signalrHubManager($rootScope, $q, authStorage, coreDataMail) {
        var vm = this;
        var mailHubUrl = stSiteRoot + "hubs/mail";
        var connection;
        var initDefer;

        // Service variables
        vm.selfTestRunning = false;
        vm.coreDataListener = null;
        vm.selfTestSignalRStr = [];
        vm.connection = null;

        // Functions
        vm.init = init;
        vm.isConnected = isConnected;
        vm.userLogout = userLogout;
        vm.pingServer = pingServer;

        activate();

        // Implementation ---------------------------------------

        function activate() {
            $rootScope.$on("signalR.selfTest", function (a, data) {
                console.log("[SignalR Self Check " + data.testStr + "] Got response from server: " + data.testStr)
                vm.selfTestSignalRStr.push(data.testStr);
            });
        }

        function init() {
            if (initDefer)
                return initDefer.promise;
            initDefer = $q.defer();

            var promise = initDefer.promise;
            if (connection) {
                let result = { mailHub: connection };
                initDefer.resolve(result);
            }

            innerInit()
                .then(function () {
                    let result = { mailHub: connection };
                    initDefer.resolve(result);
                })
                .catch(function (error) {
                    initDefer.reject(error);
                    initDefer = null;
                });

            return promise;
        }

        async function innerInit() {
            console.log("SignalR Starting");
            connection = new signalR.HubConnectionBuilder()
                .withUrl(mailHubUrl)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect({ nextRetryDelayInMilliseconds: nextReconnectRetryDelayInMilliseconds })
                .build();
            connection.onreconnected(onSignalrReconnected);
            connection.onclose(onSignalrClose);
            vm.connection = connection;

            await startConnection();
            setupMailHooks();
            setupDashboardHooks();
            setupChatVideoHooks();
            console.log("SignalR Started");

            runSignalRTest();

            var isWebkit = navigator.userAgent.toLowerCase().indexOf("applewebkit") != -1;
            if (isWebkit)
                setInterval(vm.pingServer, 10000);
        }

        function nextReconnectRetryDelayInMilliseconds(retryContext) {
            let retryCount = (retryContext && retryContext.previousRetryCount) || 0;
            let retries = [0, 250, 1000, 1000, 1000, 1000, 2500, 5000, 5000, 5000, 5000, 5000, 5000];
            if (retryCount < retries.length)
                return retries[retryCount];
            return 15000;
        }

        async function onSignalrReconnected() {
            console.log("SignalR Reconnected");
            await connection.invoke("connect", authStorage.getRefreshToken());
            $rootScope.$broadcast("signalRHubManagerReconnected");
            coreDataMail.loadMailTree(null, true)
                .then(
                    function () { $rootScope.$broadcast("mail.reloadSources"); },
                    function () { });
        }

        async function onSignalrClose() {
            console.log("Signalr Disconnected");
            connection && typeof connection.onDisconnected === 'function' && connection.onDisconnected();
            await startConnection();
        }

        async function startConnection() {
            try {
                await connection.start();
                await connection.invoke("connect", authStorage.getRefreshToken());
                console.log("SignalR Connected");
                $rootScope.$broadcast("signalRHubManagerConnected");
            } catch (err) {
                console.log(err);
                setTimeout(() => startConnection(), 5000);
            }
        }

        function isConnected() {
            return connection ? true : false;
        }

        function userLogout() {
            if (connection) {
                connection.onclose(() => { });
                connection.stop();
                connection = null;
            }
        }

        async function pingServer() {
            var str = Math.random().toString(36).substring(7);
            if (!connection)
                return;

            try {
                await connection.invoke("ping", str);
            } catch (e) {
                // ignored
            }
        }

        function setupDashboardHooks() {
            connection.on("dashboardUpdate", (data, startData) => {
                $rootScope.$broadcast("signalR.client.dashboardUpdate", data, startData);
            });

            connection.on("manageInit", (data, startData) => {
                $rootScope.$broadcast("signalR.client.manageInit", startData);
            });

            connection.on("dashboardInit", (data, startData) => {
                $rootScope.$broadcast("signalR.client.dashboardInit", startData);
            });
        }

        function setupMailHooks() {
            //Reconnect hook
            connection.on("reconnect", async () => { await connection.invoke("connect", authStorage.getRefreshToken()) });

            // Tests
            connection.on("test", () => { }); // DONT REMOVE
            connection.on("selfTestReturn", (data) => { $rootScope.$broadcast("signalR.selfTest", data); });

            // Notifications
            connection.on("notificationChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.notificationChanged", data); });
            connection.on("eventNotification", (data) => { $rootScope.$broadcast("signalR.mailHub.client.eventNotification", data); });
            connection.on("appointmentReminderNotification", (data) => { $rootScope.$broadcast("signalR.mailHub.client.appointmentReminderNotification", data); });

            // Sharing
            connection.on("sharesChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.sharesChanged", data); });

            // SSL
            connection.on("acmeCertsChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.acmeCertsChanged", data); });
            connection.on("sslCertsChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.sslCertsChanged", data); });

            // Email Signing
            connection.on("dkimRecordsUpdated", (data) => { $rootScope.$broadcast("signalR.mailHub.client.dkimRecordsUpdated", data) });

            // Mail
            connection.on("mailAdded", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailAdded", data); });
            connection.on("mailRemoved", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailRemoved", data); });
            connection.on("mailModified", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailModified", data); });
            connection.on("folderChange", () => { $rootScope.$broadcast("signalR.mailHub.client.folderChange"); });
            connection.on("mailAction", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailAction", data); });
            connection.on("messageFlagChange", (folder, UID, flags) => {
                $rootScope.$broadcast("signalR.mailHub.client.messageFlagChange", { folder: folder, UID: UID, flags: flags });
            });
            connection.on("mailAvatar", (data) => { $rootScope.$broadcast("mail.avatarLoaded", data); });
            connection.on("disposableAddressModified", () => { $rootScope.$broadcast("signalR.mailHub.client.disposableAddressModified", {}); });

            // Contacts
            connection.on("contactsModified", (modified) => { $rootScope.$broadcast("signalR.mailHub.client.contactsModified", modified); });
            connection.on("galUpdate", (galEntry) => { $rootScope.$broadcast("signalR.mailHub.client.galUpdate", galEntry); });
            connection.on("contactsDeleted", function (owner, contactIds) {
                $rootScope.$broadcast("signalR.mailHub.client.contactsDeleted", { owner: owner, contactIds: contactIds });
            });

            // Notes
            connection.on("notesModified", (modified) => { $rootScope.$broadcast("signalR.mailHub.client.notesModified", modified); });
            connection.on("notesDeleted", (owner, uids) => { $rootScope.$broadcast("signalR.mailHub.client.notesDeleted", { owner: owner, uids: uids }); });

            // Rss
            connection.on("rssContentsUpdated", (modified) => { $rootScope.$broadcast("signalR.mailHub.client.rssContentsUpdated", modified); });

            // Calendar
            connection.on("eventModified", (modified) => { $rootScope.$broadcast("signalR.mailHub.client.eventModified", modified); });
            connection.on("eventDeleted", (events) => { $rootScope.$broadcast("signalR.mailHub.client.eventDeleted", events); });

            // Tasks
            connection.on("tasksModified", (owner, modified) => { $rootScope.$broadcast("signalR.mailHub.client.tasksModified", modified); });
            connection.on("tasksDeleted", (owner, taskIds) => { $rootScope.$broadcast("signalR.mailHub.client.tasksDeleted", { owner: owner, taskIds: taskIds }); });

            // Archive
            connection.on("messageArchiveSearchUpdate", (data) => { $rootScope.$broadcast("signalR.mailHub.client.messageArchiveSearchUpdate", data.searchGuid); });

            // Meetings
            connection.on("meetingWorkspaceRemoved", (data) => { $rootScope.$broadcast("signalR.mailHub.client.meetingDeleted", data.meetingId); });
            connection.on("meetingWorkspaceModified", () => { $rootScope.$broadcast("signalR.mailHub.client.meetingModified", {}); });
            connection.on("meetingWorkspaceCreated", () => { $rootScope.$broadcast("signalR.mailHub.client.meetingCreated", {}); });

            // File Storage
            connection.on("fsFolderChange", (data) => { $rootScope.$broadcast("signalR.mailHub.client.fileStorageFolderChange", data); });
            connection.on("filesAdded", (data) => { $rootScope.$broadcast("signalR.mailHub.client.filesAdded", data); });
            connection.on("filesDeleted", (data) => { $rootScope.$broadcast("signalR.mailHub.client.filesDeleted", data); });
            connection.on("filesModified", (data) => { $rootScope.$broadcast("signalR.mailHub.client.filesModified", data); });

            // Settings
            connection.on("settingsModified", () => { $rootScope.$broadcast("signalR.mailHub.client.settingsModified", {}); });
            connection.on("settingsModified_byAdmin", (data) => { $rootScope.$broadcast("signalR.mailHub.client.settingsModified_byAdmin", data); });
            connection.on("mailMigrationUpdate", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailMigrationUpdate", data); });
            connection.on("mailRetrievalStart", (data) => { $rootScope.$broadcast("signalR.mailHub.client.mailRetrievalStart", data); });
            connection.on("listModeratorChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.listModeratorChanged", data); });
            connection.on("mailboxSizeUpdate", (data) => { $rootScope.$broadcast("diskSpaceUsage:changed", data); });
            connection.on("contentFilterProgress", (data) => { $rootScope.$broadcast("signalR.mailHub.client.contentFilterProgress", data); });
            connection.on("userGroupsModified", (data) => { $rootScope.$broadcast("signalR.mailHub.client.userGroupsModified", data); });
            connection.on("userSignaturesModified", (data) => { $rootScope.$broadcast("signalR.mailHub.client.userSignaturesModified", data); });
            connection.on("customHelpUpdated", (data) => { $rootScope.$broadcast("customHelpUpdated", data); });

            // Spool
            connection.on("spoolMessageStatusUpdate", (fileName, data) => { $rootScope.$broadcast("signalR.mailHub.client.SpoolMessageStatusUpdate", { fileName: fileName, data: data }); });
            connection.on("spoolMessageAdded", (fileName, data) => { $rootScope.$broadcast("signalR.mailHub.client.SpoolMessageAdded", { fileName: fileName, data: data }); });
            connection.on("spoolMessageRemove", (fileName) => {
                $rootScope.$broadcast("signalR.mailHub.client.SpoolMessageRemove", fileName);
                $rootScope.$broadcast("spoolMessageRemove", { fileName: fileName });
            });
            connection.on("addSpoolStat", () => { $rootScope.$broadcast("spoolRefresh"); });

            // Authentication
            connection.on("logout", () => { console.log("Logout initiated from SignalR"); $rootScope.$broadcast("signalR.mailHub.client.logout", {}); });

            // System Admin
            connection.on("certificateValidityUpdate", (data) => {
                console.log(data);
                $rootScope.$broadcast("signalR.mailHub.client.certificateValidityUpdate", data);
            });
            connection.on("domainReload", (data) => { $rootScope.$broadcast("signalR.mailHub.client.domainReload", data); });
            connection.on("detachDomainProgress", (data) => { $rootScope.$broadcast("signalR.mailHub.client.detachDomainProgress", data); });
            connection.on("messageArchiveReindex", (data) => { $rootScope.$broadcast("signalR.mailHub.client.messageArchiveReindex", data); });
            connection.on("logDownloadProg", (data) => { $rootScope.$broadcast("signalR.mailHub.client.logDownloadProg", data); });
            connection.on("profilerStatus", (data) => { $rootScope.$broadcast("signalR.mailHub.client.profilerStatus", data); });

            // Licensing
            connection.on("licensingChanged", (data) => { $rootScope.$broadcast("signalR.mailHub.client.licensingChanged", data); });

            // Categories
            connection.on("categoriesModified", () => { $rootScope.$broadcast("signalR.mailHub.client.categoriesModified", {}); });
        }

        function setupChatVideoHooks() {
            connection.on("chatVideoReceivingCall", (data) => { $rootScope.$broadcast("signalR.chatVideo.receivingCall", data); });
            connection.on("chatVideoReceiveResponse", (data) => { $rootScope.$broadcast("signalR.chatVideo.receiveResponse", data); });
            connection.on("chatVideoDisconnectCall", (data) => { $rootScope.$broadcast("signalR.chatVideo.disconnectCall", data); });
            connection.on("chatVideoRtcNegotiate", (data) => { $rootScope.$broadcast("signalR.chat.chatVideoRtcNegotiate", data); });
            connection.on("chatVideoRtcEmitReturn", (data) => { $rootScope.$broadcast("signalR.chatVideo.rtcEmitReturn", data); });
            connection.on("chatVideoRespondedToCall", () => { $rootScope.$broadcast("signalR.chatVideo.respondedToCall"); });
            connection.on("chatMarkRead", (data) => { $rootScope.$broadcast("signalR.chat.markRead", data); });
            connection.on("chatProviderChanged", (data) => {
                $rootScope.$broadcast("signalR.chat.chatProviderChanged", data);
            });
        }
        async function runSignalRTest() {
            if (!connection)
                return false;

            if (vm.selfTestRunning)
                return false;

            vm.selfTestRunning = true;

            const str = Math.random().toString(36).substring(7);

            console.log("[SignalR Self Check " + str + "] Starting...");

            const delay = 250;
            const attempts = 120;
            var attemptsRemaining = attempts;
            var connectionTestInterval = setInterval(selfTestStrMatchesResult, delay);

            try {
                const now = new Date();
                await connection.invoke("runConnectivityTest", authStorage.getRefreshToken(), str, now);

                selfTestStrMatchesResult();

            } catch (err) {
                console.log("[SignalR Self Check " + str + "] Server did not receive our request, check FAILED.");
                throw err;
            }

            function selfTestStrMatchesResult() {
                attemptsRemaining--;
                if (vm.selfTestSignalRStr.includes(str)) {
                    console.log("[SignalR Self Check " + str + "] Got result from server. SignalR is working! Check PASSED.");
                    //defer.resolve();
                    clearInterval(connectionTestInterval);
                }
                else if (attemptsRemaining < 0) {
                    console.log("[SignalR Self Check " + str + "] Server did not respond within " + (delay * attempts) + "ms, check FAILED.");
                    //defer.reject();
                    clearInterval(connectionTestInterval);
                }
            }
        }
    }
})();
