(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainNewUserController", domainNewUserController);

	function domainNewUserController($scope, $mdDialog, $http, $q, $filter, coreDataDomainSettings, errorHandling, isDomainAdmin) {
        var vm = this;
		$scope.username = '';
		$scope.password = '';
		$scope.confirmPassword = '';
		$scope.isValid = true;
		$scope.authType = 0;
		$scope.adUsername = null;
		$scope.adEnabled = false;
		$scope.meetsReq = false;
		$scope.newPasswordOnLogin = false;
		$scope.domainAdmin = isDomainAdmin;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {

            var promises = [
                coreDataDomainSettings.init(),
                $http.get("~/api/v1/settings/domain/domain")
            ];
			$q.all(promises).then(onLoaded, errorHandling.report);

			function onLoaded(result) {
				vm.domainSettings = result[1].data.domainSettings;
				$scope.authProvider = 
				$scope.adEnabled = vm.domainSettings.activeDirectoryIntegration;
				$scope.authProviders =
					vm.domainSettings.authenticationProviders.map(p => ({
						"id": p.providerId,
						"name": p.providerName,
						"type": p.providerType,
						"label": toLabel( p.providerType, p.providerName)
					}));
				$scope.authProviders.unshift({
					"id": "",
					"name": $filter('translate')("SMARTERMAIL"),
					"type": 0,
					"label": toLabel(0)
				});
				$scope.authProvider = $scope.authProviders[0];
				function toLabel(type, name) {
					let prefix = ""
					switch (type) {
					case 0:
						prefix = $filter('translate')("SMARTERMAIL");
						break;
					case 1:
						prefix = $filter('translate')("ACTIVE_DIRECTORY");
						break;
					case 3:
						prefix = $filter('translate')("LDAP");
						break;
					default:
						prefix = $filter('translate')("UNKNOWN");
						break;
					}

					return name ? `${prefix}: ${name}` : prefix;
				}
			}

			$scope.$watch("authProvider", function (newValue, oldValue) {
				if (newValue === oldValue) return;
				$scope.password = "";
				$scope.confirmPassword = "";
				$scope.adUsername = "";
			});
		}

		function save(form) {
			if (!form.$valid)
				return;

			if ((vm.domainSettings.domainPath.length + $scope.username.length) > 210) {
				errorHandling.report($filter('translate')('WINDOWS_FILE_LENGTH_EXCEEDED'));
				return;
			}

			var userData = {
				userName: $scope.username,
				password: $scope.password,
				adUsername: $scope.adUsername,
				securityFlags: {
					authType: $scope.authProvider.type,
					authProviderId: $scope.authProvider.id,
					isDomainAdmin: $scope.domainAdmin
				},
				isPasswordExpired: $scope.newPasswordOnLogin
			};
			if ($scope.authProvider.type === 0) {
				doSave();
			} else {
				verifyLdapUser();
			}
			function verifyLdapUser() {
				$http.post("~/api/v1/settings/domain/ldap-usernames",
						JSON.stringify({
							ldapProviderId: $scope.authProvider.id,
						}))
					.then(
						function(success) {
							const authUsername = $scope.adUsername || $scope.username;
							const ldapUser = success.data.usernames.find(contact => contact.toLowerCase() ===
								authUsername.toLowerCase());
							if (ldapUser) {
								doSave();
							} else {
								errorHandling.report(
									$filter("translate")("ERROR_ACTIVE_DIRECTORY_USERNAME_NOT_FOUND_ON_DOMAIN"));
							}
						},
						errorHandling.report);
			}
			function doSave() {
				$http
					.post('~/api/v1/settings/domain/user-put', JSON.stringify({ userData: userData }))
					.then(function (result) {
						if (result.data.success) {
							$mdDialog.hide({
								username: $scope.username,
							});
						}
					}, errorHandling.report);
			}

		};

		function cancel() {
			$mdDialog.cancel();
		};
	}

})();
