(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("addToFilterController", addToFilterController);
    function addToFilterController($scope, $mdDialog, $http, $translate, errorHandling, messages) {
        $scope.controller = this;
        $scope.filters = [];
        $scope.selectedFilter = undefined;
        $scope.messages = messages;
        $scope.senderDescription = "";
        if ($scope.messages && $scope.messages.length == 1 && $scope.messages[0].fromAddress)
            $scope.senderDescription = $scope.messages[0].fromAddress;
        else if ($scope.messages && $scope.messages.length > 1)
            $scope.senderDescription = $translate.instant("MULTIPLE", { count: $scope.messages.length });

        activate();

        // Load available filters

        function activate() {
            init();
        }

        async function init() {
            try {
                const success = await $http.get('~/api/v1/settings/content-filter-groups');
                $scope.filters = success.data.contentFilterGroups || [];

                // Remove filters that don't meet requirements
                $scope.filters = $scope.filters.filter(function (contentFilter) {
                    return contentFilter.filters.filter(function (filter) {
                        return (filter.fieldType == 1 && filter.filterType == 2)  // From specific domains
                            || (filter.fieldType == 0 && filter.filterType == 2)  // From specific addresses
                            || (filter.fieldType == 6 && filter.filterType == 2); // From address
                    }).length > 0;
                });
                $scope.selectedFilter = $scope.filters[0];
            } catch (err) {
                errorHandling.report(err);
            }
        }

        $scope.done = function () {
            $mdDialog.hide({ 'success': true, 'filter': $scope.selectedFilter });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }
})();