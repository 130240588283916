/*
 * Use Examples:
 * <st-avatar-icon name="message.fromAddress.name" image-url="message.avatarUrl" width="64" height="64"></st-avatar-icon>
 */

(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('stAvatarIcon', {
            controller: stAvatarIconController,
            bindings: {
                imageUrl: "<",
                name: "<",
                width: "<",
                height: "<",
                elementId: "@",
                noBorder: "<",
                preLoading: "<",
            },
            template: `
                <div class="st-avatar-icon avatar-color-{{$ctrl.colorIndex}}" 
                     id="{{$ctrl.elementId}}" 
                     ng-class="{
                         'avatar-border': !$ctrl.noBorder                         
                     }"
                     ng-style="{
                         'width': $ctrl.width,
                         'min-width': $ctrl.width,    
                         'max-width': $ctrl.width,    
                         'height': $ctrl.height,
                         'min-height': $ctrl.height,    
                         'max-height': $ctrl.height, 
                         'visibility': $ctrl.ready && !$ctrl.preLoading ? 'visible' : 'hidden',
                     }">
				    <img ng-image-on-load="$ctrl.onImageLoad()" 
                         ng-image-on-error="$ctrl.onImageError()" 
                         id="avimg_{{$ctrl.elementId}}" 
                         ng-src="{{$ctrl.imageUrl}}" 
                         ng-if="$ctrl.imageUrl" 
                         referrerpolicy="no-referrer">
					<div class="letter-container" 
                         ng-if="!$ctrl.imageUrl"
                         ng-style="{
                             'font-size': $ctrl.fontSize,
                             'line-height': $ctrl.lineHeight,
                         }">
                        {{$ctrl.firstLetter}}
                    </div>
                </div>`
        });

    function stAvatarIconController($scope, $timeout) {
        var vm = this;
        vm.height = vm.height || '32px';
        vm.width = vm.width || '32px';
        vm.firstLetter = vm.firstLetter || 'a';
        vm.imageUrl = vm.imageUrl || "";
        vm.ready = false;
        vm.imageLoadedUrl = null;
        vm.colorIndex = 'clear';

        // Functions
        vm.onImageLoad = onImageLoaded;
        vm.onImageError = onImageError;

        /////////////////

        vm.$onInit = function () { };

        vm.$onChanges = function (changesObj) {
            vm.fontSize = String(Math.ceil(vm.width * 0.37)) + 'px';
            vm.lineHeight = String(vm.width / 2) + 'px';
            if (changesObj['imageUrl']) {
                onImageUrlChanged(changesObj['imageUrl'].currentValue, changesObj['imageUrl'].previousValue);
            }
            updateColor();
        }

        function onImageLoaded() {
            $scope.$evalAsync(() => {
                vm.imageLoadedUrl = vm.imageUrl;
                vm.ready = true;
            });
        }

        function onImageError() {
            if (vm.imageUrl) {
                let unfoundAvatarCheck = "unfound-avatar-" + encodeURIComponent(vm.imageUrl);
                try {
                    sessionStorage.setItem(unfoundAvatarCheck, 1);
                } catch (err) { }
            }

            $timeout(function () {
                vm.imageLoadedUrl = '';
                vm.imageUrl = '';
                vm.ready = true;
                updateColor();
            });
        }

        function isEmptyUrl(url) {
            return !url || url == '/undefined' || url == '/null' ||
                url.indexOf('ST_User_Cir.svg') > 0 || url.indexOf('data=default') > 0;
        }

        function onImageUrlChanged(newValue, oldValue) {
            if (oldValue === newValue)
                return;

            if (vm.imageUrl) {
                try {
                    let unfoundAvatarCheck = "unfound-avatar-" + encodeURIComponent(vm.imageUrl);
                    if (sessionStorage.getItem(unfoundAvatarCheck))
                        vm.imageUrl = "";
                } catch (err) { }
            }

            vm.ready = false;

            if (isEmptyUrl(vm.imageUrl)) {
                vm.imageUrl = "";
                vm.imageLoadedUrl = null;
            }

            if (vm.imageUrl) {
                vm.ready = vm.imageLoadedUrl == vm.imageUrl;
            } else {
                vm.imageLoadedUrl = null;
                vm.ready = true;
            }

            $scope.$applyAsync();
        }

        function updateColor() {
            var nameValue = 0;
            if (vm.name) {
                for (var i = 0; i < vm.name.length; ++i) {
                    nameValue += vm.name.charCodeAt(i);
                }
            }
            var charValue = nameValue ? (nameValue % 26) : 27;

            vm.colorIndex = (vm.imageUrl || vm.preLoading) ? 'clear' : charValue;
            vm.firstLetter = vm.name ? vm.name.substring(0, 1).toUpperCase() : '';
        }

    }
})();