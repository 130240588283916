(function () {
	"use strict";

	/**
	 * Example(s):
	 * <st-toggle-switch st-model="enableGravSupport" translation-key="Require approval for custom avatars"></st-toggle-switch>
	 */
	angular.module("smartertools").directive('stToggleSwitch', ["$compile",
		function ($compile) {
			return {
				restrict: "EA",
				scope: {
					translationKey: "@",
					stModel: "=",
					onClick: "&",
					ngDisabled: "="
				},
				link: function (scope, element) {
					//$(element).uniqueId();

					var elementToAdd = angular.element(
						'<md-switch md-no-ink ng-model="stModel" class="md-primary" ng-click="myFunction()" aria-label="{{translationKey}}" ' + (scope.ngDisabled !== undefined ? 'ng-disabled="' + scope.ngDisabled + '"' : '') + '>' +
							'<span class="wordwrap" translate="{{translationKey}}"></span>' +
						'</md-switch>'
					);
					var elementCompiled = $compile(elementToAdd)(scope);
					element.append(elementCompiled);

					scope.myFunction = function () {
					    if (scope.ngDisabled !== true && scope.onClick != null) {
							return scope.onClick();
						}
					}
				}
			}
		}
	]);
})();