/*
 *
 * Any ng-table can be wrapped with a div containing the class st-table and it will have column switching
 *
 */

(function () {
    "use strict";

    angular
        .module("smartertools")
        .directive("stTable", stTableSecondDirective);

    if (!window.skipNgTableOverrides) {
        angular
            .module("smartertools")
            .directive("ngTable", ngTableRowDirective)
            .directive("ngTableDynamic", ngTableRowDirective);
    }

    // This directive happens before the things like ng-table or ng-if are transcluded/compiled/touched in any way
    // This allows the addition of basically anything to the DOM and afterwards it will compile and work properly
    function stTableSecondDirective($compile, tableColumnSwitching, tablePageService) {
        var switchers = { /*switcher: {}, optionalColumns: {}*/ };

        return {
            restrict: "C",
            priority: 1002,
            compile: onCompile,
            controller: stTableController,
        };

        function onCompile(elem, attrs) {
            var switcherToUse = tableColumnSwitching.requestSwitcher();
            var id = switcherToUse.id;
            const switchElementId = "tableSwitchCtrl" + id;

            // We're treating this as a dictionary that we grab from in the controller
            switchers[id] = {
                optionalColumns: {},
                switcher: switcherToUse
            };

            // Add Column binding so we can access the columns
            elem.find("table")
                .attr("ng-table-columns-binding", `${switchElementId}.columnSwitching.cols`);

            // Add ng-if="true" to any tds that aren't check boxes or ng-repeats
            elem.find("table td:not(.select-box)").not("[ng-repeat]")
                .attr("ng-if", "true");

            // Add a different ng-if for tds with ng-repeats. This can't be done for normal tds because ng-table takes over the ng-if and breaks it
            elem.find("table td:not(.select-box)[ng-repeat]")
                .attr("ng-if", `${switchElementId}.columnSwitching.cols[\$index].show()`);

            // If we have a checkbox, the firstColumn is the second column
            if (elem.find("table td.select-box").length > 0)
                switcherToUse.setFirstColumn(1);

            // Append the controls for the column switcher
            elem.append(`
                <span class="chevron" ng-click="${switchElementId}.columnSwitching.prev()" ng-show="${switchElementId}.columnSwitching.showLeftChevron">
                    <i class="toolsicon toolsicon-keyboard_arrow_left"></i>
                </span>
                <span class="chevron chevron-right" ng-click="${switchElementId}.columnSwitching.next()" ng-show="${switchElementId}.columnSwitching.showRightChevron">
                    <i class="toolsicon toolsicon-keyboard_arrow_right"></i>
                </span>
            `);

            // Wrap the table in a div that will allow extra control over the styling
            elem.find("table")
                .wrap(`<div class="table-container" ng-class="{'left-pad': ${switchElementId}.columnSwitching.currentColumn > ${switchElementId}.columnSwitching.firstColumn}"></div>`);

            // Create a unique class that we'll use on the Switcher to find this tables DOM element
            elem.addClass("st-table-" + id);

            var tds = elem.find("td");
            for (var i = 0; i < tds.length; i++)
                if (tds.eq(i).attr("st-optional"))
                    switchers[id].optionalColumns[i] = tds.eq(i).attr("st-optional");

            return {
                pre: function () { },
                post: function () { }
            }
        }

        function stTableController($scope, $element) {
            // Since our element has it's id as a class we parse that id to get our switcher
            var split = $element.attr("class").split(' ');
            var regex = /st-table-\d/;
            var id;
            for (var i = 0; i < split.length; i++) {
                if (regex.test(split[i])) {
                    id = split[i].split('-')[2];
                    break;
                }
            }
            var columnSwitching = switchers[id].switcher;

            for (var key in switchers[id].optionalColumns)
                columnSwitching.optionalColumns[key] = $scope.$parent ? ($scope.$parent.$parent ? ($scope.$parent.$parent[switchers[id].optionalColumns[key]] || {}) : {}) : {};

            columnSwitching.softReset();
            // Create a uniquely id'd object that will allow us to bind with the DOM properly

            $scope["tableSwitchCtrl" + columnSwitching.id] = { columnSwitching: columnSwitching };
            $scope.$on('$destroy', function () {
                tablePageService.reset(undefined);
                columnSwitching.softReset();
            });
        }
    }

    function ngTableRowDirective($compile, $timeout, tablePageService, ngTableEventsChannel) {
        var countSet = false;
        var pageSet = false;

        return {
            restrict: "A",
            priority: 1002,
            link: onLink
        };

        function onLink(scope, el, attrs) {
            scope.$watch("params._params.page", (newVal, oldVal) => onParamsPageChanged(scope, newVal, oldVal));
            ngTableEventsChannel.onAfterReloadData(() => onAfterReloadData(scope));
        }

        function onAfterReloadData(scope) {
            // Mainly doing this for the table pages
            // Because section like Mailing-lists.js that use "dataset: []" instead of "getData: queryData" have issues with going to the page you tell it to

            if (window.skipNgTableOverrides) return;

            countSet = true;
            pageSet = true;

            if (tablePageService.tablePageNumber > 1 && scope.params._params.page != tablePageService.tablePageNumber) {
                scope.params._params.page = tablePageService.tablePageNumber;
            }

        }


        function onParamsPageChanged(scope, newVal, oldVal) {
            if (window.skipNgTableOverrides) return;
            if (pageSet === true) {
                if (newVal != oldVal) {
                    tablePageService.tablePageNumber = newVal;
                }
                return;
            }

            if (tablePageService.tablePageNumber > 1)
                scope.params._params.page = tablePageService.tablePageNumber;
        };
    }
})();