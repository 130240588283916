(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("successHandling", successHandling);

	function successHandling($filter, $rootScope, toaster, $timeout) {
		var vm = this;

		// Functions
		vm.report = report;
		vm.reportAndHideSpinner = reportAndHideSpinner;

		/////////////////////

		function report(message, extra) {
			if (message && message.data)
				message = message.data;
			if (message && typeof message == "object")
                message = message.message;

			var translatedMessage = message
				? $filter("translate")(message, extra)
				: $filter("translate")("ACTION_SUCCEEDED");
			$timeout(function () { toaster.pop("success", "", translatedMessage); }, 10);
			
		}

		function reportAndHideSpinner(message) {
			report(message);
			$rootScope.spinner.hide();
		}
	}
})();