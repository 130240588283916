(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("renameTaskFolderController", renameTaskFolderController);

    function renameTaskFolderController($mdDialog, $translate, $http, source, userDataService, errorHandling) {
        var vm = this;
        vm.owner = source ? (source.owner ? source.owner : userDataService.user.domain) : userDataService.user.username;
        vm.isOwner = vm.owner.toLowerCase() === userDataService.user.username.toLowerCase();
        vm.name = source
            ? (source.displayName ? $translate.instant(source.displayName) : source.name)
            : "";
        vm.id = source ? (source.id || source.itemID) : null;
        vm.color = source ? source.color : "#C6DE70";

        // Functions
        vm.save = save;
        vm.cancel = cancel;

        activate();

        //////////////////

        function activate() {
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save(ev, taskFolderForm) {
            if (vm.name === "")
                return;
            if (taskFolderForm.$invalid)
                return;

            try {
                const folder = {
                    folder: vm.name,
                    uid: vm.id,
                    color: vm.color
                };

                await $http.post("~/api/v1/tasks/sources/edit", folder);
                $mdDialog.hide();
            }
            catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();
