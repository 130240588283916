(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("editDomainRestoreController", editDomainRestoreController);

    function editDomainRestoreController($scope, $http, $filter, $translate, $mdDialog, toaster, errorHandling, successHandling, options) {
        $scope.typeOptions = [
            { translate: 'ATTACH_USER', value: 0, saveButtonText: 'ATTACH' },
            { translate: 'ATTACH_FOLDER', value: 1, saveButtonText: 'ATTACH' },
            { translate: 'REBUILD_FOLDER', value: 2, saveButtonText: 'OK' },
            { translate: 'DETACH_USER', value: 3, saveButtonText: 'DETACH' },
        ];
        $scope.currentType = options.type || 0;
        $scope.recursive = true;
        $scope.emailInput = options.emailAddress || '';
        $scope.folderPath = '';
        $scope.detachUserWarning = false;

        // Functions
        $scope.restore = restore;

        activate();

        /////////////////////

        function activate() {
            let ops = $scope.typeOptions[$scope.currentType];
            $scope.saveButtonText = $translate.instant(ops.saveButtonText);
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        }

        function restore(restoreForm) {
            if (!restoreForm.$valid) {
                restoreForm.$submitted = true;
                return;
            }
            switch ($scope.currentType) {
                case 0:
                    {
                        let params = JSON.stringify({ input: $scope.emailInput.split(';') });
                        $http.post('~/api/v1/settings/sysadmin/attach-users', params)
                            .then(function (success) {
                                successHandling.report();
                                $mdDialog.hide();
                            }, errorHandling.report);
                        break;
                    }
                case 2:
                case 1:
                    {
                        let params = JSON.stringify({ restorations: [{ folder: $scope.folderPath, email: $scope.emailInput, recursive: $scope.recursive }] });
                        $http.post('~/api/v1/settings/sysadmin/restore-folders', params)
                            .then(function (success) {
                                toaster.clear();
                                successHandling.report('REBUILD_FOLDER_COMPLETE_MESSAGE');
                            }, errorHandling.report);
                        successHandling.report('REBUILD_FOLDER_RUNNING_MESSAGE');
                        $mdDialog.hide();
                        break;
                    }
                case 3:
                    {
                        let params = JSON.stringify({
                            input: $scope.emailInput.split(';')
                        });
                        $http.post('~/api/v1/settings/sysadmin/detach-users/'+ $scope.detachUserWarning, params)
                            .then(function (success) {
                                successHandling.report();
                                $mdDialog.hide();
                                //}, errorHandling.report);
                            }, function(failure){
                               if (failure.data.message === 'detach user warning') {
                                   $scope.detachUserWarning = true;
                                   $scope.saveButtonText = $translate.instant("CONTINUE");
                               } else {
                                   errorHandling.report(failure.data.message);
                                   $mdDialog.cancel();
                               }
                            });
                        break;
                    }
            }
        }
    }
})();
