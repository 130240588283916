(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataManageArchive", coreDataManageArchive);

	function coreDataManageArchive($http, $q, $filter, $rootScope, $timeout) {
	    var vm = this;
		var _isInitialized = false;
		var searchResults = [];
		var searchResultsInitialized = false;
		var searchResultsCounts = [];
		var searchResultsCountsInitialized = false;
		vm.searchField = '';
		vm.totalCount = 0;
		vm.totalFilteredCount = 0; //Count of total messages after a search.
		vm.listDataCache = {};
		vm.listController = {};
		var dataProviderQueue = [];
		vm.selectMode = false;
		vm.selectedCards = [];
		vm.lastSelectedCard = undefined;

	    // Functions
		vm.init = init;
		vm.reset = reset;
		vm.Refresh = Refresh;
		vm.listDataProvider = listDataProvider;
		vm.processDataProvider = processDataProvider;
		vm.onSearchChange = onSearchChange;
		vm.setSelectMode = setSelectMode;
		vm.selectCard = selectCard;
		vm.isCardSelected = isCardSelected;
		vm.deselectAll = coreDeselectAll;
		vm.selectAll = coreSelectAll;

		activate();

	    /////////////////////

		function activate() {
		}

		function init() {
			var defer = $q.defer();
			if (_isInitialized)
				defer.resolve();
			else {
				var promises = [];
				$q.all(promises)
				.then(function () {
					_isInitialized = true;
					defer.resolve();
				}, function (failure) {
					defer.reject(failure);
				});
			}
			return defer.promise;
		};

		function reset() {
			_isInitialized = false;
			searchResults = [];
			searchResultsInitialized = false;
			searchResultsCounts = [];
			searchResultsCountsInitialized = false;
		    //vm.currentSection = 'ALL_CONNECTIONS';
		};

		function Refresh(autoRefresh) {
		    var _this = vm;
			var params = {
			    searchID: vm.searchID,
				startIndex: 0,
				count: 0
			};

			$http.post('~/api/v1/settings/sysadmin/message-archive-results', JSON.stringify(params))
			.then(function (success) {
				_this.totalCount = success.data.searchResults.totalItems;

				searchResultsInitialized = false;
				searchResults.length = 0;
				_this.selectedCards.length = 0;
				if (_this.listController.updateDisplayList)
					_this.listController.updateDisplayList();

				if (autoRefresh && success.data.searchResults.percentProgress < 100)
					$timeout(_this.Refresh, 1000);
			}, function (failure) {
			});
		}

		function listDataProvider(obj) {
		    vm.processDataProvider(obj);
			return obj.defer.promise;
		}

		function processDataProvider(obj) {
			var dataMissing = true;
			var take = obj.take;
			var start = obj.start;
			take *= 2;

			if (vm.totalCount != 0 && (start + take) > vm.totalCount)
			    take = vm.totalCount - start;

			if ((obj.start + take) <= searchResults.length) {
				dataMissing = false;
				var subset = [];
				var j = 0;

				for (var i = obj.start; i < (obj.start + take) ; i++) {
					//Note: splicing will make a copy, I want a reference.
					subset[j++] = searchResults[i];
					if (subset[j - 1] != undefined)
						subset[j - 1].highlight = (i % 2 == 0 ? true : false);
				}
				$.each(subset, function (i, v) {
					if (v == undefined) {
						dataMissing = true;
						return false;
					}
				});
				if (!dataMissing) {
					obj.defer.resolve(subset);
				} else {
					start -= take;
					take *= 2;

					if (start < 0)
						start = 0;

					if (vm.totalCount != 0 && (start + take) > vm.totalCount) {
					    take = vm.totalCount - start;
					}
				}
			}

			if (dataMissing) {
			    var params = { searchID: vm.searchID, startIndex: start, count: take };
				var url = stSiteRoot + 'api/v1/settings/sysadmin/message-archive-results';

				loadData(url, params)
				.then(function (success) {
					var subset = [];
					var j = 0;
					for (var i = start; i < (start + take) ; i++) {
						//Note: splicing will make a copy, I want a reference.
						if (searchResults[i] == undefined) {
							break;
						}
						subset[j++] = searchResults[i];
						subset[j - 1].highlight = (i % 2 == 0 ? true : false);
					}
					obj.defer.resolve(subset);
				}, function (failure) {
					obj.defer.reject(failure);
				});
			}
		}

		function loadData(url, params) {
			var defer = $q.defer();
			var _this = vm;

			$http.post(url, JSON.stringify(params))
			.then(function (success) {
				var index = params.startIndex;
				_this.totalFilteredCount = success.data.searchResults.totalItems;

				angular.forEach(success.data.searchResults.results, function (result) {
					searchResults[index++] = result;
				});

				_this.totalCount = success.data.searchResults.totalItems;
				defer.resolve(true);
			}, function (failure) {
				defer.reject(failure);
			});

			return defer.promise;
		}

		function onSearchChange(search) {
		    vm.searchField = search;
		    vm.selectedCards.length = 0;
		    if (vm.listController.reset && vm.listController.updateDisplayList) {
				searchResults.length = 0;
				vm.listController.reset();
				vm.listController.updateDisplayList();
			}
		}

		function setSelectMode(enable) {
		    vm.selectedCards.length = 0;
		    vm.selectMode = enable;
		}

		function getIndexOf(card) {
			for (var i = 0; i < searchResults.length; ++i) {
				if (searchResults[i] && searchResults[i].messageFileName === card.messageFileName) {
					return i;
				}
			}
			return -1;
		};

		function getSelectedIndexOf(card) {
		    for (var i = 0; i < vm.selectedCards.length; ++i) {
		        if (vm.selectedCards[i].messageFileName === card.messageFileName) {
					return i;
				}
			}
			return -1;
		}

		function selectCard(selectedCard, ev) {
			document.getSelection().removeAllRanges();
			if (ev.shiftKey && vm.lastSelectedCard) {
				var selectedIndex = getSelectedIndexOf(selectedCard);
				var lastCardIndex = getIndexOf(vm.lastSelectedCard);
				var cardIndex = getIndexOf(selectedCard);
				if (lastCardIndex > -1) {
				    vm.selectedCards.length = 0;
					var toSelect = [];
					var missingMesStart = -1;
					var missingMesEnd = -1;
					if (cardIndex > lastCardIndex) {
						for (var i = lastCardIndex; i <= cardIndex; ++i) {
							if (!searchResults[i]) {
								if (i < missingMesStart || missingMesStart === -1) {
									missingMesStart = i;
								}
								if (i > missingMesEnd || missingMesEnd === -1) {
									missingMesEnd = i;
								}
							}
						}
					} else {
						for (var i = cardIndex; i <= lastCardIndex; ++i) {
							if (!searchResults[i]) {
								if (i < missingMesStart || missingMesStart === -1) {
									missingMesStart = i;
								}
								if (i > missingMesEnd || missingMesEnd === -1) {
									missingMesEnd = i;
								}
							}
						}
					}
					var missingCount = missingMesEnd - missingMesStart;
					if (missingCount > 0) {
						var d = $q.defer();
						vm.listController.spinner.show();
						vm.listDataProvider({ start: missingMesStart, take: missingCount, defer: d });
						d.promise.then(function (response) {
							if (cardIndex > lastCardIndex) {
								for (var i = lastCardIndex; i <= cardIndex; ++i) {
									toSelect.push(searchResults[i]);
								}
							} else {
								for (var i = cardIndex; i <= lastCardIndex; ++i) {
									toSelect.push(searchResults[i]);
								}
							}
							vm.selectedCards = vm.selectedCards.concat(toSelect);
							vm.listController.spinner.hide();
						}, function (response) { vm.listController.spinner.hide(); });
					} else {
						if (cardIndex > lastCardIndex) {
							for (var i = lastCardIndex; i <= cardIndex; ++i) {
								toSelect.push(searchResults[i]);
							}
						} else {
							for (var i = cardIndex; i <= lastCardIndex; ++i) {
								toSelect.push(searchResults[i]);
							}
						}
						vm.selectedCards = vm.selectedCards.concat(toSelect);
					}
				}
			} else {
			    var index = vm.selectedCards.indexOf(selectedCard);
				if (index > -1) {
				    vm.selectedCards.splice(index, 1);
				} else {
				    vm.selectedCards.push(selectedCard);
				}
				vm.lastSelectedCard = selectedCard;
			}
		}

		function isCardSelected(selectedCard) {
			var found = false;
			$.each(vm.selectedCards, function (i, v) {
				if (v.messageFileName == selectedCard.messageFileName) {
					found = true;
					return false;
				}
			});
			return found;
		};

		function coreDeselectAll() {
		    vm.selectedCards.length = 0;
		};

		function coreSelectAll() {
		    var selectedCount = vm.totalFilteredCount;
			if (selectedCount > 0) {
			    if (vm.selectedCards.length === selectedCount) {
			        vm.deselectAll();
				} else {
					var missingMesStart = -1;
					var missingMesEnd = -1;
					searchResults.length = selectedCount;
					for (var i = 0; i < searchResults.length; ++i) {
						if (searchResults[i] == undefined) {
							if (missingMesStart == -1) {
								missingMesStart = i;
							}
							missingMesEnd = i + 1;
						} else {
							var index = getSelectedIndexOf(searchResults[i]);
							if (index == -1) {
							    vm.selectedCards.push(searchResults[i]);
							}
						}
					}
					var missingCount = missingMesEnd - missingMesStart;
					if (missingCount > 0) {
						var d = $q.defer();
						vm.listController.spinner.show();
						vm.listDataProvider({ start: missingMesStart, take: missingCount, defer: d });
						d.promise.then(function (response) {
							for (var i = missingMesStart; i < missingMesStart + missingCount; i++) {
								var mes = searchResults[i];
								var index = getSelectedIndexOf(mes);
								if (index == -1) {
								    vm.selectedCards.push(mes);
								}
							}
							vm.listController.spinner.hide();
						}, function (response) { vm.listController.spinner.hide(); });
					}
				}
			}
		}
	}
})();