(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysadminAntiSpamRblsController", sysadminAntiSpamRblsController);

	function sysadminAntiSpamRblsController($rootScope, $scope, $http, $filter, $mdDialog, $compile, $translate, NgTableParams, gridCheckboxes, errorHandling,
			tablePageService, i18n) {
		var vm = this;
		var timingDict = [];
		vm.spamChecks = [];
		vm.searchText = null;
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: 'asc' },
				count: 25,
				filter: {}
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;
		vm.strings = {
			avg_time_ms: `${$translate.instant('AVG_TIME')} (${$translate.instant('MILLISECONDS_ABREV')})`
		};

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.deleteCheckedItems = deleteCheckedItems;
		vm.modifyItems = modifyItems;
		vm.searchItems = function () {
			vm.checkboxes.reset();
			vm.tableParams.filter({ $: vm.searchText });
			vm.tableParams.reload();
		}

		activate();

		//////////////////////////

		function activate() {
			loadChecks();
			//$scope.$watch(function () {
			//    return vm.checkboxes.checked;
			//}, function (value) {
			//    angular.forEach(vm.spamChecks, function (item) {
			//        vm.checkboxes.items[item.name] = value;
			//        // TODO: -------------------------------------> Take into account active page limits
			//    });
			//});
		}

		function loadChecks() {
			tablePageService.tablePageNumber = 1;
			$http.get("~/api/v1/settings/sysadmin/antispam/rbls/list")
				.then(onDataRetrieved, errorHandling.report);

			function onDataRetrieved(result) {
				angular.forEach(result.data.items, function (item) {
					item.sortEnableFiltering = "" + item.enableFiltering;
					item.sortEnableIncomingSmtpBlocking = "" + item.enableIncomingSmtpBlocking;
					item.sortEnableOutgoingSmtpBlocking = "" + item.enableOutgoingSmtpBlocking;
				});
				vm.spamChecks = $filter('filter')(result.data.items, { type: 'RBL' });
				for (let i = 0; i < vm.spamChecks.length; i++) {
					vm.spamChecks[i].weightRange = getWeightString(vm.spamChecks[i]);
				}
				vm.tableParams.settings({ dataset: vm.spamChecks });
				
				refreshTable();
				vm.initialized = true;
				//    avgResponse: (timingDict && timingDict[spamCheck.name]) ? timingDict[spamCheck.name].AverageMillisecondsLast15Minutes : "-",
				//    attentionNeeded: (timingDict && timingDict[spamCheck.name]) ? timingDict[spamCheck.name].AttentionNeeded : false,
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function signalCountChanged() {
			$rootScope.$broadcast("antispam:counts-changed");
		}

		function refresh() {
			loadChecks();
		}

		function modifyItems(ev) {
			var selectedCards = vm.checkboxes.getCheckedItems();
			if (!selectedCards || !selectedCards.length)
				return;

			if (selectedCards.length == 1) {
				var temp = $.grep(vm.spamChecks, function (card) { return card.id === selectedCards[0] });
				if (temp.length > 0)
					editItem(temp[0], ev);
				return;
			}

			$mdDialog
				.show({
					locals: {type: 'rbl'},
					controller: "modifyChecksSpamCtrl",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/settings/antispam/modals/modify-checks.dlg.html",
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					var changes = [];
					var rblsChanged = false;

					angular.forEach(selectedCards, function (card) {
						changes.push({
							id: card,
							enableFiltering: modalSuccess.filteringEnabled,
							enableIncomingSmtpBlocking: modalSuccess.inFilteringEnabled,
							enableOutgoingSmtpBlocking: modalSuccess.outFilteringEnabled
						});
					});

					var params = JSON.stringify({ itemsToUpdate: changes });
					$http.post("~/api/v1/settings/sysadmin/antispam/rbls/update", params)
						.then(function () {
							refresh();
							signalCountChanged();
						});

				}, function () { });

		};

		function newItem(ev) {
			var card = {
				lookupResultsIndicatingSpam: ["127.0.0.2"],
				weight: 10,
				maxWeight: 30,
				type: "RBL",
				new: true
			};
			$mdDialog.show({
				locals: { orig: angular.copy(card) },
				controller: "rblSpamCtrl",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/antispam/modals/rbl.dlg.html",
				targetEvent: ev
			})
				.then(onDialogOk, function () { });

			function onDialogOk() {
				refresh();
				signalCountChanged();
			}
		}

		function editItem(card, ev) {
			if (!card) return;
			showEditDialog();
			function showEditDialog() {
				$mdDialog.show({
					locals: { orig: angular.copy(card) },
					controller: "rblSpamCtrl",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/settings/antispam/modals/rbl.dlg.html",
					targetEvent: ev
				})
					.then(onDialogOk, function () { });

			}

			function onDialogOk(modalResult) {
				if (modalResult.itemsToRemove) {
					var params = JSON.stringify({ itemsToRemove: [card.id] });
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS_one'))
						.targetEvent(ev);
					$mdDialog.show(confirm).then(function () {
						$http
							.post("~/api/v1/settings/sysadmin/antispam/rbls/update", params)
							.then(function () {
								refresh();
								signalCountChanged();
							}, function () { });
						}
						, showEditDialog);

				} 
				else {
					card.name = modalResult.name;
					card.description = modalResult.description;
					card.weight = modalResult.weight;
					card.maxWeight = modalResult.maxWeight;
					card.lookupDomain = modalResult.lookupDomain;
					card.lookupResultsIndicatingSpam = modalResult.lookupResultsIndicatingSpam;
					card.requireLookupMatch = modalResult.requireLookupMatch;
					card.enableFiltering = modalResult.enableFiltering;
					card.enableIncomingSmtpBlocking = modalResult.enableIncomingSmtpBlocking;
					card.enableOutgoingSmtpBlocking = modalResult.enableOutgoingSmtpBlocking;
					refresh();
				}

			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteCheckedItems', click: deleteCheckedItems, params: $scope.dropdownEvent, translateKey: 'DELETE' },
				{ key: 'modifyItems', click: modifyItems, params: $scope.dropdownEvent, translateKey: 'MODIFY' },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteCheckedItems(event) {
			var cards = vm.checkboxes.getCheckedItems();

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: cards.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				var params = JSON.stringify({ itemsToRemove: cards });
				$http.post("~/api/v1/settings/sysadmin/antispam/rbls/update", params)
					.then(function () {
						refresh();
						signalCountChanged();
					});
			}
		}

		function getWeightString(item) {
			let minWeight = 9999999;
			let maxWeight = -9999999;
			for (let i = 0; i < item.spamResultIndicators.length; i++) {
				if (item.spamResultIndicators[i].weight < minWeight)
					minWeight = item.spamResultIndicators[i].weight;
				if (item.spamResultIndicators[i].weight > maxWeight)
					maxWeight = item.spamResultIndicators[i].weight;
			}

			item.weightSort = minWeight;

			if (minWeight === maxWeight)
				return minWeight;

			return $translate.instant("INTEGER_RANGE", { start: minWeight, end: maxWeight });
		}

		//function updateTimings(time) {
		//    timer = $timeout(function () {
		//        if ($location.url() === "/sysadmin/security/antispam/spam_checks") {
		//            $http.get("~/api/v1/settings/sysadmin/spam-ip4r-timings")
		//                .then(function (success) {
		//                    angular.forEach(success.data.timingData, function (d) {
		//                        timingDict[d.SpamCheckName] = d;
		//                    });
		//                    angular.forEach($scope.cardService.cards, function (c) {
		//                        if (c.type === "rbl" || c.type === "uribl") {
		//                            c.avgResponse = timingDict[c.name] ? timingDict[c.name].AverageMillisecondsLast15Minutes : "-";
		//                            c.attentionNeeded = timingDict[c.name] ? timingDict[c.name].AttentionNeeded : false;
		//                        }
		//                    });
		//                    updateTimings();
		//                }, function (failure) {
		//                    updateTimings(30000);
		//                });
		//        }
		//    }, parseInt(time, 10) || 5000);
		//}

	}

})();
