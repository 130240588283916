(function () {

    angular
        .module("smartertools")
        .filter('safeNumber', safeNumberFilter);

    function safeNumberFilter($filter) {
        return function (number, fractionSize) {
            if (/^[\d\.]+$/.test(number))
                return $filter('number')(number, fractionSize);
            else
                return number;
        };
    }

})();