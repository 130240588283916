(function () {
	'use strict';

	angular
		.module('smartermail')
		.factory('simpleRtcConnectionFactory', simpleRtcConnectionFactory);

	function simpleRtcConnectionFactory($rootScope) {
		return {
			createConnection: async function (sendRtcNegotiate, streamModifiedCallback, initiateNegotiation, iceConfigData, stateChangeCallback, audioOnly) {
				const configData = {
					iceServers: [{urls: "stun:stun.l.google.com:19302"}]
				};
				
				const peer = {
					sendMessage: sendRtcNegotiate,
					isCaller: initiateNegotiation,
					isResponder: !initiateNegotiation,
					iceCandidates: [],
					videoStream: null,
					audioStream: null,
					rtcConn: new RTCPeerConnection(iceConfigData || configData),
					videoEnabled: !audioOnly,
					isMuted: false,
				};
				// Create a Data Channel
				peer.rtcDataChannel = peer.rtcConn.createDataChannel("both", {negotiated: true, id: 0});
				peer.rtcDataChannel.onmessage = (e) => {
					const data = JSON.parse(e.data);
					if (data.state) {
						peer.videoEnabled = data.state.videoEnabled;
						peer.isMuted = data.state.isMuted;
						if (typeof stateChangeCallback === "function") 
							stateChangeCallback(data.state);
					}
				};
				
				peer.sendStateChange = function (videoEnabled, isMuted) {
					if (peer.rtcDataChannel.readyState === "open") {
						peer.rtcDataChannel.send(JSON.stringify({state: {videoEnabled, isMuted}}));
					}
				};
				peer.rtcConn.makingOffer = false;
				peer.rtcConn.ignoreOffer = false;

				peer.streamRequestSuccession = 0;
				peer.processRtcNegotiateMessage = async (data) => {
					async function sendBufferedCandidates() {
						while (peer.iceCandidates.length > 0) {
							const candidate = peer.iceCandidates.shift();
							//peer].log("Sending buffered ICE candidate", candidate);
							try {
								await peer.sendMessage({ type: "ice-candidate", candidate });
							} catch (e) {
								console.error("Error sending buffered ICE candidate", e, candidate);
							}
						}
					}
					async function handleOfferMessage(message) {
						// Only proceed if the state is stable or we're in an appropriate state for an incoming offer
						if (peer.rtcConn.signalingState !== "stable") {
							if (peer.isResponder) {
								try {
									await peer.rtcConn.setLocalDescription({ type: "rollback" });
								} catch (e) {
									console.error("[handleOfferMessage]", "Rollback failed:", e);
								}
							} 
						}

						try {
							// Set the remote offer
							await peer.rtcConn.setRemoteDescription(message);

							// Create an answer only if we successfully set the remote offer
							if (peer.rtcConn.signalingState === "have-remote-offer") {
								const answer = await peer.rtcConn.createAnswer();

								await peer.rtcConn.setLocalDescription(answer);
								await peer.sendMessage(peer.rtcConn.localDescription);
								await sendBufferedCandidates();

							} 
						} catch (err) {
							console.log("[handleOfferMessage]", "Failed to handle offer:", err);
						}
					}

					async function handleAnswerMessage(message) {
						// Ensure the signaling state is appropriate for setting a remote answer
						if (peer.rtcConn.signalingState === "have-local-offer") {
							try {
								await peer.rtcConn.setRemoteDescription(message);
								await sendBufferedCandidates();
							} catch (err) {
								console.log("[handleAnswerMessage]", "Failed to set remote answer:", err);
							}
						} else {
							console.warn("[handleAnswerMessage]", "Attempted to set remote answer in wrong state:", peer.rtcConn.signalingState);
						}
					}


					try {
						//peer.log("[processRtcNegotiateMessage]", "DESC for ", message.type, " from ", peerId, data);
						if (peer.rtcConn.signalingState === "closed") 
							return;
						
						switch (data.type) {
							case "offer":
								await handleOfferMessage(data);
								break;
							case "answer":
								await handleAnswerMessage(data);

								break;
							case "ice-candidate":
								if (data.candidate) {
									peer.iceCandidates.push({data: data.candidate});
									try {
										await peer.rtcConn.addIceCandidate(data.candidate);
									} catch (e) {
										console.error("[onRtcNegotiate]", "AddIceCandidate exception.", e, data.candidate);
									}
								}
								break;
						}
					} catch (err) {
						console.error(err);
					}
				}
				$rootScope.$on("signalR.chat.chatVideoRtcNegotiate", (e, data) => peer.processRtcNegotiateMessage(data));
				peer.initiateNegotiation = async () => {
					try {
						peer.rtcConn.makingOffer = true;
						const offer = await peer.rtcConn.createOffer();
						await peer.rtcConn.setLocalDescription(offer);
						peer.sendMessage(offer);
					} catch (err) {
						console.error("[RtcFactory] initiateNegotiation failed:", err);
					} finally {
						peer.rtcConn.makingOffer = false;
					}
				};

				peer.rtcConn.onicecandidate = (event) => {
					if (event.candidate) {
						peer.sendMessage({
							type: "ice-candidate",
							candidate: event.candidate
						});
					}
				};

				peer.rtcConn.onconnectionstatechange = () => {
					const state = peer.rtcConn.connectionState;
					console.log("[RtcFactory] RTC connection state change:", state);

					// Emit state change to AngularJS scope
					$rootScope.$broadcast("chatVideoStateChangeDetected", peer.id, state);
				};

				peer.rtcConn.onnegotiationneeded = async () => {
					if (
						peer.rtcConn.connectionState !== "connected" ||
						peer.rtcConn.makingOffer ||
						peer.rtcConn.signalingState !== "stable"
					) return;

					try {
						peer.rtcConn.makingOffer = true;
						const offer = await peer.rtcConn.createOffer();
						await peer.rtcConn.setLocalDescription(offer);
						peer.sendMessage(offer);
					} catch (err) {
						console.error("[RtcFactory] Negotiation failed:", err);
					} finally {
						peer.rtcConn.makingOffer = false;
					}
				};

				peer.rtcConn.ontrack = (event) => {
					const stream = event.streams[0];
					
					const kind = event.track.kind;
					if (typeof streamModifiedCallback === "function") 
						streamModifiedCallback(kind, stream);
					if (kind === "audio"){
						peer.audioStream = stream;
					} else if (kind === "video"){
						peer.videoStream = stream;
					}
				};

				return peer;
			}
		};
	}
})();