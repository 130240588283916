(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("failedToLoadDialogController", failedToLoadDialogController);

	function failedToLoadDialogController($scope, $mdDialog, $http, errorHandling, domainName, domainPath) {

		$scope.domainName = domainName;
		$scope.domainPath = domainPath;

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.removeFromList = function() {
			$http
				.post("~/api/v1/settings/sysadmin/remove-domain-from-list/" + $scope.domainName)
				.then(function () { $mdDialog.hide(true); }, errorHandling.report);
		};
	}

})();
