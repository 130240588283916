(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsForwardingBlacklistController", adminSettingsForwardingBlacklistController);

	function adminSettingsForwardingBlacklistController($rootScope, $scope, $mdDialog, $filter, $http, $compile, NgTableParams, gridCheckboxes, errorHandling, i18n) {
		var vm = this;
		vm.blacklist = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { emailAddress: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.newItem = newItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
			loadBlacklist();
		}

		function loadBlacklist() {
			$http
				.get("~/api/v1/settings/sysadmin/forward-blacklist")
				.then(function (result) {
					vm.blacklist = result.data.forwardBlacklists || [];
					vm.tableParams.settings({ dataset: vm.blacklist });
					
					refreshTable();
					vm.initialized = true;
				}, errorHandling.report);
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function newItem(ev) {
			return $mdDialog.show({
				controller: "systemNewForwardingBlacklistModalController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/delivery-limits/forwarding-blacklist-new.dlg.html",
				targetEvent: ev
			}).then(function () {
				loadBlacklist();
				$rootScope.$broadcast("security:countersChanged");
			}, function () { });
		}

		function editItem(card, ev) {
			openEditModal({ domainName: card }, ev);
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var cards = vm.checkboxes.getCheckedItems();
			deleteCards(cards);
		}
		function deleteCards(cards) { 
			if (!cards)
				return;
			if (!angular.isArray(cards))
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: cards.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				var params = JSON.stringify({
					toRemove: cards
				});
				$http
					.post("~/api/v1/settings/sysadmin/forward-blacklist", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDeleteSuccess() {
				loadBlacklist();
				$rootScope.$broadcast("security:countersChanged");
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function openEditModal(info, ev) {
			return $mdDialog.show({
				locals: { domains: vm.blacklist, info: info },
				controller: "systemForwardingBlacklistModalController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/delivery-limits/forwarding-blacklist-edit.dlg.html",
				targetEvent: ev
			}).then(function (action) {
				if (action.delete) {
					deleteCards([info.domainName]);
				} else {
					loadBlacklist();
					$rootScope.$broadcast("security:countersChanged");
				}
				}, function () { });
		}
	}

})();
