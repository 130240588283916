(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSpoolThrottledDomainsController", adminSpoolThrottledDomainsController);

	function adminSpoolThrottledDomainsController($scope, $filter, $mdDialog, successHandling, $rootScope, $http, $localStorage, $translate, $compile,
		errorHandling, coreDataSpool, coreDataSysAdminSettings, coreData, NgTableParams, gridCheckboxes) {
		var vm = this;
		$scope.spoolCore = coreDataSpool;
		$scope.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/throttled-domains";
		vm.searchParams = { startIndex: 0, count: 0, sortType: null, search: '', ascending: false };
		vm.selectedSection = $localStorage.connectionsSection ? $localStorage.connectionsSection : 'SPOOL_SECTIONS_TDOMAINS';
		vm.tableParams = new NgTableParams(
			{
				sorting: { domain: 'asc' },
				count: 100
			}, {
				getData: queryData,
				localStorageKey: "ngTableRows-Spool"
			});

		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.throttleReasons = { '0': 'SPOOL_THROTTLE_REASONS_MESSAGES', '1': 'BANDWIDTH', '2': 'SPOOL_THROTTLE_REASONS_BOUNCES' };

		// Functions
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.Reset = Reset;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyToClipboard = copyToClipboard;

		/////////////////////

		function Refresh() {
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function () { }, function () { })
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
			$scope.$emit('reloadSpool');
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function Reset() {
			$rootScope.spinner.show();
			var toReset = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toReset = toReset.concat($.grep(vm.searchResults, function (r) { return r.id === key }));
				}
			}
			toReset = _.map(toReset, function (x) { return x.domain; });

			if (toReset.length == 1) {
				doReset();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: toReset.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(doReset, function () {});
			}

			function doReset() {
				$http.post('~/api/v1/settings/sysadmin/throttled-domain-reset', { input: toReset })
					.then(function () {
						$scope.$emit('reloadSpool');
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}
		}

		function queryData(params) {
			vm.searchParams.startIndex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();
			vm.searchParams.sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = k;
				vm.searchParams.ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.get(vm.queryUrl)
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.domains;
				vm.searchResults = $filter('filter')(vm.searchResults, { domain: vm.searchParams.search });
				vm.searchResultCount = vm.searchResults.length;
				params.total(vm.searchResultCount);
				vm.searchResults = $filter('orderBy')(vm.searchResults, vm.searchParams.sortType, !vm.searchParams.ascending);
				vm.searchResults = vm.searchResults.splice(vm.searchParams.startIndex, vm.searchParams.count);

				if (vm.searchResults.length === 0 && params.page() > 1) { params.page(params.page() - 1); vm.tableParams.reload(); return; }
				
				return vm.searchResults;
			}
		}

		window.refreshSpool = function () {
			vm.checkboxes.reset();
			vm.tableParams.reload();
			$scope.$emit('reloadSpool');
		};

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'Reset', click: Reset, translateKey: 'SPOOL_REMOVE_THROTTLE' },
				{ divider: true },
				{ key: 'copyDomain', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.domain }, translateKey: "COPY_DOMAIN" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
