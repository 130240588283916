(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarAvailOnlyDialogController", calendarAvailOnlyDialogController);

    function calendarAvailOnlyDialogController($scope, $mdDialog, titleText, bodyText) {
        $scope.titleText = titleText;
        $scope.bodyText = bodyText;

        $scope.done = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    }

})();
