(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminConfigurationModuleController", adminConfigurationModuleController);

    function adminConfigurationModuleController(
        $scope,
        $rootScope,
        $http,
        $mdDialog,
        $q,
        $compile,
        $filter,
        $timeout,
        NgTableParams,
        gridCheckboxes,
        themesService,
        $translate,
        errorHandling,
        successHandling,
        userDataService,
        coreLicensing,
        coreDataDomainSettings,
        localAddonName,
        localAddonId,
        localEnabled,
        localAlreadyConfigured,
        localDomainAdminCanOverride,
        localHelpLink
    ) {
        let vm = this;
        vm.helpLink = localHelpLink;
        vm.addonName = localAddonName;
        vm.addonId = localAddonId;
        vm.emailSent = false;
        vm.alreadyConfigured = localAlreadyConfigured;
        vm.domainAdminCanOverride = localDomainAdminCanOverride;
        vm.localEnabled = localEnabled;
        vm.addonEnabled = vm.localEnabled || false;
        vm.name = $translate.instant(vm.addonId.toUpperCase());
        vm.countrySearch = "";
        vm.countries = [];
        vm.filterCountrySearch = filterCountrySearch;
        vm.verifyCode = verifyCode;
        vm.notEligible = false;

        function verifyCode(ev, form) {
            let code = vm.activeSections[0].settings[0].value;
            $http
                .post('~/api/v1/settings/domain/emclient/verify-code', JSON.stringify({
                    authCode: code.toString().padStart(6, '0'),
                    fullName: "" // Not used in verification
                }))
                .then(function (success) {
                    vm.verificationState = success.data.state;
                }, errorHandling.report);
        }


        // --- Utility to filter country search ---
        function filterCountrySearch(item) {
            return item.translation.toLowerCase().startsWith(vm.countrySearch.toLowerCase()) ||
                item.key.toLowerCase().startsWith(vm.countrySearch.toLowerCase());
        }

        vm.activeSections = [];

        function initializeSettings(name) {
            name = name.toString();
            name = name.toLowerCase();
            switch (name) {
                case "emclient":
                    // Load country codes so that the country selector is populated.
                    loadCountryCodes();
                    // Two pages for EmClient:
                    // Page 1: Request/verify the code.
                    // Page 2: Registration with Organization Name, Full Name, Owner Email, Confirm Email, and Country.
                    vm.activeSections = [
                        {
                            title: $translate.instant("EMCLIENT_PAGE_1"),
                            settings: [
                                {
                                    key: "code",
                                    type: "input",
                                    label: $translate.instant("CODE"),
                                    value: vm.verificationCode || ""
                                }
                            ]
                        },
                        {
                            title: $translate.instant("EMCLIENT_PAGE_2"),
                            settings: [
                                {
                                    key: "organizationName",
                                    type: "input",
                                    label: $translate.instant("ORGANIZATION_NAME"),
                                    value: ""
                                },
                                {
                                    key: "fullName",
                                    type: "input",
                                    label: $translate.instant("FULL_NAME"),
                                    value: ""
                                },
                                {
                                    key: "ownerEmail",
                                    type: "input",
                                    label: $translate.instant("OWNER_EMAIL"),
                                    value: ""
                                },
                                {
                                    key: "confirmEmail",
                                    type: "input",
                                    label: $translate.instant("CONFIRM_OWNER_EMAIL"),
                                    value: ""
                                },
                                {
                                    key: "countryCode",
                                    type: "select",
                                    label: $translate.instant("COUNTRY"),
                                    value: ""
                                }
                            ]
                        }
                    ];
                    break;
                case "collabora":
                    vm.activeSections = [
                        {
                            title: $translate.instant("COLLABORA"),
                            settings: [
                                {
                                    key: "serverUrl",
                                    type: "url",
                                    label: $translate.instant("SERVER_ADDRESS"),
                                    value: ""
                                },
                                {
                                    key: "maxConnections",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_ZERO_UNLIMITED"),
                                    value: 0
                                },
                                {
                                    key: "maxConnectionsPerUser",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    fetchSettings();
                    break;
                case "onlyoffice":
                    vm.activeSections = [
                        {
                            title: $translate.instant("ONLYOFFICE"),
                            settings: [
                                {
                                    key: "serverUrl",
                                    type: "url",
                                    label: $translate.instant("SERVER_ADDRESS"),
                                    value: ""
                                },
                                {
                                    key: "maxConnections",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_ZERO_UNLIMITED"),
                                    value: 0
                                },
                                {
                                    key: "maxConnectionsPerUser",
                                    type: "input",
                                    label: $translate.instant("MAX_CONNECTIONS_PER_USER_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    fetchSettings();
                    break;
                case "chatgpt":
                    vm.activeSections = [
                        {
                            title: $translate.instant("CHATGPT"),
                            settings: [
                                {
                                    key: "usersCanOverrideApiKey",
                                    type: "switch",
                                    label: $translate.instant("AI_ALLOW_USERS_TO_OVERRIDE"),
                                    value: ""
                                },
                                {
                                    key: "aiModel",
                                    type: "modelSelect",
                                    label: $translate.instant("AI_MODEL"),
                                    value: 0,
                                    options: [
                                        {
                                            key: 0,
                                            translation: $translate.instant("GPT-4O-MINI")
                                        },
                                        {
                                            key: 1,
                                            translation: $translate.instant("GPT-4O")
                                        },
                                        {
                                            key: 3,
                                            translation: $translate.instant("O3-MINI")
                                        },
                                        {
                                            key: 3,
                                            translation: $translate.instant("O1-MINI")
                                        }
                                    ]
                                },
                                {
                                    key: "apiKey",
                                    type: "password",
                                    label: $translate.instant("AI_OPENAI_KEY"),
                                    value: ""
                                },
                                {
                                    key: "maxMessagesPerDay",
                                    type: "input",
                                    label: $translate.instant("MAX_REQUESTS_PER_DAY_ZERO_UNLIMITED"),
                                    value: 0
                                }
                            ]
                        }
                    ];
                    const modelSetting = vm.activeSections[0].settings.find(s => s.key === "aiModel");
                    vm.models = modelSetting.options; // Copy the options to settingsCtrl.models
                    fetchSettings();
                    break;
                default:
                    vm.activeSections = [
                        {
                            title: "",
                            settings: []
                        }
                    ];
                    break;
            }
        }

        function fetchSettings() {
            var apiUrl;
            switch (vm.addonName.toLowerCase()) {
                case "collabora":
                    apiUrl = "/api/v1/marketplace/GetCollaboraOnlineSettings";
                    break;
                case "onlyoffice":
                    apiUrl = "/api/v1/marketplace/GetOnlyOfficeSettings";
                    break;
                case "chatgpt":
                    apiUrl = "/api/v1/marketplace/GetChatGPTSettingsAdmin";
                    break;
                default:
                    console.warn("No settings endpoint defined for addon: " + vm.addonName);
                    return;
            }
            $http.post(apiUrl, {})
                .then(function (response) {
                    var settings = response.data;
                    vm.addonEnabled = settings.enabled || false;
                    populateSettings(settings);
                })
                .catch(function (error) {
                    console.error("Failed to fetch settings for addon: " + vm.addonName, error);
                    vm.errorMessage = $translate.instant("FETCH_SETTINGS_FAILED");
                });
        }

        function populateSettings(settings) {
            vm.activeSections.forEach(function (section) {
                section.settings.forEach(function (setting) {
                    if (settings.hasOwnProperty(setting.key)) {
                        setting.value = settings[setting.key] !== undefined ? settings[setting.key] : "";
                    }
                });
            });
        }

        function loadCountryCodes() {
            $http.get("~/api/v1/settings/domain/country-codes")
                .then(onCountryCodesLoaded, errorHandling.report);

            function onCountryCodesLoaded(result) {
                vm.countries = [];
                angular.forEach(result.data.results, function (key) {
                    vm.countries.push({key: key, translation: $filter("translate")("COUNTRY_CODE_" + key)});
                });
            }
        }

        vm.cancel = function () {
            $mdDialog.hide();
        };
        vm.saved = function () {
            $mdDialog.cancel();
        };

        vm.success = function () {
            $mdDialog.hide();
        };

        vm.save = function () {
            var updatedSettings = {};
            if (vm.addonName.toLowerCase() === "chatgpt") {
                updatedSettings = {enabled: vm.addonEnabled};

                vm.activeSections.forEach(function (section) {
                    section.settings.forEach(function (setting) {
                        if (setting.key === "maxMessagesPerDay") {
                            updatedSettings[setting.key] = Number(setting.value) || 0;

                        } else {
                            updatedSettings[setting.key] = setting.value;
                        }
                    });
                });

                $http.post("/api/v1/marketplace/SetChatGPTSettingsAdmin", updatedSettings)
                    .then(
                        function (response) {
                            $mdDialog.hide(response.data);
                        },
                        function (error) {
                            errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                        }
                    );
            }

            if (vm.addonName.toLowerCase() === "onlyoffice") {
                updatedSettings = {enabled: vm.addonEnabled};
                vm.activeSections.forEach(function (section) {
                    section.settings.forEach(function (setting) {
                        updatedSettings[setting.key] = setting.value;
                    });
                });
                $http.post("/api/v1/marketplace/setOnlyOfficeSettings", updatedSettings)
                    .then(function (response) {
                        $mdDialog.hide(response.data);
                    }, function (error) {
                        errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                    })
                    .catch(function (error) {
                        errorHandling.handleError(error);
                    });
            } else if (vm.addonName.toLowerCase() === "collabora") {
                updatedSettings = {enabled: vm.addonEnabled};
                vm.activeSections.forEach(function (section) {
                    section.settings.forEach(function (setting) {
                        updatedSettings[setting.key] = setting.value;
                    });
                });
                $http.post("/api/v1/marketplace/setCollaboraSettings", updatedSettings)
                    .then(function (response) {
                        $mdDialog.hide(response.data);
                    }, function (error) {
                        errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                    })
                    .catch(function (error) {
                        errorHandling.handleError(error);
                    });
            } else if (vm.addonName.toLowerCase() === "emclient" || vm.addonName.toLowerCase() === "chatgpt") {
                // EmClient saving is handled via the verification/registration functions below.
            } else {
                vm.toggleCloudProvider(vm.addonId, vm.addonEnabled);
            }
        };

        vm.toggleCloudProvider = function (providerKey, enable) {
            var request = {
                ProviderKey: providerKey,
                Enable: enable
            };
            $http.post("/api/v1/marketplace/ToggleCloudStorageProvider", request)
                .then(function (response) {
                    vm.saved();
                }, function (error) {
                    errorHandling.report("FAILED_TO_SAVE_SETTINGS");
                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        vm.handleStorageSuccess = function (result) {
            console.log("Storage configuration updated successfully:", result);
        };

        // --- EmClient-Specific Functions ---
        if (vm.addonName.toLowerCase() === "emclient") {
            vm.verificationCode = "";
            vm.organizationName = "";
            vm.fullName = "";
            vm.ownerEmail = "";
            vm.confirmEmail = "";
            vm.countryCode = "";
            vm.iAgree = false;
            vm.disableSendCode = false;
            vm.serverRejectedReason = "";
            vm.requestInfo = {};

            function checkVerificationStatus() {
                return $http
                    .get('~/api/v1/settings/domain/emclient/verification-status')
                    .then(function (success) {
                        vm.verificationState = success.data.state;
                        if (vm.verificationState === 0 && sessionStorage["emClientVerificationRequested"]) {
                            vm.verificationState = 1;
                        }
                        return vm.verificationState;
                    }, function (fail) {
                        errorHandling.report(fail.data.message);
                        if (fail.data.message === "EMCLIENT_OFFER_NOT_AVAILABLE") {
                            vm.notEligible = true;
                        }
                    })
                    .finally(function () {
                        vm.active = true;
                        if ($rootScope.spinner.isShown) {
                            $timeout($rootScope.spinner.hide);
                        }
                    });
            }

            // Initialize verification state from the server.
            checkVerificationStatus();

            // Request a verification code.
            vm.submitRequestOffer = function (ev) {
                $http
                    .post('~/api/v1/settings/domain/emclient/request-offer')
                    .then(function (success) {
                        vm.emailSent = true;  // Add this line to set the flag
                        vm.disableSendCode = true;
                        $timeout(function () {
                            vm.disableSendCode = false;
                        }, 10 * 1000);
                        sessionStorage.emClientVerificationRequested = true;
                        vm.verificationState = success.data.state;
                        if (vm.verificationState === 3) {
                            vm.emClientServiceError = success.data.message;
                        } else {
                            successHandling.report($filter("translate")("EMCLIENT_DOMAIN_VERIFICATION_EMAIL", {email: userDataService.user.emailAddress}));
                        }
                    }, function (fail) {
                        if (fail.data && fail.data.errorCode) {
                            vm.verificationState = 2;
                            vm.serverRejectedReason = fail.data.message;
                        } else {
                            errorHandling.report(fail.message);
                        }
                    });
            };


            // Register EmClient with full registration details.
            vm.registerEmClient = function (ev, form) {
                if (vm.verificationState !== 3 || form.$invalid) {
                    return;
                }
                $http
                    .post('/api/v1/settings/domain/emclient/request-license', JSON.stringify({
                        organizationName: vm.organizationName,
                        fullName: vm.fullName,
                        ownerEmail: vm.ownerEmail,
                        confirmEmail: vm.confirmEmail,
                        countryCode: vm.countryCode
                    }))
                    .then(function (success) {
                        vm.verificationState = success.data.state;
                        if (vm.verificationState === 0) {
                            vm.requestInfo = {};
                            delete sessionStorage["emClientRequestInfo"];
                        }
                        if (vm.verificationState === 4) {
                            successHandling.report($filter("translate")("EMCLIENT_ACTIVATION_CONFIRMATION_MESSAGE"));
                            vm.licenseInfo = success.data;
                        }
                    }, function (error) {
                        errorHandling.report(error.data.message);
                        if (error.data.message === "EMCLIENT_ERROR_INVALID_VERIFICATION_API_KEY") {
                            vm.verificationState = 0;
                        }
                    });
            };
        }

        initializeSettings(vm.addonName);
    }
})();