(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("gridCheckboxes", gridCheckboxes);

	function gridCheckboxes($timeout, ngTableEventsChannel) {
		var vm = this;
		var lastClickedKey;

		vm.checked = false;
		vm.items = {};
		vm.key = "";
		vm.pageResults = {};
		vm.table = undefined;
		vm.specialKey = undefined;
		vm.ignoreItemDelegate = undefined;
		vm.selectedCount = 0;

		vm.checkAll = checkAll;
		vm.checkAllChecked = checkAllChecked;
		vm.checkboxChanged = checkboxChanged;
		vm.checkboxClicked = checkboxClicked;
		vm.checkCheckbox = checkCheckbox;

		vm.init = function (key) {
			vm.reset();
			vm.table = undefined;
			vm.pageResults = {};
			vm.key = key;
			vm.specialKey = undefined;
			vm.ignoreItemDelegate = undefined;
			vm.checkAll = checkAll;
			vm.checkboxChanged = checkboxChanged;
			lastClickedKey = undefined;
			vm.selectedCount = 0;
			return vm;
		};

		vm.reset = function () {
			vm.items = {};
			vm.checked = false;
			vm.selectedCount = 0;
		};

		vm.getCheckedItems = function () {
			var retVal = [];
			for (var k in vm.items) {
				if (vm.items.hasOwnProperty(k) && vm.items[k])
					retVal.push(k);
			}
			return retVal;
		};

		//vm.selectedCount = function () { return vm.getCheckedItems().length; };

		function checkAll() {
			var data = vm.table.data;

			for (var i = 0; i < data.length; i++) {
				if (vm.ignoreItemDelegate && vm.ignoreItemDelegate(data[i])) continue;
				var checkKey = getKey(data[i]);
				setKey(vm.checked, checkKey);
			}
		};


		function checkAllChecked() {
			vm.checked = false;
			if (!vm.pageResults || vm.pageResults.length === 0) return;

			var data = vm.table.data;
            if (!data)
				return false;

			for (var i = 0; i < data.length; i++) {
				if (vm.ignoreItemDelegate && vm.ignoreItemDelegate(data[i])) continue;
				var checkKey = getKey(data[i]);
				if (!vm.items.hasOwnProperty(checkKey) || !vm.items[checkKey]) return;
			}
			vm.checked = true;
		};

		function checkboxChanged(key) {
			if (!vm.items[key]) {
				vm.checked = false;
				delete vm.items[key];
			}
			else checkAllChecked();
		};

		function checkboxClicked(event, key) {
			document.getSelection().removeAllRanges();
			var setChecked = !vm.items[key];

			if (setChecked)++vm.selectedCount;
			else --vm.selectedCount;

			// If a shift select is occurring
			if (lastClickedKey && key != lastClickedKey && event.shiftKey) {
				var data = vm.table.data;

				// Make sure the last clicked is on this page
				var lastClickedIsOnPage = false;
				for (var i = 0; i < data.length; i++) {
					var checkKey = getKey(data[i]);
					if (checkKey == lastClickedKey) lastClickedIsOnPage = true;
				}
				if (!lastClickedIsOnPage) { lastClickedKey = key; return; };
				var checking = false;
				for (var i = 0; i < data.length; i++) {
					var checkKey = getKey(data[i]);
					if (checking) {
						if (checkKey !== key) setKey(setChecked, checkKey);
						// If we reached the end of the shift selection
						if (checkKey == lastClickedKey || checkKey == key) break;
					} else if (checkKey == lastClickedKey || checkKey == key) { // We reached the beginning of the shift selection
						if (checkKey !== key) setKey(setChecked, checkKey);
						checking = true;
					}
				}
			}
			else {
			}

			lastClickedKey = key;
		}

		function checkCheckbox(event, item) {
			if (vm.ignoreItemDelegate && vm.ignoreItemDelegate(item)) return;
			var key = getKey(item);
			var selectedCount = vm.selectedCount;
			checkboxClicked(event, key);
			vm.selectedCount = selectedCount;
			setKey(!vm.items[key], key)
			checkboxChanged(key);
		}

		function setKey(checked, key) {
			if (checked) {
				if (!vm.items[key])
					++vm.selectedCount;
				vm.items[key] = checked;
			}
			else {
				if (vm.items[key] !== undefined)
					--vm.selectedCount;
				delete vm.items[key];
			}
		}

		function getKey(item) {
			return vm.key ? item[vm.key] : (vm.specialKey ? vm.specialKey(item) : item)
		}

		// If we land on a page with no results, send the user back to page 1
		ngTableEventsChannel.onAfterReloadData(function () {
			if (vm.table && vm.table.data && vm.table.data.length == 0 && vm.table._params.page > 1) {
				var correctPage = Math.floor(vm.table.total() / vm.table._params.count);
				vm.table._params.page = correctPage;
				$timeout(function () { confirmPageCorrection(correctPage); });
			}

		});

		function confirmPageCorrection (pageNum) {
			if (vm.table._params.page != pageNum)
				vm.table._params.page = pageNum;
		}
	}
})();