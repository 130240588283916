(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("editSharedFolderDialogController", editSharedFolderDialogController);

    function editSharedFolderDialogController($rootScope, $mdDialog, $http, $filter,
        errorHandling, successHandling, errorMessageService, folder, folderId, webcalLink, coreDataMail) {

        var vm = this;
        var FOLDERTYPE_NOTES = 5;
        var FOLDERTYPE_CONTACTS = 1;
        var FOLDERTYPE_APPOINTMENTS = 2;
        var FOLDERTYPE_TASKS = 4;

        vm.folder = folder;
        vm.folderId = folderId;
        vm.ready = false;
        vm.webcalLink = webcalLink;

        // functions
        vm.cancel = cancel;
        vm.delete = deleteFolder;
        vm.stopSharing = stopSharing;
        vm.save = save;
        vm.copyWebcalLinkToClipboard = copyWebcalLinkToClipboard;
        vm.disableAttach = disableAttach;

        // Startup
        activate();

        ///////////////////////////////////////////////////

        function activate() {
            if (!vm.folder && !vm.folderId) {
                cancel();
            }

            if (vm.folder) {
                vm.folderId = vm.folder.folderId;
                loadFolder();
            }
            else {
                $http.get("~/api/v1/folders/get-virtual-folder/" + vm.folderId)
                    .then(function (success) {
                        if (!success.data.success) {
                            cancel();
                            return;
                        }
                        vm.folder = success.data.virtualFolder;
                        loadFolder();
                    }, errorHandling.report);
            }

            function loadFolder() {
                vm.ready = true;

                if (vm.folder.folderType === FOLDERTYPE_APPOINTMENTS)
                    vm.folder.folderName = $filter('folderTranslate')(vm.folder.translatableName) || $filter('folderTranslate')(vm.folder.folderName);
                else
                    vm.folder.folderName = $filter('folderTranslate')(vm.folder.translatableName) || vm.folder.displayPath || $filter('folderTranslate')(vm.folder.folderName);


                vm.parentFolder = "";
                if (vm.folder.parentFolderId) {
                    let pathArray = vm.folder.folderPath.split('/');
                    pathArray.pop();
                    let parentPath = pathArray.join('/');
                    vm.parentFolder = parentPath;
                }

                vm.originalFolderName = vm.folder.folderName;
                vm.folder.webcalLink = vm.webcalLink;
                onFolderLoaded();
            }
        }

        function onFolderLoaded() {
            vm.host = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

            switch (vm.folder.folderType) {
                case FOLDERTYPE_NOTES: vm.typeName = "notes"; break;
                case FOLDERTYPE_CONTACTS: vm.typeName = "contacts"; break;
                case FOLDERTYPE_APPOINTMENTS: vm.typeName = "appointments"; break;
                case FOLDERTYPE_TASKS: vm.typeName = "tasks"; break;
                default: vm.typeName = "email"; break;
            }

            vm.ownerFullName = "";
            if (vm.folder.ownerUserName && vm.folder.ownerDisplayName && vm.folder.ownerUserName != vm.folder.ownerDisplayName)
                vm.ownerFullName = vm.folder.ownerDisplayName + " (" + vm.folder.ownerUserName + ")";
            else if (vm.folder.ownerUserName && !(vm.folder.ownerDisplayName && vm.folder.ownerUserName != vm.folder.ownerDisplayName))
                vm.ownerFullName = vm.folder.ownerUserName;
            else
                vm.ownerFullName = vm.folder.ownerDomain;

            vm.canDelete = !vm.folder.isDomainResource && !vm.folder.isPrimary && !vm.folder.isSpecialFolder && (!vm.folder.isMapped && vm.folder.access >= 10);

            vm.accessAsString = "";
            switch (vm.folder.access) {
                case 2: vm.accessAsString = translate("MAPPED_RESOURCES_ACCESS_2"); break;
                case 4: vm.accessAsString = translate("MAPPED_RESOURCES_ACCESS_4"); break;
                case 8: vm.accessAsString = translate("MAPPED_RESOURCES_ACCESS_8"); break;
                case 10: vm.accessAsString = translate("MAPPED_RESOURCES_ACCESS_10"); break;
                default: vm.accessAsString = translate("MAPPED_RESOURCES_ACCESS_0"); break;
            }

            if (vm.folder.includeSubfolders && vm.folder.folderType == 3) {
                vm.accessAsString += " (" + translate("SHARE_SUBFOLDERS_MESSAGE") + ")";
            }
        }

        function copyWebcalLinkToClipboard() {
            copyToClipboard(vm.webcalLink);
        }

        function copyToClipboard(value) {
            var textArea = document.createElement("textarea");
            textArea.value = value || "";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            successHandling.report("COPIED_TO_CLIPBOARD");
        }

        function save(event, form) {
            if (vm.folder.isMapped) {
                saveProperties();
            }
            else {
                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/folders/attach-to-virtual-folders", JSON.stringify({ folderIds: [vm.folderId] }))
                    .then(function () {
                        saveProperties();
                    },
                        function (failure) {
                            errorMessageService.showErrorMessage(failure);
                        })
                    .finally($rootScope.spinner.hide);
            }
        }

        function saveProperties() {
            $rootScope.spinner.show();

            let params;

            if (vm.folder.access >= 10 && vm.originalFolderName !== vm.folder.folderName) {
                switch (vm.typeName) {
                    case "appointments":
                        params = {
                            id: vm.folderId,
                            name: vm.folder.folderName,
                            owner: vm.folder.ownerUserName
                        };
                        $http.post("~/api/v1/calendars/rename", params)
                            .then(updateVirtualProperties,
                                function (failure) {
                                    errorMessageService.showErrorMessage(failure);
                                    $rootScope.spinner.hide();
                                });
                        return;
                    case "tasks":
                        params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/tasks/sources/edit", params)
                            .then(updateVirtualProperties,
                                function (failure) {
                                    errorMessageService.showErrorMessage(failure);
                                    $rootScope.spinner.hide();
                                });
                        return;
                    case "contacts":
                        params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/contacts/address-book/edit", params)
                            .then(
                                function () {
                                    $mdDialog.hide({ action: "refresh" })
                                },
                                function (failure) {
                                    errorMessageService.showErrorMessage(failure);
                                })
                            .finally($rootScope.spinner.hide);
                        return;
                    case "notes":
                        params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        }
                        $http.post("~/api/v1/notes/sources/edit", params)
                            .then(
                                function () {
                                    $mdDialog.hide({ action: "refresh" })
                                },
                                function (failure) {
                                    errorMessageService.showErrorMessage(failure);
                                })
                            .finally($rootScope.spinner.hide);
                        return;
                    case "email":
                        params = {
                            folder: vm.originalFolderName,
                            newFolder: vm.folder.folderName,
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`
                        }
                        $http.post("~/api/v1/folders/folder-patch", params)
                            .then(function (response) {
                                var data = response.data ? (response.data.folder ? response.data.folder : {}) : {};
                                data.newName = vm.folder.folderName;
                                data.path = vm.parentFolder ? (vm.parentFolder + "/" + data.newName) : data.newName;
                                $mdDialog.hide({ action: "refresh" })
                            }, function (failure) {
                                errorMessageService.showErrorMessage(failure);
                            })
                            .finally(function () {
                                $rootScope.spinner.hide();
                                coreDataMail.ignoreFolderUpdate.requested = Date.now();
                            });
                        return;
                }
            }

            updateVirtualProperties();

            function updateVirtualProperties() {
                params = JSON.stringify({
                    folderId: vm.folderId,
                    color: vm.folder.color,
                    allowWebcalSubscription: vm.folder.allowWebcalSubscription
                });
                $http
                    .post("~/api/v1/folders/set-virtual-folder-properties", params)
                    .then(function () {
                        $mdDialog.hide({ action: "refresh" });
                    }, function (failure) {
                        errorMessageService.showErrorMessage(failure);
                    })
                    .finally($rootScope.spinner.hide);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function deleteFolder(ev) {
            if (!vm.canDelete)
                return;

            var deleteFunc;
            let message = $filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: vm.folder.treeName }) +
                "<br><br>" +
                $filter("translate")("CONFIRMATIONS_DELETE_FOLDER_SHARED_FOOTNOTE");

            const confirm = $mdDialog.confirmDeletion()
                .htmlContent(message)
                .targetEvent(ev);

            switch (vm.typeName) {
                case "appointments":
                    deleteFunc = function () {
                        $http.post(`~/api/v1/calendars/calendar-delete/${vm.folder.folderGuid}/${vm.folder.ownerUserName}`)
                            .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
                    };
                    break;
                case "tasks":
                    deleteFunc = function () {
                        let params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/tasks/sources/delete", params)
                            .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
                    };
                    break;
                case "contacts":
                    deleteFunc = function () {
                        let params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/contacts/address-book/delete", params)
                            .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
                    };
                    break;
                case "notes":
                    deleteFunc = function () {
                        let params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            uid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/notes/sources/delete", params)
                            .then(function () { $mdDialog.hide({ deleted: true }); }, errorHandling.report);
                    }
                    break;
                case "email":
                    deleteFunc = function () {
                        let params = {
                            ownerEmailAddress: `${vm.folder.ownerUserName}@${vm.folder.ownerDomain}`,
                            folder: vm.folder.folderName,
                            folderGuid: vm.folder.folderGuid
                        };
                        $http.post("~/api/v1/folders/delete-folder", params)
                            .then(function () {
                                $mdDialog.hide({ deleted: true });
                                coreDataMail.ignoreFolderUpdate.requested = Date.now();
                            }, errorHandling.report);
                    }
                    break;
            }

            if (deleteFunc)
                $mdDialog.show(confirm).then(deleteFunc, function () { });
        }

        function stopSharing(event) {
            $rootScope.spinner.show();
            $http
                .post("~/api/v1/folders/detach-from-virtual-folders", JSON.stringify({ folderIds: [vm.folderId] }))
                .then(function () {
                    $mdDialog.hide({ action: "refresh", detached: true });
                }, function (failure) {
                    errorMessageService.showErrorMessage(failure);
                })
                .finally($rootScope.spinner.hide);
        }

        function disableAttach(form) {
            if (!vm.folder.isMapped)
                return false;
            if (form.$invalid || form.$pristine)
                return true;
            return false;
        }

        function translate(key) {
            return $filter("translate")(key);
        }
    }

})(); 