(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editFolderController", editFolderController);

	function editFolderController($scope, $mdDialog, $http, $timeout, coreDataMail, name, currentFolder, parentFolder, owner) {
		$scope.controller = this;
		$scope.title = "EDIT_FOLDER";
		$scope.name = name;
		$scope.currentFolder = currentFolder;
		$timeout(function () { $("input").trigger("focus"); }, 350);

		$scope.done = function () {
			if (!$scope.name || $scope.name.length === 0) { return; }
			//remove trailing .'s
			while ($scope.name.charAt($scope.name.length - 1) === ".") {
				$scope.name = $scope.name.substring(0, $scope.name.length - 1);
			}

			var parameters = {
				"folder": $scope.currentFolder,
				"newFolder": $scope.name,
				"parentFolder": parentFolder,
				"newParentFolder": parentFolder,
				"ownerEmailAddress": owner
			};

			coreDataMail.ignoreFolderUpdate.requested = Date.now();
			$http.post("~/api/v1/folders/folder-patch", parameters)
				.then(function (response) {
					var data = response.data ? (response.data.folder ? response.data.folder : {}) : {};
					data.newName = $scope.name;
					data.path = parentFolder ? (parentFolder + "/" + data.newName) : data.newName;
					$mdDialog.hide({ "success": response.data.success, "data": data, "response": response });
				}, function (response) {
					$mdDialog.hide({ "success": false, "response": response });
				});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}
})();
