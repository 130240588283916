(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemBindingsModalController", systemBindingsModalController);

    function systemBindingsModalController($scope, $http, $rootScope, errorHandling, claimsService) {
        var vm = this;
        vm.gridSections = [
            { translate: "IP_ADDRESSES", length: '0' },
            { translate: "PORTS", length: '0' }
        ];
        vm.selectedSection = "IP_ADDRESSES";
        vm.changeSection = function (section) { vm.selectedSection = section; };

        // Functions

        activate();

        /////////////////////////

        function activate() {
            refreshCounts();
            $scope.$on("bindings:counts-changed", function () { refreshCounts(); });
       }

        function refreshCounts() {
            $rootScope.spinner.show();
            $http
                .get("~/api/v1/settings/sysadmin/ip-binding-manager")
				.then(onBindingManagerLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onBindingManagerLoaded(result) {
                vm.bindingManager = result.data.bindingManager;
                vm.gridSections[0].length = (result.data.bindingManager.bindingInfo || []).length;
                vm.gridSections[1].length = (result.data.bindingManager.bindingPorts || []).length;
            }
        }
    }

})();

