(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditSignatureController", domainEditSignatureController);

	function domainEditSignatureController($scope, $mdDialog, $filter, $timeout, name, body, newItem, variables, sigId, domainOverride) {
	    $scope.name = name;
		$scope.body = body;
		$scope.newItem = newItem;
		$scope.customVariables = [{ key: 'var_dropdown', options: variables, icon: 'cog', title: $filter('translate')("SIGNATURES_CUSTOM_VARIABLES"), callback: variableCallback }];
		$scope.sigId = newItem ? 123 : sigId;
		$scope.domainOverride = domainOverride;
		$scope.editorScope = {};

		// Functions
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		//////////////////////////

		function variableCallback(cmd, val, scope) {
			$scope.form.$setDirty(); $scope.$applyAsync();
			if (scope.insertHtml) {
				scope.insertHtml(val);
			}
		};

		function save() {
			const bodyHtml = $scope.editorScope.getHtml();

			$timeout(function () {
				if ($scope.userForm.$valid) {
					$mdDialog.hide({ name: $scope.name, body: bodyHtml, deleteItem: false });
				}
			});
		};

		function deleteThis() {
			$mdDialog.hide({ name: $scope.name, body: $scope.body, deleteItem: true });
		};

		function cancel() {
			$mdDialog.cancel();
		};
	}
})();
