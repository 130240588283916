(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditUserGroupController", domainEditUserGroupController);

	function domainEditUserGroupController($rootScope, $scope, $mdDialog, $filter, $http,
		coreDataSettings, errorHandling, successHandling, id, name, usernames, i18n) {

		$scope.id = id;
		$scope.name = name;
		$scope.isNew = !name;
		$scope.userOptions = [];
		$scope.users = [];
		$scope.selectedItem = [];

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.delete = deleteItem;
		$scope.queryUsers = queryUsers;
		$scope.onEmailChanged = onEmailChanged;
		$scope.onEmailBlurred = onEmailBlurred;
		$scope.removeUser = removeUser;

		activate();

		///////////////////////

		function activate() {
			$scope.users = $.map(usernames, function (username) { return { name: username } });
			$scope.users.push({ name: "" });
		}

		function save(userForm) {
			if (userForm && userForm.$invalid) {
				return;
			}

			$scope.users.pop();

			$mdDialog.hide({
				name: $scope.name,
				grouped: $.map($scope.users, function (user) { return user.name; })
			});
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function deleteItem(ev) {
			if ($scope.isNew)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_one"))
				.targetEvent(ev);
			$mdDialog
				.show(confirm)
				.then(doDelete, function () { });

			function doDelete() {
				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/domain/remove-user-groups", JSON.stringify({ IDs: [id] }))
					.then(onDelete, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDelete(success) {
				if (success && success.data.result) {
					var confirm = $mdDialog.confirmDeletion()
						.title()
						.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE_ACCOUNTS"))
						.ok($filter("translate")("OK"))
						.noWarn(true)
						.hideCancel(true);
					$mdDialog.show(confirm);
				}

				successHandling.report();
				$rootScope.$broadcast("refreshUsersGrid");
				$mdDialog.hide();
			}
		}

		// User List
		function queryUsers(query) {
			return coreDataSettings.queryUsers(query, false);
		}

		function onEmailChanged(last, index, form) {
			var email = form["email" + index].$viewValue;
			if (email && email.length > 0 && last)
				$scope.users.push({ name: "" });
		}

		function onEmailBlurred(last, index, form) {
			var email = form["email" + index].$viewValue;
			if ((!email || email.length === 0) && !last) {
                $scope.selectedItem.splice(index, 1);
                $scope.users.splice(index, 1);
			}
		}

		function removeUser(index, form) {
			$scope.selectedItem.splice(index, 1);
			$scope.users.splice(index, 1);
			form.$setDirty();
		}
	}

})();
