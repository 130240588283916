(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("contactGroupViewController", contactGroupViewController);

    function contactGroupViewController($rootScope, $scope, $mdDialog, $filter, $http, $q, $stateParams,
        coreData, coreDataContacts, coreDataCategories, errorHandling, apiCategories, apiContactSources, userDataService, themesService, coreDataSettings, i18n) {

        var vm = this;

        vm.askBeforeLeave = true;
        vm.categories = [];
        vm.contactGuid = $stateParams.id;
        vm.contactInfo = {
            sourcePermission: 8,
            contactType: 2,
            groupedContacts: []
        }
        vm.displayNameChanged = false;
        vm.isDisabled = false;
        vm.isNew = vm.contactGuid === "new";
        vm.potentialMembers = [];
        vm.ready = false;
        vm.showSharedGal = true;
        vm.removedAttachedFiles = [];
        vm.sourceOwner = $stateParams.sourceOwner;
        vm.sources = [];

        // #region Functions
        vm.editMember = editMember;
        vm.isOwner = isOwner;
        vm.newMember = newMember;
        vm.openNoteBody = openNoteBody;
        vm.hasSelectedCategory = hasSelectedCategory;
        vm.onCategoryChanged = onCategoryChanged;
        vm.onClearAllCategories = onClearAllCategories;
        vm.openManageCategoriesModal = openManageCategoriesModal;
        vm.categoryColorByName = categoryColorByName;

        $scope.cancel = cancel;
        $scope.canDelete = canDelete;
        $scope.canMove = canMove;
        $scope.canSave = canSave;
        $scope.deleteWithConfirmation = deleteWithConfirmation;
        $scope.onSourceChanged = onSourceChanged;
        $scope.removeAttachedFile = removeAttachedFile;
        $scope.save = save;
        $scope.setForm = setForm;

        // #endregion

        activate();

        function activate() {
            themesService.ensureActivated();
            $rootScope.spinner.showInstant();

            const promises = [
                apiContactSources.getSources(true),
                coreData.init(),
				coreDataSettings.init()
					.then(function () {
						vm.showSharedGal = coreDataSettings.userPermissions.featureGal;
                    }, function () {})
            ];

            if (!vm.isNew)
                promises.push(coreDataContacts.getContact($stateParams.sourceOwner, $stateParams.sourceId, vm.contactGuid));

            $q
                .all(promises)
                .then(
                    function (results) {
                        vm.sources = results[0];

                        var temp = $.grep(vm.sources, function (source) {
                            return source.ownerUsername === userDataService.user.username && source.isPrimary;
                        });
                        $scope.defaultSource = temp.length > 0 ? temp[0] : vm.sources[0];
                        $scope.currentSource = $scope.defaultSource;
                        if (!vm.isNew)
                            setupEditContact(results[3]);

                        if ($scope.currentSource.ownerUsername) {
                            apiCategories.categoryOwner = $scope.currentSource.ownerUsername;
                        }
                        vm.categories = apiCategories.getCategories();
                        setCheckedCategories(vm.categories);
                        vm.attachedFiles = vm.contactInfo.attachedFiles ? JSON.parse(JSON.stringify(vm.contactInfo.attachedFiles)) : [];

                        vm.attachmentGuid = coreData.generateGuid();
                        coreDataContacts.init()
                            .then(init, errorHandling.report)
                            .finally(function () {
                                vm.ready = true;
                                $rootScope.spinner.hide();
                            });
                    },
                    errorHandling.reportAndHideSpinner);

            window.onbeforeunload = function () {
                if (!vm.askBeforeLeave || !vm.userForm.$dirty)
                    return undefined;
                vm.askBeforeLeave = false;
                return "";
            };

            function init() {
                $http.get(`~/api/v1/contacts/members/${$scope.currentSource.ownerUsername}`)
                    .then(
                        function (result) {
                            $scope.potentialMembers = result.data.results;
                            if (!vm.isNew)
                                $scope.potentialMembers = $scope.potentialMembers.filter(function (m) { return m.type !== 1 || m.emailAddress || m.displayName !== vm.contactInfo.displayAs; });

                            $scope.$on("categoriesUpdated", onCategoryListModified);
                        },
                        errorHandling.reportAndHideSpinner);
            }
        }

        function cancel() {
            $scope.skipNavigateConfirm = true;
            vm.askBeforeLeave = false;
            window.close();
        }

        function canDelete() {
            return !vm.isNew && vm.contactInfo && vm.contactInfo.sourcePermission >= 8;
        }

        function canMove() {
            return !$scope.currentSource || ($scope.currentSource.itemID !== "gal" && $scope.currentSource.access >= 8);
        }

        function canSave() {
            return vm.isNew || ($scope.currentSource && $scope.currentSource.access >= 8);
        }

        function doDelete() {
            coreDataContacts
                .removeSingleContact(vm.contactInfo.id, vm.contactInfo.sourceId, vm.contactInfo.sourceOwner)
                .then(
                    function () {
                        $scope.skipNavigateConfirm = true;
                        vm.askBeforeLeave = false;
                        window.close();
                    },
                    function (failure) {
                        errorHandling.report(failure);
                    });
        }

        function deleteWithConfirmation(ev) {
            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_one"))
                .targetEvent(ev);
            $mdDialog
                .show(confirm)
                .then(function () { doDelete(); }, function () { });
        }

        function editMember(event, item, index) {
            $mdDialog
                .show({
                    locals: {
                        memberItem: item,
                        sourceOwner: $scope.currentSource.ownerUsername,
                        possibleMembers: filterPotentialMembers(item),
                        galEnabled: vm.showSharedGal
                    },
                    controller: "editGroupMemberController",
                    controllerAs: "ctrl",
                    templateUrl: "app/contacts/modals/group-member.dlg.html",
                    targetEvent: event
                })
                .then(
                    function (data) {
                        if (data.delete) {
                            vm.contactInfo.groupedContacts.splice(index, 1);
                        } else {
                            vm.contactInfo.groupedContacts.splice(index, 1, data.member);
                        }
                        vm.userForm.$setDirty();
                    },
                    function () { });
        }

        function filterPotentialMembers(editingMember) {
            var filtered = $scope.potentialMembers;

            for (let i = 0; i < vm.contactInfo.groupedContacts.length; i++) {
                const gc = vm.contactInfo.groupedContacts[i];
                if (editingMember && gc === editingMember)
                    continue;

                switch (gc.type) {
                    case 0:
                        // OneOffs aren't in the potential list, so we don't need to filter them out
                        continue;
                    case 1:
                        // Contact
                        filtered = filtered.filter(function (c) { return c.type !== 1 || c.folderId !== gc.folderId || c.contactId !== gc.contactId; });
                        break;
                    case 2:
                        // GAL
                        filtered = filtered.filter(function (c) { return c.type !== 2 || !c.emailAddress || c.emailAddress.toLowerCase() !== gc.emailAddress.toLowerCase(); });
                        break;
                }
            }

            return filtered;
        }

        function getItemSourceType(item) {
            switch (item.type) {
                case 0:	// OneOff
                default:
                    return null;
                case 1:	// Contact/Distribution List
                    return item.emailAddress
                        ? $filter("translate")("CONTACT")
                        : $filter("translate")("CONTACT_GROUP");
                case 2:	// GAL
                    return $filter("translate")("GAL");
            }
        }

        function isOwner() {
            return $scope.currentSource && $scope.currentSource.ownerUsername === userDataService.user.username;
        }

        function memberExists(testMember, index) {
            if (!testMember.emailAddress) // This indicates a grouped contact, and there isn't really a way to duplicate them using the UI.
                return false;

            for (let i = 0; i < vm.contactInfo.groupedContacts.length; i++) {
                if (index && index === i)
                    continue;
                const member = vm.contactInfo.groupedContacts[i];

                if (member.emailAddress === testMember.emailAddress)
                    return true;
            }

            return false;
        }

        function newMember(event) {
            $mdDialog
                .show({
                    locals: {
                        memberItem: undefined,
                        sourceOwner: $scope.currentSource.ownerUsername,
                        possibleMembers: filterPotentialMembers(),
                        galEnabled: vm.showSharedGal
                    },
                    controller: "editGroupMemberController",
                    controllerAs: "ctrl",
                    templateUrl: "app/contacts/modals/group-member.dlg.html",
                    targetEvent: event
                })
                .then(
                    function (data) {
                        if (memberExists(data.member)) {
                            errorHandling.report($filter("translate")("CONTACT_GROUP_MEMBER_EXISTS", { email: data.member.emailAddress }));
                            return;
                        }

                        vm.contactInfo.groupedContacts.push(data.member);
                        vm.userForm.$setDirty();
                    },
                    function () { });
        }

        function onCategoryListModified() {
            apiCategories.getCategories(true)
                .then(
                    function (results) {
                        const currentSelects = vm.categories;
                        vm.categories = results;
                        setCheckedCategories(vm.categories, currentSelects);
                    },
                    function (failure) {
                        errorHandling.report(failure);
                    });
        }

        function hasSelectedCategory() {
            if (!vm.categories)
                return false;
            for (var i = 0; i < vm.categories.length; i++)
                if (vm.categories[i].selected) return true;
            return false;
        }

        function onCategoryChanged(cat) {
            if (cat)
                cat.selected = !cat.selected;
            vm.userForm.$setDirty();
        }

        function onClearAllCategories() {
            for (var i = 0; i < vm.categories.length; i++)
                vm.categories[i].selected = false;
            vm.userForm.$setDirty();
        }

        function openManageCategoriesModal(ev) {
            $mdDialog.show({
                controller: "manageCategoriesDialogController",
                controllerAs: "manageCategoriesCtrl",
                templateUrl: "app/shared/modals/manage.categories.dlg.html",
                targetEvent: ev
            });
        }

        function categoryColorByName(catName) {
            const cat = getCategoryByName(catName);
            if (!cat || cat.colorIndex == -1)
                return null;
            const color = apiCategories.getCategoryColor(cat.colorIndex);
            if (!color || !color.rgb)
                return null;
            return color.rgb;

            function getCategoryByName(categoryName) {
                if (!categoryName) return null;
                var results = $.grep(vm.categories, (cat) => cat.name.toUpperCase() === categoryName.toUpperCase());
                return results.length > 0 ? results[0] : null;
            }
        }

        function onSourceChanged(oldSource, newSource) {
            // Can't change owner on an existing item, so only need to do anything if the group is new.
            if (!vm.isNew || oldSource.ownerUsername === newSource.ownerUsername)
                return;

            // Need to remove members that conflict with the change in owner.
            for (let i = 0; i < vm.contactInfo.groupedContacts.length; i++) {
                const member = vm.contactInfo.groupedContacts[i];
                if (member.type === 0 || member.type === 2)
                    continue;

                var source = $.grep(vm.sources || [], function (src) { return src.folderId === member.folderId; });
                if (source.length === 0 || source[0].ownerUserName !== newSource.ownerUsername) {
                    vm.contactInfo.groupedContacts.splice(i, 1);
                    i--;
                }
            }

            $http.get(`~/api/v1/contacts/members/${newSource.ownerUsername}`)
                .then(
                    function (result) {
                        $scope.potentialMembers = result.data.results;
                    },
                    function () { });
        }

        function openNoteBody(ev) {
            if (vm.isDisabled)
                return;

            var afcontext = "user";
            if ($scope.currentSource.isDomainResource)
                afcontext = "domainshare";
            $mdDialog
                .show({
                    locals: {
                        name: "NOTES",
                        text: vm.contactInfo.additionalInfo,
                        afContext: afcontext,
                        afSourceType: "cc",
                        afSourceId1: undefined,
                        readOnly: vm.isDisabled
                    },
                    controller: "contactNoteEditController",
                    controllerAs: "ctrl",
                    templateUrl: "app/contacts/modals/contact-note-edit.dlg.html",
                    targetEvent: ev,
                    clickOutsideToClose: false
                })
                .then(function (modalSuccess) {
                    if (vm.contactInfo.additionalInfo !== modalSuccess) {
                        vm.contactInfo.additionalInfo = modalSuccess;
                        vm.contactInfo.additionalInfoIsHtml = true;
                        vm.userForm.$setDirty();
                    }
                }, function () {
                    // Cancel
                });
        }

        function removeAttachedFile(attachment, ev) {
            if (vm.isDisabled || !attachment)
                return;
            if (attachment.isNew && typeof attachment.remove === "function") {
                attachment.remove();
                return;
            } 
            vm.removedAttachedFiles.push(attachment.fileReference);
            vm.userForm.$setDirty();
        };

        function save() {
            $scope.skipNavigateConfirm = true;
            vm.askBeforeLeave = false;

            vm.contactInfo.categories = $.grep(vm.categories || [], function (cat) { return cat.selected; });
            vm.contactInfo.contactType = 2;
            if (vm.attachedFiles.some(x => x.isNew))
                vm.contactInfo.attachmentGuid = vm.attachmentGuid;
            if (vm.isNew) {
                vm.contactInfo.id = undefined;
                vm.contactInfo.sourceOwner = $scope.currentSource.ownerUsername;
                vm.contactInfo.sourceName = $scope.currentSource.displayName;
                vm.contactInfo.sourceId = $scope.currentSource.itemID;
                vm.contactInfo.source = $scope.currentSource;

                coreDataContacts.addContact(vm.contactInfo)
                    .then(
                        function (success) {
                            vm.contactInfo.id = success.data.contacts[0].id;

                            //coreDataContacts.editContact(vm.contactInfo).then(function () { window.close(); }, function () { });
                            window.close();
                        },
                        errorHandling.report);
                return;
            } 
            if (vm.contactInfo.sourceId !== $scope.currentSource.itemID) {
                const origContactId = vm.contactInfo.oldId = vm.contactGuid;
                const origSourceId = vm.contactInfo.oldSourceId = vm.contactInfo.sourceId;
                const origSourceOwner = vm.contactInfo.oldSourceOwner = vm.contactInfo.sourceOwner;

                vm.contactInfo.id = undefined;
                vm.contactInfo.sourceOwner = $scope.currentSource.ownerUsername;
                vm.contactInfo.sourceName = $scope.currentSource.displayName;
                vm.contactInfo.sourceId = $scope.currentSource.itemID;
                vm.contactInfo.source = $scope.currentSource;
                vm.contactInfo.oldSourceId = origSourceId;
                vm.contactInfo.oldId = origContactId;
                vm.contactInfo.oldSourceOwner = origSourceOwner;

            } 
            deleteRemovedAttachedFiles(vm.contactInfo.sourceId, vm.contactInfo.sourceOwner)
                .then(
                    function () {
                        coreDataContacts.editContact(vm.contactInfo)
                            .then(window.close, errorHandling.report);
                    },
                    errorHandling.report);

            function deleteRemovedAttachedFiles(sourceId, ownerEmail) {
                if (!vm.removedAttachedFiles || vm.removedAttachedFiles.length === 0)
                    return $q.when();

                const source = vm.sources.filter(src => src.itemID === sourceId)[0];
                const removeParams = JSON.stringify({
                    folderId: source.folderId,
                    owner: ownerEmail,
                    removeFileReferences: vm.removedAttachedFiles
                });

                return $http.post("~/api/v1/filestorage/remove-attached-file", removeParams);
            }
        }

        function setCheckedCategories(categories, selectedCategories) {
            if (!selectedCategories)
                selectedCategories = vm.contactInfo.categories;

            categories.forEach(function (cat) { cat.selected = false; });
            if (selectedCategories) {
                categories = selectedCategories.reduce(function (res, cat) {
                    const masterCat = res.find(c => c.name === cat.name);
                    if (masterCat) {
                        masterCat.selected = cat.selected;
                    } else if (cat.selected) {
                        res.push({ name: cat.name, colorIndex: -1, master: false, translatedName: $filter("translate")(cat.name), selected: true });
                    }
                    return res;
                }, categories);
            }
            return categories;
        }

        function setForm(form) {
            vm.userForm = form;
        }

        function setupEditContact(contact) {
            vm.contactInfo = contact;
            if (contact) vm.attachedFiles = contact.attachedFiles;

            for (let i = 0; i < vm.contactInfo.groupedContacts.length; i++) {
                vm.contactInfo.groupedContacts[i].source = getItemSourceType(vm.contactInfo.groupedContacts[i]);
            }

            var temp = $.grep(vm.sources,
                function (source) {
                    if (vm.contactInfo.sourceId === source.itemID) {
                        if (vm.contactInfo.sourceOwner && source.ownerUsername && vm.contactInfo.sourceOwner.toUpperCase() === source.ownerUsername.toUpperCase())
                            return true;
                        else if (!vm.contactInfo.sourceOwner && !source.ownerUsername)
                            return true;
                    }
                    return false;
                });
            $scope.currentSource = temp.length > 0 ? temp[0] : vm.sources[0];

            temp = $.grep(vm.sources, function (source) {
                return source.access >= 8 || ($scope.currentSource && source.itemId == $scope.currentSource.itemId);
            });
            if (temp.length > 0)
                vm.sources = temp;
        }

        vm.fileUploadedCallback = function() {
            vm.userForm.$setDirty();
        }
    }
})();