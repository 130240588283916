(function () {
	angular.module("smartertools").filter('daysSince', function () {
		return function (days) {
			return moment.duration(days, 'days').humanize();
		}
	});
})();

(function () {
	angular.module("smartertools").filter('fromNow', function () {
		return function (dateTime, showSuffix) {
			return moment(dateTime).fromNow(!showSuffix);
		}
	});
})();