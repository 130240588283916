(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarDateWarnDialogController", calendarDateWarnDialogController);

    function calendarDateWarnDialogController($mdDialog, isSeries, isMeeting, isRecurring, isNew) {
        var vm = this;
        vm.isSeries = isSeries;
        vm.isMeeting = isMeeting;
        vm.isRecurring = isRecurring;
        vm.isNew = isNew;
        vm.notify = false;
        vm.save = function () {
            $mdDialog.hide({ notify: vm.notify });
		}

		vm.cancel = function () {
			$mdDialog.cancel();
		}
    };
})();