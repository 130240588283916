(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsIpAddressesController", adminSettingsIpAddressesController);

	function adminSettingsIpAddressesController($rootScope, $scope, $mdDialog, $http, $translate, $compile,
		NgTableParams, gridCheckboxes, errorHandling, i18n) {

		var vm = this;
		vm.searchText = null;
		vm.ipAddresses = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { ipAddress: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("ipAddress");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.deleteItems = deleteItems;

		activate();

		///////////////////

		function activate() { }

		function queryData(params) {
			var skip = (params.page() - 1) * params.count();
			var take = Number(params.count());
			var search = vm.searchText ? vm.searchText.toLowerCase() : "";

			var sortField = null;
			var sortDescending = false;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortField = k;
				sortDescending = params.sorting()[k] === "desc";
				break;
			}

			$rootScope.spinner.show();
			return $http
				.get("~/api/v1/settings/sysadmin/ip-binding-manager/" + sortField + '/' + sortDescending)
				.then(onDataQuery, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDataQuery(result) {
				vm.bindingManager = result.data.bindingManager;
				vm.availableAddresses = vm.bindingManager.availableAddresses || [];
				vm.ipAddresses = vm.bindingManager.bindingInfo || [];
				vm.checkboxes.reset();
				vm.tableParams.total(vm.ipAddresses.length);
				if (search) {
					vm.ipAddresses = _.filter(vm.ipAddresses, function (value) {
						if (value.ipAddress && value.ipAddress.toLowerCase().indexOf(search) !== -1)
							return true;
						if (value.hostName && value.hostName.toLowerCase().indexOf(search) !== -1)
							return true;
						if (value.description && value.description.toLowerCase().indexOf(search) !== -1)
							return true;
						return false;
					});
					vm.tableParams.total(vm.ipAddresses.length);
				}
				return vm.ipAddresses.slice(skip, skip + take);
			}
		}

		function editItem(item, event) {
			var ports = $.map(vm.bindingManager.bindingPorts, function (port) {
				return {
					id: port.id,
					name: port.name,
					port: port.port,
					isSelected: item.portIDs && item.portIDs.indexOf(port.id) > -1
				};
			});
			var selectedCard = $.extend({}, item, { ports: ports, canDelete: !vm.availableAddresses.some(avail => avail === item.ipAddress) });

			$mdDialog.show({
				locals: { info: selectedCard },
				controller: "systemIpAddressModalController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/bindings/ip-address.dlg.html",
				targetEvent: event
			}).then(onEditSuccess, function(){});

			function onEditSuccess(data) {
				if (data && data.delete) {
					confirmAndDeleteItems([item.ipAddress], event);
				} else {

					refreshTable();
				}
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.ipAddress; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.ipAddress];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function searchItems() {
			refreshTable();
		}

		function deleteItems(ev) {
			var items = vm.checkboxes.getCheckedItems();
			confirmAndDeleteItems(items, ev);
		}
		function confirmAndDeleteItems(items, ev) { 
			var itemsToDelete = [];
			for (var i = 0; i < items.length; ++i) {
				var found = false;
				for (var j = 0; j < vm.availableAddresses.length; ++j) {
					if (vm.availableAddresses[j] === items[i]) {
						found = true;
						break;
					}
				}
				if (found)
					continue;

				// Only allow IPs not on this server to be deleted
				itemsToDelete.push(items[i]);
			}

			if (itemsToDelete.length === 0) {
				onDeletion();
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: itemsToDelete.length }))
				.targetEvent(ev);
			$mdDialog.show(confirm).then(doDelete, function () { });

			function doDelete() {
				var params = {
					toRemove: itemsToDelete
				};

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/ip-binding-info", JSON.stringify(params))
					.then(onDeletion, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDeletion() {
				errorHandling.warn("SYSADMIN_SETTINGS_ONLY_UNAVAILABLE_ADDRESSES_DELETED");
				refreshTable();
				searchItems();
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
	}
})();