(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("editDomainNameController", editDomainNameController);

	function editDomainNameController($scope, $mdDialog, $http, $filter, coreDataDomainSettings, errorHandling, oldDomainName, oldIdn) {
		$scope.oldDomainName = oldDomainName;
		$scope.oldIdn = oldIdn

		$scope.newDomainName = "";
		$scope.domainPath = "";
		coreDataDomainSettings.settingsData.domainData
			.then(function (successData) {
				$scope.domainPath = successData.path;
			}, function () { });

		$scope.close = function (form) {
			if (!form.$valid)
				return;
			var newLower = $scope.newDomainName.toLowerCase();
			if (oldDomainName.toLowerCase() === $scope.newDomainName.toLowerCase() || (oldIdn && oldIdn.toLowerCase() === newLower))
				return;
			
			if ((($scope.domainPath.length - $scope.oldDomainName.length) + $scope.newDomainName.length) > 210) {
				errorHandling.report($filter('translate')('WINDOWS_FILE_LENGTH_EXCEEDED'));
				return;
			}

			var params = JSON.stringify({
				oldDomainName: oldDomainName,
				newDomainName: $scope.newDomainName
			});

			$http
				.post("~/api/v1/settings/sysadmin/rename-domain", params)
				.then(onDomainRenamed, errorHandling.report);

			function onDomainRenamed() {
				$mdDialog.hide();
			}
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

	}

})();
