(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("taskActions", taskActions);

    function taskActions($rootScope, $http, $mdDialog, $translate, $filter, errorHandling, preferencesStorage, popupService) {
        var vm = this;

        // Functions
        vm.addToOutlook = addToOutlook;
        vm.showCreateFolderModal = showCreateFolderModal;
        vm.showRenameFolderModal = showRenameFolderModal;
        vm.showDeleteFolderModal = showDeleteFolderModal;
        vm.showShareFolderModal = showShareFolderModal;
        vm.showEditFolderModal = showEditFolderModal;
        vm.showSharedFolderPropertiesModal = showSharedFolderPropertiesModal;
        vm.showNewTaskPopup = showNewTaskPopup;
        vm.showEditTaskPopup = showEditTaskPopup;

        ///////////////////////////

        async function addToOutlook() {
            let modalSuccess;

            try {
                modalSuccess = await $mdDialog.show({
                    controller: "taskOutlookDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/tasks/modals/task-outlook.dlg.html"
                });
            }
            catch (err) { return; }

            try {
                $rootScope.spinner.show();

                const params = {
                    "outlookVersion": modalSuccess.outlookVersion,
                    "addType": "tasks",
                    "taskName": modalSuccess.source.id,
                    "addId": modalSuccess.source.id,
                    "owner": modalSuccess.source.owner,
                    "displayName": modalSuccess.displayName
                };
                const httpResult = await $http.post("~/api/v1/outlook/add-to-url", params);
                window.open(httpResult.data, "_self");
            }
            catch (err) { errorHandling.report(err); }
            finally { $rootScope.spinner.hide(); }
        }

        async function showCreateFolderModal(event) {
            try {
                await $mdDialog.show({
                    locals: {},
                    controller: "createTaskFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/tasks/modals/create-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore error - they closed the modal
            }
        }

        async function showRenameFolderModal(event, source) {
            if (!source)
                return;

            try {
                let success = undefined;

                if (source.isSharedItem) {
                    success = await $mdDialog.show({
                        controller: "editSharedFolderDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                        targetEvent: event,
                        locals: { folderId: source.folderId, webcalLink: null, folder: null }
                    });
                }
                else {
                    success = await $mdDialog.show({
                        locals: { source: source },
                        controller: "renameTaskFolderController",
                        controllerAs: "ctrl",
                        templateUrl: "app/tasks/modals/rename-folder.dlg.html",
                        targetEvent: event,
                        clickOutsideToClose: false
                    });
                }

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("tasks", source);
                    preferencesStorage.removeSourceVisibility("calendars", source);
                }

                return true;
            }
            catch (err) {
                // exception just means modal was closed
            }
        }

        async function showDeleteFolderModal(event, source) {
            if (!source || source.isPrimary)
                return;

            const name = source.displayName ? $translate.instant(source.displayName) : source.name;
            const confirm = $mdDialog.confirmDeletion()
                .htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: name }))
                .targetEvent(event);

            try {
                await $mdDialog.show(confirm);
            } catch (err) {
                return;
            }

            try {
                const folder = {
                    folder: name,
                    uid: source.id || source.itemID
                };
                await $http.post("~/api/v1/tasks/sources/delete", folder);
                preferencesStorage.removeSourceVisibility("tasks", source);
                preferencesStorage.removeSourceVisibility("calendars", source);
                return true;
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function showShareFolderModal(event, source) {
            try {
                await $mdDialog.show({
                    locals: { source: source, isCalendarView: false },
                    controller: "shareTaskFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/tasks/modals/share-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }

        async function showSharedFolderPropertiesModal(event, source) {
            try {
                let success = await $mdDialog.show({
                    controller: "editSharedFolderDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                    targetEvent: event,
                    locals: { folderId: source.folderId, webcalLink: null, folder: null }
                });

                if (success && success.detached) {
                    preferencesStorage.removeSourceVisibility("tasks", source);
                    preferencesStorage.removeSourceVisibility("calendars", source);
                }
                return true;
            } catch (err) {
                // ignore if the user closed the dialog
            }
        }

        async function showEditFolderModal(event, source) {
            let modalResult;

            if (source && source.isSharedItem) {
                modalResult = await $mdDialog.show({
                    controller: "editSharedFolderDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                    targetEvent: event,
                    locals: { folderId: source.folderId, webcalLink: null, folder: null }
                });
            }
            else {
                modalResult = await $mdDialog.show({
                    locals: { source: source, isCalendarView: false },
                    controller: "editTaskFolderController",
                    controllerAs: "ctrl",
                    templateUrl: "app/tasks/modals/edit-task-folder.dlg.html",
                    targetEvent: event,
                    clickOutsideToClose: false
                });
            }

            if (modalResult && modalResult.detached) {
                preferencesStorage.removeSourceVisibility("tasks", source);
                preferencesStorage.removeSourceVisibility("calendars", source);
            }
        }

        function showNewTaskPopup() {
            const url = `${stSiteRoot}interface/root#/popout/task/new`;
            return window.open(url, "", "resizable=1, " + popupService.dimensions.task);
        }

        function showEditTaskPopup(id, sourceId, sourceOwner) {
            const url = `${stSiteRoot}interface/root#/popout/task/${id}/${sourceId}/${sourceOwner}`;
            return window.open(url, id, "resizable=1, " + popupService.dimensions.task);
        }
    }
})(); 