(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("authStorage", authStorage);

	function authStorage($rootScope, $localStorage, $timeout, $q, $injector, claimsService) {
		var storage = $localStorage;
		var newStorage = localStorage;

		var service = {};
		service.saveToken = saveToken;
		service.deleteTokens = deleteTokens;
		service.getToken = getToken;
		service.getTokenWithPromise = getTokenWithPromise;
		service.getUserEmail = getUserEmail;
		service.getDomain = getDomain;
		service.saveRememberMe = saveRememberMe;
		service.getCurrentUser = getCurrentUser;
		service.deleteAll = deleteAll;
		service.getRefreshToken = getRefreshToken;
		service.isLocalStorageForCurrentUser = isLocalStorageForCurrentUser;
		service.localStorageHasValue = localStorageHasValue;
		service.saveImpersonateToken = saveImpersonateToken;
		service.deleteImpersonateToken = deleteImpersonateToken;
		service.impersonationTokens = impersonationTokens;
		service.deleteSessionStorage = deleteSessionStorage;
		service.savePasswordResetId = savePasswordResetId;
		service.savePasswordResetUsername = savePasswordResetUsername;
		service.getPasswordResetId = getPasswordResetId;
		service.getPasswordResetUsername = getPasswordResetUsername;
		service.setPopoutData = setPopoutData;
		service.getPopoutData = getPopoutData;
		service.savePasswordRequirements = savePasswordRequirements;
		service.getPasswordRequirements = getPasswordRequirements;
		service.getAccessTokenExpiration = getAccessTokenExpiration;
		service.initiatedImpersonation = initiatedImpersonation;
		service.hasInitiatedImpersonation = hasInitiatedImpersonation;
		service.deleteInitiatedImpersonation = deleteInitiatedImpersonation;
		service.deleteAllExceptPasswordResetInfo = deleteAllExceptPasswordResetInfo;
		service.deleteAllWithUser = deleteAllWithUser;
		service.updatingToken = updatingToken;
		service.isTokenCurrentlyUpdating = isTokenCurrentlyUpdating;
		service.getClientId = getClientId;

		var tokenPromise = undefined;
		var tokenPromiseDone = true;
		var updatingTokenTimeout = null;

		return service;

		function updatingToken(value) {
			var emailAddress = sessionStorage.username;
			if (emailAddress && newStorage.userTokens) {
				var userTokens = JSON.parse(newStorage.userTokens);
				userTokens[emailAddress].updateInProgress = value;
				newStorage.setItem("userTokens", JSON.stringify(userTokens));
			}
			$rootScope.$applyAsync();
		}

		function isTokenCurrentlyUpdating() {
			var val = false;
			var emailAddress = sessionStorage.username;
			if (emailAddress && newStorage.userTokens) {
				var userTokens = JSON.parse(newStorage.userTokens);
				val = userTokens[emailAddress].updateInProgress || false;
			}
			return val;
		}

		function saveToken(token, impersonating, source) {
			if (!token) {
				return;
			}

			if (token.email)
				sessionStorage.username = token.email;
			else
				sessionStorage.username = token.username;

			if (isRememberMeChecked() || isLocalStorageForCurrentUser()) {
				if (!storage.currentUser)
					storage.currentUser = {};

				if (token.username)
					storage.currentUser.username = token.username;
			}
			
			var emailAddress = undefined;
			if (newStorage.impUser) {
				var obj = JSON.parse(newStorage.impUser);
				emailAddress = obj.email;
			} else {
				emailAddress = sessionStorage.username;
			}

			var userTokens = {};
			if (newStorage.userTokens && emailAddress != undefined) {
				userTokens = JSON.parse(newStorage.userTokens);
			}

			userTokens[emailAddress] = {};
			userTokens[emailAddress].accessToken = token.accessToken;
			userTokens[emailAddress].refreshToken = token.refreshToken;
			userTokens[emailAddress].accessTokenExpiration = token.accessTokenExpiration;
			userTokens[emailAddress].refreshTokenExpiration = token.refreshTokenExpiration;
			userTokens[emailAddress].updateInProgress = false;
			newStorage.setItem("userTokens", JSON.stringify(userTokens));

			$rootScope.$applyAsync();
		}

		function deleteTokens() {
			// Can delete the current user's token since they logged out
			var emailAddress = sessionStorage.username;
			if (emailAddress != undefined && newStorage.userTokens) {
				var userTokens = JSON.parse(newStorage.userTokens)
				userTokens[emailAddress] = {};
				delete userTokens[emailAddress];
				newStorage.setItem("userTokens", JSON.stringify(userTokens));
			}

			// Can delete any tokens where the refresh token has expired
			if (newStorage.userTokens) {
				var now = new Date().getTime();
				var userTokens = JSON.parse(newStorage.userTokens);
				for (let tmpEmailAddress in userTokens) {
					var obj = userTokens[tmpEmailAddress];
					if (obj && obj.refreshTokenExpiration && now > new Date(obj.refreshTokenExpiration)) {
						delete userTokens[tmpEmailAddress];
                    }
				}
				newStorage.setItem("userTokens", JSON.stringify(userTokens));
			}

			if (storage.currentUser === emailAddress) {
				delete storage.currentUser;
            }

			$rootScope.$applyAsync();
		}

		function initiatedImpersonation(email) {
			storage.initiatedImpersonation = email;
		}

		function hasInitiatedImpersonation(email) {
			if (storage.initiatedImpersonation == undefined)
				return false;
			if (storage.initiatedImpersonation == email)
				return true;
			else
				return false;
		}

		function deleteInitiatedImpersonation() {
			delete storage.initiatedImpersonation;
		}

		function getAccessTokenExpiration() {
			var res = "";
			var emailAddress = sessionStorage.username;
			if (emailAddress) {
				var userTokens = JSON.parse(newStorage.userTokens);
				res = userTokens[emailAddress].accessTokenExpiration;
			}
			return res;
		}

		function saveImpersonateToken(data) {
			var impUser = {
				accessToken: data.impersonateAccessToken,
				refreshToken: data.impersonateRefreshToken,
				username: data.username,
				email: data.email,
				accessTokenExpiration: data.impersonateAccessTokenExpiration,
				refreshTokenExpiration: data.impersonateRefreshTokenExpiration
			};
			newStorage.setItem("impUser", JSON.stringify(impUser));
			$rootScope.$applyAsync();
		}

		function deleteImpersonateToken() {
			newStorage.removeItem("impUser");
		}

		function impersonationTokens() {
			var user = newStorage.getItem("impUser");
			if (user) {
				return JSON.parse(user);
			}
			return undefined;
		}

		function getToken(isSysAdmin) {
			var emailAddress = sessionStorage.username;

			if (!isSysAdmin) {
				var impUser = impersonationTokens();
				if (impUser) {
					return impUser.accessToken;
				}
			}

			if (storage.currentUser && claimsAndLocalStorageMatch()) {
				emailAddress = storage.currentUser.username;
			}

			if (emailAddress && newStorage.userTokens) {
				var userTokens = JSON.parse(newStorage.userTokens);
				var res = userTokens[emailAddress].accessToken;
				if (res)
					return res;
				return "";
			}
			return "";
		}

		// This is being called
		function getTokenWithPromise() {
			if (tokenPromiseDone) {
				tokenPromise = undefined;
			}
			tokenPromiseDone = false;
			tokenPromise = $q.defer();

			if (isTokenCurrentlyUpdating()) {
				if (!updatingTokenTimeout) {
					updatingTokenTimeout = $timeout(someTimeout, 20);
				}

				function someTimeout() {
					if (isTokenCurrentlyUpdating()) {
						updatingTokenTimeout = $timeout(someTimeout, 20);
						return;
					} else {
						updatingTokenTimeout = undefined;
						tokenPromise.resolve(getToken());
					}
				}
			} else {
				updatingToken(true);

				var token = getToken();
				if (!token) {
					updatingToken(false);
					return $q.when();
				} else {
					var now = new Date().getTime();
					var accessTokenExpiration = getAccessTokenExpiration();
					var expireConverted = new Date(accessTokenExpiration);
					var accessTokenExpirationBuffer = 60000;
					var expires = expireConverted.getTime() - accessTokenExpirationBuffer;
					if (now > expires) {
						// refresh needed
						
						var clientId = getClientId();

						var refreshToken = getRefreshToken();
						var http = $injector.get("$http");
						http
							.post(stSiteRoot + "api/v1/auth/refresh-token", { token: refreshToken, iswebmailrefresh: true, clientId: clientId })
							.then(
								function (retData) {
									token = retData.data.accessToken;
									tokenPromise.resolve(token);
									tokenPromiseDone = true;
								}, function (failure) {
									updatingToken(false);
									tokenPromise.reject(failure);
									tokenPromiseDone = true;
								});
					} else {
						// no refresh needed
						updatingToken(false);
						tokenPromise.resolve(token);
						tokenPromiseDone = true;
						return tokenPromise.promise;
					}
				}
			}

			return tokenPromise.promise;
		}

		function claimsAndLocalStorageMatch() {
			var impUser = impersonationTokens();
			if (impUser) {
				return false;
			}

			if (storage.currentUser && storage.currentUser.username) {
				// This means that somebody has logged in with remember me checked
				// and has never logged out.  There's a good chance that their token
				// is still be valid.
				var e = claimsService.getUsername();
				if (e === undefined || e.length == 0)
					return false;

				if ((claimsService.getUsername() + "@" + claimsService.getDomain()) !== sessionStorage.username)
					return false;

				if (storage.currentUser.username === (claimsService.getEmailAddress() || "")) {
					return true;
				} else if (storage.currentUser.username === (claimsService.getUsername() || "")) {
					return true;
				} else if (storage.currentUser.username === (claimsService.getUsername() + "@" + claimsService.getDomain())) {
					return true;
				}

				return false;
			}
		}

		function claimsAndImpersonatedMatch() {
			if (storage.impUser) {
				if (storage.impUser.email === claimsService.getEmailAddress()) {
					return true;
				} else if (storage.impUser.email === claimsService.getUsername()) {
					return true;
				} else if (storage.impUser.email === (claimsService.getUsername() + "@" + claimsService.getDomain())) {
					return true;
				}
			}
		}

		function getRefreshToken() {
			var emailAddress = "";
			if (sessionStorage.username)
				emailAddress = sessionStorage.username;
			else if (storage.currentUser && storage.currentUser.username)
				emailAddress = storage.currentUser.username;

			if (emailAddress && newStorage.userTokens) {
				var userTokens = JSON.parse(newStorage.userTokens);
				var res = userTokens[emailAddress] && userTokens[emailAddress].refreshToken;
				if (res)
					return res;
				return "";
			}

			return "";
		}

		function getClientId() {
			if (newStorage.clientId == undefined) {
				newStorage.clientId = "WEBMAIL-" + uuidv4();
			}
			var res = newStorage.clientId;
			return res;
		}

		function uuidv4() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		}

		function isLocalStorageForCurrentUser() {
			if (storage.currentUser && claimsAndLocalStorageMatch()) {
				var emailAddress = storage.currentUser.username;
				var userTokens = JSON.parse(newStorage.userTokens);
				var token = userTokens[emailAddress] && userTokens[emailAddress].accessToken;
				if (token)
					return true;
				else
					return false;
			} else {
				return false;
			}
		}

		function localStorageHasValue() {
			if (storage.currentUser) {
				var token = storage.currentUser.token;
				if (token)
					return true;
				else
					return false;
			} else {
				return false;
			}
		}

		function getUserEmail() {
			var email = claimsService.getEmailAddress();
			if (!email)
				email = storage.currentUser ? storage.currentUser.username : undefined;
			return email;
		}

		function getDomain() {
			var email = getUserEmail();
			if (email === "")
				return "";
			var parts = email.split("@");
			if (parts.length === 2)
				return parts[1];
			else
				return "";
		}

		function saveRememberMe(value) {
			if (value !== undefined)
				sessionStorage.rememberMe = value;
		}

		function getCurrentUser() {
			if (!storage.currentUser)
				return null;
			return storage.currentUser;
		}

		function deleteAll(source) {
			// Only delete the current user if the data in the claims service matches
			if (storage.currentUser && claimsAndLocalStorageMatch()) {
				delete storage.currentUser;
			}
			// Only delete the impersonated user if the data in the claims service matches
			if (storage.impUser && claimsAndImpersonatedMatch()) {
				delete storage.impUser;
			}
			deleteSessionStorage("deleteAll");
		}

		function deleteAllWithUser(user, source) {
			if (storage.currentUser && storage.currentUser.username && user) {
				delete storage.currentUser;
			}
			if (storage.impUser && storage.impUser.username && user) {
				delete storage.impUser;
			}
			deleteSessionStorage("deleteAllWithUser");
		}

		function deleteSessionStorage(source) {
			delete sessionStorage.username;
			delete sessionStorage.rememberMe;
			delete sessionStorage.passwordResetId;
			delete sessionStorage.passwordResetUsername;
			delete sessionStorage.popoutData;
		}

		function deleteAllExceptPasswordResetInfo(source) {
			// Only delete the current user if the data in the claims service matches
			if (storage.currentUser && claimsAndLocalStorageMatch())
				delete storage.currentUser;
			// Only delete the impersonated user if the data in the claims service matches
			if (storage.impUser && claimsAndImpersonatedMatch())
				delete storage.impUser;

			delete sessionStorage.username;
			delete sessionStorage.rememberMe;
			delete sessionStorage.popoutData;
		}

		function isRememberMeChecked() {
			if (claimsService.impersonating())
				return false;

			if (sessionStorage.rememberMe === "true")
				return true;

			if ((claimsService.getUsername() && storage.currentUser && claimsService.getUsername() === storage.currentUser.username))
				return true;

			return false;
		}

		function savePasswordResetId(passwordResetId) {
			sessionStorage.passwordResetId = passwordResetId;
		}

		function savePasswordResetUsername(passwordResetUsername) {
			sessionStorage.passwordResetUsername = passwordResetUsername;
		}

		function getPasswordResetId() {
			return sessionStorage.passwordResetId;
		}

		function getPasswordResetUsername() {
			return sessionStorage.passwordResetUsername;
		}

		function savePasswordRequirements(passwordRequirements) {
			sessionStorage.passwordRequirements = JSON.stringify(passwordRequirements);
		}
		function getPasswordRequirements() {
			if (sessionStorage.passwordRequirements) {
				const passwordRequirements = JSON.parse(sessionStorage.passwordRequirements);
				delete sessionStorage.passwordRequirements;
				return passwordRequirements;
			} else {
				return null;
			}
		}

		// Please open your popout window immediately after calling this
		function setPopoutData(data, callback) {
			sessionStorage.popoutData = JSON.stringify(data);
			$timeout(function () {
				// So we don't have to manually remove the data from the main window constantly
				delete sessionStorage.popoutData;
			});
		}

		function getPopoutData() {
			if (sessionStorage.popoutData) {
				var popoutData = JSON.parse(sessionStorage.popoutData);
				delete sessionStorage.popoutData;
				return popoutData;
			} else {
				return {};
			}
		}

		function getSessionClaims() {
			const claims = sessionStorage.getItem("claims");
			if (claims) {
				const claimSet = claims ? JSON.parse(claims) : [];
				if (claims && claimSet && claimSet.length > 0 && !claimSet["claimVersion"]) {
					return [];
				}
				return claimSet;
			} else {
				return [];
			}
		}
	}
})();
