(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("shareCalendarFolderController", shareCalendarFolderController);

    function shareCalendarFolderController($scope, $mdDialog, $http, $filter, $translate, $timeout, source, coreDataSettings, coreData, errorHandling, successHandling) {

        var originalShareUsers = [];

        var vm = this;
        vm.newItem = !source;
        vm.name = source ? (source.untranslatedName ? $translate.instant(source.untranslatedName) : source.name) : "";
        vm.color = source ? source.color : coreData.nextCalendarColor();
        vm.isCalendar = source ? source.isCalendar : true;
        vm.isResource = source ? source.isResource : false;
        vm.permission = vm.isResource ? 8 : (source ? source.permission : 8);
        vm.access = $filter("translate")("MAPPED_RESOURCES_ACCESS_" + vm.permission);
        vm.sourceName = source ? (source.isCalendar ? source.id : "MY_TASKS") : "Calendar_";
        vm.shareType = source ? (source.isCalendar ? 3 : 4) : 3;
        vm.userPermissions = [];
        vm.groupPermissions = [];
        vm.userGroups = [];
        vm.selectedItem = [];
        vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
        vm.webcalLink = source ? source.webcalLink : null;
        vm.allowWebcalSubscription = source ? source.allowWebcalSubscription : false;
        vm.error = false;
        vm.testing = false;

        // Functions
        vm.cancel = cancel;
        vm.copyWebcalLinkToClipboard = copyWebcalLinkToClipboard;
        vm.focusUserInput = focusUserInput;
        vm.onEmailBlurred = onEmailBlurred;
        vm.onEmailChanged = onEmailChanged;
        vm.onGroupChanged = onGroupChanged;
        vm.queryUsers = coreDataSettings.queryUsers;
        vm.removeGroup = removeGroup;
        vm.removeUser = removeUser;
        vm.save = save;

        // Setup
        activate();

        //////////////////////////////////////

        function activate() {
            loadShareUsers();
        }

        async function loadShareUsers() {
            var params = {
                sharingItem: vm.isCalendar ? 3 : 4,
                shareID: vm.sourceName
            };

            try {
                let success = await $http.post("~/api/v1/settings/share-users/", JSON.stringify(params));
                originalShareUsers = $.extend(true, [], success.data.shareUsers);
                angular.forEach(success.data.shareUsers, function (share) {
                    if (share.user)
                        vm.userPermissions.push(share);
                    else
                        vm.groupPermissions.push(share);
                });
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });	// This is necessary to add the "blank entry" field
                getGroupPermissions();
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

        async function save() {
            try {
                var params = {
                    setting: {
                        id: source.id,
                        allowWebcalSubscription: vm.allowWebcalSubscription
                    }
                };

                await $http.post("~/api/v1/calendars/calendar", JSON.stringify(params));
                await savePermissions();

            } catch (err) {
                errorHandling.report(err);
            }
        }

        function getGroupPermissions() {
            coreDataSettings.settingsData.userGroups
                .then(function (success) {
                    vm.userGroups.push({ name: $filter("translate")("SELECT_A_GROUP"), id: undefined, selectText: true });
                    vm.userGroups.push({ name: $filter("translate")("EVERYONE"), id: -1, isEveryone: true });
                    vm.userGroups.push({ name: $filter("translate")("SHARED_RESOURCES_ADMIN"), id: -2, isAdmins: true });
                    vm.userGroups = vm.userGroups.concat($.extend(true, [], success));

                    if (vm.groupPermissions.length > 0) {
                        angular.forEach(vm.groupPermissions, function (share) {
                            for (var i = 0; i < vm.userGroups.length; i++) {
                                if (vm.userGroups[i].isEveryone && share.isEveryone) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (vm.userGroups[i].isAdmins && share.isAdmins) {
                                    share.group = vm.userGroups[i];
                                    break;
                                } else if (share.group && vm.userGroups[i].id === share.group.id) {
                                    share.group = vm.userGroups[i];
                                    break;
                                }
                            }
                        });
                    }

                    vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
                });
        }

        function copyWebcalLinkToClipboard() {
            copyToClipboard(vm.webcalLink);
        }

        function copyToClipboard(value) {
            var textArea = document.createElement("textarea");
            textArea.value = value || "";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            successHandling.report("COPIED_TO_CLIPBOARD");
        }

        function onEmailChanged(last, index, form) {
            var email = form["email" + index].$viewValue;
            if (email && email.length > 0 && last)
                vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });
        }

        function onEmailBlurred(last, index, form) {
            var email = form["email" + index].$viewValue;
            if ((!email || email.length === 0) && !last) {
                vm.selectedItem.splice(index, 1);
                vm.userPermissions.splice(index, 1);
            }
        }

        function removeUser(index, form) {
            vm.selectedItem.splice(index, 1);
            vm.userPermissions.splice(index, 1);
            form.$setDirty();
        }

        function onGroupChanged(last) {
            if (last)
                vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
        }

        function removeGroup(index, form) {
            vm.groupPermissions.splice(index, 1);
            form.$setDirty();
        }

        async function savePermissions(created) {
            vm.groupPermissions = $.grep(vm.groupPermissions, function (share) { return !share.group.selectText });
            vm.groupPermissions = vm.groupPermissions.map(function (g) {
                if (g.group && g.group.isEveryone) {
                    g.group = undefined;
                    g.isEveryone = true;
                } else if (g.group && g.group.isAdmins) {
                    g.group = undefined;
                    g.isAdmins = true;
                }
                return g;
            });

            var shareUsers = vm.userPermissions.concat(vm.groupPermissions);
            shareUsers = $.grep(shareUsers, function (su) {
                if (su.group || su.isEveryone || su.isAdmins) return true;
                var emailLen = su.user && su.user.emailAddress && su.user.emailAddress.length > 0;
                var userLen = su.user && su.user.userName && su.user.userName.length > 0;
                return emailLen || userLen;
            });
            var addedLength = shareUsers.length;

            try {
                // Push to server
                let params = {
                    shareUsers: shareUsers,
                    sharingItem: vm.isCalendar ? 3 : 4,
                    shareID: vm.sourceName
                };
                await $http.post("~/api/v1/settings/share-item", params);

                coreDataSettings.addPermissions(vm.isCalendar ? 3 : 4, vm.sourceName, addedLength);

                shareUsers = [];

                for (let iter = 0; iter < originalShareUsers.length; ++iter) {
                    let share = originalShareUsers[iter];
                    let found = false;
                    if (share.user) {
                        for (let i = 0; i < vm.userPermissions.length; i++) { // If we removed the user
                            if (vm.userPermissions[i].user.emailAddress === share.user.emailAddress) {
                                found = true;
                                break;
                            }
                        }
                    } else {
                        for (let i = 0; i < vm.groupPermissions.length; i++) { // If we removed the userGroup
                            if (vm.groupPermissions[i].isEveryone && share.isEveryone) {
                                found = true;
                                break;
                            } else if (vm.groupPermissions[i].isAdmins && share.isAdmins) {
                                found = true;
                                break;
                            } else if (share.group && vm.groupPermissions[i].group && vm.groupPermissions[i].group.id === share.group.id) {
                                found = true;
                                break;
                            }
                        }
                    }
                    if (!found)
                        shareUsers.push(share);
                }

                var removedLength = shareUsers.length;
                params = {
                    shareUsers: shareUsers,
                    sharingItem: vm.isCalendar ? 3 : 4,
                    shareID: vm.sourceName
                };
                await $http.post("~/api/v1/settings/share-users-delete", JSON.stringify(params));

                coreDataSettings.removePermissions(source.isCalendar ? 3 : 4, vm.sourceName, removedLength);
                if (created)
                    $mdDialog.hide({ created: true });
                else
                    $mdDialog.hide({ modified: true });

            } catch (err) {
                errorHandling.report(err);
            }
        }

        function focusUserInput() {
            $timeout(function () {
                $("md-autocomplete input").last().trigger("focus");
            });
        }
    }
})();
