(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminSecurityCertificatesController", sysAdminSecurityCertificatesController);

    function sysAdminSecurityCertificatesController($rootScope, $scope, $http, $filter, $mdDialog, $translate, $compile, errorHandling, NgTableParams, gridCheckboxes, coreDataFileStorage, i18n) {
        var vm = this;
        vm.items = [];
        vm.tableParams = new NgTableParams(
            {
                sorting: { StatusSort: "asc", hostname: "asc" },
                count: 25
            }, {
            dataset: [],
            counts: $rootScope.commonTableCounts
        });
        vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.table = vm.tableParams;
        vm.initialized = false;

        // Functions
        //vm.newItem = newItem;
        //vm.deleteItems = deleteItems;
        vm.searchItems = searchItems;
        //vm.editItem = editItem;
        //vm.contextMenuGridItem = contextMenuGridItem;
        //vm.openAbuseDetectionWizard = openAbuseDetectionWizard;
        //      vm.exportSecuritySettings = exportSecuritySettings;
        //      vm.importSecuritySettings = importSecuritySettings;

        activate();

        //////////////////////////

        function activate() {
            loadItems();
        }

        function loadItems() {
            $http
                .get("~/api/v1/settings/sysadmin/ssl-certificates")
                .then(function (result) {
                    vm.items = result.data.certificates || [];

                    let textExpired = $translate.instant("EXPIRED");
                    let textOK = $translate.instant("OK");
                    let textPending = $translate.instant("RENEWING");
                    let textInvalid = $translate.instant("INVALID");

                    for (let i = 0; i < vm.items.length; i++) {
                        let item = vm.items[i];

                        item.Status = textOK;
                        item.StatusSort = 999;
                        item.IsStatusError = false;

                        if (item.isExpired) {
                            item.Status = textExpired;
                            item.StatusSort = 0;
                            item.IsStatusError = true;
                            continue;
                        }

                        if (item.certSetupType == 2) {
                            if (item.acmeValidationStatus == 1) {
                                item.StatusSort = 2;
                                item.Status = textPending;
                                continue;
                            }

                            if (item.acmeValidationStatus == 3) {
                                item.Status = textInvalid;
                                item.StatusSort = 1;
                                item.IsStatusError = true;
                                continue;
                            }
                        }
                    }


                    vm.tableParams.settings({ dataset: vm.items });

                    refreshTable();
                    vm.initialized = true;
                }, function () { });
        }

        function searchItems() {
            vm.tableParams.filter({ $: vm.searchText });
        }

        //function newItem(ev) {
        //	$mdDialog.show({
        //		locals: {
        //			detectionType: 0,
        //			action: 3,
        //			tempBlockWatchPeriod: "",
        //			tempBlockConnectionThreshold: "",
        //			tempBlockPeriod: "",
        //			notifyEmail: "",
        //			description: "",
        //		},
        //		controller: "sysAdminEditAbuseDetectionController",
        //		controllerAs: "ctrl",
        //		templateUrl: "app/sysadmin/settings/security/rule.dlg.html",
        //		targetEvent: ev
        //	})
        //		.then(onModalSuccess);

        //	function onModalSuccess(modalSuccess) {
        //		var params = JSON.stringify({
        //			toAdd: [{
        //				detectionType: modalSuccess.detectionType,
        //				action: modalSuccess.action,
        //				tempBlockWatchPeriod: modalSuccess.tempBlockWatchPeriod,
        //				tempBlockConnectionThreshold: modalSuccess.tempBlockConnectionThreshold,
        //				tempBlockPeriod: modalSuccess.tempBlockPeriod,
        //				notifyEmail: modalSuccess.notifyEmail,
        //				description: modalSuccess.description,
        //			}]
        //		});
        //		saveRules(params);
        //	}
        //}

        //function editItem(card, ev) {
        //	$mdDialog.show({
        //		locals: {
        //			detectionType: card.detectionType,
        //			action: card.action,
        //			tempBlockWatchPeriod: card.tempBlockWatchPeriod,
        //			tempBlockConnectionThreshold: card.tempBlockConnectionThreshold,
        //			tempBlockPeriod: card.tempBlockPeriod,
        //			notifyEmail: card.notifyEmail || "",
        //			description: card.description,
        //		},
        //		controller: "sysAdminEditAbuseDetectionController",
        //		controllerAs: "ctrl",
        //		templateUrl: "app/sysadmin/settings/security/rule.dlg.html",
        //		targetEvent: ev
        //	})
        //		.then(onModalSuccess, function (){});

        //	function onModalSuccess(modalSuccess) {
        //		var params = JSON.stringify({
        //			tempIPBlocks: [{
        //				detectionType: modalSuccess.detectionType,
        //				action: modalSuccess.action,
        //				tempBlockWatchPeriod: modalSuccess.tempBlockWatchPeriod,
        //				tempBlockConnectionThreshold: modalSuccess.tempBlockConnectionThreshold,
        //				tempBlockPeriod: modalSuccess.tempBlockPeriod,
        //				notifyEmail: modalSuccess.notifyEmail || "",
        //				description: modalSuccess.description,
        //				id: card.id
        //			}]
        //		});
        //		saveRules(params);
        //	}
        //}

        //function contextMenuGridItem(item, ev) {
        //	if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
        //		return;
        //	}
        //	ev.stopPropagation();
        //	ev.preventDefault();

        //	var items = vm.checkboxes.getCheckedItems();
        //	//If we right clicked on a not selected item we want to use that item instead
        //	if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
        //		vm.checkboxes.reset();
        //		vm.checkboxes.checkCheckbox(ev, item);
        //		items = [item.id];
        //	}

        //	$scope.dropdownEvent = $.extend(true, {}, ev);
        //	$scope.dropdownOptions = [
        //		{ key: "deleteItems", click: deleteItems, params: $scope.dropdownEvent, translateKey: "DELETE" }
        //	];

        //	var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
        //	var element = $("#context-menu-area");
        //	if (element) {
        //		var elementCompiled = $compile(elementToCompile)($scope);
        //		element.append(elementCompiled);
        //	}
        //}

        //function saveRules(params) {
        //	if (!params) return;

        //	$rootScope.spinner.show();
        //	$http
        //		.post("~/api/v1/settings/sysadmin/temp-ip-blocks", params)
        //		.then(onSaveSuccess, errorHandling.report)
        //		.finally($rootScope.spinner.hide);
        //}

        //function onSaveSuccess() {
        //	loadItems();
        //	$rootScope.$broadcast("security:countersChanged");
        //}

        //function deleteItems(event) {
        //	var rules = vm.checkboxes.getCheckedItems();
        //	if (!rules || !rules.length)
        //		return;

        //	$http.post("~/api/v1/settings/sysadmin/blocked-ips-count-by-rule", JSON.stringify(rules))
        //		.then(
        //			function (result) { confirmDelete(result.data.count); },
        //			function () { confirmDelete(0); });

        //	function confirmDelete(blockCount) {
        //		let confMsg = "";
        //		confMsg += i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: rules.length });
        //		if (blockCount)
        //			confMsg += "\r\n" +i18n.reactPluralize("CONFIRMATIONS_DELETE_IDS_RULE_BLOCKS", { count: blockCount });

        //		var confirm = $mdDialog.confirmDeletion()
        //			.textContent(confMsg)
        //			.targetEvent(event);
        //		$mdDialog.show(confirm).then(doDelete, function () { });
        //	}

        //	function doDelete() {
        //		var params = JSON.stringify({ toRemove: rules });
        //		saveRules(params);
        //	}
        //}

        function refreshTable() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        //	function openAbuseDetectionWizard(selectedCards, ev) {
        //		var confirm = $mdDialog.confirmDeletion()
        //			.textContent($filter("translate")("CONFIRMATIONS_RESET_IDS_RULES"))
        //			.ok($filter("translate")("RESET"))
        //			.targetEvent(ev);
        //		$mdDialog
        //			.show(confirm)
        //			.then(onSpamWizardSuccess, function () { });

        //		function onSpamWizardSuccess() {
        //			$http
        //				.post("~/api/v1/settings/sysadmin/default-temp-ip-blocks")
        //				.then(onSaveSuccess, errorHandling.report);

        //			function onSaveSuccess() {
        //				loadItems();
        //				$rootScope.$broadcast("security:countersChanged");
        //			}
        //		}
        //	};

        //       function exportSecuritySettings() {
        //           var httpPath = "~/api/v1/settings/sysadmin/export-security-settings/idsSettings";
        //           var fileName = $filter("translate")("FILENAME_SECURITY_CONFIG") + "-" + $filter("translate")("IDS_RULES") + ".json";

        //           $rootScope.spinner.show();
        //           coreDataFileStorage
        //               .downloadFile(httpPath, fileName)
        //               .then(onExportSuccess, errorHandling.report)
        //               .finally($rootScope.spinner.hide);

        //           function onExportSuccess() { }
        //       }
        //       function importSecuritySettings(ev) {
        //           $mdDialog
        //               .show({
        //                   locals: {
        //                       titleText: $translate.instant("IMPORT") + " " +  $translate.instant("IDS_RULES")
        //                   },    
        //                   controller: "sysAdminImportSecuritySettingsController",
        //                   controllerAs: "ctrl",
        //                   templateUrl: "app/sysadmin/settings/security/modals/import-security-settings.dlg.html",
        //                   targetEvent: ev
        //               })
        //               .then(function () {
        //                   loadItems();
        //                   $rootScope.$broadcast("security:countersChanged");
        //               });
        //       }
    }

})();
