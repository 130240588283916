(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("shareNoteFolderController", shareNoteFolderController);

	function shareNoteFolderController($scope, $mdDialog, $filter, $q, $translate, $http, $timeout,
		source, coreData, coreDataSettings, coreDataNotes, userDataService, errorHandling) {
		var vm = this;
		vm.newItem = !source;
		vm.owner = source ? (source.ownerUsername ? source.ownerUsername : userDataService.user.domain) : userDataService.user.username;
		vm.isOwner = vm.owner.toLowerCase() === userDataService.user.username.toLowerCase();
		vm.isShareable = vm.isOwner;
		vm.name = source
			? (source.displayName ? $filter("folderTranslate")(source.displayName, vm.owner) : source.name)
			: '';
		vm.originalName = vm.name;
		vm.isPrimary = source ? source.isPrimary : false;
		vm.origIsPrimary = vm.isPrimary;
		vm.permission = source ? source.access : 8;
		vm.access = $filter('translate')('MAPPED_RESOURCES_ACCESS_' + vm.permission);
		vm.id = source ? (source.id || source.itemID) : null;
		vm.type = source ? source.shareType || 'Notes' : 'Notes';
		vm.galEnabled = coreData.user.settings.features.enableGlobalAddressList;
		vm.selectedItem = [];

		if (vm.isShareable)
			sharingInit();

		vm.queryUsers = coreDataSettings.queryUsers;
		vm.save = save;
		vm.delete = deleteNoteFolder;
		vm.detach = detach;
		vm.cancel = cancel;

		function cancel() {
			$mdDialog.cancel();
		}

		function deleteNoteFolder(ev) {
			if (vm.isPrimary)
				return;

			const confirm = $mdDialog.confirmDeletion()
				.htmlContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: vm.name }))
				.targetEvent(ev);
			$mdDialog.show(confirm)
				.then(
					function() {
						var folder = {
							isPrimary: vm.isPrimary,
							folder: vm.name,
							uid: vm.id
						};

						$http
							.post("~/api/v1/notes/sources/delete", folder)
							.then($mdDialog.hide, errorHandling.report);
					},
					function() { });
		}

		function detach() {
			$http.post('~/api/v1/settings/disconnect-from-share', JSON.stringify({ shareConnectorData: [source] }))
				.then(function () {
					$mdDialog.hide({ detached: true });
				}, errorHandling.report);
		}

		function save(ev, noteFolderForm) {
			if (!vm.isValid())
				return;
			if (noteFolderForm.$invalid)
				return;

			var folder = {
				folder: vm.name,
				uid: vm.id
			};

			if (vm.isOwner) {
				if (vm.newItem) {
					$http.post('~/api/v1/notes/sources/add', folder)
						.then(
							function (success) {
								vm.id = success.data.id;
								if (vm.isShareable)
									savePermissions();
								else
									$mdDialog.hide();
							},
							function (failure) {
								errorHandling.report(failure.data.message);
							});
				} else {
					$http.post('~/api/v1/notes/sources/edit', folder)
						.then(
							function (success) {
								if (vm.isShareable)
									savePermissions();
								else
									$mdDialog.hide();
							},
							function (failure) {
								errorHandling.report(failure.data.message);
							});
				}
			}
            // Shares should use edit-shared-folder.dlg.html

		}

		function savePermissions() {
			vm.userPermissions.pop();
			vm.groupPermissions = $.grep(vm.groupPermissions, function (share) {
				if (share.group) return !share.group.selectText;
			});
			vm.groupPermissions = vm.groupPermissions.map(function (g) {
				if (g.group && g.group.isEveryone) {
					g.group = undefined;
					g.isEveryone = true;
				} else if (g.group && g.group.isAdmins) {
					g.group = undefined;
					g.isAdmins = true;
				} else {
					g.isAdmins = false;
					g.isEveryone = false;
				}
				return g;
			});
			var shareUsers = vm.userPermissions.concat(vm.groupPermissions);
			// Push to server
			var params = {
				shareUsers: shareUsers,
				sharingItem: vm.type,
				shareID: vm.id
			};

			var addedLength = shareUsers.length;
			$http.post('~/api/v1/settings/share-item', params)
				.then(function (success) {
					coreDataSettings.addPermissions(vm.type, vm.id, addedLength);
					$mdDialog.hide({});
				}, errorHandling.report);

			shareUsers = [];
			angular.forEach(vm.originalShareUsers, function (share) {
				var found = false;
				if (share.user) {
					for (var i = 0; i < vm.userPermissions.length; i++) { // If we removed the user
						if (vm.userPermissions[i].user.emailAddress === share.user.emailAddress) {
							found = true;
							break;
						}
					}
				} else {
					for (var j = 0; j < vm.groupPermissions.length; j++) { // If we removed the userGroup
						if (vm.groupPermissions[j].isEveryone && share.isEveryone) {
							found = true;
							break;
						} else if (vm.groupPermissions[j].isAdmins && share.isAdmins) {
							found = true;
							break;
						} else if (share.group && vm.groupPermissions[j].group && vm.groupPermissions[j].group.id === share.group.id) {
							found = true;
							break;
						}
					}
				}
				if (!found)
					shareUsers.push(share);
			});

			params = {
				shareUsers: shareUsers,
				sharingItem: vm.type,
				shareID: vm.id
			};

			var removedLength = shareUsers.length;
			$http.post('~/api/v1/settings/share-users-delete', JSON.stringify(params))
				.then(function (success) {
					coreDataSettings.removePermissions(vm.type, vm.id, removedLength);
				}, errorHandling.report);
		}

		function sharingInit() {
			vm.userPermissions = [];
			vm.groupPermissions = [];
			vm.userGroups = [];
			vm.originalShareUsers = [];

			if (!source) {
				vm.userPermissions.push({ access: 4, user: { emailAddress: '' } });
				LoadUserGroupList();
				return;
			}

			$http.post('~/api/v1/settings/share-users/', { sharingItem: vm.type, shareID: vm.id })
				.then(function (success) {
					vm.originalShareUsers = $.extend(true, [], success.data.shareUsers);

					angular.forEach(success.data.shareUsers, function (share) {
						if (share.user)
							vm.userPermissions.push(share);
						else
							vm.groupPermissions.push(share);
					});
					vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });

					LoadUserGroupList();
				}, errorHandling.report);
		}

		function LoadUserGroupList() {
			coreDataSettings.settingsData.userGroups
				.then(function (success) {
					vm.userGroups.push({ name: $translate.instant("SELECT_A_GROUP"), id: undefined, selectText: true });
					vm.userGroups.push({ name: $translate.instant("EVERYONE"), id: -1, isEveryone: true });
					vm.userGroups.push({ name: $translate.instant("SHARED_RESOURCES_ADMIN"), id: -2, isAdmins: true });

					var groups = $.extend(true, [], success).sort(function (a, b) {
						var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
						if (nameA < nameB)
							return -1;
						if (nameA > nameB)
							return 1;
						return 0;
					});
					vm.userGroups = vm.userGroups.concat(groups);

					if (vm.groupPermissions.length > 0) {
						angular.forEach(vm.groupPermissions, function (share) {
							for (var i = 0; i < vm.userGroups.length; i++) {
								if (vm.userGroups[i].isEveryone && share.isEveryone) {
									share.group = vm.userGroups[i];
									break;
								} else if (vm.userGroups[i].isAdmins && share.isAdmins) {
									share.group = vm.userGroups[i];
									break;
								} else if (share.group && vm.userGroups[i].id === share.group.id) {
									share.group = vm.userGroups[i];
									break;
								}
							}
						});
					}

					vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
				});
		}

		/*
		 * Validation
		 */
		vm.ignoreNameWatch = true;
		vm.isNameValid = true;

		vm.isValid = function () { return vm.isNameValid; };

		$scope.$watch("name", function () {
			if (!vm.ignoreNameWatch) {
				if (vm.name === "") {
					vm.isNameValid = false;
				} else {
					vm.isNameValid = true;
				}
			} else {
				vm.ignoreNameWatch = false;
			}
		});

		/*
		 * Sharing tab
		 */

		// User List
		vm.onEmailChanged = function (last, index, form) {
			var email = form["email" + index].$viewValue;
			if (email && email.length > 0 && last)
				vm.userPermissions.push({ access: 4, user: { emailAddress: "" } });
		};

		vm.onEmailBlurred = function (last, index, form) {
			var email = form["email" + index].$viewValue;
			if ((!email || email.length === 0) && !last) {
				vm.selectedItem.splice(index, 1);
				vm.userPermissions.splice(index, 1);
			}
		};

		vm.removeUser = function (index, form) {
			vm.selectedItem.splice(index, 1);
			vm.userPermissions.splice(index, 1);
			form.$setDirty();
		};

		// User Group List
		vm.onGroupChanged = function (last) {
			if (last)
				vm.groupPermissions.push({ access: 4, group: vm.userGroups[0] });
		};

		vm.removeGroup = function (index, form) {
			vm.groupPermissions.splice(index, 1);
			form.$setDirty();
		};

	}
})();
