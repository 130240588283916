(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("coreDataManageDomain", coreDataManageDomain);

	function coreDataManageDomain() {
		var vm = this;
		vm.impersonatingWindow = undefined;
	}
})();